import { ActionTypes } from '../actionTypes';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { AssetDropActionType, AssetDropActionSuccessType } from '../modelsPage/types';

import { updateAssetConfigModel } from '../../routes/Assets/AssetConfigModal/types';
import { VIEW_MODE } from '../../utils/constants/appConstants';
import {
    ActionMenuValue,
    InstanceMonitoringItem,
    SupportedTypes,
    SelectedTypes,
    ConfigFormFields,
    ActiveTypeParams,
    InstanceSaveType,
    FunctionTypeBasics,
} from './types';
import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import { OriginalFunctionTypes } from '../configurationTool/types';

export const getAssetTableDataApi = () => {
    return <const>{
        type: ActionTypes.GET_ASSET_TABLE_DATA_API,
    };
};

export const getAssetTableDataStore = (payload: any) => {
    return <const>{
        type: ActionTypes.GET_ASSET_TABLE_DATA_STORE,
        payload,
    };
};

export const getAllComputeModelsApi = () => {
    return <const>{
        type: ActionTypes.GET_ALL_COMPUTE_MODELS_API,
    };
};

export const getLoadedInstace = () => {
    return <const>{
        type: ActionTypes.GET_ALL_COMPUTE_MODELS,
    };
};

export const getAllComputeModelsStore = (payload: { computeModels: ComputeModelFromJson[] }) => {
    return <const>{
        type: ActionTypes.GET_ALL_COMPUTE_MODELS_STORE,
        payload,
    };
};

export const getMatchingComputeModelsApi = () => {
    return <const>{
        type: ActionTypes.GET_MATCHING_COMPUTE_MODELS_API,
    };
};
export const getMatchingComputeModelsStore = (payload: {
    computeMatchingModels: ComputeModelFromJson[];
}) => {
    return <const>{
        type: ActionTypes.GET_MATCHING_COMPUTE_MODELS_STORE,
        payload,
    };
};

export const changeActiveModelInstanceConfig = (payload: { modelId: string }) => {
    return <const>{
        type: ActionTypes.CHANGE_ACTIVE_MODEL_INSTANCE_CONFIG,
        payload,
    };
};

export const handleIncludeAllChildElements = (payload: { includeAllChild: boolean }) => {
    return <const>{
        type: ActionTypes.HANDLE_INCLUDE_ALL_CHILD_ELEMENTS,
        payload,
    };
};

export const handleTableTotalRecords = (payload: { totalRecords: number }) => {
    return <const>{
        type: ActionTypes.HANDLE_TABLE_TOTAL_RECORDS,
        payload,
    };
};

// export const updateCanvasJsonActionInstanceConfig = (
//     canvasJson: any = { assetData: [], connectionData: [] }
// ) => {
//     return <const>{
//         type: ActionTypes.TABLE_UPDATE_CANVAS_JSON_INSTANCE_CONFIG,
//         payload: {
//             canvasJson
//         }
//     };
// };

// export const handleAssetDropActionInstanceConfig = (
//     dataPayload: AssetDropActionType
// ) => {
//     return <const>{
//         type: ActionTypes.ADD_ASSET_OBJECT_REQUEST_INSTANCE_CONFIG,
//         payload: dataPayload
//     };
// };
// export const handleAssetDropSuccessActionInstanceConfig = (
//     dataPayload: AssetDropActionSuccessType
// ) => {
//     return <const>{
//         type: ActionTypes.ADD_ASSET_OBJECT_SUCCESS_INSTANCE_CONFIG,
//         payload: dataPayload
//     };
// };

export const addToSelectedInstance = (payload: { objectIds: string[] }) => {
    return <const>{
        type: ActionTypes.ADD_TO_SELECTED_INSTANCE,
        payload,
    };
};

export const removeFromSelectedInstance = (payload: { objectIds: string[] }) => {
    return <const>{
        type: ActionTypes.REMOVE_FROM_SELECTED_INSTANCE,
        payload,
    };
};

export const updateActiveTabInstanceConfig = (payload: { tab: string }) => {
    return <const>{
        type: ActionTypes.UPDATE_ACTIVE_TAB_INSTANCE_CONFIG,
        payload,
    };
};

export const changeActivePageInstanceConfig = (payload: { pageNumber: number }) => {
    return <const>{
        type: ActionTypes.CHANGE_ACTIVE_PAGE_INSTANCE_CONFIG,
        payload,
    };
};

export const changeActivePageInstanceUpdate = (payload: { isloading: true }) => {
    return <const>{
        type: ActionTypes.CHANGE_ACTIVE_PAGE_INSTANCE_UPDATE,
        payload,
    };
};

export const changeEnteriesPerPageInstanceConfig = (payload: { enteriesPerPage: any }) => {
    return <const>{
        type: ActionTypes.CHANGE_ENTERIES_PER_PAGE_INSTANCE_CONFIG,
        payload,
    };
};

export const handleFilterAssetTableData = (payload: { field: string; value: string }) => {
    return <const>{
        type: ActionTypes.HANLDE_FILTER_ASSET_TABLE_DATA,
        payload,
    };
};

export const handleSortAssetTableData = (payload: { sortBy: string }) => {
    return <const>{
        type: ActionTypes.HANLDE_SORT_ASSET_TABLE_DATA,
        payload,
    };
};

export const newModelSaveAvailable = (payload: any) => {
    debugger;
    return {
        type: ActionTypes.NEW_MODEL_SAVE_AVAILABLE,
        payload,
    };
};

export const handleConfiguredInstanceJson = (payload: {
    computeModelFromJson: ComputeModelFromJson;
    isNew?: boolean;
}) => {
    return <const>{
        type: ActionTypes.HANDLE_CONFIGURED_INSTANCE_JSON,
        payload,
    };
};

export const handleLoadingConfiguredInstance = () => {
    return <const>{
        type: ActionTypes.HANDLE_LOADING_CONFIGURED_INSTANCE,
    };
};

export const handleAssetToggleUnconfiguredInstance = () => {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TOGGLE_UNCONFIGURED_INSTANCE,
    };
};

export const saveComputeModelInstanceConfigSuccess = () => {
    return <const>{
        type: ActionTypes.SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS,
    };
};

export const saveComputeModelInstanceConfigError = () => {
    return <const>{
        type: ActionTypes.SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR,
    };
};

export const deleteComputeModelInstanceConfigSuccess = () => {
    return <const>{
        type: ActionTypes.DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS,
    };
};

export const deleteComputeModelInstanceConfigError = () => {
    return <const>{
        type: ActionTypes.DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR,
    };
};

export const deleteComputeModelInstanceConfig = () => {
    return <const>{
        type: ActionTypes.DELETE_INSTANCE_COMPUTE_MODEL,
    };
};

export const handleViewModeChangeInstanceConfig = (payload: { viewMode: typeof VIEW_MODE }) => {
    return <const>{
        type: ActionTypes.HANDLE_VIEW_MODE_CHANGE_INSTANCE_CONFIG,
        payload,
    };
};

export const handleAssetModelConfig = (payload: {
    updateAssetConfigModel: updateAssetConfigModel;
}) => {
    return <const>{
        type: ActionTypes.UPDATE_ASSET_CONFIG_MODEL,
        payload,
    };
};
export const clearAssetModelDetailConfig = () => {
    return <const>{
        type: ActionTypes.CLEAR_ASSET_CONFIG_MODEL_DETAILS,
    };
};

export const getIdentyConfigStatus = (identityModels: string[]) => {
    return <const>{
        type: ActionTypes.GET_IDENTITY_CONFIG_STATUS,
        payload: {
            identityModels
        }
    };
};

export const updateIdentyConfigStatus = (identityStatus: {[identity: string]: boolean}) => {
    return <const>{
        type: ActionTypes.UPDATE_IDENTITY_CONFIG_STATUS,
        payload: {
            identityStatus
        }
    };
};

export const updateIsMultipleModify = (isMultipleModify: boolean) => {
    return <const>{
        type: ActionTypes.UPDATE_IS_MULTIPLE_MODIFY,
        payload: {
            isMultipleModify
        }
    };
};

export const saveComputeModelInstanceConfig = (payload?: {
    objectIds: string[];
    saveMode?: InstanceSaveType;
}) => {
    return <const>{
        type: ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL,
        payload,
    };
};
export const saveComputeModelInstanceConfigUpdate = (payload?: {
    objectIds: string[];
    saveMode?: InstanceSaveType;
}) => {
    return <const>{
        type: ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL_CHANGES_UPDATE,
        payload,
    };
};
export const handleAssetConfigureBtnClickAction = () => {
    return <const>{
        type: ActionTypes.HANDLE_CONFIGURE_BUTTON_CLICK,
        payload: {},
    };
};

export function updateSelectedMenuItem(menuItem: ActionMenuValue) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_MENU_ITEM,
        payload: menuItem,
    };
}

export const handleAssetEnableBtnClickAction = (payload: any) => {
    return <const>{
        type: ActionTypes.HANDLE_ENABLE_BUTTON_CLICK,
        payload,
    };
};

export function updateAssetCriticalityModels(payload: ComputeModelToJson[]) {
    return <const>{
        type: ActionTypes.UPDATE_ASSET_CRITICALITY_MODELS,
        payload,
    };
}

export function updateSelectedCriticalityModel(payload: ComputeModelToJson) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_CRITICALITY_MODEL,
        payload,
    };
}

export function updateInstanceMonitoringList(payload: InstanceMonitoringItem[]) {
    return <const>{
        type: ActionTypes.UPDATE_INSTANCE_MONITORING_LIST,
        payload,
    };
}

export function updateSupportedTypes(payload: SupportedTypes[]) {
    return <const>{
        type: ActionTypes.UPDATE_SUPPORTED_TYPES,
        payload,
    };
}

export function updateSelectedSupportedType(payload: SelectedTypes) {
    debugger
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_SUPPORTED_TYPE,
        payload,
    };
}
export function updateSelectedSupportedTypemodel(payload: any){
    return <const>{
        type:ActionTypes.UPDATE_SELECTED_SUPPORTED_TYPE_MODEl,
        payload,
    }
}
export function checkedListofItems(isCheckeditems: boolean){
    debugger
    return<const>{
        type:ActionTypes.CHECKED_LISTOF_ITEMS,
        payload:isCheckeditems
    }
}

export function checkedListofItemupdate(isCheckeditemupdate: boolean){
    return<const>{
        type:ActionTypes.CHECKED_LISTOF_ITEM_UPDATE,
        payload:isCheckeditemupdate
    }
}

export function CheckedAllItems(checkedAllItems:any){
    return<const>{
        type:ActionTypes.CHECKED_ALL_ITEMS,
        payload: checkedAllItems
    }
}

export function viewupdateSelectedSupportedType(payload: any) {
    return <const>{
        type: ActionTypes.VIEW_UPDATE_SELECTED_SUPPORTED_TYPE,
        payload,
    };
}
export function updateComputeModelInstanceConfig(payload?: {
    objectIds: string[];
    saveMode?: InstanceSaveType;
}) {
    debugger;
    return <const>{
        type: ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL_UPDATE,
        payload,
    };
}

export function viewandUpdateComputeModelInstanceConfig(payload?: {
    objectIds: string[];
    saveMode?: InstanceSaveType;
}) {
    debugger;
    return {
        type: ActionTypes.VIEW_SAVE_INSTANCE_COMPUTE_MODEL_UPDATE,
        payload,
    };
}

export function getConfiguredInstanceJson(payload: {
    objectId: string;
    assetMonitorModelId: string;
}) {
    return {
        type: ActionTypes.GET_CONFIGURED_INSTANCE_JSON,
        payload,
    };
}

export function handleLoading(isLoading: boolean) {
    return {
        type: ActionTypes.HANDLE_INSTANCE_CONFIG_LOADING,
        payload: isLoading,
    };
}

// export function updatedPreviousSelectedTypes(payload: SelectedTypes) {
//     return <const>{
//         type: ActionTypes.UPDATE_PREVIOUS_SELECTED_TYPES,
//         payload,
//     };
// }

export function getSelectedTypeComputeModelFromJson(payload: {
    modelId: string;
    typeId: string;
    version: string;
    objectId: string;
}) {
    return <const>{
        type: ActionTypes.GET_SELECTED_TYPE_COMPUTE_MODEL_FROM_JSON,
        payload,
    };
}

export function updateButtonLoader(isLoading: boolean) {
    debugger;
    return {
        type: ActionTypes.UPDATE_ASSET_BUTTON_LOADING,
        payload: isLoading,
    };
}
export function updateSelectedTypeJson(payload: { json: ComputeModelFromJson }) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_TYPE_JSON,
        payload,
    };
}

export function updateAssetConfigModalFields(payload: ConfigFormFields) {
    return <const>{
        type: ActionTypes.UPDATE_ASSET_CONFIG_MODAL_FIELDS,
        payload,
    };
}

export function updateActiveModelTypeParams(payload: ActiveTypeParams) {
    return {
        type: ActionTypes.UPDATE_ACTIVE_MODEL_TYPE_PARAMS,
        payload,
    };
}

export function handleConfiguredSaveInstance() {
    return {
        type: ActionTypes.HANDLE_CONFIGURED_SAVE_INSTANCE,
    };
}

export function updateAssetConfigLoader(loading: boolean) {
    return {
        type: ActionTypes.UPDATE_ASSET_CONFIG_LOADING,
        payload: loading,
    };
}

export function updateOriginalTypeJson(payload: { json: ComputeModelToJson }) {
    return <const>{
        type: ActionTypes.UPDATE_ORIGINAL_TYPE_JSON,
        payload,
    };
}

export const updateInstanceTypeJsonConditionDetails = (payload: {
    json: LocalJson;
    originalFunctionUsed: OriginalFunctionTypes;
}) => {
    return <const>{
        type: ActionTypes.UPDATE_INSTANCE_TYPE_JSON_CONDITION_DETAILS,
        payload,
    };
};

export const hanldeInstanceTypeOriginalFunctionsLoading = (isLoading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_INSTANCE_TYPE_ORIGINAL_FUNC_LOADING,
        payload: isLoading,
    };
};

export const getLibraryReferencesForFunctionTypesRequest = (
    functionTypeBasicsList: FunctionTypeBasics[]
) => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_REQUEST,
        payload: functionTypeBasicsList,
    };
};

export const getLibraryReferencesForFunctionTypesSuccess = () => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_SUCCESS,
    };
};

export const getLibraryReferencesForFunctionTypeFailure = () => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_FAILURE,
    };
};
