import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Button, Tooltip, WithTooltip } from "@abb/abb-common-ux-react";

import "./style.scss";
import Flex from "../../../components/shared/Flex";
import { ActionTypes } from "../../../store/actionTypes";
import {
    handleAssetToggle,
    updateAssetPageStatus,
    updateModelStatus,
} from "../../../store/assets/actions";
import {
    ASSET_MODAL_BUTTON,
    CONFIRMATION_BUTTON,
    TOOLTIP_MESSAGES,
} from "../../../utils/constants/uiConstants";
import { StoreState } from "../../../store";
import { showModal } from "../../../store/modal/action";
import SaveInstanceConfigModal from "../SaveInstanceModal";
import { SAVE_OPTIONS, VIEW_MODE } from "../../../utils/constants/appConstants";
import {
    handleConfiguredSaveInstance,
    updateIsMultipleModify,
} from "../../../store/instanceConfig/actions";
import {
    updateConfigurationToolActiveView,
    undoJSON,
    redoJSON,
} from "../../../store/configurationTool/actions";
import { showGrid } from "../../../store/grid/action";
import { viewandUpdateComputeModelInstanceConfig } from "../../../store/instanceConfig/actions";
// import { isUpdateButton } from '../../../store/login/types';

interface AppProps {
    viewMode: typeof VIEW_MODE.DIAGRAM | typeof VIEW_MODE.TABLE;
}

export function ButtonBar(
    props: AppProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) {
    const updateHandle = (e: any) => {
        let assetInstanceAmInfo: any[] = [];
        const assetInstance =
            props.instanceTableDataById[
                props.clickedActiveType[0].selectedInstance
            ];
        assetInstanceAmInfo = assetInstance.amInfo;
        const requiredAmInfo = assetInstanceAmInfo.find(
            (item: any) =>
                item.assetMonitorModel === Object.keys(props.selectedTypes)[0]
        );
        if (requiredAmInfo) {
            props.viewandUpdateComputeModelInstanceConfig(
                requiredAmInfo.latestVersion
            );
        }
    };

    return (
        <Flex center className="button-bar">
            <Flex className="left-margin">
                <WithTooltip>
                    <Button
                        className="toggle-grid-btn"
                        type={"primary-blue"}
                        disabled={props.undoStack.length === 0}
                        sizeClass="small"
                        style={{ marginRight: "10px" }}
                        icon="abb/undo"
                        id="mGrid"
                        onClick={props.undoConfigurationJson}
                    />
                    <Tooltip>{TOOLTIP_MESSAGES.UNDO}</Tooltip>
                </WithTooltip>
                <WithTooltip>
                    <Button
                        className="toggle-grid-btn"
                        type={"primary-blue"}
                        disabled={props.redoStack.length === 0}
                        sizeClass="small"
                        icon="abb/redo"
                        id="mGrid"
                        onClick={props.redoConfigurationJson}
                    />
                    <Tooltip>{TOOLTIP_MESSAGES.REDO}</Tooltip>
                </WithTooltip>
            </Flex>
            <div className="left-margin">
                <WithTooltip>
                    <Button
                        className="toggle-grid-btn "
                        type={props.showGrid ? "primary-blue" : "normal"}
                        sizeClass="small"
                        icon="abb/matrix"
                        id="mGrid"
                        onClick={() => props.toggleGridDisplay(!props.showGrid)}
                    />
                    <Tooltip>{TOOLTIP_MESSAGES.GRID}</Tooltip>
                </WithTooltip>
            </div>
            <Button
                id={props.viewMode.label + ""}
                type="discreet-black"
                icon="abb/view"
                text={
                    props.viewMode === VIEW_MODE.DIAGRAM
                        ? VIEW_MODE.TABLE.label
                        : VIEW_MODE.DIAGRAM.label
                }
                sizeClass="small"
                disabled={
                    props.isLoading ||
                    props.isSaving ||
                    props.isOriginalFunctionLoading
                }
                onClick={props.toggleActiveView}
            />

            <Button
                id="CancelBtn"
                type="discreet-blue"
                text={CONFIRMATION_BUTTON.CANCEL}
                sizeClass="small"
                disabled={
                    props.isLoading ||
                    props.isSaving ||
                    props.isOriginalFunctionLoading
                }
                onClick={() => {
                    props.handleAssetPageStatus(false);
                    props.updateIsMultipleModify(false);
                }}
            />

            {props.isUpdateButton ? (
                <Button
                    id={SAVE_OPTIONS.PUBLISH}
                    className="btn-bar-save"
                    type="primary-blue"
                    text={props.isUpdateButton ? "Update" : ""}
                    isLoading={props.isSaving}
                    disabled={
                        props.isLoading ||
                        props.isSaving ||
                        props.isOriginalFunctionLoading
                    }
                    sizeClass="small"
                    onClick={(e: any) => {
                        updateHandle(e);
                    }}
                />
            ) : (
                (props.isConfiguredInstance || props.isMultipleModify) && (
                    <Button
                        id={SAVE_OPTIONS.PUBLISH}
                        className="btn-bar-save"
                        type="primary-blue"
                        isLoading={props.isSaving}
                        text={"Modify"}
                        disabled={
                            props.isLoading ||
                            props.isSaving ||
                            props.isOriginalFunctionLoading
                        }
                        sizeClass="small"
                        onClick={() => {
                            props.saveComputeModelInstance();
                            // props.handleConfiguredSaveInstance();
                        }}
                    />
                )
            )}

            {!props.isUpdateButton && !props.isMultipleModify && (
                <Button
                    id={SAVE_OPTIONS.PUBLISH}
                    className="btn-bar-save"
                    type="primary-blue"
                    text={
                        props.isConfiguredInstance
                            ? ASSET_MODAL_BUTTON.STOP_MONITORING
                            : ASSET_MODAL_BUTTON.START_MONITORING
                    }
                    sizeClass="small"
                    isLoading={!props.isConfiguredInstance && props.isSaving}
                    disabled={
                        props.isLoading ||
                        props.isSaving ||
                        props.isOriginalFunctionLoading
                    }
                    onClick={
                        props.isConfiguredInstance
                            ? props.deleteComputeModelInstance
                            : props.showSaveComputeModelInstance
                    }
                />
            )}
        </Flex>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        isConfiguredInstance: !state.instanceConfig.activeModel.isNew,
        isMultipleModify: state.instanceConfig.isMultipleModify,
        hasMonitorInstance: !state.assets.assetPermission.hasMonitorInstance,
        hasDeleteInstance: !state.assets.assetPermission.hasDeleteInstance,
        isLoading: state.instanceConfig.isLoading,
        isSaving: state.instanceConfig.isSaving,
        isOriginalFunctionLoading: state.instanceConfig.originalFunctionLoading,
        showGrid: state.grid.show,
        json: state.configurationTool.json,
        undoStack: state.configurationTool.undoStack,
        redoStack: state.configurationTool.redoStack,
        instanceTableDataById:
            state.instanceConfig.instancesTable.tableData.byId,
        selectedInstances:
            state.instanceConfig.instancesTable.tableData.selectedInstances,
        selectedTypes: state.instanceConfig.selectedTypes,
        isUpdateButton: state.assets.isUpdateButton,
        clickedActiveType: state.instanceConfig.clickedActiveParams,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        toggleActiveView: () => {
            dispatch(updateConfigurationToolActiveView());
        },
        handleAssetPageStatus: (configurationMode: boolean) => {
            dispatch(updateAssetPageStatus(configurationMode));
        },
        saveComputeModelInstance: () => {
            dispatch({ type: ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL });
        },
        saveComputeModelInstanceUpdate: () => {
            dispatch({
                type: ActionTypes.SAVE_INSTANCE_COMPUTE_MODEL_CHANGES_UPDATE,
            });
        },
        viewandUpdateComputeModelInstanceConfig: (payload: {
            objectIds: string[];
        }) => {
            dispatch(viewandUpdateComputeModelInstanceConfig(payload));
        },
        deleteComputeModelInstance: () => {
            dispatch({ type: ActionTypes.DELETE_INSTANCE_COMPUTE_MODEL });
        },
        toggleAssetPage: () => {
            dispatch(handleAssetToggle());
        },

        handleConfiguredSaveInstance: () => {
            dispatch(handleConfiguredSaveInstance());
        },
        showSaveComputeModelInstance: () => {
            dispatch(
                showModal({
                    //@ts-ignore
                    component: SaveInstanceConfigModal,
                    modalTitle: "Instances",
                })
            );
        },
        toggleGridDisplay: (show: boolean) => {
            dispatch(showGrid(show));
        },
        undoConfigurationJson: () => {
            dispatch(undoJSON());
        },
        redoConfigurationJson: () => {
            dispatch(redoJSON());
        },
        updateIsMultipleModify: (isMultipleModify: boolean) => {
            dispatch(updateIsMultipleModify(isMultipleModify));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonBar);
