import { ActionTypes } from '../actionTypes';
import { IODataTypes } from '../../components/Fabric/types';
import {
    FunctionTypeList,
    AssetsInfo,
    EndPoint,
    FunctionComponents,
} from '../../routes/Functions/type';
import {
    Functions,
    FunctionHeaderTypeDetails,
    FunctionSaveType,
    FunctionMode,
    IFunctionPermission,
    FunctionAlarmType,
    DragDropTableData,
    AlarmMapping,
    LibraryAndNodePayload,
    RemoveAndAddFunctionsToLibrariesPayload,
    LibraryAndNodePayloadWithoutNodes,
    UpdateActiveFunctionType,
    EncryptLibraryRequestPayload,
    LibraryDraggedDetails,
    IGetSelectedFunctionPayload,
    IChangeLibPasswordPayload,
    IEndPointList
} from './types';
import { VersionUpdateType } from '../configurationTool/types';
import { payloadToExportFunctionWithLibraries } from '../exportAsset/types';
import { AddNewLibraryWithFunctionsPayloadtype } from '../../routes/Functions/CreateLibraryPopup';
import { UI } from 'sce-engg-model-19.09/lib/interfaces/IPProtection';
import { FunctionType } from 'sce-engg-model-19.09/lib/types/Type';

interface CreateFunctionModelDetails {
    name: string;
    typeId: string;
    description: string;
    endpoint: string;
    version: string;
    tags: string[];
}

interface FunctionPropertiesSettings {
    endpoint: {
        value: string;
        dataType: IODataTypes;
        description: string;
        isMandatory: boolean;
    };
}

export interface FunctionProperties {
    settings: FunctionPropertiesSettings;
    inputs: any;
    outputs: any;
    alias: any;
    library?: any;
}
export interface CreateFunctionAssetDetails {
    model: string;
    typeId: string;
    version: string;
    name: string;
    description: string;
    tags: string[];
    properties: FunctionProperties;
    isExtensible?: boolean;
    modelsUsed? :any[];
}

export function createNewFunction(payload: {
    assetsDetails?: CreateFunctionAssetDetails;
    modelDetails?: CreateFunctionModelDetails;
}) {
    return {
        type: ActionTypes.CREATE_FUNCTION,
        payload,
    };
}
export function dragandDropFunction(name: string) {
    return {
        type: ActionTypes.DRAG_DROP_FUNCTION,
        payload: name,
    };
}

export function getSelectedFunctionType(payload: IGetSelectedFunctionPayload) {
    return {
        type: ActionTypes.GET_SELECTED_FUNCTION_TYPE,
        payload,
    };
}

export function updateSelectedFunctionType(payload: {
    typeDetails: CreateFunctionAssetDetails;
    activeLibId?: string;
}) {
    return {
        type: ActionTypes.UPDATE_SELECTED_FUNCTION_TYPE,
        payload,
    };
}

export function handleFunctionDelete(payload: {
    modelId: string;
    typeId: string;
    functionName: string;
    tags: string[];
}) {
    return {
        type: ActionTypes.HANDLE_FUNCTION_DELETE,
        payload,
    };
}
export function successFunctionDelete(functionTypeId: string) {
    return {
        type: ActionTypes.SUCCESS_FUNCTION_DELETE,
        payload: functionTypeId,
    };
}
export function handleUniqueTypeIdLoading(isLoading: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID_LOADING,
        payload: isLoading,
    };
}
export function handleUniqueTypeIdFunction(payload: { details: any | { assetsInfo: AssetsInfo }; saveType: FunctionSaveType; mode: FunctionMode }) {
    console.log("assset", payload)
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID_FUNCTION,
        payload,
    }
}
export function handleUniqueTypeIdFailed(isExist: string) {
    console.log(isExist, 'trueee');
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID_ISEXIST,
        payload: isExist,
    };
}

export function handleUniqueTypeIdMessage(message: string) {
    console.log(message, 'messagess');
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID_MESSAGE,
        payload: message,
    };
}
export function handleUniqueLibraryFailed(isExist: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_LIBRARY_ISEXIST,
        payload: isExist,
    };
}

export function errorFunctionDelete() {
    return {
        type: ActionTypes.ERROR_FUNCTION_DELETE,
    };
}
export function getfunctionTypes(models: string[]) {
    return {
        type: ActionTypes.GET_FUNCTION_TYPES,
        payload: models,
    };
}

export function setFunctionTypes(payload: { functionsList: Functions[] }) {
    return {
        type: ActionTypes.SET_FUNCTION_TYPES,
        payload,
    };
}

export function errorGetFunctionTypes() {
    return {
        type: ActionTypes.ERROR_GET_FUNCTION_TYPES,
    };
}

export function successLoadingData() {
    return {
        type: ActionTypes.SUCCESS_LOADING_DATA,
    };
}
export function errorLoadingData() {
    return {
        type: ActionTypes.ERROR_LOADING_DATA,
    };
}

export function updateAssetInfo(payload: { assetsInfo: AssetsInfo }) {
    return {
        type: ActionTypes.UPDATE_FUNC_ASSETS_INFO,
        payload,
    };
}

export function handlePopoverOpen(isOpen: boolean) {
    return {
        type: ActionTypes.HANDLE_POPOVER_OPEN,
        payload: isOpen,
    };
}

export function handlePopoverInfo(isOpen: boolean) {
    return {
        type: ActionTypes.HANDLE_POPOVER_INFO,
        payload: isOpen,
    };
}

export function updateFunctionHeaderTypeDetails(payload: FunctionHeaderTypeDetails) {
    return {
        type: ActionTypes.UPDATE_FUNCTION_HEADER_TYPE_DETAILS,
        payload,
    };
}

export function updateFunctionHeaderTypeStatusDetails(payload: {
    functionHeaderDetails: FunctionHeaderTypeDetails;
    overallStatus: boolean;
    tableType: 'INPUT' | 'OUTPUT' | 'CONDITION';
}) {
    return {
        type: ActionTypes.UPDATE_FUNCTION_HEADER_TYPE_STATUS_DETAILS,
        payload,
    };
}

export function updateConditionDelete(payload: string) {
    return {
        type: ActionTypes.UPDATE_CONDITION_DELETE_STATUS,
        payload,
    };
}

export function updateCalculationDeleteStatus(payload: FunctionHeaderTypeDetails) {
    return {
        type: ActionTypes.UPDATE_CALCULATION_DELETE_STATUS,
        payload,
    };
}

export function handleFunctionSave(saveType: FunctionSaveType) {
    return {
        type: ActionTypes.HANDLE_FUNCTION_SAVE,
        payload: saveType,
    };
}

export function updateFunctionEndPoint(endpoint: EndPoint) {
    return {
        type: ActionTypes.UPDATE_FUNCTION_ENDPOINT,
        payload: endpoint,
    };
}

export function handleFunctionMode(functionMode: FunctionMode) {
    return {
        type: ActionTypes.HANDLE_FUNCTION_MODE,
        payload: functionMode,
    };
}

export function successFunctionSave(functionData: CreateFunctionAssetDetails) {
    return {
        type: ActionTypes.SUCCESS_FUNCTION_SAVE,
        payload: functionData,
    };
}

export function handleIsFunctionDirty(isDirty: boolean) {
    return {
        type: ActionTypes.HANDLE_IS_FUNCTION_DIRTY,
        payload: isDirty,
    };
}

export function handleFunctionErrorFlag(errorFlag: boolean) {
    return {
        type: ActionTypes.HANDLE_FUNCTION_ERROR_FLAG,
        payload: errorFlag,
    };
}

export function getAllPermisssionForFunctionAction() {
    return {
        type: ActionTypes.GET_ALL_PERMISSION_FUNCTION,
    };
}

export function updateFunctionPermission(payload: IFunctionPermission) {
    return {
        type: ActionTypes.UPDATE_FUNCTION_PERMISSION,
        payload,
    };
}
export function getAlarmTypes() {
    return {
        type: ActionTypes.GET_ALARM_TYPE,
    };
}

export function setAlarmTypes(payload: FunctionAlarmType[]) {
    return {
        type: ActionTypes.SET_ALARM_TYPE,
        payload,
    };
}

export const updateAlarmDragDropTables = (payload: DragDropTableData) => {
    return <const>{
        type: ActionTypes.UPDATE_ALARM_MODAL_DRAG_DROP_TABLES,
        payload: payload,
    };
};

export const updateAlarmMappingDetails = (payload: AlarmMapping) => {
    return <const>{ type: ActionTypes.UPDATE_ALARM_MAPPING_DETAILS, payload };
};

export const updateFunctionTabNavList = (payload: FunctionComponents[]) => {
    return <const>{
        type: ActionTypes.UPDATE_TAB_NAV_LIST,
        payload,
    };
};

export const updateSelectedAlarmType = (payload: FunctionAlarmType) => {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_ALARM_TYPE,
        payload,
    };
};

export const clearDropTableMapping = () => {
    return <const>{
        type: ActionTypes.CLEAR_DROP_TABLE_MAPPING,
    };
};

export const changeFunctionVersionUpdate = (updateVersionType: VersionUpdateType) => {
    return <const>{
        type: ActionTypes.UPDATE_VERSION_VARIABLE,
        payload: updateVersionType,
    };
};

export const toggleSpinnerText = (payload: boolean) => {
    return <const>{
        type: ActionTypes.TOGGLE_LOADER_TEXT,
        payload,
    };
};

export const getMutuallyExclusiveFunctionTypes = () => {
    return <const>{
        type: ActionTypes.MUTUALLY_EXCLUSIVE_FUNCTIONS_REQUEST,
    };
};

export const mutuallyExclusiveFunctionTypesSuccess = (payload: UpdateActiveFunctionType[]) => {
    return <const>{
        type: ActionTypes.MUTUALLY_EXCLUSIVE_FUNCTIONS_SUCCESS,
        payload,
    };
};

export const getMutuallyExclusiveFunctionTypesFailure = () => {
    return <const>{
        type: ActionTypes.MUTUALLY_EXCLUSIVE_FUNCTIONS_FAILURE,
    };
};

export const addFunctionsToNewLibraryRequest = (payload: AddNewLibraryWithFunctionsPayloadtype) => {
    return <const>{
        type: ActionTypes.ADD_FUNCTIONS_TO_NEW_LIBRARY_REQUEST,
        payload,
    };
};

export const addFunctionsToNewLibrarySuccess = () => {
    return <const>{
        type: ActionTypes.ADD_FUNCTIONS_TO_NEW_LIBRARY_SUCCESS,
    };
};

export const addFunctionsToNewLibraryFailure = () => {
    return <const>{
        type: ActionTypes.ADD_FUNCTIONS_TO_NEW_LIBRARY_FAILURE,
    };
};

export const getLibraryAndNodesRequest = (exportMode?: boolean) => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_AND_NODES_REQUEST,
        exportMode,
    };
};

export const getLibraryAndNodesSuccess = (payload: LibraryAndNodePayload[]) => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_AND_NODES_SUCCESS,
        payload,
    };
};

export const getLibraryAndNodesFailure = () => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_AND_NODES_FAILURE,
    };
};

export const getLibraryDetailsRequest = (payload: {tenantId: string, libraryId: string}) => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_DETAILS_REQUEST,
        payload,
    };
};

export const getLibraryDetailsSuccess = (payload: LibraryAndNodePayload[]) => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_DETAILS_SUCCESS,
        payload,
    };
};

export const getLibraryDetailsFailure = () => {
    return <const>{
        type: ActionTypes.GET_LIBRARY_DETAILS_FAILURE,
    };
};

export const deleteLibraryRequest = (
    libraryId: string,
    libraryVersion: string,
    password: string,
    isIPProtected: boolean,
    deleteFunctions: boolean
) => {
    return <const>{
        type: ActionTypes.DELETE_LIBRARY_REQUEST,
        payload: {
            libraryId,
            libraryVersion,
            password,
            isIPProtected,
            deleteFunctions,
        },
    };
};

export const renameLibraryRequest = (
    libraryId: string,
    libraryVersion: string,
    password: string,
    root: string,
) => {
    return <const>{
        type: ActionTypes.RENAME_LIBRARY_REQUEST,
        payload: {
            libraryId,
            libraryVersion,
            password,
            root,
        },
    };
};

export const renameLibrarySuccess = () => {
    return <const>{
        type: ActionTypes.RENAME_LIBRARY_SUCCESS,
    };
};

export const renameLibraryError = () => {
    return <const>{
        type: ActionTypes.RENAME_LIBRARY_ERROR,
    };
};

export const deleteLibrarySuccess = () => {
    return <const>{
        type: ActionTypes.DELETE_LIBRARY_SUCCESS,
    };
};

export const deleteLibraryFailure = () => {
    return <const>{
        type: ActionTypes.DELETE_LIBRARY_FAILURE,
    };
};

export const removeFunctionsFromExistingLibraryRequest = (
    payload: RemoveAndAddFunctionsToLibrariesPayload
) => {
    return <const>{
        type: ActionTypes.REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_REQUEST,
        payload,
    };
};

export const removeFunctionsFromExistingLibrarySuccess = () => {
    return <const>{
        type: ActionTypes.REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_SUCCESS,
    };
};

export const removeFunctionsFromExistingLibraryFailure = () => {
    return <const>{
        type: ActionTypes.REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_FAILURE,
    };
};

export const addFunctionsToExistingLibraryRequest = (
    payload: RemoveAndAddFunctionsToLibrariesPayload
) => {
    return <const>{
        type: ActionTypes.ADD_FUNCTIONS_TO_EXISTING_LIBRARY_REQUEST,
        payload,
    };
};

export const addFunctionsToExistingLibrarySuccess = () => {
    return <const>{
        type: ActionTypes.ADD_FUNCTIONS_TO_EXISTING_LIBRARY_SUCCESS,
    };
};

export const addFunctionsToExistingLibraryFailure = () => {
    return <const>{
        type: ActionTypes.ADD_FUNCTIONS_TO_EXISTING_LIBRARY_FAILURE,
    };
};

export const getExportableFunctionsRequest = (payload: payloadToExportFunctionWithLibraries[]) => {
    return <const>{
        type: ActionTypes.GET_EXPORTABLE_FUNCTIONS_REQUEST,
        payload,
    };
};

export const getExportableFunctionsSuccess = () => {
    return <const>{
        type: ActionTypes.GET_EXPORTABLE_FUNCTIONS_SUCCESS,
    };
};

export const getExportableFunctionsFailure = () => {
    return <const>{
        type: ActionTypes.GET_EXPORTABLE_FUNCTIONS_FAILURE,
    };
};

export const getDecryptedFunctionTypeRequest = (payload: {
    functionsDetailsList: CreateFunctionAssetDetails[];
    libraryDetails: LibraryAndNodePayloadWithoutNodes[];
}) => {
    console.log("requested")
    return <const>{
        type: ActionTypes.GET_DECRYPTED_FUNCTION_TYPE_REQUEST,
        payload,
    };
};

export const getDecryptedFunctionTypeSuccess = (payload: {
    decryptedLibraryDetails: {
        root: string;
        id: string;
        libraryVersion: string;
        isIPProtected: boolean;
        password: string;
    };
}) => {
    return <const>{
        type: ActionTypes.GET_DECRYPTED_FUNCTION_TYPE_SUCCESS,
        payload,
    };
};

export const getDecryptedFunctionTypeFailure = () => {
    console.log("failed")
    return <const>{
        type: ActionTypes.GET_DECRYPTED_FUNCTION_TYPE_FAILURE,

    };
};
export const handleSaveAsMessage = (payload: {
    assetsInfo: AssetsInfo;
    functionsDetailsList: CreateFunctionAssetDetails[];
    libraryDetails: LibraryAndNodePayloadWithoutNodes[];
}) => {
    return {
        type: ActionTypes.GET_DECRYPTED_PASSWORD_TYPE_REQUEST,

        payload

    }

}


export const setGlobalLibraryError = (payload: { error: string }) => {
    return <const>{
        type: ActionTypes.SET_GLOBAL_LIBRARY_ERROR,
        payload,
    };
};

export const encryptLibraryRequest = (payload: EncryptLibraryRequestPayload) => {
    return <const>{
        type: ActionTypes.ENCRYPT_LIBRARY_REQUEST,
        payload,
    };
};

export const encryptLibrarySuccess = () => {
    return <const>{
        type: ActionTypes.ENCRYPT_LIBRARY_SUCCESS,
    };
};

export const encryptLibraryFailure = () => {
    return <const>{
        type: ActionTypes.ENCRYPT_LIBRARY_FAILURE,
    };
};

export const setDragAndDroppedLibrary = (payload: { dragDroppedLib: LibraryDraggedDetails[] }) => {
    return <const>{
        type: ActionTypes.SET_DRAGGED_AND_DROPPED_LIBRARY,
        payload,
    };
};

export const handleChangeLibraryPassword = (payload: IChangeLibPasswordPayload) => {
    return <const>{
        type: ActionTypes.HANDLE_CHANGE_LIBRARY_PASSWORD,
        payload,
    };
};
export const handleChangePasswordLoading = (isloading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_CHANGE_PASSWORD_LOADING,
        payload: isloading,
    };
};

export const handleLibPasswordError = (errorString: string) => {
    return <const>{
        type: ActionTypes.HANDLE_LIB_PASSWORD_ERROR,
        payload: errorString,
    };
};

export const resetLibPasswordError = () => {
    return <const>{
        type: ActionTypes.RESET_LIB_PASSWORD_ERROR,
    };
};

export const unlockLibrary = (payload: {
    libraryDetail: LibraryAndNodePayload;
    password: string;
}) => {
    return <const>{
        type: ActionTypes.UNLOCK_LIBRARY,
        payload,
    };
};

export const handlePasswordBtnLoading = (isloading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_PASSWORD_BTN_LOADING,
        payload: isloading,
    };
};

export const handleGetLibraryAndNodesLoading = (isLoading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_GET_LIBRARY_AND_NODES_LOADING,
        payload: isLoading,
    };
};

export const handleSelectedFunctionLoading = (isLoading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_SELECTED_FUNCTION_LOADING,
        payload: isLoading,
    };
};

export const setDeletingLibraryDetails = (payload: {
    libraryId: string;
    libraryVersion: string;
    password: string;
}) => {
    return <const>{
        type: ActionTypes.SET_DELETING_LIBRARY_DETAILS,
        payload,
    };
};

export const handleLibrariesImport = (librariesToImport: UI.IImportableLibraryInfo[]) => {
    return <const>{
        type: ActionTypes.HANDLE_LIBRARIES_IMPORT,
        payload: librariesToImport,
    };
};

export const updateOriginalFunctionDetail = (payload: FunctionType) => {
    return <const>{
        type: ActionTypes.UPDATE_ORIGINAL_FUNCTION_DETAIL,
        payload,
    };
};

export function handleSaveFunctionEndPoint(payload: { endpoint: string }) {
    console.log("ADD_FUNCTION_ENDPOINT_URL", payload);
    return <const>{
        type: ActionTypes.ADD_FUNCTION_ENDPOINT_URL,
        payload,
    }
}

export function handleDeleteFunctionEndPoint(payload: { endpoint: string }) {
    console.log("DELETE_FUNCTION_ENDPOINT_URL", payload);
    return <const>{
        type: ActionTypes.DELETE_FUNCTION_ENDPOINT_URL,
        payload,
    }
}

export function getfunctionEndPointList() {
    return {
        type: ActionTypes.GET_FUNCTION_ENDPOINT_URL,
        payload: [],
    };
}

export const updateEndPointList = (payload: {
    endPointlist: any;
}) => {
    return <const>{
        type: ActionTypes.UPDATE_ENDPOINT_FUNCTION_LISTS,
        payload,
    };
};

export const setEmptyValidationErrors = (payload: string[]) => {
    return <const>{
        type: ActionTypes.SET_EMPTY_VALIDATIONS_ERROR,
        payload,
    };
};