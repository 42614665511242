import { LineWithTriggerParams, FabricILWT, FabricOLWP } from './FabricLineWithTrigger';
import { fabric } from 'fabric';
import {
    fontSize,
    canvasIOInhibitColor,
    outputCircleRadius,
    triggerCircleRadius,
    fullFunctionPinLength,
    canvasAliasColor,
    canvasPrimaryTextColor,
} from '../UiConfig';
import FunctionTypeDetail from '../../../../transformers/AssetType/FunctionType';

export interface LineWithNameParams extends LineWithTriggerParams {
    text: string;
    isAlias?: boolean;
    asset?: FunctionTypeDetail;
    ioName?: string;
}

export class FabricILWN extends FabricILWT {
    lineText: fabric.IText;
    constructor(options: LineWithNameParams) {
        super(options);
        const { text, isAlias, asset, ioName } = options;
        // const circleTip = circleBefore ? this.ioCircle.left || 0 : (this.ioCircle.left || 0) + (this.ioCircle.radius || 0)
        const circleTip = this.ioCircle.left || 0;
        const pinText = text.length > 24 ? text.slice(0, 21) + '...' : text;
        this.lineText = new fabric.IText(pinText, {
            fontSize: fontSize * 0.8,
            // originX: circleBefore ? 'right' : 'left',
            originX: 'left',
            originY: 'center',
            // left: circleBefore ? circleTip - 10 : circleTip + 10,
            left: fullFunctionPinLength + 2 * outputCircleRadius,
            selectable: false,
            hoverCursor: 'default',
            fill: isAlias ? canvasAliasColor : canvasPrimaryTextColor,
            fontWeight: isAlias ? 'bold' : '',
            fontStyle: isAlias ? 'italic' : '',
        });
        this.lineText.data = { tooltip: text, asset, ioName };
    }
}

export class FabricOLWN extends FabricOLWP {
    lineText: fabric.Text;
    constructor(options: LineWithNameParams) {
        super(options);
        const { text, isAlias, asset, ioName } = options;

        const circleTip = this.ioCircle.left || 0;
        const pinText = text.length > 24 ? text.slice(0, 21) + '...' : text;
        this.lineText = new fabric.IText(pinText, {
            fontSize: fontSize * 0.8,
            // originX: 'left',
            originX: 'right',
            originY: 'center',
            // left: circleTip + 10,
            left: -outputCircleRadius,
            selectable: false,
            hoverCursor: 'default',
            fill: isAlias ? canvasAliasColor : canvasPrimaryTextColor,
            fontWeight: isAlias ? 'bold' : '',
            fontStyle: isAlias ? 'italic' : '',
        });
        this.lineText.data = { tooltip: text, asset, ioName };
    }
}

export class FabricALWN {
    constructor() {}
}
