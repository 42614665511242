import { configurationToolActionTypes } from '../configurationTool/actionTypes';
import { modelsPageActionTypes } from '../modelsPage/actionTypes';
import { exportDialogActionTypes } from '../exportAsset/actionTypes';
import { supportedConditionMonitors } from '../supportedConditionMonitors/actionTypes';
import { settingActionTypes } from '../settings/actionTypes';

export const ActionTypes = <const>{
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    SHOW_DIALOG: 'SHOW_DIALOG',
    HIDE_DIALOG: 'HIDE_DIALOG',
    SHOW_ALARM_MODAL: 'SHOW_ALARM_MODAL',
    HIDE_ALARM_MODAL: 'HIDE_ALARM_MODAL',
    TOGGLE_ACTIVE_VIEW: 'TOGGLE_ACTIVE_VIEW',
    HIDE_MESSAGE_MODAL: 'HIDE_MESSAGE_MODAL',
    SHOW_MESSAGE_MODAL: 'SHOW_MESSAGE_MODAL',
    TOGGLE_SPINNER_MODAL: 'TOGGLE_SPINNER_MODAL',
    SHOW_DISPATCH_MODAl: 'SHOW_DISPATCH_MODAl',
    CHECK_PASSWORD_TYPE: 'CHECK_PASSWORD_TYPE',
    NEW_MODEL_SAVE_AVAILABLE: 'NEW_MODEL_SAVE_AVAILABLE',
    CHECKED_LISTOF_ITEM_UPDATE:'CHECKED_LISTOF_ITEM_UPDATE',
    CHECKED_ALL_ITEMS:'CHECKED_ALL_ITEMS',
    SHOW_INSTANCES_MODAL:'SHOW_INSTANCES_MODAL',

    SHOW_GRID_DISPLAY: 'SHOW_GRID_DISPLAY',
    HIDE_GRID_DISPLAY: 'HIDE_GRID_DISPLAY',

    UPDATE_ASSET_CONFIG_MODEL: 'UPDATE_ASSET_CONFIG_MODEL',
    CLEAR_ASSET_CONFIG_MODEL_DETAILS: 'CLEAR_ASSET_CONFIG_MODEL_DETAILS',

    GET_ASSET_TABLE_DATA_API: 'GET_ASSET_TABLE_DATA_API',
    GET_ASSET_TABLE_DATA_STORE: 'GET_ASSET_TABLE_DATA_STORE',

    FETCH_ASSETS_LIST: 'FETCH_ASSETS_LIST',
    UPDATE_ASSETS_LIST: 'UPDATE_ASSETS_LIST',
    ADD_NEW_COMPUTE_MODEL: 'ADD_COMPUTE_MODEL',
    ADD_SAVE_AS_COMPUTE_MODEL: 'ADD_SAVE_AS_COMPUTE_MODEL',
    CHANGE_ACTIVE_MODEL: 'UPDATE_ACTIVE_MODEL',
    MARK_COMPUTE_MODEL_DIRTY: 'MARK_COMPUTE_MODEL_DIRTY',
    TOGGLE_EDIT_MODE: 'TOGGLE_EDIT_MODE',
    OBJECT_LIST_SEARCH: 'OBJECT_LIST_SEARCH',
    FUNCTION_LIST_SEARCH: 'FUNCTION_LIST_SEARCH',
    UPDATE_OVERALL_SEVERITY_FUNCTION_STATUS: 'UPDATE_OVERALL_SEVERITY_FUNCTION_STATUS',

    // Table
    TABLE_UPDATE_CANVAS_JSON: 'TABLE_UPDATE_CANVAS_JSON',
    ADD_ASSET_OBJECT_REQUEST: 'ADD_ASSET_OBJECT_REQUEST',
    ADD_ALARM_TRIGGER_TO_FUNCTION: 'ADD_ALARM_TRIGGER_TO_FUNCTION',
    EDIT_ALARM_TRIGGER_TO_FUNCTION: 'EDIT_ALARM_TRIGGER_TO_FUNCTION',
    EDIT_ALIAS_NAME_IN_MODELS: 'EDIT_ALIAS_NAME_IN_MODELS',
    EDIT_NODE_ID_OF_FUNCTION: "EDIT_NODE_ID_OF_FUNCTION",
    ADD_EVENT_TRIGGER_TO_FUNCTION: 'ADD_EVENT_TRIGGER_TO_FUNCTION',
    DELETE_ALARM_TRIGGER_TO_FUNCTION: 'DELETE_ALARM_TRIGGER_TO_FUNCTION',
    DELETE_EVENT_TRIGGER_TO_FUNCTION: 'DELETE_EVENT_TRIGGER_TO_FUNCTION',
    ADD_ASSET_OBJECT_SUCCESS: 'ADD_ASSET_OBJECT_SUCCESS',
    ADD_ASSET_OBJECT_FAILURE: 'ADD_ASSET_OBJECT_FAILURE',
    TABLE_IO_CONNECTION_REQUEST: 'TABLE_IO_CONNECTION_REQUEST',
    TABLE_IO_CONNECTION_SUCCESS: 'TABLE_IO_CONNECTION_SUCCESS',
    TABLE_IO_CONNECTION_FAILURE: 'TABLE_IO_CONNECTION_FAILURE',
    TABLE_CHECKBOX_CLICK: 'TABLE_CHECKBOX_CLICK',
    TABLE_REMOVE_IO_CONNECTION_REQUEST: 'TABLE_REMOVE_IO_CONNECTION_REQUEST',
    SAVE_COMPUTE_MODEL_API: 'SAVE_COMPUTE_MODEL_API',
    SAVE_COMPUTE_MODEL_STORE: 'SAVE_COMPUTE_MODEL_STORE',
    SAVE_COMPUTE_MODEL_API_ERROR: 'SAVE_COMPUTE_MODEL_API_ERROR',
    VIEW_UPDATE_SELECTED_SUPPORTED_TYPE: 'VIEW_UPDATE_SELECTED_SUPPORTED_TYPE',

    GET_COMPUTE_MODELS_API: 'GET_COMPUTE_MODELS_API',
    GET_COMPUTE_MODELS_STORE: 'GET_COMPUTE_MODELS_STORE',
    DELETE_COMPUTE_MODEL_API: 'DELETE_COMPUTE_MODEL_API',
    DELETE_COMPUTE_MODEL_STORE: 'DELETE_COMPUTE_MODEL_STORE',
    DELETE_COMPUTE_MODEL_API_ERROR: 'DELETE_COMPUTE_MODEL_API_ERROR',
    UPDATE_ACTIVE_MODEL_JSON: 'UPDATE_ACTIVE_MODEL_JSON',
    GET_MODEL_USED_BY_INSTANCES: 'GET_MODEL_USED_BY_INSTANCES',
    UPDATE_MODEL_USED_BY_INSTANCES: 'UPDATE_MODEL_USED_BY_INSTANCES',
    HANDLE_SIDEBAR_TOGGLE: 'HANDLE_SIDEBAR_TOGGLE',
    GET_ALL_PERMISSION_MODEL: 'GET_ALL_PERMISSION_MODEL',
    UPDATE_MODEL_PERMISSION: 'UPDATE_MODEL_PERMISSION',
    UPDATE_LATEST_FUNCTION_DATA_PAYLOAD: 'UPDATE_LATEST_FUNCTION_DATA_PAYLOAD',
    UPDATE_CURRENT_SELECTED_FUNCTION_WITH_LATEST_DATA:
        'UPDATE_CURRENT_SELECTED_FUNCTION_WITH_LATEST_DATA',

    // Asset Reducer
    ASSET_CATEGORY_LIST_REQUEST: 'ASSET_CATEGORY_LIST_REQUEST',
    ASSET_CATEGORY_LIST_SUCCESS: 'ASSET_CATEGORY_LIST_SUCCESS',
    ASSET_CATEGORY_LIST_FAILURE: 'ASSET_CATEGORY_LIST_FAILURE',
    RESET_ASSETS_FILTER: 'RESET_ASSETS_FILTER',
    ASSET_CATEGORY_SELECTION: 'ASSET_CATEGORY_SELECTION',

    GET_SYSTEM_STRUCTURE_MODELS: 'GET_SYSTEM_STRUCTURE_MODELS',
    GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS: 'GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS',
    GET_SYSTEMS_BASED_ON_CONNECT_MODEL: 'GET_SYSTEMS_BASED_ON_CONNECT_MODEL',
    UPDATE_SYSTEM_STRUCTURE_DETAILS: 'UPDATE_SYSTEM_STRUCTURE_DETAILS',
    UPDATE_CONNECT_DETAILS_FROM_IDENTITY_MODELS: 'UPDATE_CONNECT_DETAILS_FROM_IDENTITY_MODELS',
    UPDATE_GROUP_BY: 'UPDATE_GROUP_BY',
    UPDATE_STRUCTURE: 'UPDATE_STRUCTURE',
    UPDATE_SYSTEM: 'UPDATE_SYSTEM',
    GET_NEW_SYSTEM_STRUCTURES_REQUEST: 'GET_NEW_SYSTEM_STRUCTURES_REQUEST',
    HANDLE_NEW_TREE_LIST_NODE_TOGGLE: 'HANDLE_NEW_TREE_LIST_NODE_TOGGLE',
    GET_NEW_ASSET_INSTANCE_LIST_REQUEST: 'GET_NEW_ASSET_INSTANCE_LIST_REQUEST',
    GET_NEW_ASSET_INSTANCE_LIST_REQUEST_UPDATE: 'GET_NEW_ASSET_INSTANCE_LIST_REQUEST_UPDATE',
    SEARCH_ASSET: 'SEARCH_ASSET',
    HANDLE_UNIQUE_TYPEID_FUNCTION: 'HANDLE_UNIQUE_TYPEID_FUNCTION',
    VIEW_SAVE_INSTANCE_COMPUTE_MODEL_UPDATE: 'VIEW_SAVE_INSTANCE_COMPUTE_MODEL_UPDATE',

    GET_ALL_PERMISSION_ASSET: 'GET_ALL_PERMISSION_ASSET',
    UPDATE_ASSET_PERMISSION: 'UPDATE_ASSET_PERMISSION',
    GET_FILTER_ASSET_MODEL_TYPE_REQUEST: 'GET_FILTER_ASSET_MODEL_TYPE_REQUEST',
    UPDATE_FILTER_ASSET_MODEL_TYPE: 'UPDATE_FILTER_ASSET_MODEL_TYPE',
    UPDATE_FILTER_ASSET_QUERY: 'UPDATE_FILTER_ASSET_QUERY',
    UPDATE_SELECTED_PARENT_TREE_NODE: 'UPDATE_SELECTED_PARENT_TREE_NODE',
    UPDATE_SELECTED_NODE_PARENT_LIST: 'UPDATE_SELECTED_NODE_PARENT_LIST',

    GET_SYSTEMS_REQUEST: 'GET_SYSTEMS_REQUEST',
    GET_SYSTEMS_SUCCESS: 'GET_SYSTEMS_SUCCESS',
    GET_SYSTEMS_FAILURE: 'GET_SYSTEMS_FAILURE',
    GET_EDGES_REQUEST: 'GET_EDGES_REQUEST',
    GET_EDGES_SUCCESS: 'GET_EDGES_SUCCESS',
    GET_EDGES_FAILURE: 'GET_EDGES_FAILURE',
    GET_CONNECTIVITY_EDGES_REQUEST: 'GET_CONNECTIVITY_EDGES_REQUEST',
    GET_CONNECTIVITY_EDGES_SUCCESS: 'GET_CONNECTIVITY_EDGES_SUCCESS',
    GET_CONNECTIVITY_EDGES_FAILURE: 'GET_CONNECTIVITY_EDGES_FAILURE',
    GET_CLEANUP_INSTANCES_REQUEST: 'GET_CLEANUP_INSTANCES_REQUEST',
    GET_CLEANUP_INSTANCES_SUCCESS: 'GET_CLEANUP_INSTANCES_SUCCESS',
    GET_CLEANUP_INSTANCES_FAILURE: 'GET_CLEANUP_INSTANCES_FAILURE',
    REMOVE_CLEANUP_INSTANCES: 'REMOVE_CLEANUP_INSTANCES',
    REMOVE_CLEANUP_INSTANCES_LOADING:'REMOVE_CLEANUP_INSTANCES_LOADING',
    GET_OBJECT_BY_EDGES_REQUEST: 'GET_OBJECT_BY_EDGES_REQUEST',
    GET_OBJECT_BY_EDGES_SUCCESS: 'GET_OBJECT_BY_EDGES_SUCCESS',
    GET_OBJECT_BY_EDGES_FAILURE: 'GET_OBJECT_BY_EDGES_FAILURE',
    GET_STRUCTURES_REQUEST: 'GET_STRUCTURES_REQUEST',
    GET_STRUCTURES_SUCCESS: 'GET_STRUCTURES_SUCCESS',
    GET_STRUCTURES_FAILURE: 'GET_STRUCTURES_FAILURE',
    GET_SYSTEM_STRUCTURES_REQUEST: 'GET_SYSTEM_STRUCTURES_REQUEST',
    GET_SYSTEM_STRUCTURES_SUCCESS: 'GET_SYSTEM_STRUCTURES_SUCCESS',

    GET_SYSTEM_STRUCTURES_FAILURE: 'GET_SYSTEM_STRUCTURES_FAILURE',
    GET_ASSET_INSTANCE_LIST_REQUEST: 'GET_ASSET_INSTANCE_LIST_REQUEST',
    GET_LOADED_MODEL: 'GET_LOADED_MODEL',
    GET_ASSET_INSTANCE_LIST_SUCCESS: 'GET_ASSET_INSTANCE_LIST_SUCCESS',
    GET_ASSET_INSTANCE_LIST_FAILURE: 'GET_ASSET_INSTANCE_LIST_FAILURE',
    UPDATE_SELECTED_TREE_NODE: 'UPDATE_SELECTED_TREE_NODE',
    UPDATE_TREE_LIST_NODES: 'UPDATE_TREE_LIST_NODES',
    HANDLE_TREE_LIST_NODE_TOGGLE: 'HANDLE_TREE_LIST_NODE_TOGGLE',
    TOGGLE_ASSET_PAGE: 'TOGGLE_ASSET_PAGE',
    TOGGLE_ASSET_PAGE_FOR_UPDATE: 'TOGGLE_ASSET_PAGE_FOR_UPDATE',
    SAVE_INSTANCE_COMPUTE_MODEL_UPDATE: 'SAVE_INSTANCE_COMPUTE_MODEL_UPDATE',

    UPDATE_NORMALIZED_TREE_LIST_NODE: 'UPDATE_NORMALIZED_TREE_LIST_NODE',
    HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST: 'HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST',
    HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_SUCCESS: 'HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_SUCCESS',
    HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_FAILURE: 'HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_FAILURE',
    HANDLE_ENABLE_BUTTON_CLICK: 'HANDLE_ENABLE_BUTTON_CLICK',
    HANDLE_INSTANCE_MONITORING: 'HANDLE_INSTANCE_MONITORING',
    UPDATE_SELECTED_MENU_ITEM: 'UPDATE_SELECTED_MENU_ITEM',
    UPDATE_INSTANCE_MONITORING_LIST: 'UPDATE_INSTANCE_MONITORING_LIST',
    START_CRITICALITY_MONITORING: 'START_CRITICALITY_MONITORING',
    UPDATE_SELECTED_SUPPORTED_TYPE_MODEl:'UPDATE_SELECTED_SUPPORTED_TYPE_MODEl',
    CHECKED_LISTOF_ITEMS:'CHECKED_LISTOF_ITEMS',
    SAVE_INSTANCE_COMPUTE_MODEL_CHANGES_UPDATE: 'SAVE_INSTANCE_COMPUTE_MODEL_CHANGES_UPDATE',
    UPDATE_SUPPORTED_TYPES: 'UPDATE_SUPPORTED_TYPES',
    UPDATE_SELECTED_SUPPORTED_TYPE: 'UPDATE_SELECTED_SUPPORTED_TYPE',
    UPDATE_PREVIOUS_SELECTED_TYPES: 'UPDATE_PREVIOUS_SELECTED_TYPES',
    HANDLE_ASSET_TABLE_LOADING: 'HANDLE_ASSET_TABLE_LOADING',
    UPDATE_ASSET_PAGE_STATUS: 'UPDATE_ASSET_PAGE_STATUS',
    UPDATE_ASSET_PAGE_STATUS_UPDATE: 'UPDATE_ASSET_PAGE_STATUS_UPDATE',
    UPDATE_MODEL_STATUS: 'UPDATE_MODEL_STATUS',
    GET_CONFIGURED_INSTANCE_JSON: 'GET_CONFIGURED_INSTANCE_JSON',
    HANDLE_INSTANCE_CONFIG_LOADING: 'HANDLE_INSTANCE_CONFIG_LOADING',
    GET_SELECTED_TYPE_COMPUTE_MODEL_FROM_JSON: 'GET_SELECTED_TYPE_COMPUTE_MODEL_FROM_JSON',
    UPDATE_SELECTED_TYPE_JSON: 'UPDATE_SELECTED_TYPE_JSON',
    UPDATE_ASSET_CONFIG_MODAL_FIELDS: 'UPDATE_ASSET_CONFIG_MODAL_FIELDS',
    UPDATE_ACTIVE_MODEL_TYPE_PARAMS: 'UPDATE_ACTIVE_MODEL_TYPE_PARAMS',
    HANDLE_CONFIGURED_SAVE_INSTANCE: 'HANDLE_CONFIGURED_SAVE_INSTANCE',

    UPDATE_ACTIVE_MODEL_DATA: 'UPDATE_ACTIVE_MODEL_DATA',
    UPDATE_ACTIVE_MODEL_DETAILS_FOR_IMPORT: 'UPDATE_ACTIVE_MODEL_DETAILS_FOR_IMPORT',
    UPDATE_SUPPORTED_MODEL_DATA: 'UPDATE_SUPPORTED_MODEL_DATA',

    GET_ALL_COMPUTE_MODELS_API: 'GET_ALL_COMPUTE_MODELS_API',
    GET_ALL_COMPUTE_MODELS: 'GET_ALL_COMPUTE_MODELS_API',
    GET_ALL_COMPUTE_MODELS_STORE: 'GET_ALL_COMPUTE_MODELS_STORE',
    UPDATE_ASSET_CRITICALITY_MODELS: 'UPDATE_ASSET_CRITICALITY_MODELS',
    UPDATE_SELECTED_CRITICALITY_MODEL: 'UPDATE_SELECTED_CRITICALITY_MODEL',
    GET_MATCHING_COMPUTE_MODELS_API: 'GET_MATCHING_COMPUTE_MODELS_API',
    GET_MATCHING_COMPUTE_MODELS_STORE: 'GET_MATCHING_COMPUTE_MODELS_STORE',
    CHANGE_ACTIVE_MODEL_INSTANCE_CONFIG: 'CHANGE_ACTIVE_MODEL_INSTANCE_CONFIG',
    SAVE_INSTANCE_COMPUTE_MODEL: 'SAVE_INSTANCE_COMPUTE_MODEL',
    DELETE_INSTANCE_COMPUTE_MODEL: 'DELETE_INSTANCE_COMPUTE_MODEL',
    ADD_TO_SELECTED_INSTANCE: 'ADD_TO_SELECTED_INSTANCE',
    REMOVE_FROM_SELECTED_INSTANCE: 'REMOVE_FROM_SELECTED_INSTANCE',
    UPDATE_ACTIVE_TAB_INSTANCE_CONFIG: 'UPDATE_ACTIVE_TAB_INSTANCE_CONFIG',
    CHANGE_ACTIVE_PAGE_INSTANCE_CONFIG: 'CHANGE_ACTIVE_PAGE_INSTANCE_CONFIG',
    CHANGE_ACTIVE_PAGE_INSTANCE_UPDATE: 'CHANGE_ACTIVE_PAGE_INSTANCE_UPDATE',
    CHANGE_ENTERIES_PER_PAGE_INSTANCE_CONFIG: 'HANGE_ENTERIES_PER_PAGE_INSTANCE_CONFIG',
    HANLDE_FILTER_ASSET_TABLE_DATA: 'HANLDE_FILTER_ASSET_TABLE_DATA',
    HANLDE_SORT_ASSET_TABLE_DATA: 'HANDLE_SORT_ASSET_TABLE_DATA',
    HANDLE_CONFIGURED_INSTANCE_JSON: 'HANDLE_CONFIGURED_INSTANCE_JSON',
    HANDLE_LOADING_CONFIGURED_INSTANCE: 'HANDLE_LOADING_CONFIGURED_INSTANCE',
    HANDLE_ASSET_TOGGLE_UNCONFIGURED_INSTANCE: 'HANDLE_ASSET_TOGGLE_UNCONFIGURED_INSTANCE',
    SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS: 'SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS',
    SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR: 'SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR',
    DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS: 'DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS',
    DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR: 'DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR',
    HANDLE_VIEW_MODE_CHANGE_INSTANCE_CONFIG: 'HANDLE_VIEW_MODE_CHANGE_INSTANCE_CONFIG',
    HANDLE_INSTANCE_DROP_ACTION: 'HANDLE_INSTANCE_DROP_ACTION',
    TABLE_UPDATE_CANVAS_JSON_INSTANCE_CONFIG: 'TABLE_UPDATE_CANVAS_JSON_INSTANCE_CONFIG',
    HANDLE_CONFIGURE_BUTTON_CLICK: 'HANDLE_CONFIGURE_BUTTON_CLICK',
    ADD_ASSET_OBJECT_REQUEST_INSTANCE_CONFIG: 'ADD_ASSET_OBJECT_REQUEST_INSTANCE_CONFIG',
    ADD_ASSET_OBJECT_SUCCESS_INSTANCE_CONFIG: 'ADD_ASSET_OBJECT_SUCCESS_INSTANCE_CONFIG',
    ADD_ASSET_OBJECT_FAILURE_INSTANCE_CONFIG: 'ADD_ASSET_OBJECT_FAILURE_INSTANCE_CONFIG',
    TABLE_IO_CONNECTION_REQUEST_INSANCE_CONFIG: 'TABLE_IO_CONNECTION_REQUEST_INSANCE_CONFIG',
    TABLE_IO_CONNECTION_SUCCESS_INSANCE_CONFIG: 'TABLE_IO_CONNECTION_SUCCESS_INSANCE_CONFIG',
    TABLE_IO_CONNECTION_FAILURE_INSANCE_CONFIG: 'TABLE_IO_CONNECTION_FAILURE_INSANCE_CONFIG',
    TABLE_CHECKBOX_CLICK_INSANCE_CONFIG: 'TABLE_CHECKBOX_CLICK_INSANCE_CONFIG',
    TABLE_REMOVE_IO_CONNECTION_REQUEST_INSANCE_CONFIG:
        'TABLE_REMOVE_IO_CONNECTION_REQUEST_INSANCE_CONFIG',
    GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_REQUEST:
        'GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_REQUEST',
    GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_SUCCESS:
        'GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_SUCCESS',
    GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_FAILURE:
        'GET_LIBRARY_REFERENCES_FOR_FUNCTION_TYPES_FAILURE',
    UPDATE_IDENTITY_CONFIG_STATUS: 'UPDATE_IDENTITY_CONFIG_STATUS',
    GET_IDENTITY_CONFIG_STATUS: 'GET_IDENTITY_CONFIG_STATUS',
    UPDATE_IS_MULTIPLE_MODIFY: 'UPDATE_IS_MULTIPLE_MODIFY',

    // functions
    CREATE_FUNCTION: 'CREATE_FUNCTION',
    DRAG_DROP_FUNCTION: 'DRAG_DROP_FUNCTION',
    GET_FUNCTION_TYPES: 'GET_FUNCTION_TYPES',
    SET_FUNCTION_TYPES: 'SET_FUNCTION_TYPES',
    ERROR_GET_FUNCTION_TYPES: 'ERROR_GET_FUNCTION_TYPES',
    GET_SELECTED_FUNCTION_TYPE: 'GET_SELECTED_FUNCTION_TYPE',
    UPDATE_SELECTED_FUNCTION_TYPE: 'UPDATE_SELECTED_FUNCTION_TYPE',
    HANDLE_FUNCTION_DELETE: 'HANDLE_FUNCTION_DELETE',
    SUCCESS_FUNCTION_DELETE: 'SUCCESS_FUNCTIO_DELETE',
    ERROR_FUNCTION_DELETE: 'ERROR_FUNCTION_DELETE',
    SAVE_FUNCTION: 'SAVE_FUNCTION',
    ERROR_LOADING_DATA: 'ERROR_SAVE_FUNCTION',
    SUCCESS_LOADING_DATA: 'SUCCESS_SAVE_FUNCTION',
    SHOW_NOTIFICATION_MODAL: 'SHOW_NOTIFICATION_MODAL',
    HIDE_NOTIFICATIOM_MODAL: 'HIDE_NOTIFICATIOM_MODAL',
    UPDATE_FUNC_ASSETS_INFO: 'UPDATE_FUNC_ASSETS_INFO',
    HANDLE_POPOVER_OPEN: 'HANDLE_POPOVER_OPEN',
    HANDLE_POPOVER_INFO: 'HANDLE_POPOVER_INFO',
    UPDATE_FUNCTION_HEADER_TYPE_DETAILS: 'UPDATE_FUNCTION_HEADER_TYPE_DETAILS',
    UPDATE_FUNCTION_HEADER_TYPE_STATUS_DETAILS: 'UPDATE_FUNCTION_HEADER_TYPE_STATUS_DETAILS',
    UPDATE_CONDITION_DELETE_STATUS: 'UPDATE_CONDITION_DELETE_STATUS',
    UPDATE_CALCULATION_DELETE_STATUS: 'UPDATE_CALCULATION_DELETE_STATUS',
    HANDLE_FUNCTION_SAVE: 'HANDLE_FUNCTION_SAVE',
    HANDLE_ASSETS_INFO: 'HANDLE_ASSETS_INFO',
    UPDATE_FUNCTION_ENDPOINT: 'UPDATE_FUNCTION_ENDPOINT',
    HANDLE_FUNCTION_MODE: 'HANDLE_FUNCTION_MODE',
    SUCCESS_FUNCTION_SAVE: 'SUCCESS_FUNCTION_SAVE',
    CHECK_CALC_FIELD_VALIDATION: 'CHECK_CALC_FIELD_VALIDATION',
    HANDLE_IS_FUNCTION_DIRTY: 'HANDLE_IS_FUNCTION_DIRTY',
    HANDLE_FUNCTION_ERROR_FLAG: 'HANDLE_FUNCTION_ERROR_FLAG',
    

    GET_FUNCTION_ENDPOINT_URL: 'GET_FUNCTION_ENDPOINT_URL',

    UPDATE_ENDPOINT_FUNCTION_LISTS: 'UPDATE_ENDPOINT_FUNCTION_LISTS',
    SET_EMPTY_VALIDATIONS_ERROR: 'SET_EMPTY_VALIDATIONS_ERROR',
    DELETE_FUNCTION_ENDPOINT_URL: 'DELETE_FUNCTION_ENDPOINT_URL',

    ADD_FUNCTION_ENDPOINT_URL: 'ADD_FUNCTION_ENDPOINT_URL',

    GET_ALL_PERMISSION_FUNCTION: 'GET_ALL_PERMISSION_FUNCTION',
    UPDATE_FUNCTION_PERMISSION: 'UPDATE_FUNCTION_PERMISSION',

    DEFAULT_VALUE_TOOL: 'DEFAULT_VALUE_TOOL',
    DEFAULT_VALUE_INSTANCE_CONFIG: 'DEFAULT_VALUE_INSTANCE_CONFIG',
    HANDLE_INCLUDE_ALL_CHILD_ELEMENTS: 'HANDLE_INCLUDE_ALL_CHILD_ELEMENTS',
    HANDLE_TABLE_TOTAL_RECORDS: 'HANDLE_TABLE_TOTAL_RECORDS',
    UPDATE_ALARM_MODAL_DRAG_DROP_TABLES: 'UPDATE_ALARM_MODAL_DRAG_DROP_TABLES',
    RESET_PAGINATION_LOADER: 'RESET_PAGINATION_LOADER',
    GET_ALARM_TYPE: 'GET_ALARM_TYPE',
    SET_ALARM_TYPE: 'SET_ALARM_TYPE',
    UPDATE_SELECTED_ALARM_TYPE: 'UPDATE_SELECTED_ALARM_TYPE',
    UPDATE_ALARM_MAPPING_DETAILS: 'UPDATE_ALARM_MAPPING_DETAILS',
    UPDATE_TAB_NAV_LIST: 'UPDATE_TAB_NAV_LIST',
    CLEAR_DROP_TABLE_MAPPING: 'CLEAR_DROP_TABLE_MAPPING',
    UPDATE_ACTIVE_PARENT_KEY: 'UPDATE_ACTIVE_PARENT_KEY',

    UPDATE_ASSET_CONFIG_LOADING: 'UPDATE_ASSET_CONFIG_LOADING',
    UPDATE_ASSET_BUTTON_LOADING: 'UPDATE_ASSET_BUTTON_LOADING',
    UPDATE_ORIGINAL_TYPE_JSON: 'UPDATE_ORIGINAL_TYPE_JSON',
    UPDATE_VERSION_VARIABLE: 'UPDATE_VERSION_VARIABLE',
    TOGGLE_LOADER_TEXT: 'TOGGLE_LOADER_TEXT',
    UPDATE_INSTANCE_TYPE_JSON_CONDITION_DETAILS: 'UPDATE_INSTANCE_TYPE_JSON_CONDITION_DETAILS',
    HANDLE_INSTANCE_TYPE_ORIGINAL_FUNC_LOADING: 'HANDLE_INSTANCE_TYPE_ORIGINAL_FUNC_LOADING',

    ...configurationToolActionTypes,
    ...modelsPageActionTypes,
    ...exportDialogActionTypes,
    ...supportedConditionMonitors,
    ...settingActionTypes,

    // LOGIN ACTION TYPES
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    INITIALISE_LOGIN_PROVIDER: 'INITIALISE_LOGIN_PROVIDER',
    HANDLE_USER_LOGIN: 'HANDLE_USER_LOGIN',
    UPDATE_IS_LOGGED_IN: 'UPDATE_IS_LOGGED_IN',
    HANDLE_LOADING_STATUS: 'HANDLE_LOADING_STATUS',
    HANDLE_LOGIN_LOADING: 'HANDLE_LOGIN_LOADING',
    REDIRECT_LOGIN: 'REDIRECT_LOGIN',
    HANDLE_ERROR_LOGIN: 'HANDLE_ERROR_LOGIN',
    //----------//
    CUSTOM_VARIABLE_DELETE: 'CUSTOM_VARIABLE_DELETE',
    HANDLE_ASSET_TABLE_LOADING_UPDATE: 'HANDLE_ASSET_TABLE_LOADING_UPDATE',
    UPDATE_EDGE_LIST: 'UPDATE_EDGE_LIST',
    RESTORE_EDGE: 'RESTORE_EDGE',
    GET_INFO_MODEL_INSTANCES: 'GET_INFO_MODEL_INSTANCES',
    UPDATE_INFO_MODEL_INSTANCE: 'UPDATE_INFO_MODEL_INSTANCE',
    SAVE_INFO_MODEL_INSTANCE: 'SAVE_INFO_MODEL_INSTANCE',
    DELETE_INFO_MODEL_INSTANCE: 'DELETE_INFO_MODEL_INSTANCE',
    GET_TYPE_DEFINITION_REQUEST: 'GET_TYPE_DEFINITION_REQUEST',
    UPDATE_NEW_DEFINITION_TYPE: 'UPDATE_NEW_DEFINITION_TYPE',
    SHOW_PROGRESS: 'SHOW_PROGRESS',
    HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE: 'HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE',
    UPDATE_TYPE_JSON_CONDITION_DETAILS: 'UPDATE_TYPE_JSON_CONDITION_DETAILS',
    UPDATE_ORIIGNAL_COMPUTE_MODELS: 'UPDATE_ORIIGNAL_COMPUTE_MODELS',
    MUTUALLY_EXCLUSIVE_FUNCTIONS_REQUEST: 'MUTUALLY_EXCLUSIVE_FUNCTIONS_REQUEST',
    MUTUALLY_EXCLUSIVE_FUNCTIONS_SUCCESS: 'MUTUALLY_EXCLUSIVE_FUNCTIONS_SUCCESS',
    MUTUALLY_EXCLUSIVE_FUNCTIONS_FAILURE: 'MUTUALLY_EXCLUSIVE_FUNCTIONS_FAILURE',
    ADD_FUNCTIONS_TO_NEW_LIBRARY_REQUEST: 'ADD_FUNCTIONS_TO_NEW_LIBRARY_REQUEST',
    ADD_FUNCTIONS_TO_NEW_LIBRARY_SUCCESS: 'ADD_FUNCTIONS_TO_NEW_LIBRARY_SUCCESS',
    ADD_FUNCTIONS_TO_NEW_LIBRARY_FAILURE: 'ADD_FUNCTIONS_TO_NEW_LIBRARY_FAILURE',
    GET_AVAILABLE_TYPE_IDS: 'GET_AVAILABLE_TYPE_IDS',
    UPDATE_AVAILABLE_TYPE_IDS: 'UPDATE_AVAILABLE_TYPE_IDS',
    GET_LIBRARY_AND_NODES_REQUEST: 'GET_LIBRARY_AND_NODES_REQUEST',
    GET_LIBRARY_AND_NODES_SUCCESS: 'GET_LIBRARY_AND_NODES_SUCCESS',
    GET_LIBRARY_AND_NODES_FAILURE: 'GET_LIBRARY_AND_NODES_FAILURE',
    GET_LIBRARY_DETAILS_REQUEST: 'GET_LIBRARY_DETAILS_REQUEST',
    GET_LIBRARY_DETAILS_SUCCESS: 'GET_LIBRARY_DETAILS_SUCCESS',
    GET_LIBRARY_DETAILS_FAILURE: 'GET_LIBRARY_DETAILS_FAILURE',
    DELETE_LIBRARY_REQUEST: 'DELETE_LIBRARY_REQUEST',
    DELETE_LIBRARY_SUCCESS: 'DELETE_LIBRARY_SUCCESS',
    DELETE_LIBRARY_FAILURE: 'DELETE_LIBRARY_FAILURE',
    RENAME_LIBRARY_REQUEST: 'RENAME_LIBRARY_REQUEST',
    RENAME_LIBRARY_SUCCESS: 'RENAME_LIBRARY_SUCCESS',
    RENAME_LIBRARY_ERROR: 'RENAME_LIBRARY_ERROR',
    REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_REQUEST:
        'REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_REQUEST',
    REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_SUCCESS:
        'REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_SUCCESS',
    REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_FAILURE:
        'REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_FAILURE',
    ADD_FUNCTIONS_TO_EXISTING_LIBRARY_REQUEST: 'ADD_FUNCTIONS_TO_EXISTING_LIBRARY_REQUEST',
    ADD_FUNCTIONS_TO_EXISTING_LIBRARY_SUCCESS: 'ADD_FUNCTIONS_TO_EXISTING_LIBRARY_SUCCESS',
    ADD_FUNCTIONS_TO_EXISTING_LIBRARY_FAILURE: 'ADD_FUNCTIONS_TO_EXISTING_LIBRARY_FAILURE',
    GET_EXPORTABLE_FUNCTIONS_REQUEST: 'GET_EXPORTABLE_FUNCTIONS_REQUEST',
    GET_EXPORTABLE_FUNCTIONS_SUCCESS: 'GET_EXPORTABLE_FUNCTIONS_SUCCESS',
    GET_EXPORTABLE_FUNCTIONS_FAILURE: 'GET_EXPORTABLE_FUNCTIONS_FAILURE',
    GET_DECRYPTED_FUNCTION_TYPE_REQUEST: 'GET_DECRYPTED_FUNCTION_TYPE_REQUEST',
    GET_DECRYPTED_FUNCTION_TYPE_SUCCESS: 'GET_DECRYPTED_FUNCTION_TYPE_SUCCESS',
    GET_DECRYPTED_FUNCTION_TYPE_FAILURE: 'GET_DECRYPTED_FUNCTION_TYPE_FAILURE',
    TOGGLE_TYPE_ID_DURING_IMPORT: 'TOGGLE_TYPE_ID_DURING_IMPORT',
    SET_AUTHORIZE: 'SET_AUTHORIZE',
    SET_GLOBAL_LIBRARY_ERROR: 'SET_GLOBAL_LIBRARY_ERROR',
    ENCRYPT_LIBRARY_REQUEST: 'ENCRYPT_LIBRARY_REQUEST',
    ENCRYPT_LIBRARY_SUCCESS: 'ENCRYPT_LIBRARY_SUCCESS',
    ENCRYPT_LIBRARY_FAILURE: 'ENCRYPT_LIBRARY_FAILURE',
    SET_DRAGGED_AND_DROPPED_LIBRARY: 'SET_DRAGGED_AND_DROPPED_LIBRARY',
    HANDLE_CHANGE_LIBRARY_PASSWORD: 'HANDLE_CHANGE_LIBRARY_PASSWORD',
    HANDLE_CHANGE_PASSWORD_LOADING: 'HANDLE_CHANGE_PASSWORD_LOADING',
    HANDLE_LIB_PASSWORD_ERROR: 'HANDLE_LIB_PASSWORD_ERROR',
    RESET_LIB_PASSWORD_ERROR: 'RESET_LIB_PASSWORD_ERROR',
    UNLOCK_LIBRARY: 'UNLOCK_LIBRARY',
    HANDLE_PASSWORD_BTN_LOADING: 'HANDLE_PASSWORD_BTN_LOADING',
    HANDLE_GET_LIBRARY_AND_NODES_LOADING: 'HANDLE_GET_LIBRARY_AND_NODES_LOADING',
    HANDLE_SELECTED_FUNCTION_LOADING: 'HANDLE_SELECTED_FUNCTION_LOADING',
    SET_DELETING_LIBRARY_DETAILS: 'SET_DELETING_LIBRARY_DETAILS',
    HANDLE_LIBRARIES_IMPORT: 'HANDLE_LIBRARIES_IMPORT',
    UPDATE_ORIGINAL_FUNCTION_DETAIL: 'UPDATE_ORIGINAL_FUNCTION_DETAIL',

    SET_ZOOM: 'SET_ZOOM',
    GET_DECRYPTED_PASSWORD_TYPE_REQUEST: 'GET_DECRYPTED_PASSWORD_TYPE_REQUEST',

    //alarm
    SET_NEW_ALARM_DETAILS: 'SET_NEW_ALARM_DETAILS',
    ADD_NEW_ALARM_TO_CANVAS: 'ADD_NEW_ALARM_TO_CANVAS',
};
