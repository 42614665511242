import { put, takeLatest, select, call } from 'redux-saga/effects';
import { sce, AbilityService } from 'sce-engg-model-19.09';

import {
    mutuallyExclusiveFunctionTypesSuccess,
    getMutuallyExclusiveFunctionTypesFailure,
    addFunctionsToNewLibraryRequest,
    addFunctionsToNewLibrarySuccess,
    addFunctionsToNewLibraryFailure,
    getLibraryAndNodesSuccess,
    getLibraryAndNodesFailure,
    deleteLibraryRequest,
    deleteLibraryFailure,
    deleteLibrarySuccess,
    removeFunctionsFromExistingLibraryRequest,
    removeFunctionsFromExistingLibrarySuccess,
    removeFunctionsFromExistingLibraryFailure,
    addFunctionsToExistingLibraryFailure,
    addFunctionsToExistingLibrarySuccess,
    addFunctionsToExistingLibraryRequest,
    getLibraryAndNodesRequest,
    getExportableFunctionsRequest,
    getExportableFunctionsSuccess,
    getExportableFunctionsFailure,
    getSelectedFunctionType,
    setGlobalLibraryError,
    encryptLibraryRequest,
    encryptLibrarySuccess,
    encryptLibraryFailure,
    setDragAndDroppedLibrary,
    handleChangeLibraryPassword,
    handleChangePasswordLoading,
    handleLibPasswordError,
    unlockLibrary,
    handlePasswordBtnLoading,
    handleGetLibraryAndNodesLoading,
    handleSelectedFunctionLoading,
    handleLibrariesImport,
    renameLibraryRequest,
    renameLibrarySuccess,
    renameLibraryError,
    handleUniqueLibraryFailed,
} from '../action';
import { StoreState } from '../..';
import {
    isPresentInFunctions,
    generateAndSaveZipFileToSystem,
    findActiveIndexForFunctionsLibrary,
    convertFuncDetailsToFuncType,
} from '../../../routes/Functions/helper';
import { hideModal, showModal } from '../../modal/action';
import { ActionTypes } from '../../actionTypes';
import { LibraryAndNodePayload, LibraryDraggedDetails, UpdateActiveFunctionType } from '../types';
import { abbSDKGetErrorMessage, abbSDKGetLibraryError, getUpdatedLibraryWithNodes, isNilOrEmpty } from '../../../utils/helpers';
import { showNotificationModal } from '../../notificationModal/action';
import {
    NOTIFICATION_MODAL_STATUS,
    CONFIGURATION_TOOL_MODE,
    LIBRARY,
    ROUTE_PATHNAME,
    TYPE_MSG,
    GENERIC_FUNCTIONS,
} from '../../../utils/constants/appConstants';
import { FunctionsLibraries, TypesWithLibraries } from '../../exportAsset/types';
import _ from 'lodash';
import {
    toggleModelSpinner,
    hideImportModal,
    showSummaryModal,
    setDetailsForTypeLevelImport,
} from '../../exportAsset/actions';
import { ComputeModelFromJson } from '../../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import {
    updateConfigurationToolMode,
    createConfigurationToolJson,
} from '../../configurationTool/actions';
import { saveComputeModelStore } from '../../modelsPage/actions';
import { UI } from 'sce-engg-model-19.09/lib/interfaces/IPProtection';
import PreImportLibraryValidationModal from '../../../routes/Functions/PreImportLibraryValidationModal';
import { CREATE_NEW_MODEL } from '../../../utils/constants/uiConstants';
import PreImportTypeValidationModal from '../../../components/ExportDialog/PreImportTypeValidationModal';

function* mutuallyExclusiveFunctionTypes() {
    try {
        const res = yield sce.getMutuallyExclusiveFunctionTypes();
        yield put(mutuallyExclusiveFunctionTypesSuccess(res.details));
    } catch (error) {
        yield put(getMutuallyExclusiveFunctionTypesFailure());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [error.response.data[0].details],
            })
        );
    }
}
function* addFunctionsToNewLibrarySaga(action: ReturnType<typeof addFunctionsToNewLibraryRequest>) {
    const state: StoreState = yield select();
    const functionWithNodesList = state.functions.libraryAndFunctionsList;
    try {
        const libDetails = _.cloneDeepWith(action.payload);
        delete libDetails.withIPProtection;

        if (action.payload.withIPProtection === false) {
            libDetails.password = null as any;
        }

        const isLibraryExist = yield sce.verifyLibraryExists(libDetails.libraryName);
        if(isLibraryExist.details && isLibraryExist.details.isExist) {
            yield put(handleUniqueLibraryFailed(true));
        } else {
            const res = yield sce.createLibrary(
            libDetails.libraryName,
            libDetails.password,
            action.payload.withIPProtection
        );
        if (res.status === 200) {
            const libraryDetail = yield sce.getLibraryDetails(res.details.objectId);
            yield put(getLibraryAndNodesSuccess(getUpdatedLibraryWithNodes(libraryDetail.details[0],functionWithNodesList)));
            yield put(addFunctionsToNewLibrarySuccess());
            yield put(handleUniqueLibraryFailed(false));
            yield put(hideModal());
            //yield put(getLibraryAndNodesRequest());
            yield put(
                showNotificationModal({
                    title: LIBRARY.CREATE_SUCCESS_MSG,
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: 'banner',
                })
            );
        }
        }

        
    } catch (error) {
        yield put(addFunctionsToNewLibraryFailure());
        yield put(hideModal());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* getLibraryAndNodesSaga() {
    try {
        const res = yield sce.getLibraryAndNodes();
        yield put(getLibraryAndNodesSuccess(res.details));
    } catch (error) {
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [error.response.data[0].details],
            })
        );
        yield put(getLibraryAndNodesFailure());
    }
}

function* deleteLibrarySaga(action: ReturnType<typeof deleteLibraryRequest>) {
    try {
        const { libraryId, libraryVersion, password, isIPProtected, deleteFunctions } =
            action.payload;
        const res = yield sce.deleteLibraryNew(
            libraryId,
            libraryVersion,
            password,
            isIPProtected,
            deleteFunctions
        );

        // if (res.status === 200) {
        let { libraryAndFunctionsList } = yield select((state: StoreState) => ({
            libraryAndFunctionsList: state.functions.libraryAndFunctionsList,
        }));
        const filteredLibraryAndFunctionList = (
            libraryAndFunctionsList as LibraryAndNodePayload[]
        ).filter((item) => item.id !== libraryId);
        let libFunctions = libraryAndFunctionsList.filter((lib: LibraryAndNodePayload) => lib.id === libraryId)[0].nodes;
        if(libFunctions.length) {
            libFunctions = libFunctions.map((fn:any) => {
                let majorVersion = fn.version.split('.')[0].toString();
                let minorVersion = (Number(fn.version.split('.')[1]) + 1).toString();
                let patchVersion = fn.version.split('.')[2].toString();
                fn.version = `${majorVersion}.${minorVersion}.${patchVersion}`;
                return fn;
            });
            //@ts-ignore
            libraryAndFunctionsList[libraryAndFunctionsList.length - 1].nodes = [...libraryAndFunctionsList[libraryAndFunctionsList.length - 1].nodes,...libFunctions];
            libraryAndFunctionsList[libraryAndFunctionsList.length - 1].nodes.sort((a:any, b:any) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      });
        }
        libraryAndFunctionsList = libraryAndFunctionsList.filter((item:any) => item.id !== libraryId);
        yield put(getLibraryAndNodesSuccess(libraryAndFunctionsList));
        yield put(deleteLibrarySuccess());
        const index = findActiveIndexForFunctionsLibrary(filteredLibraryAndFunctionList);
        if (index >= 0) {
            yield put(
                getSelectedFunctionType({
                    activeLibId: libraryAndFunctionsList[index].id,
                    selectedFunction: libraryAndFunctionsList[index].nodes.length > 0 ? convertFuncDetailsToFuncType(
                        libraryAndFunctionsList[index].nodes[0]
                    ) : {
                        assetRef: "",
                        assetDescription: "",
                        assetName: "",
                        assetType: "",
                        assetVersion: "",
                        assetTags: [],
                    },
                })
            );
        }
        yield put(
            showNotificationModal({
                title: LIBRARY.DELETE_SUCCESS_MSG,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        yield put(setGlobalLibraryError({ error: '' }));
        yield put(hideModal());
        // }
    } catch (err) {
        const { error, statusCode } = abbSDKGetLibraryError(err);
        if (statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [error],
                })
            );
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: error,
                })
            );
        }
        yield put(deleteLibraryFailure());
    }
}

function* renameLibrarySaga(action: ReturnType<typeof renameLibraryRequest>) {
    const state: StoreState = yield select();
    const functionWithNodesList = state.functions.libraryAndFunctionsList;
    try {
        const { libraryId, libraryVersion, password, root } = action.payload;
        const isLibraryExist = yield sce.verifyLibraryExists(root);
        if(isLibraryExist.details && isLibraryExist.details.isExist) {
            yield put(handleUniqueLibraryFailed(true));
            yield put(renameLibraryError());
        } else { 
            const res = yield sce.renameLibrary(libraryId, libraryVersion, password, root);

        // if (res.status === 200) {
        //yield put(getLibraryAndNodesRequest());
        yield put(
            showNotificationModal({
                title: LIBRARY.RENAME_SUCCESS_MSG,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        yield put(setGlobalLibraryError({ error: '' }));
        const libraryDetail = yield sce.getLibraryDetails(libraryId);
        yield put(getLibraryAndNodesSuccess(getUpdatedLibraryWithNodes(libraryDetail.details[0],functionWithNodesList)));
        yield put(renameLibrarySuccess());
        yield put(hideModal());
        }
        
        // }
    } catch (err) {
        const { error, statusCode } = abbSDKGetLibraryError(err);
        if (statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [error],
                })
            );
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: error,
                })
            );
        }
        yield put(renameLibraryError());
    }
}

function* removeFunctionsFromExistingLibrarySaga(
    action: ReturnType<typeof removeFunctionsFromExistingLibraryRequest>
) {
    const state: StoreState = yield select();
    const functionWithNodesList = state.functions.libraryAndFunctionsList;

    const libDetails = _.cloneDeepWith(action.payload);
    delete libDetails.withIPProtection;
    try {
        const res = yield sce.removeFunctionsFromExistingLibrary(
            libDetails,
            action.payload.withIPProtection
        );
        if (res.status === 200) {
            //yield put(getLibraryAndNodesRequest());
            const libraryDetail = yield sce.getLibraryDetails(libDetails.libraryId);
            if(libraryDetail.status === 200) {
                let updatedLibraryWithNodes = getUpdatedLibraryWithNodes(libraryDetail.details[0],functionWithNodesList);
                let libFunctions = libDetails.functions;
            libFunctions = libFunctions.map((fn:any) => {
                if(libraryDetail.details[0].isIPProtected) {
                let majorVersion = (Number(fn.version.split('.')[0]) + 1).toString();
                fn.version = `${majorVersion}.0.0`;
                } else {
                let majorVersion = fn.version.split('.')[0].toString();
                let minorVersion = (Number(fn.version.split('.')[1]) + 1).toString();
                let patchVersion = fn.version.split('.')[2].toString();
                fn.version = `${majorVersion}.${minorVersion}.${patchVersion}`;
                }
                return fn;
            });
                updatedLibraryWithNodes[updatedLibraryWithNodes.length - 1].nodes = [...updatedLibraryWithNodes[updatedLibraryWithNodes.length - 1].nodes,...libFunctions];
                updatedLibraryWithNodes[updatedLibraryWithNodes.length - 1].nodes.sort((a:any, b:any) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  yield put(getLibraryAndNodesSuccess(updatedLibraryWithNodes));
                  const libraryAndFunctionsList: LibraryAndNodePayload[] = updatedLibraryWithNodes;
                const library = libraryAndFunctionsList.filter(
                    (item) => item.id === action.payload.libraryId
                )[0];

                yield put(
                    showNotificationModal({
                        title: `Removed ${action.payload.functions.length === 1 ? 'Function' : 'Functions'
                            } Successfully`,
                        resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                        type: 'banner',
                    })
                );
                const updatedNodes = library.nodes;

                if (updatedNodes.length > 0) {
                    yield put(
                        getSelectedFunctionType({
                            activeLibId: action.payload.libraryId,
                            selectedFunction: convertFuncDetailsToFuncType(updatedNodes[0]),
                        })
                    );
                } else {
                    let libraryIndex = findActiveIndexForFunctionsLibrary(libraryAndFunctionsList);
                    let functionTypeIndex = 0;
                    updatedLibraryWithNodes.forEach(
                        (item: LibraryAndNodePayload, itemIndex: number) => {
                            item.nodes.forEach((itemNode, itemNodeIndex) => {
                                if (itemNode.typeId === action.payload.functions[0].typeId) {
                                    libraryIndex = itemIndex;
                                    functionTypeIndex = itemNodeIndex;
                                }
                            });
                        }
                    );
                    if (libraryIndex >= 0) {
                        yield put(
                            getSelectedFunctionType({
                                activeLibId: libraryAndFunctionsList[libraryIndex].id,
                                selectedFunction: convertFuncDetailsToFuncType(
                                    libraryAndFunctionsList[libraryIndex].nodes[functionTypeIndex]
                                ),
                            })
                        );
                    }
                }
                  
            }
            yield put(removeFunctionsFromExistingLibrarySuccess());
            yield put(setGlobalLibraryError({ error: '' }));
            yield put(hideModal());
        }
    } catch (error) {
        yield put(removeFunctionsFromExistingLibraryFailure());
        if (abbSDKGetLibraryError(error).statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetLibraryError(error).error],
                })
            );
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: abbSDKGetLibraryError(error).error,
                })
            );
        }
    }
}

function* addFunctionsToExistingLibrarySaga(
    action: ReturnType<typeof addFunctionsToExistingLibraryRequest>
) {
    const state: StoreState = yield select();
    const functionWithNodesList = state.functions.libraryAndFunctionsList;
    const libDetails = _.cloneDeepWith(action.payload);
    try {
        const res = yield sce.addFunctionsToExistingLibrary(
            libDetails,
            action.payload.withIPProtection
        );

        if (res.status === 200) {
            try {
                const libraryDetail = yield sce.getLibraryDetails(libDetails.libraryId);
                
                if (libraryDetail.status === 200) {
                    let updatedLibraryWithNodes = getUpdatedLibraryWithNodes(libraryDetail.details[0],functionWithNodesList);
                    updatedLibraryWithNodes[updatedLibraryWithNodes.length - 1].nodes = updatedLibraryWithNodes[updatedLibraryWithNodes.length - 1].nodes.filter(fn => libDetails.functions.some(addedFn => addedFn.typeId !== fn.typeId))
                    yield put(getLibraryAndNodesSuccess(updatedLibraryWithNodes));
                    let currentNode: UpdateActiveFunctionType = {} as UpdateActiveFunctionType;
                    updatedLibraryWithNodes.forEach((item: LibraryAndNodePayload) => {
                        if (item.id === libDetails.libraryId) {
                            item.nodes.forEach((itemNode) => {
                                if (itemNode.typeId === action.payload.functions[0].typeId) {
                                    currentNode = itemNode;
                                }
                            });
                        }
                    });
                    yield put(
                        getSelectedFunctionType({
                            activeLibId: libDetails.libraryId,
                            selectedFunction: convertFuncDetailsToFuncType(currentNode),
                        })
                    );
                    yield put(addFunctionsToExistingLibrarySuccess());
                    if (action.payload.dragAndDrop && action.payload.dragAndDrop === true) {
                        const dragDroppedLib: LibraryDraggedDetails[] = [
                            {
                                libraryId: action.payload.libraryId,
                                libraryVersion: action.payload.libraryVersion,
                                password: action.payload.password,
                                withIPProtection: action.payload.withIPProtection,
                            },
                        ];
                        yield put(setDragAndDroppedLibrary({ dragDroppedLib }));
                    } else {
                        yield put(setDragAndDroppedLibrary({ dragDroppedLib: [] }));
                    }

                    yield put(
                        showNotificationModal({
                            title: `Added ${action.payload.functions.length === 1 ? 'Function' : 'Functions'
                                } Successfully`,
                            resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                            type: 'banner',
                        })
                    );
                }
            } catch (error) {
                yield put(
                    showNotificationModal({
                        title: 'API error',
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        type: 'confirmation',
                        details: [error.response.data[0].details],
                    })
                );
            }
            yield put(setGlobalLibraryError({ error: '' }));
            yield put(hideModal());
        }
    } catch (error) {
        yield put(addFunctionsToExistingLibraryFailure());
        if (abbSDKGetLibraryError(error).statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetLibraryError(error).error],
                })
            );
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: abbSDKGetLibraryError(error).error,
                })
            );
        }
    }
}

function* getExportableFunctionsSaga(action: ReturnType<typeof getExportableFunctionsRequest>) {
    try {
        const res = yield sce.getExportableFunctions(action.payload);
        const state: StoreState = yield select();
        if (res.status === 200) {
            generateAndSaveZipFileToSystem(res, state.exportAsset.zipFileName);
            yield put(getExportableFunctionsSuccess());
            yield put(hideModal());
        } else {
            yield put(getExportableFunctionsFailure());
        }
    } catch (error) {
        yield put(getExportableFunctionsFailure());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* handleLibraryImport() {
    const state: StoreState = yield select();
    const selectedLibraries = state.exportAsset.selectedItemsForImport;
    const selectedLibrariesPassword = state.exportAsset.passwordDetails;
    const dependantLibraries = state.exportAsset.dependantLibraries;
    if (state.configurationTool.mode !== CONFIGURATION_TOOL_MODE.IMPORT) {
        yield put(hideImportModal());
    }

    if (window.location.pathname.includes(ROUTE_PATHNAME.FUNCTIONS)) {
        let librariesToImport: UI.IImportableLibraryInfo[] = [];
        (selectedLibraries as FunctionsLibraries[]).forEach((item) => {
            let obj = {} as UI.IImportableLibraryInfo;

            const { isProtected, library, functionTypes, hash } = item;
            obj['libraryPack'] = {
                isProtected,
                library,
                functionTypes,
                hash,
            };
            obj['password'] = null;
            librariesToImport.push(obj);
        });
        yield put(toggleModelSpinner(true));
        try {
            const validateLib = yield sce.validateLibImport(librariesToImport);
            yield put(toggleModelSpinner(false));
            yield put(
                showModal({
                    component: PreImportLibraryValidationModal,
                    modalTitle: CREATE_NEW_MODEL.PRE_IMPORT_VALIDATION,
                    data: {
                        validationDetails: validateLib.details,
                        librariesToImport: librariesToImport,
                    },
                })
            );
        } catch (err) {
            yield put(toggleModelSpinner(false));
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetErrorMessage(err)],
                })
            );
        }
    }
    if (window.location.pathname.includes(ROUTE_PATHNAME.HOME)) {
        if (state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT) {
            if (
                !isNilOrEmpty(state.exportAsset.importedModelDetails) &&
                state.exportAsset.importedModelDetails.hasOwnProperty('typeId')
            ) {
                let selectedModelTypeIdArray = (
                    selectedLibraries as TypesWithLibraries[]
                )[0].model.properties.model.typeId.value.split('.');
                let importedModelTypeIdArray =
                    state.exportAsset.importedModelDetails['typeId']['value'].split('.');
                let isSameTypeId = importedModelTypeIdArray.reduce(
                    (isTypeIdEqual: boolean, currentId: string, currentIndex: number) => {
                        if (currentIndex < 2) {
                            return true;
                        }
                        return (
                            currentId === selectedModelTypeIdArray[currentIndex] && isTypeIdEqual
                        );
                    },
                    true
                );
                if (isSameTypeId) {
                    yield put(toggleModelSpinner(false));
                    yield put(
                        showNotificationModal({
                            title: TYPE_MSG.TYPEID_CONFLICT_TITLE,
                            details: [TYPE_MSG.TYPEID_CONFLICT_DESC],
                            resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                            type: 'confirmation',
                        })
                    );
                    return;
                }
            }
        } else {
            const parentAssociatedObjectType = {
                model: state.modelsPage.activeAsset ? state.modelsPage.activeAsset.assetType : '',
                typeId: state.modelsPage.activeAsset ? state.modelsPage.activeAsset.assetRef : '',
                version: state.modelsPage.activeAsset
                    ? state.modelsPage.activeAsset.assetVersion
                    : '',
            };

            let isValid = true;
            let fileName = [] as string[];
            let parentObjectName = [] as string[];
            const { model, typeId, version } = parentAssociatedObjectType;
            (selectedLibraries as TypesWithLibraries[]).forEach((typeDetail) => {
                if (
                    typeDetail.model.properties.associatedObjectType.model.value !== model ||
                    typeDetail.model.properties.associatedObjectType.typeId.value !== typeId ||
                    typeDetail.model.properties.associatedObjectType.version.value !== version
                ) {
                    isValid = false;
                    fileName = [...fileName, typeDetail.model.properties.model.name.value];
                    parentObjectName = [
                        ...parentObjectName,
                        typeDetail.model.properties.associatedObjectType.model.value,
                    ];
                }
            });

            if (!isValid) {
                const details = fileName.map((fileName, index) => {
                    return `Cannot import ${fileName} under ${typeId} as it belongs to ${parentObjectName[index]}`;
                });

                yield put(
                    showNotificationModal({
                        title: TYPE_MSG.WRONG_TYPE_IMPORT,
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        type: 'confirmation',
                        details: details,
                    })
                );
                return;
            }
        }

        let importTypeDetails: any = {
            models: [],
            dependentLibraries: {},
        };
        importTypeDetails['models'] = selectedLibraries as TypesWithLibraries[];
        importTypeDetails['dependentLibraries'] = _.cloneDeepWith(selectedLibrariesPassword).map(
            (item) => {
                let obj = {
                    library: dependantLibraries.filter((lib) => lib.name === item.libraryName)[0],
                    // password: item.password,
                };
                return obj;
            }
        );
        yield put(toggleModelSpinner(true));
        try {
            const validateTypes = yield sce.validateTypeImport(importTypeDetails);
            yield put(toggleModelSpinner(false));
            yield put(
                showModal({
                    component: PreImportTypeValidationModal,
                    modalTitle: CREATE_NEW_MODEL.PRE_IMPORT_VALIDATION,
                    data: {
                        validationDetails: validateTypes.details,
                        zippedModels: importTypeDetails,
                    },
                })
            );
        } catch (err) {
            yield put(toggleModelSpinner(false));
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetErrorMessage(err)],
                })
            );
        }
    }
}

function* encryptLibrarySaga(action: ReturnType<typeof encryptLibraryRequest>) {
    try {
        const { libraryId, libraryVersion, password } = action.payload;
        const store: StoreState = yield select();
        const { typeDetails, activeLibId, currentLibraryToDragAndDrop,libraryAndFunctionsList } = store.functions;
        const res = yield sce.convertToIPProtectedLibrary(libraryId, libraryVersion, password);
        const libraryDetail = yield sce.getLibraryDetails(libraryId);
        yield put(getLibraryAndNodesSuccess(getUpdatedLibraryWithNodes(libraryDetail.details[0],libraryAndFunctionsList)));
        yield put(hideModal());
        yield put(encryptLibrarySuccess());
        yield put(
            showNotificationModal({
                title: res.details,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        yield put(setGlobalLibraryError({ error: '' }));
        if (res.status === 200) {
            // clearing currentLibraryToDragAndDrop details
            if (
                currentLibraryToDragAndDrop.length > 0 &&
                currentLibraryToDragAndDrop[0].libraryId === libraryId
            ) {
                yield put(setDragAndDroppedLibrary({ dragDroppedLib: [] }));
            }
            if (typeDetails && libraryId === activeLibId) {
                yield put(handleSelectedFunctionLoading(true));
                yield put(handleGetLibraryAndNodesLoading(true));
                yield call(updateFunctionDataWithLatestDetails, libraryId, typeDetails.typeId);
                yield put(handleGetLibraryAndNodesLoading(false));
            } else {
                //yield put(getLibraryAndNodesRequest());
            }
        }
    } catch (error) {
        yield put(encryptLibraryFailure());
        if (abbSDKGetLibraryError(error).statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetLibraryError(error).error],
                })
            );
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: abbSDKGetLibraryError(error).error,
                })
            );
        }
    }
}

function* changeLibraryPasswordSaga(action: ReturnType<typeof handleChangeLibraryPassword>) {
    try {
        const { libDetails, password, newPassword } = action.payload;
        const store: StoreState = yield select();
        const { typeDetails, activeLibId, currentLibraryToDragAndDrop,libraryAndFunctionsList } = store.functions;
        yield put(handleChangePasswordLoading(true));
        yield sce.ChangeLibraryPassword(
            {
                functions: libDetails.nodes,
                libraryId: libDetails.id,
                newPassword,
                password,
                libraryVersion: libDetails.libraryVersion,
            },
            libDetails.isIPProtected
        );
        
        const libraryDetail = yield sce.getLibraryDetails(libDetails.id);
        yield put(getLibraryAndNodesSuccess(getUpdatedLibraryWithNodes(libraryDetail.details[0],libraryAndFunctionsList)));
        yield put(hideModal());
        yield put(handleChangePasswordLoading(false));
        // clearing currentLibraryToDragAndDrop details
        if (
            currentLibraryToDragAndDrop.length > 0 &&
            currentLibraryToDragAndDrop[0].libraryId === libDetails.id
        ) {
            yield put(setDragAndDroppedLibrary({ dragDroppedLib: [] }));
        }

        yield put(handleLibPasswordError(''));
        yield put(
            showNotificationModal({
                title: 'Successfully changed password',
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        yield put(setGlobalLibraryError({ error: '' }));
        if (typeDetails && libDetails.id === activeLibId) {
            yield put(handleSelectedFunctionLoading(true));
            yield put(handleGetLibraryAndNodesLoading(true));
            yield call(updateFunctionDataWithLatestDetails, libDetails.id, typeDetails.typeId);
            yield put(handleGetLibraryAndNodesLoading(false));
        } else {
            //yield put(getLibraryAndNodesRequest());
        }
    } catch (err) {
        yield put(handleLibPasswordError(abbSDKGetLibraryError(err).error));
        yield put(handleChangePasswordLoading(false));
        yield put(handleGetLibraryAndNodesLoading(false));
        
    }
}

function* unlockLibrarySaga(action: ReturnType<typeof unlockLibrary>) {
    try {
        const { libraryDetail, password } = action.payload;
        const store: StoreState = yield select();
        const { typeDetails, activeLibId,libraryAndFunctionsList } = store.functions;
        yield put(handlePasswordBtnLoading(true));
        const res = yield sce.UnlockLibrary(
            {
                libraryId: libraryDetail.id,
                functions: libraryDetail.nodes,
                password: password,
                libraryVersion: libraryDetail.libraryVersion,
            },
            libraryDetail.isIPProtected
        );
        const libDetail = yield sce.getLibraryDetails(libraryDetail.id);
        yield put(getLibraryAndNodesSuccess(getUpdatedLibraryWithNodes(libDetail.details[0],libraryAndFunctionsList)));
        yield put(handlePasswordBtnLoading(false));
        yield put(hideModal());
        yield put(
            showNotificationModal({
                title: res.details[0].title ? res.details[0].title : res.details[0].message,
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            })
        );
        yield put(setGlobalLibraryError({ error: '' }));

        // if (typeDetails && libraryDetail.id === activeLibId) {
        //     yield put(handleSelectedFunctionLoading(true));
        //     yield put(handleGetLibraryAndNodesLoading(true));
        //     yield call(updateFunctionDataWithLatestDetails, libraryDetail.id, typeDetails.typeId);
        //     yield put(handleGetLibraryAndNodesLoading(false));
        // } else {
        //     //yield put(getLibraryAndNodesRequest());
        // }
    } catch (error) {
        yield put(handlePasswordBtnLoading(false));
        yield put(handleGetLibraryAndNodesLoading(false));
        if (abbSDKGetLibraryError(error).statusCode !== 401) {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetLibraryError(error).error],
                })
            );
            yield put(hideModal());
            yield put(
                setGlobalLibraryError({
                    error: '',
                })
            );
        } else {
            yield put(
                setGlobalLibraryError({
                    error: abbSDKGetLibraryError(error).error,
                })
            );
        }
    }
}

function* updateFunctionDataWithLatestDetails(libraryId: string, currentFunctionTypeId: string) {
    const libraryResponse = yield sce.getLibraryAndNodes();
    if (libraryResponse.status === 200) {
        yield put(getLibraryAndNodesSuccess(libraryResponse.details));
        let currentNode: UpdateActiveFunctionType = {} as UpdateActiveFunctionType;
        libraryResponse.details.some((item: LibraryAndNodePayload) => {
            if (item.id === libraryId) {
                item.nodes.some((itemNode) => {
                    if (itemNode.typeId === currentFunctionTypeId) {
                        currentNode = itemNode;
                        return true;
                    }
                    return false;
                });
                return true;
            }
            return false;
        });
        yield put(
            getSelectedFunctionType({
                activeLibId: libraryId,
                selectedFunction: convertFuncDetailsToFuncType(currentNode),
            })
        );
    }
}

function* libraryImportSaga(action: ReturnType<typeof handleLibrariesImport>) {
    const librariesToImport = action.payload;
    try {
        const state: StoreState = yield select();
        const { activeLibId, typeDetails } = state.functions;
        yield put(toggleModelSpinner(true));
        const res = yield sce.importFunctions(librariesToImport);
        console.log(res.details);
        
        const libraryResponse = yield sce.getLibraryAndNodes();
        if (libraryResponse.status === 200) {
            yield put(getLibraryAndNodesSuccess(libraryResponse.details));
            const libraryAndFunctionsList: LibraryAndNodePayload[] = libraryResponse.details;

            const activeLibraryDetail = libraryAndFunctionsList.find((item) =>
                activeLibId !== GENERIC_FUNCTIONS ? item.id === activeLibId : item.id === null
            );
            if (typeDetails && activeLibraryDetail) {
                const nodes = activeLibraryDetail.nodes;
                const updatedFunction = nodes.find((item) => item.typeId === typeDetails.typeId);
                if (updatedFunction) {
                    yield put(
                        getSelectedFunctionType({
                            activeLibId: activeLibId,
                            selectedFunction: convertFuncDetailsToFuncType(updatedFunction),
                        })
                    );
                }
            }
        }
        yield put(toggleModelSpinner(false));
        if (res.details) {
            const payload: any = {
                summaryModalDetails: {
                    showSummaryModal: true,
                    mode: 'IMPORT',
                    route: 'FUNCTIONS',
                    data: res.details,
                },
            };
            yield put(showSummaryModal(payload));
        }
    } catch (error) {
        yield put(toggleModelSpinner(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

export default [
    takeLatest(ActionTypes.MUTUALLY_EXCLUSIVE_FUNCTIONS_REQUEST, mutuallyExclusiveFunctionTypes),
    takeLatest(ActionTypes.ADD_FUNCTIONS_TO_NEW_LIBRARY_REQUEST, addFunctionsToNewLibrarySaga),
    takeLatest(ActionTypes.GET_LIBRARY_AND_NODES_REQUEST, getLibraryAndNodesSaga),
    takeLatest(ActionTypes.DELETE_LIBRARY_REQUEST, deleteLibrarySaga),
    takeLatest(
        ActionTypes.REMOVE_FUNCTIONS_FROM_EXISTING_LIBRARY_REQUEST,
        removeFunctionsFromExistingLibrarySaga
    ),
    takeLatest(
        ActionTypes.ADD_FUNCTIONS_TO_EXISTING_LIBRARY_REQUEST,
        addFunctionsToExistingLibrarySaga
    ),
    takeLatest(ActionTypes.GET_EXPORTABLE_FUNCTIONS_REQUEST, getExportableFunctionsSaga),
    takeLatest(ActionTypes.HANDLE_FILES_IMPORT, handleLibraryImport),
    takeLatest(ActionTypes.ENCRYPT_LIBRARY_REQUEST, encryptLibrarySaga),
    takeLatest(ActionTypes.HANDLE_CHANGE_LIBRARY_PASSWORD, changeLibraryPasswordSaga),
    takeLatest(ActionTypes.UNLOCK_LIBRARY, unlockLibrarySaga),
    takeLatest(ActionTypes.HANDLE_LIBRARIES_IMPORT, libraryImportSaga),
    takeLatest(ActionTypes.RENAME_LIBRARY_REQUEST, renameLibrarySaga),
];
