import { fabric } from 'fabric';
import NodeController from './NodeController';
import { IEvent } from 'fabric/fabric-impl';
import ConnectionController from './ConnectionController';
import {
    IOTypes,
    AddDraggableOptions,
    AddToCanvasOptions,
    Privileges,
    IODataTypes,
    Asset,
    AddAlarmPayload,
    ALARM_TYPE,
    AddEventPayload,
    EVENT_TYPE,
} from './types';
import connectionChecker from './utils/connectionChecker';
import calcLineCurve from './utils/calcLineCurve';
import {
    canvasSecondaryBorderColor,
    crossIconRadius,
    canvasBorderPadding,
    MAX_CANVAS_HEIGHT,
    MAX_CANVAS_WIDTH,
    ALIGNING_LINE_OFFSET,
    ALIGNING_LINE_MARGIN,
    ALIGNING_LINE_WIDTH,
    ALIGNING_LINE_COLOR,
    GRID_PIXEL,
    SNAP_FACTOR,
    GRID_COLOR,
    BOUNDARY_PADDING,
    fullFunctionPinLength,
    GRID_DOT_WIDTH,
    MIN_TOP_PADDING,
    MIN_Left_PADDING,
    MIN_BOTTOM_PADDING,
    MIN_RIGHT_PADDING,
} from './UIHelpers/UiConfig';
import ObjectTypeDetail from '../../transformers/AssetType/ObjectType';
import FunctionTypeDetail from '../../transformers/AssetType/FunctionType';
import { FunctionOutput } from './IOController/helperClasses';
import IOController from './IOController';
import { FabricTooltip } from './UIHelpers/FabricTooltip';
import { FabricCrossIcon } from './UIHelpers/FabricCrossIcon';
import { FabricInputTrigger, FabricOutputPublish } from './UIHelpers/FabricIOTrigger';
import { FabricFunctionRectangle } from './UIHelpers/functionRectangle';
import { FabricObjectRectangle } from './UIHelpers/FabricObjectRectangle';
import { FabricIOCircle, FabricOutputCircle } from './UIHelpers/FabricIOCircle';
import { Node } from './NodeController/types';
import { FabricConnectionLine } from './UIHelpers/FabricConnectionLine';
import { FabricPublishIcon } from './UIHelpers/functionRectangle/smartChilds/FabricPublishIcon';
import { getAssetDetails } from './utils/getAssetDetails';
import { FabricSettingsIcon } from './UIHelpers/functionRectangle/smartChilds/FabricSettingsIcon';
import { store } from '../../containers/AppBoot/index';
import { showModal } from '../../store/modal/action';
import FunctionConfiguration from './UIComponents/FunctionConfiguration';
import {
    handleCanvasZoomUpdate,
    markComputeModelDirty,
    showConfigurationPopup,
    hideConfigurationPopup,
    editNodeIdOfFunction,
} from '../../store/configurationTool/actions';
import {
    CANVAS_DIMENSION,
    DELETE_MODEL_TYPE,
    FUNCTION_SEVERITY_PIN,
    MODAL_TITLE,
    OBJECT_SCALE_LIMIT,
    ROUTE_PATHNAME,
    CONFIRMATION_MSG,
    deleteFunctionAlarmEventPinWarningMessage,
    VIEW_MODE,
    INSTANCE_VALIDATION_MSG,
    NOTIFICATION_MODAL_STATUS,
} from '../../utils/constants/appConstants';
import ConfirmationMessage from './utils/ConfirmationMessage';
import { Connection } from './ConnectionController/types';
import {
    FunctionInputTrigger,
    FunctionOutputPublish,
    ObjectOutputCircle,
    FunctionOutputCircle,
} from './IOController/types';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import { getInstanceDetails } from './utils/getInstanceDetails';
import { DELETE_MODEL_MSG, CONFIRMATION_BUTTON, CONFIRMATION_INITIAL_VALUE } from '../../utils/constants/uiConstants';
import { adjustNodeToCorrectPosition } from './utils/adjustNodeToCorrectPosition';
import { calcAssetNodeDimension } from './utils/calcAssetNodeDimension';
import { FabricAlarmIcon } from './UIHelpers/FabricAlarmIcon';
import {
    getOriginalFunctionTriggerForInstance,
    getOriginalSelectedFuncDetail,
} from '../../utils/helpers';
import { FunctionTypeDetailsWithMappings } from '../../model/TableController/types';
import {
    isArrayDatatype,
    convertMajorVersionToFullVersion,
    calculateDropDownTitlePosition,
    isManualConnectionAllowed,
    isConnectionDeleteAllowed,
} from './utils/helpers';
import { FabricHistoryIcon } from './UIHelpers/FabricHistoryIcon';
import HistoryConfiguration, { HistoryDataFormValues } from './UIComponents/HistoryConfiguration';
import _ from 'lodash';
import ConditionConfiguration from './UIComponents/ConditionConfiguration';
import { addOverallSeverityFunctionToCanvas } from './utils/addOverallSeverityFunction';
import { FabricRefreshIcon } from './UIHelpers/functionRectangle/smartChilds/FabricRefreshIcon';
import { handleMaxCanvasWidthHeightLimit } from './utils/handleCanvasMaxLimit';
import { calculateAssetDimension } from './utils/calculateAssetDimension';
import { showDialog, hideDialog } from '../../store/dialog/action';
import { MessageModal } from '../MessageModal';
import AllowOverlapMessage from './UIComponents/AllowOverlapMessageModal';
import { FabricAlarmRectangle } from './UIHelpers/alarmRectangle';
import { FabricDropDownTitle } from './UIHelpers/titleRectangle';
import { FabricArrowIcon } from './UIHelpers/FabricArrowIcon';
import { FabricLineDeleteIcon } from './UIHelpers/ioGroup/FabricILA';

import AlarmTypeDetail from '../../transformers/AssetType/AlarmType';
import uuid from 'uuid';
import { FabricEventRectangle } from './UIHelpers/eventRectangle';
import EventTypeDetail from '../../transformers/AssetType/EventType';
import { generateFunctionId } from '../../transformers/AssetType/utils/functionIoIdGenerator';
import { FabricAlarmTriggerIcon } from './UIHelpers/ioGroup/FabricAlarmTriggerIcon';
import AddAlarmForm from '../CreateAlarmForm';
import { addConnectionConfigurationTool, removeConnectionConfigurationTool, addDefaultValueConfigurationTool } from '../../store/configurationTool/actions';
import { getSelectedFunctionDetails } from '../../store/configurationTool/actions';
import { FabricILWV } from './UIHelpers/ioGroup/FabricLineWithValue';
import GetAliasNameInModels from '../GetAliasNameInModels';
import { FabricOutputAlarmIcon } from './UIHelpers/ioGroup/AlarmIcon';
import { showNotificationModal } from '../../store/notificationModal/action';
import { SEVERITY_PIN } from '../../routes/Functions/constants';

class CanvasController {
    //@ts-ignore
    private _connections: [];
    //@ts-ignore
    public _canvas: fabric.Canvas;
    public _canvasParent: HTMLElement | null;
    private _canvasElement: HTMLCanvasElement | null;

    private _NodeController: NodeController;
    public IOController: IOController;
    private _ConnectionController: ConnectionController;
    private _currentConnection: FabricConnectionLine | null = null;
    private _selection: {
        currentSelection: FabricDropDownTitle | null;
        crossIcon: fabric.Group;
    };
    private _tooltip: FabricTooltip;

    public editMode: boolean;
    public showGrid: boolean;
    public privileges: Privileges;

    private _canvasOffSet: { x: number; y: number };
    private canvasId: string;
    private viewportTransform: number[];
    public zoom: number = 1;
    private verticalLines: Array<any> = [];
    private horizontalLines: Array<any> = [];
    private canvasWidthCenterMap: object = {};
    private canvasHeightCenterMap: object = {};
    private isInVerticalCenter: boolean | null = false;
    private isInHorizontalCenter: boolean | null = false;
    private selectedNode: FabricFunctionRectangle | null = null;

    _canvasHeight: number;
    _canvasWidth: number;
    handleMouseWheel: any;
    cloneRowData: any = []
    // private _positionTooltip: FabricTooltip;
    /**
     * Canvas controller constructor, canvas id should be 'canvas'
     * canvas parent div id should be 'canvas-parent'
     * canvas parent div height/width fixed with overflow hidden.
     */

    constructor(id = 'canvas', privilegs: Privileges) {
        this.canvasId = id;
        this._connections = [];
        this._canvasHeight = this._canvasWidth = 0;
        // overriding fabric Object method to fix resizing issue and integrate maxScaleLimit
        // @ts-ignore
        fabric.Object.prototype._constrainScale = function (...args) {
            let value = args[0];
            if (Math.abs(value) < OBJECT_SCALE_LIMIT.MIN_VALUE) {
                if (value < 0) {
                    return -OBJECT_SCALE_LIMIT.MIN_VALUE;
                } else {
                    return OBJECT_SCALE_LIMIT.MIN_VALUE;
                }
            } else if (Math.abs(value) >= OBJECT_SCALE_LIMIT.MAX_VALUE) {
                return OBJECT_SCALE_LIMIT.MAX_VALUE;
            } else if (value === 0) {
                return 0.0001;
            }
            return value;
        };

        this._canvas = new fabric.Canvas(id, {
            selection: false,
            backgroundColor: 'transparent',
            preserveObjectStacking: true,
        });

        this.privileges = privilegs;
        this._canvasElement = document.getElementById(id) as HTMLCanvasElement;
        this._canvasParent = document.getElementById('canvas-parent');
        // @ts-ignore
        window._canvas = this._canvas;
        // @ts-ignore
        window.canvas = this;

        this._canvasOffSet = this.getCanvasOffSet(id);
        let width = this._canvasParent ? this._canvasParent.offsetWidth : CANVAS_DIMENSION.WIDTH;
        let height = CANVAS_DIMENSION.HEIGHT;

        this._canvas.setHeight(height);
        this._canvas.setWidth(width);
        this._NodeController = new NodeController({
            canvas: this._canvas,
            canvasOffSet: this._canvasOffSet,
        });
        this._ConnectionController = new ConnectionController({
            canvas: this._canvas,
        });
        this.IOController = new IOController({
            canvas: this._canvas,
            connectionController: this._ConnectionController,
            nodeController: this._NodeController,
        });

        this._selection = {
            crossIcon: new FabricCrossIcon(),
            currentSelection: null,
        };
        this._tooltip = new FabricTooltip();
        this.editMode = true;
        this.showGrid = false;
        this.subscribeCanvasEvents();

        // this.subscribeStoreEvents();
        this.resetzoom();

        this.viewportTransform = [];
        // this._canvas.setZoom(1.4);
        this.zoom = 1;
        const storeVal = store.getState();
        const { configurationTool } = storeVal;
    }

    // to resize the canvas according to parent container.
    resizeCanvas = () => {
        this._canvasParent = document.getElementById('canvas-parent');

        if (this._canvasParent) {
            console.log('WIDTHHHHHH', this._canvasParent.offsetWidth);
            const oldHeight = this._canvas.getHeight();
            const oldWidth = this._canvas.getWidth();
            this._canvas.setHeight(Math.max(this._canvasParent.offsetHeight, oldHeight));
            this._canvas.setWidth(Math.max(this._canvasParent.offsetWidth + 50, oldWidth));
            this._canvas.renderAll();
            if (this.showGrid) {
                this.addGridLines(this.showGrid);
            }
        }
    };

    // set scroll based on zoom
    resizeScroll = () => {
        return new Promise((resolve, reject) => {
            const debouncedResize = _.debounce(() => {
                let canvas = this._canvas;
                let zoom = canvas.getZoom();
                let { height, width } = this.calculateDimensionsFromObjects();

                if (this._canvasParent) {
                    let newWidth = Math.max(
                        (width + BOUNDARY_PADDING) * zoom,
                        this._canvasParent.offsetWidth
                    );
                    let newHeight = Math.max(
                        (height + MIN_BOTTOM_PADDING) * 1,
                        this._canvasParent.offsetHeight
                    );
                    canvas.setWidth(newWidth);
                    canvas.setHeight(newHeight);
                }
                this.addGridLines(this.showGrid);
            }, 400);
            debouncedResize();
            resolve(true);
        });
    };

    /**
     * ! Add new dragable object to canvas
     * !This will make api call and get asset details
     */
    addDraggable = (options: AddDraggableOptions) => {
        this._canvasOffSet = this.getCanvasOffSet('canvas');

        if (this.editMode) {
            this.clearSelection();
            const currentZoom = this._canvas.getZoom();
            const { position, isOverallSeverityFunction } = options;
            console.log('currentZoom:', currentZoom);
            console.log('cavasOffset:', this._canvasOffSet);
            console.log('position:', position);
            let offSetPosition = {
                x: (position.x - this._canvasOffSet.x) / currentZoom,
                y: (position.y - this._canvasOffSet.y) / currentZoom,
            };

            const {
                assetType,
                assetRef,
                assetName,
                assetVersion,
                isReferencedType,
                referencedTypes,
                objectId,
            } = options.asset;
            this._tooltip.text.text = 'Loading...';
            this._tooltip.rect.set('width', 100);
            this._tooltip.left = offSetPosition.x;
            this._tooltip.top = offSetPosition.y;
            this._canvas.add(this._tooltip);

            getAssetDetails({
                assetName,
                assetRef,
                assetType,
                assetVersion,
                position: offSetPosition,
                isOverallSeverityFunction,
                isReferencedType,
                referencedTypes,
                objectId,
            })
                //@ts-ignore
                .then((obj: any) => {
                    this.addToCanvas({ asset: obj });
                    this._canvas.remove(this._tooltip);
                })
                .catch((err: any) => {
                    this._tooltip.text.set('text', 'Error Loading');
                    this._canvas.renderAll();
                    setTimeout(() => {
                        this._canvas.remove(this._tooltip);
                    }, 2000);
                });
        }
    };

    addDraggableInstance = (options: {
        instance: { objectId: string };
        position: { x: number; y: number };
    }) => {
        const { instance } = options;
        this._canvasOffSet = this.getCanvasOffSet('canvas');
        const currentZoom = this._canvas.getZoom();
        this.clearSelection();
        const { position } = options;
        let offSetPosition = {
            x: (position.x - this._canvasOffSet.x) / currentZoom,
            y: (position.y - this._canvasOffSet.y) / currentZoom,
        };

        const { objectId } = options.instance;

        this._tooltip.text.text = 'Loading...';
        this._tooltip.rect.set('width', 100);
        this._tooltip.left = offSetPosition.x;
        this._tooltip.top = offSetPosition.y;
        this._canvas.add(this._tooltip);

        getInstanceDetails({ instance, position: offSetPosition })
            .then((obj: any) => {
                obj.objectInstance = true;
                this.addToCanvas({ asset: obj });
                this._canvas.remove(this._tooltip);
            })
            .catch((err: any) => {
                this._tooltip.text.set('text', 'Error Loading');
                this._canvas.renderAll();
                setTimeout(() => {
                    this._canvas.remove(this._tooltip);
                }, 2000);
            });
    };

    /**
     * ! After getting asset details from api, asset is added to canvas
     *
     */
    addToCanvas(options: AddToCanvasOptions) {
        let asset = options.asset;
        let node: FabricDropDownTitle | null = null;
        const { width, height } = calculateAssetDimension(asset);
        let returnVal = false;
        const { updatedPosition, isExceedingCanvasArea } = handleMaxCanvasWidthHeightLimit({
            nodeHeight: height,
            nodeWidth: width,
            nodePosition: asset.position,
        });
        asset.position = { ...updatedPosition };
        // if (asset instanceof ObjectTypeDetail) {
        //     node = this._NodeController.addObjectType({ asset });
        // } else if (asset instanceof FunctionTypeDetail) {
        //     node = this._NodeController.addFunctionType({
        //         asset,
        //     });
        // }
        node = this._NodeController.addNode({ asset });
        if (node) {
            // checking overlap on left Boundary
            const { nodeWidth, nodeHeight, longestInputPinWidth, longestOutputPinWidth } =
                calcAssetNodeDimension({
                    node: node,
                    canvasContext: this._canvas.getContext(),
                });
            const nodeDetails = { nodeHeight, nodeWidth };
            if (asset.position.x < longestInputPinWidth + nodeWidth / 2) {
                node.left = longestInputPinWidth + nodeWidth / 2;
                asset.position.x = longestInputPinWidth + nodeWidth / 2;
            }

            // checking overlap on top boundary .
            if (asset.position.y < nodeHeight / 2) {
                node.top = nodeHeight / 2 + crossIconRadius;
                asset.position.y = nodeHeight / 2 + crossIconRadius;
            }
            if (node instanceof FabricFunctionRectangle) {
                node.data.childs.forEach((child) => {
                    child.data.rePosition();
                    const nestedChilds = child.data.childs;
                    console.log('nestedChilds :', nestedChilds);
                });
            }

            //checking and fixing overlap on right boundary
            if (asset.position.x + nodeWidth + longestOutputPinWidth < MAX_CANVAS_WIDTH) {
                if (
                    asset.position.x + nodeWidth / 2 + longestOutputPinWidth >
                    this._canvas.getWidth()
                ) {
                    this._canvas.setWidth(
                        this._canvas.getWidth() +
                        (asset.position.x +
                            nodeWidth / 2 +
                            longestOutputPinWidth -
                            this._canvas.getWidth())
                    );
                    if (this._canvasParent) {
                        this._canvasParent.scrollTo({
                            left: node.left,
                            behavior: 'smooth',
                        });
                    }
                }
            }

            //checking and fixing overlap on bottom boundary
            if (asset.position.y + nodeHeight + canvasBorderPadding < MAX_CANVAS_HEIGHT) {
                if (asset.position.y + nodeHeight / 2 > this._canvas.getHeight()) {
                    this._canvas.setHeight(
                        this._canvas.getHeight() +
                        (asset.position.y + nodeHeight / 2 - this._canvas.getHeight()) +
                        20
                    );
                    if (this._canvasParent) {
                        this._canvasParent.scrollTo({
                            top: node.top,
                            behavior: 'smooth',
                        });
                    }
                }
            }

            // handle node overlap issue
            let funcObjectAssets: (FabricObjectRectangle | FabricFunctionRectangle)[] = [];
            this._canvas._objects.forEach((object) => {
                if (
                    object instanceof FabricObjectRectangle ||
                    object instanceof FabricFunctionRectangle
                ) {
                    funcObjectAssets.push(object);
                }
            });
            node.setCoords();
            // const isOverlapped = adjustNodeToCorrectPosition({
            //     objects: funcObjectAssets,
            //     node: node,
            //     inputPinsAssetWidth: longestInputPinWidth + nodeWidth / 2,
            // });
            // if (isOverlapped) {
            //     const currentHeight = this._canvas.getHeight();
            //     const currentCanvasWidth = this._canvas.getWidth();

            //     // check if exceeding canvas max width/height limit;
            //     if (currentHeight + nodeHeight + canvasBorderPadding <= MAX_CANVAS_HEIGHT) {
            //         node.top = this._canvas.getHeight() + nodeHeight / 4;

            //         this._canvas.setHeight(
            //             this._canvas.getHeight() + nodeHeight + canvasBorderPadding
            //         );
            //         this._canvas.setActiveObject(node);
            //         asset.position.y = node.top;

            //         if (asset.position.x < longestInputPinWidth + nodeWidth / 2) {
            //             node.left = longestInputPinWidth + nodeWidth / 2;
            //             asset.position.x = longestInputPinWidth + nodeWidth / 2;
            //         }

            //         if (node instanceof FabricFunctionRectangle && node.left) {
            //             node.data.childs.forEach((child) => child.data.rePosition());
            //         }
            //         if (this._canvasParent && node.left && node.top) {
            //             this._canvasParent.scrollTo({
            //                 top: node.top,
            //                 behavior: 'smooth',
            //             });
            //         }
            //     } else {
            //         const state = store.getState();
            //         if (!state.configurationTool.isOverlapAllowed) {
            //             store.dispatch(
            //                 showDialog({
            //                     component: AllowOverlapMessage,
            //                     modalTitle: MODAL_TITLE.OVERLAP_CANVAS_BLOCKS_TITLE,
            //                     customClassName: 'wrapper-overlap-msg-modal',
            //                     data: {
            //                         standardButtonsOnBottom: [
            //                             {
            //                                 text: CONFIRMATION_BUTTON.OK,
            //                                 type: 'primary-blue',
            //                                 handler: (dlg: any) => {
            //                                     store.dispatch(hideDialog());
            //                                 },
            //                             },
            //                         ],
            //                     },
            //                 })
            //             );
            //         }
            //     }
            // }
            node.setCoords();
            // this.IOController.addOutput({
            //     nodeDetails,
            //     asset,
            // });
            // if (asset instanceof FunctionTypeDetail) {
            //     this.IOController.addInput({ nodeDetails, asset });
            // }
            this.addGridLines(this.showGrid);
            this.markComputeModelDirty();
            /**
             * To add a function @OverAllSeverity
             * if there is any conditions avaiable in the function type
             * or
             * if the dropped asset is of type @ObjectTypeDetail and is referenced type.
             * This should bed added only for the first time.
             * currently adding @OverAllSeverity .
             * */
            if (
                node.data.asset instanceof FunctionTypeDetail &&
                node.data.asset.conditions
                || (node.data.asset instanceof ObjectTypeDetail && node.data.asset.isReferencedType)
            ) {
                let isSeverityPinPresent = node.data.asset instanceof FunctionTypeDetail && node.data.asset.conditions ?  true :  false;
                if (
                    (node.data.asset instanceof ObjectTypeDetail && node.data.asset.isReferencedType)
                ) {
                    node.data.asset.outputs.forEach(output => {
                        if(output.name === SEVERITY_PIN){
                            isSeverityPinPresent = true;
                        }
                    });
                }
                const state = store.getState();
                const overallSeverityFunction = state.configurationTool.severityFunctionData;
                const isOverAllSeverityFuncAdded =
                    state.configurationTool.isOverAllSeverityFuncAdded;

                if (!isOverAllSeverityFuncAdded && isSeverityPinPresent) {
                    addOverallSeverityFunctionToCanvas({
                        canvas: this,
                        asset,
                        longestOutputPinWidth,
                        nodeWidth,
                        overallSeverityFunction,
                    });
                }
            }
        }
    }

    addAlarmInput(options: {
        functionNode: FabricFunctionRectangle;
        alarmPayload: AddAlarmPayload;
        objectNode?: FabricObjectRectangle;
        objectOutputId?: string;
    }) {
        const { functionNode, alarmPayload, objectNode, objectOutputId } = options;
        const { alarmDetails, alarmProperties, objectType } = alarmPayload;
        let nodeDetails = {
            nodeHeight: functionNode.getScaledHeight(),
            nodeWidth: functionNode.getScaledWidth(),
        };

        let circleData = {
            connected: true,
            dataType: IODataTypes.STRING,
            id:
                (alarmDetails.type === ALARM_TYPE.GENERIC ? '*' : '') +
                generateFunctionId({
                    ioName: alarmDetails.name,
                    nodeId: functionNode.data.asset.nodeId,
                    ioType: 'alarmInputs',
                }),
            isPublished: false,
            name: alarmDetails.name,
            path: `${functionNode.data.asset.nodeId}.alarmInputs.${alarmDetails.name}`,
        };
        functionNode.data.asset.alarmInputs = [
            ...functionNode.data.asset.alarmInputs,
            { ...circleData },
        ];
        let alarmPin = this.IOController.addAlarmToFunctionInput({
            asset: functionNode.data.asset,
            parent: functionNode,
            nodeDetails: { ...nodeDetails },
            circleData,
        });
        const { x, y } = functionNode.getPointByOrigin('left', 'top');

        let outputsData = {};

        alarmProperties.forEach((property) => {
            outputsData[property.name] = {
                dataType: property.dataType,
            };
        });
        const alarmVersion = convertMajorVersionToFullVersion(alarmDetails.alarmId.split('@')[1]);
        let alarmTypeDetail = new AlarmTypeDetail({
            name: alarmDetails.name,
            nodeId: alarmDetails.name + uuid(),
            position: {
                ...calculateDropDownTitlePosition({
                    position: { x, y },
                    nodeWidth: nodeDetails.nodeWidth,
                }),
            },
            scale: 1,
            typeId: alarmDetails.alarmId.split('@')[0],
            version: alarmVersion,
            modelId: alarmDetails.modelId,
            type: alarmDetails.type,
            properties: {
                inputs: {
                    alarmInput: {
                        dataType: IODataTypes.STRING,
                    },
                },
                outputs: {
                    alarmId: {
                        dataType: IODataTypes.STRING,
                        alarmId: `${alarmDetails.name}/${alarmDetails.alarmId}`,
                    },
                    modelId: {
                        dataType: IODataTypes.STRING,
                        alarmId: `${alarmDetails.name}/${alarmDetails.alarmId}`,
                    },
                    objectId: {
                        dataType: IODataTypes.STRING,
                        alarmId: `${alarmDetails.name}/${alarmDetails.alarmId}`,
                    },
                    ...outputsData,
                },
            },
        });

        // connecte the alarm id pin by default.
        alarmTypeDetail.outputs.forEach((item) => {
            if (item.name === 'alarmId') {
                item.connected = true;
            }
        });
        const dropDownRectangle = this._NodeController.addNode({
            asset: alarmTypeDetail,
        });

        let alarmOutputConnectionDetails = {
            asset: alarmTypeDetail,
            circleData: { ...alarmTypeDetail.outputs[0], connected: true, isPublished: true },
        };

        const line = new FabricConnectionLine({ left: 0, top: 0 });
        line.data = {
            input: { asset: functionNode.data.asset, circleData: { ...circleData } },
            output: alarmOutputConnectionDetails,
            trigger: false,
            historicalDataTrigger: false,
        };
        this._canvas.add(line);
        this._ConnectionController.add(line as Connection);

        const nodeHeight = dropDownRectangle.getScaledHeight();
        const nodeWidth = dropDownRectangle.getScaledWidth();
        let objectTypeOutputAlarmPin: ObjectOutputCircle | undefined;
        if (alarmDetails.type === ALARM_TYPE.SPECIFIC && objectType) {
            const line = new FabricConnectionLine({ left: 0, top: 0 });
            const objectOutputPinDetails = objectType.outputs.filter(
                (item) =>
                    item.alarmId &&
                    item.alarmId.split('/')[1] === alarmDetails.alarmId &&
                    item.id === objectOutputId
            )[0];
            if (objectNode) {
                objectTypeOutputAlarmPin = this.IOController.getObjectOutputPinById({
                    ioId: objectOutputPinDetails.id,
                    nodeId: objectNode.data.asset.nodeId,
                });
            }
            const objectOutputConnectionDetails = {
                asset: objectType,
                circleData: {
                    ...objectOutputPinDetails,
                    connected: true,
                    isPublished: true,
                },
            };
            line.data = {
                input: {
                    asset: alarmTypeDetail,
                    circleData: { ...alarmTypeDetail.inputs[0], connected: true },
                },
                output: objectOutputConnectionDetails,
                trigger: false,
                historicalDataTrigger: false,
            };
            this._canvas.add(line);
            this._ConnectionController.add(line as Connection);
        }

        if (!alarmTypeDetail.isOpen) {
            this._ConnectionController.handleToggleNode({
                nodeHeight,
                nodeWidth,
                nodeId: alarmTypeDetail.nodeId,
                position: { ...alarmTypeDetail.position },
            });
        }

        this._ConnectionController.handleIOMove({
            nodeHeight: nodeDetails.nodeHeight,
            nodeWidth: nodeDetails.nodeWidth,
            ioId: alarmPin.ioCircle.data.circleData.id,
            nodeId: functionNode.data.asset.nodeId,
            position: {
                x: alarmPin.ioCircle.calcTransformMatrix()[4],
                y: alarmPin.ioCircle.calcTransformMatrix()[5],
            },
        });

        if (alarmDetails.type === ALARM_TYPE.SPECIFIC && objectType) {
            if (objectNode) {
                if (objectTypeOutputAlarmPin) {
                    this._ConnectionController.handleIOMove({
                        nodeHeight: objectNode.getScaledHeight(),
                        nodeWidth: objectNode.getScaledWidth(),
                        ioId: objectTypeOutputAlarmPin!.data.circleData.id,
                        nodeId: alarmTypeDetail.nodeId,
                        position: {
                            x: objectTypeOutputAlarmPin.calcTransformMatrix()[4],
                            y: objectTypeOutputAlarmPin.calcTransformMatrix()[5],
                        },
                    });
                }
            } else {
                this._ConnectionController.handleToggleNode({
                    nodeId: objectType.nodeId,
                    nodeHeight,
                    nodeWidth,
                    position: { ...objectType.position },
                });
                this.IOController.handleToggleNode(objectType.nodeId);
            }
        }

        this._ConnectionController.handleRedrawLines(alarmTypeDetail.nodeId);

        let state = store.getState();

        this.markComputeModelDirty();

        if(state.configurationTool.activeView === VIEW_MODE.TABLE){
            state.configurationTool.canvasController && state.configurationTool.canvasController.fromJSON({ json: this.toJSON() });
        }
    }

    addEventInput(options: {
        functionNode: FabricFunctionRectangle;
        eventPayload: AddEventPayload;
    }) {
        const { functionNode, eventPayload } = options;
        const { eventDetails, eventProperties } = eventPayload;
        let nodeDetails = {
            nodeHeight: functionNode.getScaledHeight(),
            nodeWidth: functionNode.getScaledWidth(),
        };

        let circleData = {
            connected: true,
            dataType: IODataTypes.STRING,
            id:
                (eventDetails.type === EVENT_TYPE.GENERIC ? '*' : '') +
                generateFunctionId({
                    ioName: eventDetails.name,
                    nodeId: functionNode.data.asset.nodeId,
                    ioType: 'eventInputs',
                }),
            isPublished: false,
            name: eventDetails.name,
            path: `${functionNode.data.asset.nodeId}.alarmInputs.${eventDetails.name}`,
        };
        functionNode.data.asset.alarmInputs = [
            ...functionNode.data.asset.alarmInputs,
            { ...circleData },
        ];
        let eventPin = this.IOController.addEventToFunctionInput({
            asset: functionNode.data.asset,
            parent: functionNode,
            nodeDetails: { ...nodeDetails },
            circleData,
        });
        const { x, y } = functionNode.getPointByOrigin('left', 'top');

        let outputsData = {};

        eventProperties.forEach((property) => {
            outputsData[property.name] = {
                dataType: property.dataType,
            };
        });
        const eventVersion = convertMajorVersionToFullVersion(eventDetails.eventId.split('@')[1]);
        let eventTypeDetail = new EventTypeDetail({
            name: eventDetails.name,
            nodeId: eventDetails.name + uuid(),
            position: {
                ...calculateDropDownTitlePosition({
                    position: { x, y },
                    nodeWidth: nodeDetails.nodeWidth,
                }),
            },
            scale: 1,
            typeId: eventDetails.eventId,
            version: eventVersion,
            modelId: eventDetails.modelId,
            type: eventDetails.type,
            properties: {
                inputs: {
                    eventInput: {
                        dataType: IODataTypes.STRING,
                    },
                },
                outputs: {
                    eventId: {
                        dataType: IODataTypes.STRING,
                        eventId: `${eventDetails.name}/${eventDetails.eventId}`,
                    },
                    modelId: {
                        dataType: IODataTypes.STRING,
                        eventId: `${eventDetails.name}/${eventDetails.eventId}`,
                    },
                    objectId: {
                        dataType: IODataTypes.STRING,
                        eventId: `${eventDetails.name}/${eventDetails.eventId}`,
                    },
                    ...outputsData,
                },
            },
        });

        // connecte the alarm id pin by default.
        eventTypeDetail.outputs.forEach((item) => {
            if (item.name === 'eventId') {
                item.connected = true;
            }
        });
        const dropDownRectangle = this._NodeController.addNode({
            asset: eventTypeDetail,
        });

        let eventOutputConnectionDetails = {
            asset: eventTypeDetail,
            circleData: { ...eventTypeDetail.outputs[0], connected: true, isPublished: true },
        };

        const line = new FabricConnectionLine({ left: 0, top: 0 });
        line.data = {
            input: { asset: functionNode.data.asset, circleData: { ...circleData } },
            output: eventOutputConnectionDetails,
            trigger: false,
            historicalDataTrigger: false,
        };
        this._canvas.add(line);
        this._ConnectionController.add(line as Connection);

        const nodeHeight = dropDownRectangle.getScaledHeight();
        const nodeWidth = dropDownRectangle.getScaledWidth();
        let objectTypeOutputEventPin: ObjectOutputCircle | undefined;
        if (eventDetails.type === ALARM_TYPE.SPECIFIC) {
            const line = new FabricConnectionLine({ left: 0, top: 0 });
            objectTypeOutputEventPin = this.IOController.getObjectOutputPinById({
                ioId: eventDetails.eventId,
                nodeId: '',
            });
            const objectOutputConnectionDetails = {
                asset: objectTypeOutputEventPin.data.asset,
                circleData: {
                    ...objectTypeOutputEventPin.data.circleData,
                    connected: true,
                    isPublished: true,
                },
            };
            line.data = {
                input: {
                    asset: eventTypeDetail,
                    circleData: { ...eventTypeDetail.inputs[0], connected: true },
                },
                output: objectOutputConnectionDetails,
                trigger: false,
                historicalDataTrigger: false,
            };
        }

        if (!eventTypeDetail.isOpen) {
            this._ConnectionController.handleToggleNode({
                nodeHeight,
                nodeWidth,
                nodeId: eventTypeDetail.nodeId,
                position: { ...eventTypeDetail.position },
            });
        }

        this._ConnectionController.handleIOMove({
            nodeHeight: nodeDetails.nodeHeight,
            nodeWidth: nodeDetails.nodeWidth,
            ioId: eventPin.ioCircle.data.circleData.id,
            nodeId: functionNode.data.asset.nodeId,
            position: {
                x: eventPin.ioCircle.calcTransformMatrix()[4],
                y: eventPin.ioCircle.calcTransformMatrix()[5],
            },
        });

        if (eventDetails.type === ALARM_TYPE.SPECIFIC) {
            const objectTypeDetail = objectTypeOutputEventPin!.data.asset as ObjectTypeDetail;
            const objectRectangle = this._NodeController.getNodeById(objectTypeDetail.nodeId)!;
            this._ConnectionController.handleIOMove({
                nodeHeight: objectRectangle.getScaledHeight(),
                nodeWidth: objectRectangle.getScaledWidth(),
                ioId: objectTypeOutputEventPin!.data.circleData.id,
                nodeId: objectTypeDetail.nodeId,
                position: {
                    x: objectTypeOutputEventPin!.calcTransformMatrix()[4],
                    y: objectTypeOutputEventPin!.calcTransformMatrix()[5],
                },
            });
        }

        this._ConnectionController.handleRedrawLines(eventTypeDetail.nodeId);

        this.markComputeModelDirty();
    }

    /**
     * ! -------  Canvas interacters - View/Edit mode  -------
     * */

    handleViewMode() {
        this.editMode = false;
        this._NodeController.handleViewMode();
        this._canvas.discardActiveObject();
        this.clearSelection();
        this.addGridLines(this.showGrid);
        this.unsubsribeCanvasEvents();
        this._canvas.on('mouse:over', this.handleViewModeMouseOver);
        this._canvas.on('mouse:out', this.handleMouseOut);

        this._canvas.getObjects().forEach((obj) => {
            //@ts-ignore
            
            obj.editModeCursor = obj.hoverCursor || 'default';
            obj.hoverCursor ='default';
        });
        this._canvas.renderAll();
        // }
    }
    handleAddAlarm(options: AddAlarmPayload) {
        const { functionType, objectType, objectOutputId } = options;
        const functionNode = this._NodeController
            .getAllDropDownTitleNodes()
            .filter(
                (titlenode) =>
                    titlenode.data.asset instanceof FunctionTypeDetail &&
                    titlenode.data.asset.nodeId === functionType.nodeId
            )[0];
        let objectNode;
        if (objectType) {
            objectNode = this._NodeController
                .getAllNodes()
                .filter(
                    (node) =>
                        node instanceof FabricObjectRectangle &&
                        node.data.asset.nodeId === objectType.nodeId
                )[0];
        }
        if (functionNode) {
            if (!functionNode.data.dropDownNode) {
                this.handleNodeToggle(
                    functionNode.data.childs.filter(
                        (item) => item instanceof FabricArrowIcon
                    )[0] as FabricArrowIcon
                );
            }
            this.addAlarmInput({
                functionNode: functionNode.data.dropDownNode as FabricFunctionRectangle,
                alarmPayload: options,
                objectNode: objectNode as FabricObjectRectangle,
                objectOutputId,
            });
        }
    }

    handleAddEvent(options: AddEventPayload) {
        const { functionType, objectType } = options;
        const functionNode = this._NodeController
            .getAllNodes()
            .filter(
                (node) =>
                    node instanceof FabricFunctionRectangle &&
                    node.data.asset.nodeId === functionType.nodeId
            )[0];

        if (functionNode) {
            this.addEventInput({
                functionNode: functionNode as FabricFunctionRectangle,
                eventPayload: options,
            });
        }
    }

    handleEditMode() {
        this.editMode = true;
        this.subscribeCanvasEvents();
        this._canvas.getObjects().forEach((obj) => {
            //@ts-ignore
            obj.hoverCursor = obj.editModeCursor || 'default';
        });
        this._NodeController.handleEditMode();
        this._canvas.renderAll();
        // }
    }

    markComputeModelDirty = () => {
        store.dispatch(markComputeModelDirty());
    };

    /**
     * !  ---- Event Subscribe/ Unsubscribe/ Clear Canvas ------
     */

    subscribeCanvasEvents = () => {
        this._canvas.off();
        this._canvas.on('object:moving', this.handleObjectMoving);
        this._canvas.on('object:scaling', this.handleObjectMoving);
        this._canvas.on('mouse:down', this.handleEditModeMouseDown);
        this._canvas.on('mouse:up', this.handleMouseUp);
        this._canvas.on('mouse:move', this.handleMouseMove);
        this._canvas.on('mouse:click', this.handleEditModeMouseClick);
        this._canvas.on('mouse:wheel', this.handleMouseWheel1);
        this._canvas.on('selection:created', this.handleSelectionCreated);
        this._canvas.on('selection:updated', (e) => {
            this.clearSelection();
            this.handleSelectionCreated(e);
        });
        this._canvas.on('mouse:over', this.handleEditModeMouseOver);
        this._canvas.on('mouse:out', this.handleMouseOut);
        this._canvas.on('object:moved', this.handleObjectMoved);
        this._canvas.on('before:render', this.handleBeforeRender);
        this._canvas.on('after:render', this.handleAfterRender);
    };
    subscribeCanvasViewModeEvents = () => {
        this._canvas.off();
        this._canvas.on('mouse:over', this.handleViewModeMouseOver);
        this._canvas.on('mouse:out', this.handleMouseOut);
        this._canvas.on('mouse:wheel', this.handleMouseWheel1);
        this._canvas.on('mouse:click', this.handleViewModeMouseClick);
        this._canvas.on('mouse:down', this.handleViewModeMouseDown);
    };

    handleBeforeRender = () => {
        //@ts-ignore
        this._canvas.clearContext(this._canvas.contextTop);
    };

    handleAfterRender = () => {
        for (var i = this.verticalLines.length; i--;) {
            this.drawVerticalLine(this.verticalLines[i]);
        }
        for (var i = this.horizontalLines.length; i--;) {
            this.drawHorizontalLine(this.horizontalLines[i]);
        }

        if (this.isInVerticalCenter) {
            this.showVerticalCenterLine();
        }
        if (this.isInHorizontalCenter) {
            this.showHorizontalCenterLine();
        }
        this.verticalLines.length = this.horizontalLines.length = 0;
    };
    showVerticalCenterLine() {
        const canvasWidth = this._canvas.getWidth();
        const canvasHeight = this._canvas.getHeight();
        this.showCenterLine(canvasWidth / 2 + 0.5, 0, canvasWidth / 2 + 0.5, canvasHeight);
    }

    showHorizontalCenterLine() {
        const canvasWidth = this._canvas.getWidth();
        const canvasHeight = this._canvas.getHeight();
        this.showCenterLine(0, canvasHeight / 2 + 0.5, canvasWidth, canvasHeight / 2 + 0.5);
    }

    showCenterLine(x1: number, y1: number, x2: number, y2: number) {
        const centerLineColor = 'rgba(255,0,241,0.5)';
        const centerLineWidth = 1;
        const ctx = this._canvas.getSelectionContext();
        ctx.save();
        ctx.strokeStyle = centerLineColor;
        ctx.lineWidth = centerLineWidth;
        ctx.beginPath();
        ctx.moveTo(x1 * this.viewportTransform[0], y1 * this.viewportTransform[3]);
        ctx.lineTo(x2 * this.viewportTransform[0], y2 * this.viewportTransform[3]);
        ctx.stroke();
        ctx.restore();
    }
    unsubsribeCanvasEvents = () => {
        this._canvas.off();
    };

    clearAll() {
        this._canvas.clear();
        this._NodeController.clear();
        this.IOController.clear();
        this._ConnectionController.clear();
    }

    removeGridLines() {
        this._canvas.forEachObject((obj) => {
            if (obj.name && obj.name === 'gridGroup') {
                this._canvas.remove(obj);
            }
        });
    }

    addGridLines(showGrid?: boolean) {
        this.removeGridLines();
        if (showGrid) {
            this.showGrid = true;
            let gridDots = [];
            let zoom = this._canvas.getZoom();
            let height = this._canvas.getHeight();
            let width = this._canvas.getWidth();

            if (zoom < 1) {
                height /= zoom;
                width /= zoom;
            }
            let maxDimensions = Math.max(height, width);
            for (var i = 0; i < maxDimensions / GRID_PIXEL; i++) {
                let dot = new fabric.Line([i * GRID_PIXEL, 0, i * GRID_PIXEL, height], {
                    strokeDashArray: [GRID_DOT_WIDTH, GRID_PIXEL - GRID_DOT_WIDTH],
                    strokeWidth: GRID_DOT_WIDTH,
                    stroke: GRID_COLOR,
                    selectable: false,
                });
                gridDots.push(dot);
            }
            let gridGroup = new fabric.Group(gridDots, {
                selectable: false,
                evented: false,
                name: 'gridGroup',
            });
            this._canvas.add(gridGroup);
            this._canvas.sendToBack(gridGroup);
        } else {
            this.showGrid = false;
            this.removeGridLines();
        }
    }

    /**
     * ! ---- UI Handlers -----
     */

    /**
     * ? ------ Canvas Size handlers -----
     */

    handleCanvasDimensions = (e: IEvent) => {
        const bottomRightCorner = e.target && e.target.oCoords ? e.target.oCoords.br : null;
        let nodeWidth = 0;
        let nodeHeight = 0;
        if (e.target instanceof FabricDropDownTitle) {
            const node = e.target.data.dropDownNode;
            const asset = e.target.data.asset;
            if (asset.isOpen && node) {
                nodeHeight = node.getScaledHeight() * this._canvas.getZoom();
            }
        }
        const scaleX = (e.target && e.target.scaleX) || 1;
        if (bottomRightCorner) {
            const canvasWidth = this._canvas.getWidth();
            const canvasHeight = this._canvas.getHeight();

            if (bottomRightCorner.x + fullFunctionPinLength * scaleX > canvasWidth) {
                if (
                    bottomRightCorner.x + MIN_RIGHT_PADDING + fullFunctionPinLength * scaleX <
                    MAX_CANVAS_WIDTH
                ) {
                    this._canvas.setWidth(
                        bottomRightCorner.x + MIN_RIGHT_PADDING + fullFunctionPinLength * scaleX
                    );
                    if (this._canvasParent) {
                        // this._canvasParent.scrollLeft = this._canvasParent.scrollLeft;
                        this._canvasParent.scrollTo({
                            left: e.target!.left!,
                            behavior: 'smooth',
                        });
                        if (e.target) {
                            e.target.left = e.target.left || 150 - 100;
                        }
                    }
                    this.addGridLines(this.showGrid);
                }
            }

            if (bottomRightCorner.y + nodeHeight + 50 > canvasHeight) {
                if (bottomRightCorner.y + nodeHeight + MIN_BOTTOM_PADDING < MAX_CANVAS_HEIGHT) {
                    this._canvas.setHeight(bottomRightCorner.y + nodeHeight + MIN_BOTTOM_PADDING);
                    if (this._canvasParent) {
                        this._canvasParent.scrollTo({
                            top: e.target!.top!,
                            behavior: 'smooth',
                        });
                        if (e.target) {
                            e.target.top = e.target.top || 150 - 100;
                        }
                    }
                    this.addGridLines(this.showGrid);
                }
            }
        }
    };

    /**
     * ? ----- Other UI handler/ interacters --------
     */

    handleAlarmTriggerIconClick = (target: fabric.Object) => {
        let alarmType = this._NodeController
            .getAllNodes()
            .filter(
                (node) =>
                    node instanceof FabricAlarmRectangle &&
                    node.data.asset.assetName === target.data.circleData.name
            )[0];
        alarmType = alarmType && alarmType.data && alarmType.data.asset;
        if (alarmType) {
            let objectPinName = '';
            let { configurationTool } = store.getState();
            if (alarmType.type === ALARM_TYPE.SPECIFIC) {
                objectPinName = configurationTool.json.connectionData.filter((connection) => {
                    //@ts-ignore
                    if (connection.input.asset.nodeId === alarmType.nodeId) {
                        return true;
                    }
                    return false;
                })[0].output.circleData.name;
            }
            store.dispatch(
                showModal({
                    component: AddAlarmForm,
                    modalTitle: `${
                        window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) ||
                        configurationTool.mode === 'VIEW'
                            ? 'VIEW'
                            : 'EDIT'
                    } ALARM TRIGGER DETAILS`,
                    data: {
                        mode:
                            window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) ||
                            configurationTool.mode === 'VIEW'
                                ? 'VIEW'
                                : 'EDIT',
                        functionType: target.data.asset,
                        alarmType,
                        objectPinName,
                    },
                })
            );
        }
    };

    handleEditModeMouseClick = (e: IEvent) => {
        if (e.target) {
            console.log(e.target);
            const target = e.target;
            let modelDirty = false;
            const state = store.getState();
            if (e.target instanceof FabricArrowIcon) {
                this.handleNodeToggle(e.target);
            }

            if (target instanceof FabricInputTrigger && this.privileges.FUNCTION_TRIGGER_ALLOWED) {
                const circleData = target.data.circleData;
                const fabricFunctionTypeDetail = target.data.asset as FunctionTypeDetail;
                console.log('value1', circleData);
                if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
                    const originalFunctionTypeDetail = state.instanceConfig.originalTypeData;

                    const triggers = getOriginalFunctionTriggerForInstance({
                        nodeId: fabricFunctionTypeDetail.nodeId,
                        originalFunctionTypeDetail,
                    });

                    if (triggers && triggers.variableChange) {
                        const triggerExist = Object.keys(triggers.variableChange).find(
                            (item) => item === circleData.name
                        );
                        if (triggerExist) {
                            const inputTarget = target as FunctionInputTrigger;
                            if (
                                state.configurationTool.overallSeverityFunctionId !==
                                fabricFunctionTypeDetail.nodeId ||
                                (state.configurationTool.overallSeverityFunctionId ===
                                    fabricFunctionTypeDetail.nodeId &&
                                    inputTarget.data.circleData.id !==
                                    fabricFunctionTypeDetail.inputs[0].id)
                            ) {
                                if (inputTarget.data.circleData.connected) {
                                }
                                this._ConnectionController.handleTrigger(inputTarget);
                                modelDirty = true;
                            }
                        } else if (
                            circleData.id.includes('/alarmInputs/') &&
                            circleData.path.includes('.alarmInputs.')
                        ) {
                            this._ConnectionController.handleTrigger(
                                target as FunctionInputTrigger
                            );
                            modelDirty = true;
                        } else {
                            this.handleDisabledTrigger(circleData);
                        }
                    }
                } else {
                    const inputTarget = target as FunctionInputTrigger;
                    if (
                        state.configurationTool.overallSeverityFunctionId !==
                        fabricFunctionTypeDetail.nodeId ||
                        (state.configurationTool.overallSeverityFunctionId ===
                            fabricFunctionTypeDetail.nodeId &&
                            inputTarget.data.circleData.id !==
                            fabricFunctionTypeDetail.inputs[0].id)
                    ) {
                        if (inputTarget.data.circleData.connected) {
                        }
                        this._ConnectionController.handleTrigger(inputTarget);
                        modelDirty = true;
                    }
                }
            } else if (
                target instanceof FabricOutputPublish &&
                this.privileges.FUNCTION_PUBLISH_ALLOWED
            ) {
                const functionDetail = target.data.asset as FunctionTypeDetail;
                const nodeId = functionDetail.nodeId;
                if (state.configurationTool.overallSeverityFunctionId !== nodeId) {
                    if (
                        target.data.asset.conditions &&
                        target.data.circleData.name === FUNCTION_SEVERITY_PIN
                    ) {
                        this.handleSeverityPinPublish(target);
                    } else {
                        this.IOController.handleOutputPublish(target as FunctionOutputPublish);
                    }

                    modelDirty = true;
                }
            } else if (target instanceof FabricCrossIcon) {
                const object = this._selection.currentSelection;
                let warningMessage = DELETE_MODEL_MSG.DELETE_ASSET_MSG;
                if (object) {
                    if (
                        object.data.asset instanceof FunctionTypeDetail &&
                        this.privileges.FUNCTION_DELETE_ALLOWED
                    ) {
                        warningMessage = DELETE_MODEL_MSG.DELETE_FUNCTION_MSG;

                        if (
                            state.configurationTool.overallSeverityFunctionId !==
                            object.data.asset.nodeId
                        ) {
                            ConfirmationMessage({
                                warningMessage,
                                messageModalTitle: 'Delete',
                                acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                                deleteType: DELETE_MODEL_TYPE.FUNCTION,
                                node: object,
                            });
                        }
                    } else if (
                        object.data.asset instanceof ObjectTypeDetail &&
                        this.privileges.OBJECT_DELETE_ALLOWED
                    ) {
                        warningMessage = DELETE_MODEL_MSG.DELETE_OBJECT_MSG;

                        ConfirmationMessage({
                            warningMessage,
                            messageModalTitle: 'Delete',
                            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                            deleteType: DELETE_MODEL_TYPE.OBJECT,
                            node: object,
                        });
                    }
                }
            } else if (target instanceof FabricSettingsIcon) {
            } else if (target instanceof FabricLineDeleteIcon) {
                ConfirmationMessage({
                    warningMessage: deleteFunctionAlarmEventPinWarningMessage({
                        pinName: target.data.circleData.name,
                        type: 'Alarm',
                    }),
                    messageModalTitle: 'Delete',
                    acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                    deleteType: CONFIRMATION_MSG.DELETE_FUNCTION_ALARM_EVENT_INPUT_PIN,
                    alarmEventPinCrossIconTarget: target,
                });
            } else if (target instanceof FabricConnectionLine) {
                const line = target as Connection;
                const inputPinId = line.data.input.circleData.id;
                const inputAsset = line.data.input.asset;
                const outputAsset = line.data.output.asset;
                const isBothAssetOpen = inputAsset.isOpen && outputAsset.isOpen
                if (
                    isConnectionDeleteAllowed(
                        inputPinId,
                        inputAsset,
                        outputAsset,
                        this.privileges.FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED
                    ) && isBothAssetOpen
                ) {

                    ConfirmationMessage({
                        warningMessage: DELETE_MODEL_MSG.DELETE_LINE_MSG,
                        messageModalTitle: 'Delete',
                        acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                        deleteType: DELETE_MODEL_TYPE.CONNECTION_LINE,
                        target: target as Connection,
                    });
                }
            } else if (target instanceof FabricAlarmIcon) {
                if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
                    const originalTypeDetail = state.instanceConfig.originalTypeData;
                    const fabricFunctionTypeDetail = target.data.asset as FunctionTypeDetail;
                    const nodeId = fabricFunctionTypeDetail.nodeId;
                    const alarms = originalTypeDetail!.properties.alarms;
                    if (alarms && alarms[nodeId]) {
                        this.IOController.handleFunctionAlarmPublish(target);
                        modelDirty = true;
                    }
                } else {
                    this.IOController.handleFunctionAlarmPublish(target);
                    modelDirty = true;
                }
            } else if (target instanceof FabricHistoryIcon) {
                const circleData = target.data.circleData as FunctionTypeDetail['inputs'][0];
                const connections = this._ConnectionController.getAllConnectionsById({
                    ioId: circleData.id,
                    nodeId: target.data.asset.nodeId,
                });
                if (connections.length === 1) {
                    this.handleHistoricalData(target);
                }
            } else if (target instanceof FabricRefreshIcon) {
                this.handleFunctionRefresh(target);
            } else if (target instanceof FabricAlarmTriggerIcon) {
                let alarmType = this._NodeController
                    .getAllNodes()
                    .filter(
                        (node) =>
                            node instanceof FabricAlarmRectangle &&
                            node.data.asset.assetName === target.data.circleData.name
                    )[0];
                alarmType = alarmType && alarmType.data && alarmType.data.asset;


                if (alarmType) {
                    let objectPinName = "";
                    let { configurationTool } = store.getState();
                    if (alarmType.type === ALARM_TYPE.SPECIFIC) {
                        objectPinName = configurationTool.json.connectionData.filter(
                            (connection) => {
                                //@ts-ignore
                                if (connection.input.asset.nodeId === alarmType.nodeId) {
                                    return true;
                                }
                                return false;
                            }
                        )[0].output.circleData.name;
                    }
                    store.dispatch(
                        showModal({
                            component: AddAlarmForm,
                            modalTitle: `${
                                window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) ||
                                configurationTool.mode === 'VIEW'
                                    ? 'VIEW'
                                    : 'EDIT'
                            } ALARM TRIGGER DETAILS`,
                            data: {
                                mode:
                                    window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) ||
                                    configurationTool.mode === 'VIEW'
                                        ? 'VIEW'
                                        : 'EDIT',
                                functionType: target.data.asset,
                                alarmType,
                                objectPinName,
                            },
                        })
                    );
                }
            }  else if (e.target instanceof fabric.IText) {
                // e.target.enterEditing();
                // e.target.setSelectionStart(e.target.text!.length);
                // e.target.setSelectionEnd(e.target.text!.length);
                
                console.clear();
                console.log(e.target);
                if (e.target.data.asset instanceof FunctionTypeDetail && e.target.data.ioName) {
                    let functionType = e.target.data.asset;
                    let ioName = e.target.data.ioName;
                    if (functionType.nodeId !== store.getState().configurationTool.overallSeverityFunctionId 
                            && ioName !== SEVERITY_PIN
                            && (!functionType.calculations || !functionType.calculations[ioName])
                    ){
                        if(window.location.pathname === ROUTE_PATHNAME.OBJECTS && _.isEmpty(functionType.alias)) {
                            store.dispatch(showNotificationModal({
                                title: INSTANCE_VALIDATION_MSG,
                                resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                                details: [`The ${functionType.nodeId} function does not have alias name in the model.`,'Please update alias name in the model to edit it in Instance Configuration.'],
                                type: 'confirmation',
                            }))
                        } else {
                            store.dispatch(
                            showModal({
                                component: GetAliasNameInModels,
                                modalTitle: `${e.target.data.ioName}(${e.target.data.asset.nodeId}) Alias`,
                                data: {
                                    functionType,
                                    ioName,
                                    aliasName: !functionType.alias[ioName] 
                                                    || e.target.data.ioName === functionType.alias[ioName] 
                                                        ? ioName 
                                                        : functionType.alias[ioName].value
                                },
                                customClassName: 'delete-library-modal',
                            })
                        );
                        }
                        
                    }
                }
            }
            if (modelDirty) {
                this.markComputeModelDirty();
            }
        }
    };

    handleViewModeMouseClick = (e: IEvent) => {
        if (e.target instanceof FabricArrowIcon) {
            this.handleNodeToggle(e.target);
        } else if (e.target instanceof FabricAlarmTriggerIcon) {
            this.handleAlarmTriggerIconClick(e.target);
        }
    };

    handleSeverityPinPublish(target: FabricOutputPublish) {
        const { modelsPage } = store.getState();
        const nodeId: string = target.data.asset.nodeId;
        const circleData = target.data.circleData;
        const originalSelectedFunction = getOriginalSelectedFuncDetail({
            nodeId,
            json: modelsPage.activeModel.modelInstance.json,
        });

        let isOriginalPublished = false;
        const originalSelectedPin =
            originalSelectedFunction &&
            originalSelectedFunction.outputs.find((item) => item.id === circleData.id);

        if (originalSelectedPin) {
            isOriginalPublished = originalSelectedPin.isPublished || false;
        }

        let originalConditionConfigVariables: string[] | undefined;
        if (originalSelectedFunction) {
            originalConditionConfigVariables =
                originalSelectedFunction.conditionsConfigurationVariables;
        }

        store.dispatch(
            showModal({
                component: ConditionConfiguration,
                modalTitle: MODAL_TITLE.PUBLISH_OUTPUT_CONFIGURATION_TITLE,
                data: {
                    functionConditionDetails: target.data.asset.conditions,
                    customClassName: 'wrapper-message-modal',
                    selectedConditionConfigurationVariables:
                        target.data.asset.conditionsConfigurationVariables,
                    originalConditionConfigVariables,
                    isPublished: target.data.circleData.isPublished,
                    isOriginalPublished: isOriginalPublished,

                    primaryButtonAction: (
                        selectedProperties: string[],
                        isSelectedPropertiesChanged: boolean,
                        currentSelectedProperties: string[]
                    ) => {
                        target.data.asset.conditionsConfigurationVariables = selectedProperties;

                        if (
                            (isOriginalPublished && isSelectedPropertiesChanged) ||
                            (isOriginalPublished && !target.data.circleData.isPublished) ||
                            !isOriginalPublished
                        ) {
                            const reverify =
                                !!currentSelectedProperties.length && isOriginalPublished;
                            this.IOController.handleOutputPublish(
                                target as FunctionOutputPublish,
                                reverify
                            );
                        }
                        this.markComputeModelDirty();
                    },
                },
            })
        );
    }

    handleHistoricalData(target: FabricHistoryIcon) {
        const functionTypeDetail = target.data.asset as FunctionTypeDetail;
        const circleData = target.data.circleData as FunctionTypeDetail['inputs'][0];
        const { timeWindow, samplingAggregate, samplingWindow, limit, id: inputId } = circleData;
        store.dispatch(
            showModal({
                component: HistoryConfiguration,
                modalTitle: `History Configuration`,
                customClassName: 'history-config-custom',
                data: {
                    primaryButtonAction: (historyData: HistoryDataFormValues) => {
                        const { timeWindowState, sampleAggregate, sampleWindowState } = historyData;
                        const historicalDataParams = {
                            nodeId: functionTypeDetail.nodeId,
                            timeWindow: timeWindowState,
                            samplingAggregate: sampleAggregate,
                            samplingWindow: sampleWindowState,
                            limit: historyData.limit,
                            ioId: inputId,
                        };
                        this.IOController.handleHistoricalData({
                            ...historicalDataParams,
                        });
                        this._ConnectionController.handleHistoricalData({
                            ...historicalDataParams,
                        });
                        this.markComputeModelDirty();
                    },
                    timeWindow: timeWindow,
                    samplingAggregate: samplingAggregate,
                    samplingWindow: samplingWindow,
                    limit: limit,
                },
            })
        );
    }
    handleFunctionSettings(target: FabricSettingsIcon) {
        const fabricFunctionRectangle = target.data.parent;
        const {
            nodeId,
            assetName,
            timeTrigger,
            endpoint,
            conditions,
            calculations,
            isEndPointDirty,
        } = fabricFunctionRectangle.data.asset;
        const disabledFields = [];
        const state = store.getState();

        if (!this.privileges.FUNCTION_NAME_CHANGE_ALLOWED) {
            disabledFields.push('functionName');
        }
        if (nodeId === state.configurationTool.overallSeverityFunctionId) {
            disabledFields.push('timeTrigger');
        }

        if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
            const originalFunctionTypeDetail = state.instanceConfig.originalTypeData;
            const triggers = getOriginalFunctionTriggerForInstance({
                originalFunctionTypeDetail,
                nodeId,
            });

            if (!(triggers && triggers.time)) {
                disabledFields.push('timeTrigger');
            }

            if (!isEndPointDirty) {
                disabledFields.push('endPoint');
            }
        }
        if (!this.privileges.FUNCTION_CONDITIONS_LOGIC_CHANGE_ALLOWED) {
            disabledFields.push('logic');
        }
        if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
            disabledFields.push('conditions');
        }

        store.dispatch(
            showModal({
                component: FunctionConfiguration,
                // @ts-ignore
                props: { nodeId },
                modalTitle: `${assetName} Configuration`,
                customClassName: 'functionConfigCustom',

                data: {
                    primaryButtonAction: ({
                        functionName,
                        timeTrigger,
                        endpoint,
                        conditions,
                        calculations,
                        isConditionOverRidden,
                        isEndpointDirty,
                    }: any) => {
                        //fabricFunctionRectangle.data.asset.nodeId = functionName;

                        fabricFunctionRectangle.data.asset.timeTrigger = timeTrigger;
                        fabricFunctionRectangle.data.asset.conditions = conditions;
                        fabricFunctionRectangle.data.asset.calculations = calculations;
                        fabricFunctionRectangle.data.asset.endpoint = endpoint;
                        fabricFunctionRectangle.data.asset.hasOverRiddenConditions =
                            isConditionOverRidden;
                        fabricFunctionRectangle.data.asset.isEndPointDirty = isEndpointDirty;
                        // this.IOController.handleFunctionNameChange({
                        //     oldFunctionName: nodeId,
                        //     newFunctionName: functionName,
                        // });
                        // this._ConnectionController.handleFunctionNameChange({
                        //     oldFunctionName: nodeId,
                        //     newFunctionName: functionName,
                        // });
                        this.markComputeModelDirty();
                        if (fabricFunctionRectangle.data.asset.nodeId !== functionName) {
                            store.dispatch(editNodeIdOfFunction({
                                functionType: fabricFunctionRectangle.data.asset,
                                newName: functionName
                            }));
                        }
                    },

                    functionName: nodeId,
                    timeTrigger: timeTrigger,
                    endpoint: endpoint,
                    conditions: _.cloneDeep(conditions),
                    calculations: calculations,
                    disabledFields,
                    asset: fabricFunctionRectangle.data.asset,
                },
            })
        );
    }

    getConnectionDataByID(options: {
        inputs: FunctionTypeDetail['inputs'];
        inhibits: FunctionTypeDetail['inhibits'];
        nodeId: string;
    }) {
        const { inhibits, inputs, nodeId } = options;
        let connectionData: Connection[] = [];
        inputs.forEach((input) => {
            const inputConnections = this._ConnectionController.getAllConnectionsById({
                ioId: input.id,
                nodeId,
            });
            connectionData = [...connectionData, ...inputConnections];
        });
        inhibits.forEach((inhibit) => {
            const inhibitConnections = this._ConnectionController.getAllConnectionsById({
                ioId: inhibit.id,
                nodeId,
            });
            connectionData = [...connectionData, ...inhibitConnections];
        });
        return connectionData;
    }

    handleFunctionRefresh(target: FabricRefreshIcon) {
        const asset = target.data.parent.data.asset;
        ConfirmationMessage({
            warningMessage: CONFIRMATION_MSG.UPDATE_FUNCTION_WARNING,
            messageModalTitle: CONFIRMATION_MSG.UPDATE_FUNC_TITLE,
            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
            deleteType: CONFIRMATION_MSG.UPDATE_FUNCTION,
            asset,
        });
    }

    handleDisabledTrigger(circleData: FunctionTypeDetail['inputs'][0]) {
        if (circleData.connected) {
            store.dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_MSG.INPUT_TRIGGER_TITLE,
                    data: {
                        warningText: `Please update the model to trigger ${circleData.name}`,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.OK,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    store.dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        }
    }

    updateZoom = (val?: number) => {
        let zoom = this._canvas.getZoom();
        store.dispatch(handleCanvasZoomUpdate(val || zoom));
    };

    eachZoom = (e: any) => {
        let zoom = this._canvas.getZoom();
        let zoom1 = 0;
        console.log('initial zoom', e);
        if (e === 'in') {
            zoom = zoom + 0.2;

            if (zoom <= 2) {
                this._canvas.setZoom(zoom);
            }
        } else if (e === 'out') {
            zoom = zoom - 0.2;

            if (zoom >= 0.15) {
                this._canvas.setZoom(zoom);
            }
        } else if (e === 'reset') {
            zoom = 1;
            this._canvas.setZoom(zoom);
            this.sliderZoom(1);
        }
        this.zoom = zoom;
        this.addGridLines(this.showGrid);
        this.updateZoom();
    };

    sliderZoom = (val: any) => {
        let canvas = this._canvas;
        canvas.setZoom(val);
        this.resizeScroll().then(() => {
            this.addGridLines(this.showGrid);
        });
        this.updateZoom(val);
    };

    handleMouseWheel1 = (options: any) => {
        if (options.e.ctrlKey) {
            var delta = options.e.deltaY;
            console.log('####delta', delta);
            var zoom = this._canvas.getZoom();
            zoom *= 0.999 ** delta;
            console.log('####zoooom', zoom);
            if (zoom >= 0.25 && zoom <= 2) {
                this._canvas.setZoom(zoom);
                this.zoom = zoom;
            }
            this.resizeScroll().then(() => {
                this.addGridLines(this.showGrid);
            });
            this.updateZoom();
            options.e.preventDefault();
            options.e.stopPropagation();
        }
    };

    resetzoom = () => {
        this.sliderZoom(1);
    };

    calculateDimensionsFromObjects = () => {
        let height = 0;
        let width = 0;
        this._canvasParent = document.getElementById('canvas-parent');
        if (this._canvasParent) {
            height = this._canvasParent.offsetHeight;
            width = this._canvasParent.offsetWidth;
        }

        let extraTopMargin = Infinity;
        let extraLeftMargin = Infinity;
        this._canvas.forEachObject((item) => {
            if (
                item instanceof FabricObjectRectangle ||
                item instanceof FabricFunctionRectangle ||
                item instanceof FabricAlarmRectangle ||
                item instanceof FabricEventRectangle ||
                item instanceof FabricDropDownTitle
            ) {
                const asset = item.data.asset;
                const bottomRightCorner = item.oCoords!.br;
                const itemHeight = item.getScaledHeight();
                const itemWidth = item.getScaledWidth();
                const newCanvasHeight = bottomRightCorner.y;

                const newCanvasWidth =
                    asset.position.x + itemWidth / 2 + fullFunctionPinLength + MIN_RIGHT_PADDING;
                const topMargin = asset.position.y - itemHeight / 2 - MIN_TOP_PADDING;
                extraTopMargin = Math.min(extraTopMargin, topMargin);
                extraTopMargin = extraTopMargin > 0 ? extraTopMargin : 0;
                let leftMargin = asset.position.x - itemWidth / 2 - MIN_Left_PADDING;
                if (item instanceof FabricFunctionRectangle) {
                    leftMargin = leftMargin - fullFunctionPinLength;
                }
                extraLeftMargin = Math.min(extraLeftMargin, leftMargin);
                extraLeftMargin = extraLeftMargin > 0 ? extraLeftMargin : 0;

                height = Math.max(height, newCanvasHeight);
                width = Math.max(width, newCanvasWidth);
            }
        });

        return { height, width, extraTopMargin, extraLeftMargin };
    };

    adjustSize = () => {
        const prevZoom = this._canvas.getZoom();
        this.resetzoom();
        const originalJson = _.cloneDeepWith(this.toJSON());
        let isDirty = false;
        let { height, width, extraTopMargin, extraLeftMargin } =
            this.calculateDimensionsFromObjects();
        if (extraLeftMargin > 4 * GRID_PIXEL || extraTopMargin > 4 * GRID_PIXEL) {
            this._canvas.forEachObject((item) => {
                if (
                    item instanceof FabricObjectRectangle ||
                    item instanceof FabricFunctionRectangle ||
                    item instanceof FabricAlarmRectangle ||
                    item instanceof FabricDropDownTitle
                ) {
                    if (extraTopMargin > 4 * GRID_PIXEL) {
                        item.top = item.top! - extraTopMargin;
                    }
                    if (extraLeftMargin > 4 * GRID_PIXEL) {
                        item.left = item.left! - extraLeftMargin;
                        isDirty = true;
                    }

                    const itemHeight = item.getScaledHeight();
                    const itemWidth = item.getScaledWidth();
                    const asset = item.data.asset;
                    item.setCoords();
                    if (
                        item instanceof FabricFunctionRectangle ||
                        item instanceof FabricDropDownTitle
                    ) {
                        item.data.childs.forEach((obj) => obj.data.rePosition());
                    }

                    this.IOController.handleNodeMove({
                        nodeId: asset.nodeId,
                        nodeWidth: itemWidth,
                        position: {
                            x: item.left!,
                            y: item.top!,
                        },
                        scaleY: item.scaleY || 1,
                        scaleX: item.scaleX || 1,
                        nodeHeight: itemHeight,
                    });
                    if (item instanceof FabricDropDownTitle && !item.data.asset.isOpen) {
                        const { x, y } = item.getPointByOrigin('center', 'center');
                        this._ConnectionController.handleToggleNode({
                            nodeId: item.data.asset.nodeId,
                            nodeHeight: item.getScaledHeight(),
                            nodeWidth: item.getScaledWidth(),
                            position: { x, y },
                        });
                        this._ConnectionController.handleRedrawLines(item.data.asset.nodeId);
                    }
                    if (this._selection && this._selection.currentSelection) {
                        // Move the delete cross icon with object
                        const position = this._selection.currentSelection.getPointByOrigin(
                            'right',
                            'top'
                        );
                        this._selection.crossIcon.left = position.x;
                        this._selection.crossIcon.top = position.y;
                        this._selection.crossIcon.oCoords = this._selection.crossIcon.calcCoords();
                    }
                }
            });
        }

        const currentWidth = this._canvas.getWidth();
        const currentHeight = this._canvas.getHeight();
        const updatedWidth = width + BOUNDARY_PADDING - extraLeftMargin;
        const updatedHeight = height + BOUNDARY_PADDING - extraTopMargin;
        if (currentWidth >= updatedWidth || prevZoom !== 1) {
            this._canvas.setWidth(Math.max(updatedWidth, this._canvasParent!.offsetWidth));
        }
        if (currentHeight >= updatedHeight || prevZoom !== 1) {
            this._canvas.setHeight(Math.max(updatedHeight, this._canvasParent!.offsetHeight));
        }
        this.addGridLines(this.showGrid);
        if (isDirty) {
            // this.storeState(originalJson);
            this.markComputeModelDirty();
        }
    };

    /**
     * ? ---------- Tooltip handler ------------
     * */

    handleEditModeMouseOver = (e: IEvent) => {
        console.log('hovered :', e.target);
        if (e.target && e.target.data) {
            if (e.target.data.tooltip) {
                // console.log('hovering on', e.target);
                const matrix = e.target.calcTransformMatrix();

                this._tooltip.set(
                    'left',
                    matrix[4] + 10
                    // e.target.getPointByOrigin('right', 'top').x + 10
                );
                this._tooltip.set(
                    'top',
                    matrix[5] - 20
                    // e.target.getPointByOrigin('right', 'top').y - 5
                );
                this._tooltip.text.set('text', e.target.data.tooltip);
                const textWidth = this._tooltip.text.getScaledWidth();
                this._tooltip.rect.set('width', textWidth + 10);
                this._canvas.add(this._tooltip);
            }
        }
        const popupElement = document.getElementById('configurationPopup');
        if (e.target && e.target instanceof FabricSettingsIcon) {
            this.selectedNode = e.target.data.parent;
            if (popupElement) {
                store.dispatch(
                    showConfigurationPopup({
                        asset: e.target.data.parent.data.asset,
                        handleFunctionSettings: () =>
                            this.handleFunctionSettings(e.target as FabricSettingsIcon),
                    })
                );
                const position = e.target.getLocalPointer(e.e);
                const { x, y } = this.getMousePos(e.e);
                let left = x - position.x + e.target!.getScaledWidth();
                let top = y - position.y + e.target!.getScaledHeight();
                const screenWidth = window.innerWidth;
                const popupElementWidth = popupElement.offsetWidth;
                if (left + popupElementWidth > screenWidth) {
                    left = screenWidth - popupElementWidth - e.target!.getScaledWidth();
                }
                popupElement.style.left = left + 'px';
                popupElement.style.top = top + 'px';
                popupElement.style.visibility = 'visible';
            }
        } else {
            if (popupElement && popupElement.style.visibility === 'visible') {
                store.dispatch(hideConfigurationPopup());
                popupElement.style.visibility = 'hidden';
            }
        }
    };

    handleViewModeMouseOver = (e: IEvent) => {
        console.log('hovered :', e.target);
        if (e.target && e.target.data) {
            if (e.target.data.tooltip) {
                // console.log('hovering on', e.target);
                const matrix = e.target.calcTransformMatrix();

                this._tooltip.set(
                    'left',
                    matrix[4] + 10
                    // e.target.getPointByOrigin('right', 'top').x + 10
                );
                this._tooltip.set(
                    'top',
                    matrix[5] - 20
                    // e.target.getPointByOrigin('right', 'top').y - 5
                );
                this._tooltip.text.set('text', e.target.data.tooltip);
                const textWidth = this._tooltip.text.getScaledWidth();
                this._tooltip.rect.set('width', textWidth + 10);
                this._canvas.add(this._tooltip);
            }
            if(e.target instanceof FabricInputTrigger || FabricOutputPublish || FabricOutputAlarmIcon){
                e.target.hoverCursor='not-allowed'
            }
            if(e.target instanceof  FabricArrowIcon ){
                e.target.hoverCursor='default'
            }
        }
    };

    getMousePos(evt: any) {
        return {
            x: evt.clientX,
            y: evt.clientY,
        };
    }
    /** Tooltip handler */
    handleMouseOut = (e: any) => {
        if (e.target && e.target.data) {
            if (e.target.data.tooltip) {
                this._canvas.remove(this._tooltip);
            }
        }
    };

    /**
     * ? --------- Selection handlers ------------
     *  */

    handleSelectionCreated = (e: IEvent) => {
        this.clearSelection();
        if (
            e.target &&
            ((e.target instanceof FabricDropDownTitle &&
                e.target.data.asset instanceof ObjectTypeDetail &&
                this.privileges.OBJECT_DELETE_ALLOWED) ||
                (e.target instanceof FabricDropDownTitle &&
                    e.target.data.asset instanceof FunctionTypeDetail &&
                    this.privileges.FUNCTION_DELETE_ALLOWED))
        ) {
            const target = e.target;
            this._selection.currentSelection = target;
            target.getObjects().forEach((object: any) => {
                if (object.name === 'rect') {
                    object.set('backgroundColor', canvasSecondaryBorderColor);
                }
            });
            if (target instanceof FabricFunctionRectangle) {
                target.data.childs.forEach((child: any) => {
                    if (child instanceof FabricPublishIcon) {
                        child.set('visible', false);
                    }
                });
            }

            const position = e.target.getPointByOrigin('right', 'top');
            this._selection.crossIcon.left = position.x;
            this._selection.crossIcon.top = position.y;

            this._canvas.add(this._selection.crossIcon);
        }
    };

    clearSelection = () => {
        if (this._selection.currentSelection) {
            console.log(
                'getobject',
                this._selection.currentSelection.getObjects(),
                this._selection.currentSelection
            );
            this._selection.currentSelection.getObjects().forEach((object: any) => {
                if (object.name === 'rect') {
                    object.set('backgroundColor', 'transparent');
                }
            });

            if ('childs' in this._selection.currentSelection.data) {
                console.log('getvalue', this._selection.currentSelection.data.childs);
                this._selection.currentSelection.data.childs.forEach((child: any) => {
                    if (child instanceof FabricPublishIcon) {
                        child.set('visible', true);
                    }
                });
            }

            this._canvas.remove(this._selection.crossIcon);
            this._canvas.renderAll();
            this._selection.currentSelection = null;
        }
    };

    handleDeleteSelection = () => {
        if (this._selection && this._selection.currentSelection) {
            this._canvas.remove(this._selection.crossIcon);
            this._canvas.remove(this._selection.currentSelection);
            if ('childs' in this._selection.currentSelection.data) {
                this._selection.currentSelection.data.childs.forEach((child: fabric.Object) =>
                    this._canvas.remove(child)
                );
            }
            this._NodeController.handleDeleteNode(
                this._selection.currentSelection.data.asset.nodeId
            );

            const alarmEventBlockIds = this._ConnectionController.getAssociatedAlarmEventBlocks(
                this._selection.currentSelection.data.asset.nodeId
            );
            this.IOController.handleDeleteNode(this._selection.currentSelection.data.asset.nodeId);
            const affectedNodes = this._ConnectionController.handleDeleteNode(
                this._selection.currentSelection.data.asset.nodeId
            );
            this.IOController.handleResetTrigger(affectedNodes);
            this._selection.currentSelection = null;

            alarmEventBlockIds.forEach((nodeId) => {
                this._NodeController.handleDeleteNode(nodeId);
                this.IOController.handleDeleteNode(nodeId);
                const affectedNodes = this._ConnectionController.handleDeleteNode(nodeId);
                console.log({ affectedNodes });
                affectedNodes.forEach((affectedNode) => {
                    if (
                        affectedNode.ioId.includes('alarmInputs') ||
                        affectedNode.ioId.includes('eventInputs')
                    ) {
                        const node = this._NodeController.getNodeById(affectedNode.nodeId);
                        if (
                            node &&
                            node instanceof FabricFunctionRectangle &&
                            node.data.asset.isOpen
                        ) {
                            this.IOController.handleAlarmPinDelete({
                                ioId: affectedNode.ioId,
                                asset: node.data.asset,
                                parent: node,
                            });
                        } else {
                            const node = this._NodeController.getDropDownTitleNodesById(
                                affectedNode.nodeId
                            );
                            if (node && node.data.asset instanceof FunctionTypeDetail) {
                                const asset: FunctionTypeDetail = node.data.asset;
                                const alarmInputs = asset.alarmInputs.filter(
                                    (alarmInput) => alarmInput.id !== affectedNode.ioId
                                );
                                node.data.asset.alarmInputs = [...alarmInputs];
                            }
                        }
                    }
                });
                this.IOController.handleResetTrigger(affectedNodes);
            });
        }
        this.markComputeModelDirty();
    };

    /**
     * ? --------- Object position change handlers ---------
     *  */

    handleObjectMoved = (e: IEvent) => {
        console.log('object:moved');

        this.handleCanvasDimensions(e);

        if (e.target instanceof FabricDropDownTitle) {
            this._ConnectionController.handleRedrawLines(e.target.data.asset.nodeId);
        }

        this.markComputeModelDirty();
    };

    isInRange(value1: number, value2: number) {
        value1 = Math.round(value1);
        value2 = Math.round(value2);
        for (
            var i = value1 - ALIGNING_LINE_MARGIN, len = value1 + ALIGNING_LINE_MARGIN;
            i <= len;
            i++
        ) {
            if (i === value2) {
                return true;
            }
        }
        return false;
    }
    drawLine(x1: number, y1: number, x2: number, y2: number) {
        const ctx = this._canvas.getSelectionContext();
        const zoom = this._canvas.getZoom();
        ctx.save();
        ctx.lineWidth = ALIGNING_LINE_WIDTH;
        ctx.strokeStyle = ALIGNING_LINE_COLOR;
        ctx.beginPath();
        ctx.moveTo(
            (x1 + this.viewportTransform[4]) * zoom,
            (y1 + this.viewportTransform[5]) * zoom
        );
        ctx.lineTo(
            (x2 + this.viewportTransform[4]) * zoom,
            (y2 + this.viewportTransform[5]) * zoom
        );
        ctx.stroke();
        ctx.restore();
    }
    drawVerticalLine(coords: { x: number; y1: number; y2: number }) {
        this.drawLine(
            coords.x + 0.5,
            coords.y1 > coords.y2 ? coords.y2 : coords.y1,
            coords.x + 0.5,
            coords.y2 > coords.y1 ? coords.y2 : coords.y1
        );
    }

    drawHorizontalLine(coords: { x1: number; x2: number; y: number }) {
        this.drawLine(
            coords.x1 > coords.x2 ? coords.x2 : coords.x1,
            coords.y + 0.5,
            coords.x2 > coords.x1 ? coords.x2 : coords.x1,
            coords.y + 0.5
        );
    }

    handleObjectScaling = (e: IEvent) => {
        if (
            e.target &&
            (e.target instanceof FabricObjectRectangle ||
                e.target instanceof FabricFunctionRectangle ||
                e.target instanceof FabricDropDownTitle)
        ) {
            if (e.target.scaleX && e.target.scaleY) {
                const scaleX = e.target.scaleX;
                const scaleY = e.target.scaleY;
                if (
                    scaleX > OBJECT_SCALE_LIMIT.MAX_VALUE ||
                    scaleY > OBJECT_SCALE_LIMIT.MAX_VALUE
                ) {
                    e.target.scaleX = OBJECT_SCALE_LIMIT.MAX_VALUE;
                    e.target.scaleY = OBJECT_SCALE_LIMIT.MAX_VALUE;
                }
            }
        }
    };

    handleObjectMoving = (e: IEvent) => {
        // this.handleCanvasDimensions(e);

        // checking for overlap of objects .
        if (
            e.target &&
            (e.target instanceof FabricObjectRectangle ||
                e.target instanceof FabricFunctionRectangle ||
                e.target instanceof FabricDropDownTitle)
        ) {
            // @ts-ignore

            // handle object flipping when scaling the canvas object .
            e.target.flipX = false;
            e.target.flipY = false;

            const nodeWidth = e.target.getScaledWidth();
            const nodeHeight = e.target.getScaledHeight();

            if (e.target.scaleX && e.target.scaleY) {
                const scaleX = e.target.scaleX;
                const scaleY = e.target.scaleY;

                if (
                    // @ts-ignore
                    scaleX > e.target.maxScaleLimit ||
                    // @ts-ignore
                    scaleY > e.target.maxScaleLimit
                ) {
                    // @ts-ignore
                    e.target.scaleX = e.target.maxScaleLimit;
                    // @ts-ignore
                    e.target.scaleY = e.target.maxScaleLimit;
                }
            }

            this._canvas.forEachObject((obj) => {
                if (
                    // obj instanceof FabricFunctionRectangle ||
                    // obj instanceof FabricObjectRectangle ||
                    obj instanceof FabricDropDownTitle
                ) {
                    if (obj !== e.target) {
                        let isOverlapped = true;

                        if (e.target) {
                            let l1 = e.target.getPointByOrigin('left', 'top');
                            let l2 = obj.getPointByOrigin('left', 'top');
                            let r1 = e.target.getPointByOrigin('right', 'bottom');
                            let r2 = obj.getPointByOrigin('right', 'bottom');

                            if (l2.x > r1.x || l1.x > r2.x) {
                                isOverlapped = false;
                            }
                            if (l2.y > r1.y || l1.y > r2.y) {
                                isOverlapped = false;
                            }

                            if (isOverlapped) {
                                if (
                                    r2.x - l1.x ===
                                    Math.min(
                                        r2.x - l1.x,
                                        r1.x - r2.x,
                                        r2.y - l1.y,
                                        r1.y - l2.y
                                    ) ||
                                    r1.x - r2.x ===
                                    Math.min(r1.x - r2.x, r2.x - l1.x, r2.y - l1.y, r1.y - l2.y)
                                ) {
                                    //overlapping from right

                                    e.target.set('left', r2.x + 10 + (r1.x - l1.x) / 2);
                                }

                                if (
                                    r1.x - l2.x ===
                                    Math.min(r1.x - l2.x, l2.x - l1.x, r2.y - l1.y, r1.y - l2.y)
                                ) {
                                    // console.log("overlapping from left");

                                    e.target.left = l2.x - 10 - (r1.x - l1.x) / 2;
                                }
                                if (
                                    r1.y - l2.y ===
                                    Math.min(r1.y - l2.y, r2.y - l1.y, r1.x - l2.x, r2.x - l1.x)
                                ) {
                                    // overlapping from top
                                    e.target.top = l2.y - 10 - (r1.y - l1.y) / 2;
                                    e.target.left = l1.x + (r1.x - l1.x) / 2;
                                }
                                if (
                                    r2.y - l1.y ===
                                    Math.min(r2.y - l1.y, r1.x - l2.x, r2.x - l1.x)
                                ) {
                                    // overlapping from bottom
                                    e.target.top = r2.y + 10 + (r1.y - l1.y) / 2;
                                    e.target.left = l1.x + (r1.x - l1.x) / 2;
                                }
                            }
                        }
                    }
                }
            });

            const { updatedPosition: pos } = handleMaxCanvasWidthHeightLimit({
                nodeWidth,
                nodeHeight,
                nodePosition: {
                    x: e.target.left!,
                    y: e.target.top!,
                },
            });
            e.target.left = pos.x;
            e.target.top = pos.y;
        }
        if (e.target && e.target instanceof FabricDropDownTitle) {
            const height = e.target.getScaledHeight();
            if (e.target.top! < e.target.getScaledHeight() / 2 + MIN_TOP_PADDING) {
                e.target.top = e.target.getScaledHeight() / 2 + MIN_TOP_PADDING;
            }
            if (e.target.left! < e.target.getScaledWidth() / 2 + MIN_Left_PADDING) {
                e.target.left = e.target.getScaledWidth() / 2 + MIN_Left_PADDING;
            }
            if (e.target.data.asset instanceof FunctionTypeDetail) {
                if (
                    e.target.left! <
                    e.target.getScaledWidth() / 2 +
                    e.target.scaleX! * fullFunctionPinLength +
                    MIN_Left_PADDING
                ) {
                    e.target.left =
                        e.target.getScaledWidth() / 2 +
                        e.target.scaleX! * fullFunctionPinLength +
                        MIN_Left_PADDING;
                }
            }
            const { x, y } = e.target.getPointByOrigin('center', 'center');

            const dropDownTitle = e.target;
            dropDownTitle.data.childs.forEach((child) => child.data.rePosition());
            const asset = dropDownTitle.data.asset;
            if (asset.isOpen) {
                const node = dropDownTitle.data.dropDownNode!;
                const nodeHeight = node.getScaledHeight();
                const position = { x: x, y: y + nodeHeight / 2 + height / 2 };
                node.top = y + nodeHeight / 2 + height / 2;
                node.left = x;
                node.scaleX = dropDownTitle.scaleX;
                node.scaleY = dropDownTitle.scaleY;
                if (node instanceof FabricFunctionRectangle) {
                    node.data.childs.forEach((obj) => {
                        obj.data.rePosition();
                    });
                }
                node.setCoords();
                this.IOController.handleNodeMove({
                    nodeId: node.data.asset.nodeId,
                    nodeWidth: node.getScaledWidth(),
                    position,
                    scaleY: node.scaleY || 1,
                    scaleX: node.scaleX || 1,
                    nodeHeight: node.getScaledHeight(),
                });
            } else {
                this._ConnectionController.handleToggleNode({
                    nodeId: asset.nodeId,
                    nodeHeight: dropDownTitle.getScaledHeight(),
                    nodeWidth: dropDownTitle.getScaledWidth(),
                    position: { x, y },
                });
            }
            // Move the delete cross icon with object
            if (this._selection && this._selection.currentSelection) {
                const position = this._selection.currentSelection.getPointByOrigin('right', 'top');
                this._selection.crossIcon.left = position.x;
                this._selection.crossIcon.top = position.y;
                this._selection.crossIcon.oCoords = this._selection.crossIcon.calcCoords();
            }
        }
        // aligining guidlines.
        var activeObject = e.target,
            canvasObjects = this._canvas.getObjects(),
            activeObjectCenter = activeObject!.getCenterPoint(),
            activeObjectLeft = activeObjectCenter.x,
            activeObjectTop = activeObjectCenter.y,
            activeObjectBoundingRect = activeObject!.getBoundingRect(),
            activeObjectHeight = activeObjectBoundingRect.height / this.viewportTransform[3],
            activeObjectWidth = activeObjectBoundingRect.width / this.viewportTransform[0],
            horizontalInTheRange = false,
            verticalInTheRange = false;
        //@ts-ignore
        // transform = canvas._currentTransform;

        // if (!transform) return;

        // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
        // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

        for (var i = canvasObjects.length; i--;) {
            if (
                canvasObjects[i] === activeObject ||
                !(
                    // canvasObjects[i] instanceof FabricFunctionRectangle ||
                    // canvasObjects[i] instanceof FabricObjectRectangle   ||
                    (canvasObjects[i] instanceof FabricDropDownTitle)
                )
            )
                continue;
            var objectCenter = canvasObjects[i].getCenterPoint(),
                objectLeft = objectCenter.x,
                objectTop = objectCenter.y,
                objectBoundingRect = canvasObjects[i].getBoundingRect(),
                objectHeight = objectBoundingRect.height / this.viewportTransform[3],
                objectWidth = objectBoundingRect.width / this.viewportTransform[0];
            // snap by the horizontal center line
            if (this.isInRange(objectLeft, activeObjectLeft)) {
                verticalInTheRange = true;
                this.verticalLines.push({
                    x: objectLeft,
                    y1:
                        objectTop < activeObjectTop
                            ? objectTop - objectHeight / 2 - ALIGNING_LINE_OFFSET
                            : objectTop + objectHeight / 2 + ALIGNING_LINE_OFFSET,
                    y2:
                        activeObjectTop > objectTop
                            ? activeObjectTop + activeObjectHeight / 2 + ALIGNING_LINE_OFFSET
                            : activeObjectTop - activeObjectHeight / 2 - ALIGNING_LINE_OFFSET,
                });
                activeObject!.setPositionByOrigin(
                    new fabric.Point(objectLeft, activeObjectTop),
                    'center',
                    'center'
                );
            }

            // snap by the left edge
            if (
                this.isInRange(
                    objectLeft - objectWidth / 2,
                    activeObjectLeft - activeObjectWidth / 2
                )
            ) {
                verticalInTheRange = true;
                this.verticalLines.push({
                    x: objectLeft - objectWidth / 2,
                    y1:
                        objectTop < activeObjectTop
                            ? objectTop - objectHeight / 2 - ALIGNING_LINE_OFFSET
                            : objectTop + objectHeight / 2 + ALIGNING_LINE_OFFSET,
                    y2:
                        activeObjectTop > objectTop
                            ? activeObjectTop + activeObjectHeight / 2 + ALIGNING_LINE_OFFSET
                            : activeObjectTop - activeObjectHeight / 2 - ALIGNING_LINE_OFFSET,
                });
                activeObject!.setPositionByOrigin(
                    new fabric.Point(
                        objectLeft - objectWidth / 2 + activeObjectWidth / 2,
                        activeObjectTop
                    ),
                    'center',
                    'center'
                );
            }

            // snap by the right edge
            if (
                this.isInRange(
                    objectLeft + objectWidth / 2,
                    activeObjectLeft + activeObjectWidth / 2
                )
            ) {
                verticalInTheRange = true;
                this.verticalLines.push({
                    x: objectLeft + objectWidth / 2,
                    y1:
                        objectTop < activeObjectTop
                            ? objectTop - objectHeight / 2 - ALIGNING_LINE_OFFSET
                            : objectTop + objectHeight / 2 + ALIGNING_LINE_OFFSET,
                    y2:
                        activeObjectTop > objectTop
                            ? activeObjectTop + activeObjectHeight / 2 + ALIGNING_LINE_OFFSET
                            : activeObjectTop - activeObjectHeight / 2 - ALIGNING_LINE_OFFSET,
                });
                activeObject!.setPositionByOrigin(
                    new fabric.Point(
                        objectLeft + objectWidth / 2 - activeObjectWidth / 2,
                        activeObjectTop
                    ),
                    'center',
                    'center'
                );
            }

            // snap by the vertical center line
            if (this.isInRange(objectTop, activeObjectTop)) {
                horizontalInTheRange = true;
                this.horizontalLines.push({
                    y: objectTop,
                    x1:
                        objectLeft < activeObjectLeft
                            ? objectLeft - objectWidth / 2 - ALIGNING_LINE_OFFSET
                            : objectLeft + objectWidth / 2 + ALIGNING_LINE_OFFSET,
                    x2:
                        activeObjectLeft > objectLeft
                            ? activeObjectLeft + activeObjectWidth / 2 + ALIGNING_LINE_OFFSET
                            : activeObjectLeft - activeObjectWidth / 2 - ALIGNING_LINE_OFFSET,
                });
                activeObject!.setPositionByOrigin(
                    new fabric.Point(activeObjectLeft, objectTop),
                    'center',
                    'center'
                );
            }

            // snap by the top edge
            if (
                this.isInRange(
                    objectTop - objectHeight / 2,
                    activeObjectTop - activeObjectHeight / 2
                )
            ) {
                horizontalInTheRange = true;
                this.horizontalLines.push({
                    y: objectTop - objectHeight / 2,
                    x1:
                        objectLeft < activeObjectLeft
                            ? objectLeft - objectWidth / 2 - ALIGNING_LINE_OFFSET
                            : objectLeft + objectWidth / 2 + ALIGNING_LINE_OFFSET,
                    x2:
                        activeObjectLeft > objectLeft
                            ? activeObjectLeft + activeObjectWidth / 2 + ALIGNING_LINE_OFFSET
                            : activeObjectLeft - activeObjectWidth / 2 - ALIGNING_LINE_OFFSET,
                });
                activeObject!.setPositionByOrigin(
                    new fabric.Point(
                        activeObjectLeft,
                        objectTop - objectHeight / 2 + activeObjectHeight / 2
                    ),
                    'center',
                    'center'
                );
            }

            // snap by the bottom edge
            if (
                this.isInRange(
                    objectTop + objectHeight / 2,
                    activeObjectTop + activeObjectHeight / 2
                )
            ) {
                horizontalInTheRange = true;
                this.horizontalLines.push({
                    y: objectTop + objectHeight / 2,
                    x1:
                        objectLeft < activeObjectLeft
                            ? objectLeft - objectWidth / 2 - ALIGNING_LINE_OFFSET
                            : objectLeft + objectWidth / 2 + ALIGNING_LINE_OFFSET,
                    x2:
                        activeObjectLeft > objectLeft
                            ? activeObjectLeft + activeObjectWidth / 2 + ALIGNING_LINE_OFFSET
                            : activeObjectLeft - activeObjectWidth / 2 - ALIGNING_LINE_OFFSET,
                });
                activeObject!.setPositionByOrigin(
                    new fabric.Point(
                        activeObjectLeft,
                        objectTop + objectHeight / 2 - activeObjectHeight / 2
                    ),
                    'center',
                    'center'
                );
            }
        }

        if (!horizontalInTheRange) {
            this.horizontalLines.length = 0;
        }

        if (!verticalInTheRange) {
            this.verticalLines.length = 0;
        }

        const canvasWidth = this._canvas.getWidth();
        const canvasHeight = this._canvas.getHeight();
        const centerLineMargin = 4;
        for (
            var i = canvasWidth / 2 - centerLineMargin, len = canvasWidth / 2 + centerLineMargin;
            i <= len;
            i++
        ) {
            this.canvasWidthCenterMap[Math.round(i)] = true;
        }
        for (
            var i = canvasHeight / 2 - centerLineMargin, len = canvasHeight / 2 + centerLineMargin;
            i <= len;
            i++
        ) {
            this.canvasHeightCenterMap[Math.round(i)] = true;
        }
        var object = e.target;
        if (object) {
            (objectCenter = object.getCenterPoint()),
                //@ts-ignore
                // (transform = canvas._currentTransform);
                // if (!transform) return;

                (this.isInVerticalCenter = Math.round(objectCenter.x) in this.canvasWidthCenterMap),
                (this.isInHorizontalCenter =
                    Math.round(objectCenter.y) in this.canvasHeightCenterMap);

            // if (this.isInHorizontalCenter || this.isInVerticalCenter) {
            //     object!.setPositionByOrigin(
            //         new fabric.Point(
            //             this.isInVerticalCenter ? canvasWidth / 2 : objectCenter.x,
            //             this.isInHorizontalCenter ? canvasHeight / 2 : objectCenter.y
            //         ),
            //         'center',
            //         'center'
            //     );
            // }
        }

        // Move the IO on object move
        if (e.target && e.target instanceof FabricDropDownTitle) {
            const height = e.target.getScaledHeight();
            if (e.target.top! < e.target.getScaledHeight() / 2 + MIN_TOP_PADDING) {
                e.target.top = e.target.getScaledHeight() / 2 + MIN_TOP_PADDING;
            }
            if (e.target.left! < e.target.getScaledWidth() / 2 + MIN_Left_PADDING) {
                e.target.left = e.target.getScaledWidth() / 2 + MIN_Left_PADDING;
            }
            if (e.target.data.asset instanceof FunctionTypeDetail) {
                if (
                    e.target.left! <
                    e.target.getScaledWidth() / 2 +
                    e.target.scaleX! * fullFunctionPinLength +
                    MIN_Left_PADDING
                ) {
                    e.target.left =
                        e.target.getScaledWidth() / 2 +
                        e.target.scaleX! * fullFunctionPinLength +
                        MIN_Left_PADDING;
                }
            }
            const { x, y } = e.target.getPointByOrigin('center', 'center');

            const dropDownTitle = e.target;
            dropDownTitle.data.childs.forEach((child) => child.data.rePosition());
            const asset = dropDownTitle.data.asset;
            if (asset.isOpen) {
                const node = dropDownTitle.data.dropDownNode!;
                const nodeHeight = node.getScaledHeight();
                const position = { x: x, y: y + nodeHeight / 2 + height / 2 };
                node.top = y + nodeHeight / 2 + height / 2;
                node.left = x;
                node.scaleX = dropDownTitle.scaleX;
                node.scaleY = dropDownTitle.scaleY;
                if (node instanceof FabricFunctionRectangle) {
                    node.data.childs.forEach((obj) => {
                        obj.data.rePosition();
                    });
                }
                node.setCoords();
                this.IOController.handleNodeMove({
                    nodeId: node.data.asset.nodeId,
                    nodeWidth: node.getScaledWidth(),
                    position,
                    scaleY: node.scaleY || 1,
                    scaleX: node.scaleX || 1,
                    nodeHeight: node.getScaledHeight(),
                });
            } else {
                this._ConnectionController.handleToggleNode({
                    nodeId: asset.nodeId,
                    nodeHeight: dropDownTitle.getScaledHeight(),
                    nodeWidth: dropDownTitle.getScaledWidth(),
                    position: { x, y },
                });
            }
            // Move the delete cross icon with object
            if (this._selection && this._selection.currentSelection) {
                const position = this._selection.currentSelection.getPointByOrigin('right', 'top');
                this._selection.crossIcon.left = position.x;
                this._selection.crossIcon.top = position.y;
                this._selection.crossIcon.oCoords = this._selection.crossIcon.calcCoords();
            }
        }
    };

    /**
     * ? ----------Connection handlers------------
     * */

    handleEditModeMouseDown = (e: IEvent) => {
        console.log('mouseDown', e.target);

        if (e.target) {
            if (e.target instanceof FabricIOCircle) {
                const target = e.target;
                const fieldName =
                    target instanceof FabricOutputCircle ? IOTypes.OUTPUT : IOTypes.INPUT;

                if (
                    fieldName === IOTypes.OUTPUT &&
                    (target.data.asset instanceof ObjectTypeDetail ||
                        (target.data.asset instanceof FunctionTypeDetail &&
                            this.privileges.FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED) ||
                        (target.data.asset instanceof AlarmTypeDetail &&
                            this.privileges.FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED) ||
                        (target.data.asset instanceof EventTypeDetail &&
                            this.privileges.FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED)) &&
                    !e.target!.data.circleData.alarmId
                ) {
                    const left = target.calcTransformMatrix()[4];
                    const top = target.calcTransformMatrix()[5];

                    const line = new FabricConnectionLine({ left, top });
                    line.data = { [fieldName]: target.data };
                    this._canvas.add(line);
                    this._currentConnection = line;
                }
            }
        }

        if (
            !(e.target instanceof FabricCrossIcon) &&
            e.target !== this._selection.currentSelection
        ) {
            this.clearSelection();
        }

        this.handleEditModeMouseClick(e);
        this.viewportTransform = this._canvas.viewportTransform || [];
        // zoom = canvas.getZoom();
    };

    handleViewModeMouseDown = (e: IEvent) => {
        console.log('mouseDown', e.target);
        this.handleViewModeMouseClick(e);
        // zoom = canvas.getZoom();
    };

    showConfirmationDialog = (input: any) => {
        store.dispatch(
            showDialog({
                component: MessageModal,
                modalTitle: CONFIRMATION_BUTTON.CONFIRM,
                customClassName: 'wrapper-message-modal',
                data: {
                    warningText: CONFIRMATION_INITIAL_VALUE,
                    standardButtonsOnBottom: [
                        {
                            text: CONFIRMATION_BUTTON.CANCEL,
                            type: 'discreet-black',
                            handler: (dlg: any) => {
                                store.dispatch(hideDialog());
                            },
                        },
                        {
                            text: CONFIRMATION_BUTTON.OK,
                            type: 'primary-blue',
                            handler: (dlg: any) => {
                                // store.dispatch(cancelUnsavedModelChanges());

                                // input.defaultValue = input.defaultValue + ",0";
                                // props.addDefaultValueConfigurationTool({
                                //     circleData: input,
                                //     asset,
                                //     defaultValue: input.defaultValue + ",0"
                                // });
                                input.input.circleData.defaultValue = input.input.circleData.defaultValue + ",0"

                                store.dispatch(addConnectionConfigurationTool({
                                    outputAsset: input.output.asset,
                                    outputCircleData: input.output.circleData,
                                    inputCircleData: input.input.circleData,
                                    inputAsset: input.input.asset,
                                }));
                                store.dispatch(addDefaultValueConfigurationTool({
                                    circleData: input.input.circleData,
                                    asset: input.input.asset,
                                    defaultValue: input.input.circleData.defaultValue,
                                }));
                                store.dispatch(hideDialog());
                            },
                        },
                    ],
                },
            })
        );
    }

    handleMouseUp = (e: IEvent) => {
        if (e.target) {
            // snap to grid

            if (e.target instanceof FabricIOCircle && this._currentConnection) {
                const id: string = e.target.data.circleData.id;
                if (!isManualConnectionAllowed(id, e.target.data.asset)) {
                    this.removeCurrentConnection();
                    return;
                }
                const target = e.target;
                const targetData = target.data;

                const fieldName =
                    target instanceof FabricOutputCircle ? IOTypes.OUTPUT : IOTypes.INPUT;
                console.log(fieldName);
                this._currentConnection.data[fieldName] = targetData;
                const validConnection = connectionChecker({
                    inputData: this._currentConnection.data[IOTypes.INPUT],
                    outputData: this._currentConnection.data[IOTypes.OUTPUT],
                    ConnectionController: this._ConnectionController,
                });

                if (this._currentConnection && validConnection) {
                    console.log('currentConnection  =>', this._currentConnection);
                    console.log(
                        '*****----******',
                        this._currentConnection.data.input.asset ===
                        this._currentConnection.data.output.asset
                    );
                    // @ts-ignore
                    const isSame =
                        this._currentConnection.data.input.asset ===
                        this._currentConnection.data.output.asset;

                    // handle curved line depth .
                    // if(this._currentConnection.)
                    this._currentConnection.path = calcLineCurve(
                        // @ts-ignore
                        this._currentConnection.path,
                        isSame
                    );

                    this._currentConnection.rePosition();

                    this._canvas.remove(this._currentConnection);
                    this._canvas.add(this._currentConnection);

                    this._ConnectionController.add(this._currentConnection as Connection);
                    let allConnections = this._ConnectionController.getAllConnectionsById(
                        {
                            ioId: this._currentConnection.data.input.circleData.id,
                            nodeId: this._currentConnection.data.input.asset.nodeId,
                        }
                    );
                    store.dispatch(getSelectedFunctionDetails({
                        assetType: e.target.data.asset.assetType,
                        assetRef: e.target.data.asset.assetRef,
                        assetVersion: e.target.data.asset.assetVersion
                    }));

                    const storeVal = store.getState();
                    const { configurationTool, modelsPage } = storeVal;
                    const { tableData } = configurationTool;

                    const origVersionId = e.target.data.asset.assetRef.split("@");
                    const orunId = origVersionId[0] + '#' + e.target.data.asset.assetVersion;
                    const origFuncData = configurationTool.originalFunctionTypesUsed.byIdWithVersion[orunId] || configurationTool.latestOriginalFunctionsUsed.byIdWithVersion[orunId];

                    if(e.target.data.asset.nodeId !== storeVal.configurationTool.overallSeverityFunctionId){
                        if (this._currentConnection.data.input.circleData.dataType === 'array_integer' ||
                            this._currentConnection.data.input.circleData.dataType === 'array_number' ||
                            this._currentConnection.data.input.circleData.dataType === 'array_string') {

                            let FunctionTypeDetails = origFuncData;
                            if (allConnections && allConnections.length > 0) {
                                let defaultValue = this._currentConnection.data.input.circleData.defaultValue ? this._currentConnection.data.input.circleData.defaultValue.split(",") : "";
                                if (allConnections && allConnections.length > 0) {
                                    if (allConnections.length > defaultValue.length) {
                                        if (FunctionTypeDetails) {
                                            let findInput: any = FunctionTypeDetails;
                                            const inputCircleData = this._currentConnection.data.input.circleData;
                                            let inputExists = findInput.inputs.find((input: any) => input.name === inputCircleData.name);
                                            if (!this._currentConnection.data.input.circleData.defaultValue) {
                                                this._currentConnection.data.input.circleData.defaultValue = "0";
                                                store.dispatch(addConnectionConfigurationTool({
                                                    outputAsset: this._currentConnection.data.output.asset,
                                                    outputCircleData: this._currentConnection.data.output.circleData,
                                                    inputCircleData: this._currentConnection.data.input.circleData,
                                                    inputAsset: this._currentConnection.data.input.asset,
                                                }));
                                                store.dispatch(addDefaultValueConfigurationTool({
                                                    circleData: this._currentConnection.data.input.circleData,
                                                    asset: this._currentConnection.data.input.asset,
                                                    defaultValue: this._currentConnection.data.input.circleData.defaultValue,
                                                }));
                                            } else {
                                                if (inputExists.defaultValue) {

                                                    if (inputExists) {
                                                        let connectionMap = inputCircleData.defaultValue.split(",");
                                                        const previousJsonValue = inputExists.defaultValue.split(",");
                                                        if (connectionMap.length < previousJsonValue.length) {
                                                            const notMatchedValues = this.removeCommon(connectionMap, previousJsonValue)
                                                            this._currentConnection.data.input.circleData.defaultValue = this._currentConnection.data.input.circleData.defaultValue + "," + notMatchedValues[0];
                                                            store.dispatch(addConnectionConfigurationTool({
                                                                outputAsset: this._currentConnection.data.output.asset,
                                                                outputCircleData: this._currentConnection.data.output.circleData,
                                                                inputCircleData: this._currentConnection.data.input.circleData,
                                                                inputAsset: this._currentConnection.data.input.asset,
                                                            }));
                                                            store.dispatch(addDefaultValueConfigurationTool({
                                                                circleData: this._currentConnection.data.input.circleData,
                                                                asset: this._currentConnection.data.input.asset,
                                                                defaultValue: this._currentConnection.data.input.circleData.defaultValue,
                                                            }));
                                                        } else {
                                                            // if (input.mappingDetails.length > connectionMap.length) {
                                                            //     input.defaultValue = input.defaultValue + ",0";
                                                            // }
                                                            // if (input.mappingDetails.length > connectionMap.length) {
                                                            // }
                                                            // store.dispatch(addDefaultValueConfigurationTool({
                                                            //     circleData: this._currentConnection.data.input.circleData,
                                                            //     asset: this._currentConnection.data.input.asset,
                                                            //     defaultValue: this._currentConnection.data.input.circleData.defaultValue
                                                            // }));
                                                            if (allConnections.length > defaultValue.length) {
                                                                this.showConfirmationDialog(this._currentConnection.data);
                                                                // this._currentConnection.data.input.circleData.defaultValue = this._currentConnection.data.input.circleData.defaultValue + ",0"
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (!this._currentConnection.data.input.circleData.defaultValue) {

                                                        this._currentConnection.data.input.circleData.defaultValue = "0";
                                                        store.dispatch(addConnectionConfigurationTool({
                                                            outputAsset: this._currentConnection.data.output.asset,
                                                            outputCircleData: this._currentConnection.data.output.circleData,
                                                            inputCircleData: this._currentConnection.data.input.circleData,
                                                            inputAsset: this._currentConnection.data.input.asset,
                                                        }));
                                                        store.dispatch(addDefaultValueConfigurationTool({
                                                            circleData: this._currentConnection.data.input.circleData,
                                                            asset: this._currentConnection.data.input.asset,
                                                            defaultValue: this._currentConnection.data.input.circleData.defaultValue,
                                                        }));
                                                    }
                                                    if (this._currentConnection.data.input.circleData.defaultValue) {
                                                        this._currentConnection.data.input.circleData.defaultValue = this._currentConnection.data.input.circleData.defaultValue + ",0";
                                                        store.dispatch(addConnectionConfigurationTool({
                                                            outputAsset: this._currentConnection.data.output.asset,
                                                            outputCircleData: this._currentConnection.data.output.circleData,
                                                            inputCircleData: this._currentConnection.data.input.circleData,
                                                            inputAsset: this._currentConnection.data.input.asset,
                                                        }));
                                                        store.dispatch(addDefaultValueConfigurationTool({
                                                            circleData: this._currentConnection.data.input.circleData,
                                                            asset: this._currentConnection.data.input.asset,
                                                            defaultValue: this._currentConnection.data.input.circleData.defaultValue,
                                                        }));
                                                    }

                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        } else if (this._currentConnection.data.input.circleData.dataType === 'array_boolean') {
                            if (allConnections && allConnections.length > 0) {
                                let defaultValue = this._currentConnection.data.input.circleData.defaultValue ? this._currentConnection.data.input.circleData.defaultValue.split(",") : "";
                                if (allConnections && allConnections.length > 0) {
                                    if (allConnections.length > defaultValue.length) {
                                        this._currentConnection.data.input.circleData.defaultValue = this._currentConnection.data.input.circleData.defaultValue + ",false"
                                        store.dispatch(addConnectionConfigurationTool({
                                            outputAsset: this._currentConnection.data.output.asset,
                                            outputCircleData: this._currentConnection.data.output.circleData,
                                            inputCircleData: this._currentConnection.data.input.circleData,
                                            inputAsset: this._currentConnection.data.input.asset,
                                        }));
                                        store.dispatch(addDefaultValueConfigurationTool({
                                            circleData: this._currentConnection.data.input.circleData,
                                            asset: this._currentConnection.data.input.asset,
                                            defaultValue: this._currentConnection.data.input.circleData.defaultValue,
                                        }));
                                    }
                                }
                            }
                        } else if (this._currentConnection.data.input.circleData.dataType === 'string' ||
                            this._currentConnection.data.input.circleData.dataType === 'number' ||
                            this._currentConnection.data.input.circleData.dataType === 'integer') {
                            let FunctionTypeDetails = origFuncData;
                            if (FunctionTypeDetails) {
                                let findInput: any = FunctionTypeDetails;
                                const inputCircleData = this._currentConnection.data.input.circleData;
                                console.log(inputCircleData, "inputCircleData");
                                if (inputCircleData) {
                                    if (!this._currentConnection.data.input.circleData.defaultValue) {
                                        this._currentConnection.data.input.circleData.defaultValue = "0";
                                        store.dispatch(addConnectionConfigurationTool({
                                            outputAsset: this._currentConnection.data.output.asset,
                                            outputCircleData: this._currentConnection.data.output.circleData,
                                            inputCircleData: this._currentConnection.data.input.circleData,
                                            inputAsset: this._currentConnection.data.input.asset,
                                        }));
                                        store.dispatch(addDefaultValueConfigurationTool({
                                            circleData: this._currentConnection.data.input.circleData,
                                            asset: this._currentConnection.data.input.asset,
                                            defaultValue: this._currentConnection.data.input.circleData.defaultValue,
                                        }));
                                    }
                                    // store.dispatch(addConnectionConfigurationTool({
                                    //     outputAsset: this._currentConnection.data.output.asset,
                                    //     outputCircleData: this._currentConnection.data.output.circleData,
                                    //     inputCircleData: this._currentConnection.data.input.circleData,
                                    //     inputAsset: this._currentConnection.data.input.asset,
                                    // }));
                                    // store.dispatch(addDefaultValueConfigurationTool({
                                    //     circleData: this._currentConnection.data.input.circleData,
                                    //     asset: this._currentConnection.data.input.asset,
                                    //     defaultValue: this._currentConnection.data.input.circleData.defaultValue,
                                    // }));
                                }
                            }
                        }
                    }
                    this._currentConnection = null;
                    this._canvas.renderAll();

                    this.markComputeModelDirty();
                    const state = store.getState();

                    const targetData = e.target.data;
                    const asset = e.target.data.asset as FunctionTypeDetailsWithMappings;
                    if (asset.nodeId === state.configurationTool.overallSeverityFunctionId) {
                        if (targetData.circleData.id === asset.inputs[0].id) {
                            const connections = this._ConnectionController.getAllConnectionsById({
                                ioId: asset.inputs[0].id,
                                nodeId: asset.nodeId,
                            });
                            if (connections.length === 1) {
                                const inputTrigger = this.IOController.getInputTrigger([
                                    {
                                        ioId: asset.inputs[0].id,
                                        nodeId: asset.nodeId,
                                    },
                                ]);

                                if (inputTrigger) {
                                    this._ConnectionController.handleTrigger(inputTrigger);
                                    this.markComputeModelDirty();
                                }
                            }
                        }
                    }

                    // handling historicalDataTrigger.
                    asset.inputs.forEach((input) => {
                        const historicalDataTrigger = this.IOController.getHistoricalDataTrigger([
                            {
                                ioId: input.id,
                                nodeId: asset.nodeId,
                            },
                        ]);
                        if (historicalDataTrigger) {
                            this._ConnectionController.handleHistoricalDataTrigger(
                                historicalDataTrigger
                            );
                        }
                    });
                    this.markComputeModelDirty();
                } else {
                    this.removeCurrentConnection();
                }
            } else {
                this.removeCurrentConnection();
            }
            this.verticalLines.length = this.horizontalLines.length = 0;
            const activeObject = this._canvas.getActiveObject();
            if (
                e.target instanceof FabricObjectRectangle ||
                e.target instanceof FabricFunctionRectangle ||
                (e.target instanceof FabricDropDownTitle &&
                    (activeObject instanceof FabricObjectRectangle ||
                        activeObject instanceof FabricFunctionRectangle ||
                        activeObject instanceof FabricDropDownTitle))
            ) {
                let coords = e.target.getPointByOrigin('left', 'top');
                let eLeft = coords.x;
                let eTop = coords.y;
                console.log('snapppp', eLeft);

                if (
                    Math.floor((eLeft / GRID_PIXEL) * SNAP_FACTOR) % SNAP_FACTOR == 0 &&
                    Math.floor((eTop / GRID_PIXEL) * SNAP_FACTOR) % SNAP_FACTOR == 0
                ) {
                    e.target.left =
                        Math.floor(eLeft / GRID_PIXEL) * GRID_PIXEL + e.target.getScaledWidth() / 2;
                    e.target.top =
                        Math.floor(eTop / GRID_PIXEL) * GRID_PIXEL + e.target.getScaledHeight() / 2;

                    e.target.setCoords();
                    this.handleObjectMoving(e);
                }
            }
        } else {
            this.removeCurrentConnection();
        }
    };



    removeCommon = (first: any, second: any) => {
        var arrayDifference = second.filter((x: any) => {
            return !first.includes(x);
        });
        return arrayDifference;
    };

    /**
     * Remove the current connection on canvas
     */
    removeCurrentConnection = () => {
        if (this._currentConnection) {
            this._canvas.remove(this._currentConnection);
            this._currentConnection = null;
        }
    };

    handleMouseMove = (e: IEvent) => {
        if (this._currentConnection) {
            const line = this._currentConnection;
            if (line.path && e.pointer) {
                const currentZoom = this._canvas.getZoom();
                if (this._currentConnection.data[IOTypes.INPUT]) {
                    console.log('moving end');
                    line.path[1][3] = e.pointer.x / currentZoom;
                    line.path[1][4] = e.pointer.y / currentZoom;
                } else if (this._currentConnection.data[IOTypes.OUTPUT]) {
                    console.log('moving start', currentZoom);
                    line.path[0][1] = e.pointer.x / currentZoom;
                    line.path[0][2] = e.pointer.y / currentZoom;
                }
                this._canvas.renderAll();
            }
        }
    };

    handleDeleteConnection(target: Connection) {
        const storeVal = store.getState();
        const { configurationTool } = storeVal;
        const { tableData, previousJson, json } = configurationTool;

        const origVersionId = target.data.input.asset.assetRef.split("@");
        const orunId = origVersionId[0] + '#' + target.data.input.asset.assetVersion;
        const origFuncData = configurationTool.originalFunctionTypesUsed.byIdWithVersion[orunId] || configurationTool.latestOriginalFunctionsUsed.byIdWithVersion[orunId];
        const asset = tableData.functionTypeDetails.find((asset) => asset.nodeId === target.data.input.asset.nodeId);

        if (asset) {
            let getAllConnections = this._ConnectionController.getAllConnectionsById(
                {
                    ioId: target.data.input.circleData.id,
                    nodeId: target.data.input.asset.nodeId,
                }
            );
            const inputData =
                asset.inputs.find((i) => i.id === target.data.input.circleData.id);
            if (inputData) {
                if (inputData.defaultValue) {
                    const connectionindex = getAllConnections.findIndex(
                        (item: any) =>
                            item.data.output.circleData.id === target.data.output.circleData.id
                    );

                    if (inputData.mappingDetails && inputData.mappingDetails.length > 0) {

                        let findInput: any = origFuncData;
                        if (findInput.inputs) {
                            let inputExists = findInput.inputs.find((input: any) => input.name === target.data.input.circleData.name);
                            if (inputExists.defaultValue !== inputData.defaultValue) {
                                let connectionDefaultValue = inputData.defaultValue.split(",");
                                connectionDefaultValue.forEach((defaultData: any, index: number) => {
                                    if (inputExists.defaultValue) {
                                        const initialFunctionExists = inputExists.defaultValue.split(",");
                                        if (connectionindex === index) {
                                            if (initialFunctionExists.length < connectionDefaultValue.length) {
                                                connectionDefaultValue.splice(connectionindex, 1);
                                            }
                                        }
                                    }
                                });
                                // connectionDefaultValue.splice(connectionindex, 1);
                                inputData.defaultValue = connectionDefaultValue.join(",");

                                if (!inputData.defaultValue && inputData.name === inputExists.name) {
                                    if (inputExists.defaultValue) {
                                        inputData.defaultValue = inputExists.defaultValue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        // if (getAllConnections && getAllConnections.length > 0) {
        //     const currentMappingDetailIndex = getAllConnections.findIndex(
        //         (item: any) =>
        //             item.data.output.circleData.id === target.data.output.circleData.id
        //     );
        //     const defaultData = target.data.input.circleData.defaultValue ? target.data.input.circleData.defaultValue.split(",") : "";
        //     if (defaultData && defaultData.length > 0) {
        //         defaultData.splice(currentMappingDetailIndex, 1);
        //         target.data.input.circleData.defaultValue = defaultData.join(",");
        //     }
        // }
        store.dispatch(removeConnectionConfigurationTool({
            inputAsset: target.data.input.asset,
            inputCircleData: target.data.input.circleData,
            outputCircleData: target.data.output.circleData,
            outputAsset: target.data.output.asset,
        } as any));
        this._ConnectionController.handleDeleteConnection(target);
        // let assetMapping = target.data.input.asset;

        let flag = true;
        let historyFlag = true;
        if (target.data.input.circleData.dataType.includes('array')) {
            flag = !this._ConnectionController.getConnectionById({
                ioId: target.data.input.circleData.id,
                nodeId: target.data.input.asset.nodeId,
            });
            historyFlag = flag;
        }

        if (target.data.input.circleData.id && flag) {
            this.IOController.handleResetTrigger([
                {
                    ioId: target.data.input.circleData.id,
                    nodeId: target.data.input.asset.nodeId,
                },
            ]);
        }

        const historicalDataTrigger = this.IOController.getHistoricalDataTrigger([
            {
                ioId: target.data.input.circleData.id,
                nodeId: target.data.input.asset.nodeId,
            },
        ]);

        if (historicalDataTrigger) {
            this._ConnectionController.handleHistoricalDataTrigger(historicalDataTrigger);
        }

        if (isArrayDatatype(target.data.input.circleData.dataType) && historyFlag) {
            this.IOController.resetHistoricalDataTrigger([
                {
                    ioId: target.data.input.circleData.id,
                    nodeId: target.data.input.asset.nodeId,
                },
            ]);
        }
        this._canvas.remove(target);
        this.markComputeModelDirty();
    }

    handleNodeToggle(currTarget: FabricArrowIcon) {
        const currParent = currTarget.data.parent;
        const currAsset = currParent.data.asset;
        const isOpen = currAsset.isOpen;
        const connections = this._ConnectionController.getAllConnectionsByNodeId(currAsset.nodeId);
        let nodeIdList: string[] = [];
        let longestBlockHeight = 0;
        if (connections && connections.length > 0) {
            connections.forEach((connection) => {
                if (connection.data.input.asset.nodeId !== currAsset.nodeId) {
                    nodeIdList.push(connection.data.input.asset.nodeId);
                }
                if (connection.data.output.asset.nodeId !== currAsset.nodeId) {
                    nodeIdList.push(connection.data.output.asset.nodeId);
                }
            });
        }

        const dropDownTitleNodes = [
            currTarget.data.parent,
            ...this._NodeController
                .getAllDropDownTitleNodes()
                .filter((node) => nodeIdList.includes(node.data.asset.nodeId)),
        ];

        dropDownTitleNodes.forEach((node) => {
            let target: FabricArrowIcon;
            node.data.childs.forEach((item) => {
                if (item instanceof FabricArrowIcon) {
                    target = item;
                }
            });
            const parent = target!.data.parent;
            const asset = parent.data.asset;
            const nodeHeight = parent.getScaledHeight();
            const nodeWidth = parent.getScaledWidth();
            const { x, y } = parent.getPointByOrigin('center', 'center');

            if (asset.isOpen && isOpen) {
                target!.close();
                asset.isOpen = false;
                this._ConnectionController.handleToggleNode({
                    nodeId: asset.nodeId,
                    nodeHeight,
                    nodeWidth,
                    position: { x, y },
                });
                this.IOController.handleToggleNode(asset.nodeId);
                this._NodeController.handleToggleNode({
                    nodeToToggle: parent,
                    isOpen: asset.isOpen,
                });
            } else {
                if (!isOpen && !asset.isOpen) {
                    target!.open();
                    asset.isOpen = true;
                    asset.scale = parent.scaleX!;

                    this._NodeController.handleToggleNode({
                        nodeToToggle: parent,
                        isOpen: asset.isOpen,
                    });
                    if (asset instanceof FunctionTypeDetail) {
                        const node = this._NodeController.addFunctionType({
                            asset,
                            editMode: this.editMode,
                        });
                        if (asset.alarmInputs.length > 0) {
                            this.IOController.clearAlarmInputById(asset.nodeId);
                            asset.alarmInputs.forEach((input) => {
                                console.log('%cinput', 'background-color:red');
                                let nodeDetails = {
                                    nodeHeight: node.getScaledHeight(),
                                    nodeWidth: node.getScaledWidth(),
                                };
                                let alarmPin = this.IOController.addAlarmToFunctionInput({
                                    asset: asset,
                                    parent: node,
                                    nodeDetails: { ...nodeDetails },
                                    circleData: { ...input },
                                });
                                this._ConnectionController.handleIOMove({
                                    nodeHeight: nodeDetails.nodeHeight,
                                    nodeWidth: nodeDetails.nodeWidth,
                                    ioId: alarmPin.ioCircle.data.circleData.id,
                                    nodeId: node.data.asset.nodeId,
                                    position: {
                                        x: alarmPin.ioCircle.calcTransformMatrix()[4],
                                        y: alarmPin.ioCircle.calcTransformMatrix()[5],
                                    },
                                });
                                this._ConnectionController.handleTrigger(alarmPin.ioTrigger, true);
                            });
                        }
                        parent.data.dropDownNode = node;
                        const height = node.getScaledHeight();
                        const width = node.getScaledWidth();
                        const outputData = this.IOController.addOutput({
                            asset,
                            nodeDetails: { nodeHeight: height, nodeWidth: width },
                            parent,
                        });

                        if (outputData) {
                            //@ts-ignore
                            outputData.ioCircles.forEach((ioCircle) => {
                                this._ConnectionController.handleIOMove({
                                    nodeHeight: height,
                                    nodeWidth: width,
                                    ioId: ioCircle.data.circleData.id,
                                    nodeId: ioCircle.data.asset.nodeId,
                                    position: {
                                        x: ioCircle.calcTransformMatrix()[4],
                                        y: ioCircle.calcTransformMatrix()[5],
                                    },
                                });
                            });

                            if (outputData instanceof FunctionOutput) {
                                outputData.ioPublish.forEach((publish) => {
                                    const isOriginalPublished = publish.data.circleData.isPublished;
                                    const isSeverityPinWithcondition =
                                        publish.data.circleData.name === FUNCTION_SEVERITY_PIN;
                                    let reverify = true;

                                    if (isSeverityPinWithcondition) {
                                        reverify = isOriginalPublished || false;
                                    }
                                    this.IOController.handleOutputPublish(publish, reverify);
                                });
                                outputData.conditionAlarm.forEach((alarm) => {
                                    this.IOController.handleFunctionAlarmPublish(alarm, true);
                                });
                            }
                        }
                        const inputData = this.IOController.addInput({
                            asset,
                            nodeDetails: { nodeHeight: height, nodeWidth: width },
                            parent,
                        });

                        if (inputData) {
                            inputData.ioCircles.forEach((ioCircle: any) =>
                                this._ConnectionController.handleIOMove({
                                    nodeHeight: height,
                                    nodeWidth: width,
                                    ioId: ioCircle.data.circleData.id,
                                    nodeId: ioCircle.data.asset.nodeId,
                                    position: {
                                        x: ioCircle.calcTransformMatrix()[4],
                                        y: ioCircle.calcTransformMatrix()[5],
                                    },
                                })
                            );
                            //@ts-ignore
                            inputData.ioTriggers &&
                                inputData.ioTriggers.forEach((trigger) => {
                                    this._ConnectionController.handleTrigger(trigger, true);
                                });

                            inputData.historyIcons.forEach((historyIcon) => {
                                this._ConnectionController.handleHistoricalDataTrigger(historyIcon);
                            });
                        }
                    } else if (asset instanceof ObjectTypeDetail) {
                        const node = this._NodeController.addObjectType({ asset });
                        parent.data.dropDownNode = node;
                        const height = node.getScaledHeight();
                        const width = node.getScaledWidth();
                        const outputData = this.IOController.addOutput({
                            asset,
                            nodeDetails: { nodeHeight: height, nodeWidth: width },
                            parent,
                        });
                        if (outputData) {
                            //@ts-ignore
                            outputData.ioCircles.forEach((ioCircle) => {
                                this._ConnectionController.handleIOMove({
                                    nodeHeight: height,
                                    nodeWidth: width,
                                    ioId: ioCircle.data.circleData.id,
                                    nodeId: ioCircle.data.asset.nodeId,
                                    position: {
                                        x: ioCircle.calcTransformMatrix()[4],
                                        y: ioCircle.calcTransformMatrix()[5],
                                    },
                                });
                            });

                            if (outputData instanceof FunctionOutput) {
                                outputData.ioPublish.forEach((publish) => {
                                    const isOriginalPublished = publish.data.circleData.isPublished;
                                    const isSeverityPinWithcondition =
                                        publish.data.circleData.name === FUNCTION_SEVERITY_PIN;
                                    let reverify = true;

                                    if (isSeverityPinWithcondition) {
                                        reverify = isOriginalPublished || false;
                                    }
                                    this.IOController.handleOutputPublish(publish, reverify);
                                });
                                outputData.conditionAlarm.forEach((alarm) => {
                                    this.IOController.handleFunctionAlarmPublish(alarm, true);
                                });
                            }
                        }
                    } else {
                        let alarmEventNode: FabricAlarmRectangle | FabricEventRectangle;
                        if (asset instanceof AlarmTypeDetail) {
                            alarmEventNode = this._NodeController.addAlarmType({ asset: asset });
                        } else {
                            alarmEventNode = this._NodeController.addEventType({ asset: asset });
                        }

                        parent.data.dropDownNode = alarmEventNode;

                        let alarmEventNodeDetails = {
                            nodeHeight: alarmEventNode.getScaledHeight(),
                            nodeWidth: alarmEventNode.getScaledWidth(),
                        };

                        const outputData = this.IOController.addOutput({
                            nodeDetails: { ...alarmEventNodeDetails },
                            asset: asset,
                            parent,
                        });
                        if (asset.type === ALARM_TYPE.SPECIFIC) {
                            const inputData = this.IOController.addInput({
                                nodeDetails: { ...alarmEventNodeDetails },
                                asset,
                                parent,
                            });
                            if (inputData) {
                                inputData.ioCircles.forEach((ioCircle: any) =>
                                    this._ConnectionController.handleIOMove({
                                        nodeHeight: alarmEventNodeDetails.nodeHeight,
                                        nodeWidth: alarmEventNodeDetails.nodeWidth,
                                        ioId: ioCircle.data.circleData.id,
                                        nodeId: ioCircle.data.asset.nodeId,
                                        position: {
                                            x: ioCircle.calcTransformMatrix()[4],
                                            y: ioCircle.calcTransformMatrix()[5],
                                        },
                                    })
                                );
                            }
                        }

                        if (outputData) {
                            outputData.ioCircles.forEach(
                                (ioCircle: FunctionOutputCircle | ObjectOutputCircle) => {
                                    this._ConnectionController.handleIOMove({
                                        nodeHeight: alarmEventNodeDetails.nodeHeight,
                                        nodeWidth: alarmEventNodeDetails.nodeWidth,
                                        ioId: ioCircle.data.circleData.id,
                                        nodeId: ioCircle.data.asset.nodeId,
                                        position: {
                                            x: ioCircle.calcTransformMatrix()[4],
                                            y: ioCircle.calcTransformMatrix()[5],
                                        },
                                    });
                                }
                            );
                        }
                    }
                    this._ConnectionController.handleRedrawLines(asset.nodeId);
                    const bottomRightCorner = parent.oCoords!.br;
                    longestBlockHeight = Math.max(
                        bottomRightCorner.y +
                        parent.data.dropDownNode!.getScaledHeight() * this._canvas.getZoom(),
                        longestBlockHeight
                    );
                }
            }
        });

        if (!isOpen && currParent.oCoords) {
            const canvasHeight = this._canvas.getHeight();
            if (longestBlockHeight + 50 > canvasHeight) {
                if (longestBlockHeight + MIN_BOTTOM_PADDING < MAX_CANVAS_HEIGHT) {
                    this._canvas.setHeight(longestBlockHeight + MIN_BOTTOM_PADDING);
                    this.addGridLines(this.showGrid);
                }
            }
        }
        this.markComputeModelDirty();
    }

    /**
     * ! --This function calculates canvas offset position in html, used to get
     * !  exact drop position of a element. --
     */

    public getCanvasOffSet = (id: string) => {
        let canvasOffSet = {
            x: 0,
            y: 0,
        };

        if (!this._canvasElement) {
            const element = document.getElementById(id) as HTMLCanvasElement;
            this._canvasElement = element;
        }

        if (this._canvasElement) {
            canvasOffSet.x = this._canvasElement.getBoundingClientRect().left;
            canvasOffSet.y = this._canvasElement.getBoundingClientRect().top;
        }

        return canvasOffSet;
    };

    /**
     * ! -------------From and to Json ----------,
     * * to draw and retrive diagram's from canvas.
     *
     */

    fromJSON(options: { json: LocalJson; editMode?: boolean }) {
        this.clearAll();
        let json = _.cloneDeepWith(options.json);
        let width =
            this._canvasParent && this._canvasParent.offsetWidth
                ? this.editMode
                    ? this._canvasParent.offsetWidth + 40
                    : this._canvasParent.offsetWidth
                : CANVAS_DIMENSION.WIDTH;
        let height =
            this._canvasParent && this._canvasParent.offsetHeight
                ? this.editMode
                    ? this._canvasParent.offsetHeight + GRID_PIXEL
                    : this._canvasParent.offsetHeight
                : CANVAS_DIMENSION.HEIGHT;
        const assetData = json && json.assetData;
        const connectionData = json && json.connectionData;
        connectionData.forEach((connection) => {
            const line = new FabricConnectionLine({
                left: 0,
                top: 0,
            }) as Connection;
            line.data = { ...connection };
            this._canvas.add(line);
            this._ConnectionController.add(line);
        });

        assetData.forEach((data) => {
            const dropDownRectangle = this._NodeController.addNode({ asset: data });
            if (data.isOpen) {
                let node: Node | null = null;
                if (data instanceof ObjectTypeDetail) {
                    const { width, height } = calculateAssetDimension(data);
                    const { updatedPosition } = handleMaxCanvasWidthHeightLimit({
                        nodeHeight: height,
                        nodeWidth: width,
                        nodePosition: data.position,
                    });
                    data.position = { ...updatedPosition };
                    node = this._NodeController.addObjectType({ asset: data });
                    dropDownRectangle.data.dropDownNode = node;
                } else if (data instanceof FunctionTypeDetail) {
                    const { width, height } = calculateAssetDimension(data);
                    const { updatedPosition } = handleMaxCanvasWidthHeightLimit({
                        nodeHeight: height,
                        nodeWidth: width,
                        nodePosition: data.position,
                    });
                    data.position = { ...updatedPosition };
                    node = this._NodeController.addFunctionType({
                        asset: data,
                        editMode: this.editMode,
                    });
                    dropDownRectangle.data.dropDownNode = node;
                } else {
                    let position = data.position;
                    const { width, height } = calculateAssetDimension(data);
                    const { updatedPosition } = handleMaxCanvasWidthHeightLimit({
                        nodeHeight: height,
                        nodeWidth: width,
                        nodePosition: position,
                    });
                    position = { ...updatedPosition };
                    if (data instanceof AlarmTypeDetail) {
                        node = this._NodeController.addAlarmType({ asset: data });
                    } else {
                        node = this._NodeController.addEventType({ asset: data });
                    }
                    dropDownRectangle.data.dropDownNode = node;
                }

                if (node) {
                    const nodeHeight = node.getScaledHeight();
                    const nodeWidth = node.getScaledWidth();

                    // const { nodeId, assetRef } = node.data;
                    if (node.oCoords) {
                        if (node.oCoords.br.x + 50 > width) {
                            width =
                                node.oCoords.br.x +
                                MIN_RIGHT_PADDING +
                                fullFunctionPinLength * node.data.asset.scale;
                        }

                        if (node.oCoords.br.y + 50 > height) {
                            height = node.oCoords.br.y + MIN_BOTTOM_PADDING;
                        }
                    }
                    const nodeDetails = {
                        nodeHeight,
                        nodeWidth,
                    };

                    const outputData = this.IOController.addOutput({
                        nodeDetails,
                        asset: data,
                        parent: dropDownRectangle,
                    });
                    if (outputData) {
                        //@ts-ignore
                        outputData.ioCircles.forEach((ioCircle) => {
                            this._ConnectionController.handleIOMove({
                                nodeHeight: nodeHeight,
                                nodeWidth: nodeWidth,
                                ioId: ioCircle.data.circleData.id,
                                nodeId: ioCircle.data.asset.nodeId,
                                position: {
                                    x: ioCircle.calcTransformMatrix()[4],
                                    y: ioCircle.calcTransformMatrix()[5],
                                },
                            });
                        });

                        if (outputData instanceof FunctionOutput) {
                            outputData.ioPublish.forEach((publish) => {
                                const isOriginalPublished = publish.data.circleData.isPublished;
                                const isSeverityPinWithcondition =
                                    publish.data.circleData.name === FUNCTION_SEVERITY_PIN;
                                let reverify = true;

                                if (isSeverityPinWithcondition) {
                                    reverify = isOriginalPublished || false;
                                }
                                this.IOController.handleOutputPublish(publish, reverify);
                            });
                            outputData.conditionAlarm.forEach((alarm) => {
                                this.IOController.handleFunctionAlarmPublish(alarm, true);
                            });
                        }
                    }

                    if (data instanceof FunctionTypeDetail) {
                        const { inputs } = data;
                        const inputData = this.IOController.addInput({
                            nodeDetails,
                            asset: data,
                            parent: dropDownRectangle,
                        });
                        if (inputData) {
                            inputData.ioCircles.forEach((ioCircle) =>
                                this._ConnectionController.handleIOMove({
                                    nodeHeight: nodeHeight,
                                    nodeWidth: nodeWidth,
                                    ioId: ioCircle.data.circleData.id,
                                    nodeId: ioCircle.data.asset.nodeId,
                                    position: {
                                        x: ioCircle.calcTransformMatrix()[4],
                                        y: ioCircle.calcTransformMatrix()[5],
                                    },
                                })
                            );
                            //@ts-ignore
                            inputData.ioTriggers &&
                                inputData.ioTriggers.forEach((trigger) => {
                                    this._ConnectionController.handleTrigger(trigger, true);
                                });

                            inputData.historyIcons.forEach((historyIcon) => {
                                this._ConnectionController.handleHistoricalDataTrigger(historyIcon);
                            });
                        }
                    } else if (data instanceof AlarmTypeDetail) {
                        const inputData = this.IOController.addInput({
                            nodeDetails: { ...nodeDetails },
                            asset: data,
                            parent: dropDownRectangle,
                        });
                        if (inputData) {
                            inputData.ioCircles.forEach((ioCircle: any) =>
                                this._ConnectionController.handleIOMove({
                                    nodeHeight: nodeDetails.nodeHeight,
                                    nodeWidth: nodeDetails.nodeWidth,
                                    ioId: ioCircle.data.circleData.id,
                                    nodeId: ioCircle.data.asset.nodeId,
                                    position: {
                                        x: ioCircle.calcTransformMatrix()[4],
                                        y: ioCircle.calcTransformMatrix()[5],
                                    },
                                })
                            );
                        }
                    }
                    if (data instanceof FunctionTypeDetail) {
                        if (data.alarmInputs.length > 0) {
                            this.IOController.clearAlarmInputById(data.nodeId);

                            data.alarmInputs.forEach((input) => {
                                if (node && node instanceof FabricFunctionRectangle) {
                                    let nodeDetails = {
                                        nodeHeight: node.getScaledHeight(),
                                        nodeWidth: node.getScaledWidth(),
                                    };
                                    let alarmPin = this.IOController.addAlarmToFunctionInput({
                                        asset: data,
                                        parent: node,
                                        nodeDetails: { ...nodeDetails },
                                        circleData: input,
                                    });
                                    if (alarmPin) {
                                        this._ConnectionController.handleIOMove({
                                            nodeHeight: nodeDetails.nodeHeight,
                                            nodeWidth: nodeDetails.nodeWidth,
                                            ioId: alarmPin.ioCircle.data.circleData.id,
                                            nodeId: node.data.asset.nodeId,
                                            position: {
                                                x: alarmPin.ioCircle.calcTransformMatrix()[4],
                                                y: alarmPin.ioCircle.calcTransformMatrix()[5],
                                            },
                                        });
                                        this._ConnectionController.handleTrigger(
                                            alarmPin.ioTrigger,
                                            true
                                        );
                                    }
                                }
                            });
                        }
                    }

                    this._ConnectionController.handleRedrawLines(data.nodeId);
                }
            } else {
                if (dropDownRectangle) {
                    const nodeHeight = dropDownRectangle.getScaledHeight();
                    const nodeWidth = dropDownRectangle.getScaledWidth();

                    // const { nodeId, assetRef } = node.data;
                    if (dropDownRectangle.oCoords) {
                        if (dropDownRectangle.oCoords.br.x + 50 > width) {
                            width =
                                dropDownRectangle.oCoords.br.x +
                                MIN_RIGHT_PADDING +
                                fullFunctionPinLength * dropDownRectangle.data.asset.scale;
                        }

                        if (dropDownRectangle.oCoords.br.y + 50 > height) {
                            height = dropDownRectangle.oCoords.br.y + MIN_BOTTOM_PADDING;
                        }
                    }

                    this._ConnectionController.handleToggleNode({
                        nodeHeight,
                        nodeWidth,
                        nodeId: data.nodeId,
                        position: { ...data.position },
                    });
                    this._ConnectionController.handleRedrawLines(data.nodeId);
                }
            }
        });
        const finalCanavasHeight = height >= MAX_CANVAS_HEIGHT ? MAX_CANVAS_HEIGHT : height;
        const finalCanavasWidth = width >= MAX_CANVAS_WIDTH ? MAX_CANVAS_WIDTH : width;
        this._canvas.setHeight(finalCanavasHeight);
        this._canvas.setWidth(finalCanavasWidth);
        this._canvasHeight = finalCanavasHeight;
        this._canvasWidth = finalCanavasWidth;

        this.addGridLines(this.showGrid);
        if (typeof options.editMode === 'boolean') {
            if (options.editMode === true && this.editMode === false) {
                this.subscribeCanvasEvents();
            }
            this.editMode = options.editMode;
        }

        if (!this.editMode) {
            this.handleViewMode();
            this.subscribeCanvasViewModeEvents();
        }
    }

    toJSON: () => LocalJson = () => {
        const assetData: LocalJson['assetData'] = [];
        const connectionData: LocalJson['connectionData'] = [];
        this._NodeController.getAllDropDownTitleNodes().forEach((object) => {
            let node = { ...object };
            if (node instanceof FabricFunctionRectangle) {
                delete node.data.childs;
            }

            assetData.push(node.data.asset);
        });
        this._ConnectionController.getAllConnections().forEach((connection) => {
            connectionData.push({ ...connection.data });
        });

        return { assetData, connectionData };
    };

    handleUnmount() {
        this.clearAll();
        this.unsubsribeCanvasEvents();
    }
}

export default CanvasController;
