import _ from 'lodash';
import JSZip from 'jszip';
import jwt_decode from 'jwt-decode';
import { CSSProperties } from 'react';
import { AbilityService } from 'sce-engg-model-19.09';

import {
    ROUTE_MODE,
    TABLE_IO_TYPE,
    CONFIGURATION_STATUS,
    ROUTE_PATHNAME,
    IMPORT_FILE,
} from './constants/appConstants';
import { StoreState } from '../store';
import {
    SelectedTypes,
    TableDataById,
    SupportedTypes,
    SelectedInstances,
    FunctionTypeBasics,
    SegregatedModelItem,
    InstanceMonitoringItem,
    SegregatedModelItemValue,
} from '../store/instanceConfig/types';
import { ComputeModelToJson } from '../transformers/ComputeModel/toJson/ComputeModelToJson';
import { ComputeModelFromJson } from '../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import {
    AlarmTypeDetailsWithMappings,
    EventTypeDetailsWithMappings,
    FunctionTypeDetailsWithMappings,
    TableDropDownData,
} from '../model/TableController/types';

import {
    OriginalFunctionTypes,
    VersionUpdateType,
    VERSION_UPDATE_TYPE,
} from '../store/configurationTool/types';
import AppSettings from '../services/AppSettings';
import { FunctionDetailsRequiredByLibrary } from '../routes/Functions/CreateLibraryPopup';
import { TableData } from '../model/TableController';
import { AssetType } from '../transformers/AssetType';
import { AbbResponseType, commonAuthHelper } from './types/commonInterface';
import { ALARM_TYPE, Asset, IODataTypes } from '../components/Fabric/types';
import {
    FunctionHeaderTypeDetails,
    UpdateActiveFunctionType,
    LibraryAndNodePayload,
    LibraryAndNodePayloadWithoutNodes,
} from '../store/function/types';
import ObjectTypeDetail from '../transformers/AssetType/ObjectType';
import { CreateFunctionAssetDetails } from '../store/function/action';
import { FunctionsLibraries, TypesWithLibraries, TypeLibrary } from '../store/exportAsset/types';
import FunctionTypeDetail from '../transformers/AssetType/FunctionType';
import { LocalJson } from '../transformers/ComputeModel/fromJson/types';
import { validateForSwedishAndFinishCharacters, hasSpecialCharacter } from '../routes/Functions/helper';
import { SupportedNewModel } from '../store/supportedConditionMonitors/types';
import { AssetsInfo } from '../routes/Functions/type';
import AlarmTypeDetail from '../transformers/AssetType/AlarmType';
import { GLOBAL_TENANT_ID, LOCAL_TYPE_ID_PREFIX, TENANT_ID_KEY } from '../routes/Functions/constants';

export const isNilOrEmpty = (val: any): boolean => {
    return Boolean(_.isNil(val) || _.isEmpty(val));
};

export const isIOConnectionArrayType = (type: string) => {
    return IODataTypes.ARRAY === type;
};

export const abbSDKResponseHandle = (response: AbbResponseType) => {
    if (response.status >= httpStatus.OK && response.status <= httpStatus.MULTIPLE_CHOICES) {
        return response;
    }

    throw new Error('Something went wrong');
};

export const isAssetInstanceNotConfigured = (configureStatus: string) => {
    return configureStatus === CONFIGURATION_STATUS.YET_TO_BE_CONFIGURED;
};

export const abbSDKGetErrorMessage = (error: any) => {
    
    if (
        error.response &&
        error.response.data &&
        error.response.data.meta &&
        error.response.data.meta.length
    ) {
        let errorArray = error.response.data.meta || '';
        let errorMessage = '';
        errorMessage = errorArray[errorArray.length - 1].detail;
        return errorMessage;
    } else if (error.response && error.response.data) {
        if(error.response.data.status === 502) {
            return 'Bad Gateway - Some of the essesntial services are not reachable at the moment. Please try after sometime. If the problem persists, please contact the system administrator.'
        }
        const errorData = error.response.data;
        let errorMessage = error.message || '';
        if (Array.isArray(errorData)) {
            /** differen error format coming
             * {reason:string, title:string,status:number}[]
             * or
             * {detail:string,status:number}[]
             */
            if (errorData[0].details) {
                return errorData[0].details;
            }
            if (errorData[0].reason) {
                return errorData[0].reason;
            }
            if(errorData[0].errors) {
                return errorData[0].errors;
            }
        }
        if (Array.isArray(errorData.errors)) {
            /** differen error format coming
             * {reason:string, title:string,status:number}[]
             * or
             * {detail:string,status:number}[]
             */
            if (errorData.errors[0].detail) {
                return errorData.errors[0].detail;
            }
            if (errorData[0].reason) {
                return errorData[0].reason;
            }
        } else {
            if(errorData.errors) {
                return errorData.errors.toString();
            }
            if(errorData.detail) {
                return errorData.detail.toString();
            }
        }
        return errorMessage.toString();
    } else if (error.message) {
        let message = error.message || '';
        try {
            const parsedError = JSON.parse(error.message);
            message = parsedError.meta
                ? parsedError.meta.error
                    ? parsedError.meta.error
                    : parsedError.meta
                : parsedError;
                console.log(parsedError, message);
            message = JSON.parse(message);
            message = Array.isArray(message) ? message[0].details ? message[0].details : message[0].message : message.errors ? message.errors : message;
        } catch (error) { }
        return message.toString();
    }
};

export const abbSDKGetLibraryError = (error: any) => {
    let errorStatus = { error: '', statusCode: 0 };
    errorStatus['statusCode'] = error.response.status;
    const errorData = error.response.data;
    if(errorData) {
        if(Array.isArray(errorData)) {
                if (error.response.data[0].details) {
                    errorStatus['error'] = error.response.data[0].details;
                    return errorStatus;
                }
            } //@ts-ignore
            else if(Array.isArray(errorData.errors)) {
                if(error.response.data.errors[0].detail) {
                    errorStatus['error'] = error.response.data.errors[0].detail;
                    return errorStatus;
                }
            }
            else if (errorData.detail) {
                errorStatus['error'] = errorData.detail;
                return errorStatus;
            } 
    } else {
        return errorStatus;
    } 
    return errorStatus;
    
};

export const abbSDKGetInstanceConfigErrorMessage = (error: any) => {
    if (error.response) {
        let errorDetails: string[] = [];
        if (error.response.data) {
            if (_.isArray(error.response.data)) {
                error.response.data.forEach((item: any) => {
                    if (item.objectId && item.message && item.statusCode) {
                        const errordata = `objectId:${item.objectId}, message:${item.message}, details:${item.details}, statusCode:${item.statusCode}`;
                        errorDetails.push(errordata);
                    }
                });
            } else {
                if(Array.isArray(error.response.data.errors) && error.response.data.errors[0].detail)
                errorDetails.push(error.response.data.errors[0].detail);
                else errorDetails.push(error.response.data);
            }
        }
        if (errorDetails.length === 0) {
            errorDetails = [error.message || ''];
        }
        return errorDetails;
    } else {
        let message = error.message;
        try {
            const parsedError = JSON.parse(error.message);
            
            message = parsedError.meta
                ? parsedError.meta.error
                    ? parsedError.meta.error
                    : parsedError.meta
                : parsedError;
            message = JSON.parse(message);
            
            message = Array.isArray(message) ? message[0].details ? message[0].details : message[0].message : message.errors ? message.errors : message;
        } catch (error) { }
        return [message.toString()];
    }
};

export function getFunctionTypeDetailId(options: {
    functionName: string;
    assetData: LocalJson['assetData'];
    oldAssetData: LocalJson['assetData'];
}) {
    const { functionName, assetData, oldAssetData } = options;
    let countSameFunction = 0;

    const oldFuncData = oldAssetData.filter((item) => item instanceof FunctionTypeDetail);

    const currentAssetData = assetData.filter((item) => item instanceof FunctionTypeDetail);
    const ids = new Set(currentAssetData.map((item) => item.nodeId));
    const mergedAssetData = [
        ...currentAssetData,
        ...oldFuncData.filter((item) => !ids.has(item.nodeId)),
    ];

    const getFunctionId = (options: { suffix: string; id: string }) => {
        const { id, suffix } = options;
        return `${id.split(' ').join('')}_${suffix}`;
    };

    mergedAssetData.forEach((asset) => {
        if (asset instanceof FunctionTypeDetail) {
            if (asset.assetName === functionName) {
                countSameFunction = countSameFunction + 1;
            }
        }
    });
    let functionId = getFunctionId({
        suffix: countSameFunction + '',
        id: functionName,
    });
    let isValidId = checkFunctionIdDuplication({
        id: functionId,
        assetData: mergedAssetData,
    });

    while (!isValidId) {
        countSameFunction += 1;
        functionId = getFunctionId({
            suffix: countSameFunction + '',
            id: functionName,
        });
        isValidId = checkFunctionIdDuplication({
            id: functionId,
            assetData: mergedAssetData,
        });
    }
    return functionId.split('.').join('');
}

export function checkFunctionIdDuplication(options: {
    id: string;
    assetData: LocalJson['assetData'];
}) {
    console.log('duplication check', options);
    const { id, assetData } = options;

    return !assetData.find((asset) => asset instanceof FunctionTypeDetail && asset.nodeId === id);
}

// ORIGINAL SELECTED FUNCTION DETAIL TO COMPARE WITH UPDATED FUNCTION TO CHECK FOR TRIGGER.
export function getOriginalFunctionTriggerForInstance(options: {
    nodeId: string;
    originalFunctionTypeDetail: ComputeModelToJson | undefined;
}) {
    const { nodeId, originalFunctionTypeDetail } = options;

    if (originalFunctionTypeDetail) {
        const availableFunctions = originalFunctionTypeDetail.properties.functions;

        const selectedFunction = availableFunctions[nodeId];

        const triggers = selectedFunction && selectedFunction.triggers;

        return triggers;
    }

    return undefined;
}

export const getOriginalSelectedFuncDetail = (options: { nodeId: string; json: LocalJson }) => {
    const { json, nodeId } = options;
    const selectedFunction = json.assetData.find(
        (item) => item instanceof FunctionTypeDetail && item.nodeId === nodeId
    ) as FunctionTypeDetail | undefined;
    return selectedFunction;
};

// SEARCH HELPER FUNCTION .

export const filterListData = (data: any[], path: string, searchValue: string) => {
    const filterList: any[] = [];
    data.forEach((item, index) => {
        let res = _.get(item, path, '');
        if (res.toLowerCase().includes(searchValue.toLowerCase())) filterList.push(item);
    });

    return filterList;
};

export function handletableDropDownData(options: {
    tableData: TableData;
    input: FunctionTypeDetailsWithMappings['inputs'][0];
}) {
    const { tableData, input } = options;
    let dropDownOptions: TableDropDownData[] = [];
    const selectedDropDownItemIndex: number[] = [];
    Object.values(tableData).forEach(
        (assetType: ObjectTypeDetail[] | FunctionTypeDetailsWithMappings[]) => {
            assetType.forEach(
                (assetTypeDetail: ObjectTypeDetail | FunctionTypeDetailsWithMappings) => {
                    assetTypeDetail.outputs.forEach((output) => {
                        if (output.dataType === input.dataType) {
                            dropDownOptions.push({
                                output: output,
                                outputAsset: assetTypeDetail,
                                mappingDetail: input.mappingDetails,
                            });
                        }
                        if (input.dataType === IODataTypes.ARRAY_STRING) {
                            output.dataType === IODataTypes.STRING &&
                                dropDownOptions.push({
                                    output: output,
                                    outputAsset: assetTypeDetail,
                                    mappingDetail: input.mappingDetails,
                                });
                        }
                        if (input.dataType === IODataTypes.ARRAY_NUMBER) {
                            output.dataType === IODataTypes.NUMBER &&
                                dropDownOptions.push({
                                    output: output,
                                    outputAsset: assetTypeDetail,
                                    mappingDetail: input.mappingDetails,
                                });
                        }
                        if (input.dataType === IODataTypes.ARRAY_BOOLEAN) {
                            output.dataType === IODataTypes.BOOLEAN &&
                                dropDownOptions.push({
                                    output: output,
                                    outputAsset: assetTypeDetail,
                                    mappingDetail: input.mappingDetails,
                                });
                        }
                        if (input.dataType === IODataTypes.ARRAY_INTEGER) {
                            output.dataType === IODataTypes.INTEGER &&
                                dropDownOptions.push({
                                    output: output,
                                    outputAsset: assetTypeDetail,
                                    mappingDetail: input.mappingDetails,
                                });
                        }

                        if (input.mappingDetails.length > 0) {
                            input.mappingDetails.forEach((mappingDetail) => {
                                if (mappingDetail.output.circleData.id === output.id) {
                                    selectedDropDownItemIndex.push(dropDownOptions.length - 1);
                                    // let currIndex = dropDownOptions.findIndex((optionIndex) => {
                                    //     return optionIndex.output.id === mappingDetail.output.circleData.id
                                    // });
                                    // if (currIndex !== -1) {
                                    //     dropDownOptions = array_move(dropDownOptions, currIndex, selectedDropDownItemIndex,)
                                    // }
                                }
                            });

                        }
                    });
                }
            );
        }
    );
    // const currIndex = dropDownOptions.findIndex((inputopt) => {
    //     return inputopt.input.id === input.id
    // })
    // array_move(dropDownOptions, selectedDropDownItemIndex, )

    return { dropDownOptions, selectedDropDownItemIndex };
}

const array_move = (arr: any, old_index: any, new_index: any) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

const getUpdateDetails = (details: any[], overallStatus: boolean) => {
    const updatedDetails = details.map((detail) => {
        if (!detail.hasOwnProperty('isDisabled')) {
            if (overallStatus === false && detail.hasOwnProperty('isDelete')) {
                delete detail.isDelete;
            } else if (overallStatus === true && !detail.hasOwnProperty('isDelete')) {
                detail.isDelete = true;
            }
        }

        return detail;
    });
    return updatedDetails;
};
const getUpdatedObject = (details: any[], updatedDetails: any[]) => {
    let updatedObj = {};
    let i = 0;
    details.forEach((detail: any) => {
        updatedObj[detail] = updatedDetails[i];
        i++;
    });
    return updatedObj;
};
export const updateHeaderDetails = (
    functionHeaderDetails: FunctionHeaderTypeDetails,
    overallStatus: boolean,
    tableType: 'INPUT' | 'OUTPUT' | 'CONDITION'
) => {
    let headerDetails: FunctionHeaderTypeDetails = {
        inputDetails: {},
        outputDetails: {},
        conditionDetails: {},
        calculationDetails: {},
    };
    if (tableType === TABLE_IO_TYPE.INPUT) {
        const updatedDetails = getUpdateDetails(
            Object.values(functionHeaderDetails.inputDetails),
            overallStatus
        );
        const updatedObject = getUpdatedObject(
            Object.keys(functionHeaderDetails.inputDetails),
            updatedDetails
        );
        headerDetails.inputDetails = updatedObject;
        headerDetails.outputDetails = functionHeaderDetails.outputDetails;
        headerDetails.conditionDetails = functionHeaderDetails.conditionDetails;
        headerDetails.calculationDetails = functionHeaderDetails.calculationDetails;
    } else if (tableType === TABLE_IO_TYPE.OUTPUT) {
        const updatedDetails = getUpdateDetails(
            Object.values(functionHeaderDetails.outputDetails),
            overallStatus
        );
        const updatedObject = getUpdatedObject(
            Object.keys(functionHeaderDetails.outputDetails),
            updatedDetails
        );
        if (Object.keys(functionHeaderDetails.conditionDetails).length > 0) {
            delete updatedObject['severity']['isDelete'];
        }
        headerDetails.inputDetails = functionHeaderDetails.inputDetails;
        headerDetails.outputDetails = updatedObject;
        headerDetails.conditionDetails = functionHeaderDetails.conditionDetails;
        headerDetails.calculationDetails = functionHeaderDetails.calculationDetails;
    }
    return headerDetails;
};
export const getUpdatedConditionDetails = (key: string, conditionDetails: {}) => {
    let updatedConditionDetails = conditionDetails;
    const selectedObj = updatedConditionDetails[key];
    if (selectedObj.hasOwnProperty('deleteFlag')) {
        if (selectedObj.hasOwnProperty('subConditions')) {
            Object.values(Object.values(selectedObj)[0] as {}).map((subCondition: any) => {
                delete subCondition.deleteFlag;
            });
        }
        delete selectedObj.deleteFlag;
    } else {
        if (selectedObj.hasOwnProperty('subConditions')) {
            Object.values(Object.values(selectedObj)[0] as {}).map((subCondition: any) => {
                subCondition.deleteFlag = true;
            });
        }
        selectedObj.deleteFlag = true;
    }
    return updatedConditionDetails;
};

export const getUpdatedList = (functions: any[], newFunction: any) => {
    let updatedFunctions = [];
    let functionPresent = false;
    functions.forEach((functionDetail) => {
        if (functionDetail.id.value === newFunction.id.value) {
            functionPresent = true;
        }
    });
    if (functionPresent) {
        const reduced = functions.filter((functionDetail) => {
            return functionDetail.id.value !== newFunction.id.value;
        });
        updatedFunctions = [...reduced, newFunction];
    } else {
        updatedFunctions = [...functions, newFunction];
    }

    updatedFunctions.sort(function (functionA, functionB) {
        var nameA = functionA.name.value.toLowerCase(),
            nameB = functionB.name.value.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    return updatedFunctions;
};
const formatData = (details: any) => {
    Object.values(details).forEach((detail: any) => {
        if (detail.hasOwnProperty('isDelete') || detail.hasOwnProperty('deleteFlag')) {
            delete detail.isDelete;
            delete detail.deleteFlag;
        }
    });
    return details;
};
const formatConditionData = (details: any) => {
    Object.values(details).forEach((detail: any) => {
        if (detail.hasOwnProperty('deleteFlag')) {
            if (detail.hasOwnProperty('subConditions')) {
                detail.subConditions = formatData(detail.subConditions);
            }
            delete detail.deleteFlag;
        }
    });
    return details;
};
export const objectsToSave = (
    inputDetails: any,
    conditionDetails: any,
    calculationDetails: any,
    outputDetails: any
) => {
    const formattedData = {
        inputDetails: _.cloneDeep(inputDetails),
        conditionDetails: _.cloneDeep(conditionDetails),
        calculationDetails: _.cloneDeep(calculationDetails),
        outputDetails: _.cloneDeep(outputDetails),
    };
    formattedData.inputDetails = formatData(inputDetails);
    formattedData.outputDetails = formatData(outputDetails);
    formattedData.calculationDetails = formatData(calculationDetails);
    formattedData.conditionDetails = formatConditionData(conditionDetails);
};

export const finalOutputConditionJSON = (conditionDetails: any, objectLink: string) => {
    const finalObj = {};
    Object.keys(conditionDetails).forEach((conditionName) => {
        const subCondition = conditionDetails[conditionName]['subConditions'];
        const subConditionKeys = Object.keys(subCondition);
        finalObj[conditionName] = {
            subCondition: {
                dataType: 'string',
                enum: [...subConditionKeys],
            },
        };
        const subConditionProperties = subCondition[subConditionKeys[0]];
        Object.keys(subConditionProperties).forEach((property) => {
            finalObj[conditionName][property] = {
                dataType: subConditionProperties[property]['dataType'],
            };
        });
        finalObj[conditionName][conditionName + 'Alarm'] = {
            dataType: 'string',
            objectLink: objectLink,
        };
    });

    return finalObj;
};

export function validURL(str: string) {
    // var pattern = new RegExp(
    //     "^(https?:\\/\\/)" + // protocol
    //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    //     "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    //     "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    //     "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    //         "(\\#[-a-z\\d_]*)?$",
    //     "i"
    // ); // fragment locator
    // return !!pattern.test(str);
    try {
        const urlString = str.toLowerCase();
        const url = new URL(urlString);
        if (url.href === urlString) {
            return true;
        }
    } catch (err) {
        console.log(err);
        return false;
    }
    return false;
}

export const getJsonArrayFromZip = async (filesToBeImported: any) => {
    let jsonsToBeImported: any = [];
    var blob = new Blob([filesToBeImported as any], {
        type: 'application/zip',
    });
    var new_zip = new JSZip();
    try {
        jsonsToBeImported = await new_zip.loadAsync(blob).then(async (zipped: any) => {
            return Promise.all(
                Object.keys(zipped.files).map(async (filename) => {
                    const json = await zipped.file(filename);
                    let finalJson = undefined;
                    if (json) {
                        finalJson = await json.async('string');
                    }
                    let res = null;
                    if (finalJson && filename[0] !== '_') {
                        res = JSON.parse(finalJson);
                    }
                    return res;
                })
            );
        });
        jsonsToBeImported = jsonsToBeImported.filter((item: any) => item !== null);
        return jsonsToBeImported;
    } catch (e) {
        const error = {
            message: IMPORT_FILE.INVALID_IMPORT_MSG,
            originalError: e,
        };
        throw error;
    }
};

export const checkFilesValidity = (
    files: {
        model: ComputeModelToJson;
        functionTypes: object[];
    }[]
) => {
    let valid = true;
    files.forEach((file) => {
        try {
            const transform = new ComputeModelFromJson(file.model);
        } catch (error) {
            console.log('error :', error);
            valid = false;
        }
    });
    return valid;
};

export const getAssetRef = (supportedModels: any, modalType: any) => {
    let modalRef: any;
    const modalName = Object.keys(modalType)[0];
    supportedModels.forEach((supportedModalType: any) => {
        const assets: any = Object.values(supportedModalType)[1];
        assets.forEach((assetType: any) => {
            if (assetType.assetName === modalName) {
                modalRef = assetType.assetType;
            }
        });
    });
    return modalRef;
};

export const reactSelectMenuStyle = (provided: any, state: any) => ({
    ...provided,
    marginTop: '0px',
    borderRadius: '0px',
    position: 'relative',
    zIndex: 0,
});

export const customReactSelectStyles = {
    menu: (provided: any, state: any) => ({
        ...provided,
        marginTop: '0px',
        borderRadius: '0px',
        // position: "relative",
        zIndex: 9999,
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,

        maxHeight: '100px',
        overflowY: 'auto',
        padding: '0px 0px', //"2px 2px"
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        padding: '0px',
    }),
    clearIndicator: (provided: any, state: any) => ({
        ...provided,
        padding: '0px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        padding: '0px',
    }),
    control: (styles: CSSProperties, state: any) => ({
        ...styles,
        backgroundColor: 'white',
        borderRadius: '0px',
        minHeight: '32px',
        padding: '0px',
        border: state.isSelected || state.isFocused ? '1px solid #3366ff' : '1px solid #bababa ',
    }),
    option: (provided: CSSProperties, state: any) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000000',
        backgroundColor: state.isDisabled
            ? '#fff'
            : state.isSelected
                ? '#3366ff'
                : state.isFocused
                    ? '#dbdbdb'
                    : '#fff',
        ':hover': {
            backgroundColor: '#dbdbdb',
        },
    }),

    multiValue: (styles: any) => {
        return {
            ...styles,
            backgroundColor: '#dbdbdb',
            borderRadius: '40px',
            padding: '2px',
        };
    },
    multiValueLabel: (styles: any) => ({
        ...styles,
        color: '#1f1f1f',
        maxWidth: '150px',
        backgroundColor: '#dbdbdb',
        borderRadius: '40px',
        textOverflow: 'inherit',
        // whiteSpace: "nowrap",
        overflow: 'auto',
        marginLeft: '10px',
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        color: '#1f1f1f',
        backgroundColor: '#dbdbdb',
        borderRadius: '40px',
        ':hover': {
            backgroundColor: '#858585',
            color: '#1f1f1f',
            borderRadius: '40px',
        },
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided,
            opacity,
            transition,
            textOverflow: 'inherit',
            overflow: 'auto',
        };
    },
};

export const isTypePreviouslySelected = (amInfoData: any[], modelId: string) => {
    const previouslySelected = amInfoData.find((item) => item.assetMonitorModel === modelId);

    if (previouslySelected) {
        return true;
    }
    return false;
};
export const assetSelectedToDelete = (assetsArray: any[], searchItem: any) => {
    let present = assetsArray.findIndex((item: any, index: any) => {
        if (item.nodeId === searchItem.nodeId) {
            return true;
        }
        return false;
    });
    return present;
};

export const deleteFromCanvas = (assetsArray: any, tableData: any) => {
    let updatedTableData = _.cloneDeep(tableData);
    updatedTableData.objectTypeDetails = updatedTableData.objectTypeDetails.filter(
        (item: any) => assetSelectedToDelete(assetsArray, item) === -1
    );
    updatedTableData.alarmTypeDetails = updatedTableData.alarmTypeDetails.filter(
        (item: AlarmTypeDetailsWithMappings) => {
            if (item.inputs[0].mappingDetails[0]) {
                // return assetsArray.filter((asset: any) => {
                //     return (asset instanceof ObjectTypeDetail && item.inputs[0].mappingDetails[0].output.asset.nodeId === asset.nodeId)
                // }).length > 0;
                let isAlarmDeleted =
                    assetSelectedToDelete(
                        assetsArray,
                        item.inputs[0].mappingDetails[0].output.asset
                    ) !== -1;
                if (isAlarmDeleted) {
                    assetsArray.push(item);
                }
                return !isAlarmDeleted;
            }
            return true;
        }
    );
    updatedTableData.eventTypeDetails = updatedTableData.eventTypeDetails.filter((item: any) => {
        if (item.inputs[0].mappingDetails[0]) {
            let isEventDeleted =
                assetSelectedToDelete(
                    assetsArray,
                    item.inputs[0].mappingDetails[0].output.asset
                ) !== -1;
            if (isEventDeleted) {
                assetsArray.push(item);
            }
            return !isEventDeleted;
        }
        return true;
    });
    updatedTableData.functionTypeDetails = updatedTableData.functionTypeDetails.filter(
        (item: any) => assetSelectedToDelete(assetsArray, item) === -1
    );
    updatedTableData.functionTypeDetails = updatedTableData.functionTypeDetails.map(
        (functionDetail: FunctionTypeDetailsWithMappings) => {
            const functionInputs = functionDetail.inputs;
            functionInputs.map((inputType) => {
                let updatedMappingDetails: FunctionTypeDetailsWithMappings['inputs'][0]['mappingDetails'] =
                    [];
                inputType.mappingDetails.forEach((item) => {
                    if (
                        assetSelectedToDelete(assetsArray, item.input.asset) === -1 &&
                        assetSelectedToDelete(assetsArray, item.output.asset) === -1
                    ) {
                        updatedMappingDetails.push(item);
                    }
                });
                inputType.mappingDetails = updatedMappingDetails;
                return inputType;
            });
            const functionAlarmInputs = functionDetail.alarmInputs;
            functionDetail.alarmInputs = functionAlarmInputs.filter(
                (inputType) =>
                    updatedTableData.alarmTypeDetails.findIndex(
                        (alarm: AlarmTypeDetailsWithMappings) =>
                            alarm.nodeId === inputType.mappingDetails[0].output.asset.nodeId
                    ) >= 0
            );

            const functionEventInputs = functionDetail.eventInputs;
            functionDetail.eventInputs = functionEventInputs.filter(
                (inputType) =>
                    updatedTableData.alarmTypeDetails.findIndex(
                        (event: EventTypeDetailsWithMappings) =>
                            event.nodeId === inputType.mappingDetails[0].output.asset.nodeId
                    ) >= 0
            );

            return functionDetail;
        }
    );

    assetsArray.forEach((asset: any) => {
        if (asset.alarmInputs && asset.alarmInputs.length > 0) {
            asset.alarmInputs.forEach((input: { mappingDetails: any }) => {
                let alarmDetail = updatedTableData.alarmTypeDetails.filter(
                    (alarm: AlarmTypeDetailsWithMappings) =>
                        input.mappingDetails[0].output.asset.nodeId === alarm.nodeId
                )[0];
                if (alarmDetail) {
                    alarmDetail.outputs.forEach((alarmInput: any) => {
                        if (alarmInput.name === 'alarmId') {
                            alarmInput.connected = false;
                        }
                    });
                }
            });
        }
        if (asset.eventInputs && asset.eventInputs.length > 0) {
            asset.eventInputs.forEach((input: { mappingDetails: any }) => {
                let eventDetail = updatedTableData.eventTypeDetails.filter(
                    (event: EventTypeDetailsWithMappings) =>
                        input.mappingDetails[0].output.asset.nodeId === event.nodeId
                )[0];
                if (eventDetail) {
                    eventDetail.outputs.forEach((eventInput: any) => {
                        if (eventInput.name === 'eventId') {
                            eventInput.connected = false;
                        }
                    });
                }
            });
        }
    });

    updatedTableData.alarmTypeDetails = updatedTableData.alarmTypeDetails.filter(
        (alarmDetail: AlarmTypeDetailsWithMappings) => {
            let isAlarmOutputsConnected =
                alarmDetail.outputs.filter((alarmOutput) => {
                    return alarmOutput.connected;
                }).length > 0;
            return isAlarmOutputsConnected || alarmDetail.inputs[0].connected;
        }
    );

    updatedTableData.eventTypeDetails = updatedTableData.eventTypeDetails.filter(
        (eventDetail: EventTypeDetailsWithMappings) => {
            let isEventOutputsConnected =
                eventDetail.outputs.filter((eventOutput) => {
                    return eventOutput.connected;
                }).length > 0;
            return isEventOutputsConnected || eventDetail.inputs[0].connected;
        }
    );

    return updatedTableData;
};

export function getConfiguredAndUnConfiguredType(amInfoData: any[], selectedTypes: SelectedTypes) {
    let configuredTypes: string[] = [];
    let unConfiguredTypes: string[] = [];

    Object.keys(selectedTypes).forEach((type) => {
        const isPreviouslySelected = isTypePreviouslySelected(amInfoData, type);

        if (!isPreviouslySelected) {
            unConfiguredTypes.push(type);
        } else {
            configuredTypes.push(type);
        }
    });
    return { configuredTypes, unConfiguredTypes };
}

export const getMultipleSelectedInstances = (
    instanceMonitoringList: InstanceMonitoringItem[],
    instanceTableDataById: TableDataById
) => {
    let multipSelectedInstance: SelectedInstances[] = [];

    instanceMonitoringList.forEach((item) => {
        let objectId = item.key;
        let sceModuleId = instanceTableDataById[objectId].amInfo[0].cmeID;
        item.value.forEach((model) => {
            multipSelectedInstance.push({
                objectId,
                model,
                sceModuleId,
            });
        });
    });

    return multipSelectedInstance;
};

export const getobjectToSceModuleIds = (
    selectedInstances: string[],
    instanceTableDataById: TableDataById
) => {
    let objectToSceModuleIds: {
        key: string;
        value: string | null;
    }[] = [];

    selectedInstances.forEach((item) => {
        let sceModuleId = instanceTableDataById[item].amInfo[0].cmeID;
        objectToSceModuleIds.push({
            key: item,
            value: sceModuleId,
        });
    });
    return objectToSceModuleIds;
};

export const getTypeDetailsFromImportedFile = (files: any) => {
    const typeNames = files.map((item: any) => {
        let typeDetails = {};
        typeDetails = {
            objectType:
                item.model.properties.associatedObjectType.typeId.value.split('.')[
                item.model.properties.associatedObjectType.typeId.value.split('.').length - 1
                ] +
                ' ' +
                '(' +
                item.model.properties.associatedObjectType.model.value.split('.')[
                item.model.properties.associatedObjectType.model.value.split('.').length - 1
                ] +
                ')',
            nameWithVersion:
                item.model.properties.model.name.value +
                ' ' +
                item.model.properties.model.version.value,
            name: item.model.properties.model.name.value,
        };
        return typeDetails;
    });
    return typeNames;
};

export const checkTypesForDuplicateTypeIdAndName = (options: {
    typeId: string;
    name: string;
    computeModels: ComputeModelFromJson[];
}) => {
    const { typeId, name, computeModels } = options;
    let isTypeIdExist = false;
    let isNameExist = false;
    computeModels.forEach((type: ComputeModelFromJson) => {
        console.log('typee', type.modelDetails.typeId);
        console.log(typeId);
        if (type.modelDetails.typeId === typeId) {
            isTypeIdExist = true;
        }
        if (type.modelDetails.name === name) {
            isNameExist = true;
        }
    });

    return {
        isTypeIdExist,
        isNameExist,
    };
};

export const markConditionDataDirty = (options: {
    originalConditons: any;
    changedConditions: any;
    route?: typeof ROUTE_MODE.FUNCTIONS | typeof ROUTE_MODE.TYPES;
    savedConditions?: any;
    addIsDirty?: boolean;
}) => {
    const { originalConditons, changedConditions, route, savedConditions, addIsDirty } = options;
    let overRiddenCondition = _.cloneDeepWith(originalConditons);
    let isDirty = false;
    const hasChangedConditions = Object.keys({ ...changedConditions }).length > 0;
    Object.keys(originalConditons).forEach((condition) => {
        const subConditions = originalConditons[condition].subConditions;
        Object.keys(subConditions).forEach((subCondition) => {
            const subConditionData = subConditions[subCondition];
            Object.keys(subConditionData).forEach((item) => {
                const changedSubCondition =
                    (hasChangedConditions &&
                        changedConditions[condition] &&
                        changedConditions[condition]['subConditions'][subCondition]) ||
                    undefined;
                const changedConditionItem = hasChangedConditions
                    ? (changedSubCondition && changedSubCondition[item]) || undefined
                    : undefined;
                const overRiddenConditionItem =
                    overRiddenCondition[condition]['subConditions'][subCondition][item];
                if (overRiddenConditionItem['isNew']) {
                    delete overRiddenCondition[condition]['subConditions'][subCondition][item][
                        'isNew'
                    ];
                }
                const savedConditionItem =
                    savedConditions &&
                    savedConditions[condition] &&
                    savedConditions[condition]['subConditions'][subCondition] &&
                    savedConditions[condition]['subConditions'][subCondition][item];
                if (changedConditionItem) {
                    overRiddenConditionItem['isDirty'] = false;
                    if (subConditionData[item].dataType !== changedConditionItem.dataType) {
                        overRiddenConditionItem['isDirty'] = true;
                        overRiddenConditionItem.dataType = changedConditionItem.dataType;
                        isDirty = true;
                    }
                    if (!subConditionData[item].value && changedConditionItem.value) {
                        if (savedConditionItem && savedConditionItem['isDirty']) {
                            if (savedConditionItem.value) {
                                overRiddenConditionItem['isDirty'] = true;
                                overRiddenConditionItem.value = changedConditionItem.value;
                                if (savedConditionItem.value !== changedConditionItem.value) {
                                    isDirty = true;
                                }
                            }
                        } else {
                            overRiddenConditionItem['isDirty'] = true;
                            overRiddenConditionItem.value = changedConditionItem.value;
                            isDirty = true;
                        }
                    }

                    if (savedConditionItem && savedConditionItem['isDirty']) {
                        if (
                            savedConditionItem.value ||
                            savedConditionItem.value === '' ||
                            changedConditionItem.value
                        ) {
                            overRiddenConditionItem['isDirty'] = true;
                            overRiddenConditionItem.value = changedConditionItem.value;
                            if (savedConditionItem.value !== changedConditionItem.value) {
                                isDirty = true;
                            }
                        }
                    } else {
                        if (subConditionData[item]) {
                            if (subConditionData[item].value !== changedConditionItem.value) {
                                overRiddenConditionItem['isDirty'] = true;
                                overRiddenConditionItem.value = changedConditionItem.value;
                                isDirty = true;
                            }

                            if (!route || route !== ROUTE_MODE.FUNCTIONS) {
                                if (
                                    (subConditionData[item].value &&
                                        !changedConditionItem.hasOwnProperty('value')) ||
                                    (changedConditionItem.value === '' &&
                                        !subConditionData[item].hasOwnProperty('value'))
                                ) {
                                    overRiddenConditionItem['isDirty'] = false;
                                    overRiddenConditionItem.value = subConditionData[item].value;
                                }
                            }
                        }
                    }

                    // check for isDirty flag using saved conditions.

                    if (savedConditionItem) {
                        if (savedConditionItem['isDirty'] || addIsDirty) {
                            if (!savedConditionItem['isNew']) {
                                overRiddenConditionItem['isDirty'] = true;
                            }
                        }
                    }
                } else {
                    overRiddenConditionItem['isDirty'] = false;
                }
            });
        });
    });

    changedConditions && Object.keys(changedConditions).forEach((condition) => {
        if(!originalConditons[condition]){
            overRiddenCondition[condition] = changedConditions[condition];
        }
    });
    return { overRiddenCondition, isDirty };
};

export const getOverRiddenConditionData = (conditionsData: any) => {
    let overRiddenCondition = {};
    let incomingConditionData = _.cloneDeepWith(conditionsData);
    Object.keys(incomingConditionData).forEach((condition) => {
        const subConditions = incomingConditionData[condition].subConditions;
        Object.keys(subConditions).forEach((subCondition) => {
            const subConditionData = subConditions[subCondition];
            Object.keys(subConditionData).forEach((item) => {
                const changedConditionItem =
                    incomingConditionData[condition]['subConditions'][subCondition][item];
                if (changedConditionItem.isDirty) {
                    delete changedConditionItem['isDirty'];

                    if (overRiddenCondition[condition]) {
                        overRiddenCondition[condition] = {
                            ...overRiddenCondition[condition],
                        };
                    } else {
                        overRiddenCondition[condition] = {};
                    }

                    if (overRiddenCondition[condition]['subConditions']) {
                        overRiddenCondition[condition]['subConditions'] = {
                            ...overRiddenCondition[condition]['subConditions'],
                        };
                    } else {
                        overRiddenCondition[condition]['subConditions'] = {};
                    }
                    if (overRiddenCondition[condition]['subConditions'][subCondition]) {
                        overRiddenCondition[condition]['subConditions'][subCondition] = {
                            ...overRiddenCondition[condition]['subConditions'][subCondition],
                        };
                    } else {
                        overRiddenCondition[condition]['subConditions'][subCondition] = {};
                    }
                    if (overRiddenCondition[condition]['subConditions'][subCondition][item]) {
                        overRiddenCondition[condition]['subConditions'][subCondition][item] = {
                            ...overRiddenCondition[condition]['subConditions'][subCondition][item],
                        };
                    } else {
                        overRiddenCondition[condition]['subConditions'][subCondition][item] =
                            changedConditionItem;
                    }
                }
            });
        });
    });
    return overRiddenCondition;
};

export const markCalculationDirty = (options: {
    originalCalculations: any;
    changedCalculations: any;
}) => {
    const { originalCalculations, changedCalculations } = options;
    let overWrittenCalculations = _.cloneDeepWith(originalCalculations);
    let isDirty = false;
    Object.keys({ ...originalCalculations }).forEach((calculation) => {
        const calculationData = originalCalculations[calculation];
        Object.keys({ ...calculationData }).forEach((item) => {
            const changedCalculationItem = changedCalculations[calculation][item];
            const overWrittenCalculationItem = overWrittenCalculations[calculation][item];
            if (
                changedCalculationItem.hasOwnProperty('dataType') &&
                changedCalculationItem.hasOwnProperty('value')
            ) {
                overWrittenCalculationItem['isDirty'] = false;
                if (
                    changedCalculationItem['dataType'] !== overWrittenCalculationItem['dataType'] ||
                    changedCalculationItem['value'] !== overWrittenCalculationItem['value']
                ) {
                    overWrittenCalculationItem['isDirty'] = true;
                    overWrittenCalculationItem['dataType'] = changedCalculationItem['dataType'];
                    overWrittenCalculationItem['value'] = changedCalculationItem['value'];
                    isDirty = true;
                }
            }
        });
    });
    return { overWrittenCalculations, isDirty };
};

export const getRelatedCalculationJson = (calculationsData: any) => {
    let updatedCalculations = _.cloneDeepWith(calculationsData);
    Object.keys({ ...calculationsData }).forEach((calculation) => {
        const calculationData = updatedCalculations[calculation];
        Object.keys({ ...calculationData }).forEach((item) => {
            if (
                !(
                    calculationData[item].hasOwnProperty('isDirty') &&
                    calculationData[item]['isDirty'] === true
                )
            ) {
                delete calculationData[item];
            } else {
                delete calculationData[item]['isDirty'];
            }
        });
        if (isNilOrEmpty(updatedCalculations[calculation])) {
            delete updatedCalculations[calculation];
        }
    });
    return updatedCalculations;
};

export const checkVersionUpdateStatus = (options: {
    oldVersion: string;
    currentVersion: string;
    versionUpdateType: VersionUpdateType;
}) => {
    const { oldVersion, currentVersion, versionUpdateType } = options;
    console.log(versionUpdateType);

    let errorText = '';
    let successVersionUpdate = true;
    const oldVersionData = oldVersion.split('.');
    const currentVersionData = currentVersion.split('.');
    const oldData = {
        major: parseInt(oldVersionData[0]),
        minor: parseInt(oldVersionData[1]),
        patch: parseInt(oldVersionData[2]),
    };
    const currentData = {
        major: parseInt(currentVersionData[0]),
        minor: parseInt(currentVersionData[1]),
        patch: parseInt(currentVersionData[2]),
    };
    switch (versionUpdateType) {
        case VERSION_UPDATE_TYPE.MAJOR: {
            if (currentData.major <= oldData.major) {
                errorText = `Please update the version to ${oldData.major + 1}.0.0 or higher.`;
                successVersionUpdate = false;
            }
            if (currentData.minor !== 0 || currentData.patch !== 0) {
                errorText = `Only major version update required.Please set minor and patch version to 0.`;
                successVersionUpdate = false;
            }
            break;
        }
        case VERSION_UPDATE_TYPE.MINOR: {
            if (currentData.major === oldData.major) {
                if (currentData.minor <= oldData.minor) {
                    errorText = `Please update the version to ${oldData.major}.${oldData.minor + 1
                        }.${oldData.patch} or higher.`;
                    successVersionUpdate = false;
                }
            }
            if (currentData.major > oldData.major) {
                if (currentData.minor !== 0 || currentData.patch !== 0) {
                    errorText = `Please increment either major or minor version (${oldData.major}.${oldData.minor + 1
                        }.${oldData.patch} or higher).`;
                    successVersionUpdate = false;
                }
            }
            if (oldData.major > currentData.major) {
                errorText = `Please increment either major or minor version (${oldData.major}.${oldData.minor + 1
                    }.${oldData.patch} or higher).`;
                successVersionUpdate = false;
            }
            break;
        }
        case VERSION_UPDATE_TYPE.PATCH: {
            if (currentData.major > oldData.major) {
                if (currentData.minor !== oldData.minor || currentData.patch !== oldData.patch) {
                    errorText = `Please update either major or minor version`;
                    successVersionUpdate = false;
                }
            }
            if (currentData.major !== oldData.major || currentData.minor !== oldData.minor) {
                errorText = `Only patch version update is required`;
                successVersionUpdate = false;
            }
            if (currentData.major === oldData.major) {
                if (currentData.minor === oldData.minor) {
                    if (currentData.patch <= oldData.patch) {
                        errorText = `Please update the version to ${oldData.major}.${oldData.minor
                            }.${oldData.patch + 1} or higher.`;
                        successVersionUpdate = false;
                    }
                } else if (currentData.minor < oldData.minor) {
                    errorText = `Please update the version to ${oldData.major}.${oldData.minor}.${oldData.patch + 1
                        } or higher.`;
                    successVersionUpdate = false;
                }
            }
            if (currentData.major < oldData.major) {
                errorText = `Please update the version to ${oldData.major}.${oldData.minor}.${oldData.patch + 1
                    } or higher.`;
                successVersionUpdate = false;
            }
            break;
        }
        case VERSION_UPDATE_TYPE.DEFAULT: {
            if (oldVersion !== currentVersion) {
                errorText = `No changes!. Please set version to ${oldData.major}.${oldData.minor}.${oldData.patch}.`;
                successVersionUpdate = false;
            }
            break;
        }
        default: {
            console.log('Invalid version update scenario');
        }
    }

    return {
        errorVersionUpdateText: errorText,
        successVersionUpdate: successVersionUpdate,
    };
};

export const compareTypesFormDetails = (options: {
    originalDetails: ComputeModelFromJson['modelDetails'];
    updatedDetails: ComputeModelFromJson['modelDetails'];
}) => {
    const { originalDetails, updatedDetails } = options;
    let hasChangedDetails = false;

    if (
        originalDetails.name !== updatedDetails.name ||
        originalDetails.description !== updatedDetails.description ||
        originalDetails.model !== updatedDetails.model ||
        originalDetails.tags[0] !== updatedDetails.tags[0]
    ) {
        hasChangedDetails = true;
    }
    return hasChangedDetails;
};

export const isMinorVersionUpdate = (options: { oldVersion: string; currentVersion: string }) => {
    const { oldVersion, currentVersion } = options;
    let isMinorVersionUpdate = false;

    const oldVersionData = oldVersion.split('.');
    const currentVersionData = currentVersion.split('.');
    const oldData = {
        major: parseInt(oldVersionData[0]),
        minor: parseInt(oldVersionData[1]),
        patch: parseInt(oldVersionData[2]),
    };
    const currentData = {
        major: parseInt(currentVersionData[0]),
        minor: parseInt(currentVersionData[1]),
        patch: parseInt(currentVersionData[2]),
    };

    if (currentData.major === oldData.major) {
        if (currentData.minor > oldData.minor) {
            isMinorVersionUpdate = true;
        }
    }
    return isMinorVersionUpdate;
};

export const handleTokenDecode = () => {
    // const token =
    //     AppSettings.cacheLocation === 'localStorage'
    //         ? localStorage.getItem('token')
    //         : sessionStorage.getItem('token');
    const token = commonAuthHelper.getToken();
    if (!!token) AbilityService.decodeToken(token);
};
const sampleSubCondition = {
    description: { dataType: 'string' },
    severity: { dataType: 'integer' },
    correctiveAction: { dataType: 'string' },
    logic: { dataType: 'string' },
    possibleCause: { dataType: 'string' },
    suggestedAction: { dataType: 'string' },
    status: { dataType: 'string' },
};

export const getUpdatedConditionsForExport = (modelJson: ComputeModelToJson[]) => {
    let updatedModelJson: ComputeModelToJson[] = _.cloneDeep(modelJson);
    updatedModelJson.forEach((modelJson: ComputeModelToJson) => {
        let associatedFunction = modelJson.properties.functions;
        Object.keys(associatedFunction).forEach((functionDetail) => {
            if (associatedFunction[functionDetail].inputs.hasOwnProperty('conditions')) {
                let conditions = associatedFunction[functionDetail].inputs.conditions;
                Object.keys(conditions).forEach((conditionDetail) => {
                    let subConditions = conditions[conditionDetail].subConditions;
                    Object.keys(subConditions).forEach((subConditionKey) => {
                        let subConditionDetail = subConditions[subConditionKey];
                        Object.keys(sampleSubCondition).forEach((item) => {
                            if (subConditionDetail.hasOwnProperty(item)) {
                                if (
                                    !subConditionDetail[item].hasOwnProperty('isDirty') ||
                                    (subConditionDetail[item].hasOwnProperty('isDirty') &&
                                        subConditionDetail[item]['isDirty'] === true)
                                ) {
                                    subConditionDetail[item]['isDirty'] = true;
                                } else {
                                    delete subConditionDetail[item];
                                }
                            }
                        });

                        if (Object.keys(subConditionDetail).length === 0) {
                            delete subConditions[subConditionKey];
                        }
                    });
                    if (Object.keys(subConditions).length === 0) {
                        delete conditions[conditionDetail];
                    }
                });
                if (Object.keys(conditions).length === 0) {
                    delete associatedFunction[functionDetail].inputs.conditions;
                }
            }
        });
    });

    return updatedModelJson;
};

export const overRideConditionForTypeJson = (options: {
    functionTypes: any[];
    typeJson: ComputeModelToJson;
}) => {
    const { typeJson, functionTypes } = options;
    const currentTypeJson = _.cloneDeep(typeJson);
    const availableFunction = currentTypeJson.properties.functions;
    Object.keys(availableFunction).forEach((item) => {
        const currentFunction = availableFunction[item];
        const version = currentFunction.version.value;
        let functionIdWithVersion = currentFunction.functionType.value;
        const lastIndex = functionIdWithVersion.lastIndexOf('@');
        let typeId = functionIdWithVersion;

        if (lastIndex !== -1) {
            typeId = typeId.slice(0, lastIndex);
        }
        if (currentFunction) {
            functionTypes.forEach((data) => {
                if (data.typeId === typeId && data.version === version) {
                    const originalConditions = data.properties.inputs.conditions;
                    const changedConditions = currentFunction.inputs.conditions;
                    if (originalConditions) {
                        const { overRiddenCondition } = markConditionDataDirty({
                            originalConditons: originalConditions,
                            changedConditions,
                        });
                        currentFunction.inputs.conditions = {
                            ...overRiddenCondition,
                        };
                    }
                    if (data.properties.inputs.calculations) {
                        currentFunction.inputs.calculations = data.properties.inputs.calculations;
                    }
                }
            });
        }
    });

    return currentTypeJson;
};

export function updateJsonWithOriginalFunctionDetails(options: {
    json: LocalJson;
    originalFunctionUsed: OriginalFunctionTypes;
    addIsDirtyToOldCondtions?: boolean;
}) {
    const {
        json,
        originalFunctionUsed: originalFunctionTypesUsed,
        addIsDirtyToOldCondtions,
    } = options;

    let udpatedJson: LocalJson = { ...json };
    udpatedJson.assetData.forEach((asset) => {
        if (asset instanceof FunctionTypeDetail) {
            const { assetRef, assetVersion } = asset;
            const lastIndex = assetRef.lastIndexOf('@');
            let typeId = assetRef;

            if (lastIndex !== -1) {
                typeId = typeId.slice(0, lastIndex);
            }
            const funcIdWithVersion = `${typeId}#${assetVersion}`;
            const originalFunctionType =
                originalFunctionTypesUsed.byIdWithVersion[funcIdWithVersion];
            if (originalFunctionType) {
                if (asset.endpoint && addIsDirtyToOldCondtions) {
                    asset.isEndPointDirty = true;
                }
                if (!asset.endpoint) {
                    asset.endpoint = originalFunctionType.endpoint;
                }
                if (originalFunctionType.calculations) {
                    asset.calculations = {
                        ...originalFunctionType.calculations,
                    };
                    //asset.assetVersion = originalFunctionType.assetVersion;
                }
                asset.isEncrypted = originalFunctionType.isEncrypted;
            }
            if (
                originalFunctionType &&
                originalFunctionType.alias &&
                Object.keys(originalFunctionType.alias).length > 0
            ) {
                let originalAlias = _.cloneDeep(originalFunctionType.alias);
                let updatedAlias = _.cloneDeep(asset.alias);
                Object.keys(updatedAlias).forEach((item) => {
                    if (
                        updatedAlias[item] &&
                        originalAlias[item] &&
                        updatedAlias[item].value !== originalAlias[item].value
                    ) {
                        updatedAlias[item].isDirty = true;
                    } else if (!originalAlias[item]) {
                        updatedAlias[item].isDirty = true;
                    } else {
                        updatedAlias[item].isDirty = false;
                    }
                });
                asset.alias = updatedAlias;
            }
            //  else if (
            //     (originalFunctionType && (originalFunctionType.alias === undefined ||
            //         Object.keys(originalFunctionType.alias).length === 0)) &&
            //     asset &&
            //     asset.alias &&
            //     Object.keys(asset.alias).length > 0
            // ) {
            //     let updatedAlias = _.cloneDeep(asset.alias);
            //     Object.keys(updatedAlias).forEach((item) => {
            //         updatedAlias[item].isDirty = updatedAlias[item].value !== item ? true : false;
            //     });
            //     asset.alias = updatedAlias;
            // }
            if (originalFunctionType && originalFunctionType.conditions) {
                const { overRiddenCondition } = markConditionDataDirty({
                    originalConditons: originalFunctionType.conditions,
                    changedConditions: asset.conditions,
                    savedConditions: asset.conditions,
                    addIsDirty: !!addIsDirtyToOldCondtions,
                });
                if (Object.keys({ ...asset.conditions }).length === 0) {
                    asset.assetVersion = originalFunctionType.assetVersion;
                }
                if (overRiddenCondition) {
                    asset.conditions = { ...overRiddenCondition };
                }
            }
            if(!asset.isUpdateAvailable && originalFunctionType) {
                asset.assetVersion = originalFunctionType.assetVersion;
            }
            //alert(asset.assetVersion)
        }
    });

    return udpatedJson;
}

export const checkWhetherFunctionIsSelected = (
    item: FunctionDetailsRequiredByLibrary | UpdateActiveFunctionType,
    list: FunctionDetailsRequiredByLibrary[]
) => {
    let itemPresent = false;
    list.forEach((itemDetail) => {
        if (
            itemDetail.typeId == item.typeId &&
            itemDetail.model === item.model &&
            itemDetail.name === item.name &&
            itemDetail.version === item.version
        ) {
            itemPresent = true;
        }
    });
    return itemPresent;
};

export const checkWhetherConditionMonitorIsUnique = (
    conditionMonitorListToRender: SupportedNewModel[],
    currentMonitorIndex: number,
    value: string
) => {
    const filteredMonitorList = conditionMonitorListToRender.filter(
        (item, index) => index !== currentMonitorIndex
    );
    let isUnique = true;
    filteredMonitorList.forEach((item) => {
        if (item.modelName === value) {
            isUnique = false;
        }
    });
    return isUnique;
};

export const convertModelDetailsToAssetType = (
    modelDetails: ComputeModelFromJson['modelDetails']
) => {
    const assetType: AssetType = {
        assetVersion: modelDetails.version,
        assetDescription: modelDetails.description,
        assetName: modelDetails.name,
        assetRef: modelDetails.typeId,
        assetTags: [...modelDetails.tags],
        assetType: modelDetails.model,
        isReferencedType: true,
    };

    return assetType;
};

export const checkValidityForMonitorsInput = (
    index: number,
    value: string,
    key: string,
    conditionMonitorListToRender: SupportedNewModel[]
) => {
    let valid = true;
    let text = 'OK!';
    let updatedValue = _.cloneDeepWith(value).split('.').join('');
    if (!validateForSwedishAndFinishCharacters(updatedValue)) {
        valid = false;
        text = `Model ${key} cannot have special characters`;
    }

    if (!hasSpecialCharacter(updatedValue)) {
        valid = false;
        text = `Model ${key} cannot have special characters`;
    }

    if (!checkWhetherConditionMonitorIsUnique(conditionMonitorListToRender, index, value)) {
        valid = false;
        text = `Model ${key} should be unique`;
    }
    return { valid, text };
};

export const getReferencesForDroppingAsset = (asset: AssetType) => {
    let assetModelReferences = [asset.assetType];
    if (asset.referencedTypes && asset.referencedTypes.length) {
        asset.referencedTypes.forEach((item) => {
            if (assetModelReferences.indexOf(item.modelId) === -1) {
                assetModelReferences = [...assetModelReferences, item.modelId];
            }
        });
    }
    return assetModelReferences;
};

export const getReferencesFromRemainingCanvasAssets = (assets: Asset[]) => {
    const assetTypes = assets.filter(
        (assetItem) => assetItem instanceof ObjectTypeDetail && assetItem.isReferencedType
    );
    let canvasAssetReferences: any[] = [];

    assetTypes.forEach((assetTypeDetail: Asset) => {
        let obj = {};
        let canvasAssetReferencesList: string[] = [];

        if (canvasAssetReferencesList.indexOf(assetTypeDetail.assetType) === -1) {
            canvasAssetReferencesList = [assetTypeDetail.assetType];
        }
        if (
            assetTypeDetail instanceof ObjectTypeDetail &&
            assetTypeDetail.referencedTypes &&
            assetTypeDetail.referencedTypes.length
        ) {
            assetTypeDetail.referencedTypes.forEach((referenceType) => {
                if (canvasAssetReferencesList.indexOf(referenceType.modelId) === -1) {
                    canvasAssetReferencesList = [
                        ...canvasAssetReferencesList,
                        referenceType.modelId,
                    ];
                }
            });
        }
        obj[assetTypeDetail.assetName] = canvasAssetReferencesList;
        canvasAssetReferences.push(obj);
    });
    return canvasAssetReferences;
};

export const checkWhetherAssetCanBeDropped = (
    currentModelId: string,
    assetModelReferences: string[],
    canvasReferences: any[],
    assetName: string,
    currentModelName: string
) => {
    let isDroppable = true;
    let errorMsg = '';
    if (assetModelReferences.indexOf(currentModelId) !== -1) {
        isDroppable = false;
        errorMsg =
            assetName === currentModelName
                ? `Current Model is already ${assetName}`
                : `ModelId of ${assetName} matches the modelId of ${currentModelName}`;
    } else {
        assetModelReferences.forEach((assetReference) => {
            canvasReferences.forEach((item: any) => {
                const references: any = Object.values(item)[0];
                if (references.indexOf(assetReference) !== -1) {
                    isDroppable = false;
                    errorMsg =
                        assetName === Object.keys(item)[0]
                            ? `${assetName} is already available`
                            : `${assetName} is internally referenced with type ${Object.keys(item)[0]
                            }`;
                }
            });
        });
    }

    return { isDroppable, errorMsg };
};

export const functionDetailRemodelForUpdateRelatedInstances = (
    typeDetails: CreateFunctionAssetDetails | undefined,
    state: StoreState,
    isExtensible: boolean,
    assetInfo: AssetsInfo
) => {
    let updatedTypeDetails = _.cloneDeepWith(typeDetails);
    const { overWrittenCalculations } = markCalculationDirty({
        originalCalculations: {
            ...updatedTypeDetails!.properties.inputs.calculations,
        },
        changedCalculations: {
            ...state.functions.functionHeaderDetails.calculationDetails,
        },
    });
    const newCalculationJson = getRelatedCalculationJson(overWrittenCalculations);
    const { overRiddenCondition } = markConditionDataDirty({
        originalConditons: {
            ...state.functions.typeDetails!.properties.inputs.conditions,
        },
        changedConditions: {
            ...state.functions.functionHeaderDetails.conditionDetails,
        },
        route: ROUTE_MODE.FUNCTIONS,
    });
    const newConditionJson = getOverRiddenConditionData(overRiddenCondition);
    delete updatedTypeDetails!.properties.outputs;
    const typeInputs = _.cloneDeepWith(updatedTypeDetails!.properties.inputs);
    Object.keys(typeInputs).forEach((input) => {
        if (input !== 'conditions' && input !== 'calculations') {
            delete updatedTypeDetails!.properties.inputs[input];
        }
    });

    if (!isNilOrEmpty(newConditionJson)) {
        updatedTypeDetails!.properties['inputs']['conditions'] = newConditionJson;
    } else {
        delete updatedTypeDetails!.properties['inputs']['conditions'];
    }

    if (!isNilOrEmpty(newCalculationJson)) {
        updatedTypeDetails!.properties['inputs']['calculations'] = newCalculationJson;
    } else {
        delete updatedTypeDetails!.properties['inputs']['calculations'];
    }

    if (Object.keys(updatedTypeDetails!.properties['inputs']).length === 0) {
        delete updatedTypeDetails!.properties['inputs'];
    }

    if (updatedTypeDetails) {
        if (!updatedTypeDetails.isExtensible) {
            updatedTypeDetails['isExtensible'] = isExtensible;
        }
        if (updatedTypeDetails!.description === assetInfo.description) {
            delete updatedTypeDetails['description'];
        } else {
            updatedTypeDetails['description'] = assetInfo.description;
        }

        if (updatedTypeDetails!.tags.join() === assetInfo.tags.join()) {
            delete updatedTypeDetails['tags'];
        } else {
            updatedTypeDetails['tags'] = assetInfo.tags;
        }

        if (updatedTypeDetails!.properties.settings.endpoint.value !== assetInfo.endpoint) {
            const endpoint = {
                ...updatedTypeDetails['properties']['settings'].endpoint,
                value: assetInfo.endpoint,
            };
            updatedTypeDetails['properties']['endpoint'] = { ...endpoint };
        }
        updatedTypeDetails['version'] = assetInfo.version;
        delete updatedTypeDetails['properties']['settings'];
    }
    return updatedTypeDetails;
};

export const isImportedZipFileValid = (val: any) => {
    let isValid = false;
    if (val) {
        if (/.zip$/i.test(val.name) && val.name !== '') {
            isValid = true;
        }
    }
    return isValid;
};

export const getSelectedFunctionsForImport = (selectedItemsData: FunctionsLibraries[]) =>
    (selectedItemsData as FunctionsLibraries[]).filter(
        (item) => item.library !== null && item.library.properties.isProtected.value
    );

export const isFunctionSelectedForImport = (selectedItemsData: FunctionsLibraries[]) => {
    return selectedItemsData.length > 0;
};

export const isTypeSelectedForImport = (selectedItemsForImport: TypesWithLibraries[]) => {
    return selectedItemsForImport.length > 0;
};

export const getSelectedTypesForImport = (selectedItemsForImport: TypesWithLibraries[]) => {
    let updatedSelectedItems: TypeLibrary[] = [];
    selectedItemsForImport.forEach((item) => {
        item.libraryReferences &&
            item.libraryReferences.forEach((lib) => {
                let libDetails = {
                    libraryId: lib.libraryId,
                    libraryVersion: lib.libraryVersion,
                    libraryName: lib.libraryName,
                };
                if (
                    _.findIndex(updatedSelectedItems, {
                        libraryId: lib.libraryId,
                        libraryVersion: lib.libraryVersion,
                        libraryName: lib.libraryName,
                    }) == -1
                ) {
                    updatedSelectedItems.push(libDetails);
                }
            });
    });

    return updatedSelectedItems;
};

export const findPassWordDetails = (
    selectedItemsForImport: (FunctionsLibraries | TypesWithLibraries)[]
) => {
    const pathname = `/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        }`;
    let passDetails: {
        libraryName: string;
    }[] = [];

    if (pathname === '/functions') {
        const libraryDetails = getSelectedFunctionsForImport(
            selectedItemsForImport as FunctionsLibraries[]
        );
        if (libraryDetails.length) {
            libraryDetails.forEach((item) => {
                let obj: {
                    libraryName: string;
                } = {
                    libraryName: item.library.name,
                };
                passDetails.push(obj);
            });
        }
    }

    if (pathname === '/models') {
        const libraryDetails = getSelectedTypesForImport(
            selectedItemsForImport as TypesWithLibraries[]
        );
        if (libraryDetails.length) {
            libraryDetails.forEach((item) => {
                let obj: {
                    libraryName: string;
                } = {
                    libraryName: item.libraryName,
                };
                passDetails.push(obj);
            });
        }
    }
    return passDetails;
};

export const isValidToImport = (
    selectedItemsForImport: (FunctionsLibraries | TypesWithLibraries)[]
) => {
    let error = '';
    if (
        window.location.pathname.includes(ROUTE_PATHNAME.FUNCTIONS) &&
        !isFunctionSelectedForImport(selectedItemsForImport as FunctionsLibraries[])
    ) {
        error = 'No Libraries selected';
    } else if (
        window.location.pathname.includes(ROUTE_PATHNAME.HOME) &&
        !isTypeSelectedForImport(selectedItemsForImport as TypesWithLibraries[])
    ) {
        error = 'No Models selected';
    } else {
        error = '';
    }
    return error;
};

export const checkValidityForZipFolder = (files: any) => {
    let isValid = true;
    if (
        window.location.pathname.includes(ROUTE_PATHNAME.FUNCTIONS) &&
        files[0].hasOwnProperty('libraryReferences') &&
        !files[0].hasOwnProperty('library')
    ) {
        isValid = false;
    }

    if (
        window.location.pathname.includes(ROUTE_PATHNAME.HOME) &&
        files[0].hasOwnProperty('hash') &&
        files[0].hasOwnProperty('library')
    ) {
        isValid = false;
    }
    return isValid;
};

export const getFunctionsList = (assetList: Asset[]) => {
    let functionsList: Asset[] = [];
    functionsList = _.cloneDeepWith(assetList).filter(
        (typeDetail: Asset) => typeDetail instanceof FunctionTypeDetail
    );
    return functionsList;
};

export const getTypeBasicsForFunctionTypeDetail = (functionAssetList: Asset[]) => {
    let functionTypeBasicsList: FunctionTypeBasics[] = functionAssetList.map(
        (typeDetail: Asset) => {
            let obj: FunctionTypeBasics = {
                name: '',
                model: '',
                typeId: '',
                version: '',
            };
            obj['name'] = typeDetail.assetName;
            obj['model'] = typeDetail.assetType;
            obj['typeId'] = typeDetail.assetRef.split('@')[0];
            obj['version'] = typeDetail.assetVersion;
            return obj;
        }
    );
    return functionTypeBasicsList;
};

export const filterSegregatedModelByVersion = (segregatedModels: SegregatedModelItem) => {
    let segregatedModelsData: SegregatedModelItem = {};
    Object.keys(segregatedModels).forEach((modelId) => {
        const correspondingModels = segregatedModels[modelId];
        let correspondingModelsObject: {
            [key: string]: SegregatedModelItemValue;
        } = {};
        correspondingModels.forEach((item) => {
            if (!correspondingModelsObject[item.typeId]) {
                correspondingModelsObject[item.typeId] = { ...item };
            } else {
                const alreadyExisitingItem = correspondingModelsObject[item.typeId];
                const oldVersion = alreadyExisitingItem.version;
                const newVersion = item.version;
                const isGreaterVersionAvailable = checkIsGreaterVersionAvailable(
                    oldVersion,
                    newVersion
                );
                if (isGreaterVersionAvailable) {
                    correspondingModelsObject[item.typeId] = { ...item };
                }
            }
        });
        const correspondingModelsObjectValue = Object.values(correspondingModelsObject);
        segregatedModelsData[modelId] = [...correspondingModelsObjectValue];
    });
    return segregatedModelsData;
};

export const checkPermissionRoles = (token: string): boolean => {
    const decodedToken: any = jwt_decode(token);
    const access =
        decodedToken &&
        decodedToken.roles &&
        Array.isArray(decodedToken.roles) &&
        decodedToken.roles.length > 0;
    return access ? true : false;
};

export const checkIsGreaterVersionAvailable = (oldVersion: string, newVersion: string) => {
    let isGreaterVersionAvailable = false;

    const oldVersionData = oldVersion.split('.');
    const newVersionData = newVersion.split('.');
    const oldData = {
        major: parseInt(oldVersionData[0]),
        minor: parseInt(oldVersionData[1]),
        patch: parseInt(oldVersionData[2]),
    };

    const newData = {
        major: parseInt(newVersionData[0]),
        minor: parseInt(newVersionData[1]),
        patch: parseInt(newVersionData[2]),
    };

    if (newData.major > oldData.major) {
        isGreaterVersionAvailable = true;
    } else if (newData.major === oldData.major) {
        if (newData.minor > oldData.minor) {
            isGreaterVersionAvailable = true;
        } else if (newData.minor === oldData.minor) {
            if (newData.patch > oldData.patch) {
                isGreaterVersionAvailable = true;
            }
        }
    }

    return isGreaterVersionAvailable;
};

export const getUpdatedVersionForModelDetails = (options: {
    versionUpdateType: VersionUpdateType;
    oldVersion: string;
}) => {
    const { versionUpdateType, oldVersion } = options;
    const oldVersionData = oldVersion.split('.');
    const newVersion = {
        minor: parseInt(oldVersionData[1]),
        major: parseInt(oldVersionData[0]),
        patch: parseInt(oldVersionData[2]),
    };
    switch (versionUpdateType) {
        case VERSION_UPDATE_TYPE.MAJOR: {
            newVersion.major = newVersion.major + 1;
            break;
        }
        case VERSION_UPDATE_TYPE.MINOR: {
            newVersion.minor = newVersion.minor + 1;
            break;
        }
        case VERSION_UPDATE_TYPE.PATCH: {
            newVersion.patch = newVersion.patch + 1;
            break;
        }
        default: {
            console.log('invalidCase');
        }
    }

    const updatedVersion = `${newVersion.major}.${newVersion.minor}.${newVersion.patch}`;

    return updatedVersion;
};

export const isObjectTypePresent = (json: LocalJson, currentAsset: AssetType) => {
    let matchedAsset = {} as Asset;
    const isPresent = json.assetData.some((item) => {
        if (item instanceof ObjectTypeDetail && !item.objectInstance && !item.isReferencedType) {
            const itemAssetRef = item.assetRef.split('@')[0];
            if (itemAssetRef === currentAsset.assetRef) {
                matchedAsset = item;
                return true;
            }
        }
        return false;
    });
    return { isPresent, matchedAsset };
};

export const getNormalTypeIdForType = (maskedTypeId: string) => {
    const itemOne = maskedTypeId.toLowerCase().includes('.tenantid.')
        ? maskedTypeId.split('.').reverse().splice(2).reverse().join('.')
        : maskedTypeId;
    const itemTwo = itemOne.toLowerCase().includes('abb.local.')
        ? itemOne.split('.').splice(2).join('.')
        : itemOne;

    return itemTwo;
};

export const checIsFunctionUpdateAvailable = (json: LocalJson) => {
    const isFunctionUpdateAvailable = json.assetData.some((item) => {
        if (item instanceof FunctionTypeDetail) {
            if (item.isUpdateAvailable) {
                return true;
            } else {
                return true;
            }
        } else {
            return true;
        }
    });

    return isFunctionUpdateAvailable;
};

export const getMaskedId = (item: {
    typeId: string;
    typeName: string;
    version: string;
    objectId: string;
}) => {
    const value = item.typeId.toLowerCase().includes('.tenantid.')
        ? item.typeId.split('.').reverse().splice(2).reverse().join('.')
        : item.typeId;
    const maskedTypeId = value.toLowerCase().includes('abb.local.')
        ? value.split('.').splice(2).join('.')
        : value;
    const maskName = maskedTypeId;
    return { value, maskName, maskedTypeId };
};

export const getUpdatedSupportedValues = (supportedType: SupportedTypes[], filter: string) => {
    let updatedSupportedType = supportedType;

    if (filter.length > 0 && supportedType.length > 0) {
        updatedSupportedType = supportedType.map((unConfigType) => {
            unConfigType.value.types = unConfigType.value.types.filter((item) =>
                getMaskedId(item).maskName.toLowerCase().includes(filter.toLowerCase())
            );
            return unConfigType;
        });
    }

    return updatedSupportedType;
};
export const getMaskedName = (item: {
    typeId: string;
    typeName: string;
    version: string;
    objectId: string;
}) => {
    const value = item.typeId.toLowerCase().includes('.tenantid.')
        ? item.typeId.split('.').reverse().splice(2).reverse().join('.')
        : item.typeId;
    const maskedTypeId = value.toLowerCase().includes('abb.local.')
        ? value.split('.').splice(2).join('.')
        : value;
    const maskName = item.typeName !== null ? item.typeName : maskedTypeId;
    return { value, maskName, maskedTypeId };
};
export const getUpdatedSupportedValuesName = (supportedType: SupportedTypes[], filter: string) => {
    let updatedSupportedType = supportedType;

    if (filter.length > 0 && supportedType.length > 0) {
        updatedSupportedType = supportedType.map((unConfigType) => {
            unConfigType.value.types = unConfigType.value.types.filter((item) =>
                getMaskedName(item).maskName.toLowerCase().includes(filter.toLowerCase())
            );
            return unConfigType;
        });
    }

    return updatedSupportedType;
};
export const getSelectedLibrary = (
    libraryAndFunctionsList: LibraryAndNodePayload[],
    activeFunctionId: string,
    activeFunctionVersion: string
) => {
    let defaultLib: LibraryAndNodePayloadWithoutNodes = {} as LibraryAndNodePayloadWithoutNodes;

    libraryAndFunctionsList.forEach((item) => {
        item.nodes.forEach((functionDetail) => {
            if (
                functionDetail.typeId === activeFunctionId &&
                functionDetail.version === activeFunctionVersion
            ) {
                defaultLib = {
                    id: item.id,
                    libraryVersion: item.libraryVersion,
                    isIPProtected: item.isIPProtected,
                    password: '',
                    root: item.root,
                };
            }
        });
    });

    return defaultLib;
};

export const getSelectedLibraryWithNodes = (
    libraryAndFunctionsList: LibraryAndNodePayload[],
    activeFunctionId: string
) => {
    let defaultLib: LibraryAndNodePayload = {} as LibraryAndNodePayload;
    libraryAndFunctionsList.forEach((item) => {
        item.nodes.forEach((functionDetail) => {
            if (functionDetail.typeId === activeFunctionId) {
                defaultLib = item;
            }
        });
    });

    return defaultLib;
};

export const getUpdatedLibraryWithNodes = (library: LibraryAndNodePayload,  libraryAndFunctionsList: LibraryAndNodePayload[]) => {
    let updatedLibraryWithNodes = _.cloneDeepWith(libraryAndFunctionsList);
    const libIndex = updatedLibraryWithNodes.findIndex(lib => lib.id === library.id);
    if(libIndex >=0) {
        updatedLibraryWithNodes[libIndex] = library;
    }else {
        updatedLibraryWithNodes = [library,...updatedLibraryWithNodes]
        updatedLibraryWithNodes.sort((a:any, b:any) => {
            if (a.root < b.root) {
              return -1;
            }
            if (a.root > b.root) {
              return 1;
            }
            return 0;
          });
    }
    return updatedLibraryWithNodes;
}

export const validatePasswordRegex = (password: string) => {
    let { min, max } = AppSettings.IpPasswordLength;

    let passwordRegex = new RegExp(
        `^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{${min},${max}}$`
    );
    return passwordRegex.test(password);
};

export const monitorsList = (supportedConditionMonitors: SupportedNewModel[]) => {
    const monitorList = supportedConditionMonitors.map((monitor) => {
        return monitor.modelName;
    });

    return monitorList;
};

export const getMonitorsToDeleteLocally = (
    selectedConditionMonitorModels: SupportedNewModel[],
    addedSupportedConditionMonitorModels: SupportedNewModel[]
) => {
    let monitorsToDeleteLocally: SupportedNewModel[] = [];

    selectedConditionMonitorModels.forEach((monitor) => {
        let isLocalMonitorSelected = false;
        addedSupportedConditionMonitorModels.forEach((localMonitor) => {
            if (localMonitor.modelName === monitor.modelName) {
                isLocalMonitorSelected = true;
            }
        });
        if (isLocalMonitorSelected) {
            monitorsToDeleteLocally.push(monitor);
        }
    });

    return monitorsToDeleteLocally;
};

export const getMonitorsToDeleteRemotely = (
    selectedConditionMonitorModels: SupportedNewModel[],
    monitorsToDeleteLocally: SupportedNewModel[]
) => {
    let monitorsToDeleteRemotely: SupportedNewModel[] = [];

    selectedConditionMonitorModels.forEach((monitor) => {
        let isMonitorPresentForLocalDelete = false;
        monitorsToDeleteLocally.forEach((localMonitor) => {
            if (localMonitor.modelName === monitor.modelName) {
                isMonitorPresentForLocalDelete = true;
            }
        });
        if (!isMonitorPresentForLocalDelete) {
            monitorsToDeleteRemotely.push(monitor);
        }
    });

    return monitorsToDeleteRemotely;
};
/** used to prevent duplication of condition name */
export const checkForDuplicateConditionName = (
    conditionFunctionType: any[],
    currentConditionName: string
) => {
    const hasDuplicateName = conditionFunctionType.some((item) => {
        const conditionName = Object.keys(item).filter(
            (key) => key !== 'error' && key !== 'deleteFlag'
        )[0];
        if (conditionName === currentConditionName) {
            return true;
        } else {
            return false;
        }
    });

    return hasDuplicateName;
};

/** used to prevent duplication of subCondition name for a given condition */
export const checkForDuplicateSubConditionName = (
    subConditionList: any[],
    currentSubConditionName: string
) => {
    const hasDuplicateName = subConditionList.some((item) => {
        if (item.name === currentSubConditionName) {
            return true;
        } else {
            return false;
        }
    });
    return hasDuplicateName;
};

/** check for error property in conditions */
export const hasErrorInConditionsData = (conditionFunctionType: any[]) => {
    const visitedConditions: string[] = [];
    const hasError = conditionFunctionType.some((conditionItem) => {
        const conditionName = Object.keys(conditionItem).filter(
            (key) => key !== 'error' && key !== 'deleteFlag'
        )[0];
        console.log('condition', conditionItem);
        const isConditionPresent = visitedConditions.includes(conditionName);
        if (isConditionPresent) {
            return true;
        } else {
            if (conditionName === '') {
                return true;
            } else if (conditionItem.error) {
                return true;
            } else {
                visitedConditions.push(conditionName);
            }
        }
        const visitedSubConditions: string[] = [];
        const subConditionList: any[] = conditionItem[conditionName];
        const hasErrorInSubConditions = subConditionList.some((subConditionItem) => {
            const isPresent = visitedSubConditions.includes(subConditionItem.name);
            if (isPresent) {
                return true;
            } else {
                if (subConditionItem.name === '') {
                    return true;
                } else if (subConditionItem.error) {
                    return true;
                } else if (subConditionItem.severityError) {
                    return true;
                } else {
                    visitedSubConditions.push(subConditionItem.name);
                    return false;
                }
            }
        });
        return hasErrorInSubConditions;
    });
    return hasError;
};

export const getUniqueName = (nameListObj: object, prefix: string, currentIndex: number) => {
    let newIndex = currentIndex;
    let itemName = prefix + newIndex;

    if (nameListObj[itemName]) {
        newIndex++;
        itemName = getUniqueName(nameListObj, prefix, newIndex);
    }
    return itemName;
};

export const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const checkForExistingAliasName = (value: string, functionType: FunctionTypeDetail) => {
    let isAliasExist = false;
    Object.values(functionType.alias).forEach((aliasObj) => {
        if (aliasObj.value === value) {
            isAliasExist = true;
        }
    });
    functionType.inhibits.forEach((inhibitObj) => {
        if (inhibitObj.name === value) {
            isAliasExist = true;
        }
    });
    functionType.inputs.forEach((inputObj) => {
        if (inputObj.name === value) {
            isAliasExist = true;
        }
    });
    functionType.outputs.forEach((outputObj) => {
        if (outputObj.name === value) {
            isAliasExist = true;
        }
    });
    return isAliasExist;
};
export const isHttpExists = (endpoint: string) => {
    let endPointHttp: string = endpoint;
    const isHttpExists: boolean = endpoint.startsWith("http");
    if (!isHttpExists) {
        endPointHttp = "http://" + endpoint;
    }
    return endPointHttp;
}

export const isGlobalTenantType = (tags: string[], typeId ?: string) => {
    let isGlobal = false;
    let isTenantIdTagPresent = false;

    tags.forEach((tag) => {
        let splitArr = tag.split('=');
        if (splitArr[0] === TENANT_ID_KEY){
            isTenantIdTagPresent = true;
            if(splitArr[1] === GLOBAL_TENANT_ID){
                isGlobal = true;
            }
        }
    });

    
    if((typeId && !typeId.startsWith(LOCAL_TYPE_ID_PREFIX)) || !isTenantIdTagPresent){
        isGlobal = true;
    }

    if(typeId && typeId.startsWith(LOCAL_TYPE_ID_PREFIX)){
        isGlobal = false;
    }

    return isGlobal;
}
