import React, { Fragment, useState, useCallback, useEffect } from "react";
import TR from "../../../../../Table/TableRow";
import { Collapse } from "reactstrap";
import { IoTableTabsProps } from "../../../..";
import { FunctionTypeDetailsWithMappings } from "../../../../../../model/TableController/types";
import Td from "../../../../../Table/TableData";
import Condition from "../../../../../shared/Condition";
import _ from "lodash";
import Checkbox from "../../../../../CustomCheckbox";
import FunctionTypeDetail from "../../../../../../transformers/AssetType/FunctionType";
import { Icon, Input } from "@abb/abb-common-ux-react";
import {
    getOriginalFunctionTriggerForInstance,
    getOriginalSelectedFuncDetail,
} from "../../../../../../utils/helpers";
import { SEVERITY_PIN } from "../../../../../../routes/Functions/constants";
import {
    FUNCTION_SEVERITY_PIN,
    ROUTE_PATHNAME,
} from "../../../../../../utils/constants/appConstants";
import { StoreState } from "../../../../../../store";
import { Dispatch } from "redux";
import {
    markComputeModelDirty,
    updateTimeTriggerForFunction,
} from "../../../../../../store/configurationTool/actions";
import { connect } from "react-redux";
import CustomABBInput from "../../../../../CustomABBInput";

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
}

export const ExpandableTimeTriggerRows = (
    props: ExpandTableRowProps &
        IoTableTabsProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const [isExpanded, updateIsExpanded] = useState(false);
    const [timeTrigger, setTimeTrigger] = useState(
        props.asset.timeTrigger || ""
    );
    const [isTimeTriggerDisabled, setIsTimeTriggerDisabled] = useState(false);

    const toggleExpand = () => {
        updateIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
            const triggers = getOriginalFunctionTriggerForInstance({
                nodeId: props.asset.nodeId,
                originalFunctionTypeDetail: props.originalTypeData,
            });

            if (
                !(triggers && triggers.time) ||
                props.asset.nodeId === props.overallSeverityFunctionId ||
                !props.isEditMode
            ) {
                setIsTimeTriggerDisabled(true);
            } else {
                setIsTimeTriggerDisabled(false);
            }
        } else if (
            props.asset.nodeId === props.overallSeverityFunctionId ||
            !props.isEditMode
        ) {
            setIsTimeTriggerDisabled(true);
        } else {
            setIsTimeTriggerDisabled(false);
        }
    }, [props.isEditMode, props.originalTypeData]);

    const handleTimeTriggerChange = (value: string) => {
        setTimeTrigger(value);
    };

    return (
        <Fragment>
            <div className="title-collapse-handle" onClick={toggleExpand}>
                {isExpanded ? (
                    <Icon
                        name="abb/minus"
                        sizeClass="small"
                        className="toggle-icon"
                    />
                ) : (
                    <Icon
                        name="abb/plus"
                        sizeClass="small"
                        className="toggle-icon"
                    />
                )}
                <h2>
                    <div className="view-mode-header">{props.asset.nodeId}</div>
                </h2>
            </div>
            <Collapse isOpen={isExpanded} timeout={{ enter: 0, exit: 0.3 }}>
                <TR id={`${props.asset.nodeId}`}>
                    <Fragment>
                        <Td>{props.asset.nodeId}</Td>
                        <Td className="w-22-column">{props.asset.assetName}</Td>
                        <Fragment>
                            <Td>
                                <CustomABBInput
                                    value={timeTrigger}
                                    dataType={"number"}
                                    placeholder={"Enter Time in Seconds"}
                                    disabled={isTimeTriggerDisabled}
                                    onValueChange={handleTimeTriggerChange}
                                    onLostFocus={() => {
                                        console.log(
                                            "LOST_FOCUS",
                                            props.asset.timeTrigger,
                                            timeTrigger
                                        );
                                        if (
                                            props.asset.timeTrigger !==
                                            timeTrigger
                                        ) {
                                            props.updateTimeTriggerForFunction({
                                                nodeId: props.asset.nodeId,
                                                timeTrigger:
                                                    parseInt(timeTrigger),
                                            });
                                            props.markComputeModelDirty();
                                        }
                                    }}
                                />
                            </Td>
                        </Fragment>
                    </Fragment>
                </TR>
            </Collapse>
        </Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        overallSeverityFunctionId:
            state.configurationTool.overallSeverityFunctionId,
        originalTypeData: state.instanceConfig.originalTypeData,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        markComputeModelDirty: () => dispatch(markComputeModelDirty()),
        updateTimeTriggerForFunction: (payload: {
            nodeId: string;
            timeTrigger: number;
        }) => dispatch(updateTimeTriggerForFunction(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpandableTimeTriggerRows);
