import React, { Fragment, useState, useCallback, useEffect } from "react";
import TR from "../../../../Table/TableRow";
import { Collapse } from "reactstrap";
import { IoTableTabsProps } from "../../..";
import { FunctionTypeDetailsWithMappings } from "../../../../../model/TableController/types";
import Td from "../../../../Table/TableData";
import Condition from "../../../../shared/Condition";
import _ from "lodash";
import Checkbox from "../../../../CustomCheckbox";
import FunctionTypeDetail from "../../../../../transformers/AssetType/FunctionType";
import { Icon, Input } from "@abb/abb-common-ux-react";
import {
    getOriginalSelectedFuncDetail,
    checkForExistingAliasName,
} from "../../../../../utils/helpers";
import { SEVERITY_PIN } from "../../../../../routes/Functions/constants";
import {
    FUNCTION_SEVERITY_PIN,
    ROUTE_PATHNAME,
} from "../../../../../utils/constants/appConstants";
import { validateForSwedishAndFinishCharacters } from "../../../../../routes/Functions/helper";
import CollapseTitle from "../../../../CollapseTitle";

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
}

const ExpandableOutputRows = (
    props: ExpandTableRowProps & IoTableTabsProps
) => {
    const [isExpanded, updateIsExpanded] = useState(false);

    const toggleExpand = () => {
        updateIsExpanded(!isExpanded);
    };

    const rows = props.asset.outputs;

    const rowCount = rows.length;

    const rowArray = isExpanded ? rows : [rows[0]];
    return (
        <Fragment>
            {rowArray.length === 0 ? (
                <TR>Empty</TR>
            ) : (
                <>
                    <CollapseTitle
                        title={props.asset.nodeId}
                        isCollapse={isExpanded}
                        toggleCollapse={toggleExpand}
                    />
                    <Collapse
                        isOpen={isExpanded}
                        timeout={{ enter: 0, exit: 0.3 }}
                    >
                        {rowArray.map((output, index) => {
                            return (
                                <OutputRow
                                    key={output.id}
                                    {...props}
                                    isExpanded={isExpanded}
                                    rowCount={rowCount}
                                    toggleExpand={toggleExpand}
                                    output={output}
                                    index={index}
                                    asset={props.asset}
                                    conditions={props.asset.conditions}
                                />
                            );
                        })}
                    </Collapse>
                </>
            )}
        </Fragment>
    );
};

export const OutputRow = (
    options: {
        index: number;
        rowCount: number;
        isExpanded: boolean;
        toggleExpand: () => void;
        output: FunctionTypeDetailsWithMappings["outputs"][0];
        asset: FunctionTypeDetailsWithMappings;
        conditions: FunctionTypeDetail["conditions"];
    } & IoTableTabsProps
) => {
    const {
        index,
        rowCount,
        isExpanded,
        toggleExpand,
        output,
        asset,
        inViewMode,
        conditions,
        activeModelJson,
    } = options;

    const [aliasName, setAliasName] = useState(
        asset.alias[output.name] ? asset.alias[output.name].value : options.output.name
    );
    const [aliasNameError, setAliasNameError] = useState("");

    const handleCheckboxClick = useCallback(() => {
        if (
            conditions &&
            asset.conditionsConfigurationVariables &&
            output.name === FUNCTION_SEVERITY_PIN
        ) {
            const nodeId: string = asset.nodeId;
            const circleData = output;
            const originalSelectedFunction = getOriginalSelectedFuncDetail({
                nodeId,
                json: activeModelJson,
            });

            let isOriginalPublished = false;
            const originalSelectedPin =
                originalSelectedFunction &&
                originalSelectedFunction.outputs.find(
                    (item) => item.id === circleData.id
                );

            if (originalSelectedPin) {
                isOriginalPublished = originalSelectedPin.isPublished || false;
            }

            let originalConditionConfigVariables: string[] | undefined;
            if (originalSelectedFunction) {
                originalConditionConfigVariables =
                    originalSelectedFunction.conditionsConfigurationVariables;
            }

            const data = {
                functionConditionDetails: conditions,
                customClassName: "wrapper-message-modal",
                selectedConditionConfigurationVariables:
                    asset.conditionsConfigurationVariables,
                isPublished: output.isPublished,
                isOriginalPublished,
                originalConditionConfigVariables,
                primaryButtonAction: (selectedProperties: string[]) => {
                    asset.conditionsConfigurationVariables = selectedProperties;
                    if (selectedProperties.length && !output.isPublished) {
                        options.publishOutputConfigurationTool({
                            circleData: output,
                            asset,
                        });
                    } else if (
                        output.isPublished &&
                        selectedProperties.length === 0
                    ) {
                        options.publishOutputConfigurationTool({
                            circleData: output,
                            asset,
                        });
                    }
                    options.markComputeModelDirty();
                },
            };
            options.showConditionModal(data);
        } else {
            options.publishOutputConfigurationTool({
                circleData: output,
                asset,
            });
        }
    }, [asset, conditions, output]);

    const handleAliasNameChange = useCallback(
        (value: string) => {
            setAliasName(value.trim());
            // if (
            //     value.length > 0 &&
            //     !validateForSwedishAndFinishCharacters(value)
            // ) {
            //     setAliasNameError(`Special Characters are not allowed`);
            // } else 
            if (checkForExistingAliasName(value, asset) && value !== options.output.name) {
                setAliasNameError(
                    `${value} is already used in ${asset.nodeId}`
                );
            } else if (value === SEVERITY_PIN) {
                setAliasNameError(`${value} cannot be used as alias name`);
            } else {
                setAliasNameError("");
            }
        },
        [aliasName]
    );

    const handleAliasFocusOut = useCallback(
        (event: any) => {
            event.preventDefault();
            if(event.target.value.trim() === '') {
                setAliasName(options.output.name)
            }
            options.changeAliasNameForModels({
                functionType: asset,
                ioName: output.name,
                aliasName,
            });
        },
        [aliasName]
    );

    return (
        <TR id={`${asset.nodeId}_${output.name}`}>
            {/* <Td tdBorderTop={index !== 0 ? 'remove-inner-row-border-top' : ''}>
                <Condition when={index === 0 && rowCount > 1}>
                    <Icon name={isExpanded ? 'abb/minus' : 'abb/plus'} onClick={toggleExpand} />
                </Condition>
            </Td> */}
            <Td
                tdBorderTop={
                    index !== rowCount - 1
                        ? "remove-inner-row-border-bottom"
                        : ""
                }
            >
                {index === 0 ? asset.nodeId : ""}
            </Td>
            <Td
                tdBorderTop={
                    index !== rowCount - 1
                        ? "remove-inner-row-border-bottom"
                        : ""
                }
            >
                {index === 0 ? asset.assetName : ""}
            </Td>
            <Td> {output.name}</Td>
            <Td
                tdBorderTop={index !== 0 ? "remove-inner-row-border-top" : ""}
                className={"alias-column"}
            >
                <Input
                    id={`${output.name}_alias`}
                    value={aliasName}
                    dataType="text"
                    style={{
                        minWidth: "30px",
                    }}
                    disabled={
                        !options.isEditMode ||
                        asset.nodeId === options.overallSeverityFunctionId ||
                        output.name === SEVERITY_PIN ||
                        (asset.calculations && asset.calculations[output.name])
                    }
                    onValueChange={(value) => handleAliasNameChange(value)}
                    onLostFocus={handleAliasFocusOut}
                    validator={() =>
                        aliasNameError !== ""
                            ? {
                                  valid: false,
                                  text: aliasNameError,
                              }
                            : { valid: true, text: "OK!" }
                    }
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                />
                {asset.alias[output.name] &&
                asset.alias[output.name].isDirty ? (
                    <div className="changedBorder"></div>
                ) : (
                    <></>
                )}
            </Td>
            <Td> {output.dataType}</Td>
            <Td>
                <Checkbox
                    checked={output.isPublished}
                    isDisable={inViewMode}
                    onClick={handleCheckboxClick}
                />
            </Td>
        </TR>
    );
};

export default ExpandableOutputRows;
