import { isNilOrEmpty } from './../../../utils/helpers';
import _ from 'lodash';
import { ComputeModelToJson } from '../toJson/ComputeModelToJson';

import { AssetsType, IODataTypes, ALARM_TYPE } from '../../../components/Fabric/types';
import { outputFinder, pathFinder } from '../../utils/outputFinder';
import {
    ObjectTypes,
    FunctionTypes,
    LocalJson,
    PINS_TYPE,
    PinsType,
    ReferenceTypeValueItem,
} from './types';
import FunctionTypeDetail from '../../AssetType/FunctionType';
import ObjectTypeDetail from '../../AssetType/ObjectType';
import AlarmTypeDetail from '../../AssetType/AlarmType';
import EventTypeDetail from '../../AssetType/EventType';

// TODO: Add api field validation
export class ComputeModelFromJson {
    json: LocalJson = ({
        assetData: [],
        connectionData: [],
    } = { assetData: [], connectionData: [] });
    objectId: string = '';
    version: string = '';
    modelDetails: {
        name: string;
        description: string;
        model: string;
        typeId: string;
        version: string;
        tags: string[];
    } = {
        name: '',
        description: '',
        model: '',
        typeId: '',
        version: '',
        tags: [],
    };

    associatedObject: {
        model: string;
        typeId: string;
        version: string;
    } = { model: '', typeId: '', version: ' ' };

    saveOption?: string;
    isOverAllSeverityFunctionPresent?: boolean = false;
    overAllSeverityFunctionId?: string = '';
    referencedTypes?: ReferenceTypeValueItem[];
    constructor(json?: ComputeModelToJson) {
        if (json) {
            this.version = json.version;

            this.saveOption = json.properties.saveOption ? json.properties.saveOption.value : '';

            this.associatedObject = {
                version: json.properties.associatedObjectType.version.value,
                model: json.properties.associatedObjectType.model.value,
                typeId: json.properties.associatedObjectType.typeId.value,
            };

            this.objectId = json.objectId;

            this.modelDetails = {
                description: json.properties.model.description.value,
                model: json.properties.model.model.value,
                name: json.properties.model.name.value,
                tags: json.properties.model.tags.value,
                version: json.properties.model.version.value,
                typeId: json.properties.model.typeId.value,
            };
            // console.log("modelDetails", this.modelDetails);
            this.referencedTypes = [];

            const objectTypeKeys = Object.keys(json.properties.objectTypes);
            const objectInstanceTypeKeys = Object.keys(json.properties.objectInstances || {});

            // Referenced Type keys from json.
            const referencedTypeKeys = Object.keys(json.properties.types || {});

            objectTypeKeys.forEach((key) => {
                const asset = getDataFromObject(json.properties.objectTypes[key], key);

                this.json.assetData.push(asset);
            });

            referencedTypeKeys.forEach((key) => {
                if (json.properties.types && json.properties.types[key]) {
                    const currentRefTypeAsset = json.properties.types[key];
                    const referencedTypeData = json.properties.types[key].referencedTypes;
                    const asset = getDataFromObject(json.properties.types[key], key);
                    asset.isReferencedType = true;
                    asset.referencedTypes = referencedTypeData ? [...referencedTypeData.value] : [];
                    asset.objectId = currentRefTypeAsset.objectId!.value;
                    const referenceTypesObject =
                        (this.referencedTypes &&
                            this.referencedTypes.reduce(
                                (obj, item) =>
                                    Object.assign(obj, {
                                        [item.modelId]: item.typeId,
                                    }),
                                {}
                            )) ||
                        {};
                    referencedTypeData &&
                        referencedTypeData.value.forEach((item) => {
                            if (!referenceTypesObject[item.modelId]) {
                                this.referencedTypes && this.referencedTypes.push(item);
                            }
                        });
                    this.referencedTypes &&
                        this.referencedTypes.push({
                            modelId: currentRefTypeAsset.objectTypeModel.value,
                            typeId: currentRefTypeAsset.objectType.value,
                            version: currentRefTypeAsset.version.value,
                            objectId: currentRefTypeAsset.objectId!.value,
                        });

                    this.json.assetData.push(asset);
                }
            });

            objectInstanceTypeKeys.forEach((key) => {
                if (json.properties.objectInstances && json.properties.objectInstances[key]) {
                    const paths = pathFinder(json.properties.objectInstances[key], 'variables');
                    console.log('instance path', paths);
                    //@ts-ignore
                    const obj = json.properties.objectInstances[key];
                    const outputs: any[] = [];

                    paths.forEach((path) => {
                        path = path.slice(1);

                        if (
                            //@ts-ignore
                            json.properties.objectInstances[key][path].variables
                        ) {
                            outputs.push(
                                ...outputFinder(
                                    //@ts-ignore
                                    json.properties.objectInstances[key][path].variables
                                )
                            );
                        }
                    });
                    console.log(outputs);

                    const asset = new ObjectTypeDetail({
                        description: '',
                        name: obj.name.value,
                        outputs,
                        position: {
                            x: obj.xcoordinate.value,
                            y: obj.ycoordinate.value,
                        },
                        typeId: obj.objectType ? obj.objectType.value : '',
                        scale: obj.scale.value,
                        version: obj.version ? obj.version.value : '',
                        nodeId: key,
                        creatingFromJson: true,
                    });

                    asset.objectInstance = true;
                    this.json.assetData.push(asset);
                }
            });

            let functionTypeKeys = Object.keys(json.properties.functions);
            const alarms = json.properties.alarms;
            functionTypeKeys.forEach((key) => {
                const ref = json.properties.functions[key];
                const assetRef = ref.functionType.value;
                const assetType = AssetsType.FUNCTION;
                const nodeId = key;
                const position = {
                    x: ref.xcoordinate.value,
                    y: ref.ycoordinate.value,
                };
                const description = '';
                const name = ref.name.value;
                const scale = ref.scale.value;
                const version = ref.version.value;
                const alarmInputs = ref.alarmInputs;
                const eventInputs = ref.eventInputs;
                const alias = ref.alias;
                const inputs = removeAlarmEventInputsFromFunctionInputs(ref.inputs, {
                    ...ref.alarmInputs,
                    ...ref.eventInputs,
                });
                const outputs = ref.outputs;
                const inhibits = ref.inhibit;
                const settings = { endpoint: ref.endpoint };

                const timeTrigger = ref.triggers && ref.triggers.time && ref.triggers.time.value;
                const variableChangeTrigger = ref.triggers && ref.triggers.variableChange;

                const alarmTrigger = ref.triggers && ref.triggers.alarm;
                const eventTrigger = ref.triggers && ref.triggers.event;

                const asset = new FunctionTypeDetail({
                    creatingFromJson: true,
                    nodeId,
                    position,
                    description,
                    name,
                    scale,
                    typeId: assetRef,
                    version,

                    properties: {
                        alarms,
                        alarmInputs,
                        eventInputs,
                        inputs,
                        outputs,
                        settings,
                        inhibits,
                        timeTrigger,
                        variableChangeTrigger,
                        alarmTrigger,
                        eventTrigger,
                        alias
                    },
                });

                // To check if overAllSeverityFunctionPresent.
                Object.keys(ref.outputs).forEach((item) => {
                    if (ref.outputs[item].link === '#/variables/severity') {
                        this.isOverAllSeverityFunctionPresent = true;
                        this.overAllSeverityFunctionId = key;
                    }
                });

                this.json.assetData.push(asset as FunctionTypeDetail);
            });
            const alarmTypes = json.properties.alarmTypes;
            if (alarmTypes) {
                let alarmTypeKeys = Object.keys(alarmTypes);
                alarmTypeKeys.forEach((key) => {
                    const ref = alarmTypes[key];
                    const assetRef = ref.alarmType.value;
                    const nodeId = key;
                    const position = {
                        x: ref.xcoordinate.value,
                        y: ref.ycoordinate.value,
                    };
                    const model = ref.alarmTypeModel.value;
                    const description = '';
                    const name = ref.name.value;
                    const scale = ref.scale.value;
                    const version = ref.version.value;
                    const inputs = ref.inputs;
                    const outputs = ref.outputs;
                    const isSpecificAlarmType = Object.keys(ref.inputs).some((item) => {
                        if (ref.inputs[item].link) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    const asset = new AlarmTypeDetail({
                        creatingFromJson: true,
                        nodeId,
                        position,
                        description,
                        name,
                        scale,
                        typeId: assetRef,
                        version,
                        modelId: model,
                        type: isSpecificAlarmType ? ALARM_TYPE.SPECIFIC : ALARM_TYPE.GENERIC,

                        properties: {
                            inputs,
                            outputs,
                        },
                    });
                    this.json.assetData.push(asset);
                    Object.keys({ ...ref.inputs }).forEach((inputKey, index) => {
                        const inputRef = ref.inputs[inputKey];
                        if (inputRef.link) {
                            let path: any = inputRef.link;

                            // for checking input connections.
                            const { asset: inputAsset, io: inputIo } = getAssetMatchingParams({
                                nodeId: key,
                                ioName: inputKey,
                                json: this.json,
                                pinsType: PINS_TYPE.INPUT_INHIBIT,
                            });

                            try {
                                path = JSON.parse(path);
                            } catch {}
                            if (!Array.isArray(path)) {
                                path = [path];
                            }
                            path.forEach((str: string) => {
                                const { nodeId, outputName: ioName } = getIDSFromPath(str);

                                // for checking output connections.
                                const { asset: outputAsset, io: outputIo } = getAssetMatchingParams(
                                    {
                                        nodeId,
                                        ioName,
                                        json: this.json,
                                        pinsType: PINS_TYPE.OUTPUT,
                                        pinId: str,
                                    }
                                );

                                inputIo.connected = true;
                                outputIo.connected = true;

                                // check for empty link to update trigger value.
                                const variableChangeTriggerData =
                                    ref.triggers &&
                                    _.get(ref, `triggers.variableChange.${inputKey}`);
                                const isTriggered = !!(
                                    variableChangeTriggerData &&
                                    variableChangeTriggerData.link !== ''
                                );
                                const alarmTriggerData =
                                    ref.triggers && _.get(ref, `triggers.alarm.${inputKey}`);
                                const isAlarmTriggered =
                                    alarmTriggerData && alarmTriggerData.link !== '';

                                const eventTriggerData =
                                    ref.triggers && _.get(ref, `triggers.event.${inputKey}`);
                                const isEventTriggered =
                                    eventTriggerData && eventTriggerData.link !== '';

                                this.json.connectionData.push({
                                    input: {
                                        asset: inputAsset as FunctionTypeDetail,
                                        circleData: inputIo,
                                    },
                                    output: {
                                        asset: outputAsset as any,
                                        circleData: outputIo,
                                    },
                                    trigger: isTriggered || isAlarmTriggered || isEventTriggered,
                                });
                            });
                        }
                    });
                });
            }
            const eventTypes = json.properties.eventTypes;
            if (eventTypes) {
                let eventTypeKeys = Object.keys(eventTypes);
                eventTypeKeys.forEach((key) => {
                    const ref = eventTypes[key];
                    const assetRef = ref.eventType.value;
                    const nodeId = key;
                    const model = ref.eventTypeModel.value;
                    const position = {
                        x: ref.xcoordinate.value,
                        y: ref.ycoordinate.value,
                    };
                    const description = '';
                    const name = ref.name.value;
                    const scale = ref.scale.value;
                    const version = ref.version.value;
                    const inputs = ref.inputs;
                    const outputs = ref.outputs;
                    const isSpecificEventType = Object.keys(ref.inputs).some((item) => {
                        if (ref.inputs[item].link) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    const asset = new EventTypeDetail({
                        creatingFromJson: true,
                        nodeId,
                        position,
                        description,
                        name,
                        scale,
                        typeId: assetRef,
                        version,
                        modelId: model,
                        type: isSpecificEventType ? ALARM_TYPE.SPECIFIC : ALARM_TYPE.GENERIC,
                        properties: {
                            inputs,
                            outputs,
                        },
                    });
                    this.json.assetData.push(asset);
                });
            }

            // building connections
            //function type connection.
            functionTypeKeys.forEach((key) => {
                const ref = json.properties.functions[key];
                const functionInputs = removeAlarmEventInputsFromFunctionInputs(
                    { ...ref.inputs, ...ref.inhibit },
                    { ...ref.alarmInputs, ...ref.eventInputs }
                );
                Object.keys({
                    ...functionInputs,
                    ...ref.inhibit,
                    ...ref.alarmInputs,
                    ...ref.eventInputs,
                }).forEach((inputKey, index) => {
                    if (inputKey !== 'conditions') {
                        const inputRef =
                            //@ts-ignore
                            functionInputs[inputKey] ||
                            ref.alarmInputs[inputKey] ||
                            ref.eventInputs[inputKey] ||
                            //@ts-ignore
                            ref.inhibit[inputKey];

                        if (inputRef.link) {
                            let path: any = inputRef.link;

                            // for checking input connections.
                            const { asset: inputAsset, io: inputIo } = getAssetMatchingParams({
                                nodeId: key,
                                ioName: inputKey,
                                json: this.json,
                                pinsType: PINS_TYPE.INPUT_INHIBIT,
                            });

                            try {
                                path = JSON.parse(path);
                            } catch {}
                            if (!Array.isArray(path)) {
                                path = [path];
                            }
                            path.forEach((str: string) => {
                                const { nodeId, outputName: ioName } = getIDSFromPath(str);

                                // for checking output connections.
                                const { asset: outputAsset, io: outputIo } = getAssetMatchingParams(
                                    {
                                        nodeId,
                                        ioName,
                                        json: this.json,
                                        pinsType: PINS_TYPE.OUTPUT,
                                        pinId: str,
                                    }
                                );

                                inputIo.connected = true;
                                outputIo.connected = true;

                                // check for empty link to update trigger value.
                                const variableChangeTriggerData =
                                    ref.triggers &&
                                    _.get(ref, `triggers.variableChange.${inputKey}`);
                                const isTriggered = !!(
                                    variableChangeTriggerData &&
                                    variableChangeTriggerData.link !== ''
                                );
                                const alarmTriggerData =
                                    ref.triggers && _.get(ref, `triggers.alarm.${inputKey}`);
                                const isAlarmTriggered =
                                    alarmTriggerData && alarmTriggerData.link !== '';

                                const eventTriggerData =
                                    ref.triggers && _.get(ref, `triggers.event.${inputKey}`);
                                const isEventTriggered =
                                    eventTriggerData && eventTriggerData.link !== '';

                                this.json.connectionData.push({
                                    input: {
                                        asset: inputAsset as FunctionTypeDetail,
                                        circleData: inputIo,
                                    },
                                    output: {
                                        asset: outputAsset as any,
                                        circleData: outputIo,
                                    },
                                    trigger: isTriggered || isAlarmTriggered || isEventTriggered,
                                });
                            });
                        }
                    }
                });
            });
        }

        if (!this.saveOption) {
            delete this.saveOption;
        }
    }
}

function getAssetMatchingParams(options: {
    nodeId: string;
    ioName: string;
    json: ComputeModelFromJson['json'];
    pinsType: PinsType;
    pinId?: string;
}) {
    const { nodeId, ioName, json, pinsType, pinId } = options;

    let asset, io;
    asset = json.assetData.find((asset) => {
        if (asset.nodeId === nodeId) {
            let inOutput;
            let inInput;
            let inInhibit;
            let inAlarmInput;
            let inEventInput;

            if (pinsType === PINS_TYPE.OUTPUT) {
                inOutput = asset.outputs.find((element) => {
                    if (element.name === ioName && element.id === pinId!) {
                        io = element;
                        return true;
                    }
                    return false;
                });
            }

            if (pinsType === PINS_TYPE.INPUT_INHIBIT) {
                if (
                    asset instanceof FunctionTypeDetail ||
                    asset instanceof AlarmTypeDetail ||
                    asset instanceof EventTypeDetail
                ) {
                    inInput = asset.inputs.find((element) => {
                        if (element.name === ioName) {
                            io = element;
                            return true;
                        }
                        return false;
                    });
                }

                if (!inInput && asset instanceof FunctionTypeDetail) {
                    inInhibit = asset.inhibits.find((inhibit) => {
                        if (inhibit.name === ioName) {
                            io = inhibit;
                            return true;
                        }
                        return false;
                    });
                }

                if (!inInput && !inInhibit && asset instanceof FunctionTypeDetail) {
                    inAlarmInput = asset.alarmInputs.find((alarmInput) => {
                        if (alarmInput.name === ioName) {
                            io = alarmInput;
                            return true;
                        }
                        return false;
                    });
                    if (!inAlarmInput) {
                        inEventInput = asset.eventInputs.find((eventInput) => {
                            if (eventInput.name === ioName) {
                                io = eventInput;
                                return true;
                            }
                            return false;
                        });
                    }
                }
            }

            return inOutput || inInput || inInhibit || inAlarmInput || inEventInput;
        }
        return false;
    });

    return { asset, io } as {
        asset: ObjectTypeDetail | FunctionTypeDetail;
        io: any;
    };
}

function getIDSFromPath(path: string, stringsToReplace?: string[]) {
    path = path.replace('*', '');
    path = path.replace('#/properties/functions/', '');
    path = path.replace('#/properties/alarmTypes/', '');
    path = path.replace('#/properties/eventTypes/', '');
    path = path.replace('#abb.controlSystem.800xA.aspectObject', '');
    const splitedPath = path.split('/');

    // previously nodeId = splittedPath[0].
    const currentNodeId = splitedPath[0].split('#')[0];
    return {
        nodeId: currentNodeId,
        outputName: splitedPath[splitedPath.length - 1],
    };
}

function getDataFromObject(asset: ObjectTypes['key'], nodeId: string) {
    const outputs = outputFinder(asset.variables);

    return new ObjectTypeDetail({
        description: '',
        name: asset.name.value,
        outputs,
        position: { x: asset.xcoordinate.value, y: asset.ycoordinate.value },
        typeId: asset.objectType.value,
        scale: asset.scale.value,
        version: asset.version.value,
        nodeId,
        creatingFromJson: true,
        assetType: asset.objectTypeModel.value,
    });
}

export function CreateComputeModelFromJson(json: ComputeModelToJson) {
    try {
        return new ComputeModelFromJson(json);
    } catch {
        return null;
    }
}

// TODO types
export function getAssetDataObjectForTable(assetObj: any) {
    let res = _.cloneDeep(assetObj);
    const addAssetProperty = (arr: FunctionTypeDetail[]) => {
        if (isNilOrEmpty(arr)) {
            return [];
        }
        return arr.map((ioObj: FunctionTypeDetail) => {
            return {
                ...ioObj,
                nodeId: assetObj.nodeId,
                assetRef: assetObj.assetRef,
                assetType: assetObj.assetType,
            };
        });
    };
    res.inputs = addAssetProperty(assetObj.inputs);
    res.outputs = addAssetProperty(assetObj.outputs);
    return res;
}

export function removeAlarmEventInputsFromFunctionInputs(
    inputs: FunctionTypes['key']['inputs'],
    alarmEventInputs: FunctionTypes['key']['alarmInputs'] | FunctionTypes['key']['eventInputs']
) {
    let finalInputs: typeof inputs = {};
    Object.keys(inputs).forEach((inputName) => {
        if (!alarmEventInputs[inputName]) {
            finalInputs[inputName] = { ...inputs[inputName] };
        }
    });
    return finalInputs;
}
//@ts-ignore
window.ComputeModelFromJson = ComputeModelFromJson;
