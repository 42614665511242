import { ActionTypes } from '../actionTypes';
import { AssetType } from '../../transformers/AssetType';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { SAVE_OPTIONS } from '../../utils/constants/appConstants';

import { UpdateActiveModelType } from '../../routes/Home/CreateModel/Form/types';
import {
    ModelUsedInstanceType,
    InstancePaginationType,
    IModelPermission,
    IInfoModelInstance,
    ModelSaveType,
    ModelsPageState,
} from './types';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import { OriginalFunctionTypes, IConfigurationToolMode } from '../configurationTool/types';

import { ComputeModelToJson } from '../../transformers/ComputeModel/toJson/ComputeModelToJson';

export function getAssetsList() {
    return <const>{
        type: ActionTypes.FETCH_ASSETS_LIST,
    };
}

interface UpdateAssetListPayload {
    functionTypeList: AssetType[];
    objectTypeList: AssetType[];
}
export function updateAssetList(payload: UpdateAssetListPayload): {
    type: typeof ActionTypes.UPDATE_ASSETS_LIST;
    payload: UpdateAssetListPayload;
} {
    return <const>{
        type: ActionTypes.UPDATE_ASSETS_LIST,
        payload,
    };
}

export interface AddNewModelComputeModelPayload {
    modelDetails: ComputeModelFromJson['modelDetails'];
    associatedObject: AssetType;
}
export function addNewComputeModel(payload: AddNewModelComputeModelPayload): {
    type: typeof ActionTypes.ADD_NEW_COMPUTE_MODEL;
    payload: AddNewModelComputeModelPayload;
} {
    return <const>{
        type: ActionTypes.ADD_NEW_COMPUTE_MODEL,
        payload,
    };
}

export function addSaveAsComputeModel(payload: AddNewModelComputeModelPayload) {
    return {
        type: ActionTypes.ADD_SAVE_AS_COMPUTE_MODEL,
        payload,
    };
}

export function changeActiveModel(payload: { modelId: string }): {
    type: typeof ActionTypes.CHANGE_ACTIVE_MODEL;
    payload: { modelId: string };
} {
    return <const>{
        type: ActionTypes.CHANGE_ACTIVE_MODEL,
        payload,
    };
}

export function cancelUnsavedModelChanges() {
    return <const>{
        type: ActionTypes.CANCEL_UNSAVED_MODEL_CHANGES,
    };
}

export function saveComputeModelApi(payload: {
    saveOption: SAVE_OPTIONS;
    isLatestFunctionsUpdating?: boolean;
}) {
    return <const>{
        type: ActionTypes.SAVE_COMPUTE_MODEL_API,
        payload,
    };
}

export function saveComputeModelApiError() {
    return {
        type: ActionTypes.SAVE_COMPUTE_MODEL_API_ERROR,
    };
}

export function saveComputeModelStore(payload: { computeModel: ComputeModelFromJson }) {
    return <const>{
        type: ActionTypes.SAVE_COMPUTE_MODEL_STORE,
        payload,
    };
}

export function getComputeModelsApi(payload: {
    objectRef: string;
    version: string;
    asset: AssetType;
    currentRoutePath: string;
}) {
    return <const>{
        type: ActionTypes.GET_COMPUTE_MODELS_API,
        payload,
    };
}

export function getComputeModelsStore(payload: {
    computeModels: ComputeModelFromJson[];
    // filteredComputeModels: ModelsPageState["filteredComputeModels"];
    activeAsset: AssetType;
    activeModelId: string;
    currentRoutePath?: string;
    addIsDirtyToConditions: boolean;
}) {
    return <const>{
        type: ActionTypes.GET_COMPUTE_MODELS_STORE,
        payload,
    };
}

export function deleteComputeModelApi() {
    return <const>{
        type: ActionTypes.DELETE_COMPUTE_MODEL_API,
    };
}

export function deleteComputeModelStore(payload: {
    newModelId: string;
    computeModels: ModelsPageState['computeModels'];
}) {
    return <const>{
        type: ActionTypes.DELETE_COMPUTE_MODEL_STORE,
        payload,
    };
}

export function deleteComputeModelApiError() {
    return {
        type: ActionTypes.DELETE_COMPUTE_MODEL_API_ERROR,
    };
}

export const objectListSearch = (payload: { search: string }) => {
    return <const>{
        type: ActionTypes.OBJECT_LIST_SEARCH,
        payload,
    };
};

export const functionListSearch = (payload: { search: string }) => {
    return <const>{
        type: ActionTypes.FUNCTION_LIST_SEARCH,
        payload,
    };
};

export const updateActiveModel = (dataPayload: UpdateActiveModelType) => {
    return <const>{
        type: ActionTypes.UPDATE_ACTIVE_MODEL_DATA,
        payload: dataPayload,
    };
};

export const updateActiveModelDetailsForImport = (payload: UpdateActiveModelType) => {
    return <const>{
        type: ActionTypes.UPDATE_ACTIVE_MODEL_DETAILS_FOR_IMPORT,
        payload,
    };
};
export const updateSupportedModels = (
    payload: {
        key: string;
        value: any;
    }[]
) => {
    return <const>{
        type: ActionTypes.UPDATE_SUPPORTED_MODEL_DATA,
        payload,
    };
};

export const getModelUsedbyInstances = (payload: InstancePaginationType) => {
    return <const>{
        type: ActionTypes.GET_MODEL_USED_BY_INSTANCES,
        payload,
    };
};

export const updateModelUsedbyInstances = (payload: ModelUsedInstanceType[]) => {
    return <const>{
        type: ActionTypes.UPDATE_MODEL_USED_BY_INSTANCES,
        payload,
    };
};

export const handleSidebarToggle = (toggleStatus: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_SIDEBAR_TOGGLE,
        payload: toggleStatus,
    };
};

export const changePaginationDetails = (payload: {
    totalRecords: number;
    entriesPerPage: number;
    noOfPages: number;
}) => {
    return <const>{
        type: ActionTypes.CHANGE_PAGINATION_DETAILS,
        payload: payload,
    };
};

export const resetPaginationLoader = (payload: boolean) => {
    return <const>{
        type: ActionTypes.RESET_PAGINATION_LOADER,
        payload: payload,
    };
};

export function getAllPermisssionForModelAction() {
    return {
        type: ActionTypes.GET_ALL_PERMISSION_MODEL,
    };
}

export function updateModelPermission(payload: IModelPermission) {
    return {
        type: ActionTypes.UPDATE_MODEL_PERMISSION,
        payload,
    };
}
export const updateActiveParentKey = (key: string) => {
    return <const>{
        type: ActionTypes.UPDATE_ACTIVE_PARENT_KEY,
        payload: key,
    };
};

export const setActiveModalForImport = (val: any) => {
    return <const>{
        type: ActionTypes.SET_ACTIVE_MODAL_IMPORT,
        payload: val,
    };
};

// export const getInfoModelInstancesRequest = () => {
//     return <const>{
//         type: ActionTypes.GET_INFO_MODEL_INSTANCES,
//     };
// };

// export const updateInfoModelInstanceRequest = (
//     supportedModel: IInfoModelInstance,
//     identityModel: IInfoModelInstance[]
// ) => {
//     return <const>{
//         type: ActionTypes.UPDATE_INFO_MODEL_INSTANCE,
//         payload: {
//             supportedModel: supportedModel,
//             identityModel: identityModel,
//         },
//     };
// };

// export const saveInfoModelInstanceRequest = (val: IInfoModelInstance, type: string) => {
//     return <const>{
//         type: ActionTypes.SAVE_INFO_MODEL_INSTANCE,
//         payload: {
//             infoModel: val,
//             type: type,
//         },
//     };
// };

// export const deleteInfoModelInstanceRequest = (
//     objectId: string,
//     modelId: string
// ) => {
//     return <const>{
//         type: ActionTypes.DELETE_INFO_MODEL_INSTANCE,
//         payload: {
//             objectId: objectId,
//             modelId: modelId,
//         },
//     };
// };

// export const getTypeDefinitionRequest = () => {
//     return <const>{
//         type: ActionTypes.GET_TYPE_DEFINITION_REQUEST,
//     };
// };

// export const updateTypeDefinitionRequest = (val: IInfoModelInstance) => {
//     return <const>{
//         type: ActionTypes.UPDATE_NEW_DEFINITION_TYPE,
//         payload: val,
//     };
// };

export const showProgresRequest = (val: boolean) => {
    return <const>{
        type: ActionTypes.SHOW_PROGRESS,
        payload: val,
    };
};

export const handleLoadingUpdateTypeRelatedIntance = (isLoading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE,
        payload: isLoading,
    };
};

export const updateTypeJsonConditionDetails = (payload: {
    json: LocalJson;
    originalFunctionUsed: OriginalFunctionTypes;
}) => {
    return <const>{
        type: ActionTypes.UPDATE_TYPE_JSON_CONDITION_DETAILS,
        payload,
    };
};

export const updateOriginalComputeModels = (payload: ComputeModelToJson[]) => {
    return <const>{
        type: ActionTypes.UPDATE_ORIIGNAL_COMPUTE_MODELS,
        payload,
    };
};

export const getAvailableAssetTypeIds = () => {
    return {
        type: ActionTypes.GET_AVAILABLE_TYPE_IDS,
    };
};

export const updateAvailableTypeIds = (payload: string[]) => {
    return {
        type: ActionTypes.UPDATE_AVAILABLE_TYPE_IDS,
        payload,
    };
};

export function setAuthorize(payload: boolean) {
    return <const>{
        type: ActionTypes.SET_AUTHORIZE,
        payload,
    };
}

export function updateActiveModelJsonData(payload: { json: LocalJson }) {
    return <const>{
        type: ActionTypes.UPDATE_ACTIVEMODEL_JSON_DATA,
        payload,
    };
}

export function handleIsFunctionUpdatesAvailable(isUpdateAvailable: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_IS_FUNC_UPDATE_AVAILABLE,
        payload: isUpdateAvailable,
    };
}

export function handleUniqueTypeIdLoading(isLoading: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID_LOADING,
        payload: isLoading,
    };
}
export function handleUniqueTypeId(payload: {
    details: AddNewModelComputeModelPayload | UpdateActiveModelType;
    mode: IConfigurationToolMode;
}) {
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID,
        payload,
    };
}
export function handleUniqueTypeIdFailed(isExist: boolean) {
    console.log(isExist, 'trueee');
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID_ISEXIST,
        payload: isExist,
    };
}
export function handleUniqueTypeIdMessage(message: string) {
    console.log(message, 'messagess');
    return <const>{
        type: ActionTypes.HANDLE_UNIQUE_TYPEID_MESSAGE,
        payload: message,
    };
}

export function handleExistingTypeIdError(hasError: boolean) {
    return <const>{
        type: ActionTypes.HANDLE_EXISTING_TYPEID_ERROR,
        payload: hasError,
    };
}

export function setNewAlarmDetails(payload: any) {
    return <const>{
        type: ActionTypes.SET_NEW_ALARM_DETAILS,
        payload,
    };
}

export function showInstancesModal(payload: boolean) {
    return {
        type: ActionTypes.SHOW_INSTANCES_MODAL,
        payload,
    }
}
