import React, { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
    BaseLayout,
    SidebarContainer,
    BodyContainer,
    NoAccess,
} from '../../containers/EditorLayout';
import LeftNavigation from './LeftNavigation';
import CanvasEditorContainer from './CanvasEditorContainer';

import './style.scss';
import ModelsActionHeader from '../Home/CanvasEditorContainer/CanvasHeader';
import {
    CONFIGURATION_TOOL_PRIVILEGES,
    VIEW_MODE,
    CONFIGURATION_TOOL_MODE,
    EXPORT_MODELS_MODES,
} from '../../utils/constants/appConstants';
import {
    createConfigurationToolJson,
    initializeConfigurationTool,
} from '../../store/configurationTool/actions';
import { getAllPermisssionForModelAction, setAuthorize, showInstancesModal, updateModelUsedbyInstances } from '../../store/modelsPage/actions';
import SpinnerModal from '../../components/SpinnerModal';
import SummaryModal from '../../components/ExportDialog/SummaryModal';
import ModalExportDialog from '../../components/ExportDialog/modalExportDialog';
import Condition from '../../components/shared/Condition';
import { handleUserLogin, redirectLogin } from '../../store/login/action';
import { handleTokenDecode, checkPermissionRoles } from '../../utils/helpers';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import AppSettings from '../../services/AppSettings';
import CanvasController from '../../components/Fabric';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import VerticalNavBar from '../../containers/EditorLayout/VerticalNavBar';
import { commonAuthHelper } from '../../utils/types/commonInterface';
import { Dialog } from '@abb/abb-common-ux-react';
import InstancesTable from '../../components/LeftNavigationData/DataRow/InstancesTable';

export function Home(
    props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
    const [canvasController, updateCanvasController] = useState({} as CanvasController);
    const [showExportDialog, updateShowExportDialog] = useState(false);
    const { summaryModalDetails } = props;

    const canAccess = useMemo(() => {
        let access = false;
        const token = commonAuthHelper.getToken();
        if (!!token) {
            access = checkPermissionRoles(token);
        }
        return access;
    }, []);

    useEffect(() => {
        props.setAuthorize(canAccess);
        if (canAccess) {
            handleTokenDecode();
            props.getAllPermisssionForModel();
            const canvasController = new CanvasController(
                undefined,
                CONFIGURATION_TOOL_PRIVILEGES.MODELS_PAGE
            );
            // check if there is modelJson available.

            props.initializeConfigurationTool(canvasController, props.modelJson);
            if (props.modelJson) {
                props.createConfigurationToolJson(props.modelJson);
            }

            updateCanvasController(canvasController);
        }
    }, [canAccess]);

    const toggleDialog = () => {
        updateShowExportDialog(!showExportDialog);
    };

    const closeExportModal = useCallback(() => {
        updateShowExportDialog(false);
    }, [updateShowExportDialog]);

    console.log('edit Mode', props.editMode);
    return (
        <Fragment>
            <Condition when={!canAccess}>
                <BaseLayout>
                    <NoAccess />
                </BaseLayout>
            </Condition>
            <Condition when={canAccess}>
                <BaseLayout hideTopBar={props.editMode}>
                    <div className="base-layout">
                        {props.editMode && (
                            <ModelsActionHeader
                                currentModels={`${props.activeAssetName}`}
                                checkboxLabel="Enable Grid"
                                showContainer={!!props.activeAssetName}
                            />
                        )}
                        <div
                            className={classnames('canvas-layout', {
                                'margin-top': props.editMode,
                            })}
                        >
                            {/* <VerticalNavBar /> */}
                            <SidebarContainer
                                isCollapse={props.editMode && props.sidebarToggleStatus}
                                isModelEditMode={props.editMode}
                                useDraggable
                            >
                                <LeftNavigation
                                    canvas={canvasController}
                                    toggleImportExportDialog={() => toggleDialog()}
                                />
                            </SidebarContainer>
                            <BodyContainer fill isModelEditMode={props.editMode}>
                                <div className="model-container">
                                    <CanvasEditorContainer
                                        canvas={canvasController}
                                        toggleImportExportDialog={() => toggleDialog()}
                                    />
                                </div>
                            </BodyContainer>
                        </div>
                    </div>
                </BaseLayout>
            </Condition>
            {!showExportDialog && (
                <SpinnerModal mode={'Modal'} showModal={props.showSpinnerModal} />
            )}
            <SummaryModal
                showSummaryDialog={summaryModalDetails.showSummaryModal}
                mode={summaryModalDetails.mode}
                route={summaryModalDetails.route}
                data={summaryModalDetails.data}
            />
            {showExportDialog && (
                <ModalExportDialog
                    showExportDialog={showExportDialog}
                    modelMode={EXPORT_MODELS_MODES.MULTPLE_OBJECTS}
                    onExportClose={() => updateShowExportDialog(false)}
                    importMode={props.modelsImportMode}
                />
            )}
            <Dialog
                containerClassName="logic-add-dialog new-layout"
                title="Delete Model"
                dimBackground={true}
                showCloseButton={true}
                isOpen={props.showInstancesModal}
                onClose={() => {
                    props.setInstancesModal(false);
                    props.updateModelUsedByInstances([]);
                }}
                className="instance-dialog-class"
            >
                <InstancesTable isDelete={true} usedInstanceList={props.usedInstanceList} />
            </Dialog>
        </Fragment>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        modelsImportMode: state.exportAsset.importMode,
        sidebarToggleStatus: state.modelsPage.sidebarToggleStatus,
        editMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,

        activeAssetName: state.modelsPage.activeAsset ? state.modelsPage.activeAsset.assetName : '',
        showSpinnerModal: state.loader.importExportModal.isProcessing,
        summaryModalDetails: state.exportAsset.summaryModalDetails,
        isLoggedIn: state.login.isLoggedIn,
        modelJson:
            state.modelsPage.activeModel.modelInstance &&
            state.modelsPage.activeModel.modelInstance.json,
        showInstancesModal: state.modelsPage.showInstancesModal,
        usedInstanceList: state.modelsPage.modelUsedInstances,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        initializeConfigurationTool: (
            canvasController: CanvasController,
            json: LocalJson | undefined
        ) => {
            if (canvasController) {
                dispatch(
                    initializeConfigurationTool({
                        canvasController,
                        activeView: VIEW_MODE.DIAGRAM,
                        mode: CONFIGURATION_TOOL_MODE.VIEW,
                        privileges: CONFIGURATION_TOOL_PRIVILEGES.MODELS_PAGE,
                        json: json,
                    })
                );
            }
        },
        getAllPermisssionForModel: () => {
            dispatch(getAllPermisssionForModelAction());
        },
        redirectLogin: () => {
            dispatch(redirectLogin());
        },
        handleUserLogin: () => {
            dispatch(handleUserLogin());
        },
        createConfigurationToolJson: (json: LocalJson) => {
            dispatch(createConfigurationToolJson({ json: json }));
        },
        setAuthorize: (access: boolean) => {
            dispatch(setAuthorize(access));
        },
        updateModelUsedByInstances: (payload: any) => {
            dispatch(updateModelUsedbyInstances(payload));
        },
        setInstancesModal: (payload: boolean) => {
            dispatch(showInstancesModal(payload));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
