import { takeLatest, select, put, fork, take, cancel } from 'redux-saga/effects';
import { ActionTypes } from '../actionTypes';

import { StoreState } from '..';
import {
    CONFIGURATION_TOOL_MODE,
    VIEW_MODE,
    ROUTE_PATHNAME,
    NOTIFICATION_MODAL_STATUS,
    TYPE_VALIDATION_ERROR_MSG,
    SAVE_OPTIONS,
    CONFIRMATION_MSG,
    OVERALL_SEVERITY_FUNCTION,
    TYPE_VALIDATION_WARNING_MSG,
    INSTANCE_VALIDATION_MSG,
} from '../../utils/constants/appConstants';

import _ from 'lodash';

import {
    addConnectionConfigurationTool,
    updateConfigurationToolJson,
    removeConnectionConfigurationTool,
    triggerConnectionConfigurationTool,
    addDefaultValueConfigurationTool,
    refreshDefaultValueConfigurationTool,
    publishOutputConfigurationTool,
    handleAssetDropAction,
    handleInstanceDropAction,
    publishAlarmConfigurationTool,
    createConfigurationToolJson,
    updateConfigurationToolActiveView,
    updateOverallSeverityFunctionId,
    updateOverAllSeverityFunctionStatus,
    updateConfigurationToolMode,
    markComputeModelDirty,
    getSelectedFunctionDetails,
    setSelectedFunctionDetails,
    handleFunctionDetailsLoading,
    getOriginalFunctionTypes,
    setOriginalFunctionTypes,
    getOriginalFunctionDataFromJson,
    updateCurrentSelectedFunctionWithLatestData,
    getLatestFunctionsForType,
    updateLatestFunctionData,
    updateConfigurationToolJsonWithLatestFunction,
    handleLatestFunctionDataLoading,
    handleCurrentFunctionUpdateStatus,
    initializeConfigurationTool,
    changeIsSeverityUpdate,
    stopBacgroundTaskGetLatestFunctions,
    updateOriginalFunctiontypes,
    updateModelsForTriggers,
    fetchModelsForTriggers,
    fetchAlarmsForModels,
    updateAlarmsForModels,
    addAlarmTriggerToFunction,
    addEventTriggerToFunction,
    updateEventsForModels,
    deleteAlarmTriggerToFunction,
    deleteEventTriggerToFunction,
    editAlarmTriggerToFunction,
    editAliasNameInModels,
    setLatestOriginalFunctions,
} from './actions';
import FunctionTypeDetail, {
    CreateFunctionTypeDetail,
} from '../../transformers/AssetType/FunctionType';

import connectionChecker from '../../components/Fabric/utils/connectionChecker';

import ObjectTypeDetail from '../../transformers/AssetType/ObjectType';
import TableController, { TableData } from '../../model/TableController';
import { getInstanceDetails } from '../../components/Fabric/utils/getInstanceDetails';
import {
    getOriginalFunctionTriggerForInstance,
    deleteFromCanvas,
    getReferencesForDroppingAsset,
    getReferencesFromRemainingCanvasAssets,
    checkWhetherAssetCanBeDropped,
    abbSDKGetErrorMessage,
    getUpdatedVersionForModelDetails,
    isObjectTypePresent,
    updateJsonWithOriginalFunctionDetails,
} from '../../utils/helpers';
import { Asset, AssetsType, ALARM_TYPE } from '../../components/Fabric/types';
import { FunctionTypeDetailsWithMappings } from '../../model/TableController/types';
import { sce } from 'sce-engg-model-19.09';
import { GetFunctionTypePayload, OriginalFunctionTypes, LatestFunctionDataPayload, LatestFunctionDataType } from './types';
import {
    updateTypeJsonConditionDetails,
    updateActiveModel,
    saveComputeModelApi,
    updateActiveModelJsonData,
    handleIsFunctionUpdatesAvailable,
} from '../modelsPage/actions';
import { updateInstanceTypeJsonConditionDetails } from '../instanceConfig/actions';
import { showNotificationModal } from '../notificationModal/action';
import { updateJsonWithLatestFunctionDetails } from './reducer';
import { checkIsFunctionUpdatesAvailable } from '../../routes/Functions/helper';
import { compareTypesJson } from '../../transformers/ComputeModel/validation/versionUpdate';
import { UpdateActiveModelType } from '../../routes/Home/CreateModel/Form/types';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import { showDialog } from '../dialog/action';
import { MessageModal } from '../../components/MessageModal';
import { CONFIRMATION_BUTTON } from '../../utils/constants/uiConstants';
import { IResult } from 'sce-engg-model-19.09/lib/interfaces/IResult';
import { API } from 'sce-engg-model-19.09/lib/interfaces/IPProtection';
import { showGrid } from '../grid/action';
import { strictEqual } from 'assert';

function* addConnectionConfigurationToolSaga(
    action: ReturnType<typeof addConnectionConfigurationTool>
) {
    const { inputCircleData, outputCircleData, inputAsset, outputAsset } = action.payload;
    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { tableData, privileges } = configurationTool;

    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;

    if (
        privileges.FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED === false &&
        outputAsset instanceof FunctionTypeDetail
    ) {
        return;
    }

    if (
        editMode &&
        connectionChecker({
            tableData: tableData,
            inputData: { asset: inputAsset, circleData: inputCircleData },
            outputData: {
                asset: outputAsset as FunctionTypeDetail,
                circleData: outputCircleData,
            },
        })
    ) {
        const asset = tableData.functionTypeDetails.find(
            (asset) => asset.nodeId === inputAsset.nodeId
        );

        if (asset) {
            const inputData =
                asset.inputs.find((i) => i.id === inputCircleData.id) ||
                asset.inhibits.find((i) => i.id === inputCircleData.id);
            if (inputData) {
                inputData.mappingDetails = [
                    ...inputData.mappingDetails,
                    {
                        input: {
                            circleData: inputCircleData,
                            asset: inputAsset,
                        },
                        output: {
                            circleData: outputCircleData,
                            asset: outputAsset as FunctionTypeDetail,
                        },
                    },
                ];

                yield put(
                    updateConfigurationToolJson({
                        tableData: {
                            functionTypeDetails: tableData.functionTypeDetails,
                            objectTypeDetails: tableData.objectTypeDetails,
                            alarmTypeDetails: tableData.alarmTypeDetails,
                            eventTypeDetails: tableData.eventTypeDetails,
                        },
                    })
                );

                if (
                    asset.nodeId === configurationTool.overallSeverityFunctionId &&
                    asset.inputs[0].id === inputData.id
                ) {
                    yield put(
                        triggerConnectionConfigurationTool({
                            asset: asset,
                            circleData: inputData,
                            trigger: true,
                        })
                    );
                }
            }
        }
    }
}

function* removeConnectionConfigurationToolSaga(
    action: ReturnType<typeof removeConnectionConfigurationTool>
) {
    const { inputCircleData, inputAsset } = action.payload;

    let outputCircleData: any;
    let outputAsset;
    let removeAll = false;
    if ('outputCircleData' in action.payload) {
        outputCircleData = action.payload.outputCircleData;
        outputAsset = action.payload.outputAsset;
    }
    if ('removeAll' in action.payload) {
        removeAll = action.payload.removeAll;
    }

    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { overallSeverityFunctionId } = configurationTool;
    const { tableData, privileges } = configurationTool;

    if (
        privileges.FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED === false &&
        outputAsset &&
        outputAsset instanceof FunctionTypeDetail
    ) {
        return;
    }
    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;

    if (editMode) {
        const asset = tableData.functionTypeDetails.find(
            (asset) => asset.nodeId === inputAsset.nodeId
        );

        if (asset) {
            const inputData =
                asset.inputs.find((i) => i.id === inputCircleData.id) ||
                asset.inhibits.find((i) => i.id === inputCircleData.id);
            if (inputData) {
                inputData.mappingDetails = removeAll
                    ? inputData.mappingDetails.filter((connection) => {
                        if (
                            privileges.FUNCTION_OUTPUT_MAPPING_CHANGE_ALLOWED === false &&
                            connection.output.asset instanceof FunctionTypeDetail
                        ) {
                            return true;
                        }
                        return false;
                    })
                    : outputCircleData
                        ? inputData.mappingDetails.filter((connection) => {
                            if (connection.output.circleData.id !== outputCircleData.id) {
                                return true;
                            } else {
                                const pinConnections = getConnectionsOfOutputPin(
                                    connection.output.circleData.id,
                                    configurationTool.json
                                );
                                if (pinConnections.length <= 1) {
                                    connection.output.circleData.connected = false;
                                    connection.output.asset.outputs.forEach((item) => {
                                        if (item.id === connection.output.circleData.id) {
                                            item.connected = false;
                                        }
                                    });
                                }
                                return false;
                            }
                        })
                        : inputData.mappingDetails;
                if (inputData.mappingDetails.length === 0) {
                    inputData.trigger = false;
                }
                yield put(
                    updateConfigurationToolJson({
                        tableData: {
                            functionTypeDetails: tableData.functionTypeDetails,
                            objectTypeDetails: tableData.objectTypeDetails,
                            alarmTypeDetails: tableData.alarmTypeDetails,
                            eventTypeDetails: tableData.eventTypeDetails,
                        },
                    })
                );
            }
        }
    }
}


function* triggerConnectionConfigurationToolSaga(
    action: ReturnType<typeof triggerConnectionConfigurationTool>
) {
    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { tableData, privileges } = configurationTool;
    const { asset, circleData, trigger } = action.payload;

    if (privileges.FUNCTION_TRIGGER_ALLOWED === false) {
        return;
    }

    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;

    if (editMode && typeof trigger === 'boolean') {
        const functionAsset = tableData.functionTypeDetails.find(
            (functionAsset) => functionAsset.nodeId === asset.nodeId
        );
        if (functionAsset) {
            const input =
                functionAsset.inputs.find((input) => input.id === circleData.id) ||
                functionAsset.inhibits.find((input) => input.id === circleData.id);
            if (input) {
                if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
                    const triggers = getOriginalFunctionTriggerForInstance({
                        nodeId: asset.nodeId,
                        originalFunctionTypeDetail: store.instanceConfig.originalTypeData,
                    });
                    if (triggers && triggers.variableChange) {
                        const matchedTrigger = Object.keys(triggers.variableChange).find(
                            (item) => item === input.name
                        );
                        if (matchedTrigger) {
                            input.trigger = trigger;
                        } else {
                            if (circleData.connected) {
                                yield put(
                                    showDialog({
                                        component: MessageModal,
                                        modalTitle: CONFIRMATION_MSG.INPUT_TRIGGER_TITLE,
                                        data: {
                                            standardButtonsOnBottom: [
                                                {
                                                    text: CONFIRMATION_BUTTON.OK,
                                                    type: 'primary-blue',
                                                    handler: (dlg: any) => {
                                                        dlg.props.onClose();
                                                    },
                                                },
                                            ],
                                            warningText: `Please update the model to trigger ${circleData.name}`,
                                        },
                                    })
                                );
                            }

                            input.trigger = !trigger;
                        }
                    }
                } else {
                    if (input.connected) {
                        input.trigger = trigger;
                    } else {
                        input.trigger = !trigger;
                    }
                }
                yield put(
                    updateConfigurationToolJson({
                        tableData: {
                            functionTypeDetails: tableData.functionTypeDetails,
                            objectTypeDetails: tableData.objectTypeDetails,
                            alarmTypeDetails: tableData.alarmTypeDetails,
                            eventTypeDetails: tableData.eventTypeDetails,
                        },
                    })
                );
            }
        }
    }
}


function* refreshDefaultValueConfigurationToolSaga(
    action: ReturnType<typeof refreshDefaultValueConfigurationTool>
) {
    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { tableData } = configurationTool;
    const { asset, circleData, defaultValue } = action.payload;
    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;
    if (editMode && typeof defaultValue === 'string') {
        const functionAsset = tableData.functionTypeDetails.find(
            (functionAsset) => functionAsset.nodeId === asset.nodeId
        );
        if (functionAsset) {
            const origVersionId = asset.assetRef.split("@");
            const orunId = origVersionId[0] + '#' + asset.assetVersion;
            const origFuncData = configurationTool.originalFunctionTypesUsed.byIdWithVersion[orunId] || configurationTool.latestOriginalFunctionsUsed.byIdWithVersion[orunId];
            const input =
                functionAsset.inputs.find((input) => input.id === circleData.id) ||
                functionAsset.inhibits.find((input) => input.id === circleData.id);
            if (input) {
                if (origFuncData) {
                    if (origFuncData.inputs) {
                        let inputExists = origFuncData.inputs.find((originput: any) => originput.name === input.name);
                        if (inputExists) {
                            input.defaultValue = inputExists.defaultValue;
                            yield put(
                                updateConfigurationToolJson({
                                    tableData: {
                                        functionTypeDetails: tableData.functionTypeDetails,
                                        objectTypeDetails: tableData.objectTypeDetails,
                                        alarmTypeDetails: tableData.alarmTypeDetails,
                                        eventTypeDetails: tableData.eventTypeDetails,
                                    },
                                })
                            );
                        }
                    }
                }
            }
        }
    }
}

function* addDefaultValueConfigurationToolSaga(
    action: ReturnType<typeof addDefaultValueConfigurationTool>
) {
    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { tableData } = configurationTool;
    const { asset, circleData, defaultValue } = action.payload;

    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;
    if (editMode && typeof defaultValue === 'string') {
        const functionAsset = tableData.functionTypeDetails.find(
            (functionAsset) => functionAsset.nodeId === asset.nodeId
        );
        if (functionAsset) {
            const input =
                functionAsset.inputs.find((input) => input.id === circleData.id) ||
                functionAsset.inhibits.find((input) => input.id === circleData.id);
            if (input) {
                input.defaultValue = defaultValue;
                yield put(
                    updateConfigurationToolJson({
                        tableData: {
                            functionTypeDetails: tableData.functionTypeDetails,
                            objectTypeDetails: tableData.objectTypeDetails,
                            alarmTypeDetails: tableData.alarmTypeDetails,
                            eventTypeDetails: tableData.eventTypeDetails,
                        },
                    })
                );
            }
        }
    }
}

function* publishOutputConfigurationToolSaga(
    action: ReturnType<typeof publishOutputConfigurationTool>
) {
    const { circleData, asset } = action.payload;
    const { configurationTool }: StoreState = yield select();
    const { tableData, privileges } = configurationTool;

    if (privileges.FUNCTION_PUBLISH_ALLOWED === false) {
        return;
    }

    const functionAsset = tableData.functionTypeDetails.find(
        (functionAsset) => functionAsset.nodeId === asset.nodeId
    );
    if (functionAsset) {
        const output = functionAsset.outputs.find((output) => output.id === circleData.id);

        if (output) {
            if (functionAsset.nodeId !== configurationTool.overallSeverityFunctionId) {
                output.isPublished = !output.isPublished;
                yield put(
                    updateConfigurationToolJson({
                        tableData: {
                            functionTypeDetails: tableData.functionTypeDetails,
                            objectTypeDetails: tableData.objectTypeDetails,
                            alarmTypeDetails: tableData.alarmTypeDetails,
                            eventTypeDetails: tableData.eventTypeDetails,
                        },
                    })
                );
                yield put(changeIsSeverityUpdate());
            }
        }
    }
}

function* publishAlarmConfigurationToolSaga(
    action: ReturnType<typeof publishAlarmConfigurationTool>
) {
    const { asset } = action.payload;
    const { configurationTool, instanceConfig }: StoreState = yield select();
    const { tableData, privileges } = configurationTool;

    const functionAsset = tableData.functionTypeDetails.find(
        (functionAsset) => functionAsset.nodeId === asset.nodeId
    );
    if (functionAsset) {
        const alarmPins = functionAsset.alarmPins;

        if (alarmPins) {
            if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
                const originalTypeDetail = instanceConfig.originalTypeData!;

                const nodeId = functionAsset.nodeId;
                const originalTypeAlarm = originalTypeDetail.properties.alarms;
                if (originalTypeAlarm && originalTypeAlarm[nodeId]) {
                    alarmPins.isPublished = !alarmPins.isPublished;
                } else {
                    alarmPins.isPublished = alarmPins.isPublished;
                }
            } else {
                alarmPins.isPublished = !alarmPins.isPublished;
            }
            yield put(
                updateConfigurationToolJson({
                    tableData: {
                        functionTypeDetails: tableData.functionTypeDetails,
                        objectTypeDetails: tableData.objectTypeDetails,
                        alarmTypeDetails: tableData.alarmTypeDetails,
                        eventTypeDetails: tableData.eventTypeDetails,
                    },
                })
            );
        }
    }
}

function* handleAssetDrop(action: ReturnType<typeof handleAssetDropAction>) {
    console.log('AssetDrop called .');
    try {
        const { position, asset, canvasAssets, activeModel } = action.payload;
        let isDroppableType = true;
        let error = '';

        if (asset.isReferencedType) {
            const currentModelId = activeModel.modelInstance.modelDetails.model;

            const assetModelReferences = getReferencesForDroppingAsset(asset);

            const canvasReferences = getReferencesFromRemainingCanvasAssets(canvasAssets);

            const { isDroppable, errorMsg } = checkWhetherAssetCanBeDropped(
                currentModelId,
                assetModelReferences,
                canvasReferences,
                asset.assetName,
                activeModel.modelInstance.modelDetails.name
            );
            isDroppableType = isDroppable;
            error = errorMsg;
        }
        if (!isDroppableType) {
            yield put(
                showNotificationModal({
                    title: TYPE_VALIDATION_ERROR_MSG,
                    resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                    details: [error],
                    type: 'confirmation',
                })
            );
            return;
        }

        const { configurationTool }: StoreState = yield select();
        const editMode =
            configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;

        const { canvasController, activeView, tableData, json } = configurationTool;

        if (!editMode) {
            return;
        }

        // if (activeView === VIEW_MODE.DIAGRAM) {
        if (position && canvasController) {
            // if (activeView === VIEW_MODE.TABLE) {
            //     canvasController.fromJSON({
            //         json,
            //         editMode: editMode,
            //     });
            // }

            if (asset.assetType === AssetsType.OBJECT && !asset.isReferencedType) {
                const { isPresent, matchedAsset } = isObjectTypePresent(json, asset);
                if (isPresent) {
                    const errorMessage = `${matchedAsset.assetName} is already present on the canvas.`;
                    yield put(
                        showNotificationModal({
                            resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                            title: TYPE_VALIDATION_WARNING_MSG,
                            details: [errorMessage],
                            type: 'confirmation',
                        })
                    );

                    return;
                }
            }

            canvasController.addDraggable({
                asset: asset,
                position,
            });
        }
    } catch (e) {
        console.error(
            '%c  error inside ADD_ASSET_OBJECT_REQUEST',
            'background: salmon; color: black',
            e
        );
        yield put({
            type: ActionTypes.ADD_ASSET_OBJECT_FAILURE,
            payload: {
                assetObj: null,
            },
        });
    }
}

function* handleInstanceDrop(action: ReturnType<typeof handleInstanceDropAction>) {
    debugger;
    const { position, instance } = action.payload;
    const { configurationTool, instanceConfig }: StoreState = yield select();
    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;

    const selectedInstances = instanceConfig.instancesTable.tableData.selectedInstances;
    const { canvasController, activeView, tableData } = configurationTool;
    // if in view mode, if instance already dropped or no computeModel selected then return
    if (
        !editMode ||
        configurationTool.json.assetData.find((asset) => asset.nodeId === instance.objectId) ||
        configurationTool.json.assetData.length === 0
    ) {
        return;
    }
    // check if originalTypeObject is deleted , else allow only one instance to be selected incase of multiple selected instance.
    const json = configurationTool.json;
    if (selectedInstances.length > 1) {
        const hasObjects = json.assetData.some((item) => {
            let isPresent = false;
            if (item instanceof ObjectTypeDetail && !item.objectInstance) {
                isPresent = true;
            }
            return isPresent;
        });
        const hasObjectInstances = json.assetData.some((item) => {
            let isPresent = false;
            if (item instanceof ObjectTypeDetail && item.objectInstance) {
                isPresent = true;
            }
            return isPresent;
        });
        if (hasObjectInstances && hasObjects) {
            return;
        }
    }

    // It will render the same json in both the table view and Diagram view.
    if (position && canvasController) {
        // if (activeView === VIEW_MODE.TABLE) {
        //     // updating the json in Diagram view.
        //     canvasController.fromJSON({
        //         json,
        //         editMode: editMode,
        //     });
        // }

        canvasController.addDraggableInstance({
            instance,
            position,
        });
    }
}

function* removSeverityFunctionFromCanvas() {
    const state: StoreState = yield select();

    const canvasJson = _.cloneDeepWith(state.configurationTool.json);
    const tableData = TableController.fromJSON({
        canvasJson: canvasJson,
    });

    let overallSeverityFunction = {} as FunctionTypeDetailsWithMappings;

    const overAllseverityFunctionDetail = tableData.functionTypeDetails.find(
        (node) => node.nodeId === state.configurationTool.overallSeverityFunctionId
    );

    if (overAllseverityFunctionDetail) {
        overallSeverityFunction = { ...overAllseverityFunctionDetail };
    }
    const latestTableData = deleteFromCanvas([overallSeverityFunction!], {
        ...tableData,
    });

    yield put(updateConfigurationToolJson({ tableData: latestTableData }));
    yield put(updateConfigurationToolActiveView({ activeView: VIEW_MODE.DIAGRAM }));
    yield put(updateOverallSeverityFunctionId(''));
    yield put(updateOverAllSeverityFunctionStatus(false));
}

function* handleConfigurationToolModel(action: ReturnType<typeof updateConfigurationToolMode>) {
    const state: StoreState = yield select();
    const { mode } = action.payload;
    const activeModel = state.modelsPage.activeModel.modelInstance;
    if (mode === CONFIGURATION_TOOL_MODE.EDIT) {
        const isOverAllSeverityFuncAdded = activeModel.isOverAllSeverityFunctionPresent || false;
        const severityFunctionId = activeModel.overAllSeverityFunctionId || '';
        yield put(updateOverAllSeverityFunctionStatus(isOverAllSeverityFuncAdded));
        yield put(updateOverallSeverityFunctionId(severityFunctionId));
    }
}

function* handleSelectedFunction(action: ReturnType<typeof getSelectedFunctionDetails>) {
    const { assetRef, assetType, assetVersion } = action.payload;
    try {
        const res = yield sce.getTypeDetails(assetType, assetRef, assetVersion);
        const nodeId = res.details.name;
        const position = { x: 0, y: 0 };
        const functionTypeDetails = CreateFunctionTypeDetail({
            ...res.details,
            position,
            nodeId,
        });
        yield put(setSelectedFunctionDetails(functionTypeDetails));
    } catch (err) {
        yield put(handleFunctionDetailsLoading(false));
    }
}

function* getOrignalFunctionTypesDetail(action: ReturnType<typeof getOriginalFunctionTypes>) {
    const { functionTypes: functionTypes, currentRoutePath } = action.payload;

    let originalFunctionTypesDetail: OriginalFunctionTypes = {
        byIdWithVersion: {},
        entities: [],
    };

    const usedFunctionData: OriginalFunctionTypes = yield Promise.all(
        functionTypes.map(async (item) => {
            const { assetRef, assetType, assetVersion } = item;
            try {
                const res = await sce.getFunctionTypeWithEncryptionStatus(
                    assetType,
                    assetRef,
                    assetVersion.split('.')[0]
                );
                const nodeId = res.details.functionType.name;
                const position = { x: 0, y: 0 };
                const functionTypeDetails = CreateFunctionTypeDetail({
                    ...(res.details.functionType as any),
                    position,
                    nodeId,
                    properties: {
                        ...res.details.functionType.properties,
                        isEncrypted: res.details.isEncrypted,
                    },
                });
                const functionIdWithVersion = `${assetRef}#${assetVersion}`;

                const hasFunctionDetail = originalFunctionTypesDetail.entities.find(
                    (item) => item === functionIdWithVersion
                );
                if (!hasFunctionDetail) {
                    if (functionTypeDetails) {
                        originalFunctionTypesDetail.entities.push(functionIdWithVersion);

                        originalFunctionTypesDetail.byIdWithVersion[functionIdWithVersion] = {
                            ...functionTypeDetails,
                        };
                    }
                }
                return originalFunctionTypesDetail;
            } catch (error) {
                console.log('error getting original function details.', assetRef);
                return Promise.resolve({} as OriginalFunctionTypes);
            }
        })
    );

    yield put(
        setOriginalFunctionTypes({
            originalFunctionTypes: originalFunctionTypesDetail,
            currentRoutePath,
        })
    );
}

function* setOriginalFunctionTypesSaga(action: ReturnType<typeof setOriginalFunctionTypes>) {
    const { originalFunctionTypes, currentRoutePath } = action.payload;
    const { byIdWithVersion, entities } = originalFunctionTypes;

    const originalFunctionTypesUsed: OriginalFunctionTypes = {
        byIdWithVersion,
        entities,
    };
    const state: StoreState = yield select();
    const { json, addIsDirtyToConditions } = state.configurationTool;
    yield put(updateOriginalFunctiontypes(originalFunctionTypesUsed));

    if (currentRoutePath.includes(ROUTE_PATHNAME.HOME)) {
        const { modelsPage } = state;
        const activeModel = _.cloneDeepWith(modelsPage.activeModel.modelInstance);
        activeModel.json = _.cloneDeepWith(json);

        if (activeModel.saveOption && activeModel.saveOption === SAVE_OPTIONS.PUBLISH) {
            yield put(stopBacgroundTaskGetLatestFunctions());
            //json.assetData.forEach((asset) => {if(asset instanceof FunctionTypeDetail) alert(asset.assetVersion)})
            yield put(
                getLatestFunctionsForType({
                    activeModel,
                    currentRoutePath: ROUTE_PATHNAME.HOME,
                })
            );
        }
    } else {
        const updatedJson = updateJsonWithOriginalFunctionDetails({
            json,
            originalFunctionUsed: originalFunctionTypesUsed,
            addIsDirtyToOldCondtions: addIsDirtyToConditions,
        });
        yield put(createConfigurationToolJson({ json: updatedJson }));

        yield put(
            updateInstanceTypeJsonConditionDetails({
                json: updatedJson,
                originalFunctionUsed: originalFunctionTypesUsed,
            })
        );
    }
}

function* getOriginalFunctionTypesMain(action: ReturnType<typeof getOriginalFunctionTypes>) {
    const bgSyncTask = yield fork(getOrignalFunctionTypesDetail, action);
    // wait for the user stop action
    yield take('STOP_BACKGROUND_SYNC_FOR_GET_ORIGINAL_FUNC_TYPES');
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block

    yield cancel(bgSyncTask);
    yield put(stopBacgroundTaskGetLatestFunctions());
    yield put(createConfigurationToolJson({ json: { assetData: [], connectionData: [] } }));
}

function* handleGetOriginalFunctionDataFromJson(
    action: ReturnType<typeof getOriginalFunctionDataFromJson>
) {
    const { json: typeFromJson, currentRoutePath } = action.payload;

    const json = { ...typeFromJson.json };
    const originalFunctionTypesPayload: GetFunctionTypePayload[] = [];
    yield put(createConfigurationToolJson({ json: typeFromJson.json }));
    json.assetData.forEach((asset) => {
        const { assetRef, assetType, assetVersion } = asset;

        const lastIndex = assetRef.lastIndexOf('@');
        let typeId = assetRef;

        if (lastIndex !== -1) {
            typeId = typeId.slice(0, lastIndex);
        }
        if (asset instanceof FunctionTypeDetail) {
            const getFuncTypePayload: GetFunctionTypePayload = {
                assetType: assetType,
                assetRef: typeId,
                assetVersion: assetVersion,
            };
            originalFunctionTypesPayload.push(getFuncTypePayload);
        }
    });
    yield put(
        getOriginalFunctionTypes({
            functionTypes: originalFunctionTypesPayload,
            currentRoutePath,
        })
    );
}

function* getLatestFunctionsForTypeMain(action: ReturnType<typeof getLatestFunctionsForType>) {
    const bgSyncTask = yield fork(getLatestFunctionsForTypeData, action);
    // wait for the user stop action
    yield take('STOP_BACKGROUND_SYNC_FOR_GET_LATEST_FUNCTIONS');
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgSyncTask);
}
export function* getLatestFunctionsForTypeData(
    action: ReturnType<typeof getLatestFunctionsForType>
) {
    const { activeModel, currentRoutePath } = action.payload;
    const state: StoreState = yield select();
    const { originalFunctionTypesUsed, addIsDirtyToConditions, previousJson } = state.configurationTool;
    const modelDetails = activeModel.modelDetails;
    const modelId = modelDetails.model;
    const typeId = modelDetails.typeId;
    const version = modelDetails.version;
    try {
        const latestFunctionsResponse: IResult<API.IFunctionType[]> =
            yield sce.getTypeLatestFunctionDataWithEncryption(modelId, typeId, version);

        if (latestFunctionsResponse && latestFunctionsResponse.details) {
            const responseDetails = latestFunctionsResponse.details;

            const latestFunctions: {[typeId: string]: LatestFunctionDataType} = [...responseDetails].reduce(
                (o, item) => ({
                    ...o,
                    [item.functionType.typeId]: {
                        ...item.functionType,
                        properties: {
                            ...item.functionType.properties,
                            isEncrypted: item.isEncrypted,
                        },
                    },
                }),
                {}
            );

            let latestOriginalFunctionsDetail: OriginalFunctionTypes = {
                byIdWithVersion: {},
                entities: [],
            };

            Object.values(latestFunctions).forEach((item) => {
                const assetRef = item.typeId;
                const assetType = item.model;
                const assetVersion = item.version;
                    // const res = await sce.getFunctionTypeWithEncryptionStatus(
                    //     assetType,
                    //     assetRef,
                    //     assetVersion.split('.')[0]
                    // );
                    const nodeId = item.name;
                    const position = { x: 0, y: 0 };
                    const functionTypeDetails = CreateFunctionTypeDetail({
                        ...(item as any),
                        position,
                        nodeId,
                        properties: {
                            ...item.properties,
                        },
                    });
                    const functionIdWithVersion = `${assetRef}#${assetVersion}`;
    
                    const hasFunctionDetail = latestOriginalFunctionsDetail.entities.find(
                        (item) => item === functionIdWithVersion
                    );
                    if (!hasFunctionDetail) {
                        if (functionTypeDetails) {
                            latestOriginalFunctionsDetail.entities.push(functionIdWithVersion);
    
                            latestOriginalFunctionsDetail.byIdWithVersion[functionIdWithVersion] = {
                                ...functionTypeDetails,
                            };
                        }
                    }
            });

            yield put(
                setLatestOriginalFunctions({
                    latestOriginalFunctions: latestOriginalFunctionsDetail,
                    currentRoutePath,
                })
            );

            const updatedJson = yield handleFunctionUpdateAvailable(activeModel.json, latestFunctions);
            if (currentRoutePath === ROUTE_PATHNAME.HOME) {
                const isFunctionUpdatesAvailable = checkIsFunctionUpdatesAvailable(updatedJson);
                yield put(handleIsFunctionUpdatesAvailable(isFunctionUpdatesAvailable));

                const udpatedJsonWithFunctions = updateJsonWithOriginalFunctionDetails({
                    json: updatedJson,
                    originalFunctionUsed: originalFunctionTypesUsed,
                    addIsDirtyToOldCondtions: addIsDirtyToConditions,
                });
                activeModel.json = { ...udpatedJsonWithFunctions };
                yield put(
                    createConfigurationToolJson({
                        json: activeModel.json,
                    })
                );

                yield put(
                    updateTypeJsonConditionDetails({
                        json: activeModel.json,
                        originalFunctionUsed: originalFunctionTypesUsed,
                    })
                );
                // To update the isUpdate available property in functiontypeDetails.
                yield put(updateActiveModelJsonData({ json: activeModel.json }));
                yield put(updateLatestFunctionData(latestFunctions));
                yield put(createConfigurationToolJson({ json: activeModel.json }));
            } else {
                yield put(updateLatestFunctionData(latestFunctions));
                yield put(createConfigurationToolJson({ json: updatedJson }));
            }
        }
    } catch (error) {
        yield put(handleLatestFunctionDataLoading(false));
        // render original json.
        yield put(createConfigurationToolJson({ json: activeModel.json }));
        yield put(
            showNotificationModal({
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                title: 'Api Error',
                details: [abbSDKGetErrorMessage(error)],
                type: 'confirmation',
            })
        );
    }
}

export function* refreshFunctionWithLatestData(
    action: ReturnType<typeof updateCurrentSelectedFunctionWithLatestData>
) {
    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { currentSelectedFunction } = action.payload;
    const { assetRef, nodeId } = currentSelectedFunction;
    const currenAssetRef = assetRef.split('@')[0];
    const latestFunctions: LatestFunctionDataPayload = {};
    latestFunctions[currenAssetRef] = configurationTool.latestFunctionTypes[currenAssetRef];

    const updatedJson = updateJsonWithLatestFunctionDetails({
        json: configurationTool.json,
        latestFunctions: latestFunctions,
        nodeId,
    });
    yield put(createConfigurationToolJson({ json: updatedJson }));
    yield put(markComputeModelDirty());
    const isFunctionUpdateAvailable = checkIsFunctionUpdatesAvailable(updatedJson);
    yield put(handleIsFunctionUpdatesAvailable(isFunctionUpdateAvailable));
}

export function* updateTypeWithLatestFunctionsSaga() {
    const store: StoreState = yield select();
    const { configurationTool, modelsPage } = store;
    const { json, latestFunctionTypes, mode } = configurationTool;
    const { activeModel } = modelsPage;
    const activeModelJson = activeModel.modelInstance.json;
    const modelDetails = activeModel.modelInstance.modelDetails;

    let newJson: LocalJson = { ...json };
    if (mode === CONFIGURATION_TOOL_MODE.VIEW) {
        const originalFunctionUsed = store.configurationTool.originalFunctionTypesUsed;
        newJson = updateJsonWithOriginalFunctionDetails({
            json: newJson,
            originalFunctionUsed: originalFunctionUsed,
            addIsDirtyToOldCondtions: false,
        });
    }
    const updatedJson = updateJsonWithLatestFunctionDetails({
        json: newJson,
        latestFunctions: latestFunctionTypes,
    });

    if (mode === CONFIGURATION_TOOL_MODE.VIEW) {
        const versionUpdateType = compareTypesJson({
            originalJson: activeModelJson,
            updatedJson,
            computeModel: activeModel.modelInstance,
            overallSeverityFunctionId: configurationTool.overallSeverityFunctionId,
        });

        const updatedVersion = getUpdatedVersionForModelDetails({
            versionUpdateType,
            oldVersion: modelDetails.version,
        });
        const activeModelDetails: UpdateActiveModelType = {
            ...modelDetails,
            version: updatedVersion,
        };
        yield put(updateActiveModel({ ...activeModelDetails }));
        yield put(updateConfigurationToolJsonWithLatestFunction(updatedJson));
        const isOverAllSeverityFunctionPresent = updatedJson.assetData.some((asset) => {
            if (
                asset instanceof FunctionTypeDetail &&
                asset.nodeId === OVERALL_SEVERITY_FUNCTION.ID
            ) {
                return true;
            } else {
                return false;
            }
        });
        if (isOverAllSeverityFunctionPresent) {
            yield put(updateOverallSeverityFunctionId(OVERALL_SEVERITY_FUNCTION.ID));
            yield put(updateOverAllSeverityFunctionStatus(true));
        }
        yield put(
            saveComputeModelApi({
                saveOption: SAVE_OPTIONS.PUBLISH,
                isLatestFunctionsUpdating: true,
            })
        );
    } else {
        yield put(createConfigurationToolJson({ json: updatedJson }));
        yield put(handleCurrentFunctionUpdateStatus(true));
        yield put(handleIsFunctionUpdatesAvailable(false));
        yield put(markComputeModelDirty());
    }
}

export function* initializeConfigurationToolSaga(
    action: ReturnType<typeof initializeConfigurationTool>
) {
    const { canvasController } = action.payload;
    if (canvasController) {
        yield put(showGrid(false));
    }
}

export function handleOverallSeverityFuncDelete(totalAsset: Asset[], selectedAsset: Asset[]) {
    let isAllConditionSelected = false;
    let isAllReferencedTypesSelected = false;
    let conditionFunctionCount = 0;
    let selectedConditionFunctionLength = 0;
    let availableReferencedTypesLength = 0;
    let selectedReferencedTuypesLength = 0;
    totalAsset.forEach((item) => {
        if (item instanceof FunctionTypeDetail) {
            if (item.conditions) {
                conditionFunctionCount++;
            }
        }
        if (item instanceof ObjectTypeDetail) {
        } else if (item instanceof ObjectTypeDetail) {
            if (item.isReferencedType) {
                availableReferencedTypesLength++;
            }
        }
    });

    selectedAsset.forEach((item) => {
        if (item instanceof FunctionTypeDetail) {
            if (item.conditions) {
                selectedConditionFunctionLength++;
            }
        }
        if (item instanceof ObjectTypeDetail) {
        } else if (item instanceof ObjectTypeDetail) {
            if (item.isReferencedType) {
                selectedReferencedTuypesLength++;
            }
        }
    });

    if (conditionFunctionCount === selectedConditionFunctionLength) {
        isAllConditionSelected = true;
    }
    if (selectedReferencedTuypesLength === availableReferencedTypesLength) {
        isAllReferencedTypesSelected = true;
    }

    return isAllConditionSelected && isAllReferencedTypesSelected;
}

export function handleFunctionUpdateAvailable(
    json: LocalJson,
    latestFunctionData: LatestFunctionDataPayload
) {
    let updatedJson = _.cloneDeepWith(json);

    updatedJson.assetData.forEach((asset) => {
        
        if (asset instanceof FunctionTypeDetail) {
            const functionDetail = asset;
            const assetRefValue = functionDetail.assetRef.split('@')[0];
            const oldVersion = functionDetail.assetVersion;
            const currentFunction = latestFunctionData[assetRefValue];
            // handle deleted function.
            if (currentFunction) {
                const newVersion = currentFunction.version;
                const minorOldVersion = parseInt(oldVersion.split('.')[1]);
                const minorNewVersion = parseInt(newVersion.split('.')[1]);
                const isMinorVersionAvailable = minorNewVersion > minorOldVersion;


                const majorOldVersion = parseInt(oldVersion.split('.')[0]);
                const majorNewVersion = parseInt(newVersion.split('.')[0]);
                const isMajorVersionUpdateAvailable = majorNewVersion > majorOldVersion;
                if (isMajorVersionUpdateAvailable || (currentFunction.name === "OverallSeverity" && isMinorVersionAvailable)) {
                    asset.isUpdateAvailable = true;
                } else {
                    const currentConditions = functionDetail.conditions;
                    let isUpdate = false;
                    if(functionDetail.assetVersion !== currentFunction.version && currentFunction.properties['alias'] && Object.keys(currentFunction.properties['alias']).length) {
                        Object.keys(currentFunction.properties['alias']).forEach((key) => {
                            if(functionDetail.alias[key] && currentFunction.properties['alias'][key].value !== functionDetail.alias[key].value) {
                                isUpdate = true;
                            }
                        })
                        
                    }
                    
                    if (Object.keys({ ...currentConditions }).length > 0) {
                        if (functionDetail.assetVersion !== currentFunction.version) {
                            isUpdate = isUpdate || isOverRiddenConditionFieldsChanged({
                                latestCondition: currentFunction.properties['inputs'].conditions,
                                overRiddenCondition: functionDetail.conditions,
                            });
                        }
                    }
                    
                    asset.isUpdateAvailable = isUpdate;
                }
                
                if (!isMajorVersionUpdateAvailable && isMinorVersionAvailable && currentFunction.name !== "OverallSeverity") {
                    const position = { x: 0, y: 0 };
                    const nodeId = asset.nodeId;
                    const functionTypeDetails = CreateFunctionTypeDetail({
                        ...(currentFunction as any),
                        position,
                        nodeId,
                        properties: {
                            ...currentFunction.properties,
                            isEncrypted: functionDetail.isEncrypted,
                        },
                    });
                    
                    // if (functionTypeDetails) {
                    //     functionDetail.inputs = functionTypeDetails.inputs;
                    // }
                }
            }
        }
    });
    updatedJson.connectionData = updatedJson.connectionData.filter((connection: any, index: number) => {
        if (connection.input.asset.inputs.length > 0 && (connection.output.asset instanceof FunctionTypeDetail || connection.output.asset instanceof ObjectTypeDetail)) {
            let inputData: any;
            connection.input.asset.inputs.find((assetInput: any) => {
                if(assetInput.path === connection.input.circleData.path){
                    inputData = assetInput;
                }
            });
            // if ((inputData && inputData.defaultValue !== undefined)) {
            //     return false;
            // }
            return true;
        }
        return true;
    });
    
    return updatedJson;
}

export function isOverRiddenConditionFieldsChanged(options: {
    latestCondition: any;
    overRiddenCondition: any;
}) {
    const { latestCondition, overRiddenCondition } = options;
    let isValueChangedForOverRiddenItem = false;

    //return !_.isEqual(latestCondition, overRiddenCondition);

    Object.keys(overRiddenCondition).forEach((conditionKey) => {
        const condition = overRiddenCondition[conditionKey];
        const subConditions = condition.subConditions;

        Object.keys(subConditions).forEach((subConditionName) => {
            const subConditionItem = subConditions[subConditionName];
            Object.keys(subConditionItem).forEach((item) => {
                const originalItem =
                    latestCondition[conditionKey].subConditions[subConditionName][item];
                const overRiddenItem = subConditionItem[item];

                if (originalItem.value && originalItem.value !== overRiddenItem.value) {
                    isValueChangedForOverRiddenItem = true;
                }
            });
        });
    });
    //return !_.isEqual(latestCondition, overRiddenCondition);
    return isValueChangedForOverRiddenItem;
}

export function* fetchModelsForTriggersSaga(action: ReturnType<typeof fetchModelsForTriggers>) {
    try {
        const res = yield sce.getAllModels();
        const models = res.details;
        yield put(
            updateModelsForTriggers({
                models,
            })
        );
        yield put(fetchAlarmsForModels({ modelId: models[5].key }));
    } catch (err) {
        yield put(handleFunctionDetailsLoading(false));
    }
}

export function* fetchAlarmsforModelsSaga(action: ReturnType<typeof fetchAlarmsForModels>) {
    try {
        const { modelId } = action.payload;
        const res = yield sce.getAlarms(modelId);
        const alarms: string[] = res.details;
        yield put(
            updateAlarmsForModels({
                alarms,
                modelId,
            })
        );
    } catch (err) {
        yield put(handleFunctionDetailsLoading(false));
    }
}

export function* addAlarmTriggerToFunctionSaga(
    action: ReturnType<typeof addAlarmTriggerToFunction>
) {
    const { alarmDetails, functionType, objectType, objectOutputId } = action.payload;
    const [alarmId, alarmVersion] = alarmDetails.alarmId.split('@');

    try {
        const res = yield sce.getAlarmProperties('abb.ability.am.alarm', alarmId, alarmVersion);
        const alarmProperties = res.details;
        const store: StoreState = yield select();
        store.configurationTool.canvasController &&
            store.configurationTool.canvasController.handleAddAlarm({
                alarmDetails,
                alarmProperties,
                functionType,
                objectType,
                objectOutputId,
            });
    } catch (err) {
        console.log(err);
        yield put(handleFunctionDetailsLoading(false));
    }
}

export function* editAlarmTriggerToFunctionSaga(
    action: ReturnType<typeof editAlarmTriggerToFunction>
) {
    const { alarmType, newName, functionType } = action.payload;

    try {
        const store: StoreState = yield select();
        let updatedtableData = _.cloneDeep(store.configurationTool.tableData);
        updatedtableData.alarmTypeDetails.forEach((alarmTableType) => {
            if (alarmTableType.nodeId === alarmType.nodeId) {
                alarmTableType.assetName = newName;
                let changedOutputId = '';
                alarmTableType.nodeId = alarmTableType.nodeId.replace(alarmType.assetName, newName);
                if (alarmType.type === ALARM_TYPE.SPECIFIC) {
                    alarmTableType.inputs[0].mappingDetails[0].input.asset = alarmTableType;
                    alarmTableType.inputs[0].mappingDetails[0].input.circleData.id = alarmTableType.inputs[0].mappingDetails[0].input.circleData.id.replace(alarmType.assetName, newName);
                    alarmTableType.inputs[0].mappingDetails[0].input.circleData.path = alarmTableType.inputs[0].mappingDetails[0].input.circleData.path.replace(alarmType.assetName, newName);
                }
                alarmTableType.inputs[0].id = alarmTableType.inputs[0].id.replace(alarmType.assetName, newName);
                alarmTableType.inputs[0].path = alarmTableType.inputs[0].path.replace(alarmType.assetName, newName);
                alarmTableType.outputs.forEach((output) => {
                    if (output.alarmId) {
                        output.alarmId = output.alarmId.replace(alarmType.assetName, newName);
                    }
                    if (output.id) {
                        output.id = output.id.replace(alarmType.assetName, newName);
                        changedOutputId = output.id;
                    }
                });
                updatedtableData.functionTypeDetails.forEach((functionData) => {
                    if (functionData.nodeId === functionType.nodeId) {
                        functionData.alarmInputs.forEach((alarmData) => {
                            if (alarmData.name === alarmType.assetName) {
                                alarmData.name = newName;
                                alarmData.id = alarmData.id.replace(alarmType.assetName, newName);
                                alarmData.path = alarmData.path.replace(alarmType.assetName, newName);
                                alarmData.mappingDetails.forEach((mappingDetail) => {
                                    mappingDetail.output.asset = alarmTableType;
                                    if (changedOutputId !== mappingDetail.output.circleData.id) {
                                        mappingDetail.output.circleData.id = mappingDetail.output.circleData.id.replace(alarmType.assetName, newName);
                                    }
                                    //mappingDetail.output.circleData.path = mappingDetail.output.circleData.path.replace(alarmType.assetName, newName);
                                    //@ts-ignore
                                    mappingDetail.output.circleData.alarmId = `${newName}/${mappingDetail.output.circleData.alarmId.split('/')[1]}`;
                                    mappingDetail.input.circleData.id = alarmData.id;
                                    mappingDetail.input.circleData.path = alarmData.path;
                                });
                            }
                        });
                    }
                });
            }
        });
        yield put(
            updateConfigurationToolJson({
                tableData: {
                    functionTypeDetails: updatedtableData.functionTypeDetails,
                    objectTypeDetails: updatedtableData.objectTypeDetails,
                    alarmTypeDetails: updatedtableData.alarmTypeDetails,
                    eventTypeDetails: updatedtableData.eventTypeDetails,
                },
            })
        );
    } catch (err) {
        console.log(err);
        yield put(handleFunctionDetailsLoading(false));
    }
}

export function* fetchEventsforModelsSaga(action: ReturnType<typeof fetchAlarmsForModels>) {
    try {
        const { modelId } = action.payload;
        const res = yield sce.getEvents(modelId);
        const events: string[] = res.details;
        yield put(
            updateEventsForModels({
                events,
                modelId,
            })
        );
    } catch (err) {
        yield put(handleFunctionDetailsLoading(false));
    }
}

export function* addEventTriggerToFunctionSaga(
    action: ReturnType<typeof addEventTriggerToFunction>
) {
    const { eventDetails, functionType, objectType } = action.payload;
    const [eventId, eventVersion] = eventDetails.eventId.split('@');
    try {
        const res = yield sce.getEventProperties('abb.ability.am.event', eventId, eventVersion);
        const eventProperties = res.details;
        const store: StoreState = yield select();
        store.configurationTool.canvasController &&
            store.configurationTool.canvasController.handleAddEvent({
                eventDetails,
                eventProperties,
                functionType,
                objectType,
            });
    } catch (err) {
        console.log(err);
        yield put(handleFunctionDetailsLoading(false));
    }
}

function* deleteAlarmTriggerToFunctionSaga(
    action: ReturnType<typeof deleteAlarmTriggerToFunction>
) {
    const { inputCircleData, inputAsset, outputCircleData, outputAsset } = action.payload;

    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { overallSeverityFunctionId } = configurationTool;
    const { tableData, privileges } = configurationTool;

    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;

    if (editMode) {
        const asset = tableData.functionTypeDetails.find(
            (asset) => asset.nodeId === inputAsset.nodeId
        );

        if (asset) {
            asset.alarmInputs = asset.alarmInputs.filter((i) => i.id !== inputCircleData.id);
            let isAlarmOutputsConnected =
                outputAsset.outputs.filter((alarmOutput) => {
                    return alarmOutput.connected && alarmOutput.name !== 'alarmId';
                }).length > 0;
            let tableDataAlarmTypes = _.cloneDeep(tableData.alarmTypeDetails);
            if (!isAlarmOutputsConnected) {
                tableDataAlarmTypes = tableDataAlarmTypes.filter((alarmType) => {
                    return alarmType.nodeId !== outputAsset.nodeId;
                });
            }
            yield put(
                updateConfigurationToolJson({
                    tableData: {
                        functionTypeDetails: tableData.functionTypeDetails,
                        objectTypeDetails: tableData.objectTypeDetails,
                        alarmTypeDetails: tableDataAlarmTypes,
                        eventTypeDetails: tableData.eventTypeDetails,
                    },
                })
            );
        }
    }
}

function* deleteEventTriggerToFunctionSaga(
    action: ReturnType<typeof deleteEventTriggerToFunction>
) {
    const { inputCircleData, inputAsset, outputCircleData, outputAsset } = action.payload;

    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { overallSeverityFunctionId } = configurationTool;
    const { tableData, privileges } = configurationTool;

    const editMode =
        configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
        configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT;

    if (editMode) {
        const asset = tableData.functionTypeDetails.find(
            (asset) => asset.nodeId === inputAsset.nodeId
        );

        if (asset) {
            asset.eventInputs = asset.eventInputs.filter((i) => i.id !== inputCircleData.id);
            let isEventOutputsConnected =
                outputAsset.outputs.filter((eventOutput) => {
                    return eventOutput.connected && eventOutput.name !== 'eventId';
                }).length > 0;
            let tableDataEventTypes = _.cloneDeep(tableData.eventTypeDetails);
            if (!isEventOutputsConnected) {
                tableDataEventTypes = tableDataEventTypes.filter((eventType) => {
                    return eventType.nodeId !== outputAsset.nodeId;
                });
            }
            yield put(
                updateConfigurationToolJson({
                    tableData: {
                        functionTypeDetails: tableData.functionTypeDetails,
                        objectTypeDetails: tableData.objectTypeDetails,
                        alarmTypeDetails: tableData.alarmTypeDetails,
                        eventTypeDetails: tableDataEventTypes,
                    },
                })
            );
        }
    }
}

function getConnectionsOfOutputPin(pinId: string, json: LocalJson) {
    const { connectionData } = json;
    const connections: LocalJson['connectionData'] = [];
    connectionData.forEach((connection) => {
        if (connection.output.circleData.id === pinId) {
            connections.push(connection);
        }
    });
    return connections;
}

export function* editNodeIdFunctionSaga(
    action: ReturnType<typeof editAlarmTriggerToFunction>
) {
    const { newName, functionType } = action.payload;

    try {
        const store: StoreState = yield select();
        let updatedtableData = _.cloneDeep(store.configurationTool.tableData);
        updatedtableData.functionTypeDetails.forEach((functionDetail) => {
            if (functionDetail.nodeId === functionType.nodeId) {
                functionDetail.nodeId = newName;
                functionDetail.inputs.forEach((input) => {
                    input.id = input.id.replace(functionType.nodeId, newName);
                    input.path = input.path.replace(functionType.nodeId, newName);
                    input.mappingDetails.forEach((mapping) => {
                        mapping.input.asset.nodeId = newName;
                        mapping.input.circleData.id = input.id;
                        mapping.input.circleData.path = input.path;
                    });
                });
                functionDetail.alarmInputs.forEach((alarmInput) => {
                    alarmInput.id = alarmInput.id.replace(functionType.nodeId, newName);
                    alarmInput.path = alarmInput.path.replace(functionType.nodeId, newName);
                    alarmInput.mappingDetails.forEach((mapping) => {
                        mapping.input.asset.nodeId = newName;
                        mapping.input.circleData.id = alarmInput.id;
                        mapping.input.circleData.path = alarmInput.path;
                    });
                });
                functionDetail.outputs.forEach((output) => {
                    output.id = output.id.replace(functionType.nodeId, newName);
                    output.path = output.path.replace(functionType.nodeId, newName);
                });
            } else {
                functionDetail.inputs.forEach((input) => {
                    input.mappingDetails.forEach((mapping) => {
                        if (mapping.output.asset.nodeId === functionType.nodeId) {
                            mapping.output.asset.nodeId = newName;
                            mapping.output.circleData.id = mapping.output.circleData.id.replace(functionType.nodeId, newName);
                            mapping.output.circleData.path = mapping.output.circleData.path.replace(functionType.nodeId, newName);
                        }
                    });
                });
            }
        });
        yield put(
            updateConfigurationToolJson({
                tableData: {
                    functionTypeDetails: updatedtableData.functionTypeDetails,
                    objectTypeDetails: updatedtableData.objectTypeDetails,
                    alarmTypeDetails: updatedtableData.alarmTypeDetails,
                    eventTypeDetails: updatedtableData.eventTypeDetails,
                },
            })
        );
    } catch (err) {
        console.log(err);
        yield put(handleFunctionDetailsLoading(false));
    }
}

export function* editAliasNameInModelsSaga(
    action: ReturnType<typeof editAliasNameInModels>
) {
    const { aliasName, functionType, ioName } = action.payload;

    try {
        const store: StoreState = yield select();
        let updatedtableData = _.cloneDeep(store.configurationTool.tableData);
        updatedtableData.functionTypeDetails.forEach((functionDetail) => {
            if (functionDetail.nodeId === functionType.nodeId) {
                if (aliasName) {
                    functionDetail.alias[ioName] = {
                        value: aliasName,
                        dataType: 'string',
                        isDirty: aliasName !== functionType.alias[ioName].value
                    };
                } else {
                    functionDetail.alias[ioName] = {
                        value: ioName,
                        dataType: 'string',
                        isDirty: aliasName !== functionType.alias[ioName].value
                    };
                } 
            }
        });
            yield put(
                updateConfigurationToolJson({
                    tableData: {
                        functionTypeDetails: updatedtableData.functionTypeDetails,
                        objectTypeDetails: updatedtableData.objectTypeDetails,
                        alarmTypeDetails: updatedtableData.alarmTypeDetails,
                        eventTypeDetails: updatedtableData.eventTypeDetails,
                    },
                })
            );
        
    } catch (err) {
        console.log(err);
        yield put(handleFunctionDetailsLoading(false));
    }
}

export default [
    takeLatest(ActionTypes.ADD_CONNECTION_CONFIGURATION_TOOL, addConnectionConfigurationToolSaga),
    takeLatest(
        ActionTypes.REMOVE_CONNECTION_CONFIGURATION_TOOL,
        removeConnectionConfigurationToolSaga
    ),

    takeLatest(
        ActionTypes.TRIGGER_CONNECTION_CONFIGURATION_TOOL,
        triggerConnectionConfigurationToolSaga
    ),
    takeLatest(
        ActionTypes.ADD_DEFAULT_VALUE_CONFIGURATION_TOOL,
        addDefaultValueConfigurationToolSaga
    ),
    takeLatest(ActionTypes.PUBLISH_OUTPUT_CONFIGURATION_TOOL, publishOutputConfigurationToolSaga),
    takeLatest(ActionTypes.PUBLISH_ALARM_CONFIGURATION_TOOL, publishAlarmConfigurationToolSaga),
    takeLatest(ActionTypes.ADD_ASSET_OBJECT_REQUEST, handleAssetDrop),
    takeLatest(ActionTypes.HANDLE_INSTANCE_DROP_ACTION, handleInstanceDrop),
    takeLatest(ActionTypes.REMOVE_OVERALL_SEVERITY_FUNCTION, removSeverityFunctionFromCanvas),
    takeLatest(ActionTypes.UPDATE_CONFIGURATION_TOOL_MODE, handleConfigurationToolModel),
    takeLatest(ActionTypes.GET_SELECTED_FUNCTION_DETAIL, handleSelectedFunction),

    takeLatest(
        ActionTypes.GET_ORIGINAL_FUNCTION_DATA_FROM_JSON,
        handleGetOriginalFunctionDataFromJson
    ),
    takeLatest(ActionTypes.GET_ORIGINAL_FUNCTION_TYPES, getOriginalFunctionTypesMain),
    takeLatest(ActionTypes.SET_ORIGINAL_FUNCTION_TYPES, setOriginalFunctionTypesSaga),
    takeLatest(ActionTypes.GET_LATEST_FUNCTIONS_FOR_TYPE, getLatestFunctionsForTypeMain),
    takeLatest(
        ActionTypes.UPDATE_CURRENT_SELECTED_FUNCTION_WITH_LATEST_DATA,
        refreshFunctionWithLatestData
    ),
    takeLatest(ActionTypes.UPDATE_TYPE_WITH_LATEST_FUNCTIONS, updateTypeWithLatestFunctionsSaga),
    takeLatest(ActionTypes.INITIALIZE_CONFIGURATION_TOOL, initializeConfigurationToolSaga),
    takeLatest(ActionTypes.FETCH_MODELS_FOR_TRIGGERS, fetchModelsForTriggersSaga),
    takeLatest(ActionTypes.FETCH_ALARMS_FOR_MODELS, fetchAlarmsforModelsSaga),
    takeLatest(ActionTypes.ADD_ALARM_TRIGGER_TO_FUNCTION, addAlarmTriggerToFunctionSaga),
    takeLatest(ActionTypes.EDIT_ALARM_TRIGGER_TO_FUNCTION, editAlarmTriggerToFunctionSaga),
    takeLatest(ActionTypes.EDIT_NODE_ID_OF_FUNCTION, editNodeIdFunctionSaga),
    takeLatest(ActionTypes.EDIT_ALIAS_NAME_IN_MODELS, editAliasNameInModelsSaga),
    takeLatest(ActionTypes.DELETE_ALARM_TRIGGER_TO_FUNCTION, deleteAlarmTriggerToFunctionSaga),
    takeLatest(ActionTypes.DELETE_EVENT_TRIGGER_TO_FUNCTION, deleteEventTriggerToFunctionSaga),
    takeLatest(ActionTypes.FETCH_EVENTS_FOR_MODELS, fetchEventsforModelsSaga),
    takeLatest(ActionTypes.ADD_EVENT_TRIGGER_TO_FUNCTION, addEventTriggerToFunctionSaga),

    takeLatest(
        ActionTypes.REFRESH_DEFAULT_VALUE_CONFIGURATION_TOOL,
        refreshDefaultValueConfigurationToolSaga
    ),
];
