import {
    isNilOrEmpty,
    abbSDKGetErrorMessage,
    getMultipleSelectedInstances,
    abbSDKGetInstanceConfigErrorMessage,
} from "../../utils/helpers";
import { StoreState } from "../index";
import { takeLatest, put, select, call } from "redux-saga/effects";
import { ActionTypes } from "../actionTypes";
import {
    getAssetCategoryListActionRequest,
    getAssetCategoryListActionSuccess,
    getAssetCategoryListActionFailure,
    handleCategorySelectionAction,
    getSystemsActionRequest,
    setTreeListNodesAction,
    getEdgesActionRequest,
    geObjectBytEdgesActionRequest,
    handleTreeListNodeToggleAction,
    updateNormalizedTreeListNode,
    getStructuresActionSuccess,
    getStructuresActionRequest,
    getStructuresActionFailure,
    getSystemStructuresActionFailure,
    getSystemStructuresActionRequest,
    getSystemStructuresActionSuccess,
    getAssetInstanceListActionRequest,
    getAssetInstanceListActionSuccess,
    setSelectedTreeNodeAction,
    handleAssetInstanceActionMenuClick,
    handleAssetToggle,
    handleAssetInstanceActionMenuClickSuccess,
    handleAssetInstanceActionMenuClickFailure,
    updateSystemStructureModels,
    updateConnectDetailsFromIdentityModels,
    updateGroupBy,
    getSystemStructureModel,
    getConnectDetailsFromIdentityModel,
    getNewSystemStructuresActionRequest,
    handleNewTreeListNodeToggleAction,
    getSystemsBasedOnConnectModelRequest,
    getSystemsActionFailure,
    getNewAssetInstanceListActionRequest,
    searchAsset,
    updateAssetPermission,
    updateFilterAssetModelType,
    getAllPermisssionForAssetAction,
    handleInstanceMonitoring,
    deleteCustomVariableRequest,
    updateEdgeList,
    restoreEdgeRequest,
    handleAssetTableLoading,
    updateStructure,
    getConnectivityEdgesActionSuccess,
    getCleanUpInstancesRequest,
    removeCleanUpInstances,
    getCleanUpInstancesSuccess,
    removeCleanUpInstancesLoading,
} from "./actions";
import {
    getSelectedCategoryTypeInAssetSelector,
    getNormalizedTreeNodeSelector,
    selectedTreeNodeSelector,
    getSelectedAssetInstanceSelector,
    getVirtualTreeSelector,
} from "./selector";
import { sce as AbbSDK, sce } from "sce-engg-model-19.09";
// import { sce as AbbSDK } from '../../../services/AbbSDK/lib';
import {
    mapAssetsCategoryListModel,
    mapSystemModel,
    mapStructureModel,
    mapTreeModel,
    mapStructureToTreeNode,
} from "./model";
import { normalize } from "normalizr";
import { assetTreeNodeSchema } from "../../utils/schemas";
import _ from "lodash";
import { mergeNormalizedTreeNodes } from "./utils";
import { TreeNodeType } from "./types";
import { node } from "prop-types";
import {
    getAssetTableDataStore,
    handleAssetConfigureBtnClickAction,
    handleTableTotalRecords,
    updateSelectedMenuItem,
    updateInstanceMonitoringList,
    updateIdentyConfigStatus,
} from "../instanceConfig/actions";
import { ACTION_MENU_VALUE } from "../instanceConfig/types";
import { showNotificationModal } from "../notificationModal/action";
import AssetCriticalityModal from "../../routes/Assets/AssetCriticalityModal";
import {
    NOTIFICATION_MODAL_STATUS,
    GROUPING_IDENTIFIER,
    NAME_LOOKUP_MODEL,
    MIN_RECORDS,
    MAX_RECORDS,
    GROUP_BY_SYSTEM,
    GROUP_BY_STRUCTURE,
    ASSET_CRITICALITY,
    DEFAULT_CONTROL_STRUCTURE,
    DEFAULT_TREE_LIST_NODE,
    DEFAULT_TREE_VIRTUAL_LIST_NODE,
    OPCUA_MODEL,
    STRUCTURE_IDENTITY,
} from "../../utils/constants/appConstants";
import { CREATE_NEW_MODEL, VIEW_BY_LIST } from "../../utils/constants/uiConstants";
import { showModal } from "../modal/action";
import AppSettings from "../../services/AppSettings";
import { getInfoModelInstancesRequest } from "../settings/action";

function* getAssetCategoryList() {
    try {
        // let categoryList = yield AbbSDK.getViewBy();

        const mappedCategoryList = mapAssetsCategoryListModel(VIEW_BY_LIST);
        yield put(getAssetCategoryListActionSuccess(mappedCategoryList));

        // select 0 position category
        yield put(handleCategorySelectionAction(mappedCategoryList[0]));
    } catch (err) {
        console.log(err);
        yield put(getAssetCategoryListActionFailure());
    }
}
function* handleAssetCategoryChange() {
    try {
        const store: StoreState = yield select();
        const systemCategorySelected = store.assets.systemCategorySelected;
        console.log(
            "%c systemCategorySelected ",
            "background: lime; color: black",
            systemCategorySelected
        );

        if (systemCategorySelected) {
            // make system api call
            // yield put(getSystemsActionRequest());
            // yield put(getNewSystemStructuresActionRequest(null));
        } else {
            // make edge api call
            yield put(getEdgesActionRequest());
        }
    } catch (err) {
        console.log(
            "%c err inside handleAssetCategoryChange",
            "background: lime; color: black",
            err
        );
        // handle error handling
    }
}

function* getSystemsList() {
    try {
        let systemList = yield AbbSDK.getSystems(); // fetching of asset side bar data from system list .
        console.log(
            "%c systemList ",
            "background: lime; color: black",
            systemList
        );
        if (systemList && systemList.details) {
            const rootTreeNodes = mapSystemModel(systemList.details, null);
            const normalizedNodes = normalize(systemList.details, [
                assetTreeNodeSchema,
            ]);
            const treeNodes = mapTreeModel(rootTreeNodes);
            yield put(
                setTreeListNodesAction({
                    node: rootTreeNodes,
                    normalizeNode: {
                        byId: _.get(
                            normalizedNodes,
                            "entities.assetTreeNode",
                            {}
                        ),
                        entities: _.get(normalizedNodes, "result", {}),
                    },
                    treeVirtualNodes: treeNodes,
                })
            );
        }
    } catch (err) {
        console.log(
            "%c err inside getSystemsList ",
            "background: salmon; color: black",
            err
        );
    }
}
function* getEdgesList() {
    try {
        let edgesList = yield AbbSDK.getEdges();
        console.log(
            "%c edgesList ",
            "background: lime; color: black",
            edgesList
        );
        if (
            edgesList &&
            edgesList.details &&
            Array.isArray(edgesList.details)
        ) {
            yield put(updateEdgeList(edgesList.details));
        }
    } catch (err) {
        console.log(
            "%c err inside getEdgesList ",
            "background: salmon; color: black",
            err
        );
        yield put(updateEdgeList([]));
    }
}

function* getConnectivityEdgesList() {
    try {
        let edgesList = yield AbbSDK.getDevicesConnectivityType();
        console.log(
            "%c edgesList ",
            "background: lime; color: black",
            edgesList
        );
        if (
            edgesList &&
            edgesList.details &&
            Array.isArray(edgesList.details)
        ) {
            yield put(getConnectivityEdgesActionSuccess(edgesList.details));
        }
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
        })
     );
    }
}

function* getCleanUpInstances(action: ReturnType<typeof getCleanUpInstancesRequest>) {
    let edgeId = action.payload;
    try {
        const res = yield AbbSDK.getSCECleanupInstances(edgeId);
        
        yield put(getCleanUpInstancesSuccess(res.details))
        if(!res.details.length) {
            yield put(
                showNotificationModal({
                    title: 'No Instances Available',
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: 'banner',
                })
            );
        }
        
        
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
        })
     );
    }
}

function* deleteCleanUpInstances(action: ReturnType<typeof removeCleanUpInstances>) {
    const {multipleInstances, edgeId} = action.payload;
    try {
        yield put(removeCleanUpInstancesLoading(true));
        yield AbbSDK.deleteMultipleInstances([...multipleInstances],AppSettings.IsCOD);
        const res = yield AbbSDK.getSCECleanupInstances(edgeId);
        yield put(getCleanUpInstancesSuccess(res.details))
        yield put(removeCleanUpInstancesLoading(false));
        yield put(
            showNotificationModal({
                title: 'Selected Instances Deleted Succesfully',
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: 'banner',
            }))
        
        
        
    } catch (error) {
        yield put(removeCleanUpInstancesLoading(false));
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
        })
     );
    }
}

function* getObjectByEdges(
    action: ReturnType<typeof geObjectBytEdgesActionRequest>
) {
    try {
        if (isNilOrEmpty(action.payload.objectId)) {
            return;
        }
        let edgeObjectList = yield AbbSDK.getObjectByEdge(
            action.payload.objectId
        );
        console.log(
            "%c edgeObjectList ",
            "background: lime; color: black",
            edgeObjectList
        );
    } catch (err) {
        console.log(
            "%c err inside getObjectByEdges ",
            "background: salmon; color: black",
            err
        );
    }
}

// todo
// added type
function* addChildNodesToParentNode(node: TreeNodeType, childNodes: any) {
    let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);
    console.log("normalizeTreeNodeList", normalizeTreeNodeList);
    console.log("node", node);
    node.loading = false;

    node.expanded =
        Array.isArray(childNodes) &&
            childNodes.length > 0 &&
            !childNodes[0].isLeaf
            ? !!node.expanded
            : false;

    // set isLeafNode property here rather in tree component
    node.isLeaf =
        Array.isArray(childNodes) && childNodes.length > 0
            ? childNodes[0].isLeaf
            : false;
    node.children = !node.isLeaf ? childNodes : [];

    let mappedNodes = mapTreeModel(node);
    const treeVirtualList = yield select(getVirtualTreeSelector);
    let totalNodeList = mapStructureToTreeNode(
        treeVirtualList,
        mappedNodes.id,
        mappedNodes
    );

    const normalizedBasePlans = normalize(childNodes, [assetTreeNodeSchema]);
    console.log("normalizedBasePlans", normalizedBasePlans);

    normalizeTreeNodeList = mergeNormalizedTreeNodes(
        normalizeTreeNodeList,
        _.get(normalizedBasePlans, "entities.assetTreeNode", {}),
        _.get(normalizedBasePlans, "entities.result", [])
    );

    yield put(updateNormalizedTreeListNode(node));
}

function* getStructures(action: ReturnType<typeof getStructuresActionRequest>) {
    let node = _.get(action, "payload.data", null);
    try {
        if (node) {
            let systemStructures = yield AbbSDK.getStructures();
            console.log("node modelId :", node.modelId);
            // let systemStructures = yield AbbSDK.querySystemStructureObjects(
            //     node.modelId,
            //     node.objectId,
            //     false,
            //     false
            // );
            console.log("systemStructures -", systemStructures);
            const mappedNodes = mapStructureModel(
                systemStructures.details,
                node
            );
            console.log("mappedNodes", mappedNodes);
            yield addChildNodesToParentNode(node, mappedNodes);

            let normalizeTreeNodeList = yield select(
                getNormalizedTreeNodeSelector
            );

            yield put(
                getStructuresActionSuccess({
                    structures: systemStructures.details,
                    normalizeTreeNodeList,
                })
            );
        }
    } catch (err) {
        yield addChildNodesToParentNode(node, []);
        yield put(getStructuresActionFailure());
    }
}
function* getSystemStructures(
    action: ReturnType<typeof getSystemStructuresActionRequest>
) {
    let node = _.get(action, "payload.data", null);
    try {
        if (node) {
            console.log("node --", node);
            const systemId = node.parentId;
            const modelId = node.modelId;
            if (systemId && modelId) {
                let systemStructures = null;
                if (node.level === 3) {
                    // systemStructures = yield AbbSDK.systemStructureObjects(
                    //     node.parentId,
                    //     node.modelId
                    // );
                    console.log("node.level huuhuyguygy", node);
                    console.log("node :", node);

                    systemStructures = yield AbbSDK.querySystemStructureObjects(
                        node.modelId,
                        node.parentId,
                        false,
                        false
                    );

                    console.log("systemStructures -", systemStructures);
                } else {
                    console.log("child objects", node.level);
                    console.log("nodelink", [
                        node.link,
                        node.modelId,
                        node.structureModelId,
                    ]);

                    // systemStructures = yield AbbSDK.systemStructureChildObjects(
                    //     node.objectId,
                    //     node.modelId
                    // );
                    systemStructures = yield AbbSDK.querySystemStructureObjects(
                        node.structureModelId,
                        node.link,
                        false,
                        false
                    );

                    console.log("systemStructures -- :", systemStructures);
                }

                const mappedNodes = mapStructureModel(
                    systemStructures.details,
                    node
                );
                yield addChildNodesToParentNode(node, mappedNodes);

                let normalizeTreeNodeList = yield select(
                    getNormalizedTreeNodeSelector
                );

                yield put(
                    getSystemStructuresActionSuccess({
                        systemStructures: systemStructures.details,
                        normalizeTreeNodeList,
                    })
                );
            }
        }
    } catch (err) {
        console.log(err);
        yield addChildNodesToParentNode(node, []);
        yield put(getSystemStructuresActionFailure());
    }
}

function* handleTreeListNodeToggle(
    action: ReturnType<typeof handleTreeListNodeToggleAction>
) {
    console.log("%c node ", "background: lime; color: black", action.payload);
    try {
        const systemCategorySelected = yield select(
            getSelectedCategoryTypeInAssetSelector
        );

        // console.log("systemCategorySelected :", systemCategorySelected);
        let node = _.get(action, "payload.data", null);
        const nodeLevel = _.get(action, "payload.data.level", null);

        if (systemCategorySelected && nodeLevel && node) {
            node.toggled = !node.toggled;
            if (node.toggled) {
                node.loading = true;
            } else if (!node.toggled) {
                node.expanded = false;
            }
            yield put(updateNormalizedTreeListNode(node));

            if (nodeLevel === 2 && node.toggled) {
                yield put(getStructuresActionRequest(node));
            }
            if (nodeLevel >= 2 && node.toggled) {
                yield put(getSystemStructuresActionRequest(node));
            }
        } else {
            node.toggled = !node.toggled;
            yield put(updateNormalizedTreeListNode(node));
        }
    } catch (err) {
        console.log(
            "%c err inside getObjectByEdges ",
            "background: salmon; color: black",
            err
        );
    }
}

function* getAssetInstanceList(
    action: ReturnType<typeof getAssetInstanceListActionRequest>
) {
    let { node } = action.payload;

    const { instanceConfig }: StoreState = yield select();

    const { pagination } = instanceConfig.instancesTable;
    let activePage = action.payload.activePage || pagination.activePage;
    let enteriesPerPage =
        action.payload.enteriesPerPage || pagination.enteriesPerPage;
    let includeAllChild =
        action.payload.includeAllChild ||
        instanceConfig.instancesTable.includeChildElements;

    const systemCategorySelected = yield select(
        getSelectedCategoryTypeInAssetSelector
    );
    try {
        if (node) {
            const systemId = node.parentId;
            const modelId = node.modelId;
            let assetInstanceList = {
                details: [],
            };
            const previousSelectedTreeNode = yield select(
                selectedTreeNodeSelector
            );
            if (previousSelectedTreeNode) {
                previousSelectedTreeNode.active = false;
                yield put(
                    updateNormalizedTreeListNode(previousSelectedTreeNode)
                );
            }
            //@ts-ignore
            node.active = true;
            yield put(updateNormalizedTreeListNode(node));
            yield put(setSelectedTreeNodeAction(node));

            if (systemCategorySelected && systemId && modelId) {
                if (node.level === 3) {
                    // assetInstanceList = yield AbbSDK.systemStructureObjectsDetails(
                    //     node.parentId,
                    //     node.modelId
                    // );
                    // assetInstanceList = yield AbbSDK.querySystemStructureObjects(
                    //     node.modelId || "",
                    //     node.parentId || "",
                    //     !!includeAllChild,
                    //     true
                    // );

                    console.log(
                        "requesting with pagination",
                        activePage,
                        enteriesPerPage
                    );
                    assetInstanceList =
                        yield AbbSDK.queryStructureObjectsPaging(
                            node.modelId || "",
                            node.parentId || "",
                            !!includeAllChild,
                            true,
                            0,
                            activePage,
                            enteriesPerPage
                        );

                    console.log("node -", node);
                    console.log("assetInstanceList", assetInstanceList);
                } else {
                    console.log("node level", node.level);
                    // assetInstanceList = yield AbbSDK.systemStructureChildObjectsDetails(
                    //     node.objectId || "",
                    //     node.modelId || ""
                    // );

                    assetInstanceList =
                        yield AbbSDK.queryStructureObjectsPaging(
                            //@ts-ignore
                            node.structureModelId || "",
                            //@ts-ignore
                            node.link || "",
                            !!includeAllChild,
                            true,
                            0,
                            activePage,
                            enteriesPerPage
                        );

                    // assetInstanceList = yield AbbSDK.querySystemStructureObjects(
                    //     node.modelId ,
                    //     node.parentId
                    // )
                    console.log("node -", node);
                    console.log("assetInstanceList", assetInstanceList);
                }
            } else if (!systemCategorySelected && node.objectId) {
                // assetInstanceList = yield AbbSDK.getObjectByEdge(node.objectId);
                console.log("obj node :", node);
                try {
                    assetInstanceList = yield AbbSDK.getObjectByEdgeWithPaging(
                        node.objectId,
                        0,
                        activePage,
                        enteriesPerPage
                    );
                    console.log("assetInstanceList :", assetInstanceList);
                } catch (err) {
                    console.log("error edge :", err);
                }
            }

            yield put(
                getAssetInstanceListActionSuccess({
                    ...assetInstanceList.details,
                    activePage,
                })
            );
        }
    } catch (err) {
        yield showNotificationError(err);
    }
}

function* handleAssetTableActionMenu(
    action: ReturnType<typeof handleAssetInstanceActionMenuClick>
) {
    try {
        const actionMenu = action.payload.data;

        yield put(updateSelectedMenuItem(actionMenu.value));
        yield put(updateInstanceMonitoringList([]));

        let activeActionMenu = "";
        if (actionMenu.value === ACTION_MENU_VALUE.configure) {
            yield put(handleAssetConfigureBtnClickAction());
            return;
        } else if (
            actionMenu.value === ACTION_MENU_VALUE.enableMonitoringStatus
        ) {
            activeActionMenu = ASSET_CRITICALITY.ENABLE;
        } else if (
            actionMenu.value === ACTION_MENU_VALUE.disableMonitoringStatus
        ) {
            activeActionMenu = ASSET_CRITICALITY.DISABLE;
        } else if (actionMenu.value === ACTION_MENU_VALUE.delete) {
            activeActionMenu = ASSET_CRITICALITY.DELETE;
        }

        yield put(
            showModal({
                component: AssetCriticalityModal,
                modalTitle: activeActionMenu,
                data: {
                    submitBtnText: CREATE_NEW_MODEL.ASSET_CREATE_TEXT,
                },
            })
        );
    } catch (err) {
        yield put(handleAssetInstanceActionMenuClickFailure());
        yield showNotificationError(err);
    }
}

// function* handleAssetInstanceMonitoring(
//     action: ReturnType<typeof handleInstanceMonitoring>
// ) {
// //     try {
//         yield put(handleAssetTableLoading(true));
//         const store: StoreState = yield select();
//         const selectedMenuItem = store.instanceConfig.selectedMenuItem;
//         // const selectedInstances = yield select(
//         //     getSelectedAssetInstanceSelector
//         // );
//         const selectedTreeNode = store.assets.selectedTreeNode;
//         // Loader missing.

//         let successMessage: string = "";
//         let response: any;
//         const instanceMonitoringList =
//             store.instanceConfig.instanceMonitoringList;
//         const multipleSelectedInstances = getMultipleSelectedInstances(
//             instanceMonitoringList,
//             store.instanceConfig.instancesTable.tableData.byId
//         );

//         switch (selectedMenuItem) {
//             case ACTION_MENU_VALUE.enableMonitoringStatus:
//                 response =
//                     yield AbbSDK.enableSingleOrMultipleMonitoringInstances([
//                         ...multipleSelectedInstances,
//                     ]);
//                 successMessage =
//                     "Enabled monitoring for all selected instances";
//                 break;

//             case ACTION_MENU_VALUE.disableMonitoringStatus:
//                 response =
//                     yield AbbSDK.disableSingleOrMultipleMonitoringInstances([
//                         ...multipleSelectedInstances,
//                     ]);
//                 successMessage =
//                     "Disabled monitoring for all selected instances";

//                 break;

//             case ACTION_MENU_VALUE.delete:
//                 response = yield AbbSDK.deleteMultipleInstances(
//                     [...multipleSelectedInstances],
//                     AppSettings.IsCOD
//                 );
//                 successMessage = "Deleted all selected instances";
//                 break;

//             default:
//                 break;
//         }
//         yield put(
//             showNotificationModal({
//                 title: successMessage,
//                 resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
//                 type: "banner",
//             })
//         );
//         if (selectedTreeNode) {
//             yield put(
//                 getNewAssetInstanceListActionRequest({
//                     node: selectedTreeNode,
//                 })
//             );
//         } else {
//             // yield put(handleAssetTableLoading(true));
//             const selectedStructure =
//                 store.assets.assetStructureDetails.selectedStructure;
//             const { pagination } = store.instanceConfig.instancesTable;
//             let activePage = pagination.activePage;
//             if (selectedStructure) {
//                 yield put(updateStructure(selectedStructure));
//                 yield put(
//                     getNewAssetInstanceListActionRequest({
//                         node: null as any,
//                         activePage,
//                     })
//                 );
//             }
//         }
//     } catch (err) {
//         yield put(handleAssetTableLoading(false));
//         console.log("error --- ", err);
//         yield put(handleAssetInstanceActionMenuClickFailure());
//         yield put(
//             showNotificationModal({
//                 title: "API error",
//                 resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
//                 type: "confirmation",
//                 details: abbSDKGetInstanceConfigErrorMessage(err),
//                 // details: [abbSDKGetErrorMessage(error)],
//             })
//         );
//     }
// }

// Asset structure details
function* updateGroupByField(action: ReturnType<typeof updateGroupBy>) {
    console.log(
        "%c updateGroupBy ",
        "background: lime; color: black",
        action.payload
    );
    try {
        let groupBy = action.payload.data;
        if (groupBy === "Structure") {
            yield put(getSystemStructureModel());
        } else if (groupBy === "Connect") {
            yield put(getConnectDetailsFromIdentityModel());
        }
    } catch (err) {
        console.log(
            "%c err inside updateGroupBy ",
            "background: salmon; color: black",
            err
        );
    }
}

function* getSystemStructureModels() {
    console.log(
        "%c getSystemStructureModels ",
        "background: lime; color: black"
    );
    try {
        const hasSystemStructure = yield AbbSDK.hasPermission(
            AbbSDK.getSystemStructureModelsForSpecificTenant
        );
        const response = hasSystemStructure
            ? yield AbbSDK.getSystemStructureModelsForSpecificTenant()
            : null;
        if (
            response &&
            Array.isArray(response.details) &&
            response.details.length
        ) {
            const index = response.details.findIndex(
                (x: any) => x.model === DEFAULT_CONTROL_STRUCTURE
            );
            if (index >= 0) {
                response.details.unshift(
                    response.details.splice(
                        response.details.findIndex(
                            (x: any) => x.model === DEFAULT_CONTROL_STRUCTURE
                        ),
                        1
                    )[0]
                );
            }
            yield put(updateSystemStructureModels(response.details));
        } else {
            yield put(updateSystemStructureModels([]));
        }
    } catch (err) {
        console.log(
            "%c err inside getSystemStructureModels ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationError(err);
    }
}

function* getConnectDetailsFromIdentityModels() {
    console.log(
        "%c getConnectDetailsFromIdentityModels ",
        "background: lime; color: black"
    );
    try {
        const hasSystemStructure = yield AbbSDK.hasPermission(
            AbbSDK.getConnectDetailsFromIdentityModels
        );
        if (hasSystemStructure) {
            const response = yield AbbSDK.getConnectDetailsFromIdentityModels();
            yield put(updateConnectDetailsFromIdentityModels(response.details));
        } else {
            yield put(updateConnectDetailsFromIdentityModels([]));
        }
    } catch (err) {
        console.log(
            "%c err inside getConnectDetailsFromIdentityModels ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationError(err);
    }
}

// TO DO - fetch new system list based on 800xA/OPC UA
function* getSystemsBasedOnConnectModels(
    action: ReturnType<typeof getSystemsBasedOnConnectModelRequest>
) {
    let node = _.get(action, "payload.data", null);
    try {
        let systems = null;
        const store: StoreState = yield select();
        const assetStructureDetails = store.assets.assetStructureDetails;
        if (!node) {
            const hasSystemAccess = yield AbbSDK.hasPermission(
                AbbSDK.getSystemsBasedOnConnectModel
            );
            if (hasSystemAccess) {
                systems = yield AbbSDK.getSystemsBasedOnConnectModel(
                    assetStructureDetails.selectedSystem
                        ? assetStructureDetails.selectedSystem.baseModel
                        : "abb.controlSystem.system"
                );
                const rootTreeNodes = mapSystemModel(systems.details, null);
                const normalizedNodes = normalize(systems.details, [
                    assetTreeNodeSchema,
                ]);
                const treeNodes = mapTreeModel(rootTreeNodes);
                yield put(
                    setTreeListNodesAction({
                        node: rootTreeNodes,
                        normalizeNode: {
                            byId: _.get(
                                normalizedNodes,
                                "entities.assetTreeNode",
                                {}
                            ),
                            entities: _.get(normalizedNodes, "result", {}),
                        },
                        treeVirtualNodes: treeNodes,
                    })
                );
            }
            console.log("hasSystemAccess -", hasSystemAccess);
            console.log("systems -", systems);
        } else {
            // New browsing API changes to get strcture level data
            if (node && node.level == 2) {
                systems =
                    yield AbbSDK.getSystemStructureModelNamesAndIdsForTenantExt2(
                        node.objectId,
                        assetStructureDetails.selectedSystem!.groupBy
                    );
                const mappedNodes = mapStructureModel(systems.details, node);
                yield addChildNodesToParentNode(node, mappedNodes);
            } else {
                let link = "";
                if (node.level == 3) {
                    link =
                        assetStructureDetails.selectedSystem!.nameLookupModel.toLowerCase() ===
                            OPCUA_MODEL
                            ? `${node.modelId}/${node.parentId}/objects`
                            : `${node.modelId}/${node.parentId}`;
                } else {
                    link = node.link;
                }
                systems = yield AbbSDK.getChildObjects(
                    link,
                    GROUP_BY_SYSTEM,
                    assetStructureDetails.selectedSystem!.nameLookupModel,
                    assetStructureDetails.selectedSystem!.groupBy,
                    false,
                    false,
                    MIN_RECORDS,
                    MAX_RECORDS
                );
                const mappedNodes = mapStructureModel(
                    systems.details.data,
                    node
                );
                yield addChildNodesToParentNode(node, mappedNodes);
            }
        }

        let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);
        if (systems) {
            yield put(
                getSystemStructuresActionSuccess({
                    systemStructures: systems.details,
                    normalizeTreeNodeList,
                })
            );
        }

        // yield put(searchAsset(""));
    } catch (err) {
        console.log(
            "%c err inside getSystemsBasedOnConnectModels ",
            "background: salmon; color: black",
            err
        );
        const rootTreeNodes = mapSystemModel([], null);
        const normalizedNodes = normalize([], [assetTreeNodeSchema]);
        const treeNodes = mapTreeModel(rootTreeNodes);
        yield put(
            setTreeListNodesAction({
                node: rootTreeNodes,
                normalizeNode: {
                    byId: _.get(normalizedNodes, "entities.assetTreeNode", {}),
                    entities: _.get(normalizedNodes, "result", {}),
                },
                treeVirtualNodes: treeNodes,
            })
        );
        yield put(getSystemsActionFailure());
        yield showNotificationError(err);
    }
}

function* getNewSystemStructures(
    action: ReturnType<typeof getNewSystemStructuresActionRequest>
) {
    let node = _.get(action, "payload.data", null);
    try {
        let systemStructures = null;
        const store: StoreState = yield select();
        const assetStructureDetails = store.assets.assetStructureDetails;
        let configStatus = {...store.instanceConfig.configStatus};
        let configuredIdentities = store.settings.identityModel.map((model) => {
            //configStatus[model.properties.nameLookupModel] = true;
            return (model.properties.nameLookupModel && model.properties.nameLookupModel.value) || "";
        });
        if(configuredIdentities.length === 0){
            const responseIdentity = yield sce.getInfoModelInstances(STRUCTURE_IDENTITY.MODEL);
            configuredIdentities = responseIdentity.details.map((model: any) => {
                //configStatus[model.properties.nameLookupModel] = true;
                return (model.properties.nameLookupModel && model.properties.nameLookupModel.value) || "";
            });
        }
        configuredIdentities.forEach((identity) => {
            configStatus[identity] = true;
        });
        let identitiesToCheck = {};
        if (!node) {
            const hasSystemStructureAccess = yield AbbSDK.hasPermission(
                AbbSDK.getChildObjects
            );
            if (hasSystemStructureAccess) {
                systemStructures = yield AbbSDK.getChildObjects(
                    assetStructureDetails.selectedStructure
                        ? assetStructureDetails.selectedStructure.model
                        : DEFAULT_CONTROL_STRUCTURE,
                    GROUP_BY_STRUCTURE,
                    "",
                    "",
                    false,
                    false,
                    MIN_RECORDS,
                    MAX_RECORDS
                );
                const count = _.get(systemStructures, "details.count", 0);
                if (count !== 0) {
                    const rootTreeNodes = mapSystemModel(
                        systemStructures.details.data,
                        null
                    );
                    if(rootTreeNodes){
                        rootTreeNodes.children && rootTreeNodes.children.forEach((childNode: any) => {
                            if(childNode && childNode.modelId && !configStatus[childNode.modelId]){
                                identitiesToCheck[childNode.modelId] = 1;
                            }
                        });
                    }
                    const normalizedNodes = normalize(
                        systemStructures.details.data,
                        [assetTreeNodeSchema]
                    );
                    const treeNodes = mapTreeModel(rootTreeNodes);
                    yield put(
                        setTreeListNodesAction({
                            node: rootTreeNodes,
                            normalizeNode: {
                                byId: _.get(
                                    normalizedNodes,
                                    "entities.assetTreeNode",
                                    {}
                                ),
                                entities: _.get(normalizedNodes, "result", {}),
                            },
                            treeVirtualNodes: treeNodes,
                        })
                    );
                }
                if (count === 0) {
                    yield put(
                        setTreeListNodesAction({
                            node: _.cloneDeepWith(DEFAULT_TREE_LIST_NODE),
                            normalizeNode: {
                                byId: {},
                                entities: [],
                            },
                            treeVirtualNodes: _.cloneDeepWith(
                                DEFAULT_TREE_VIRTUAL_LIST_NODE
                            ),
                        })
                    );
                }
                console.log(
                    "hasSystemStructureAccess -",
                    hasSystemStructureAccess
                );
                console.log("systemStructures -", systemStructures);
                console.log("systemStructures -", systemStructures);
            }
        } else {
            // Need to check for child node permission
            let link = `${assetStructureDetails.selectedStructure
                ? assetStructureDetails.selectedStructure.model
                : DEFAULT_CONTROL_STRUCTURE
                }/${node.objectId}/objects`;
            systemStructures = yield AbbSDK.getChildObjects(
                link,
                GROUP_BY_STRUCTURE,
                "",
                "",
                false,
                false,
                MIN_RECORDS,
                MAX_RECORDS
            );
            const mappedNodes = mapStructureModel(
                systemStructures.details.data,
                node
            );
            if(mappedNodes){
                mappedNodes.children && mappedNodes.children.forEach((childNode: any) => {
                    if(childNode && childNode.modelId && !configStatus[childNode.modelId]){
                        identitiesToCheck[childNode.modelId] = 1;
                    }
                });
            }
            yield addChildNodesToParentNode(node, mappedNodes);
        }

        let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);

        if (systemStructures) {
            yield put(
                getSystemStructuresActionSuccess({
                    systemStructures: systemStructures.details.data,
                    normalizeTreeNodeList,
                })
            );
        }
        try{
            if(Object.keys(identitiesToCheck).length > 0){
                let configStatusResult = yield sce.getIdentityConfigurationStatus(
                    Object.keys(identitiesToCheck) as string[],
                    configuredIdentities as string[]
                );
    
                yield put(updateIdentyConfigStatus(configStatusResult.details.data));
    
                let notConfiguredIdentities: string[] = [];
                Object.keys(identitiesToCheck).forEach((identity) => {
                    if(!configStatusResult.details.data[identity]){
                        notConfiguredIdentities.push(identity);
                    }
                });
    
                if(notConfiguredIdentities.length > 0){
                    yield put(showNotificationModal(
                        {
                            title: "Configuration Error",
                            details: [
                                        "Following Identities is not configured:",
                                        ...(notConfiguredIdentities.map((id, ind) => `${ind + 1}: ${id}`))
                                    ],
                            resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                            type: "confirmation",
                        })
                    );
                }
            } 
        } catch(err){
            yield showNotificationError(err);
        }
        // yield put(searchAsset(""));
    } catch (err) {
        console.log(err);
        const rootTreeNodes = mapSystemModel([], null);
        const normalizedNodes = normalize([], [assetTreeNodeSchema]);
        const treeNodes = mapTreeModel(rootTreeNodes);
        yield put(
            setTreeListNodesAction({
                node: rootTreeNodes,
                normalizeNode: {
                    byId: _.get(normalizedNodes, "entities.assetTreeNode", {}),
                    entities: _.get(normalizedNodes, "result", {}),
                },
                treeVirtualNodes: treeNodes,
            })
        );
        yield put(getSystemStructuresActionFailure());
        yield showNotificationError(err);
    }
}

function* handleNewTreeListNodeToggle(
    action: ReturnType<typeof handleNewTreeListNodeToggleAction>
) {
    console.log("%c node ", "background: lime; color: black", action.payload);
    try {
        let node = _.get(action, "payload.data", null);
        if (node.toggled) {
            node.loading = true;
        }
        if (node && node.toggled) {
            const store: StoreState = yield select();
            const assetStructureDetails = store.assets.assetStructureDetails;
            yield put(updateNormalizedTreeListNode(node));
            if (
                assetStructureDetails &&
                assetStructureDetails.selectedGroupBy === "Connect"
            )
                yield put(getSystemsBasedOnConnectModelRequest(node));
            if (
                assetStructureDetails &&
                assetStructureDetails.selectedGroupBy === "Structure"
            )
                yield put(getNewSystemStructuresActionRequest(node));
        } else {
            // node.toggled = !node.toggled;
            yield put(updateNormalizedTreeListNode(node));
        }
    } catch (err) {
        yield showNotificationError(err);
    }
}

function* getNewAssetInstanceList(
    action: ReturnType<typeof getNewAssetInstanceListActionRequest>
) {
    let { node } = action.payload;
    const { assets }: StoreState = yield select();
    const { instanceConfig }: StoreState = yield select();

    const { pagination } = instanceConfig.instancesTable;
    let activePage = action.payload.activePage || pagination.activePage;
    let enteriesPerPage =
        action.payload.enteriesPerPage || pagination.enteriesPerPage;
    let includeAllChild =
        action.payload.includeAllChild ||
        instanceConfig.instancesTable.includeChildElements;

    const store: StoreState = yield select();
    const assetStructureDetails = store.assets.assetStructureDetails;
    const isConfigurationMode = store.assets.configurationMode;
    try {
        let assetInstanceList = {
            details: [],
        };
        let configStatus = {};
        let configuredIdentities = store.settings.identityModel.map((model) => {
            //configStatus[model.properties.nameLookupModel] = true;
            return (model.properties.nameLookupModel && model.properties.nameLookupModel.value) || "";
        });
        if(configuredIdentities.length === 0){
            const responseIdentity = yield sce.getInfoModelInstances(STRUCTURE_IDENTITY.MODEL);
            configuredIdentities = responseIdentity.details.map((model: any) => {
                //configStatus[model.properties.nameLookupModel] = true;
                return (model.properties.nameLookupModel && model.properties.nameLookupModel.value) || "";
            });
        }
        configuredIdentities.forEach((identity) => {
            configStatus[identity] = true;
        });
        const previousSelectedTreeNode = yield select(selectedTreeNodeSelector);
        if (previousSelectedTreeNode) {
            previousSelectedTreeNode.active = false;
            yield put(updateNormalizedTreeListNode(previousSelectedTreeNode));
        }
        //@ts-ignore
        if (node) {
            node.active = true;
            yield put(updateNormalizedTreeListNode(node));
            yield put(setSelectedTreeNodeAction(node));
        }
        // System Block
        if (
            node &&
            assets.assetStructureDetails &&
            assets.assetStructureDetails.selectedGroupBy === "Connect"
        ) {
            // OPCUA hard code changes as of now
            if (
                node.level == 2 &&
                assets.assetStructureDetails.selectedSystem!.nameLookupModel.toLowerCase() !==
                OPCUA_MODEL
            ) {
                yield put(
                    getAssetInstanceListActionSuccess({
                        ...assetInstanceList.details,
                        activePage,
                    })
                );
                return;
            }
            let link = "";
            if (
                node.level == 3 &&
                assetStructureDetails.selectedSystem!.nameLookupModel.toLowerCase() ===
                OPCUA_MODEL
            ) {
                link = `${node.modelId}/${node.parentId}/objects`;
            } else {
                // Need to check for child node permission
                link =
                    node.level == 3
                        ? `${node.modelId}/${node.parentId}`
                        : `${node.structureModelId ? node.structureModelId : ""
                        }/${node.objectId}/objects`;
            }

            let filterQueryDetails = { ...assets.filterQuery };
            if (
                filterQueryDetails.applyFilter &&
                filterQueryDetails.filteredItems.length > 0
            ) {
                filterQueryDetails.filterOptions =
                    filterQueryDetails.filteredItems.map((x) => {
                        return {
                            name: x.key,
                            values: x.values,
                        };
                    });
                assetInstanceList = yield AbbSDK.filterObjects(
                    link!,
                    GROUP_BY_SYSTEM,
                    assetStructureDetails.selectedSystem!.nameLookupModel,
                    assetStructureDetails.selectedSystem!.groupBy,
                    !!includeAllChild,
                    true,
                    filterQueryDetails.filterOptions,
                    activePage,
                    enteriesPerPage
                );
            } else {
                assetInstanceList = yield AbbSDK.getChildObjects(
                    link!,
                    GROUP_BY_SYSTEM,
                    assetStructureDetails.selectedSystem!.nameLookupModel,
                    assetStructureDetails.selectedSystem!.groupBy,
                    !!includeAllChild,
                    true,
                    activePage,
                    enteriesPerPage
                );
                console.log("node -", node);
                console.log("assetInstanceList", assetInstanceList);
            }
        }
        // Structure Block
        else if (
            assets.assetStructureDetails &&
            assets.assetStructureDetails.selectedGroupBy === "Structure"
        ) {
            const hasSystemStructureAccess = yield AbbSDK.hasPermission(
                AbbSDK.getChildObjects
            );
            if (hasSystemStructureAccess) {
                let link = "";
                if (!node) {
                    link = assetStructureDetails.selectedStructure
                        ? assetStructureDetails.selectedStructure.model
                        : DEFAULT_CONTROL_STRUCTURE;
                } else {
                    link = `${assets.assetStructureDetails.selectedStructure
                        ? assets.assetStructureDetails.selectedStructure
                            .model
                        : DEFAULT_CONTROL_STRUCTURE
                        }/${node.objectId}/objects`;
                }
                let filterQueryDetails = { ...assets.filterQuery };
                if (
                    filterQueryDetails.applyFilter &&
                    filterQueryDetails.filteredItems.length > 0
                ) {
                    filterQueryDetails.filterOptions =
                        filterQueryDetails.filteredItems.map((x) => {
                            return {
                                name: x.key,
                                values: x.values,
                            };
                        });
                    assetInstanceList = yield AbbSDK.filterObjects(
                        link!,
                        GROUP_BY_STRUCTURE,
                        "",
                        "",
                        !!includeAllChild,
                        true,
                        filterQueryDetails.filterOptions,
                        activePage,
                        enteriesPerPage
                    );
                } else {
                    assetInstanceList = yield AbbSDK.getChildObjects(
                        link,
                        GROUP_BY_STRUCTURE,
                        "",
                        "",
                        !!includeAllChild,
                        true,
                        activePage,
                        enteriesPerPage
                    );
                }

                console.log("node -", node);
                console.log("assetInstanceList", assetInstanceList);
            }
        }

        if (!isConfigurationMode) {
            yield put(
                getAssetInstanceListActionSuccess({
                    ...assetInstanceList.details,
                    activePage,
                })
            );
        } else {
            yield put(handleAssetTableLoading(false));
        }
    } catch (err) {
        yield showNotificationError(err);
    }
}

// Filter Query
function* getFilterAssetModelType() {
    console.log(
        "%c getFilterAssetModelType ",
        "background: lime; color: black"
    );
    try {
        const { assets }: StoreState = yield select();
        const assetTypes = yield AbbSDK.getAssetTypes();
        const assetModelTypes = assets.selectedParentTreeNodeId
            ? yield AbbSDK.getAssetModelTypes(assets.selectedParentTreeNodeId)
            : { details: [] };
        const data = {
            assetModelTypes: assetModelTypes.details,
            assetTypes: assetTypes.details,
        };
        yield put(updateFilterAssetModelType(data));
    } catch (err) {
        console.log(
            "%c err inside getFilterAssetModelType ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationError(err);
    }
}

function* showNotificationError(err: any) {
    const error = abbSDKGetErrorMessage(err);
    yield put(
        showNotificationModal({
            title: "Error",
            details: [error],
            resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
            type: "confirmation",
        })
    );
}

// get All Permissions for Asset
function* getAllPermisssionForAsset(
    action: ReturnType<typeof getAllPermisssionForAssetAction>
) {
    try {
        const hasMonitorInstance = true;    
        const hasDeleteInstance = yield AbbSDK.hasPermission(
            AbbSDK.deleteMultipleInstances
        );
        const hasEnableInstance = yield AbbSDK.hasPermission(
            AbbSDK.enableSingleOrMultipleMonitoringInstances
        );
        const hasDisableInstance = yield AbbSDK.hasPermission(
            AbbSDK.disableSingleOrMultipleMonitoringInstances
        );
        const hasUpdateAvilableInstace = true;
        yield put(
            updateAssetPermission({
                hasMonitorInstance,
                hasDeleteInstance,
                hasEnableInstance,
                hasDisableInstance,
                hasUpdateAvilableInstace,
            })
        );
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

// Custom Variable Delete
function* deleteCustomVariable(
    action: ReturnType<typeof deleteCustomVariableRequest>
) {
    try {
        const { objectId, modelId } = action.payload;
        const response = yield AbbSDK.deleteObject(objectId, modelId);
        yield put(
            showNotificationModal({
                title: "Item Deleted",
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: "banner",
            })
        );
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

// Custom Variable Delete
function* restoreEdge(action: ReturnType<typeof restoreEdgeRequest>) {
    try {
        const edge = action.payload.data;
        if (edge && edge.objectId) {
            const response = yield AbbSDK.restoreSce(edge.objectId);
            yield put(
                showNotificationModal({
                    title: _.get(response, "details.message", "Edge Restored"),
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: "banner",
                })
            );
        }
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

export default [
    takeLatest(ActionTypes.ASSET_CATEGORY_LIST_REQUEST, getAssetCategoryList),
    takeLatest(ActionTypes.ASSET_CATEGORY_SELECTION, handleAssetCategoryChange),
    takeLatest(ActionTypes.GET_SYSTEMS_REQUEST, getSystemsList),
    takeLatest(ActionTypes.GET_EDGES_REQUEST, getEdgesList),
    takeLatest(ActionTypes.GET_CONNECTIVITY_EDGES_REQUEST, getConnectivityEdgesList),
    takeLatest(ActionTypes.GET_OBJECT_BY_EDGES_REQUEST, getObjectByEdges),
    takeLatest(ActionTypes.GET_STRUCTURES_REQUEST, getStructures),
    takeLatest(ActionTypes.GET_SYSTEM_STRUCTURES_REQUEST, getSystemStructures),
    takeLatest(
        ActionTypes.GET_ASSET_INSTANCE_LIST_REQUEST,
        getAssetInstanceList
    ),
    takeLatest(ActionTypes.GET_CLEANUP_INSTANCES_REQUEST, getCleanUpInstances),
    takeLatest(ActionTypes.REMOVE_CLEANUP_INSTANCES, deleteCleanUpInstances),
    takeLatest(
        ActionTypes.GET_ASSET_INSTANCE_LIST_REQUEST,
        getAssetInstanceList
    ),
    takeLatest(
        ActionTypes.HANDLE_TREE_LIST_NODE_TOGGLE,
        handleTreeListNodeToggle
    ),
    takeLatest(
        ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST,
        handleAssetTableActionMenu
    ),
    // takeLatest(
    //     ActionTypes.HANDLE_TREE_LIST_NODE_TOGGLE,
    //     handleTreeListNodeToggle
    // ),

    takeLatest(
        ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST,
        handleAssetTableActionMenu
    ),
    // takeLatest(
    //     ActionTypes.HANDLE_INSTANCE_MONITORING,
    //     handleAssetInstanceMonitoring
    // ),
    takeLatest(
        ActionTypes.GET_SYSTEM_STRUCTURE_MODELS,
        getSystemStructureModels
    ),
    takeLatest(
        ActionTypes.GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS,
        getConnectDetailsFromIdentityModels
    ),
    takeLatest(
        ActionTypes.GET_SYSTEMS_BASED_ON_CONNECT_MODEL,
        getSystemsBasedOnConnectModels
    ),
    takeLatest(
        ActionTypes.GET_NEW_SYSTEM_STRUCTURES_REQUEST,
        getNewSystemStructures
    ),
    takeLatest(
        ActionTypes.HANDLE_NEW_TREE_LIST_NODE_TOGGLE,
        handleNewTreeListNodeToggle
    ),
    takeLatest(
        ActionTypes.GET_NEW_ASSET_INSTANCE_LIST_REQUEST,
        getNewAssetInstanceList
    ),
    takeLatest(ActionTypes.GET_ALL_PERMISSION_ASSET, getAllPermisssionForAsset),
    takeLatest(
        ActionTypes.GET_FILTER_ASSET_MODEL_TYPE_REQUEST,
        getFilterAssetModelType
    ),
    takeLatest(ActionTypes.CUSTOM_VARIABLE_DELETE, deleteCustomVariable),
    takeLatest(ActionTypes.RESTORE_EDGE, restoreEdge),
];
