export const ACTIVE_INPUT_TAB: string = "Input";
export const ACTIVE_OUTPUT_TAB: string = "Output";
export const ACTIVE_ALARM_TAB: string = "Alarm";
export const ACTIVE_TRIGGER_TAB: string = "Triggers";

export const ACTIVE_INPUT_TRIGGER_TAB = "Input Triggers";
export const ACTIVE_TIME_TRIGGER_TAB = "Time Triggers";
export const ACTIVE_ALARM_TRIGGER_TAB = "Alarm Triggers";
export const ACTIVE_EVENT_TRIGGER_TAB = "Event Triggers";

export const sampleTableDataHeader = [
    {
        label: "FUNCTION NAME",
        value: "funcName",
    },
    {
        label: "FUNCTION",
        value: "func",
    },
    {
        label: "INPUTS",
        value: "inputs",
    },
    {
        label: "ALIAS",
        value: "alias",
    },
    {
        label: "DATA TYPES",
        value: "dataTypes",
    },
    {
        label: "MAPPING DETAILS",
        value: "mappingDetails",
    },
    {
        label: "INITIAL VALUE",
        value: "initialValue",
    },
];

export const tableOutputDataHeader = [
    {
        label: "FUNCTION NAME",
        value: "funcName",
    },
    {
        label: "FUNCTION",
        value: "func",
    },
    {
        label: "OUTPUTS",
        value: "outputs",
    },
    {
        label: "ALIAS",
        value: "alias",
    },
    {
        label: "DATA TYPES",
        value: "dataTypes",
    },
    // {
    //   label: "TRIGGER",
    //   value: "trigger"
    // },
    {
        label: "PUBLISH",
        value: "publish",
    },
    // {
    //   label: "MAPPING DETAILS",
    //   value: "mappingDetails"
    // }
];

export const tableAlarmDataHeader = [
    {
        label: "FUNCTION NAME",
        value: "funcName",
    },
    {
        label: "FUNCTION",
        value: "func",
    },
    {
        label: "ALARM",
        value: "alarm",
    },
    {
        label: "DATA TYPES",
        value: "dataTypes",
    },
    {
        label: "PUBLISH",
        value: "publish",
    },
];

export const tableInputTriggerHeader = [
    {
        label: "FUNCTION NAME",
        value: "funcName",
    },
    {
        label: "FUNCTION",
        value: "func",
    },
    {
        label: "INPUTS",
        value: "input",
    },
    {
        label: "DATA TYPE",
        value: "dataType",
    },
    {
        label: "INPUT TRIGGER",
        value: "trigger",
    },
];

export const tableTimeTriggerHeader = [
    {
        label: "FUNCTION NAME",
        value: "funcName",
    },
    {
        label: "FUNCTION",
        value: "func",
    },
    {
        label: "TIME TRIGGER",
        value: "trigger",
    },
];

export const tableAlarmTriggerHeader = [
    {
        label: "FUNCTION NAME",
        value: "funcName",
    },
    {
        label: "FUNCTION",
        value: "func",
    },
    {
        label: "ALARM TRIGGER",
        value: "trigger",
    },
    {
        label: "OBJECT NAME",
        value: "obj",
    },
];

export const tableEventTriggerHeader = [
    {
        label: "FUNCTION NAME",
        value: "funcName",
    },
    {
        label: "FUNCTION",
        value: "func",
    },
    {
        label: "ALARM TRIGGER",
        value: "trigger",
    },
    {
        label: "OBJECT NAME",
        value: "obj",
    },
];
