import _ from 'lodash';
import { AssetTypeDetail } from '..';
import { FunctionTypeConstructorOptions, IFunctionAlarmInput, IFunctionEventInput } from './types';
import { IODataTypes, AssetsType, AlarmEventType } from '../../../components/Fabric/types';

import { ioConverter } from '../utils/ioConverter';
import { AlarmMapping } from '../../../store/function/types';
import { Alarms } from '../../ComputeModel/toJson/types';
import { IOApiOptions } from '../types';
import { ROUTE_PATHNAME, SamplingAggregateValues } from '../../../utils/constants/appConstants';
import { addPropertiesToConditionIfNotPresent } from '../../../routes/Functions/helper';

class FunctionTypeDetail extends AssetTypeDetail {
    inputs: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        connected: boolean;
        defaultValue?: string;
        timeWindow?: string;
        samplingAggregate?: SamplingAggregateValues;
        samplingWindow?: string;
        limit?: string;
    }[];
    outputs: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        isPublished?: boolean;
        connected: boolean;
        alarmId?: string;
        eventId?: string;
    }[];
    inhibits: {
        name: string;
        dataType: IODataTypes;
        id: string;
        path: string;
        connected: boolean;
        defaultValue?: string;
    }[];
    alias: {
        [inputName: string]: {
            dataType: string;
            value: string;
            isDirty?: boolean;
        };
    };
    alarmInputs: IFunctionAlarmInput[];
    eventInputs: IFunctionEventInput[];
    alarmPins: {
        isPublished?: boolean;
    };
    conditions?: {};
    calculations?: {};
    alarmMapping?: AlarmMapping;
    assetType: AssetsType;
    endpoint?: string;
    alarmTrigger?: IOApiOptions;
    eventTrigger?: IOApiOptions;
    timeTrigger?: string;
    variableChangeTrigger?: IOApiOptions;
    longestInputPinText: string;
    longestOutputPinText: string;
    alarms?: Alarms;
    isEndPointDirty?: boolean;
    hasOverRiddenConditions?: boolean = false;
    conditionsConfigurationVariables: string[];
    isUpdateAvailable?: boolean;
    isEncrypted?: boolean;
    constructor(options: FunctionTypeConstructorOptions) {
        super(options);
        const { properties } = options;
        this.alarmInputs = [];
        this.eventInputs = [];
        this.inputs = [];
        this.outputs = [];
        this.inhibits = [];
        this.alias = {};

        this.assetType = AssetsType.FUNCTION;
        this.longestInputPinText = '';
        this.longestOutputPinText = '';
        this.conditionsConfigurationVariables = [];
        this.alarmPins = { isPublished: false };
        this.isEndPointDirty = false;
        this.isEncrypted = false;

        if (properties) {
            const { inputs, outputs, inhibits, functionConditions, alarms, alarmInputs, eventInputs, alias } =
                properties;
            const { name } = options;
            const inputKeys = Object.keys(inputs);
            const outputKeys = Object.keys(outputs);

            if (alarms) {
                this.alarms = alarms;

                const isAlarmPinPublished = Object.keys(alarms).find(
                    (item) => item === this.nodeId
                );
                if(window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)){
                    if(isAlarmPinPublished){
                        const alarmData =  alarms[isAlarmPinPublished];
                        Object.keys(alarmData).forEach((key) => {
                            const objKey = alarmData[key];
                            const value = _.get(objKey, "alarmType.value");
                            if(value) {
                                this.alarmPins = {
                                    isPublished: true
                                };
                            }
                     });
                    }
                }
                else if (isAlarmPinPublished) {
                    this.alarmPins = {
                        isPublished: true,
                    };
                }
            }

            if (inhibits) {
                const inhibitKeys = Object.keys(inhibits);
                this.inhibits = ioConverter({
                    keys: inhibitKeys,
                    object: inhibits,
                    refPath: `inhibit`,

                    nodeId: this.nodeId,
                });
            }

            if (alarmInputs) {
                const alarmInputsKeys = Object.keys(alarmInputs);
                this.alarmInputs = ioConverter({
                    keys: alarmInputsKeys,
                    object: alarmInputs,
                    refPath: `alarmInputs`,
                    nodeId: this.nodeId,
                }) as IFunctionAlarmInput[];
            }
            this.inputs = ioConverter({
                keys: inputKeys,
                object: inputs,
                refPath: `inputs`,
                nodeId: this.nodeId,
            });

            if (eventInputs) {
                const eventInputsKeys = Object.keys(eventInputs);
                this.eventInputs = ioConverter({
                    keys: eventInputsKeys,
                    object: eventInputs,
                    refPath: `eventInputs`,
                    nodeId: this.nodeId,
                }) as IFunctionAlarmInput[];
            }

            if (alias) {
                this.alias = _.cloneDeep(alias);
            }

            this.outputs = ioConverter({
                keys: outputKeys,
                object: outputs,
                refPath: `outputs`,
                nodeId: this.nodeId,
            });

            if (properties.settings && properties.settings.endpoint) {
                this.endpoint = properties.settings.endpoint.value;
                this.isEndPointDirty = !!properties.settings.endpoint.isDirty;
            }

            if (properties.inputs && properties.inputs.conditions) {
                this.conditions = addPropertiesToConditionIfNotPresent(
                    properties.inputs.conditions,
                    ['status'],
                    true
                );
            }

            if (properties.outputs && properties.outputs.conditions) {
                const conditionsObject = _.cloneDeepWith(properties.outputs.conditions);
                const firstConditionItem = Object.keys(conditionsObject)[0];
                const conditionsConfigurationVariables = Object.keys(
                    conditionsObject[firstConditionItem]
                ).filter(
                    (item) =>
                        item === 'correctiveAction' ||
                        item === 'description' ||
                        item === 'possibleCause' ||
                        item === 'severity' ||
                        item === 'suggestedAction' ||
                        item === 'subCondition' ||
                        item === 'status'
                );

                const severityOutput = this.outputs.filter((item) => item.name === 'severity')[0];
                if (severityOutput && severityOutput.isPublished) {
                    this.conditionsConfigurationVariables = [...conditionsConfigurationVariables];
                }
            }

            if (properties.inputs && properties.inputs.calculations) {
                this.calculations = properties.inputs.calculations;
            }
            if (properties.inputs && properties.inputs.alarmMapping) {
                this.alarmMapping = properties.inputs.alarmMapping as unknown as AlarmMapping;
            }
            if (properties.variableChangeTrigger) {
                this.variableChangeTrigger = properties.variableChangeTrigger;
            }

            if (properties.timeTrigger || properties.timeTrigger === '') {
                this.timeTrigger = properties.timeTrigger;
            }
            if (properties.alarmTrigger) {
                this.alarmTrigger = properties.alarmTrigger;
            }
            if (properties.eventTrigger) {
                this.eventTrigger = properties.eventTrigger;
            }
            this.isEncrypted = !!properties.isEncrypted;
        }
    }
}

export function CreateFunctionTypeDetail(options: FunctionTypeConstructorOptions) {
    const { description, name, typeId, version, properties } = options;

    if (!(typeId || properties)) {
        return null;
    }

    return new FunctionTypeDetail(options);
}

export default FunctionTypeDetail;
