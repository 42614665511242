import _ from 'lodash';
import { VERSION_UPDATE_TYPE } from '../../../store/configurationTool/types';
import { SAVE_OPTIONS } from '../../../utils/constants/appConstants';
import FunctionTypeDetail from '../../AssetType/FunctionType';
import { ComputeModelFromJson } from '../fromJson/ComputeModelFromJson';
import { LocalJson } from '../fromJson/types';
import { ComputeModelToJson } from '../toJson/ComputeModelToJson';
import { ComputeModelToJsonConstructorOptions } from '../toJson/types';
import AlarmTypeDetail from '../../AssetType/AlarmType';

export const compareTypesJson = (options: {
    originalJson: LocalJson;
    updatedJson: LocalJson;
    computeModel: ComputeModelFromJson;
    overallSeverityFunctionId: string;
}) => {
    const { originalJson, updatedJson, computeModel, overallSeverityFunctionId } = options;
    const modelToSend: ComputeModelToJsonConstructorOptions = {
        ...computeModel,
        json: updatedJson,
        saveOptions: { value: SAVE_OPTIONS.PUBLISH },
        overallSelectedFunctionId: overallSeverityFunctionId,
    };
    const updatedTypeJson = new ComputeModelToJson(modelToSend);

    const originalTypeOptions: ComputeModelToJsonConstructorOptions = {
        ...computeModel,
        json: originalJson,
        saveOptions: { value: SAVE_OPTIONS.PUBLISH },
        overallSelectedFunctionId: overallSeverityFunctionId,
    };
    const originalTypeJson = new ComputeModelToJson(originalTypeOptions);

    let isMinorVersionUpdate = false;
    let isMajorVersionUpdate = false;
    let isPatchVersionUpdate = false;

    let hasSameItemsAndConnection = true;

    if (
        originalJson.assetData.length !== updatedJson.assetData.length ||
        originalJson.connectionData.length !== updatedJson.connectionData.length
    ) {
        hasSameItemsAndConnection = false;
        isMajorVersionUpdate = true;
    }

    if (hasSameItemsAndConnection) {
        const originalTypeJsonFunction = originalTypeJson.properties.functions;
        const updatedTypeJsonFunction = updatedTypeJson.properties.functions;
        const originalTypeJsonObjectType = originalTypeJson.properties.objectTypes;
        const updatedTypeJsonObjectType = updatedTypeJson.properties.objectTypes;

        // To check the function with the overRiddenCondition fields.
        const functionWithOverRiddenCondition = updatedJson.assetData.find(
            (asset) => asset instanceof FunctionTypeDetail && asset.hasOverRiddenConditions === true
        );
        if (functionWithOverRiddenCondition) {
            isMinorVersionUpdate = true;
        }
        // -----------//

        // comparing alarms.
        const originalTypeAlarms = originalTypeJson.properties.alarms;
        const updatedTypeAlarms = updatedTypeJson.properties.alarms;

        debugger;
        if (!_.isEqual(originalTypeAlarms, updatedTypeAlarms)) {
            isMajorVersionUpdate = true;
        }

        // To compare the Variable Trigger field.
        const originalTypeVariables = originalTypeJson.variables;
        const updatedTypeVariables = updatedTypeJson.variables;

        if (!_.isEqual(originalTypeVariables, updatedTypeVariables)) {
            isMajorVersionUpdate = true;
        }

        Object.keys(originalTypeJsonObjectType).forEach((objectType) => {
            const orignalObjectType = originalTypeJsonObjectType[objectType];
            const updatedObjectType = updatedTypeJsonObjectType[objectType];
            if (!updatedObjectType) {
                isMajorVersionUpdate = true;
            } else if (updatedObjectType) {
                if (
                    orignalObjectType.scale.value !== updatedObjectType.scale.value ||
                    orignalObjectType.xcoordinate.value !== updatedObjectType.xcoordinate.value ||
                    orignalObjectType.ycoordinate.value !== updatedObjectType.ycoordinate.value
                ) {
                    isPatchVersionUpdate = true;
                }
            }
        });

        Object.keys(originalTypeJsonFunction).forEach((item) => {
            const originalFunctionItem = originalTypeJsonFunction[item];
            const updatedFunctionItem = updatedTypeJsonFunction[item];

            if (updatedFunctionItem) {
                const originalFuncComparisonData = {
                    inhibits: originalFunctionItem.inhibit,
                    outputs: originalFunctionItem.outputs,
                    inputs: originalFunctionItem.inputs,
                    position: {
                        x: originalFunctionItem.xcoordinate.value,
                        y: originalFunctionItem.ycoordinate.value,
                    },
                    scale: originalFunctionItem.scale.value,
                    triggers: originalFunctionItem.triggers,
                    endpoint: originalFunctionItem.endpoint,
                    version: originalFunctionItem.version,
                };
                const updatedFuncComparisonData = {
                    inhibits: updatedFunctionItem.inhibit,
                    outputs: updatedFunctionItem.outputs,
                    inputs: updatedFunctionItem.inputs,
                    position: {
                        x: updatedFunctionItem.xcoordinate.value,
                        y: updatedFunctionItem.ycoordinate.value,
                    },
                    scale: updatedFunctionItem.scale.value,
                    triggers: updatedFunctionItem.triggers,
                    endpoint: updatedFunctionItem.endpoint,
                    version: updatedFunctionItem.version,
                };

                const originalFuncMajorVersion = parseInt(
                    originalFuncComparisonData.version.value.split('.')[0]
                );
                const updatedFuncMajorVersion = parseInt(
                    updatedFuncComparisonData.version.value.split('.')[0]
                );

                const originalFuncMinorVersion = parseInt(
                    originalFuncComparisonData.version.value.split('.')[1]
                );
                const updatedFuncMinorVersion = parseInt(
                    updatedFuncComparisonData.version.value.split('.')[1]
                );

                if (    
                    updatedFuncMajorVersion > originalFuncMajorVersion 
                ) {
                    isMajorVersionUpdate = true;
                } else if (updatedFuncMinorVersion > originalFuncMinorVersion || !_.isEqual(originalFunctionItem.alias, updatedFunctionItem.alias)) {
                    isMinorVersionUpdate = true;
                } else {
                    // input pins comparison.
                    Object.keys(originalFuncComparisonData.inputs).forEach((input) => {
                        const originalFuncInputPin = originalFuncComparisonData.inputs[input];
                        const updatedFuncInputPin = updatedFuncComparisonData.inputs[input];
                        if (!originalFuncInputPin || !updatedFuncInputPin) {
                            isMajorVersionUpdate = true;
                        } else {
                            if (
                                originalFuncInputPin.link !== updatedFuncInputPin.link ||
                                originalFuncInputPin.items !== updatedFuncInputPin.items
                            ) {
                                isMajorVersionUpdate = true;
                            }

                            const originalDefaultValue =
                                // @ts-ignore
                                originalFuncInputPin.value;

                            const updatedDefaultValue =
                                //@ts-ignore
                                updatedFuncInputPin.value;
                            if (originalDefaultValue !== undefined || updatedDefaultValue !== undefined) {
                                if (!_.isEqual(originalDefaultValue, updatedDefaultValue)) {
                                    isMajorVersionUpdate = true;
                                }
                            }
                            if (
                                originalFuncInputPin.limit !== updatedFuncInputPin.limit ||
                                originalFuncInputPin.timeWindow !==
                                    updatedFuncInputPin.timeWindow ||
                                originalFuncInputPin.samplingWindow !==
                                    updatedFuncInputPin.samplingWindow ||
                                originalFuncInputPin.samplingAggregate !==
                                    updatedFuncInputPin.samplingAggregate
                            ) {
                                isMajorVersionUpdate = true;
                            }
                        }
                    });

                    // inhibits pins comparison.
                    if (
                        (originalFuncComparisonData.inhibits &&
                            !updatedFuncComparisonData.inhibits) ||
                        (!originalFuncComparisonData.inhibits && updatedFuncComparisonData.inhibits)
                    ) {
                        isMajorVersionUpdate = true;
                    }
                    if (originalFuncComparisonData.inhibits && updatedFuncComparisonData.inhibits) {
                        Object.keys(originalFuncComparisonData.inhibits).forEach((inhibit) => {
                            const originalFuncInhibitPin =
                                originalFuncComparisonData.inhibits![inhibit];
                            const updatedFuncInhibitPin =
                                updatedFuncComparisonData.inhibits![inhibit];
                            if (
                                originalFuncInhibitPin.link !== updatedFuncInhibitPin.link ||
                                originalFuncInhibitPin.items !== updatedFuncInhibitPin.items ||
                                originalFuncInhibitPin.value !== updatedFuncInhibitPin.value
                            ) {
                                isMajorVersionUpdate = true;
                            }
                        });
                    }

                    // output pins comparison.
                    const originalFuncOutputs = originalFuncComparisonData.outputs;
                    const updatedFuncOutputs = updatedFuncComparisonData.outputs;
                    Object.keys(originalFuncOutputs).forEach((output) => {
                        // to check for conditions key in output
                        if (!originalFuncOutputs[output] || !updatedFuncOutputs[output]) {
                            isMajorVersionUpdate = true;
                        } else {
                            if (
                                originalFuncOutputs[output].link !==
                                    updatedFuncOutputs[output].link ||
                                originalFuncOutputs[output].items !==
                                    updatedFuncOutputs[output].items
                            ) {
                                isMajorVersionUpdate = true;
                            }
                        }
                    });

                    // endpoint comparison.
                    if (
                        originalFunctionItem.endpoint.value !== updatedFunctionItem.endpoint.value
                    ) {
                        isMinorVersionUpdate = true;
                    }

                    // function position comparison.
                    if (
                        originalFuncComparisonData.scale !== updatedFuncComparisonData.scale ||
                        originalFuncComparisonData.position.x !==
                            updatedFuncComparisonData.position.x ||
                        originalFuncComparisonData.position.y !==
                            updatedFuncComparisonData.position.y
                    ) {
                        // isMinorVersionUpdate = true;
                        isPatchVersionUpdate = true;
                    }

                    // function trigger comparison.
                    if (
                        !_.isEqual(
                            originalFuncComparisonData.triggers,
                            updatedFuncComparisonData.triggers
                        )
                    ) {
                        isMajorVersionUpdate = true;
                    }
                }
            } else {
                isMajorVersionUpdate = true;
            }
        });

        //comparing alarmItemNames
        originalJson.assetData.forEach((item, index) => {
            if (item instanceof AlarmTypeDetail) {
                let updatedAlarmItem = updatedJson.assetData.filter((typeItem) => {
                    return typeItem.nodeId === item.nodeId
                })[0];
                if (updatedAlarmItem && item.assetName !== updatedAlarmItem.assetName ) {
                    isMinorVersionUpdate = true;
                }
            }
        });

    } else {
        let originalFunctionWithRefreshRequiredCount = 0;
        let finalFunctionWithRefreshRequiredCount = 0;
        originalJson.assetData.forEach((item) => {
            if (item instanceof FunctionTypeDetail) {
                if (item.isUpdateAvailable) {
                    originalFunctionWithRefreshRequiredCount += 1;
                }
            }
        });
        updatedJson.assetData.forEach((item) => {
            if (item instanceof FunctionTypeDetail) {
                if (item.isUpdateAvailable) {
                    originalFunctionWithRefreshRequiredCount += 1;
                }
            }
        });
        if (originalFunctionWithRefreshRequiredCount !== finalFunctionWithRefreshRequiredCount) {
            return VERSION_UPDATE_TYPE.MAJOR;
        }
    }

    const versionUpdateType = isMajorVersionUpdate
        ? VERSION_UPDATE_TYPE.MAJOR
        : isMinorVersionUpdate
        ? VERSION_UPDATE_TYPE.MINOR
        : isPatchVersionUpdate
        ? VERSION_UPDATE_TYPE.PATCH
        : VERSION_UPDATE_TYPE.DEFAULT;

    return versionUpdateType;
};
