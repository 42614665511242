import React, { Fragment, useState, useCallback, useEffect } from "react";
import TR from "../../../../Table/TableRow";
import { Collapse } from "reactstrap";
import { IoTableTabsProps } from "../../..";
import {
    FunctionTypeDetailsWithMappings,
    TableDropDownData,
} from "../../../../../model/TableController/types";
import Td from "../../../../Table/TableData";
import Condition from "../../../../shared/Condition";
import _ from "lodash";
import { ToggleSwitch, Icon, Input } from "@abb/abb-common-ux-react";
import Checkbox from "../../../../CustomCheckbox";
import ConnectionDropdown from "../../ConnectionDropdown";
import {
    defaultValueValidation,
    initialValueValidation,
    defaultArrayValueValidation,
    ValidateDataType,
} from "../../../../../transformers/ComputeModel/validation/defaultValue";
import { IODataTypes } from "../../../../Fabric/types";
import { useDrop } from "react-dnd";
import {
    DRAGGABLE_ITEMS,
    ROUTE_PATHNAME,
} from "../../../../../utils/constants/appConstants";
import "../style.scss";
import {
    handletableDropDownData,
    checkFunctionIdDuplication,
    checkForExistingAliasName,
} from "../../../../../utils/helpers";
import { SEVERITY_PIN } from "../../../../../routes/Functions/constants";
import { validateForSwedishAndFinishCharacters } from "../../../../../routes/Functions/helper";
import { outputFinder } from "../../../../../transformers/utils/outputFinder";
import FunctionTypeDetail from "../../../../../transformers/AssetType/FunctionType";
import { VALIDATION_MESSAGE } from "../../../../../utils/constants/uiConstants";
import { store } from "../../../../../containers/AppBoot/index";
import { CreateFunctionTypeDetail } from "../../../../../transformers/AssetType/FunctionType";
import { OriginalFunctionTypes } from "../../../../../store/configurationTool/types";
import { getOriginalSelectedFuncDetail } from "../../../../../utils/helpers";
import { hideDialog, showDialog } from "../../../../../store/dialog/action";
import { MessageModal } from "../../../../MessageModal";
import {
    CONFIRMATION_INITIAL_VALUE,
    CONFIRMATION_BUTTON,
} from "../../../../../utils/constants/uiConstants";
import { connect } from "tls";

import { validateForSpecialCharacters_Including_HyphenAndUnderscore } from "../../../../../routes/Functions/helper";
import CollapseTitle from "../../../../CollapseTitle";
import CustomABBInput from "../../../../CustomABBInput";

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
}

const ExpandableInputRows = (props: ExpandTableRowProps & IoTableTabsProps) => {
    const [isExpanded, updateIsExpanded] = useState(false);

    const toggleExpand = () => {
        updateIsExpanded(!isExpanded);
    };

    const rows = [...props.asset.inputs, ...props.asset.inhibits];

    const rowCount = rows.length;

    const rowArray = isExpanded ? rows : [rows[0]];

    const defaultValueValidator = useCallback(
        (
            defaultValue: string,
            dataType: string,
            input: typeof props.asset.inputs[0]
        ) => {
            if (defaultValue) {
                return defaultValueValidation({
                    dataType: dataType as IODataTypes,
                    value: defaultValue,
                    inputDetails: input,
                });
            } else {
                if (!defaultValue) {
                    return initialValueValidation({
                        dataType: dataType as IODataTypes,
                        value: defaultValue,
                    });
                } else {
                    return { isInvalid: false, errorMessage: "" };
                }
            }
        },
        []
    );

    const defaultDataTypeValidator = useCallback(
        (defaultValue: string, dataType: string) => {
            if (defaultValue) {
                return defaultArrayValueValidation({
                    dataType: dataType as IODataTypes,
                    value: defaultValue,
                });
            } else {
                if (!defaultValue) {
                    return defaultArrayValueValidation({
                        dataType: dataType as IODataTypes,
                        value: defaultValue,
                    });
                } else {
                    return {};
                }
            }
        },
        []
    );

    return (
        <Fragment>
            {rowArray.length === 0 ? (
                <TR>Empty</TR>
            ) : (
                <>
                    <CollapseTitle
                        title={props.asset.nodeId}
                        isCollapse={isExpanded}
                        toggleCollapse={toggleExpand}
                    />
                    <Collapse
                        isOpen={isExpanded}
                        timeout={{ enter: 0, exit: 0.3 }}
                    >
                        {rowArray.map((input, index) => {
                            const defaultValueValidations =
                                input.defaultValue ||
                                input.mappingDetails.length > 0
                                    ? defaultValueValidator(
                                          input.defaultValue || "",
                                          input.dataType,
                                          input
                                      )
                                    : { isInvalid: false, errorMessage: "" };
                            let defaultDataTypeValidations;
                            if (input.defaultValue) {
                                defaultDataTypeValidations =
                                    defaultDataTypeValidator(
                                        input.defaultValue || "",
                                        input.dataType
                                    );
                            }
                            return (
                                <InputRow
                                    key={input.id}
                                    {...props}
                                    asset={props.asset}
                                    defaultValueValidations={
                                        defaultValueValidations
                                    }
                                    defaultDataTypeValidator={
                                        defaultDataTypeValidations
                                            ? defaultDataTypeValidations
                                            : {}
                                    }
                                    index={index}
                                    input={input}
                                    isExpanded={isExpanded}
                                    rowCount={rowCount}
                                    toggleExpand={toggleExpand}
                                />
                            );
                        })}
                    </Collapse>
                </>
            )}
        </Fragment>
    );
};

const InputRow = (
    props: {
        index: number;
        rowCount: number;
        isExpanded: boolean;
        input: FunctionTypeDetailsWithMappings["inputs"][0];
        asset: FunctionTypeDetailsWithMappings;
        toggleExpand: () => void;
        defaultValueValidations: { isInvalid: boolean; errorMessage: string };
        defaultDataTypeValidator: ValidateDataType;
    } & IoTableTabsProps
) => {
    const [, drop] = useDrop({
        accept: DRAGGABLE_ITEMS.TABLE_VIEW.ioVariables.src,
        drop: (data: any, monitor) => {
            props.addConnectionConfigurationTool({
                outputAsset: data.data.outputAsset,
                outputCircleData: data.data.circleData,
                inputAsset: props.asset,
                inputCircleData: props.input,
            });
        },
    });

    let initialNodeId = props.asset.nodeId;

    const [nodeId, updateNodeId] = useState(props.asset.nodeId);
    const [cloneRowData, setCloneRowData] = useState({} as any);
    const [mapConnectionInitialValue, setMappedConnection] = useState(
        {} as any
    );
    const [aliasName, setAliasName] = useState(
        props.asset.alias[props.input.name]
            ? props.asset.alias[props.input.name].value
            : props.input.name
    );
    const [defaultValue, updateDefaultValue] = useState(
        props.input.defaultValue !== undefined ? props.input.defaultValue : ""
    );
    const [switchState, updateSwitchState] = useState(
        props.input.defaultValue ? true : undefined
    );
    const [dropDownOptionsData, setDropDownOptionsData] = useState(
        [] as TableDropDownData[]
    );
    const [errorFuncName, updateErrorFuncName] = useState("");
    const [aliasNameError, setAliasNameError] = useState("");
    const [isFunctionValid, updateIsFunctionValid] = useState(true);
    const [selectedDropDownOptionsIndex, setSelectedDropDownOptionsIndex] =
        useState([] as number[]);

    // useEffect(() => {
    //     if (props.activeModelJson && props.activeModelJson.assetData && !cloneRowData) {
    //         // let functionTypeDetail = {};
    //         if (props.asset.nodeId) {
    //             const originalSelectedFunction = getOriginalSelectedFuncDetail({
    //                 nodeId: props.asset.nodeId,
    //                 json: props.activeModelJson,
    //             });
    //             console.log("originalSelectedFunction", originalSelectedFunction);
    //             setCloneRowData(originalSelectedFunction);
    //         }
    //     }
    // }, [])
    useEffect(() => {
        // if (props.input.mappingDetails.length > 0) {
        //     updateSwitchState(undefined);
        // }
        if (
            props.tableData.objectTypeDetails &&
            props.input &&
            props.tableData.functionTypeDetails
        ) {
            const { dropDownOptions, selectedDropDownItemIndex } =
                handletableDropDownData({
                    tableData: props.tableData,
                    input: props.input,
                });

            setDropDownOptionsData(dropDownOptions);
            setSelectedDropDownOptionsIndex(selectedDropDownItemIndex);
        }
    }, [props.input.mappingDetails.length, props.tableData]);

    useEffect(() => {
        updateDefaultValue(
            props.input.defaultValue !== undefined
                ? props.input.defaultValue
                : ""
        );
    }, [props.input, props.input.defaultValue]);

    const {
        index,
        rowCount,
        isExpanded,
        input,
        asset,
        toggleExpand,
        defaultValueValidations,
        inViewMode,
        defaultDataTypeValidator,
    } = props;

    const handleDefaultValuefocusOut = useCallback(() => {
        props.addDefaultValueConfigurationTool({
            circleData: input,
            asset,
            defaultValue: defaultValue,
        });
    }, [defaultValue, input]);

    let debounceTimeChange: NodeJS.Timeout;

    const onFunctionNameFocusOut = useCallback(() => {
        //updateNodeId(value);
        if (isFunctionValid) {
            props.editNodeIdOfFunction({
                newName: nodeId,
                functionType: props.asset,
            });
        }
    }, [nodeId, isFunctionValid]);

    const onNodeIdValueChange = (value: string) => {
        updateNodeId(value);
        validateNodeId(value);
    };

    // useEffect(() => {
    //     if (debounceTimeChange) {
    //         clearTimeout(debounceTimeChange);
    //     }
    //     debounceTimeChange = setTimeout(() => {
    //         onDebounceNodeIdChange(nodeId);
    //     }, 500);
    // }, [nodeId]);

    const validateNodeId = useCallback(
        (value: string) => {
            const oldFunctionList = props.modelInstance.json.assetData.filter(
                (item) => item instanceof FunctionTypeDetail
            );
            const currentFunctionList = props.currentJson.assetData.filter(
                (item) => item instanceof FunctionTypeDetail
            );
            const isValid =
                value === initialNodeId ||
                (checkFunctionIdDuplication({
                    id: value,
                    assetData: currentFunctionList,
                }) &&
                    checkFunctionIdDuplication({
                        id: value,
                        assetData: oldFunctionList,
                    }) &&
                    value !== props.asset.assetName);

            if (isValid) {
                if (
                    !validateForSpecialCharacters_Including_HyphenAndUnderscore(
                        value
                    )
                ) {
                    updateIsFunctionValid(false);
                    updateErrorFuncName(VALIDATION_MESSAGE.SPECIAL_CHARACTERS);
                } else if (value === "") {
                    updateIsFunctionValid(false);
                } else {
                    updateIsFunctionValid(true);
                    updateErrorFuncName("");
                }
            } else if (value === props.asset.assetName) {
                updateIsFunctionValid(false);
                updateErrorFuncName(VALIDATION_MESSAGE.ORIGINAL_FUNCTION_NAME);
            } else {
                updateIsFunctionValid(isValid);
                updateErrorFuncName(VALIDATION_MESSAGE.FUNCTION_NAME_ERROR);
            }
            return isValid;
        },
        [nodeId]
    );

    const handleArrayInputs = useCallback(
        (value: string, index: number) => {
            if (!input.defaultValue) {
                input.defaultValue = "0";
            } else {
                const splitDefValue = input.defaultValue.split(",");
                if (splitDefValue && splitDefValue.length > 0) {
                    if (!value.includes(",")) {
                        if (!value) {
                            delete splitDefValue[index];
                        }
                        if (value) {
                            splitDefValue[index] = value;
                        }
                    }
                }
                input.defaultValue = splitDefValue.join(",");
            }
            props.addDefaultValueConfigurationTool({
                circleData: input,
                asset,
                defaultValue: input.defaultValue,
            });
        },
        [input]
    );

    const addConnectionDefaultValue = useCallback(
        (type: string, assetPayload: any) => {
            //const storeVal = store.getState();
            //const { configurationTool } = storeVal;
            const { tableData } = configurationTool;

            if (asset.nodeId === configurationTool.overallSeverityFunctionId)
                return;

            //const origVersionId = assetPayload.inputAsset.assetRef.split("@");
            //const orunId =
            // origVersionId[0] + "#" + assetPayload.inputAsset.assetVersion;
            // const origFuncData =
            //     configurationTool.originalFunctionTypesUsed.byIdWithVersion[
            //         orunId
            //     ] ||
            //     configurationTool.latestOriginalFunctionsUsed.byIdWithVersion[
            //         orunId
            //     ];

            if (type == "add") {
                const { inputCircleData, inputAsset } = assetPayload;
                const asset = tableData.functionTypeDetails.find(
                    (asset) => asset.nodeId === inputAsset.nodeId
                );
                if (asset) {
                    const inputData =
                        asset.inputs.find((i) => i.id === inputCircleData.id) ||
                        asset.inhibits.find((i) => i.id === inputCircleData.id);
                    if (inputData) {
                        if (origFuncData) {
                            let findInput: any = origFuncData;
                            if (findInput.inputs) {
                                let inputExists = findInput.inputs.find(
                                    (input: any) =>
                                        input.name === inputCircleData.name
                                );

                                if (!inputData.defaultValue) {
                                    input.defaultValue = "0";
                                } else {
                                    if (
                                        input.mappingDetails &&
                                        input.mappingDetails.length > 0
                                    ) {
                                        // if (input.mappingDetails && input.mappingDetails.length > 0) {
                                        //     let connectionMap = {};
                                        //     if (!connectionMap[input.name]) {
                                        //         connectionMap[input.name] = {}
                                        //     }
                                        //     if (!connectionMap[input.name][input.mappingDetails[input.mappingDetails.length - 1].output.circleData.name]) {
                                        //         connectionMap[input.name][input.mappingDetails[input.mappingDetails.length - 1].output.circleData.name] = {};
                                        //     }
                                        //     if (!connectionMap[input.name][input.mappingDetails[input.mappingDetails.length - 1].output.circleData.name]['index']) {
                                        //         connectionMap[input.name][input.mappingDetails[input.mappingDetails.length - 1].output.circleData.name]['index'] = (input.mappingDetails.length - 1);
                                        //     }

                                        //     setMappedConnection(connectionMap);
                                        // }
                                        let connectionMap =
                                            inputData.defaultValue.split(",");
                                        const previousJsonValue =
                                            inputExists.defaultValue.split(",");
                                        if (inputExists) {
                                            if (
                                                connectionMap.length <
                                                previousJsonValue.length
                                            ) {
                                                const notMatchedValues =
                                                    removeCommon(
                                                        connectionMap,
                                                        previousJsonValue
                                                    );
                                                const defaultUpdatedValue =
                                                    input.defaultValue +
                                                    "," +
                                                    notMatchedValues[0];
                                                props.addDefaultValueConfigurationTool(
                                                    {
                                                        circleData: input,
                                                        asset,
                                                        defaultValue:
                                                            defaultUpdatedValue,
                                                    }
                                                );
                                            } else {
                                                if (
                                                    input.mappingDetails
                                                        .length >
                                                    connectionMap.length
                                                ) {
                                                    showConfirmationDialog(
                                                        input
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            if (!inputData.defaultValue) {
                                input.defaultValue = "0";
                            } else {
                                if (
                                    input.mappingDetails &&
                                    input.mappingDetails.length > 0
                                ) {
                                    let connectionMap =
                                        inputData.defaultValue.split(",");
                                    if (
                                        input.mappingDetails.length >
                                        connectionMap.length
                                    ) {
                                        // input.defaultValue = input.defaultValue + ",0";
                                        let initialDefaultValues =
                                            input.defaultValue + ",0";
                                        props.addDefaultValueConfigurationTool({
                                            circleData: input,
                                            asset,
                                            defaultValue: initialDefaultValues,
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (type == "remove") {
                const { inputCircleData, inputAsset, connectionindex } =
                    assetPayload;
                const asset = tableData.functionTypeDetails.find(
                    (asset) => asset.nodeId === inputAsset.nodeId
                );
                if (asset) {
                    const inputData =
                        asset.inputs.find((i) => i.id === inputCircleData.id) ||
                        asset.inhibits.find((i) => i.id === inputCircleData.id);
                    if (inputData) {
                        if (
                            inputData.dataType === "array_integer" ||
                            inputData.dataType === "array_string" ||
                            inputData.dataType === "array_number" ||
                            inputData.dataType === "array_boolean"
                        ) {
                            if (inputData.defaultValue) {
                                if (
                                    inputData.mappingDetails &&
                                    inputData.mappingDetails.length > 0
                                ) {
                                    if (origFuncData) {
                                        let findInput: any = origFuncData;
                                        if (findInput.inputs) {
                                            // if (mapConnectionInitialValue) {
                                            //     mapConnectionInitialValue[input.name][input.mappingDetails[connectionindex].output.circleData.name]['index'] = null;
                                            //     setMappedConnection(mapConnectionInitialValue);
                                            // }
                                            let inputExists =
                                                findInput.inputs.find(
                                                    (input: any) =>
                                                        input.name ===
                                                        inputCircleData.name
                                                );
                                            if (
                                                inputExists.defaultValue !==
                                                inputData.defaultValue
                                            ) {
                                                let connectionMapRemove =
                                                    inputData.defaultValue.split(
                                                        ","
                                                    );

                                                connectionMapRemove.pop();
                                                // connectionMapRemove.splice(connectionindex, 1);
                                                inputData.defaultValue =
                                                    connectionMapRemove.join(
                                                        ","
                                                    );
                                                if (
                                                    !inputData.defaultValue &&
                                                    inputData.name ===
                                                        inputExists.name
                                                ) {
                                                    inputData.defaultValue =
                                                        inputExists.defaultValue;
                                                }

                                                if (
                                                    inputData.mappingDetails &&
                                                    inputData.mappingDetails
                                                        .length === 0 &&
                                                    inputData.defaultValue !==
                                                        undefined
                                                ) {
                                                    let connectionMapEmpty =
                                                        inputData.defaultValue.split(
                                                            ","
                                                        );
                                                    let inputExists =
                                                        findInput.inputs.find(
                                                            (input: any) =>
                                                                input.name ===
                                                                inputCircleData.name
                                                        );
                                                    if (
                                                        inputExists.defaultValue !==
                                                        inputData.defaultValue
                                                    ) {
                                                        inputExists.defaultValue &&
                                                            inputExists.defaultValue
                                                                .split(",")
                                                                .forEach(
                                                                    (
                                                                        value: string,
                                                                        index: number
                                                                    ) => {
                                                                        if (
                                                                            index >=
                                                                            connectionMapEmpty.length
                                                                        )
                                                                            connectionMapEmpty.push(
                                                                                value
                                                                            );
                                                                    }
                                                                );
                                                    }
                                                    inputData.defaultValue =
                                                        connectionMapEmpty.join(
                                                            ","
                                                        );
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else if (
                            inputData.dataType === "integer" ||
                            inputData.dataType === "number" ||
                            inputData.dataType === "string"
                        ) {
                            if (inputData.defaultValue) {
                                if (
                                    inputData.mappingDetails &&
                                    inputData.mappingDetails.length > 0
                                ) {
                                    if (origFuncData) {
                                        let findInput: any = origFuncData;
                                        if (findInput.inputs) {
                                            let inputExists =
                                                findInput.inputs.find(
                                                    (input: any) =>
                                                        input.name ===
                                                        inputCircleData.name
                                                );
                                            if (
                                                inputExists.defaultValue !==
                                                inputData.defaultValue
                                            ) {
                                                if (
                                                    !inputData.defaultValue &&
                                                    inputData.name ===
                                                        inputExists.name
                                                ) {
                                                    inputData.defaultValue =
                                                        inputExists.defaultValue;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (type == "clear") {
                const { inputCircleData, inputAsset, connectionindex } =
                    assetPayload;
                const asset = tableData.functionTypeDetails.find(
                    (asset) => asset.nodeId === inputAsset.nodeId
                );
                if (asset) {
                    const inputData =
                        asset.inputs.find((i) => i.id === inputCircleData.id) ||
                        asset.inhibits.find((i) => i.id === inputCircleData.id);
                    if (inputData) {
                        if (
                            inputData.dataType === "array_integer" ||
                            inputData.dataType === "array_string" ||
                            inputData.dataType === "array_number" ||
                            inputData.dataType === "array_boolean"
                        ) {
                            if (inputData.defaultValue) {
                                if (origFuncData) {
                                    let findInput: any = origFuncData;
                                    if (findInput.inputs) {
                                        // if (mapConnectionInitialValue) {
                                        //     mapConnectionInitialValue[input.name][input.mappingDetails[connectionindex].output.circleData.name]['index'] = null;
                                        //     setMappedConnection(mapConnectionInitialValue);
                                        // }
                                        let inputExists = findInput.inputs.find(
                                            (input: any) =>
                                                input.name ===
                                                inputCircleData.name
                                        );
                                        let connectionMapEmpty =
                                            inputData.defaultValue.split(",");
                                        if (
                                            inputExists.defaultValue !==
                                            inputData.defaultValue
                                        ) {
                                            inputExists.defaultValue &&
                                                inputExists.defaultValue
                                                    .split(",")
                                                    .forEach(
                                                        (
                                                            value: string,
                                                            index: number
                                                        ) => {
                                                            if (
                                                                index >=
                                                                connectionMapEmpty.length
                                                            )
                                                                connectionMapEmpty.push(
                                                                    value
                                                                );
                                                        }
                                                    );
                                        }
                                        inputData.defaultValue =
                                            connectionMapEmpty.join(",");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        [input]
    );

    const removeCommon = (first: any, second: any) => {
        var arrayDifference = second.filter((x: any) => {
            return !first.includes(x);
        });
        return arrayDifference;
    };

    const showConfirmationDialog = (input: any) => {
        store.dispatch(
            showDialog({
                component: MessageModal,
                modalTitle: CONFIRMATION_BUTTON.CONFIRM,
                customClassName: "wrapper-message-modal",
                data: {
                    warningText: CONFIRMATION_INITIAL_VALUE,
                    standardButtonsOnBottom: [
                        {
                            text: CONFIRMATION_BUTTON.CANCEL,
                            type: "discreet-black",
                            handler: (dlg: any) => {
                                store.dispatch(hideDialog());
                            },
                        },
                        {
                            text: CONFIRMATION_BUTTON.OK,
                            type: "primary-blue",
                            handler: (dlg: any) => {
                                // store.dispatch(cancelUnsavedModelChanges());

                                // input.defaultValue = input.defaultValue + ",0";
                                props.addDefaultValueConfigurationTool({
                                    circleData: input,
                                    asset,
                                    defaultValue: input.defaultValue + ",0",
                                });
                                store.dispatch(hideDialog());
                            },
                        },
                    ],
                },
            })
        );
    };

    const handleAliasNameChange = useCallback(
        (value: string) => {
            setAliasName(value.trim());
            // if (
            //     value.length > 0 &&
            //     !validateForSwedishAndFinishCharacters(value)
            // ) {
            //     setAliasNameError(`Special Characters are not allowed`);
            // } else
            if (
                checkForExistingAliasName(value, props.asset) &&
                value !== props.input.name
            ) {
                setAliasNameError(
                    `${value} is already used in ${props.asset.nodeId}`
                );
            } else if (value === SEVERITY_PIN) {
                setAliasNameError(`${value} cannot be used as alias name`);
            } else {
                setAliasNameError("");
            }
        },
        [aliasName]
    );

    const handleAliasFocusOut = useCallback(
        (event: any) => {
            event.preventDefault();
            if (event.target.value.trim() === "") {
                setAliasName(props.input.name);
            }
            props.changeAliasNameForModels({
                functionType: props.asset,
                ioName: props.input.name,
                aliasName,
            });
        },
        [aliasName]
    );

    const hasDefaultValue = !!input.defaultValue;
    const switchToggled =
        typeof switchState === "boolean" && input.mappingDetails.length === 0
            ? !!switchState
            : hasDefaultValue;

    const storeVal = store.getState();
    const { configurationTool } = storeVal;
    const origVersionId = asset.assetRef.split("@");
    const orunId = origVersionId[0] + "#" + asset.assetVersion;
    const origFuncData =
        configurationTool.originalFunctionTypesUsed.byIdWithVersion[orunId] ||
        configurationTool.latestOriginalFunctionsUsed.byIdWithVersion[orunId];
    let originalInput: any = {};
    if(origFuncData) {
        originalInput = origFuncData.inputs.find(
            (argInput: any) => argInput.name === props.input.name
        )
    }

    return (
        <TR id={`${asset.nodeId}_${input.name}`}>
            <Fragment>
                {/* <Td tdBorderTop={index !== 0 ? 'remove-inner-row-border-top' : ''}>
                    <Condition when={index === 0 && rowCount > 1}>
                        <Icon
                            name={isExpanded ? "abb/minus" : "abb/plus"}
                            onClick={toggleExpand}
                        />
                    </Condition>
                </Td>
                <Td
                    tdBorderTop={
                        index !== 0 ? "remove-inner-row-border-top" : ""
                </Td> */}
                <Td
                    tdBorderTop={
                        index !== rowCount - 1
                            ? "remove-inner-row-border-bottom"
                            : ""
                    }
                >
                    {index === 0 ? (
                        <CustomABBInput
                            id={`${asset.assetRef}_nodeId`}
                            value={nodeId}
                            dataType="text"
                            disabled={
                                !props.isEditMode ||
                                window.location.pathname.includes(
                                    ROUTE_PATHNAME.OBJECTS
                                ) ||
                                asset.nodeId === props.overallSeverityFunctionId
                            }
                            onValueChange={(value) =>
                                onNodeIdValueChange(value)
                            }
                            onLostFocus={onFunctionNameFocusOut}
                            instantValidation
                            validator={() =>
                                !isFunctionValid
                                    ? nodeId === ""
                                        ? {
                                              valid: false,
                                              text: VALIDATION_MESSAGE.EMPTY_FIELD_MESSAGE,
                                          }
                                        : {
                                              valid: false,
                                              text: errorFuncName,
                                          }
                                    : { valid: true, text: "OK!" }
                            }
                            showValidationBarWhenInvalid={true}
                            showValidationIconWhenInvalid={true}
                        />
                    ) : (
                        ""
                    )}
                </Td>
                <Td
                    tdBorderTop={
                        index !== rowCount - 1
                            ? "remove-inner-row-border-bottom"
                            : ""
                    }
                >
                    {index === 0 ? asset.assetName : ""}
                </Td>
                <Td> {input.name}</Td>
                <Td className={"alias-column"}>
                    <Input
                        id={`${input.name}_alias`}
                        value={aliasName}
                        dataType="text"
                        style={{
                            minWidth: "30px",
                        }}
                        disabled={
                            !props.isEditMode ||
                            asset.nodeId === props.overallSeverityFunctionId ||
                            input.name === "referenceValue" ||
                            input.name === "actualValue"
                        }
                        onValueChange={(value) => handleAliasNameChange(value)}
                        onLostFocus={handleAliasFocusOut}
                        validator={() =>
                            aliasNameError !== ""
                                ? {
                                      valid: false,
                                      text: aliasNameError,
                                  }
                                : { valid: true, text: "OK!" }
                        }
                        showValidationBarWhenInvalid={true}
                        showValidationIconWhenInvalid={true}
                    />
                    {props.asset.alias[props.input.name] &&
                    props.asset.alias[props.input.name].isDirty ? (
                        <div className="changedBorder"></div>
                    ) : (
                        <></>
                    )}
                </Td>
                <Td> {input.dataType}</Td>
                <Fragment>
                    <Fragment>
                        {/* {props.isEditMode ? (
                            switchToggled ? (
                                <Td>
                                    <CustomABBInput
                                        dataType="text"
                                        placeholder="Enter default value"
                                        value={defaultValue}
                                        onValueChange={updateDefaultValue}
                                        onLostFocus={() =>
                                            handleDefaultValuefocusOut()
                                        }
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                        validator={() =>
                                            defaultValueValidations.isInvalid
                                                ? {
                                                      valid: false,
                                                      text:
                                                          defaultValueValidations.errorMessage ||
                                                          "Invalid input",
                                                  }
                                                : { valid: true, text: "OK!" }
                                        }
                                    />
                                </Td>
                            ) : (
                                <Td drop={drop}>
                                    <ConnectionDropdown
                                        {...props}
                                        dropDownOptions={[
                                            ...dropDownOptionsData,
                                        ]}
                                        selectedOptions={[
                                            ...selectedDropDownOptionsIndex,
                                        ]}
                                        inputAsset={asset}
                                        inputCircleData={input}
                                    />
                                </Td>
                            )
                        ) : switchToggled ? (
                            <Td>
                                <span className="text-center">
                                    {input.defaultValue}
                                </span>
                            </Td>
                        ) : (
                            <Td>
                                <span className="text-center">
                                    {input.mappingDetails.map(
                                        (mapping) =>
                                            `${mapping.output.circleData.name};`
                                    )}
                                </span>
                            </Td>
                        )} */}
                        {props.isEditMode ? (
                            <Td drop={drop}>
                                <ConnectionDropdown
                                    {...props}
                                    dropDownOptions={[...dropDownOptionsData]}
                                    selectedOptions={[
                                        ...selectedDropDownOptionsIndex,
                                    ]}
                                    inputAsset={asset}
                                    inputCircleData={input}
                                    addRemoveConnectionWithInitialValues={
                                        addConnectionDefaultValue
                                    }
                                />
                            </Td>
                        ) : (
                            <Td>
                                <span className="text-center">
                                    {input.mappingDetails.map(
                                        (mapping) =>
                                            `${mapping.output.circleData.name};`
                                    )}
                                </span>
                            </Td>
                        )}
                    </Fragment>
                    <Fragment>
                        {props.isEditMode ? (
                            input.dataType === "boolean" ? (
                                <Td>
                                    <Input
                                        dataType="text"
                                        placeholder="Enter default value"
                                        className={ originalInput && 
                                            defaultValue !=
                                                originalInput.defaultValue &&
                                            !(
                                                defaultValue === "" &&
                                                !originalInput.defaultValue
                                            )
                                                ? "changedBorder"
                                                : ""
                                        }
                                        value={defaultValue}
                                        onValueChange={updateDefaultValue}
                                        onLostFocus={() =>
                                            handleDefaultValuefocusOut()
                                        }
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                        disabled={!props.isEditMode}
                                        validator={() =>
                                            defaultValueValidations.isInvalid
                                                ? {
                                                      valid: false,
                                                      text:
                                                          defaultValueValidations.errorMessage ||
                                                          "Invalid input",
                                                  }
                                                : { valid: true, text: "OK!" }
                                        }
                                    />
                                </Td>
                            ) : input.dataType === "array_integer" ||
                              input.dataType === "array_number" ||
                              input.dataType === "array_string" ? (
                                <Td className="initial-value">
                                    {props.input.defaultValue &&
                                        props.input.defaultValue
                                            .split(",")
                                            .map((inputMap, index) => {
                                                let findInput;
                                                let orgDefaultArr;
                                                findInput =
                                                    origFuncData.inputs.find(
                                                        (input: any) =>
                                                            input.name ===
                                                            props.input.name
                                                    );
                                                orgDefaultArr =
                                                    findInput &&
                                                    findInput.defaultValue
                                                        ? findInput.defaultValue.split(
                                                              ","
                                                          )
                                                        : [];
                                                return (
                                                    <div className="initialvalue_array_integer_container">
                                                        <span>
                                                            {props.input
                                                                .mappingDetails &&
                                                            props.input
                                                                .mappingDetails
                                                                .length > 0 &&
                                                            props.input
                                                                .mappingDetails[
                                                                index
                                                            ]
                                                                ? // mapConnectionInitialValue[props.input.name][props.input.mappingDetails[index].output.circleData.name]['index'] === index ?
                                                                  // mapConnectionInitialValue[props.input.mappingDetails[index].output.circleData.id].id ===props.input.mappingDetails[index].output.circleData.id?
                                                                  props.input
                                                                      .mappingDetails[
                                                                      index
                                                                  ].output
                                                                      .circleData
                                                                      .name
                                                                : "Default"}
                                                            {": "}
                                                        </span>
                                                        <div className="initialval_input_content">
                                                            <Input
                                                                dataType="text"
                                                                placeholder="Enter the value"
                                                                className={
                                                                    inputMap !=
                                                                        orgDefaultArr[
                                                                            index
                                                                        ] ||
                                                                    index >=
                                                                        orgDefaultArr.length
                                                                        ? "changedBorder"
                                                                        : ""
                                                                }
                                                                value={
                                                                    props.input
                                                                        .defaultValue
                                                                        ? props.input.defaultValue.split(
                                                                              ","
                                                                          )[
                                                                              index
                                                                          ] ===
                                                                          "0"
                                                                            ? "0"
                                                                            : props.input.defaultValue.split(
                                                                                  ","
                                                                              )[
                                                                                  index
                                                                              ]
                                                                        : null ||
                                                                          ""
                                                                }
                                                                onValueChange={(
                                                                    val: any
                                                                ) =>
                                                                    handleArrayInputs(
                                                                        val,
                                                                        index
                                                                    )
                                                                }
                                                                showValidationBarWhenInvalid={
                                                                    true
                                                                }
                                                                showValidationIconWhenInvalid={
                                                                    true
                                                                }
                                                                instantValidation={
                                                                    true
                                                                }
                                                                disabled={
                                                                    !props.isEditMode ||
                                                                    !switchToggled
                                                                }
                                                                validator={() =>
                                                                    defaultDataTypeValidator[
                                                                        index
                                                                    ] &&
                                                                    defaultDataTypeValidator[
                                                                        index
                                                                    ].isInvalid
                                                                        ? {
                                                                              valid: false,
                                                                              text:
                                                                                  defaultDataTypeValidator[
                                                                                      index
                                                                                  ]
                                                                                      .errorMessage ||
                                                                                  "Invalid input",
                                                                          }
                                                                        : {
                                                                              valid: true,
                                                                              text: "OK!",
                                                                          }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                </Td>
                            ) : input.dataType === "integer" ||
                              input.dataType === "string" ||
                              input.dataType === "number" ? (
                                <Td>
                                    <Input
                                        dataType="text"
                                        placeholder="Enter the value"
                                        className={
                                            defaultValue !=
                                                originalInput.defaultValue &&
                                            !(
                                                defaultValue === "" &&
                                                !originalInput.defaultValue
                                            )
                                                ? "changedBorder"
                                                : ""
                                        }
                                        value={defaultValue}
                                        onValueChange={updateDefaultValue}
                                        onLostFocus={() =>
                                            handleDefaultValuefocusOut()
                                        }
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                        disabled={!props.isEditMode}
                                        validator={() =>
                                            defaultValueValidations.isInvalid
                                                ? {
                                                      valid: false,
                                                      text:
                                                          defaultValueValidations.errorMessage ||
                                                          "Invalid input",
                                                  }
                                                : { valid: true, text: "OK!" }
                                        }
                                    />
                                </Td>
                            ) : input.dataType === "array_boolean" ? (
                                <Td className="initial-value">
                                    {props.input.defaultValue &&
                                        props.input.defaultValue
                                            .split(",")
                                            .map((inputMap, index) => (
                                                <Input
                                                    dataType="text"
                                                    placeholder="Enter default value"
                                                    value={
                                                        props.input.defaultValue
                                                            ? props.input.defaultValue.split(
                                                                  ","
                                                              )[index] === "-1"
                                                                ? ""
                                                                : props.input.defaultValue.split(
                                                                      ","
                                                                  )[index]
                                                            : null || ""
                                                    }
                                                    onValueChange={(val: any) =>
                                                        handleArrayInputs(
                                                            val,
                                                            index
                                                        )
                                                    }
                                                    showValidationBarWhenInvalid={
                                                        true
                                                    }
                                                    showValidationIconWhenInvalid={
                                                        true
                                                    }
                                                    instantValidation={true}
                                                    disabled={
                                                        !props.isEditMode ||
                                                        !switchToggled
                                                    }
                                                    validator={() =>
                                                        defaultDataTypeValidator[
                                                            index
                                                        ] &&
                                                        defaultDataTypeValidator[
                                                            index
                                                        ].isInvalid
                                                            ? {
                                                                  valid: false,
                                                                  text:
                                                                      defaultDataTypeValidator[
                                                                          index
                                                                      ]
                                                                          .errorMessage ||
                                                                      "Invalid input",
                                                              }
                                                            : {
                                                                  valid: true,
                                                                  text: "OK!",
                                                              }
                                                    }
                                                />
                                            ))}
                                </Td>
                            ) : (
                                ""
                            )
                        ) : (
                            <Td>
                                <span className="text-center">
                                    {input.defaultValue}
                                </span>
                            </Td>
                        )}
                    </Fragment>
                </Fragment>
            </Fragment>
        </TR>
    );
};

export default ExpandableInputRows;
