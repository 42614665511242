import { MoveOptions, Connection } from './types';
import { outputCircleRadius } from '../UIHelpers/UiConfig';
import calcLineCurve from '../utils/calcLineCurve';
import { FabricConnectionLine } from '../UIHelpers/FabricConnectionLine';
import { FunctionInputTrigger } from '../IOController/types';
import { FabricHistoryIcon } from '../UIHelpers/FabricHistoryIcon';
import { SamplingAggregateValues } from '../../../utils/constants/appConstants';
import { fabric } from 'fabric';
import AlarmTypeDetail from '../../../transformers/AssetType/AlarmType';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';
import { AlarmEventType, ALARM_TYPE } from '../types';
import EventTypeDetail from '../../../transformers/AssetType/EventType';

class ConnectionController {
    private _connections: Connection[];
    private _canvas: fabric.Canvas;

    constructor({ canvas }: { canvas: fabric.Canvas }) {
        this._canvas = canvas;
        this._connections = [];
    }

    add(line: Connection) {
        const connection: Connection = line as Connection;
        this._connections.push(connection);

        connection.data.input.circleData.connected = true;
        connection.data.output.circleData.connected = true;

        const existingConnection = this.getConnectionById({
            ioId: connection.data.input.circleData.id,
            nodeId: connection.data.input.asset.nodeId,
        });
        if (existingConnection) {
            connection.data.trigger = existingConnection.data.trigger;
            if (connection.data.trigger) {
                connection.triggerOn();
            } else {
                connection.triggerOff();
            }
        }
    }
    //TODO: Refactoring required currently unused.
    handleAddFunctionAlarmInput(options: {
        circleData: AlarmTypeDetail['inputs'][0];
        inputAsset: FunctionTypeDetail | AlarmTypeDetail;
        outputAsset: AlarmTypeDetail | FunctionTypeDetail;
        nodeWidth: number;
        nodeHeight: number;
        alarmType: AlarmEventType;
        pinPosition: { x: number; y: number };
    }) {
        const {
            alarmType,
            circleData,
            inputAsset,
            outputAsset,
            nodeHeight,
            nodeWidth,
            pinPosition,
        } = options;
        const line = new FabricConnectionLine({ left: 0, top: 0 });
        line.data = {
            input: { asset: inputAsset, circleData: { ...circleData } },
            output: {
                asset: outputAsset,
                circleData: { ...outputAsset.outputs[0], connected: true, isPublished: true },
            },
            trigger: false,
            historicalDataTrigger: false,
        };
        this._canvas.add(line);
        this.add(line as Connection);

        if (!outputAsset.isOpen) {
            this.handleToggleNode({
                nodeHeight,
                nodeWidth,
                nodeId: outputAsset.nodeId,
                position: { ...outputAsset.position },
            });
        }
        if (alarmType === ALARM_TYPE.SPECIFIC) {
        }

        // this._ConnectionController.handleIOMove({
        //     nodeHeight: nodeHeight,
        //     nodeWidth: nodeWidth,
        //     ioId: alarmPin.ioCircle.data.circleData.id,
        //     nodeId: node.data.asset.nodeId,
        //     position: {
        //         x: pinPosition.x,
        //         y: pinPosition.y,
        //     },
        // });

        this.handleRedrawLines(inputAsset.nodeId);
    }

    handleIOMove = (options: MoveOptions) => {
        const lines = this._connections.map((line) => {
            if (
                line.data.input.asset.nodeId === options.nodeId ||
                line.data.output.asset.nodeId === options.nodeId
            ) {
                return line;
            } else {
                return false;
            }
        });

        if (lines.length > 0) {
            lines.forEach((line) => {
                if (line && line.path) {
                    if (line.data.input.circleData.id === options.ioId) {
                        line.path[0][1] = options.position.x;
                        line.path[0][2] = options.position.y;
                        const asset = line.data.input.asset;
                        if (asset.nodeId === options.nodeId && !asset.isOpen) {
                            line.path[0][1] = asset.position.x - options.nodeWidth / 2;
                            line.path[0][2] = asset.position.y - options.nodeHeight / 2;
                        }
                    } else if (line.data.output.circleData.id === options.ioId) {
                        line.path[1][3] = options.position.x;
                        line.path[1][4] = options.position.y;
                        const asset = line.data.output.asset;
                        if (asset.nodeId === options.nodeId && !asset.isOpen) {
                            line.path[1][3] = asset.position.x + options.nodeWidth / 2;
                            line.path[1][4] = asset.position.y - options.nodeHeight / 2;
                        }
                    }

                    line.rePosition();

                    line.path = calcLineCurve(
                        line.path,
                        line.data.input.asset === line.data.output.asset,
                        !line.data.input.asset.isOpen,
                        !line.data.output.asset.isOpen
                    );
                    console.log('line path io move :', line);
                }
            });
        }
    };

    handleToggleNode = (options: {
        nodeId: string;
        nodeWidth: number;
        nodeHeight: number;
        position: { x: number; y: number };
    }) => {
        const { nodeHeight, nodeId, nodeWidth, position } = options;
        const lines = this._connections.map((line) => {
            if (
                line.data.input.asset.nodeId === options.nodeId ||
                line.data.output.asset.nodeId === options.nodeId
            ) {
                return line;
            } else {
                return false;
            }
        });

        if (lines.length > 0) {
            lines.forEach((line) => {
                if (line && line.path) {
                    if (line.data.input.asset.nodeId === nodeId) {
                        const asset = line.data.input.asset;
                        if (asset.nodeId === options.nodeId) {
                            line.path[0][1] =
                                position.x -
                                options.nodeWidth / 2 -
                                outputCircleRadius * asset.scale;
                            line.path[0][2] = position.y;
                        }
                    }
                    if (line.data.output.asset.nodeId === nodeId) {
                        const asset = line.data.output.asset;
                        if (asset.nodeId === options.nodeId) {
                            line.path[1][3] =
                                position.x +
                                options.nodeWidth / 2 +
                                outputCircleRadius * asset.scale;
                            line.path[1][4] = position.y;
                        }
                    }

                    line.path = calcLineCurve(
                        line.path,
                        line.data.input.asset === line.data.output.asset,
                        !line.data.input.asset.isOpen,
                        !line.data.output.asset.isOpen
                    );
                    console.log(line.path);
                    line.rePosition();
                }
            });
        }
    };

    handleRedrawLines = (nodeId: string) => {
        const lines = this._connections.filter((line) => {
            return (
                line.data.input.asset.nodeId === nodeId || line.data.output.asset.nodeId === nodeId
            );
        });

        lines.forEach((line) => {
            this._canvas.remove(line);
            line.rePosition();
            this._canvas.add(line);
        });
    };

    handleTrigger(target: FunctionInputTrigger, reverify = false) {
        const { nodeId } = target.data.asset;
        const { id } = target.data.circleData;

        const inputs = this._connections.filter((line) => {
            return line.data.input.circleData.id === id;
        });
        inputs.forEach((input,index) => {
            if (input) {
                input.data.trigger = reverify ? input.data.trigger : !input.data.trigger;
                if (input.data.input.circleData.defaultValue) {
                    let defaultValue = input.data.input.circleData.defaultValue.split(',')[index];
                    input.data.tooltip = "Initial Value: " + defaultValue
                }
                if (!input.data.trigger) {
                    target.triggerOff();
                    input.triggerOff();
                } else {
                    target.triggerOn();
                    input.triggerOn();
                }

                this._canvas.renderAll();
            }
        });
    }

    handleHistoricalDataTrigger(target: FabricHistoryIcon) {
        const { nodeId } = target.data.asset;
        const { id } = target.data.circleData;

        const inputs = this._connections.filter((line) => {
            return line.data.input.circleData.id === id;
        });

        if (inputs.length === 1) {
            target.triggerOn();
            inputs[0].data.historicalDataTrigger = true;
        } else {
            target.triggerOff();
            this.clearHistoricalData(nodeId, id);
            inputs.forEach((input) => {
                if (input) {
                    input.data.historicalDataTrigger = false;
                }
            });
        }
        this._canvas.renderAll();
    }

    handleDeleteConnection = (line: FabricConnectionLine) => {
        this._connections = this._connections.filter((connection) => {
            if (connection === line) {
                const inputCircle = connection.data.input.circleData;
                const outputCircle = connection.data.output.circleData;

                if (inputCircle.dataType.includes('array')) {
                    if (
                        this.getAllConnectionsById({
                            ioId: inputCircle.id,
                            nodeId: connection.data.input.asset.nodeId,
                        }).length > 1
                    ) {
                    } else {
                        inputCircle.connected = false;
                    }
                } else {
                    inputCircle.connected = false;
                }

                const isOutputConnected =
                    this.getAllConnectionsById({
                        ioId: outputCircle.id,
                        nodeId: connection.data.output.asset.nodeId,
                    }).length > 1;

                if (isOutputConnected) {
                    outputCircle.connected = true;
                } else {
                    outputCircle.connected = false;
                }

                return false;
            } else {
                return true;
            }
        });
    };

    handleDeleteNode = (nodeId: string) => {
        const affectedNodes: { nodeId: string; ioId: string }[] = [];
        this._connections = this._connections.filter((connection) => {
            if (
                connection.data.input.asset.nodeId === nodeId ||
                connection.data.output.asset.nodeId === nodeId
            ) {
                this._canvas.remove(connection);

                if (connection.data.input.asset.nodeId === nodeId) {
                    affectedNodes.push({
                        nodeId: connection.data.output.asset.nodeId,
                        ioId: connection.data.output.circleData.id,
                    });
                } else {
                    affectedNodes.push({
                        nodeId: connection.data.input.asset.nodeId,
                        ioId: connection.data.input.circleData.id,
                    });
                }

                return false;
            }

            return true;
        });

        return affectedNodes;
    };

    /**
     * @param {string} nodeId
     * @returns alarmEventBlock nodeId array.
     */
    getAssociatedAlarmEventBlocks(nodeId: string) {
        const alarmIdSet = new Set<string>();
        this._connections.forEach((connection) => {
            if (connection.data.input.asset.nodeId === nodeId) {
                if (
                    connection.data.output.asset instanceof AlarmTypeDetail ||
                    connection.data.output.asset instanceof EventTypeDetail
                ) {
                    alarmIdSet.add(connection.data.output.asset.nodeId);
                }
            }
            if (connection.data.output.asset.nodeId === nodeId) {
                if (
                    connection.data.input.asset instanceof AlarmTypeDetail ||
                    connection.data.input.asset instanceof EventTypeDetail
                ) {
                    alarmIdSet.add(connection.data.input.asset.nodeId);
                }
            }
        });
        return Array.from(alarmIdSet);
    }

    // TODO: Check for this function use.
    handleFunctionNameChange(options: { oldFunctionName: string; newFunctionName: string }) {
        const { oldFunctionName, newFunctionName } = options;

        this._connections.forEach((connection) => {
            if (connection.data.input.asset.nodeId === oldFunctionName) {
                connection.data.input.asset.nodeId = newFunctionName;
                connection.data.input.circleData.id = connection.data.input.circleData.id.replace(
                    oldFunctionName,
                    newFunctionName
                );
                connection.data.input.circleData.path =
                    connection.data.input.circleData.path.replace(oldFunctionName, newFunctionName);
            }

            if (connection.data.output.asset.nodeId === oldFunctionName) {
                connection.data.output.asset.nodeId = oldFunctionName;
                connection.data.output.circleData.id = connection.data.output.circleData.id.replace(
                    oldFunctionName,
                    newFunctionName
                );
                connection.data.output.circleData.path =
                    connection.data.output.circleData.path.replace(
                        oldFunctionName,
                        newFunctionName
                    );
            }
        });
    }

    handleHistoricalData(options: {
        nodeId: string;
        timeWindow: string;
        samplingAggregate?: SamplingAggregateValues;
        samplingWindow?: string;
        limit?: string;
        ioId: string;
    }) {
        const { nodeId, timeWindow, samplingWindow, samplingAggregate, limit, ioId } = options;
        this._connections.forEach((connection) => {
            if (
                connection.data.input.asset.nodeId === nodeId &&
                connection.data.input.circleData.id === ioId
            ) {
                if (timeWindow) {
                    connection.data.input.circleData.timeWindow = timeWindow;
                } else {
                    delete connection.data.input.circleData['timeWindow'];
                }
                if (samplingAggregate) {
                    connection.data.input.circleData.samplingAggregate = samplingAggregate;
                } else {
                    delete connection.data.input.circleData['samplingAggregate'];
                }
                if (samplingWindow) {
                    connection.data.input.circleData.samplingWindow = samplingWindow;
                } else {
                    delete connection.data.input.circleData['samplingWindow'];
                }
                if (limit) {
                    connection.data.input.circleData.limit = limit;
                } else {
                    delete connection.data.input.circleData['limit'];
                }
            }
        });
    }

    clearHistoricalData(nodeId: string, ioId: string) {
        this._connections.forEach((connection) => {
            if (
                connection.data.input.asset.nodeId === nodeId &&
                connection.data.input.circleData.id === ioId
            ) {
                delete connection.data.input.circleData['timeWindow'];
                delete connection.data.input.circleData['samplingAggregate'];
                delete connection.data.input.circleData['samplingWindow'];
                delete connection.data.input.circleData['limit'];
            }
        });
    }

    getConnectionById = (options: { ioId: string; nodeId: string }) => {
        const { ioId, nodeId } = options;
        return this._connections.find((connection) => {
            return (
                (connection.data.input.asset.nodeId === nodeId &&
                    connection.data.input.circleData.id === ioId) ||
                (connection.data.output.asset.nodeId === nodeId &&
                    connection.data.output.circleData.id === ioId)
            );
        });
    };

    getConnectionByNodeId = (nodeId: string) => {
        console.log('connections', this._connections);
        return this._connections.find((connection) => {
            return (
                connection.data.input.asset.nodeId === nodeId ||
                connection.data.output.asset.nodeId === nodeId
            );
        });
    };
    getAllConnectionsByNodeId = (nodeId: string) => {
        return this._connections.filter((connection) => {
            return (
                connection.data.input.asset.nodeId === nodeId ||
                connection.data.output.asset.nodeId === nodeId
            );
        });
    };

    getAllConnectionsById = (options: { ioId: string; nodeId: string }) => {
        const { ioId, nodeId } = options;
        return this._connections.filter((connection) => {
            return (
                (connection.data.input.asset.nodeId === nodeId &&
                    connection.data.input.circleData.id === ioId) ||
                (connection.data.output.asset.nodeId === nodeId &&
                    connection.data.output.circleData.id === ioId)
            );
        });
    };

    checkConnectionBetweenIO = (options: {
        inputIoId: string;
        outputIoId: string;
        inputNodeId: string;
        outputNodeId: string;
    }) => {
        const { inputIoId, inputNodeId, outputIoId, outputNodeId } = options;
        return this._connections.find((connection) => {
            const data = connection.data;
            return (
                data.input.asset.nodeId === inputNodeId &&
                data.output.asset.nodeId === outputNodeId &&
                data.input.circleData.id === inputIoId &&
                data.output.circleData.id === outputIoId
            );
        });
    };

    getAllConnections() {
        return this._connections;
    }

    clear() {
        this._connections = [];
    }
}

export default ConnectionController;
