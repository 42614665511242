import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Input, Button } from "@abb/abb-common-ux-react";
import { connect } from "react-redux";
import { StoreState } from "../../store";
import { ModalFooter } from "reactstrap";
import "./style.scss";
import { changeZipFileNameForExport } from "../../store/exportAsset/actions";
import { Dispatch } from "redux";
import { hideModal } from "../../store/modal/action";
import { validateForSwedishAndFinishCharacters } from "../../routes/Functions/helper";
import CustomABBInput from "../CustomABBInput";

interface GetExportZipNameProps {
    exportMethod: () => any;
    defaultFileName?: string;
}
const GetExportZipNameModal = (
    props: GetExportZipNameProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    console.log(props);
    const { exportMethod } = props;
    const [fileName, setFileName] = useState(props.defaultFileName || "");
    const [fileNameError, setFileNameError] = useState("");

    const isSubmitBtnDisabled = useMemo(() => {
        return fileName.length === 0 || fileNameError.length > 0;
    }, [fileName, fileNameError]);

    useEffect(() => {
        props.changeExportZipName("");
        setFileNameError("");
    }, []);

    useEffect(() => {
        setFileNameError("");
    }, [props.exportFileName]);

    const handleFileNameChange = useCallback(
        (value: string) => {
            setFileName(value);
            if (value.trim() === "") {
                setFileNameError("File Name Cannot be Empty");
            } else {
                setFileNameError("");
            }
        },
        [setFileName]
    );

    const handleSubmit = (
        event:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<
                  HTMLButtonElement | HTMLAnchorElement,
                  MouseEvent
              >
    ) => {
        event.preventDefault();
        props.changeExportZipName(fileName);
        exportMethod();
        props.closeModalChangeName();
    };

    const handleClose = (
        event:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<
                  HTMLButtonElement | HTMLAnchorElement,
                  MouseEvent
              >
    ) => {
        event.preventDefault();
        props.changeExportZipName("");
        props.closeModalChangeName();
    };

    return (
        <form className="wrapper-export-zip-modal" onSubmit={handleSubmit}>
            <div className="export-zip-container">
                <CustomABBInput
                    className="export-zip-input"
                    label={"File Name"}
                    suffix={".zip"}
                    dataType={"text"}
                    value={fileName}
                    placeholder={"Enter File Name"}
                    onValueChange={handleFileNameChange}
                    validator={() =>
                        fileNameError.length > 0
                            ? { valid: false, text: fileNameError }
                            : { valid: true, text: "OK!" }
                    }
                    instantValidation
                    showValidationBarWhenInvalid
                    showValidationIconWhenInvalid
                />
            </div>
            <ModalFooter>
                <div className="footer-part">
                    <Button
                        text={"Export"}
                        type="primary-blue"
                        sizeClass="small"
                        disabled={isSubmitBtnDisabled}
                        onClick={handleSubmit}
                    />
                    <Button
                        style={{
                            marginRight: "10px",
                        }}
                        className="submit-btn"
                        text={"Cancel"}
                        type="discreet-black"
                        sizeClass="small"
                        onClick={handleClose}
                    />
                </div>
            </ModalFooter>
        </form>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        exportFileName: state.exportAsset.zipFileName,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeExportZipName: (fileName: string) => {
            dispatch(changeZipFileNameForExport({ fileName }));
        },
        closeModalChangeName: () => dispatch(hideModal()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetExportZipNameModal);
