import {
    VIEW_MODE,
    CONFIGURATION_TOOL_MODE,
    CONFIGURATION_TOOL_PRIVILEGES,
    OVERALL_SEVERITY_FUNCTION,
} from '../../utils/constants/appConstants';
import { ComputeModelFromJson } from '../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import {
    ConfigurationToolState,
    Actions,
    VERSION_UPDATE_TYPE,
    OriginalFunctionTypes,
    LatestFunctionDataPayload,
} from './types';
import { ActionTypes } from '../actionTypes';
import _ from 'lodash';

import TableController, { TableData } from '../../model/TableController';
import FunctionTypeDetail, {
    CreateFunctionTypeDetail,
} from '../../transformers/AssetType/FunctionType';
import { LocalJson, PinsType, PINS_TYPE } from '../../transformers/ComputeModel/fromJson/types';
import { markConditionDataDirty, updateJsonWithOriginalFunctionDetails } from '../../utils/helpers';
import { dataTypeConverterToJson } from '../../transformers/utils/dataTypeConverter';
import ObjectTypeDetail from '../../transformers/AssetType/ObjectType';
import { store } from '../../containers/AppBoot';

const initialState: ConfigurationToolState = {
    mode: CONFIGURATION_TOOL_MODE.VIEW,
    activeView: VIEW_MODE.DIAGRAM,
    json: new ComputeModelFromJson().json,
    privileges: CONFIGURATION_TOOL_PRIVILEGES.MODELS_PAGE,
    tableData: {
        functionTypeDetails: [],
        objectTypeDetails: [],
        alarmTypeDetails: [],
        eventTypeDetails: [],
    },
    canvasController: null,
    isDirty: false,
    isOverAllSeverityFuncAdded: false,
    overallSeverityFunctionId: '',
    severityFunctionData: undefined,
    selectedFunctionDetails: null,
    showFunctionLoading: false,
    versionUpdateType: VERSION_UPDATE_TYPE.DEFAULT,
    originalFunctionTypesUsed: { byIdWithVersion: {}, entities: [] },
    latestOriginalFunctionsUsed: { byIdWithVersion: {}, entities: [] },
    addIsDirtyToConditions: false,
    isOverlapAllowed: false,
    latestFunctionTypes: {} as LatestFunctionDataPayload,
    isLatestFuncLoading: false,
    isUpdatedWithLatestFunction: false,
    canvasZoom: 1,
    undoStack: [],
    redoStack: [],
    previousJson: undefined,
    isSeverityUpdate: false,
    configPopupDetails: undefined,
    showConfigPopup: false,
    modelsForTrigger: [],
    alarmsForModels: {},
    eventsForModels: {},
};

export function configurationToolReducer(
    state = initialState,
    action: Actions
): ConfigurationToolState {
    switch (action.type) {
        case ActionTypes.UPDATE_MODELS_FOR_TRIGGERS: {
            const { models } = action.payload;

            return {
                ...state,
                modelsForTrigger: models,
            };
        }

        case ActionTypes.FETCH_ALARMS_FOR_MODELS: {
            return {
                ...state,
            };
        }

        case ActionTypes.UPDATE_ALARMS_FOR_MODELS: {
            const { modelId, alarms } = action.payload;
            state.alarmsForModels[modelId] = alarms;

            return {
                ...state,
            };
        }

        case ActionTypes.FETCH_EVENTS_FOR_MODELS: {
            return {
                ...state,
            };
        }

        case ActionTypes.UPDATE_EVENTS_FOR_MODELS: {
            const { modelId, events } = action.payload;
            state.eventsForModels[modelId] = events;

            return {
                ...state,
            };
        }

        case ActionTypes.UPDATE_CONFIGURATION_TOOL_JSON: {
            const { tableData } = action.payload;
            state.tableData = tableData;
            debugger;
            if (state.previousJson && !_.isEqual(state.json, state.previousJson)) {
                debugger;
                state.undoStack.push(_.cloneDeep(state.previousJson));
            } else {
                state.undoStack.push(_.cloneDeep(state.json));
            }
            const json = TableController.toJSON({ tableData: state.tableData });
            if (
                state.activeView === VIEW_MODE.DIAGRAM &&
                // activeView === VIEW_MODE.TABLE &&
                state.canvasController
            ) {
                state.canvasController && state.canvasController.fromJSON({ json });
            }

            return {
                ...state,
                json,
                previousJson: _.cloneDeep(state.json),
                isDirty: true,
                tableData: { ...state.tableData },
                undoStack: [...state.undoStack],
                redoStack: [],
            };
        }

        case ActionTypes.CREATE_CONFIGURATION_TOOL_JSON: {
            const { json } = action.payload;
            const mode = action.payload.mode || state.mode;
            const { activeView, canvasController } = state;
            const canvasJson = _.cloneDeep(json);

            const isEditMode =
                mode === CONFIGURATION_TOOL_MODE.EDIT ||
                mode === CONFIGURATION_TOOL_MODE.CREATE ||
                mode === CONFIGURATION_TOOL_MODE.IMPORT;

            if (
                activeView === VIEW_MODE.DIAGRAM &&
                // activeView === VIEW_MODE.TABLE &&
                canvasController
            ) {
                canvasController.fromJSON({
                    json: canvasJson,
                    editMode: isEditMode,
                });
            } else if (activeView === VIEW_MODE.TABLE && canvasController) {
                canvasController.fromJSON({
                    json: canvasJson,
                    editMode: isEditMode,
                });
                state.tableData = TableController.fromJSON({ canvasJson });
            }
            if (!isEditMode) {
                state.overallSeverityFunctionId = '';
                state.isOverAllSeverityFuncAdded = false;
                state.isUpdatedWithLatestFunction = false;
            }

            return {
                ...state,
                json: canvasJson,
                previousJson: _.cloneDeep(canvasJson),
                mode: mode,
                undoStack: [],
                redoStack: [],
                isDirty: false,
            };
        }

        case ActionTypes.UPDATE_CONFIGURATION_TOOL_ACTIVE_VIEW: {
            let activeView = action.payload ? action.payload.activeView : undefined;

            if (!activeView) {
                activeView =
                    state.activeView === VIEW_MODE.DIAGRAM ? VIEW_MODE.TABLE : VIEW_MODE.DIAGRAM;
            }
            const { tableData, canvasController } = state;

            if (activeView === VIEW_MODE.DIAGRAM) {
                // const canvasJson = TableController.toJSON({ tableData });
                // console.log(canvasJson);
                if (canvasController) {
                    canvasController.fromJSON({
                        json: state.json,
                        editMode:
                            state.mode === CONFIGURATION_TOOL_MODE.EDIT ||
                            state.mode === CONFIGURATION_TOOL_MODE.CREATE ||
                            state.mode === CONFIGURATION_TOOL_MODE.IMPORT,
                    });
                }
            } else {
                // if (canvasController) {
                state.tableData = TableController.fromJSON({
                    canvasJson: state.json,
                });
                // }
            }
            return { ...state, activeView };
        }

        case ActionTypes.UPDATE_CONFIGURATION_TOOL_MODE: {
            const { mode } = action.payload;
            const { canvasController } = state;
            // Update canvas
            if (canvasController) {
                if (mode === CONFIGURATION_TOOL_MODE.EDIT) {
                    console.log('canvas to edit mode');
                    canvasController.handleEditMode();
                } else {
                    console.log('canvas to view mode');
                    canvasController.handleViewMode();
                }
            }
            if (mode === CONFIGURATION_TOOL_MODE.VIEW) {
                state.isOverAllSeverityFuncAdded = false;
                state.overallSeverityFunctionId = '';
                state.isUpdatedWithLatestFunction = false;
                state.isDirty = false;
                state.undoStack = [];
                state.redoStack = [];
            }
            state.versionUpdateType = VERSION_UPDATE_TYPE.DEFAULT;

            return { ...state, mode };
        }

        // case ActionTypes.UPDATE_CONFIGURATION_TOOL_PRIVILEGES:{
        //   const {privileges} = action.payload
        //   return {...state, privileges}
        // }

        case ActionTypes.INITIALIZE_CONFIGURATION_TOOL: {
            // clear previous controller if present
            if (state.canvasController) {
                state.canvasController.handleUnmount();
            }

            const { canvasController } = action.payload;
            const activeView = action.payload.activeView || initialState.activeView;
            const json = _.cloneDeep(action.payload.json || initialState.json);
            const mode = action.payload.mode || initialState.mode;
            const privileges = action.payload.privileges || initialState.privileges;

            return {
                ...state,
                canvasController,
                activeView,
                json,
                previousJson: _.cloneDeep(json),
                mode,
                privileges,
                undoStack: [],
                redoStack: [],
            };
        }

        // case ActionTypes.UPDATE_ACTIVE_MODEL_JSON: {
        //     const { canvasController } = state;
        //     if (canvasController) {
        //         state.json = canvasController.toJSON();
        //     }
        //     return { ...state };
        // }

        case ActionTypes.UPDATE_OVERALL_SEVERITY_FUNCTION_STATUS: {
            const isOverAllSeverityFuncAdded = action.payload;
            return {
                ...state,
                isOverAllSeverityFuncAdded,
            };
        }
        case ActionTypes.UPDATE_OVERAll_SEVERITY_FUNCTION_ID: {
            const overallSeverityFunctionId = action.payload;
            return {
                ...state,
                overallSeverityFunctionId,
            };
        }

        case ActionTypes.UPDATE_OVERALL_SEVERITY_FUNC_DATA: {
            const severityFunctionData = action.payload;
            return {
                ...state,
                severityFunctionData,
            };
        }
        case ActionTypes.GET_SELECTED_FUNCTION_DETAIL: {
            return {
                ...state,
                showFunctionLoading: true,
            };
        }
        case ActionTypes.SET_SELECTED_FUNCTION_DETAILS: {
            const selectedFunctionDetails = action.payload;
            return {
                ...state,
                selectedFunctionDetails,
                showFunctionLoading: false,
            };
        }

        case ActionTypes.HANDLE_FUNCTION_DETAILS_LOADING: {
            const showFunctionLoading = action.payload;
            return {
                ...state,
                showFunctionLoading,
            };
        }

        case ActionTypes.SET_VERSION_UPDATE_TYPE: {
            const versionUpdateType = action.payload;
            return {
                ...state,
                versionUpdateType,
            };
        }

        case ActionTypes.SET_ORIGINAL_FUNCTION_TYPES: {
            const { originalFunctionTypes, currentRoutePath } = action.payload;
            const { byIdWithVersion, entities } = originalFunctionTypes;
            const originalFunctionTypesUsed: OriginalFunctionTypes = {
                byIdWithVersion,
                entities,
            };
            return {
                ...state,
                originalFunctionTypesUsed,
            };
        }

        case ActionTypes.SET_LATEST_ORIGINAL_FUNCTIONS: {
            const { latestOriginalFunctions, currentRoutePath } = action.payload;
            const { byIdWithVersion, entities } = latestOriginalFunctions;
            const latestOriginalFunctionsUsed: OriginalFunctionTypes = {
                byIdWithVersion,
                entities,
            };
            return {
                ...state,
                latestOriginalFunctionsUsed,
            };
        }

        case ActionTypes.ADD_TO_ORIGINAL_SELECTED_FUNCTION: {
            const originalFunctionDetails = action.payload;
            if (originalFunctionDetails) {
                const { assetRef, assetVersion } = originalFunctionDetails;
                const lastIndex = assetRef.lastIndexOf('@');
                let typeId = assetRef;

                if (lastIndex !== -1) {
                    typeId = typeId.slice(0, lastIndex);
                }
                const funcIdWithVersion = `${typeId}#${assetVersion}`;
                if (!state.originalFunctionTypesUsed.byIdWithVersion[funcIdWithVersion]) {
                    state.originalFunctionTypesUsed.byIdWithVersion[funcIdWithVersion] =
                        originalFunctionDetails;
                    state.originalFunctionTypesUsed.entities = [
                        ...state.originalFunctionTypesUsed.entities,
                        funcIdWithVersion,
                    ];
                }
            }
            return {
                ...state,
            };
        }

        case ActionTypes.GET_ORIGINAL_FUNCTION_DATA_FROM_JSON: {
            const { currentRoutePath, json } = action.payload;
            const originalJson = { ...json.json };
            return {
                ...state,
                json: { ...originalJson },
                previousJson: _.cloneDeep(originalJson),
            };
        }
        case ActionTypes.ADD_IS_DIRTY_TO_CONDITION: {
            const addIsDirtyToConditions = action.payload;
            return { ...state, addIsDirtyToConditions };
        }
        case ActionTypes.HANDLE_ALLOW_OVERLAP_ON_CANVAS: {
            const isOverlapAllowed = action.payload;
            return {
                ...state,
                isOverlapAllowed,
            };
        }

        case ActionTypes.GET_LATEST_FUNCTIONS_FOR_TYPE: {
            return {
                ...state,
                isLatestFuncLoading: true,
            };
        }
        case ActionTypes.UPDATE_LATEST_FUNCTION_DATA_PAYLOAD: {
            const latestFunctionTypes = action.payload;
            return {
                ...state,
                latestFunctionTypes,
                isLatestFuncLoading: false,
            };
        }
        case ActionTypes.HANDLE_LATEST_FUNCTION_DATA_LOADING: {
            return {
                ...state,
                isLatestFuncLoading: action.payload,
            };
        }

        case ActionTypes.UPDATE_CONFIGURATION_TOOL_JSON_WITH_LATEST_FUNCTIONS: {
            const json = action.payload;
            return {
                ...state,
                json,
            };
        }

        case ActionTypes.UPDATE_CURRENT_SELECTED_FUNCTION_WITH_LATEST_DATA: {
            return {
                ...state,
                isUpdatedWithLatestFunction: true,
            };
        }

        case ActionTypes.HANDLE_CURRENT_FUNCTION_UPDATE_STATUS: {
            return {
                ...state,
                isUpdatedWithLatestFunction: action.payload,
            };
        }
        case ActionTypes.UPDATE_ORIGINAL_FUNCTION_TYPES: {
            const originalFunctionTypes = action.payload;
            return {
                ...state,
                originalFunctionTypesUsed: originalFunctionTypes,
            };
        }
        case ActionTypes.MARK_COMPUTE_MODEL_DIRTY: {
            const { canvasController } = state;
            if (canvasController) {
                let oldJSON = _.cloneDeep(state.json);
                let json = canvasController.toJSON();
                state.json = json;
                state.previousJson = _.cloneDeep(json);
                if (!_.isEqual(oldJSON, json) && !state.isSeverityUpdate) {
                    state.undoStack.push(oldJSON);
                    state.redoStack = [];
                }
                if (state.isSeverityUpdate) {
                    state.isSeverityUpdate = false;
                }

                state.tableData = TableController.fromJSON({
                    canvasJson: state.json,
                });
                return {
                    ...state,
                    isDirty: true,
                    undoStack: [...state.undoStack],
                    redoStack: [...state.redoStack],
                };
            }

            return state;
        }

        case ActionTypes.SET_ZOOM: {
            return { ...state, canvasZoom: action.payload };
        }

        case ActionTypes.UNDO: {
            const { activeView, canvasController } = state;
            if (state.undoStack.length === 0 || !canvasController) {
                return state;
            }
            const json = state.undoStack.pop();
            const mode = state.mode;
            let currentJSON =
                activeView === VIEW_MODE.DIAGRAM
                    ? _.cloneDeep(canvasController.toJSON())
                    : _.cloneDeep(TableController.toJSON({ tableData: state.tableData }));
            if (activeView === VIEW_MODE.DIAGRAM){
                canvasController.fromJSON({
                    json: json!,
                });
            }
            if (activeView === VIEW_MODE.TABLE) {
                state.tableData = TableController.fromJSON({ canvasJson: json! });
            }
            state.redoStack.push(currentJSON);
            state.json = json!;
            state.previousJson = _.cloneDeep(json!);
            return { ...state, undoStack: [...state.undoStack], redoStack: [...state.redoStack] };
        }

        case ActionTypes.REDO: {
            const { activeView, canvasController } = state;
            if (state.redoStack.length === 0 || !canvasController) {
                return state;
            }
            const json = state.redoStack.pop();
            const mode = state.mode;
            let currentJSON =
                activeView === VIEW_MODE.DIAGRAM
                    ? _.cloneDeep(canvasController.toJSON())
                    : _.cloneDeep(TableController.toJSON({ tableData: state.tableData }));

            if (activeView === VIEW_MODE.DIAGRAM){
                canvasController.fromJSON({
                    json: json!,
                });
            }

            if (activeView === VIEW_MODE.TABLE) {
                state.tableData = TableController.fromJSON({ canvasJson: json! });
            }
            state.undoStack.push(currentJSON);
            state.json = json!;
            state.previousJson = _.cloneDeep(json!);
            return { ...state, undoStack: [...state.undoStack], redoStack: [...state.redoStack] };
        }

        case ActionTypes.CLEAR_UNDO_REDO_STACK: {
            return {
                ...state,
                undoStack: [],
                redoStack: [],
            };
        }

        case ActionTypes.UPDATE_IS_SEVERITY_CHANGE: {
            return {
                ...state,
                isSeverityUpdate: true,
            };
        }

        case ActionTypes.SHOW_CONFIG_POPUP: {
            return {
                ...state,
                configPopupDetails: { ...action.payload },
                showConfigPopup: true,
            };
        }
        case ActionTypes.HIDE_CONFIG_POPUP: {
            return {
                ...state,
                configPopupDetails: undefined,
                showConfigPopup: false,
            };
        }
        case ActionTypes.UPDATE_TIME_TRIGGER_FOR_FUNCTION: {
            const { nodeId, timeTrigger } = action.payload;
            let functionAsset = state.json.assetData.filter((asset) => {
                return asset instanceof FunctionTypeDetail && asset.nodeId === nodeId
            })[0] as FunctionTypeDetail;
            functionAsset.timeTrigger = `${timeTrigger}`;
            return {
                ...state
            }
        }
        default:
            return state;
    }
}

export function updateFunctionDataWithConnectionPreserved(options: {
    currentFunctionDetail: FunctionTypeDetail;
    latestFunctionDetail: FunctionTypeDetail;
}): FunctionTypeDetail {
    const { currentFunctionDetail, latestFunctionDetail } = options;
    const {
        inputs: currentInputs,
        outputs: currentOutputs,
        inhibits: currentInhibits,
        alarmInputs: currentAlarmInputs,
        eventInputs: currentEventInputs,
        variableChangeTrigger: currentVariableChangeTrigger,
    } = currentFunctionDetail;
    const {
        inputs: latestInputs,
        outputs: latestOutputs,
        inhibits: latestInhibits,
        alarmInputs: latestAlarmInputs,
        eventInputs: latestEventInputs
    } = latestFunctionDetail;
    const variableChangeTrigger = {} as FunctionTypeDetail['variableChangeTrigger'];

    let currentFunctionVersion = currentFunctionDetail.assetVersion.split('.');
    let latestFunctionVersion = latestFunctionDetail.assetVersion.split('.');

    let isMinorVersionAvailable = currentFunctionVersion[0] === latestFunctionVersion[0] && currentFunctionVersion[1] < latestFunctionVersion[1];

    const currentInputsObj: { [key: string]: FunctionTypeDetail['inputs'][0] } =
        currentInputs.reduce((o, item) => ({ ...o, [item.id]: item }), {});
    const currentOutputsObj: { [key: string]: FunctionTypeDetail['outputs'][0] } =
        currentOutputs.reduce((o, item) => ({ ...o, [item.id]: item }), {});

    const updatedInputs: FunctionTypeDetail['inputs'] = [];
    latestInputs.forEach((item) => {
        const currentInputItem = currentInputsObj[item.id];
        if (
            currentInputItem &&
            (_.isEqual(currentInputItem, item) ||
                (currentInputItem.id === item.id &&
                    currentInputItem.name === item.name &&
                    currentInputItem.dataType === item.dataType &&
                    currentInputItem.path === item.path))
        ) {
            updatedInputs.push(currentInputsObj[item.id]);
            variableChangeTrigger![item.name] = { ...currentVariableChangeTrigger![item.name] };
        } else {
            updatedInputs.push(item);
            variableChangeTrigger![item.name] = {
                ...dataTypeConverterToJson({ dataType: item.dataType }),
                link: '',
            };
        }
    });

    const updatedInhibits = [...currentInhibits];
    currentInhibits.forEach((inhibit) => {
        variableChangeTrigger![inhibit.name] = {
            ...currentVariableChangeTrigger![inhibit.name],
        };
    });
    const updatedOutputs: FunctionTypeDetail['outputs'] = [];
    latestOutputs.forEach((item) => {
        const currentOutputItem = currentOutputsObj[item.id];
        if (
            currentOutputItem &&
            (_.isEqual(currentOutputItem, item) ||
                (currentOutputItem.id === item.id &&
                    currentOutputItem.name === item.name &&
                    currentOutputItem.dataType === item.dataType &&
                    currentOutputItem.path === item.path))
        ) {
            updatedOutputs.push(currentOutputsObj[item.id]);
        } else {
            updatedOutputs.push(item);
        }
    });
    latestFunctionDetail.inhibits = updatedInhibits;
    latestFunctionDetail.inputs = updatedInputs;
    latestFunctionDetail.outputs = updatedOutputs;
    latestFunctionDetail.alarmInputs = currentAlarmInputs;
    latestFunctionDetail.eventInputs = currentEventInputs;
    latestFunctionDetail.variableChangeTrigger = { ...variableChangeTrigger };
    if (currentFunctionDetail.conditions && latestFunctionDetail.conditions) {
        latestFunctionDetail.conditionsConfigurationVariables = [
            ...currentFunctionDetail.conditionsConfigurationVariables,
        ];
        if (latestFunctionDetail.conditions) {
            const { overRiddenCondition } = markConditionDataDirty({
                originalConditons: latestFunctionDetail.conditions,
                changedConditions: undefined,
            });
            let overRiddenDirtyCondition = overRiddenCondition;
            // if (currentFunctionDetail.conditions) {
            //     const { overRiddenCondition: overRiddenUpdatedCondition } = markConditionDataDirty({
            //         originalConditons: currentFunctionDetail.conditions,
            //         changedConditions: overRiddenCondition,
            //     });
            //     overRiddenDirtyCondition = overRiddenUpdatedCondition;
            //     if (currentFunctionDetail.isUpdateAvailable) {
            //         // to handle the condition data change from refesh button or update button.
            //         latestFunctionDetail.hasOverRiddenConditions = true;
            //     }
            //     latestFunctionDetail.conditions = overRiddenDirtyCondition;
            // }

            if(isMinorVersionAvailable){
                let state = store.getState();
                let {activeModel, computeModels} = state.modelsPage;
                let currentModel = computeModels.byId[activeModel.id]

                if(currentModel){
                    let currentFunction = currentModel.json.assetData.filter((fn) => fn.nodeId === latestFunctionDetail.nodeId)[0]
                    let currentConditions : any = currentFunction && currentFunction instanceof FunctionTypeDetail ? currentFunction.conditions : null;

                    if(currentConditions){
                        Object.keys(currentConditions).forEach((conditionKey) => {
                            let subConditionData = currentConditions[conditionKey]['subConditions'];
                            subConditionData && Object.keys(subConditionData).forEach((subConditionKey) => {
                                
                                    Object.keys(subConditionData[subConditionKey]).forEach((item) => {
                                        if(
                                            latestFunctionDetail.conditions 
                                            && latestFunctionDetail.conditions[conditionKey] 
                                            && latestFunctionDetail.conditions[conditionKey]['subConditions'] 
                                            && latestFunctionDetail.conditions[conditionKey]['subConditions'][subConditionKey]
                                        ){
                                             if(currentConditions[conditionKey]['subConditions'][subConditionKey][item]['isDirty'] !== false) {
                                                latestFunctionDetail.conditions[conditionKey]['subConditions'][subConditionKey][item]['isDirty'] = true
                                             } else {
                                                latestFunctionDetail.conditions[conditionKey]['subConditions'][subConditionKey][item]['isDirty'] = false
                                             }
                                            if(latestFunctionDetail.conditions[conditionKey]['subConditions'][subConditionKey][item]['value'] === undefined){
                                                latestFunctionDetail.conditions[conditionKey]['subConditions'][subConditionKey][item]['value'] = '';
                                            }
                                        }
                                    })
                            })
                        })
                    }
                }
            }
        }
    }

    /** handle alarms pins isPublished. */
    if (
        currentFunctionDetail.alarmMapping &&
        latestFunctionDetail.alarmMapping &&
        currentFunctionDetail.alarmPins.isPublished
    ) {
        latestFunctionDetail.alarmPins = { ...currentFunctionDetail.alarmPins };
    }

    /** handle time trigger */
    if (currentFunctionDetail.timeTrigger) {
        latestFunctionDetail.timeTrigger = currentFunctionDetail.timeTrigger;
    }

    return latestFunctionDetail;
}
/** update json with latest function Details
 * @param json of type LocalJson.
 * @param latestFunctions contains latest available function data.
 * @param nodeId (optional) - need to passed when dealing with single function block.
 */
export const updateJsonWithLatestFunctionDetails = (options: {
    json: LocalJson;
    latestFunctions: LatestFunctionDataPayload;
    nodeId?: string;
}) => {
    const { json, latestFunctions, nodeId } = options;
    let updatedJson: LocalJson = { assetData: [], connectionData: [] };
    const latestFunctionTypeDetailsObject: {
        [key: string]: FunctionTypeDetail;
    } = {};
    json.assetData.forEach((item) => {
        if (item instanceof FunctionTypeDetail && item.isUpdateAvailable) {
            if ((nodeId && item.nodeId === nodeId) || !nodeId) {
                const { assetRef, nodeId: currNodeId, position, scale } = item;
                const currenAssetRef = assetRef.split('@')[0];
                const latestFunctionData = latestFunctions[currenAssetRef];
                const latestFunctionTypeDetail = CreateFunctionTypeDetail({
                    ...(latestFunctionData as any),
                    nodeId: currNodeId,
                    position,
                });
                if (latestFunctionTypeDetail) {
                    const updatedFunctionData = updateFunctionDataWithConnectionPreserved({
                        currentFunctionDetail: item,
                        latestFunctionDetail: latestFunctionTypeDetail,
                    });
                    updatedJson.assetData.push(updatedFunctionData);
                    latestFunctionTypeDetailsObject[item.nodeId] = updatedFunctionData;
                } else {
                    updatedJson.assetData.push(item);
                }
            } else {
                updatedJson.assetData.push(item);
            }
        } else {
            updatedJson.assetData.push(item);
        }
    });

    // filter overAllSeverityFunction if functions having conditions not present.

    const isFunctionHavingConditionPresent = updatedJson.assetData.some((asset) => {
        if (asset instanceof FunctionTypeDetail && asset.conditions) {
            return true;
        } else return false;
    });

    if (!isFunctionHavingConditionPresent) {
        const assetWithoutOverallSeverityFunction = updatedJson.assetData.filter(
            (asset) =>
                !(
                    asset instanceof FunctionTypeDetail &&
                    asset.nodeId === OVERALL_SEVERITY_FUNCTION.ID
                )
        );
        updatedJson.assetData = [...assetWithoutOverallSeverityFunction];
    }

    json.connectionData.forEach((connection) => {
        const inputConnection = connection.input;
        const outputConection = connection.output;
        let isConnectionValid = true;
        const inputAsset = inputConnection.asset;
        const outputAsset = outputConection.asset;
        if (
            inputAsset &&
            inputAsset instanceof FunctionTypeDetail &&
            inputAsset.isUpdateAvailable
        ) {
            const nodeId = inputAsset.nodeId;
            if (latestFunctionTypeDetailsObject[nodeId]) {
                const circleData = inputConnection.circleData;
                const latestFunctionDetail = latestFunctionTypeDetailsObject[nodeId];
                const inputPin = [
                    ...latestFunctionDetail.inputs,
                    ...latestFunctionDetail.inhibits,
                    ...latestFunctionDetail.alarmInputs,
                    ...latestFunctionDetail.eventInputs
                ].find((input) => input.id === circleData.id);
                if (inputPin) {
                    if(inputPin.dataType.includes('array')) {
                    if(connection.input.circleData.defaultValue && inputPin.defaultValue !== connection.input.circleData.defaultValue) {
                        let currentDefaultValue = connection.input.circleData.defaultValue.split(',');
                        let latestDefaultValue = inputPin.defaultValue ? inputPin.defaultValue.split(',') :[];
                        
                        if(currentDefaultValue.length > latestDefaultValue.length) {
                            currentDefaultValue.forEach((value,index) => {
                                if(index >= latestDefaultValue.length) {
                                    latestDefaultValue.push(value);
                                }
                            } )
                            connection.input.circleData.defaultValue = latestDefaultValue.join(',');
                            inputPin.defaultValue = latestDefaultValue.join(',');
                        }
                    }
                    }
                    if (inputPin.dataType !== circleData.dataType) {
                        isConnectionValid = checkValidConnection(
                            inputPin,
                            outputConection,
                            PINS_TYPE.INPUT_INHIBIT
                        );
                        if(isConnectionValid) {
                            if(inputPin.dataType.includes('array') && inputPin.dataType.split('_')[1] === outputConection.circleData.dataType) {
                                connection.input.circleData.dataType = inputPin.dataType;
                            
                            }
                        }
                    }
                } else {
                    isConnectionValid = false;
                }
            }
        }
        if (
            outputAsset &&
            outputAsset instanceof FunctionTypeDetail &&
            outputAsset.isUpdateAvailable &&
            isConnectionValid
        ) {
            const nodeId = outputAsset.nodeId;
            if (latestFunctionTypeDetailsObject[nodeId]) {
                const circleData = outputConection.circleData;
                const latestFunctionDetail = latestFunctionTypeDetailsObject[nodeId];
                const outputPin = latestFunctionDetail.outputs.find(
                    (output) => output.id === circleData.id
                );
                if (outputPin) {
                    if (outputPin.dataType !== circleData.dataType) {
                        isConnectionValid = checkValidConnection(
                            outputPin,
                            inputConnection,
                            PINS_TYPE.OUTPUT
                        );
                    }
                } else {
                    isConnectionValid = false;
                }
            }
        }
        if (isConnectionValid) {
            updatedJson.connectionData.push(connection);
        }
    });

    updatedJson.connectionData = updatedJson.connectionData.filter((connection: any, index: number) => {
        if (connection.input.asset.inputs.length > 0 && (connection.output.asset instanceof FunctionTypeDetail || connection.output.asset instanceof ObjectTypeDetail)) {
            let inputData: any;
            connection.input.asset.inputs.find((assetInput: any) => {
                if(assetInput.id === connection.input.circleData.id){
                    inputData = assetInput;
                }
            });
            // if (inputData && inputData.defaultValue !== undefined) {
            //     return false;
            // }
            return true;
        }
        return true;
    });

    return updatedJson;
};

const checkValidConnection = (
    currentPin: FunctionTypeDetail['outputs'][0] | FunctionTypeDetail['inputs'][0],
    connectedPin:
        | LocalJson['connectionData'][0]['input']
        | LocalJson['connectionData'][0]['output'],
    pinType: PinsType
) => {
    let isConnectionValid = true;
    if (currentPin.dataType.includes('array')) {
        if (connectedPin.circleData.dataType.includes('array')) {
            if (currentPin.dataType !== connectedPin.circleData.dataType) {
                isConnectionValid = false;
            }
        } else {
            if (
                currentPin.dataType.split('_')[1] !== connectedPin.circleData.dataType ||
                pinType == PINS_TYPE.OUTPUT
            ) {
                isConnectionValid = false;
            }
        }
    } else {
        if (connectedPin.circleData.dataType.includes('array')) {
            if (connectedPin.circleData.dataType.split('_')[1] !== currentPin.dataType) {
                isConnectionValid = false;
            }
        } else if (connectedPin.circleData.dataType !== currentPin.dataType) {
            isConnectionValid = false;
        }
    }
    return isConnectionValid;
};
