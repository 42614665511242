import React, { useState, useCallback } from 'react';

import _ from 'lodash';

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import TriggerTables from './TriggerTables';
import MappingVariables from '../MappingVariables';
import {
    tableInputTriggerHeader,
    tableTimeTriggerHeader,
    tableAlarmTriggerHeader,
    tableEventTriggerHeader,
    ACTIVE_INPUT_TRIGGER_TAB,
    ACTIVE_EVENT_TRIGGER_TAB,
    ACTIVE_TIME_TRIGGER_TAB,
    ACTIVE_ALARM_TRIGGER_TAB,
} from '../constant';

import { StoreState } from '../../../store/index';
import './style.scss';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    removeConnectionConfigurationTool,
    addConnectionConfigurationTool,
    triggerConnectionConfigurationTool,
    addDefaultValueConfigurationTool,
    refreshDefaultValueConfigurationTool,
    publishOutputConfigurationTool,
    publishAlarmConfigurationTool,
    markComputeModelDirty,
    editNodeIdOfFunction,
    editAliasNameInModels,
} from '../../../store/configurationTool/actions';
import {
    RemoveConnectionOptions,
    AddConnectionOptions,
} from '../../../store/configurationTool/types';

import {
    VIEW_MODE,
    CONFIGURATION_TOOL_MODE,
    MODAL_TITLE,
    ROUTE_PATHNAME,
} from '../../../utils/constants/appConstants';
import { showModal } from '../../../store/modal/action';
import ConditionConfiguration from '../../Fabric/UIComponents/ConditionConfiguration';
import FunctionTypeDetail from '../../../transformers/AssetType/FunctionType';

export type TriggerTabTableViewProps = {} & ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export function TriggerTabTableView(props: TriggerTabTableViewProps) {
    // console.log('tableData :', props.tableData);
    const [activeTab, setActiveTab] = useState(ACTIVE_INPUT_TRIGGER_TAB);

    const toggleTab = useCallback(
        (event: React.SyntheticEvent) => {
            const target = event.target as HTMLAnchorElement;
            const name = target.name;
            console.log(event.target);
            setActiveTab((activeTab) => {
                activeTab = name;
                return activeTab;
            });
        },
        // },

        []
    );

    return (
        <TabPane className="wrapper-io-trigger-tabs">
            {/*  Input Output buttons */}
            <Nav tabs>
                {[
                    ACTIVE_INPUT_TRIGGER_TAB,
                    ACTIVE_TIME_TRIGGER_TAB,
                    ACTIVE_ALARM_TRIGGER_TAB,
                    // ACTIVE_EVENT_TRIGGER_TAB,
                ].map((tab) => (
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === tab,
                            })}
                            onClick={toggleTab}
                            name={tab}
                            id={`m${tab}IOTabs`}
                        >
                            {tab}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab}>
                {/* Inputs/outputs table */}
                {[
                    ACTIVE_INPUT_TRIGGER_TAB,
                    ACTIVE_TIME_TRIGGER_TAB,
                    ACTIVE_ALARM_TRIGGER_TAB,
                    // ACTIVE_EVENT_TRIGGER_TAB,
                ].map((tab) => {
                    return (
                        <TabPane tabId={tab}>
                            <div className="tab-content-parent">
                                <Row className="tab-content-data">
                                    <Col sm={12} className="table-parent">
                                        <TriggerTables
                                            {...props}
                                            activeTab={tab}
                                            tableHeaderData={
                                                tab === ACTIVE_INPUT_TRIGGER_TAB
                                                    ? tableInputTriggerHeader
                                                    : tab === ACTIVE_TIME_TRIGGER_TAB
                                                        ? tableTimeTriggerHeader
                                                        : tab === ACTIVE_ALARM_TRIGGER_TAB
                                                            ? tableAlarmTriggerHeader
                                                            : tableEventTriggerHeader
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                    );
                })}
            </TabContent>
        </TabPane>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        tableData: state.configurationTool.tableData,
        inViewMode: state.configurationTool.mode === CONFIGURATION_TOOL_MODE.VIEW,
        isEditMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        overallSeverityFunctionId: state.configurationTool.overallSeverityFunctionId,
        originalTypeData: state.instanceConfig.originalTypeData,
        activeModelJson:
            state.modelsPage.activeModel && state.modelsPage.activeModel.modelInstance.json,
        modelInstance: window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)
            ? state.instanceConfig.activeModel.modelInstance
            : state.modelsPage.activeModel.modelInstance,
        currentJson: state.configurationTool.json,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        removeConnectionConfigurationTool: (options: RemoveConnectionOptions) => {
            dispatch(removeConnectionConfigurationTool(options));
        },
        addConnectionConfigurationTool: (options: AddConnectionOptions) => {
            dispatch(addConnectionConfigurationTool(options));
        },
        triggerConnectionConfigurationTool: (
            options: ReturnType<typeof triggerConnectionConfigurationTool>['payload']
        ) => {
            dispatch(triggerConnectionConfigurationTool(options));
        },
        addDefaultValueConfigurationTool: (
            options: ReturnType<typeof addDefaultValueConfigurationTool>['payload']
        ) => {
            dispatch(addDefaultValueConfigurationTool(options));
        },
        refreshDefaultValueConfigurationTool: (
            options: ReturnType<typeof refreshDefaultValueConfigurationTool>['payload']
        ) => {
            dispatch(refreshDefaultValueConfigurationTool(options));
        },
        publishOutputConfigurationTool(
            options: ReturnType<typeof publishOutputConfigurationTool>['payload']
        ) {
            dispatch(publishOutputConfigurationTool(options));
        },
        publishAlarmConfigurationTool: (
            options: ReturnType<typeof publishAlarmConfigurationTool>['payload']
        ) => {
            dispatch(publishAlarmConfigurationTool(options));
        },
        markComputeModelDirty: () => dispatch(markComputeModelDirty()),
        showConditionModal: (data: any) => {
            dispatch(
                showModal({
                    component: ConditionConfiguration,
                    modalTitle: MODAL_TITLE.PUBLISH_OUTPUT_CONFIGURATION_TITLE,
                    data: data,
                })
            );
        },
        editNodeIdOfFunction: (payload: { newName: string; functionType: FunctionTypeDetail }) => {
            dispatch(editNodeIdOfFunction(payload));
        },
        changeAliasNameForModels: (payload: {
            functionType: FunctionTypeDetail;
            ioName: string;
            aliasName: string;
        }) => {
            dispatch(editAliasNameInModels(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TriggerTabTableView);
