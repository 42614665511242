import React, { Fragment, useState, useEffect } from 'react';
import TR from '../../../../../Table/TableRow';
import { IoTableTabsProps } from '../../../..';
import {
    AlarmTypeDetailsWithMappings,
    FunctionTypeDetailsWithMappings,
} from '../../../../../../model/TableController/types';
import Td from '../../../../../Table/TableData';
import _ from 'lodash';
import {
    deleteFunctionAlarmEventPinWarningMessage,
    ROUTE_PATHNAME,
} from '../../../../../../utils/constants/appConstants';
import { Button } from '@abb/abb-common-ux-react';
import { connect } from 'react-redux';
import { showModal } from '../../../../../../store/modal/action';
import { deleteAlarmTriggerToFunction } from '../../../../../../store/configurationTool/actions';
import { Dispatch } from 'redux';
import { StoreState } from '../../../../../../store';
import AddAlarmForm from '../../../../../CreateAlarmForm';
import RemovableChip from '../../../../../RemovableChip';
import AlarmTypeDetail from '../../../../../../transformers/AssetType/AlarmType';
import FunctionTypeDetail from '../../../../../../transformers/AssetType/FunctionType';
import Condition from '../../../../../shared/Condition';
import { CONFIRMATION_BUTTON } from '../../../../../../utils/constants/uiConstants';
import { removeAlarmTriggerOptions } from '../../../../../../store/configurationTool/types';
import { hideDialog, showDialog } from '../../../../../../store/dialog/action';
import MessageModal from '../../../../../MessageModal';
import { ALARM_TYPE } from '../../../../../Fabric/types';
import CollapseTitle from '../../../../../CollapseTitle';
import { Collapse } from 'reactstrap';

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
    functionType: FunctionTypeDetailsWithMappings;
}

export const ExpandableAlarmRows = (
    props: ExpandTableRowProps &
        IoTableTabsProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const [isExpanded, updateIsExpanded] = useState(false);

    const toggleExpand = () => {
        updateIsExpanded(!isExpanded);
    };
    const rows = [...props.asset.alarmInputs];

    const rowCount = rows.length;

    return (
        <Fragment>
            {rows.length === 0 ? (
                <>
                    <CollapseTitle title={props.asset.nodeId} isCollapse={isExpanded} toggleCollapse={toggleExpand} />
                    <Collapse isOpen={isExpanded} timeout={{ enter: 0, exit: 0.3 }}>
                        <TR>
                            <Td tdBorderTop={'remove-inner-row-border-bottom'}>
                        {props.asset.nodeId}
                    </Td>
                            <Td className='w-22-column' tdBorderTop={'remove-inner-row-border-bottom'}>{props.asset.assetName}</Td>
                    <Td>
                        <div className={'add-trigger'}>
                            {props.isEditMode &&
                                !window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) && (
                                    <Button
                                        type={'primary-blue'}
                                        sizeClass={'small'}
                                        text={'Add'}
                                        onClick={() => props.showCreateAlarmModal(props.asset)}
                                    />
                                )}
                        </div>
                    </Td>
                    <Td></Td>
                </TR>
                    </Collapse>
                </>

            ) : (
                <>
                    <CollapseTitle title={props.asset.nodeId} isCollapse={isExpanded} toggleCollapse={toggleExpand} />
                    <Collapse isOpen={isExpanded} timeout={{ enter: 0, exit: 0.3 }}>
                        {rows.map((input, index) => {
                            return (
                                <AlarmTriggerRow
                                    key={input.id}
                                    {...props}
                                    asset={props.asset}
                                    alarmAsset={
                                        input.mappingDetails[0] &&
                                        (input.mappingDetails[0].output.asset as AlarmTypeDetail)
                                    }
                                    index={index}
                                    input={input}
                                    rowCount={rowCount}
                                />
                            );
                        })}
                    </Collapse>
                </>

            )}
        </Fragment>
    );
};

const AlarmTriggerRow = (
    props: {
        index: number;
        rowCount: number;
        input: FunctionTypeDetailsWithMappings['inputs'][0];
        asset: FunctionTypeDetailsWithMappings;
        alarmAsset: AlarmTypeDetail;
    } & ExpandTableRowProps &
        IoTableTabsProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const { index, rowCount, asset, alarmAsset, input } = props;
    const [alarmAssetWithMapping, setAlarmAssetWithMapping] = useState(
        {} as AlarmTypeDetailsWithMappings
    );

    useEffect(() => {
        if (alarmAsset.type === ALARM_TYPE.SPECIFIC) {
            let alarmAssetWithMappingDetails = props.tableData.alarmTypeDetails.filter(
                (alarm) => alarm.nodeId === alarmAsset.nodeId
            )[0];
            setAlarmAssetWithMapping(alarmAssetWithMappingDetails);
        }
    }, props.tableData.alarmTypeDetails);

    return (
        <>
            <TR id={`${asset.nodeId}`}>
                <Fragment>
                    <Td tdBorderTop={'remove-inner-row-border-bottom'}>{index === 0 ? asset.nodeId : ''}</Td>
                    <Td className='w-22-column' tdBorderTop={'remove-inner-row-border-bottom'}>{index === 0 ? asset.assetName : ''}</Td>
                    <Td>
                        <RemovableChip
                            text={alarmAsset ? alarmAsset.assetName : ''}
                            isDeletable={
                                props.isEditMode &&
                                !window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)
                            }
                            onDelete={() => {
                                props.deleteAlarmTriggerFromFunction({
                                    inputCircleData: input,
                                    inputAsset: asset,
                                    outputCircleData: alarmAsset.outputs.filter((output) => {
                                        output.name === 'alarmId';
                                    })[0],
                                    outputAsset: alarmAsset,
                                });
                            }}
                            onClick={() =>
                                props.showViewAlarmModal(
                                    asset,
                                    alarmAsset,
                                    alarmAsset.type === ALARM_TYPE.SPECIFIC
                                        ? alarmAssetWithMapping.inputs
                                            ? alarmAssetWithMapping.inputs &&
                                              alarmAssetWithMapping.inputs[0] &&
                                              alarmAssetWithMapping.inputs[0].mappingDetails &&
                                              alarmAssetWithMapping.inputs[0].mappingDetails[0] &&
                                              alarmAssetWithMapping.inputs[0].mappingDetails[0]
                                                  .output.circleData.name
                                            : ''
                                        : '',
                                    props.viewMode
                                )
                            }
                        />
                    </Td>
                </Fragment>
                <Td>
                    {alarmAsset && alarmAsset.type === ALARM_TYPE.SPECIFIC
                        ? alarmAssetWithMapping.inputs
                            ? alarmAssetWithMapping.inputs &&
                              alarmAssetWithMapping.inputs[0] &&
                              alarmAssetWithMapping.inputs[0].mappingDetails &&
                              alarmAssetWithMapping.inputs[0].mappingDetails[0] &&
                              alarmAssetWithMapping.inputs[0].mappingDetails[0].output.asset
                                  .assetName
                            : ''
                        : ''}
                </Td>
            </TR>
            <Condition
                when={
                    index === rowCount - 1 &&
                    props.isEditMode &&
                    !window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)
                }
            >
                <TR>
                    <Td></Td>
                    <Td className='w-22-column'></Td>
                    <Td>
                        <div className={'add-trigger'}>
                            {props.isEditMode &&
                                !window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) && (
                                    <Button
                                        type={'primary-blue'}
                                        sizeClass={'small'}
                                        text={'Add'}
                                        onClick={() => props.showCreateAlarmModal(asset)}
                                    />
                                )}
                        </div>
                    </Td>
                    <Td></Td>
                </TR>
            </Condition>
        </>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        viewMode: state.configurationTool.mode,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showCreateAlarmModal: (functionType: FunctionTypeDetail) => {
            dispatch(
                showModal({
                    component: AddAlarmForm,
                    modalTitle: 'ADD ALARM TRIGGER',
                    data: {
                        mode: 'CREATE',
                        functionType,
                    },
                })
            );
        },
        showViewAlarmModal: (
            functionType: FunctionTypeDetail,
            alarmType: AlarmTypeDetail,
            objectPinName: string,
            viewMode: string
        ) => {
            dispatch(
                showModal({
                    component: AddAlarmForm,
                    modalTitle: `${
                        window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) ||
                        viewMode === 'VIEW'
                            ? 'VIEW'
                            : 'EDIT'
                    } ALARM TRIGGER DETAILS`,
                    data: {
                        mode:
                            window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) ||
                            viewMode === 'VIEW'
                                ? 'VIEW'
                                : 'EDIT',
                        functionType,
                        alarmType,
                        objectPinName,
                    },
                })
            );
        },
        deleteAlarmTriggerFromFunction: (payload: removeAlarmTriggerOptions) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: 'Delete',
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: deleteFunctionAlarmEventPinWarningMessage({
                            pinName: payload.outputAsset.assetName,
                            type: 'Alarm',
                        }),
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: () => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.CONFIRM,
                                type: 'primary-blue',
                                handler: () => {
                                    dispatch(deleteAlarmTriggerToFunction(payload));
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableAlarmRows);
