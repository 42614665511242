import React, { useState, useCallback } from "react";
import {
    Dropdown,
    DropdownGroup,
    DropdownOption,
    Button,
} from "@abb/abb-common-ux-react";
import "./style.scss";
import Flex from "../../../components/shared/Flex";
import { StoreState } from "../../../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    saveComputeModelInstanceConfig,
    getLibraryReferencesForFunctionTypesRequest,
    updateComputeModelInstanceConfig,
} from "../../../store/instanceConfig/actions";
import { hideModal } from "../../../store/modal/action";
import {
    InstanceSaveType,
    FunctionTypeBasics,
} from "../../../store/instanceConfig/types";
import {
    INSTANCE_SAVE_MODE,
    TYPE_VALIDATION_ERROR_MSG,
    NOTIFICATION_MODAL_STATUS,
} from "../../../utils/constants/appConstants";
import { Asset } from "../../../components/Fabric/types";
import FunctionTypeDetail from "../../../transformers/AssetType/FunctionType";
import {
    getFunctionsList,
    getTypeBasicsForFunctionTypeDetail,
} from "../../../utils/helpers";
import { showNotificationModal } from "../../../store/notificationModal/action";
import { store } from "../../../containers/AppBoot/index";
import MessageModal from "../../../components/MessageModal";
import { hideDialog, showDialog } from "../../../store/dialog/action";
import {
    CONFIRMATION_BUTTON,
    CONFIRMATION_INITIAL_CONNECTION_MAPPING,
} from "../../../utils/constants/uiConstants";
import { updateConfigurationToolJson } from "../../../store/configurationTool/actions";

export function SaveInstanceConfigModal(
    props: ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const [selectedAssets, updateSelectedAssets] = useState<
        { value: string; label: string; isNew: boolean }[]
    >(
        props.selectedInstances.map((selectedInstance) => ({
            value: selectedInstance.objectId,
            label: selectedInstance.name,
            isNew: false,
        }))
    );
    const handleChange = useCallback(
        (options: { value: string; label: string; isNew: boolean }[]) => {
            console.log(options);
            updateSelectedAssets(options);
            // updateSelectedAsset(objectId);
        },
        []
    );

    const handleSave = useCallback(() => {
        const { configurationTool } = store.getState();
        const { tableData } = configurationTool;
        let invalidinitialValues: boolean = false;
        let invalidDefaultEmptyValue: boolean = false;

        if (
            tableData &&
            tableData.functionTypeDetails &&
            tableData.functionTypeDetails.length > 0
        ) {
            tableData.functionTypeDetails.forEach((typeDetail: any) => {
                if (typeDetail.inputs && typeDetail.inputs.length > 0) {
                    const origVersionId = typeDetail.assetRef.split("@");
                    const origId =
                        origVersionId[0] + "#" + typeDetail.assetVersion;
                    const origFuncData =
                        configurationTool.originalFunctionTypesUsed
                            .byIdWithVersion[origId] ||
                        configurationTool.latestOriginalFunctionsUsed
                            .byIdWithVersion[origId];
                    typeDetail.inputs.forEach((inputDetail: any) => {
                        const functionInputs =
                            origFuncData &&
                            origFuncData.inputs.find(
                                (origInputData: any) =>
                                    origInputData.name === inputDetail.name
                            );
                        if (functionInputs && functionInputs.dataType.includes('array')) {
                            let defaultValue = inputDetail.defaultValue
                                ? inputDetail.defaultValue.split(",")
                                : "";
                            if (
                                defaultValue.includes("") &&
                                functionInputs.defaultValue
                            ) {
                                invalidDefaultEmptyValue = true;
                            }
                            if (defaultValue && defaultValue.length > 0) {
                                if (
                                    inputDetail.mappingDetails.length > 0 && 
                                    inputDetail.mappingDetails.length !==
                                        defaultValue.length
                                ) {
                                    invalidinitialValues = true;
                                }
                            }
                        }
                    });
                }
            });
        }
        if (invalidDefaultEmptyValue) {
            store.dispatch(
                showNotificationModal({
                    title: TYPE_VALIDATION_ERROR_MSG,
                    resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                    details: ["Please enter all initial values"],
                    type: "confirmation",
                })
            );
            return;
        }
        // if (invalidinitialValues) {
        //     store.dispatch(showNotificationModal({
        //         title: TYPE_VALIDATION_ERROR_MSG,
        //         resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
        //         details: ["Connections not mapped with initial values"],
        //         type: 'confirmation',
        //     }));
        //     return;
        // }
        if (invalidinitialValues) {
            const objectValues = {
                objectIds: selectedAssets.map(
                    (selectedAsset) => selectedAsset.value
                ),
                saveMode: INSTANCE_SAVE_MODE.VIEW_AND_DEPLOY,
            };
            confirmMappingConnection(objectValues, props);
        } else {
            props.saveComputeModelInstance({
                objectIds: selectedAssets.map(
                    (selectedAsset) => selectedAsset.value
                ),
                saveMode: INSTANCE_SAVE_MODE.VIEW_AND_DEPLOY,
            });
        }
    }, [selectedAssets]);

    const confirmMappingConnection = (objectValues: any, props: any) => {
        props.showConfirmPopup(objectValues, props);
    };

    return (
        <Flex column className="wrapper-save-instance-modal">
            <div className="title">
                Select the asset to which instance has to be created
            </div>
            <div className="wrapper-custom-select">
                <Dropdown
                    multiselect={true}
                    value={selectedAssets}
                    onChange={handleChange}
                    placeholder="Select an asset"
                >
                    {props.selectedInstances.map((selectedInstance) => {
                        return (
                            <DropdownOption
                                label={selectedInstance.name}
                                value={selectedInstance.objectId}
                                key={selectedInstance.objectId}
                            ></DropdownOption>
                        );
                    })}
                </Dropdown>

                {/* <CustomSelect
                    options={props.selectedInstances.map(selectedInstance => ({
                        label: selectedInstance.name,
                        value: selectedInstance.objectId
                    }))}
                    placeholder="Select an asset"
                    onChange={handleChange}
                    isOpen={false}
                /> */}
            </div>
            <div className="save-button">
                <Button
                    type="primary-blue"
                    text="Save"
                    sizeClass="small"
                    onClick={handleSave}
                />
            </div>
        </Flex>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        selectedInstances:
            state.instanceConfig.instancesTable.tableData.selectedInstances.map(
                (instanceId) => {
                    return state.instanceConfig.instancesTable.tableData.byId[
                        instanceId
                    ];
                }
            ),
        configurationToolJson: state.configurationTool.json,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveComputeModelInstance: (payload: {
            objectIds: string[];
            saveMode: InstanceSaveType;
        }) => {
            dispatch(saveComputeModelInstanceConfig(payload));
            dispatch(hideModal());
        },
        updateComputeModelInstance: (payload: {
            objectIds: string[];
            saveMode: InstanceSaveType;
        }) => {
            dispatch(updateComputeModelInstanceConfig(payload));
            dispatch(hideModal());
        },
        getLibraryReferencesForFunctionTypesRequest: (
            functionTypeBasicsList: FunctionTypeBasics[]
        ) =>
            dispatch(
                getLibraryReferencesForFunctionTypesRequest(
                    functionTypeBasicsList
                )
            ),
        showConfirmPopup: (objectValue: any, props: any) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_BUTTON.CONFIRM,
                    customClassName: "wrapper-message-modal",
                    data: {
                        warningText: CONFIRMATION_INITIAL_CONNECTION_MAPPING,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: "discreet-black",
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.OK,
                                type: "primary-blue",
                                handler: (dlg: any) => {
                                    const { configurationTool } =
                                        store.getState();
                                    const { tableData } = configurationTool;
                                    let isValidInitialValues: boolean = false;
                                    if (
                                        tableData &&
                                        tableData.functionTypeDetails &&
                                        tableData.functionTypeDetails.length > 0
                                    ) {
                                        tableData.functionTypeDetails.forEach(
                                            (typeDetail: any) => {
                                                if (
                                                    typeDetail.inputs &&
                                                    typeDetail.inputs.length > 0
                                                ) {
                                                    typeDetail.inputs.forEach(
                                                        (inputDetail: any) => {
                                                            let defaultValue =
                                                                inputDetail.defaultValue
                                                                    ? inputDetail.defaultValue.split(
                                                                          ","
                                                                      )
                                                                    : "";
                                                            if (
                                                                defaultValue &&
                                                                defaultValue.length >
                                                                    0
                                                            ) {
                                                                if (
                                                                    inputDetail
                                                                        .mappingDetails
                                                                        .length >
                                                                    0
                                                                ) {
                                                                    if (
                                                                        inputDetail
                                                                            .mappingDetails
                                                                            .length >
                                                                            defaultValue.length ||
                                                                        inputDetail
                                                                            .mappingDetails
                                                                            .length <
                                                                            defaultValue.length
                                                                    ) {
                                                                        // isValidInitialValues = true;
                                                                        defaultValue.splice(
                                                                            inputDetail
                                                                                .mappingDetails
                                                                                .length
                                                                        );
                                                                        inputDetail.defaultValue =
                                                                            defaultValue.join(
                                                                                ","
                                                                            );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        );
                                        dispatch(
                                            updateConfigurationToolJson({
                                                tableData,
                                            })
                                        );
                                        dispatch(hideDialog());
                                        props.saveComputeModelInstance(
                                            objectValue
                                        );
                                    }
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveInstanceConfigModal);
