export const fontSize = 14;
export const charWidth = Math.ceil((fontSize * 58.33) / 100);
export const canvasPrimaryBorderColor = '#707070';
export const canvasSecondaryBorderColor = '#ebebeb';
export const canvasTextColor = '#686868';
export const canvasPrimaryTextColor = '#1f1f1f';
export const canvasIOCircleColor = '#525252';
export const canvasIOTriggerColor = '#707070'; //'#bababa';
export const canvasLineColor = '#707070';
export const canvasIOInhibitColor = '#ff7300';
export const canvasAliasColor = '#3366FF';
export const crossIconRadius = 15;
export const canvasBorderPadding = 10;
export const MAX_CANVAS_WIDTH = 8192;
export const MAX_CANVAS_HEIGHT = 8192;

// grid
export const GRID_COLOR = '#d4d4d4';
export const GRID_PIXEL = 15;
export const SNAP_FACTOR = 1; // HIGHER FACTOR IS INVERSELY PROPORTIONAL TO THE SNAPPING EFFECT - cannot be decimal or zero
export const FUNC_BLOCK_TITLE_HEIGHT = GRID_PIXEL * 2;
export const OBJECT_BLOCK_TITLE_HEIGHT = GRID_PIXEL * 2;
export const PINS_DISTANCE = 2 * GRID_PIXEL;
export const BLOCK_BOTTOM_PADDING = 2 * GRID_PIXEL;
export const GRID_DOT_WIDTH = 3;

// object rectangle
export const objectNameMargin = 50;
export const textRightMargin = 20;
export const charHeight = Math.round((fontSize * 208.33) / 100);
export const textTopMargin = 15;
export const outputLineLength = 50;
export const outputCircleRadius = 4;
export const outputPinPadding = 4;
export const objectOutputPinLength = 56;
export const rectOffset = 7;
// trigger
export const triggerCircleRadius = 6;
export const triggerStrokeWidth = 5.5;
export const noTriggerStrokeWidth = 1;
export const triggerLineStrokeWidth = 3;
export const noTriggerLineStrokeWidth = 1;
export const triggerColor = '#3366ff';
export const menuColor = '#1f1f1f';

// function rectangle
export const settingsIconRightMargin = 10;
export const settingsIconSize = 14;
export const publishIconSize = 12;
export const triggerIconSize = 12;
export const informationIconSize = 14;
export const infromationIconColor = '#464646';
export const functionRectangaleTopBorderOffSet = 25;
export const alramIconSize = 14;
export const alarmIconColor = '#707070';
export const alarmThemeColor = '#F3933B';
export const eventThemeColor = '#3366FF';
export const functionTitleTopPadding = 7;
export const conditionTitlePaddingRight = 14;
export const conditionPinsTopPadding = 10;
export const fullFunctionPinLength =
    outputLineLength + outputCircleRadius + triggerCircleRadius + noTriggerStrokeWidth;

// function alarm pin trigger
export const noTriggerAlarmStrokeWidth = 1;
export const triggerAlarmStrokeWidth = 4;
export const alarmLineLength = 57 + GRID_PIXEL + outputCircleRadius - alramIconSize;

// refreshIconColor
export const activeRefreshIconColor = '#3366ff';
export const disabledRefreshIconColor = '#707070';

// aligningLine
export const ALIGNING_LINE_COLOR = 'rgb(0,255,0)';
export const ALIGNING_LINE_OFFSET = 5; //5;
export const ALIGNING_LINE_MARGIN = 4; //4;
export const ALIGNING_LINE_WIDTH = 1; //1

export const OBJECT_BLOCK_WIDTH = GRID_PIXEL * 15 + objectOutputPinLength;
export const FUNCTION_BLOCK_WIDTH = GRID_PIXEL * 16 + GRID_PIXEL * 4 + fullFunctionPinLength;
export const BOUNDARY_PADDING = 0;
export const MIN_TOP_PADDING = GRID_PIXEL * 2;
export const MIN_RIGHT_PADDING = GRID_PIXEL * 2;
export const MIN_Left_PADDING = GRID_PIXEL * 2;
export const MIN_BOTTOM_PADDING = GRID_PIXEL * 2;

// node theme
export const OBJECT_NODE_COLOR = '#FAF5B6';
export const ALARM_NODE_COLOR = '#F3933B';
export const FUNCTION_NODE_COLOR = '#D7F7F3';
export const FUNCTION_BORDER_COLOR = '#3DA190';
export const EVENT_NODE_COLOR = '#3366FF';
