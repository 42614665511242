export const configurationToolActionTypes = <const>{
    UPDATE_CONFIGURATION_TOOL_JSON: 'UPDATE_CONFIGURATION_TOOL_JSON',
    UPDATE_CONFIGURATION_TOOL_PRIVILEGES: 'UPDATE_CONFIGURATION_TOOL_PRIVILEGES',
    UPDATE_CONFIGURATION_TOOL_MODE: 'UPDATE_CONFIGURATION_TOOL_MODE',
    UPDATE_CONFIGURATION_TOOL_ACTIVE_VIEW: 'UPDATE_CONFIGURATION_TOOL_ACTIVE_VIEW',
    INITIALIZE_CONFIGURATION_TOOL: 'INITIALIZE_CONFIGURATION_TOOL',
    REMOVE_CONNECTION_CONFIGURATION_TOOL: 'REMOVE_CONNECTION_CONFIGURATION_TOOL',
    ADD_CONNECTION_CONFIGURATION_TOOL: 'ADD_CONNECTION_CONFIGURATION_TOOL',
    ADD_DEFAULT_VALUE_CONFIGURATION_TOOL: 'ADD_DEFAULT_VALUE_CONFIGURATION_TOOL',
    REFRESH_DEFAULT_VALUE_CONFIGURATION_TOOL: 'REFRESH_DEFAULT_VALUE_CONFIGURATION_TOOL',
    TRIGGER_CONNECTION_CONFIGURATION_TOOL: 'TRIGGER_CONNECTION_CONFIGURATION_TOOL',
    PUBLISH_OUTPUT_CONFIGURATION_TOOL: ' PUBLISH_OUTPUT_CONFIGURATION_TOOL',
    PUBLISH_ALARM_CONFIGURATION_TOOL: 'PUBLISH_ALARM_CONFIGURATION_TOOL',
    CREATE_CONFIGURATION_TOOL_JSON: 'CREATE_CONFIGURATION_TOOL_JSON',
    REMOVE_OVERALL_SEVERITY_FUNCTION: 'REMOVE_OVERALL_SEVERITY_FUNCTION',
    UPDATE_OVERALL_SEVERITY_FUNC_DATA: 'UPDATE_OVERALL_SEVERITY_FUNC_DATA',

    GET_SELECTED_FUNCTION_DETAIL: 'GET_SELECTED_FUNCTION_DETAIL',
    SET_SELECTED_FUNCTION_DETAILS: 'SET_SELECTED_FUNCTION_DETAILS',
    HANDLE_FUNCTION_DETAILS_LOADING: 'HANDLE_FUNCTION_DETAILS_LOADING',
    SET_VERSION_UPDATE_TYPE: 'SET_VERSION_UPDATE_TYPE',
    GET_ORIGINAL_FUNCTION_TYPES: 'GET_ORIGINAL_FUNCTION_TYPES',
    SET_ORIGINAL_FUNCTION_TYPES: 'SET_ORIGINAL_FUNCTION_TYPES',
    SET_LATEST_ORIGINAL_FUNCTIONS: 'SET_LATEST_ORIGINAL_FUNCTIONS',
    ADD_TO_ORIGINAL_SELECTED_FUNCTION: 'ADD_TO_ORIGINAL_SELECTED_FUNCTION',
    GET_ORIGINAL_FUNCTION_DATA_FROM_JSON: 'GET_ORIGINAL_FUNCTION_DATA_FROM_JSON',
    ADD_IS_DIRTY_TO_CONDITION: 'ADD_IS_DIRTY_TO_CONDITION',
    HANDLE_ALLOW_OVERLAP_ON_CANVAS: 'HANDLE_ALLOW_OVERLAP_ON_CANVAS',
    GET_LATEST_FUNCTIONS_FOR_TYPE: 'GET_LATEST_FUNCTIONS_FOR_TYPE',
    UPDATE_TYPE_WITH_LATEST_FUNCTIONS: 'UPDATE_TYPE_WITH_LATEST_FUNCTIONS',
    UPDATE_CONFIGURATION_TOOL_JSON_WITH_LATEST_FUNCTIONS:
        'UPDATE_CONFIGURATION_TOOL_JSON_WITH_LATEST_FUNCTIONS',
    HANDLE_LATEST_FUNCTION_DATA_LOADING: 'HANDLE_LATEST_FUNCTION_DATA_LOADING',
    UPDATE_ORIGINAL_FUNCTION_TYPES: 'UPDATE_ORIGINAL_FUNCTION_TYPES',
    HANDLE_CURRENT_FUNCTION_UPDATE_STATUS: 'HANDLE_CURRENT_FUNCTION_UPDATE_STATUS',
    UNDO: 'UNDO',
    REDO: 'REDO',
    CLEAR_UNDO_REDO_STACK: 'CLEAR_UNDO_REDO_STACK',
    UPDATE_IS_SEVERITY_CHANGE: 'UPDATE_IS_SEVERITY_CHANGE',

    SHOW_CONFIG_POPUP: 'SHOW_CONFIG_POPUP',
    HIDE_CONFIG_POPUP: 'HIDE_CONFIG_POPUP',
    STOP_BACKGROUND_SYNC_FOR_GET_ORIGINAL_FUNC_TYPES:
        'STOP_BACKGROUND_SYNC_FOR_GET_ORIGINAL_FUNC_TYPES',
    STOP_BACKGROUND_SYNC_FOR_GET_LATEST_FUNCTIONS: 'STOP_BACKGROUND_SYNC_FOR_GET_LATEST_FUNCTIONS',
    FETCH_MODELS_FOR_TRIGGERS: 'FETCH_MODELS_FOR_TRIGGERS',
    UPDATE_MODELS_FOR_TRIGGERS: 'UPDATE_MODELS_FOR_TRIGGERS',
    FETCH_ALARMS_FOR_MODELS: 'FETCH_ALARMS_FOR_MODELS',
    UPDATE_ALARMS_FOR_MODELS: 'UPDATE_ALARMS_FOR_MODELS',
    CLEAR_ALARMS_FOR_MODELS: 'CLEAR_ALARMS_FOR_MODELS',
    FETCH_EVENTS_FOR_MODELS: 'FETCH_EVENTS_FOR_MODELS',
    UPDATE_EVENTS_FOR_MODELS: 'UPDATE_EVENTS_FOR_MODELS',
    CLEAR_EVENTS_FOR_MODELS: 'CLEAR_EVENTS_FOR_MODELS',
    UPDATE_TIME_TRIGGER_FOR_FUNCTION: 'UPDATE_TIME_TRIGGER_FOR_FUNCTION'
};
