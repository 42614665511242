export const VALIDATION_MESSAGE = {
    EMPTY_FIELD_MESSAGE: 'Field cannot be left empty',
    FUNCTION_NAME_ERROR: 'Function name is already taken',
    SPECIAL_CHARACTERS: 'Special Characters are not allowed',
    SPACE: 'Spaces are not allowed',
    EMPTY_NAME: "Name can't be empty",
    ORIGINAL_FUNCTION_NAME: "Function name cannot be original function name"
};

export const CONFIRMATION_BUTTON = <const>{
    ACCEPT: 'ACCEPT',
    REJECT: 'REJECT',
    DISCARD_CHANGES: 'Discard Changes',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    OK: 'OK',
    IMPORTWARNING: 'Import Warning',
};
export const CONFIRMATION_TITLE = <const>{
    DELETE_MODEL: 'Delete',
    DISCARD_CHANGES: 'Discard Changes',
};

export const CREATE_NEW_MODEL = {
    MODEL_DETAILS: 'MODEL DETAILS',
    UPDATE_MODEL: 'UPDATE MODEL',
    UPDATE_BTN_TEXT: 'Set',
    CREATE_MODEL: 'CREATE MODEL',
    CREATE_BTN_TEXT: 'Set',
    ASSET_CONFIG_MODEL: 'CHANGE INSTANCE CONFIGURATION',
    ASSET_CREATE_TEXT: 'Set',
    ASSET_DEPLOY_TEXT: 'Deploy',
    ASSET_CONFIG_MODEL_UPDATE: 'UPDATE INSTANCE CONFIGURATION',
    ASSET_CONFIG_MODEL_MODIFY: 'CHANGE INSTANCE CONFIGURATION',
    ASSET_UPDATE_TEXT: 'Update',
    ASSET_MODIFY_TEXT: 'Modify',
    ASSET_STOP_MONITORING: 'Stop Monitoring',
    ASSET_CRITICALITY_ENABLE: 'ENABLE',
    ASSET_CRITICALITY_DISABLE: 'DISABLE',
    ASSET_CRITICALITY_DELETE: 'DELETE',
    SAVE_AS_TITLE: 'SAVE AS MODEL',
    PRE_IMPORT_VALIDATION: 'Pre Import Validation',
};

export const ASSET_MODAL_BUTTON = <const>{
    SET: 'Set',
    CONFIGURE: 'Configure',
    UPDATE: 'Update',
    STOP_MONITORING: 'Remove',
    START_MONITORING: 'Deploy',
    ASSET_CONFIG_MODEL_UPDATE: 'UPDATE INSTANCE CONFIGURATION',
    ASSET_CONFIG_MODEL_MODIFY: 'CHANGE INSTANCE CONFIGURATION',
    MODIFY: 'Modify',
};

export const CREATE_ALARM = {
    CREATE: 'CREATE',
    NEW_ALARM: 'NEW ALARM',
};

export const DELETE_MODEL_MSG = {
    DELETE_MODEL_MSG: 'Model used in Instances can not be deleted. Please check before deleting. Are you sure, you want to delete it?',
    DELETE_ASSET_MSG: 'Do you want to delete the current selected Asset?',
    DELETE_LINE_MSG: 'Do you want to remove the Mapping Details?',
    DELETE_FUNCTION_MSG: 'Do you want to remove Function from Model?',
    DELETE_OBJECT_MSG: 'Do you want to remove the Object from the Model?',
};

export const INVALID_COMPUTE_MODEL_MESSAGES = {
    CONNECT_ALL_INHIBIT_PINS: 'Please connect all inhibit pins',
    CONNECT_BOTH_OR_NONE_INHIBIT_PINS: 'Please connect both or none inhibit pins',
};

export const UNSAVED_CHANGES_CONFIRMATION_MESSAGE =
    'Unsaved changes will be lost, do you want to continue?';

export const HIDDEN_LOGIC_TEXT = '******';

export const TOOLTIP_MESSAGES = {
    UNDO: 'Undo',
    REDO: 'Redo',
    GRID: 'Toggle Grid',
    USER_MANUAL: 'Help',
    PROFILE: 'Profile',
    ZOOM_OUT: 'Zoom Out',
    ZOOM_IN: 'Zoom In',
    FIT_TO_SCREEN: 'Fit To Screen',
    TOGGLE_SIDEBAR: 'Toggle Sidebar',
    ASSET_DETAILS: 'Function Details',
    ACTIONS: 'More Actions',
    ADD_MORE: 'Add More Tabs',
    PREVIOUS:'Previous Page',
    NEXT:'Next Page',
    FIRST_PAGE:'First Page',
    LAST_PAGE:'Last Page',
    UPDATE_AVAILABLE:'Update Available'
};

export const CONFIG_POPUP = <const>{
    ADD_ALARM_TRIGGER_LABEL: 'Add Alarm Trigger',
    ADD_EVENT_TRIGGER_LABEL: 'Add Event Trigger',
    SETTINGS_LABEL: 'Settings',
};
export const CONNECTION_DISCONNECTED_CONFIRMATION_MESSAGE =
    'Object type mismatch connection data will be lost, Are you sure you want to continue?';

export const CONFIRMATION_ENDPOINT_DELETE =
    'Are you sure do you want to continue?';

export const VIEW_BY_LIST = ["Systems", "Edge"];
export const CONFIRMATION_INITIAL_VALUE =
    'Are you sure do you want add initial value?';
export const CONFIRMATION_INITIAL_CONNECTION_MAPPING =
    'Number of mapping connections and initial values are not matching. Are you sure you want to delete the values which are not matched?';
