import React, { useEffect, useState, Fragment, useMemo } from 'react';
import {
    BaseLayout,
    BodyContainer,
    SidebarContainer,
    NoAccess,
} from '../../containers/EditorLayout';
import ConfigurationBody from './PageBody/ConfigurationBody';
import AssetInstanceBody from './PageBody/AssetInstanceBody';
import './style.scss';
import ButtonBar from './ButtonBar';
import Flex from '../../components/shared/Flex';
import InstanceName from './InstanceName';
import ConfigurationSidebar from './Sidebar/ConfigurationSidebar';
import AssetInstanceSidebar from './Sidebar/AssetInstanceSidebar';
import { Dispatch } from 'redux';
import {
    getAllComputeModelsApi,
    changeActiveModelInstanceConfig,
} from '../../store/instanceConfig/actions';
import { connect } from 'react-redux';
import CanvasController from '../../components/Fabric';

import {
    CONFIGURATION_TOOL_PRIVILEGES,
    CONFIGURATION_TOOL_MODE,
} from '../../utils/constants/appConstants';
import { CREATE_NEW_MODEL } from '../../utils/constants/uiConstants';
import { StoreState } from '../../store';
import { showModal } from '../../store/modal/action';
import AssetConfigModal from './AssetConfigModal';
import {
    getAssetCategoryListActionRequest,
    updateAssetPageStatus,
} from '../../store/assets/actions';
import {
    updateConfigurationToolActiveView,
    initializeConfigurationTool,
    createConfigurationToolJson,
} from '../../store/configurationTool/actions';
import Condition from '../../components/shared/Condition';
import { handleUserLogin, redirectLogin } from '../../store/login/action';
import { LocalJson } from '../../transformers/ComputeModel/fromJson/types';
import AppSettings from '../../services/AppSettings';
import { checkPermissionRoles, handleTokenDecode } from '../../utils/helpers';
import { setAuthorize } from '../../store/modelsPage/actions';
import VerticalNavBar from '../../containers/EditorLayout/VerticalNavBar';
import { commonAuthHelper } from '../../utils/types/commonInterface';

export const Asset = (
    props: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = (val: boolean) => {
        setIsOpen(val);
        if (props.canvasController) {
            let canvas = props.canvasController._canvas;
            let width = canvas.getWidth();
            let new_width = Math.max(width, window.innerWidth);
            canvas.setWidth(new_width);
            props.canvasController.addGridLines(props.canvasController.showGrid);
        }
    };

    const canAccess = useMemo(() => {
        let access = false;
        const token = commonAuthHelper.getToken();
        if (!!token) {
            access = checkPermissionRoles(token);
        }
        return access;
    }, []);

    useEffect(() => {
        props.setAuthorize(canAccess);
        if (canAccess) {
            // props.handleUserLogin();
            handleTokenDecode();
            console.log(props.selectedTreeNode);
            if (!props.selectedTreeNode) {
                props.getAssetsCategoryList();
            }
            props.getAllComputeModelsApi();
            // Canvas
            const canvasController = new CanvasController(
                undefined,
                CONFIGURATION_TOOL_PRIVILEGES.ASSETS_PAGE
            );
            props.initializeConfigurationTool(canvasController);
            if (props.modelInstance && props.assetConfigurationMode) {
                props.updateConfigurationToolJson({
                    json: props.modelInstance.json,
                });
            }
        }
    }, [canAccess]);

    useEffect(() => {
        if (props.assetConfigurationMode) {
            props.handleAssetPageStatus(false);
        }
    }, []);

    return (
        <Fragment>
            <Condition when={!canAccess}>
                <BaseLayout>
                    <NoAccess />
                </BaseLayout>
            </Condition>
            <Condition when={canAccess}>
                <BaseLayout>
                    {/* <VerticalNavBar /> */}
                    <SidebarContainer
                        useDraggable={true}
                        customClassName={
                            props.assetConfigurationMode
                                ? `wrapper-asset-sidebar ${!isOpen ? 'collapsed' : ''} `
                                : ''
                        }
                    >
                        {!props.assetConfigurationMode && <AssetInstanceSidebar />}
                        {props.assetConfigurationMode && isOpen && (
                            <ConfigurationSidebar
                                selectedInstances={props.selectedInstaces}
                                handleSidebarToggle={toggleSidebar}
                            />
                        )}
                        {props.assetConfigurationMode && !isOpen && (
                            <div className="sidebar-tabs">
                                <div
                                    className="tabs-text"
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    Instances
                                </div>
                            </div>
                        )}
                    </SidebarContainer>
                    <BodyContainer fill>
                        <Flex column className="body-container">
                            {!props.assetConfigurationMode && (
                                <Flex column className="asset-instance-body-container">
                                    <AssetInstanceBody />
                                </Flex>
                            )}

                            <div
                                className={`${
                                    !props.assetConfigurationMode ? 'hidden' : 'default'
                                }`}
                            >
                                <Flex row className={`wrapper-asset-page-header`}>
                                    <InstanceName />
                                </Flex>
                                <Flex column className="wrapper-config-instance-body">
                                    <Flex row className="parent-asset-page-config-header">
                                        <div className="asset-page-config-header" onClick={() => {
            const popupElement = document.getElementById('configurationPopup')
            if (popupElement && popupElement.style.visibility === 'visible') {
                popupElement.style.visibility = 'hidden';
            }
        }}>
                                            <div className="wrapper-button-bar">
                                                <ButtonBar
                                                    viewMode={props.viewMode}
                                                    // canvas={props.canvasController}
                                                />
                                            </div>
                                        </div>
                                    </Flex>
                                    <ConfigurationBody viewMode={props.viewMode} />
                                </Flex>
                            </div>
                        </Flex>
                    </BodyContainer>
                </BaseLayout>
            </Condition>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getAllComputeModelsApi: () => {
            dispatch(getAllComputeModelsApi());
        },

        initializeConfigurationTool: (canvasController: CanvasController) => {
            dispatch(
                initializeConfigurationTool({
                    canvasController,
                    mode: CONFIGURATION_TOOL_MODE.EDIT,
                    privileges: CONFIGURATION_TOOL_PRIVILEGES.ASSETS_PAGE,
                })
            );
        },
        updateSelectedOption: (payload: { modelId: string }) => {
            dispatch(changeActiveModelInstanceConfig(payload));
        },
        updateViewMode: () => {
            dispatch(updateConfigurationToolActiveView());
        },
        showAssetConfigureModal: () => {
            dispatch(
                showModal({
                    component: AssetConfigModal,
                    modalTitle: CREATE_NEW_MODEL.ASSET_CONFIG_MODEL_MODIFY,
                    data: {
                        submitBtnText: CREATE_NEW_MODEL.ASSET_MODIFY_TEXT,
                        stopMonitoringBtnText: CREATE_NEW_MODEL.ASSET_STOP_MONITORING,
                    },
                })
            );
        },
        getAssetsCategoryList: () => {
            dispatch(getAssetCategoryListActionRequest());
        },
        redirectLogin: () => {
            dispatch(redirectLogin());
        },
        updateConfigurationToolJson: (payload: { json: LocalJson }) => {
            dispatch(createConfigurationToolJson(payload));
        },
        handleUserLogin: () => {
            dispatch(handleUserLogin());
        },
        setAuthorize: (access: boolean) => {
            dispatch(setAuthorize(access));
        },
        handleAssetPageStatus: (configurationMode: boolean) => {
            dispatch(updateAssetPageStatus(configurationMode));
        },
    };
};

const mapStateToProps = (state: StoreState) => {
    return {
        assetConfigurationMode: state.assets.configurationMode,
        isUnconfiguredInstance: state.instanceConfig.activeModel.isNew,
        viewMode: state.configurationTool.activeView,
        selectedTreeNode: state.assets.selectedTreeNode,
        activeModelId: state.instanceConfig.activeModel.id,
        isLoading: state.instanceConfig.isLoading,
        isSaving: state.instanceConfig.isSaving,
        modelInstance: state.instanceConfig.activeModel.modelInstance,
        isLoggedIn: state.login.isLoggedIn,
        canvasController: state.configurationTool.canvasController,
        selectedInstaces: state.instanceConfig.instancesTable.tableData.selectedInstances,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
