import React, { useState, useCallback } from "react";

import "./style.scss";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import CreateModel from "../../CreateModel";

import { StoreState } from "../../../../store";
import { showModal } from "../../../../store/modal/action";
import Condition from "../../../../components/shared/Condition";

import { Button, Tooltip, WithTooltip } from "@abb/abb-common-ux-react";

import {
    updateConfigurationToolMode,
    createConfigurationToolJson,
    updateOverAllSeverityFunctionStatus,
    updateOverallSeverityFunctionId,
} from "../../../../store/configurationTool/actions";
import MessageModal from "../../../../components/MessageModal";
import {
    CONFIGURATION_TOOL_MODE,
    SAVE_OPTIONS,
    TYPE_VALIDATION_ERROR_MSG,
    NOTIFICATION_MODAL_STATUS,
    CONFIRMATION_MSG,
    TYPE_VALIDATION_WARNING_MSG,
} from "../../../../utils/constants/appConstants";
import {
    cancelUnsavedModelChanges,
    saveComputeModelApi,
    setActiveModalForImport,
} from "../../../../store/modelsPage/actions";
import {
    CREATE_NEW_MODEL,
    UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
    CONFIRMATION_BUTTON,
    TOOLTIP_MESSAGES,
    CONFIRMATION_INITIAL_CONNECTION_MAPPING,
} from "../../../../utils/constants/uiConstants";
import ActiveViewToggleButton from "../ActiveViewToggleButton";
import {
    setImportModals,
    importModals,
    showImportModal,
    handleFilesImport,
    setDetailsForTypeLevelImport,
} from "../../../../store/exportAsset/actions";
import { ComputeModelToJson } from "../../../../transformers/ComputeModel/toJson/ComputeModelToJson";
import {
    markComputeModelDirty,
    updateConfigurationToolJson,
} from "../../../../store/configurationTool/actions";
import { ComputeModelToJsonConstructorOptions } from "../../../../transformers/ComputeModel/toJson/types";
import { computeModelJsonValidation } from "../../../../transformers/ComputeModel/validation/computeModelJson";
import { showNotificationModal } from "../../../../store/notificationModal/action";
import { hideDialog, showDialog } from "../../../../store/dialog/action";
import {
    ImportModalPayload,
    ImportFilePayload,
    PayloadForTypeLevelImport,
    TypesWithLibraries,
} from "../../../../store/exportAsset/types";
import ConfirmationMessage from "../../../../components/Fabric/utils/ConfirmationMessage";
import { ToggleButtonGroup, ToggleButton } from "@abb/abb-common-ux-react";
import CanvasController from "../../../../components/Fabric";
import { updateConfigurationToolActiveView } from "../../../../store/configurationTool/actions";
import { showGrid } from "../../../../store/grid/action";
import { store } from "../../../../containers/AppBoot/index";
import { isGlobalTenantType } from "../../../../utils/helpers";

interface CanvasHeaderProps {
    currentModels: string;
    checkboxLabel: string;
    showContainer: boolean;
    toggleImportExportDialog?: () => void;
    canvasDataLoading?: boolean;
    importExportDisable?: boolean;
    // canvasController: CanvasController;
}

function CanvasHeader(
    props: CanvasHeaderProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) {
    const [selectedBtn, setSelectedBtn] = useState([0]);
    const [zoom, setzoom] = useState(0);
    const handleActiveView = (selectedBtn: number[]) => {
        console.log(selectedBtn);
        setSelectedBtn(selectedBtn);
    };
    const handleSave = useCallback(
        (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
            const { configurationTool } = store.getState();
            const { tableData } = configurationTool;

            let isValidInitialValues: boolean = false;
            let invalidDefaultEmptyValue: boolean = false;
            if (
                tableData &&
                tableData.functionTypeDetails &&
                tableData.functionTypeDetails.length > 0
            ) {
                tableData.functionTypeDetails.forEach((typeDetail: any) => {
                    if (typeDetail.inputs && typeDetail.inputs.length > 0) {
                        const origVersionId = typeDetail.assetRef.split("@");
                        const origId =
                            origVersionId[0] + "#" + typeDetail.assetVersion;
                        const origFuncData =
                            configurationTool.originalFunctionTypesUsed
                                .byIdWithVersion[origId] ||
                            configurationTool.latestOriginalFunctionsUsed
                                .byIdWithVersion[origId];
                        typeDetail.inputs.forEach((inputDetail: any) => {
                            const functionInputs =
                                origFuncData &&
                                origFuncData.inputs.find(
                                    (origInputData: any) =>
                                        origInputData.name === inputDetail.name
                                );
                            if (functionInputs) {
                                let defaultValue = inputDetail.defaultValue
                                    ? inputDetail.defaultValue.split(",")
                                    : "";
                                if (
                                    defaultValue.includes("") &&
                                    functionInputs.defaultValue
                                ) {
                                    invalidDefaultEmptyValue = true;
                                }

                                if (defaultValue && defaultValue.length > 0) {
                                    if (inputDetail.mappingDetails.length > 0) {
                                        if (
                                            inputDetail.mappingDetails.length !=
                                            defaultValue.length
                                        ) {
                                            isValidInitialValues = true;
                                            // defaultValue.splice(inputDetail.mappingDetails.length);
                                            // inputDetail.defaultValue = defaultValue.join(",");
                                        }
                                    }
                                }
                            }
                        });
                    }
                });
            }

            if (invalidDefaultEmptyValue) {
                store.dispatch(
                    showNotificationModal({
                        title: TYPE_VALIDATION_ERROR_MSG,
                        resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                        details: ["Please enter all initial values"],
                        type: "confirmation",
                    })
                );
                return;
            }
            if (isValidInitialValues) {
                // if(e.target.id || e.currentTarget.id) {
                // const saveOption = e.target.id || e.currentTarget.id
                confirmMappingConnection(e.target, e.currentTarget);
                // store.dispatch(showNotificationModal({
                //     title: TYPE_VALIDATION_ERROR_MSG,
                //     resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                //     details: ["Please check the mapping connections and initial values are not matching"],
                //     type: 'confirmation'
                // }));
                // return;
                // props.saveComputeModelApi({
                //     //@ts-ignore
                //     saveOption: e.target.id || e.currentTarget.id,
                // });
            } else {
                props.saveComputeModelApi({
                    //@ts-ignore
                    saveOption: e.target.id || e.currentTarget.id,
                });
            }
        },
        []
    );

    const confirmMappingConnection = (target: any, currentTarget: any) => {
        props.showConfirmPopup(target, currentTarget);
    };

    console.log("modeldetails", props.modelDetails);
    return (
        <div
            className={`wrapper-canvas-header ${
                props.showContainer ? "" : "hidden"
            } ${props.isEditMode ? "edit-header" : "non-edit-header"}`} 
            onClick={() => {
                const popupElement = document.getElementById('configurationPopup')
                if (popupElement && popupElement.style.visibility === 'visible') {
                    popupElement.style.visibility = 'hidden';
                }
            }}
        >
            <div
                className={`canvas-specific-details ${
                    props.isEditMode ? "" : "borderfor-canvas-detials"
                }`}
            >
                <div className={`canvas-header-left `}>
                    {props.isEditMode
                        ? `${props.activeModelName} ${props.modelDetails.version}`
                        : props.canvasDataLoading
                        ? ""
                        : `${props.activeModelName} ${props.modelDetails.version}`}
                </div>

                <div className="canvas-header-right">
                    <Condition when={!props.isEditMode}>
                        <WithTooltip>
                            <Button
                                className="toggle-grid-btn"
                                type={
                                    props.showGrid ? "primary-blue" : "normal"
                                }
                                sizeClass="small"
                                icon="abb/matrix"
                                id="mGrid"
                                onClick={() =>
                                    props.toggleGridDisplay(!props.showGrid)
                                }
                            />
                            <Tooltip>{TOOLTIP_MESSAGES.GRID}</Tooltip>
                        </WithTooltip>

                        <Button
                            className="update-func-btn"
                            type="primary-blue"
                            text="Update"
                            sizeClass="small"
                            isLoading={props.isFunctionsUpdating}
                            disabled={
                                !props.isFunctionUpdatesAvailable ||
                                props.isImportMode ||
                                !!props.canvasDataLoading ||
                                isGlobalTenantType(
                                    props.modelDetails.tags,
                                    props.modelDetails.typeId
                                )
                            }
                            onClick={props.updateTypeWithLatestFunctions}
                            icon="abb/synchronize"
                        />
                        <ActiveViewToggleButton />
                        <Button
                            className="edit-model-btn"
                            icon="abb/edit"
                            type="primary-blue"
                            text="Edit"
                            sizeClass="small"
                            onClick={() => {
                                props.updateConfigurationToolMode({
                                    mode: CONFIGURATION_TOOL_MODE.EDIT,
                                });
                            }}
                            disabled={
                                props.canvasDataLoading ||
                                isGlobalTenantType(
                                    props.modelDetails.tags,
                                    props.modelDetails.typeId
                                )
                            }
                            id="mEditBtn"
                        />
                    </Condition>
                    <Condition when={props.isImportMode}>
                        <Button
                            icon="abb/import"
                            type="primary-blue"
                            text="Import"
                            sizeClass="small"
                            id="mImport"
                            onClick={() => {
                                const modelToSend: ComputeModelToJsonConstructorOptions =
                                    {
                                        ...props.activeModel,
                                        json: props.configurationToolJson,
                                        saveOptions: {
                                            value: SAVE_OPTIONS.PUBLISH,
                                        },
                                        overallSelectedFunctionId:
                                            props.overallSeverityFunctionId,
                                    };

                                const payload = [
                                    {
                                        model: new ComputeModelToJson(
                                            modelToSend
                                        ),
                                        functionTypes: [
                                            ...props.singleImportFunctionTypes,
                                        ],
                                    },
                                ];
                                const validations = computeModelJsonValidation({
                                    json: props.configurationToolJson,
                                    validations: { connect_both_inhibit: true },
                                    overAllSelectedFunctionId:
                                        props.overallSeverityFunctionId,
                                });
                                if (validations.isValid) {
                                    const modelToSend: ComputeModelToJsonConstructorOptions =
                                        {
                                            ...props.activeModel,
                                            json: props.configurationToolJson,
                                            saveOptions: {
                                                value: SAVE_OPTIONS.PUBLISH,
                                            },
                                            overallSelectedFunctionId:
                                                props.overallSeverityFunctionId,
                                        };

                                    const selectedModelToImport =
                                        new ComputeModelToJson(modelToSend);

                                    let selectedItemsForImport: TypesWithLibraries[] =
                                        props.selectedItemsForImport as TypesWithLibraries[];
                                    selectedItemsForImport[0].model =
                                        selectedModelToImport;
                                    let updatedDetailsForImport: PayloadForTypeLevelImport =
                                        {
                                            dependantLibraries:
                                                props.dependantLibraries,
                                            passwordDetails:
                                                props.passwordDetails,
                                            selectedItemsForImport,
                                        };
                                    props.setDetailsForTypeLevelImport(
                                        updatedDetailsForImport
                                    );
                                    props.importModals(payload);
                                    props.handleFilesImport({
                                        contentRoute: "/models",
                                        type: "MULTIPLE",
                                    });
                                } else {
                                    props.showNotificationModal({
                                        title: TYPE_VALIDATION_WARNING_MSG,
                                        details: [...validations.errorDetails],
                                        type: "confirmation",
                                        resultStatus:
                                            NOTIFICATION_MODAL_STATUS.WARNING,
                                    });
                                }
                            }}
                        />
                        <Button
                            type="discreet-blue"
                            text={CONFIRMATION_BUTTON.CANCEL}
                            sizeClass="small"
                            onClick={() => {
                                props.updateConfigurationToolMode({
                                    mode: CONFIGURATION_TOOL_MODE.VIEW,
                                });
                                if (props.computeModels.entities.length > 0) {
                                    const json =
                                        props.computeModels.byId[
                                            props.computeModels.entities[0]
                                        ].json;
                                    props.createConfigurationToolJson({ json });
                                    props.setActiveModalForImport(
                                        props.computeModels.byId[
                                            props.computeModels.entities[0]
                                        ]
                                    );
                                } else {
                                    props.createConfigurationToolJson({
                                        json: {
                                            assetData: [],
                                            connectionData: [],
                                        },
                                    });
                                }
                            }}
                            id="mCancelBtn"
                        />
                    </Condition>
                    <Condition when={props.isEditMode && !props.isImportMode}>
                        <div className="edit-topbar">
                            <Button
                                type="discreet-blue"
                                text={CONFIRMATION_BUTTON.CANCEL}
                                sizeClass="small"
                                onClick={
                                    props.isNew || props.isDirty
                                        ? props.showConfirmationDialog
                                        : props.cancelUnsavedModelChanges
                                }
                                id="mCancelBtn"
                            />

                            <Button
                                className="save-btn"
                                id={SAVE_OPTIONS.DRAFT}
                                onClick={handleSave}
                                text="Save as Draft"
                                type="normal"
                                sizeClass="small"
                                isLoading={
                                    props.isSaving &&
                                    props.saveOption === SAVE_OPTIONS.DRAFT
                                }
                            ></Button>
                            <Button
                                className="save-btn"
                                id={SAVE_OPTIONS.PUBLISH}
                                type="primary-blue"
                                text="Save"
                                sizeClass="small"
                                onClick={handleSave}
                                isLoading={
                                    props.isSaving &&
                                    props.saveOption === SAVE_OPTIONS.PUBLISH
                                }
                            ></Button>
                        </div>
                    </Condition>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        toggleGridDisplay: (show: boolean) => {
            dispatch(showGrid(show));
        },
        showCreateModel: () => {
            dispatch(
                showModal({
                    component: CreateModel,
                    modalTitle: CREATE_NEW_MODEL.CREATE_MODEL,
                    data: {
                        submitBtnText: CREATE_NEW_MODEL.CREATE_BTN_TEXT,
                    },
                })
            );
        },
        updateConfigurationToolMode: (payload: {
            mode: "VIEW" | "EDIT" | "CREATE" | "IMPORT";
        }) => {
            dispatch(updateConfigurationToolMode(payload));
        },
        showConfirmationDialog: () => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                    customClassName: "wrapper-message-modal",
                    data: {
                        warningText: UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: "discreet-black",
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                                type: "primary-blue",
                                handler: (dlg: any) => {
                                    dispatch(cancelUnsavedModelChanges());
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
        cancelUnsavedModelChanges: () => {
            dispatch(cancelUnsavedModelChanges());
        },
        saveComputeModelApi: (options: { saveOption: SAVE_OPTIONS }) => {
            dispatch(saveComputeModelApi(options));
        },
        setImportModals: (val: boolean) => dispatch(setImportModals(val)),
        importModals: (
            val: {
                model: ComputeModelToJson;
                functionTypes: object[];
            }[]
        ) => dispatch(importModals(val)),
        handleFilesImport: (payload: ImportFilePayload) =>
            dispatch(handleFilesImport(payload)),
        createConfigurationToolJson: (val: any) =>
            dispatch(createConfigurationToolJson(val)),
        setActiveModalForImport: (val: any) => {
            dispatch(setActiveModalForImport(val));
        },
        updateOverAllSeverityFunctionStatus: (
            isOverAllSeverityFuncAdded: boolean
        ) => {
            dispatch(
                updateOverAllSeverityFunctionStatus(isOverAllSeverityFuncAdded)
            );
        },
        updateOverallSeverityFunctionId: (nodeId: string) => {
            dispatch(updateOverallSeverityFunctionId(nodeId));
        },
        markComputeModelDirty: () => dispatch(markComputeModelDirty()),
        showNotificationModal: (val: any) =>
            dispatch(showNotificationModal(val)),
        showImportModal: (payload: ImportModalPayload) => {
            dispatch(showImportModal(payload));
        },
        setDetailsForTypeLevelImport: (payload: PayloadForTypeLevelImport) =>
            dispatch(setDetailsForTypeLevelImport(payload)),
        updateTypeWithLatestFunctions: () => {
            ConfirmationMessage({
                warningMessage: CONFIRMATION_MSG.UPDATE_FUNCTION_WARNING,
                deleteType: CONFIRMATION_MSG.UPDATE_ALL_FUNCTION,
                messageModalTitle: CONFIRMATION_MSG.UPDATE_FUNC_TITLE,
                acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
            });
        },
        showConfirmPopup: (target: any, currentTarget: any) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: CONFIRMATION_BUTTON.CONFIRM,
                    customClassName: "wrapper-message-modal",
                    data: {
                        warningText: CONFIRMATION_INITIAL_CONNECTION_MAPPING,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: "discreet-black",
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.OK,
                                type: "primary-blue",
                                handler: (dlg: any) => {
                                    const { configurationTool } =
                                        store.getState();
                                    const { tableData } = configurationTool;
                                    let isValidInitialValues: boolean = false;
                                    if (
                                        tableData &&
                                        tableData.functionTypeDetails &&
                                        tableData.functionTypeDetails.length > 0
                                    ) {
                                        tableData.functionTypeDetails.forEach(
                                            (typeDetail: any) => {
                                                if (
                                                    typeDetail.inputs &&
                                                    typeDetail.inputs.length > 0
                                                ) {
                                                    typeDetail.inputs.forEach(
                                                        (inputDetail: any) => {
                                                            let defaultValue =
                                                                inputDetail.defaultValue
                                                                    ? inputDetail.defaultValue.split(
                                                                          ","
                                                                      )
                                                                    : "";
                                                            if (
                                                                defaultValue &&
                                                                defaultValue.length >
                                                                    0
                                                            ) {
                                                                if (
                                                                    inputDetail
                                                                        .mappingDetails
                                                                        .length >
                                                                    0
                                                                ) {
                                                                    if (
                                                                        inputDetail
                                                                            .mappingDetails
                                                                            .length >
                                                                            defaultValue.length ||
                                                                        inputDetail
                                                                            .mappingDetails
                                                                            .length <
                                                                            defaultValue.length
                                                                    ) {
                                                                        // isValidInitialValues = true;
                                                                        defaultValue.splice(
                                                                            inputDetail
                                                                                .mappingDetails
                                                                                .length
                                                                        );
                                                                        inputDetail.defaultValue =
                                                                            defaultValue.join(
                                                                                ","
                                                                            );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        );
                                        dispatch(
                                            updateConfigurationToolJson({
                                                tableData,
                                            })
                                        );
                                        dispatch(
                                            saveComputeModelApi({
                                                //@ts-ignore
                                                saveOption:
                                                    target.id ||
                                                    currentTarget.id,
                                            })
                                        );
                                    }

                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};
const mapStateToProps = (state: StoreState) => {
    return {
        isEditMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        isImportMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        activeModelName:
            state.modelsPage.activeModel.modelInstance &&
            state.modelsPage.activeModel.modelInstance.modelDetails.name,
        modelDetails:
            state.modelsPage.activeModel.modelInstance &&
            state.modelsPage.activeModel.modelInstance.modelDetails,

        isNew: state.modelsPage.activeModel.isNew,
        showGrid: state.grid.show,
        isDirty: state.configurationTool.isDirty,
        isSaving: state.loader.computeModelSaving.isSaving,
        saveOption: state.loader.computeModelSaving.data
            ? state.loader.computeModelSaving.data.saveOption
            : "",
        hasEdit: state.modelsPage.modelPermission.hasEdit,
        hasSave: state.modelsPage.modelPermission.hasSave,
        hasImport: state.modelsPage.modelPermission.hasImport,
        hasExport: state.modelsPage.modelPermission.hasExport,
        configurationToolJson: state.configurationTool.json,
        activeModel: state.modelsPage.activeModel.modelInstance,
        computeModels: state.modelsPage.computeModels,
        singleImportFunctionTypes: state.exportAsset.singleImportFunctionTypes,
        overallSeverityFunctionId:
            state.configurationTool.overallSeverityFunctionId,
        isFunctionUpdatesAvailable: state.modelsPage.isFunctionUpdatesAvailable,
        isFunctionsUpdating: state.modelsPage.isFunctionsUpdating,
        passwordDetails: state.exportAsset.passwordDetails,
        dependantLibraries: state.exportAsset.dependantLibraries,
        selectedItemsForImport: state.exportAsset.selectedItemsForImport,
        canvasController: state.configurationTool.canvasController,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CanvasHeader);
