import React from "react";
import _ from "lodash";
import Table from "../../Table";
import Tr from "../../Table/TableRow";
import Thead from "../../Table/TableHead";
import Th from "../../Table/TableHeaderData";
import Tbody from "../../Table/TableBody";
import "./style.scss";

import {
    ACTIVE_INPUT_TAB,
    ACTIVE_OUTPUT_TAB,
    ACTIVE_ALARM_TAB,
    ACTIVE_TRIGGER_TAB,
} from "../constant";

import { IoTableTabsProps } from "..";
import ExpandableInputRows from "./ExpandTableRow/InputRow";
import ExpandableOutputRows from "./ExpandTableRow/OutputRow";
import ExpandableAlarmRows from "./ExpandTableRow/AlarmRow";
import TriggerTabTableView from "../TriggerTabTableView";

export interface AppProps {
    activeTab: string;
    tableHeaderData: {
        label: string;
        value: string;
    }[];
}

function SimpleTableView(props: AppProps & IoTableTabsProps) {
    if (props.activeTab === ACTIVE_TRIGGER_TAB) {
        return <TriggerTabTableView />;
    }
    return (
        <div className="simple-table-view">
            <div className="table1">
                <Table
                    className={`table-ioTableTabs ${
                        !props.isEditMode ||
                        props.activeTab === ACTIVE_OUTPUT_TAB
                            ? "table-row-width-100"
                            : ""
                    } `}
                >
                    <Thead>
                        <Tr>
                            {props.tableHeaderData.map((header, index) => {
                                return (
                                    <Th
                                        key={index}
                                        className={`${
                                            header.label === "ALIAS"
                                                ? " alias-column"
                                                : ""
                                        } ${
                                            header.label === "VALUE"
                                                ? " value-column"
                                                : ""
                                        }`}
                                    >
                                        {header.label}
                                    </Th>
                                );
                            })}
                        </Tr>
                    </Thead>

                    <Tbody className="table-body">
                        {props.activeTab === ACTIVE_INPUT_TAB
                            ? props.tableData.functionTypeDetails.map(
                                  (functionAsset) => {
                                      return (
                                          <ExpandableInputRows
                                              key={
                                                  functionAsset.nodeId + "input"
                                              }
                                              {...props}
                                              asset={functionAsset}
                                          />
                                      );
                                  }
                              )
                            : props.activeTab === ACTIVE_OUTPUT_TAB
                            ? props.tableData.functionTypeDetails.map(
                                  (functionAsset) => {
                                      return (
                                          <ExpandableOutputRows
                                              key={
                                                  functionAsset.nodeId +
                                                  "output"
                                              }
                                              {...props}
                                              asset={functionAsset}
                                          />
                                      );
                                  }
                              )
                            : props.tableData.functionTypeDetails.map(
                                  (functionAsset) => {
                                      return (
                                          <ExpandableAlarmRows
                                              key={
                                                  functionAsset.nodeId + "alarm"
                                              }
                                              {...props}
                                              asset={functionAsset}
                                          />
                                      );
                                  }
                              )}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
}

export default SimpleTableView;
