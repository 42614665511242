import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import './style.scss';
import UpdateModel from '../../CreateModel';
import {
    saveComputeModelApi,
    deleteComputeModelApi,
    getModelUsedbyInstances,
    handleExistingTypeIdError,
} from '../../../../store/modelsPage/actions';
import { StoreState } from '../../../../store';
import {
    CREATE_NEW_MODEL,
    DELETE_MODEL_MSG,
    CONFIRMATION_BUTTON,
    TOOLTIP_MESSAGES,
} from '../../../../utils/constants/uiConstants';
import Menu, { MenuItem, SubMenu } from 'rc-menu';
import {
    SAVE_OPTIONS,
    DELETE_MODEL_TYPE,
    CONFIGURATION_TOOL_MODE,
    VIEW_MODE,
    CONFIRMATION_MSG,
} from '../../../../utils/constants/appConstants';
import {
    updateConfigurationToolMode,
    updateConfigurationToolActiveView,
    updateConfigurationToolJson,
    updateOverAllSeverityFunctionStatus,
    updateOverallSeverityFunctionId,
    undoJSON,
    redoJSON,
} from '../../../../store/configurationTool/actions';
import { showModal } from '../../../../store/modal/action';
import { ModelUsedInstanceType, InstancePaginationType } from '../../../../store/modelsPage/types';
import { Dialog, Icon, Button, WithTooltip, Tooltip } from '@abb/abb-common-ux-react';
import ConfirmationMessage from '../../../../components/Fabric/utils/ConfirmationMessage';
import InstancesTable from '../../../../components/LeftNavigationData/DataRow/InstancesTable';
import DeleteTableAssetsModal from './deleteTableAssetsModal';
import ActiveViewToggleButton from '../ActiveViewToggleButton';
import TableController from '../../../../model/TableController';
import { hideDialog, showDialog } from '../../../../store/dialog/action';
import MessageModal from '../../../Functions/MessageModal';
import { showGrid } from '../../../../store/grid/action';

interface CanvasTopBarProps {
    currentActiveView?: string;
    showContainer: boolean;
    canvasDataLoading: boolean;
}

function CanvasTopBar(
    props: CanvasTopBarProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const updateCanvasModel = () => {
        console.log('updateModal called');
        props.showUpdateModal();
    };

    const [showModal, updateModalState] = useState(false);
    const [showDeteleAssetsModal, setShowDeteleAssetsModal] = useState(false);
    const [usedInstanceList, updateUsedInstanceList] = useState<ModelUsedInstanceType[]>([]);

    useEffect(() => {
        updateUsedInstanceList(props.usedInstanceList);
    }, [props.usedInstanceList]);

    const { currentActiveView, updateConfigurationToolJson } = props;

    useEffect(() => {
        console.log('UNDO_CHANGED');
    }, [props.undoStack]);

    return (
        <div className="canvas-topbar-parent" onClick={() => {
            const popupElement = document.getElementById('configurationPopup')
            if (popupElement && popupElement.style.visibility === 'visible') {
                popupElement.style.visibility = 'hidden';
            }
        }}>
            <div className={`wrapper-canvas-topbar ${props.showContainer ? '' : 'hidden'}`}>
                <div className="topbar-right">
                    {currentActiveView === VIEW_MODE.TABLE.value && (
                        <div className="delete-wrapper">
                            <Button
                                type="primary-blue"
                                text="Delete Objects,Models and Functions"
                                sizeClass="small"
                                onClick={() => setShowDeteleAssetsModal(true)}
                                id="mdelBtn"
                            />
                            {showDeteleAssetsModal && (
                                <DeleteTableAssetsModal
                                    showDeleteTableAssetsModal={true}
                                    onClose={() => setShowDeteleAssetsModal(false)}
                                    tableData={props.tableData}
                                    updateConfigurationToolJson={(val: any) =>
                                        updateConfigurationToolJson(val)
                                    }
                                    overallSeverityFunctionId={props.overallSeverityFunctionId}
                                    updateOverAllSeverityFunctionStatus={
                                        props.updateOverAllSeverityFunctionStatus
                                    }
                                    updateOverallSeverityFunctionId={
                                        props.updateOverallSeverityFunctionId
                                    }
                                    showMessageModal={props.showMessageModal}
                                />
                            )}
                        </div>
                    )}
                    <WithTooltip>
                        <Button
                            className="toggle-grid-btn"
                            type={'primary-blue'}
                            sizeClass="small"
                            icon="abb/undo"
                            disabled={props.undoStack.length === 0}
                            id="mGrid"
                            onClick={props.undoConfigurationJson}
                        />
                        <Tooltip>{TOOLTIP_MESSAGES.UNDO}</Tooltip>
                    </WithTooltip>
                    <WithTooltip>
                        <Button
                            className="toggle-grid-btn"
                            type={'primary-blue'}
                            sizeClass="small"
                            disabled={props.redoStack.length === 0}
                            icon="abb/redo"
                            id="mGrid"
                            onClick={props.redoConfigurationJson}
                        />
                        <Tooltip>{TOOLTIP_MESSAGES.REDO}</Tooltip>
                    </WithTooltip>
                    <WithTooltip>
                        <Button
                            className="toggle-grid-btn"
                            type={props.showGrid ? 'primary-blue' : 'normal'}
                            sizeClass="small"
                            icon="abb/matrix"
                            id="mGrid"
                            onClick={() => props.toggleGridDisplay(!props.showGrid)}
                        />
                        <Tooltip>{TOOLTIP_MESSAGES.GRID}</Tooltip>
                    </WithTooltip>

                    <Button
                        className="update-func-btn"
                        type="primary-blue"
                        text="Update"
                        sizeClass="small"
                        disabled={
                            !props.isFunctionUpdatesAvailable ||
                            props.isImportMode ||
                            !!props.canvasDataLoading ||
                            props.configurationToolMode === CONFIGURATION_TOOL_MODE.CREATE
                        }
                        isLoading={props.isFunctionsUpdating}
                        onClick={props.updateTypeWithLatestFunctions}
                        icon="abb/synchronize"
                    />
                    <ActiveViewToggleButton />

                    <div className="edit-menu">
                        <div className="menu-div"></div>
                        {props.editMode && (
                            <Menu mode="horizontal" className="custom-rc-menu">
                                <SubMenu popupOffset={[0, 0]} title={<Icon name="abb/more" />}>
                                    <MenuItem key="mDetails" className="model-menu-item">
                                        <div
                                            className="menu-item-wrapper"
                                            onClick={updateCanvasModel}
                                        >
                                            <Icon name="abb/information-circle-2" />
                                            <div className="menu-item-name">Details</div>
                                        </div>
                                    </MenuItem>
                                    <MenuItem
                                        key="mViewInstances"
                                        className="model-menu-item"
                                        disabled={
                                            props.mode === CONFIGURATION_TOOL_MODE.CREATE ||
                                            props.mode === CONFIGURATION_TOOL_MODE.IMPORT
                                        }
                                    >
                                        <div
                                            className="menu-item-wrapper"
                                            onClick={() => {
                                                if (
                                                    props.mode !== CONFIGURATION_TOOL_MODE.CREATE &&
                                                    props.mode !== CONFIGURATION_TOOL_MODE.IMPORT
                                                ) {
                                                    updateModalState(true);
                                                    props.showUsedInstanceModel({
                                                        pageNumber: 1,
                                                        pageSize: 100,
                                                    });
                                                }
                                            }}
                                        >
                                            <Icon name="abb/view" className="menu-item-icon" />
                                            <div className="menu-item-name">View instances</div>
                                        </div>
                                    </MenuItem>
                                    <MenuItem
                                        key="mDelete"
                                        className="model-menu-item"
                                        disabled={
                                            props.mode === CONFIGURATION_TOOL_MODE.CREATE ||
                                            props.mode === CONFIGURATION_TOOL_MODE.IMPORT ||
                                            !props.hasDelete
                                        }
                                    >
                                        <div
                                            className="menu-item-wrapper"
                                            onClick={() =>
                                                props.mode !== CONFIGURATION_TOOL_MODE.CREATE &&
                                                props.mode !== CONFIGURATION_TOOL_MODE.IMPORT &&
                                                props.delteModelPopup()
                                            }
                                        >
                                            <Icon name="abb/trash" className="menu-item-icon" />
                                            <div className="menu-item-name">Delete Model</div>
                                        </div>
                                    </MenuItem>
                                </SubMenu>
                            </Menu>
                        )}
                    </div>
                    <Dialog
                        containerClassName="logic-add-dialog new-layout"
                        title="Model Instance list"
                        dimBackground={true}
                        showCloseButton={true}
                        isOpen={showModal}
                        onClose={() => {
                            updateModalState(false);
                            updateUsedInstanceList([]);
                        }}
                        className="instance-dialog-class"
                    >
                        <InstancesTable usedInstanceList={usedInstanceList} />
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        activeModelObj: state.modelsPage.activeModel.modelInstance,
        showGrid: state.grid.show,
        editMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        isImportMode: state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
        configurationToolMode: state.configurationTool.mode,
        canvasController: state.configurationTool.canvasController,
        usedInstanceList: state.modelsPage.modelUsedInstances,
        mode: state.configurationTool.mode,
        activeModelType:
            state.modelsPage.activeModel.modelInstance &&
            state.modelsPage.activeModel.modelInstance.modelDetails.typeId,
        hasEdit: state.modelsPage.modelPermission.hasEdit,
        hasDelete: state.modelsPage.modelPermission.hasDelete,
        tableData: state.configurationTool.tableData,
        overallSeverityFunctionId: state.configurationTool.overallSeverityFunctionId,
        isFunctionUpdatesAvailable: state.modelsPage.isFunctionUpdatesAvailable,
        isFunctionsUpdating: state.modelsPage.isFunctionsUpdating,
        undoStack: state.configurationTool.undoStack,
        redoStack: state.configurationTool.redoStack,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleActiveView: () => {
        dispatch(updateConfigurationToolActiveView());
    },
    toggleGridDisplay: (show: boolean) => {
        dispatch(showGrid(show));
    },
    updateConfigurationToolMode: () => {
        dispatch(updateConfigurationToolMode({ mode: CONFIGURATION_TOOL_MODE.EDIT }));
    },
    updateConfigurationToolJson: (payload: {
        tableData: {
            objectTypeDetails: TableController['objectTypeDetails'];
            functionTypeDetails: TableController['functionTypeDetails'];
            alarmTypeDetails: TableController['alarmTypeDetails'];
            eventTypeDetails: TableController['eventTypeDetails'];
        };
    }) => {
        dispatch(updateConfigurationToolJson(payload));
    },
    saveComputeModelApi: (options: { saveOption: SAVE_OPTIONS }) => {
        dispatch(saveComputeModelApi(options));
    },
    deleteComputeModelApi: () => {
        dispatch(deleteComputeModelApi());
    },
    showUpdateModal: () => {
        dispatch(
            showModal({
                component: UpdateModel,
                modalTitle: CREATE_NEW_MODEL.UPDATE_MODEL,
                data: {
                    submitBtnText: CREATE_NEW_MODEL.UPDATE_BTN_TEXT,
                },
            })
        );
        dispatch(handleExistingTypeIdError(false));
    },
    delteModelPopup: () => {
        ConfirmationMessage({
            warningMessage: DELETE_MODEL_MSG.DELETE_MODEL_MSG,
            deleteType: DELETE_MODEL_TYPE.MODEL,
            messageModalTitle: 'Delete',
            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
        });
    },

    showUsedInstanceModel: (paylod: InstancePaginationType) => {
        dispatch(getModelUsedbyInstances(paylod));
    },
    updateOverAllSeverityFunctionStatus: (payload: boolean) => {
        dispatch(updateOverAllSeverityFunctionStatus(payload));
    },
    updateOverallSeverityFunctionId: (payload: string) =>
        dispatch(updateOverallSeverityFunctionId(payload)),
    showMessageModal: (payload: {
        title: string;
        rejectButtonTitle: string;
        acceptButtonTitle: string;
        handleStandardButton: (data: string, tabSel?: any) => void;
        selectedTab?: string;
        warningText: string;
    }) => {
        const {
            title,
            rejectButtonTitle,
            acceptButtonTitle,
            selectedTab,
            handleStandardButton,
            warningText,
        } = payload;
        const rejectButtonText = rejectButtonTitle || CONFIRMATION_BUTTON.CANCEL;
        const acceptButtonText = acceptButtonTitle || CONFIRMATION_BUTTON.ACCEPT;
        dispatch(
            showDialog({
                component: MessageModal,
                modalTitle: title || 'Discard Changes?',
                customClassName: 'wrapper-message-modal',
                data: {
                    selectedTab: selectedTab,
                    warningText: warningText,
                    standardButtonsOnBottom: [
                        {
                            text: rejectButtonText,
                            type: 'discreet-black',
                            handler: (dlg: any) => {
                                handleStandardButton(CONFIRMATION_BUTTON.REJECT, selectedTab);
                                dispatch(hideDialog());
                            },
                        },
                        {
                            text: acceptButtonText,
                            type: 'primary-blue',
                            handler: (dlg: any) => {
                                handleStandardButton(CONFIRMATION_BUTTON.ACCEPT, selectedTab);
                                dispatch(hideDialog());
                            },
                        },
                    ],
                },
            })
        );
    },
    updateTypeWithLatestFunctions: () => {
        ConfirmationMessage({
            warningMessage: CONFIRMATION_MSG.UPDATE_FUNCTION_WARNING,
            deleteType: CONFIRMATION_MSG.UPDATE_ALL_FUNCTION,
            messageModalTitle: CONFIRMATION_MSG.UPDATE_FUNC_TITLE,
            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
        });
    },
    undoConfigurationJson: () => {
        dispatch(undoJSON());
    },
    redoConfigurationJson: () => {
        dispatch(redoJSON());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CanvasTopBar);
