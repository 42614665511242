import React, { useCallback, useState, useEffect } from "react";
import ReactSelect from "react-select";

import "./style.scss";
import { ActionMeta } from "react-select/src/types";
import { Connection } from "../../../Fabric/ConnectionController/types";
import { IoTableTabsProps } from "../..";
import {
    FunctionTypeDetailsWithMappings,
    TableDropDownData,
} from "../../../../model/TableController/types";
import { customReactSelectStyles } from "../../../../utils/helpers";

interface AppProps {
    dropDownOptions: TableDropDownData[];
    selectedOptions: number[];
    inputAsset: FunctionTypeDetailsWithMappings;
    inputCircleData: FunctionTypeDetailsWithMappings["inputs"][0];
    addRemoveConnectionWithInitialValues: (type: string, assets: any) => void;
}
const ConnectionDropdown = (props: AppProps & IoTableTabsProps) => {
    console.log("ConnectionDropdown props:", props.inputCircleData);
    const [selectedOption, setSelectedOption] = useState(
        [] as TableDropDownData[]
    );
    useEffect(() => {
        const alreadySelectedOptions: TableDropDownData[] = [];
        props.selectedOptions &&
            props.selectedOptions.map((itemindex) => {
                alreadySelectedOptions.push(props.dropDownOptions[itemindex]);
            });
        setSelectedOption(alreadySelectedOptions);
    }, [props.selectedOptions]);
    const handleChange = useCallback(
        (value, actionMeta: any) => {
            console.log(actionMeta);
            if (actionMeta.action === "remove-value") {
                let currentMappingDetail =
                    actionMeta.removedValue.mappingDetail.find(
                        (
                            item: FunctionTypeDetailsWithMappings["inputs"][0]["mappingDetails"][0]
                        ) =>
                            item.output.circleData.id ===
                            actionMeta.removedValue.output.id
                    );

                // if (!currentMappingDetail) {
                //     currentMappingDetail = value[0].mappingDetail.find(
                //         (
                //             item: FunctionTypeDetailsWithMappings["inputs"][0]["mappingDetails"][0]
                //         ) =>
                //             item.output.circleData.id ===
                //             actionMeta.removedValue.output.id
                //     );
                // }

                let currentMappingDetailIndex =
                    actionMeta.removedValue.mappingDetail.findIndex(
                        (
                            item: FunctionTypeDetailsWithMappings["inputs"][0]["mappingDetails"][0]
                        ) =>
                            item.output.circleData.id ===
                            actionMeta.removedValue.output.id
                    );

                // if (currentMappingDetailIndex === -1) {
                //     currentMappingDetailIndex = value[0].mappingDetail.findIndex(
                //         (
                //             item: FunctionTypeDetailsWithMappings["inputs"][0]["mappingDetails"][0]
                //         ) =>
                //             item.output.circleData.id ===
                //             actionMeta.removedValue.output.id
                //     );
                // }
                console.log(
                    "currentMappingDetailIndex===",
                    currentMappingDetailIndex
                );
                if (currentMappingDetailIndex !== -1) {
                    props.addRemoveConnectionWithInitialValues("remove", {
                        inputAsset: props.inputAsset,
                        inputCircleData: props.inputCircleData,
                        outputCircleData:
                            currentMappingDetail.output.circleData,
                        outputAsset: currentMappingDetail.output.asset,
                        connectionindex: currentMappingDetailIndex,
                    });

                    props.removeConnectionConfigurationTool({
                        inputAsset: props.inputAsset,
                        inputCircleData: props.inputCircleData,
                        outputCircleData:
                            currentMappingDetail.output.circleData,
                        outputAsset: currentMappingDetail.output.asset,
                    });
                }
            } else if (actionMeta.action === "clear") {
                props.removeConnectionConfigurationTool({
                    inputAsset: props.inputAsset,
                    inputCircleData: props.inputCircleData,
                    removeAll: true,
                });
                props.addRemoveConnectionWithInitialValues("clear", {
                    //outputAsset: actionMeta.option.outputAsset,
                    //outputCircleData: actionMeta.option.output,
                    inputCircleData: props.inputCircleData,
                    inputAsset: props.inputAsset,
                });
            }
            if (actionMeta.action === "select-option") {
                props.addConnectionConfigurationTool({
                    outputAsset: actionMeta.option.outputAsset,
                    outputCircleData: actionMeta.option.output,
                    inputCircleData: props.inputCircleData,
                    inputAsset: props.inputAsset,
                });
                props.addRemoveConnectionWithInitialValues("add", {
                    outputAsset: actionMeta.option.outputAsset,
                    outputCircleData: actionMeta.option.output,
                    inputCircleData: props.inputCircleData,
                    inputAsset: props.inputAsset,
                });
            }
        },
        [props]
    );

    return (
        <div className="wrapper-react-select">
            <ReactSelect
                options={props.dropDownOptions}
                isMulti
                className="basic-multi-select"
                styles={customReactSelectStyles}
                value={selectedOption}
                getOptionLabel={(optionObj) => `${optionObj.output.name}`}
                getOptionValue={(optionObj) => {
                    return `${optionObj.output.name} ${optionObj.output.id}`;
                }}
                onChange={handleChange}
            />
        </div>
    );
};

export default ConnectionDropdown;
