import { LocalJson } from '../fromJson/types';
import FunctionTypeDetail from '../../AssetType/FunctionType';
import { INVALID_COMPUTE_MODEL_MESSAGES } from '../../../utils/constants/uiConstants';
import { defaultValueValidation } from './defaultValue';
import { Asset } from '../../../components/Fabric/types';
import { FunctionTypeDetailsWithMappings } from '../../../model/TableController/types';
import TableController from '../../../model/TableController';
import ObjectTypeDetail from '../../AssetType/ObjectType';

interface Validations {
    connect_all_inhibit?: boolean;
    connect_both_inhibit?: boolean;
    check_default_values?: boolean;
    check_no_assets?: boolean;
}

export function computeModelJsonValidation(options: {
    json: LocalJson;
    validations: Validations;
    overAllSelectedFunctionId?: string;
}) {
    let { json, validations, overAllSelectedFunctionId } = options;
    const tableData = TableController.fromJSON({
        canvasJson: json,
    });
    const tableJson = TableController.toJSON({ tableData: tableData });

    validations = {
        check_default_values: true,
        check_no_assets: true,
        ...validations,
    };
    let valid = true;
    let errorDetails: string[] = [];
    let functionsInAssetData = tableJson.assetData.filter(
        (item: Asset) => item instanceof FunctionTypeDetail
    ) as FunctionTypeDetailsWithMappings[];
    if (overAllSelectedFunctionId) {
        const overallSeverityFunction = functionsInAssetData.find(
            (item) => item.nodeId === overAllSelectedFunctionId
        );

        if (overallSeverityFunction) {
            functionsInAssetData = functionsInAssetData.filter(
                (item) => item.nodeId !== overAllSelectedFunctionId && item.conditions
            );

            const severityInput = 0;

            let functionMapped = false;

            let mappedToOverallSeverity: FunctionTypeDetailsWithMappings['inputs'][0]['mappingDetails'][0][] =
                [];
            overallSeverityFunction.inputs[severityInput].mappingDetails.forEach(
                (item: FunctionTypeDetailsWithMappings['inputs'][0]['mappingDetails'][0]) => {
                    if (item.output.circleData.name === 'severity') {
                        mappedToOverallSeverity.push(item);
                    }
                }
            );

            if (mappedToOverallSeverity.length >= 1) {
                functionMapped = true;
            }

            if (!functionMapped) {
                valid = false;
                errorDetails.push(
                    `The severity for atleast one function should be mapped to ${overallSeverityFunction.assetName}.`
                );
            }
        }
    }
    let uniqueObjectTypeDetails: { [key: string]: ObjectTypeDetail } = {};
    let matchedObjectNames: string[] = [];
    let hasMatchedObjects = false;
    if (tableJson.assetData.length === 0) {
        valid = false;
        errorDetails.push('Please add some object/function types');
    } else {
        let totalFunctionCount = 0;
        tableJson.assetData.forEach((asset) => {
            let totalInhibitsConnected = 0;
            if (asset instanceof FunctionTypeDetail) {
                totalFunctionCount++;
                if (asset.inhibits.length > 0) {
                    asset.inhibits.forEach((inhibit) => {
                        if (inhibit.connected || inhibit.defaultValue) {
                            totalInhibitsConnected++;
                        }
                    });

                    if (validations.connect_all_inhibit) {
                        if (totalInhibitsConnected !== asset.inhibits.length) {
                            valid = false;
                            errorDetails.push(
                                INVALID_COMPUTE_MODEL_MESSAGES.CONNECT_ALL_INHIBIT_PINS +
                                    ' on ' +
                                    asset.assetName
                            );
                        }
                    }

                    if (validations.connect_both_inhibit) {
                        if (
                            !(
                                totalInhibitsConnected === 0 ||
                                totalInhibitsConnected === asset.inhibits.length
                            )
                        ) {
                            valid = false;
                            errorDetails.push(
                                INVALID_COMPUTE_MODEL_MESSAGES.CONNECT_BOTH_OR_NONE_INHIBIT_PINS +
                                    ' on ' +
                                    asset.assetName
                            );
                        }
                    }
                }

                if (validations.check_default_values) {
                    asset.inputs.forEach((input) => {
                        if (input.defaultValue) {
                            const defaultValueValidationResult = defaultValueValidation({
                                dataType: input.dataType,
                                value: input.defaultValue,
                                inputDetails: input
                            });
                            if (defaultValueValidationResult.isInvalid) {
                                valid = false;
                                errorDetails.push(
                                    `Default value validation error on ${input.path}, Message: ${defaultValueValidationResult.errorMessage}`
                                );
                            }
                        }
                    });

                    asset.inhibits.forEach((inhibit) => {
                        if (inhibit.defaultValue) {
                            const defaultValueValidationResult = defaultValueValidation({
                                dataType: inhibit.dataType,
                                value: inhibit.defaultValue,
                                inputDetails: inhibit
                            });
                            if (defaultValueValidationResult.isInvalid) {
                                valid = false;
                                errorDetails.push(
                                    `Default value validation error on ${inhibit.path}, Message: ${defaultValueValidationResult.errorMessage}`
                                );
                            }
                        }
                    });
                }
            } else if (asset instanceof ObjectTypeDetail) {
                if (!asset.isReferencedType && !asset.objectInstance) {
                    const currentAssetRef = asset.assetRef.split('@')[0];
                    if (uniqueObjectTypeDetails[currentAssetRef]) {
                        matchedObjectNames.push(asset.assetName);
                        hasMatchedObjects = true;
                        valid = false;
                    } else {
                        uniqueObjectTypeDetails[currentAssetRef] = { ...asset };
                    }
                }
            } else {
                //TODO: handle the AlarmTypeDetail case.
            }
        });

        if (totalFunctionCount === 0) {
            valid = false;
            errorDetails.push('Atleast one function is required to create a model.');
        }
        if (hasMatchedObjects) {
            const errorMessage = `${matchedObjectNames.join(',')} is present more than once.`;
            errorDetails.push(errorMessage);
        }
    }

    return { isValid: valid, errorDetails };
}
