import { IODataTypes } from "../types";
import ConnectionController from "../ConnectionController";
import { Connection } from "../ConnectionController/types";
import TableController from "../../../model/TableController";
import { TableData } from "../../../model/TableController";

type Params = {
    inputData: Connection["data"]["input"];
    outputData: Connection["data"]["output"];
} & ({ ConnectionController: ConnectionController } | { tableData: TableData });

function connectionChecker(options: Params) {
    // if (options.inputData && options.inputData.circleData.defaultValue) {
    //     return false;
    // }

    // input can have only one connection, if not an array
    if (
        options.inputData &&
        options.outputData &&
        !options.inputData.circleData.dataType.includes("array")
    ) {
        if (
            options.inputData.circleData.dataType ===
            options.outputData.circleData.dataType
        ) {
            if ("ConnectionController" in options) {
                return !options.ConnectionController.getConnectionById({
                    nodeId: options.inputData.asset.nodeId,
                    ioId: options.inputData.circleData.id
                });
            } else {
                return !TableController.getConnectionById({
                    nodeId: options.inputData.asset.nodeId,
                    inputIoId: options.inputData.circleData.id,
                    tableData: options.tableData
                });
            }
        } else {
            return false;
        }
    } else {
        if (
            options.inputData &&
            options.outputData &&
            (options.inputData.circleData.dataType ===
                options.outputData.circleData.dataType ||
                options.inputData.circleData.dataType.split("_")[1] ===
                options.outputData.circleData.dataType)
        ) {
            if ("ConnectionController" in options) {
                return !options.ConnectionController.checkConnectionBetweenIO({
                    outputNodeId: options.outputData.asset.nodeId,
                    inputNodeId: options.inputData.asset.nodeId,
                    inputIoId: options.inputData.circleData.id,
                    outputIoId: options.outputData.circleData.id
                });
            } else {
                return !TableController.checkConnectionBetweenIO({
                    outputNodeId: options.outputData.asset.nodeId,
                    inputNodeId: options.inputData.asset.nodeId,
                    inputIoId: options.inputData.circleData.id,
                    outputIoId: options.outputData.circleData.id,
                    tableData: options.tableData
                });
            }
        }

        return false;
    }
}

export default connectionChecker;
