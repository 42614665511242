import React from 'react';
import ActionBar from './ActionBar';
import Flex from '../shared/Flex';
import { ABB_LOGO } from '../../utils/constants/assets';
import './styles.scss';
import { AbbBar } from '@abb/abb-common-ux-react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { CONFIGURATION_TOOL_MODE, ROUTE_PATHNAME } from '../../utils/constants/appConstants';
import AppSettings from '../../services/AppSettings';

interface AppMethods {
    showModal?(): void;
}

const logo = (): JSX.Element => {
    return <img src={ABB_LOGO} style={{ height: '15px' }} className="img-fluid" alt="logo" />;
};

export function TopNavigation(
    props: AppMethods & RouteComponentProps & ReturnType<typeof mapStateToProps>
) {
    const { pathname } = props.location;
    console.log('pathname :', pathname);
    return !(pathname === ROUTE_PATHNAME.HOME && props.editMode) ? (
        <div onClick={() => {
            const popupElement = document.getElementById('configurationPopup')
            if (popupElement && popupElement.style.visibility === 'visible') {
                popupElement.style.visibility = 'hidden';
            }
        }}>
            <Flex className="header-container" column>
            {/* add className="abb-bar" for vertical nav bar */}
            <AbbBar customLogo={logo()} productName={AppSettings.productName} />
            {pathname !== '/help/portal' && (
                <Flex>
                    <ActionBar />
                </Flex>
            )}
        </Flex>
        </div>
        
    ) : null;
}

const mapStateToProps = (state: StoreState) => {
    return {
        editMode:
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE ||
            state.configurationTool.mode === CONFIGURATION_TOOL_MODE.IMPORT,
    };
};
export default connect(mapStateToProps)(withRouter(TopNavigation));
