import React, { Fragment, useState, useCallback, useEffect } from 'react';
import TR from '../../../../../Table/TableRow';
import { IoTableTabsProps } from '../../../..';
import {
    EventTypeDetailsWithMappings,
    FunctionTypeDetailsWithMappings,
} from '../../../../../../model/TableController/types';
import Td from '../../../../../Table/TableData';
import _ from 'lodash';
import { deleteFunctionAlarmEventPinWarningMessage } from '../../../../../../utils/constants/appConstants';
import { Button } from '@abb/abb-common-ux-react';
import { connect } from 'react-redux';
import { showModal } from '../../../../../../store/modal/action';
import {
    deleteEventTriggerToFunction,
    markComputeModelDirty,
} from '../../../../../../store/configurationTool/actions';
import { Dispatch } from 'redux';
import { StoreState } from '../../../../../../store';
import AddEventForm from '../../../../../CreateEventForm';
import RemovableChip from '../../../../../RemovableChip';
import EventTypeDetail from '../../../../../../transformers/AssetType/EventType';
import FunctionTypeDetail from '../../../../../../transformers/AssetType/FunctionType';
import Condition from '../../../../../shared/Condition';
import { CONFIRMATION_BUTTON } from '../../../../../../utils/constants/uiConstants';
import { hideDialog, showDialog } from '../../../../../../store/dialog/action';
import MessageModal from '../../../../../MessageModal';
import { ALARM_TYPE, EVENT_TYPE } from '../../../../../Fabric/types';
import { removeEventTriggerOptions } from '../../../../../../store/configurationTool/types';
import ObjectTypeDetail from '../../../../../../transformers/AssetType/ObjectType';

interface ExpandTableRowProps {
    asset: FunctionTypeDetailsWithMappings;
    // functionType: FunctionTypeDetailsWithMappings;
    objectTypeList: ObjectTypeDetail[];
}

export const ExpandableEventRows = (
    props: ExpandTableRowProps &
        IoTableTabsProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const rows = [...props.asset.eventInputs];

    const rowCount = rows.length;

    return (
        <Fragment>
            {rows.map((input, index) => {
                return (
                    <EventTriggerRow
                        key={input.id}
                        {...props}
                        asset={props.asset}
                        eventAsset={
                            input.mappingDetails[0] &&
                            (input.mappingDetails[0].output.asset as EventTypeDetail)
                        }
                        index={index}
                        input={input}
                        rowCount={rowCount}
                    />
                );
            })}
            {rows.length === 0 && (
                <TR>
                    <Td className={'first-column'} tdBorderTop={'remove-inner-row-border-top'}>
                        {props.asset.nodeId}
                    </Td>
                    <Td>
                        <div className={'add-trigger'}>
                            {props.isEditMode && (
                                <Button
                                    type={'primary-blue'}
                                    sizeClass={'small'}
                                    text={'Add'}
                                    onClick={() => props.showCreateEventModal(props.asset)}
                                />
                            )}
                        </div>
                    </Td>
                    <Td></Td>
                </TR>
            )}
        </Fragment>
    );
};

const EventTriggerRow = (
    props: {
        index: number;
        rowCount: number;
        input: FunctionTypeDetailsWithMappings['inputs'][0];
        asset: FunctionTypeDetailsWithMappings;
        eventAsset: EventTypeDetail;
    } & ExpandTableRowProps &
        IoTableTabsProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const { index, rowCount, asset, eventAsset, input } = props;
    const [eventAssetWithMapping, setEventAssetWithMapping] = useState(
        {} as EventTypeDetailsWithMappings
    );

    useEffect(() => {
        if (eventAsset.type === EVENT_TYPE.SPECIFIC) {
            let eventAssetWithMappingDetails = props.tableData.eventTypeDetails.filter(
                (event) => event.nodeId === eventAsset.nodeId
            )[0];
            setEventAssetWithMapping(eventAssetWithMappingDetails);
        }
    }, props.tableData.eventTypeDetails);

    return (
        <>
            <TR id={`${asset.nodeId}`}>
                <Fragment>
                    <Td className={'first-column'}>{index === 0 ? asset.nodeId : ''}</Td>
                    <Td>
                        <RemovableChip
                            text={eventAsset.assetName}
                            isDeletable={props.isEditMode}
                            onDelete={() => {
                                props.deleteEventTriggerFromFunction({
                                    inputCircleData: input,
                                    inputAsset: asset,
                                    outputCircleData: eventAsset.outputs.filter((output) => {
                                        output.name === 'eventId';
                                    })[0],
                                    outputAsset: eventAsset,
                                });
                            }}
                        />
                    </Td>
                </Fragment>
                <Td>
                    {eventAsset.type === EVENT_TYPE.SPECIFIC
                        ? eventAssetWithMapping.inputs
                            ? eventAssetWithMapping.inputs &&
                              eventAssetWithMapping.inputs[0] &&
                              eventAssetWithMapping.inputs[0].mappingDetails &&
                              eventAssetWithMapping.inputs[0].mappingDetails[0] &&
                              eventAssetWithMapping.inputs[0].mappingDetails[0].output.asset
                                  .assetName
                            : ''
                        : ''}
                </Td>
            </TR>
            <Condition when={index === rowCount - 1}>
                <TR>
                    <Td className={'first-column'}></Td>
                    <Td>
                        <div className={'add-trigger'}>
                            {props.isEditMode && (
                                <Button
                                    type={'primary-blue'}
                                    sizeClass={'small'}
                                    text={'Add'}
                                    onClick={() => props.showCreateEventModal(asset)}
                                />
                            )}
                        </div>
                    </Td>
                    <Td></Td>
                </TR>
            </Condition>
        </>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        markComputeModelDirty: () => dispatch(markComputeModelDirty()),
        showCreateEventModal: (functionType: FunctionTypeDetail) => {
            dispatch(
                showModal({
                    component: AddEventForm,
                    modalTitle: 'ADD EVENT TRIGGER',
                    data: {
                        functionType,
                    },
                })
            );
        },
        deleteEventTriggerFromFunction: (payload: removeEventTriggerOptions) => {
            dispatch(
                showDialog({
                    component: MessageModal,
                    modalTitle: 'Delete',
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: deleteFunctionAlarmEventPinWarningMessage({
                            pinName: payload.outputAsset.assetName,
                            type: 'Event',
                        }),
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: () => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.CONFIRM,
                                type: 'primary-blue',
                                handler: () => {
                                    dispatch(deleteEventTriggerToFunction(payload));
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableEventRows);
