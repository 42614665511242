import React, { ReactNode } from 'react';
import './style.scss';
interface AppProps {
    children: ReactNode;
    bgColor?: string;
    id?: string;
}

function Tr(props: AppProps) {
    return (
        <tr
            className={`table-row ${
                props.bgColor ? 'table-row--' + props.bgColor : ''
            }`}
            id={props.id}
        >
            {props.children}
        </tr>
    );
}

export default Tr;
