import { IODataTypes, Asset } from '../../../components/Fabric/types';

import { Connection } from '../../../components/Fabric/ConnectionController/types';
import { AlarmMapping } from '../../../store/function/types';
import { SamplingAggregateValues } from '../../../utils/constants/appConstants';

// reverify - object types wont have any connection details.
export interface ObjectTypes {
    value?: any;
    [key: string]: {
        objectTypeModel: {
            value: string;
            dataType: string;
            isMandatory: boolean;
        };
        objectType: {
            value: string;
            dataType: string;
            isMandatory: boolean;
        };
        name: {
            value: string;
            dataType: string;
        };
        version: {
            value: string;
            dataType: string;
        };
        xcoordinate: {
            dataType: string;
            value: number;
        };
        ycoordinate: {
            dataType: string;
            value: number;
        };
        scale: {
            dataType: string;
            value: number;
        };
        variables: {
            // output-name
            [key: string]: {
                dataType: string;
                items?: string;
                path: string;
            };
        };
        objectId?: { value: string };
        referencedTypes?: {
            dataType: string;
            items?: string;
            value: ReferenceTypeValueItem[];
            isMandatory: boolean;
        };
    };
}

export interface ReferenceTypeValueItem {
    modelId: string;
    typeId: string;
    version: string;
    objectId: string;
}

// things to be added ---
// id's for each input and output
// output will have ref to connected ?
export interface FunctionTypes {
    [key: string]: {
        functionType: {
            dataType: string;
            value: string;
        };
        name: {
            value: string;
            dataType: string;
        };
        version: {
            value: string;
            dataType: string;
        };
        xcoordinate: {
            dataType: string;
            value: number;
        };
        ycoordinate: {
            dataType: string;
            value: number;
        };
        scale: {
            dataType: string;
            value: number;
        };
        endpoint: {
            dataType: string;
            value: string;
            description: string;
            isDirty?: boolean;
        };
        alarmInputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link: string;
            };
        };
        eventInputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link: string;
            };
        };
        inputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link: string;
                timeWindow?: string;
                samplingWindow?: string;
                samplingAggregate?: SamplingAggregateValues;
                limit?: string;
            };
            conditions?: any;
            alarmMapping?: any;
        };
        alias: {
            [key: string]: {
                dataType: string,
                value: string
            }
        }
        outputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link?: string;
            };
        };
        inhibit?: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link: string;
                value?: boolean;
            };
        };
        triggers?: {
            variableChange?: {
                inputs: {
                    dataType: IODataTypes;
                    items: string;
                    link: string;
                };
            };
            time?: {
                dataType: string;
                value: string;
            };
            alarm?: {
                [key: string]: {
                    dataType: IODataTypes;
                    items: string;
                    link: string;
                };
            };
            event?: {
                [key: string]: {
                    dataType: IODataTypes;
                    items: string;
                    link: string;
                };
            };
        };
    };
}

export interface AlarmTypes {
    [key: string]: {
        alarmType: {
            dataType: string;
            value: string;
        };
        alarmTypeModel: {
            dataType: string;
            value: string;
        };
        name: {
            value: string;
            dataType: string;
        };
        version: {
            value: string;
            dataType: string;
        };
        xcoordinate: {
            dataType: string;
            value: number;
        };
        ycoordinate: {
            dataType: string;
            value: number;
        };
        scale: {
            dataType: string;
            value: number;
        };
        inputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link: string;
            };
        };
        outputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link?: string;
            };
        };

        triggers?: {
            variableChange?: {
                inputs: {
                    dataType: IODataTypes;
                    items: string;
                    link: string;
                };
            };
        };
    };
}

export interface EventTypes {
    [key: string]: {
        eventType: {
            dataType: string;
            value: string;
        };
        eventTypeModel: {
            dataType: string;
            value: string;
        };
        name: {
            value: string;
            dataType: string;
        };
        version: {
            value: string;
            dataType: string;
        };
        xcoordinate: {
            dataType: string;
            value: number;
        };
        ycoordinate: {
            dataType: string;
            value: number;
        };
        scale: {
            dataType: string;
            value: number;
        };
        inputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link: string;
            };
        };
        outputs: {
            [key: string]: {
                dataType: string;
                items?: string;
                description: string;
                link?: string;
            };
        };

        triggers?: {
            variableChange?: {
                inputs: {
                    dataType: IODataTypes;
                    items: string;
                    link: string;
                };
            };
        };
    };
}

export interface LocalJson {
    assetData: Asset[];
    connectionData: Connection['data'][];
}

export type PinsType = 'InputInhibit' | 'Output';
export const PINS_TYPE = <const>{
    INPUT_INHIBIT: 'InputInhibit',
    OUTPUT: 'Output',
};
