import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Icon, Checkbox } from '@abb/abb-common-ux-react';

import './style.scss';
import { StoreState } from '../../../../../store';
import Condition from '../../../../../components/shared/Condition';
import { SupportedTypes, SelectedTypes } from '../../../../../store/instanceConfig/types';
import {
    changeActiveModelInstanceConfig,
    updateSelectedSupportedType,
    updateSelectedCriticalityModel,
} from '../../../../../store/instanceConfig/actions';
import { getMaskedName } from '../../../../../utils/helpers';
import { ComputeModelToJson } from '../../../../../transformers/ComputeModel/toJson/ComputeModelToJson';

interface AssetConfigModalItemProps {
    supportedType: SupportedTypes;
}

const AssetConfigModalItem = (
    props: AssetConfigModalItemProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const { supportedType } = props;

    const [isCollapsed, setIsCollapsed] = useState(true);
    // const [selectedTypeName, updateSelectedTypeName] = useState(undefined as any);
    const [selectedTypeId, updateSelectedTypeId] = useState(undefined as any);
    const [supportedTypeList, updateSupportedTypeList] = useState(supportedType.value.types);

    const handleSelectedOption = (id: string, version: string, objectId: string, name: string) => {
        let selectedSupportedType: SelectedTypes = {};
        if (props.selectedSupportedType) {
            selectedSupportedType = {
                ...props.selectedSupportedType,
            };
            if (selectedTypeId === id + version) {
                updateSelectedTypeId(undefined);
                delete selectedSupportedType[supportedType.key];
            } else {
                updateSelectedTypeId(id + version);

                // if (selectedTypeName === name + version) {
                //     updateSelectedTypeName(undefined);
                //     delete selectedSupportedType[supportedType.key];
                // } else {
                //     updateSelectedTypeName(name + version);
                //     console.log(id);

                selectedSupportedType[supportedType.key] = {
                    selectedType: id,
                    version: version,
                    objectId: objectId,
                };
            }
            props.updateSelectedSupportedType(selectedSupportedType);
        }
    };

    useEffect(() => {
        if (props.isInstanceConfigured) {
            const assetInstance = props.instanceTableDataById[props.selectedInstances[0]];
            const assetInstanceAmInfo = assetInstance.amInfo;
            const matchedModelType = assetInstanceAmInfo.find(
                (item: any) => item.assetMonitorModel === supportedType.key
            );
            if (matchedModelType) {
                if (matchedModelType.assetMonitorType) {
                    let newSelectedTypeId = matchedModelType.assetMonitorType.split('@')[0];
                    const SupportedTypesListData = supportedType.value.types.filter(
                        (item) => item.typeId === newSelectedTypeId
                    );

                    updateSupportedTypeList(SupportedTypesListData);
                }
            } else {
                updateSupportedTypeList([...supportedType.value.types]);
            }
        } else {
            updateSupportedTypeList([...supportedType.value.types]);
        }
    }, [props.supportedType]);

    return (
        <div className="asset-config-model-item">
            <div className="collapse-item-header">
                <Icon
                    name={isCollapsed ? 'abb/plus' : 'abb/minus'}
                    sizeClass="small"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                />
                <div className="collapse-item-title">{supportedType.value.modelTypeLabel}</div>
            </div>
            {!isCollapsed && (
                <div className="collapse-item-list">
                    <Condition when={true}>
                        {supportedType.value.types.length === 0 ? (
                            <div>No types available !</div>
                        ) : (
                            supportedTypeList.map((item) => {
                                const { maskedTypeId } = getMaskedName(item);
                                const Name = item.typeName;

                                return (
                                    <Checkbox
                                        key={item.typeId + item.version}
                                        value={item.typeId + item.version === selectedTypeId}
                                        label={`${Name} (${maskedTypeId})`}
                                        sizeClass="small"
                                        onChange={() =>
                                            handleSelectedOption(
                                                item.typeId,
                                                item.version,
                                                item.objectId,
                                                item.typeName
                                            )
                                        }
                                    />
                                );
                            })
                        )}
                    </Condition>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        selectedOption: state.instanceConfig.activeModel.id,
        selectedSupportedType: state.instanceConfig.selectedTypes,
        instanceTableDataById: state.instanceConfig.instancesTable.tableData.byId,
        selectedInstances: state.instanceConfig.instancesTable.tableData.selectedInstances,
        isInstanceConfigured: state.instanceConfig.isInstanceConfigured,
        selectedTypes: state.instanceConfig.selectedTypes,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateSelectedOption: (payload: { modelId: string }) => {
            dispatch(changeActiveModelInstanceConfig(payload));
        },
        updateSelectedSupportedType: (payload: SelectedTypes) => {
            dispatch(updateSelectedSupportedType(payload));
        },
        handleSelectedCriticalityModel: (payload: ComputeModelToJson) => {
            dispatch(updateSelectedCriticalityModel(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetConfigModalItem);
