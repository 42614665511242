import React, { useCallback, Fragment, useEffect, useState } from "react";
import "./style.scss";
import Checkbox from "../../../../../components/CustomCheckbox";
import Menu from "../../../../../components/CustomMenu";
import { StoreState } from "../../../../../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    addToSelectedInstance,
    removeFromSelectedInstance,
    getAssetTableDataApi,
    changeActivePageInstanceConfig,
    handleFilterAssetTableData,
    handleSortAssetTableData,
    getLoadedInstace,
    updateActiveTabInstanceConfig,
} from "../../../../../store/instanceConfig/actions";
import Pagination from "../../../../../components/Pagination";
import Flex from "../../../../../components/shared/Flex";
import Condition from "../../../../../components/shared/Condition";
import Loader from "../../../../../components/Loader";
import {
    LOADER_SIZE,
    LOADER_TYPE,
} from "../../../../../utils/constants/appConstants";
import { handleAssetInstanceActionMenuClick } from "../../../../../store/assets/actions";

import {
    ACTION_MENU_LIST,
    AssetTableDataJson,
} from "../../../../../store/instanceConfig/types";
import {
    HeaderGroup,
    Row,
    useBlockLayout,
    useFilters,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useSortBy,
    useTable,
    useFlexLayout,
} from "react-table";
import _ from "lodash";
import { filterSegregatedModelByVersion } from "../../../../../utils/helpers";
import { Icon, Input, Button } from "@abb/abb-common-ux-react";
import CustomTooltip from "../../../../../components/CustomTooltip";
import AssetInstanceHeader from "../AssetInstanceHeader";
import {
    getAllComputeModelsApi,
    changeActiveModelInstanceConfig,
} from "../../../../../store/instanceConfig/actions";
import { TOOLTIP_MESSAGES } from "../../../../../utils/constants/uiConstants";
import { StorageItems } from "@iaedge/auth";
import { List } from "react-virtualized";
import CustomABBInput from "../../../../../components/CustomABBInput";

interface AssetTableProps {}

interface TableRowdata {
    name: string;
    type: string;
    description: string;
    status: string;
    configurationStatus: string;
    lastUpdatedTimestamp: string;
    assetMonitorType: string;
    subRows?: TableRowdata[];
    objectId: string;
}
let amInfoData: any[] = [];
const getSubRows = (tableRowData: AssetTableDataJson) => {
    amInfoData = tableRowData.amInfo;
    //console.log("amInfoData", amInfoData)
    let subRows: any[] = [];
    if (amInfoData.length > 1) {
        subRows = amInfoData.slice(1, amInfoData.length).map((item, index) => {
            // console.log("assetMonitorType", item.assetMonitorType)
            return {
                name: "",
                type: "",
                description: "",
                status: item.status,
                configurationStatus: item.configurationStatus,
                lastUpdatedTimestamp: item.lastUpdatedTimestamp || "",
                assetMonitorType: item.assetMonitorType,
            };
        });
    }
    return subRows;
};

export const getMonitorType = (assetType: string) => {
    let value = "";
    if (assetType && assetType.toLowerCase().includes(".tenantid.")) {
        value = assetType.split(".").reverse().splice(2).reverse().join(".");
        const version = assetType
            .split(".")
            [assetType.split(".").length - 1].split("@");
        if (version.length > 1) {
            value = `${value}@${version[version.length - 1]}`;
        }
    } else {
        value = assetType;
    }
    const maskedTypeId =
        value && value.toLowerCase().includes("abb.local.")
            ? value.split(".").splice(2).join(".")
            : value;
    console.log("%c FILTER", "background-color:red", maskedTypeId);
    return maskedTypeId;
};

export const AssetTableView = (
    props: AssetTableProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const { filterData } = props;
    const [update, setUpdate] = useState(false);
    // const { supportedType } = props;
    // const [supportedTypeList, updateSupportedTypeList] = useState(props.supportedType.value.types);
    const columns = React.useMemo(
        () => [
            {
                Header: "NAME",
                accessor: "name",
                width: 200,
                minWidth: 200,
                path: "name",
            },
            {
                Header: "INSTANCE TYPE",
                accessor: "type",
                minWidth: 200,
                path: "type",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
                minWidth: 200,
                path: "description",
            },
            {
                Header: "STATUS",
                accessor: "status",
                minWidth: 150,
                path: "amInfo.status",
            },
            {
                Header: "LAST UPDATED",
                accessor: "lastUpdatedTimestamp",
                minWidth: 150,
                path: "amInfo.lastUpdatedTimestamp",
            },
            {
                Header: "CONFIGURATION STATUS",
                accessor: "configurationStatus",
                minWidth: 225,
                path: "amInfo.configurationStatus",
            },
            {
                Header: "MODEL TYPE",
                accessor: "assetMonitorType",
                minWidth: 200,
                path: "amInfo.assetMonitorType",
            },
        ],
        [props.instancesTable]
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 100,
            width: 100,
            maxWidth: 400,
            Filter: ({
                column: {
                    filterValue,
                    preFilteredRows,
                    setFilter,
                    id,
                    path,
                    filters,
                },
                handleFilter,
            }: {
                column: {
                    filterValue: any;
                    preFilteredRows: any;
                    setFilter: any;
                    id: any;
                    path: any;
                    filters: any;
                };
                handleFilter: (payload: {
                    field: string;
                    value: string;
                }) => void;
            }) => {
                const count = preFilteredRows.length;
                return (
                    <CustomABBInput
                        dataType={"text"}
                        value={
                            props.instancesTable.filters[path]
                                ? props.instancesTable.filters[path].value
                                : ""
                        }
                        onValueChange={(value) => {
                            setFilter(value || undefined);
                            handleFilter({ field: path, value: value || "" }); // Set undefined to remove the filter entirely
                        }}
                        placeholder={`Search in ${count} entries...`}
                    />
                );
            },
            filters: { ...props.instancesTable.filters },
        }),
        [props.instancesTable.filterData]
    );

    useEffect(() => {
        props.getAllComputeModelsApi();
        getLoadedInstace();
    }, []);
    const tableRowData = React.useMemo(() => {
        if (update) {
            let data: any = [];

            props.instancesTable.filterData.entities.forEach((id) => {
                props.instancesTable.tableData.byId[id].amInfo.forEach(
                    (model) => {
                        if (model.isUpdateAvailable) {
                            data.push(props.instancesTable.tableData.byId[id]);
                        }
                    }
                );
            });

            return data;
        } else {
            return [
                ...props.instancesTable.filterData.entities.map((id) => {
                    return props.instancesTable.tableData.byId[id];
                }),
            ];
        }
    }, [
        props.instancesTable.filterData,
        props.instancesTable.tableData,
        props.filters,
        update,
    ]);

    const data = React.useMemo(() => {
        let tableData: TableRowdata[] = [];
        console.log(tableRowData, "tableRowData");
        tableRowData.forEach((data: any) => {
            const rowItem: TableRowdata = {
                name: data.name,
                type: data.type,
                description: data.description || "",
                status: data.amInfo[0].status,
                configurationStatus: data.amInfo[0].configurationStatus,
                lastUpdatedTimestamp: data.amInfo[0].lastUpdatedTimestamp || "",
                assetMonitorType: data.amInfo[0].assetMonitorType,
                subRows: getSubRows(data),
                objectId: data.objectId,
            };
            tableData.push(rowItem);
        });
        console.log(tableData, "tableData");
        return tableData;
    }, [tableRowData]);

    const handleActivePageChange = useCallback(
        (pageNumber: number) => {
            props.changeActivePage({ pageNumber });
        },
        [props.totalPage]
    );

    const getMonitorTypeUpdate = (e: any) => {
        let value = false;
        // console.log(amInfoData, 'amInfoData')
        tableRowData.map((ele: any) => {
            // console.log(ele.amInfo[0].isUpdateAvailable, ele.amInfo[0].assetMonitorType, "isUpdateAvailable")
            ele.amInfo.map((items: any) => {
                if (e === items.assetMonitorType && items.isUpdateAvailable) {
                    value = true;
                }
            });
        });
        return value;
    };

    const handleRowCheckChange = useCallback((e) => {
        debugger;
        //console.log("aminfo1", props.aminfo)
        const target = e.target;
        if (target && target.id) {
            if (target.checked) {
                props.addToSelectedInstance({ objectIds: [e.target.id] });
            } else if (!target.checked) {
                props.removeFromSelectedInstance({ objectIds: [e.target.id] });
            }
        }
    }, []);

    const handleAllInstanceCheckboxClick = useCallback(
        (e) => {
            if (e.target) {
                if (e.target.checked) {
                    props.addToSelectedInstance({
                        objectIds: [...filterData],
                    });
                } else {
                    props.removeFromSelectedInstance({
                        objectIds: [...filterData],
                    });
                }
            }
        },
        [props.instancesTable, props.filterData]
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
    } = useTable(
        //@ts-ignore
        { columns, data: data, defaultColumn },
        useFilters,
        useSortBy,
        usePagination,
        useResizeColumns,
        useFlexLayout,
        useRowSelect
    );

    return (
        <Fragment>
            <Flex column className="wrapper-asset-table">
                <Condition when={props.isLoading}>
                    <Flex fill center>
                        <Loader
                            type={LOADER_TYPE.RADIAL}
                            sizeClass={LOADER_SIZE.LARGE}
                        />
                    </Flex>
                </Condition>
                <Condition when={!props.isLoading}>
                    <div {...getTableProps()} className="table">
                        <div
                            style={{
                                display: "inline-table",
                                position: "sticky",
                                backgroundColor: "#fff",
                                zIndex: 1,
                                top: 0,
                                width: "100%",
                            }}
                        >
                            {headerGroups.map(
                                (headerGroup: HeaderGroup<TableRowdata>) => (
                                    <>
                                        <div
                                            {...headerGroup.getHeaderGroupProps(
                                                {}
                                            )}
                                            className="tr"
                                        >
                                            <div
                                                {...headerGroup.headers[0].getHeaderProps(
                                                    {
                                                        style: {
                                                            width: "100px",
                                                            maxWidth: "100px",
                                                            minWidth: "100px",
                                                            borderBottom:
                                                                "solid 1px #bababa",

                                                            borderTop:
                                                                "1px solid #bababa",

                                                            position:
                                                                "relative",
                                                        },
                                                    }
                                                )}
                                                className="th"
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        width: "2px",
                                                        height: "100%",
                                                        backgroundColor:
                                                            "#bababa",
                                                        left: "0px",
                                                        top: "0px",
                                                    }}
                                                ></div>
                                                <Checkbox
                                                    onClick={(e) =>
                                                        filterData.length > 0 &&
                                                        handleAllInstanceCheckboxClick(
                                                            e
                                                        )
                                                    }
                                                    isDisable={
                                                        filterData.length === 0
                                                    }
                                                    checked={
                                                        filterData.length ===
                                                            props
                                                                .selectedInstances
                                                                .length &&
                                                        filterData.length > 0
                                                    }
                                                />
                                                <div className={`resizer`} />
                                            </div>
                                            {headerGroup.headers.map(
                                                (column: any) => {
                                                    return (
                                                        <div
                                                            className={`th  ${
                                                                column.Header ===
                                                                "MODEL TYPE"
                                                                    ? "model-type"
                                                                    : ""
                                                            }`}
                                                            {...column.getHeaderProps(
                                                                column.Header !==
                                                                    "MODEL TYPE" &&
                                                                    column.getSortByToggleProps(
                                                                        {
                                                                            style: {
                                                                                borderBottom: `solid ${
                                                                                    column.isSorted
                                                                                        ? "1px"
                                                                                        : "1px"
                                                                                } ${
                                                                                    column.isSorted
                                                                                        ? "#3366ff"
                                                                                        : "#bababa"
                                                                                }`,
                                                                            },
                                                                        }
                                                                    )
                                                            )}
                                                            onClick={() => {
                                                                column.toggleSortBy();
                                                                props.handleSortAssetTableData(
                                                                    {
                                                                        sortBy: column.path,
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                {column.Header ===
                                                                "MODEL TYPE" ? (
                                                                    <>
                                                                        <div>
                                                                            MODEL
                                                                            TYPE
                                                                        </div>
                                                                        <div className="model-type-tooltip">
                                                                            <CustomTooltip
                                                                                position="top"
                                                                                text={
                                                                                    TOOLTIP_MESSAGES.UPDATE_AVAILABLE
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    icon="abb/synchronize"
                                                                                    style={{
                                                                                        marginLeft: `70px`,
                                                                                    }}
                                                                                    sizeClass="small"
                                                                                    type={
                                                                                        update
                                                                                            ? "primary-blue"
                                                                                            : "normal"
                                                                                    }
                                                                                    onClick={() =>
                                                                                        setUpdate(
                                                                                            !update
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </CustomTooltip>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {column.render(
                                                                            "Header"
                                                                        )}
                                                                        <div
                                                                            className={`${
                                                                                column.isSorted
                                                                                    ? column.isSortedDesc
                                                                                        ? "descending-symbol"
                                                                                        : "ascending-symbol"
                                                                                    : ""
                                                                            }`}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div
                                                                //@ts-ignore
                                                                {...column.getResizerProps()}
                                                                className={`resizer ${
                                                                    //@ts-ignore
                                                                    column.isResizing
                                                                        ? "isResizing"
                                                                        : ""
                                                                }`}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <div
                                            className="tr"
                                            {...headerGroup.getHeaderGroupProps(
                                                {}
                                            )}
                                        >
                                            <div
                                                {...headerGroup.headers[0].getHeaderProps(
                                                    {
                                                        style: {
                                                            width: "100px",
                                                            minWidth: "100px",
                                                            maxWidth: "100px",
                                                        },
                                                    }
                                                )}
                                            ></div>
                                            {headerGroup.headers.map(
                                                (column: any) => {
                                                    return (
                                                        <div
                                                            {...column.getHeaderProps(
                                                                {
                                                                    style: {
                                                                        padding:
                                                                            "10px",
                                                                    },
                                                                }
                                                            )}
                                                        >
                                                            <div>
                                                                {column.canFilter
                                                                    ? column.render(
                                                                          "Filter",
                                                                          {
                                                                              handleFilter:
                                                                                  props.handleFilterAssetTableData,
                                                                          }
                                                                      )
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        {tableRowData.length > 0 ? (
                                            <>
                                                {props.filterData.length ===
                                                    0 && (
                                                    <div className="capitaliseText">
                                                        No instances Available
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="capitaliseText">
                                                No instances Available
                                            </div>
                                        )}
                                    </>
                                )
                            )}
                        </div>

                        <div {...getTableBodyProps()} className="tbody">
                            {rows.map((row: Row<TableRowdata>, i: number) => {
                                prepareRow(row);
                                //@ts-ignore;
                                const selectedObjectId = row.original.objectId;
                                //console.log("selectedObjectId", selectedObjectId, props.selectedInstances)
                                const isChecked =
                                    !!props.selectedInstances.includes(
                                        selectedObjectId
                                    );

                                return (
                                    <>
                                        <div
                                            {...row.getRowProps({
                                                style: {
                                                    backgroundColor: isChecked
                                                        ? "#dbdbdb"
                                                        : "",
                                                },
                                            })}
                                            className="tr"
                                        >
                                            <div
                                                className="td"
                                                {...row.cells[0].getCellProps({
                                                    style: {
                                                        width: "100px",
                                                        minWidth: "100px",
                                                        maxWidth: "100px",
                                                    },
                                                })}
                                            >
                                                <div className="asset-menu-tabledata tr">
                                                    <Checkbox
                                                        checked={isChecked}
                                                        onChange={
                                                            handleRowCheckChange
                                                        }
                                                        id={selectedObjectId}
                                                    />
                                                    <Menu
                                                        assetPermissions={
                                                            props.assetPermissions
                                                        }
                                                        actionMenuList={
                                                            props.actionMenuList
                                                        }
                                                        handleActionMenuClick={(
                                                            selectedMenuActionObj
                                                        ) =>
                                                            props.handleSelectedActionMenu(
                                                                selectedMenuActionObj
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {row.cells.map((cell) => {
                                                // console.log(cell, "row.cells")
                                                // console.log(tableRowData, 'amInfoData')

                                                return (
                                                    <div
                                                        {...cell.getCellProps()}
                                                        className="td"
                                                    >
                                                        {cell.column.Header ===
                                                        "MODEL TYPE"
                                                            ? getMonitorType(
                                                                  cell.row
                                                                      .original
                                                                      .assetMonitorType
                                                              )
                                                            : cell.render(
                                                                  "Cell"
                                                              )}

                                                        <div>
                                                            <div>
                                                                {cell.column
                                                                    .Header ===
                                                                    "MODEL TYPE" &&
                                                                getMonitorTypeUpdate(
                                                                    cell.row
                                                                        .original
                                                                        .assetMonitorType
                                                                ) ? (
                                                                    // props.configureUpdateStatus ?
                                                                    <div
                                                                        style={{
                                                                            paddingTop:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name="abb/synchronize"
                                                                            color={
                                                                                "#0000ff"
                                                                            }
                                                                            sizeClass="small"
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                color: "#0000ff",
                                                                            }}
                                                                        >
                                                                            {
                                                                                " update Available"
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {row.subRows.length > 0 &&
                                            row.subRows.map((item, index) => {
                                                prepareRow(item);
                                                return (
                                                    <div
                                                        className="tr"
                                                        {...item.getRowProps({
                                                            style: {
                                                                backgroundColor:
                                                                    isChecked
                                                                        ? "#dbdbdb"
                                                                        : "",
                                                            },
                                                        })}
                                                    >
                                                        <div
                                                            className="td"
                                                            {...row.cells[0].getCellProps(
                                                                {
                                                                    style: {
                                                                        width: "100px",
                                                                        minWidth:
                                                                            "100px",
                                                                        maxWidth:
                                                                            "100px",
                                                                    },
                                                                }
                                                            )}
                                                        />
                                                        {item.cells.map(
                                                            (cell) => {
                                                                //console.log("item.cells", item.cells)
                                                                return (
                                                                    <div
                                                                        className="td"
                                                                        {...cell.getCellProps()}
                                                                    >
                                                                        {cell
                                                                            .column
                                                                            .Header ===
                                                                        "MODEL TYPE"
                                                                            ? getMonitorType(
                                                                                  cell
                                                                                      .row
                                                                                      .original
                                                                                      .assetMonitorType
                                                                              )
                                                                            : cell.render(
                                                                                  "Cell"
                                                                              )}

                                                                        <div>
                                                                            <div>
                                                                                {cell
                                                                                    .column
                                                                                    .Header ===
                                                                                    "MODEL TYPE" &&
                                                                                getMonitorTypeUpdate(
                                                                                    cell
                                                                                        .row
                                                                                        .original
                                                                                        .assetMonitorType
                                                                                ) ? (
                                                                                    // props.configureUpdateStatus ?
                                                                                    <div
                                                                                        style={{
                                                                                            paddingTop:
                                                                                                "15px",
                                                                                        }}
                                                                                    >
                                                                                        <Icon
                                                                                            name="abb/synchronize"
                                                                                            color={
                                                                                                "#0000ff"
                                                                                            }
                                                                                            sizeClass="small"
                                                                                        />
                                                                                        <span
                                                                                            style={{
                                                                                                color: "#0000ff",
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                " update Available"
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    {/* </div> */}
                </Condition>
            </Flex>
            <Condition when={props.totalPage >= 1}>
                <Pagination
                    className="asset-table-pagination"
                    activePage={props.activePage}
                    totalPages={props.totalPage || 1}
                    onChange={handleActivePageChange}
                />
            </Condition>
        </Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    const activePage =
        state.instanceConfig.instancesTable.pagination.activePage;

    const totalPage =
        state.instanceConfig.instancesTable.pagination.totalRecords;
    const enteriesPerPage =
        state.instanceConfig.instancesTable.pagination.enteriesPerPage;

    const startFrom = (activePage - 1) * enteriesPerPage;

    return {
        selectedInstances:
            state.instanceConfig.instancesTable.tableData.selectedInstances,

        // tableRowData,
        instancesTable: state.instanceConfig.instancesTable,
        activePage,
        totalPage,
        isLoading: state.assets.isLoading,
        filters: state.instanceConfig.instancesTable.filters,
        filterData: state.instanceConfig.instancesTable.filterData.entities,
        sorting: state.instanceConfig.instancesTable.sorting,
        isInstanceConfigured: state.instanceConfig.isInstanceConfigured,
        dataAvailable:
            state.instanceConfig.instancesTable.tableData.entities.length > 0,
        actionMenuList: state.instanceConfig.instancesTable.actionMenuList,
        assetPermissions: state.assets.assetPermission,
        aminfo: state.instanceConfig.instancesTable.tableData.byId,
        supportedTypes: state.instanceConfig.supportedTypes,
        instanceTableDataById:
            state.instanceConfig.instancesTable.tableData.byId,
        // newModelSaveAvailable: state.instanceConfig.newModelSaveAvailable,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addToSelectedInstance: (
            payload: ReturnType<typeof addToSelectedInstance>["payload"]
        ) => {
            dispatch(addToSelectedInstance(payload));
        },
        removeFromSelectedInstance: (
            payload: ReturnType<typeof removeFromSelectedInstance>["payload"]
        ) => {
            dispatch(removeFromSelectedInstance(payload));
        },
        getAssetTableDataApi: () => {
            dispatch(getAssetTableDataApi());
        },
        changeActivePage: (payload: { pageNumber: number }) => {
            dispatch(changeActivePageInstanceConfig(payload));
        },
        handleFilterAssetTableData: (payload: {
            field: string;
            value: string;
        }) => {
            dispatch(handleFilterAssetTableData(payload));
        },
        handleSortAssetTableData: (payload: { sortBy: string }) => {
            dispatch(handleSortAssetTableData(payload));
        },
        handleSelectedActionMenu: (
            selectedActionMenu: typeof ACTION_MENU_LIST[0]
        ) => {
            dispatch(handleAssetInstanceActionMenuClick(selectedActionMenu));
        },
        getAllComputeModelsApi: () => {
            dispatch(getAllComputeModelsApi());
        },
        getLoadedInstace: () => {
            dispatch(getLoadedInstace());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetTableView);
