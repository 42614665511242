import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Input, Button } from "@abb/abb-common-ux-react";
import { connect } from "react-redux";
import { StoreState } from "../../store";
import { ModalFooter } from "reactstrap";
import "./style.scss";
import { changeZipFileNameForExport } from "../../store/exportAsset/actions";
import { Dispatch } from "redux";
import { hideModal } from "../../store/modal/action";
import { validateForSpecialCharacters_Including_HyphenAndUnderscore, validateForSwedishAndFinishCharacters } from "../../routes/Functions/helper";
import FunctionTypeDetail from "../../transformers/AssetType/FunctionType";
import { editAliasNameInModels } from "../../store/configurationTool/actions";
import { SEVERITY_PIN } from "../../routes/Functions/constants";
import { checkForExistingAliasName } from "../../utils/helpers";

interface GetAliasNAmeForModelsProps {
    functionType: FunctionTypeDetail;
    ioName: string;
    aliasName: string;
    exportMethod: () => any;
}
export const GetAliasNAmeForModelsModal = (
    props: GetAliasNAmeForModelsProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) => {
    const [aliasName, setAliasName] = useState(props.aliasName);
    const [aliasNameError, setAliasNameError] = useState("");

    const isSubmitBtnDisabled = useMemo(() => {
        return aliasNameError.length > 0;
    }, [aliasName, aliasNameError]);

    useEffect(() => {
        setAliasNameError("");
    }, []);

    const handleAliasNameChange = useCallback(
        (value: string) => {
                setAliasName(value.trim());
            // if (
            //     value.length > 0 &&
            //     !validateForSpecialCharacters_Including_HyphenAndUnderscore(value)
            // ) {
            //     setAliasNameError(`Special Characters are not allowed`);
            // } else 
            if (checkForExistingAliasName(value, props.functionType) && value !== props.ioName && value !== props.aliasName) {
                setAliasNameError(
                    `${value} is already used in ${props.functionType.nodeId}`
                );
            } else if (value === SEVERITY_PIN) {
                setAliasNameError(`${value} cannot be used as alias name`);
            } else {
                setAliasNameError("");
            }
        },
        [aliasName]
    );

    const handleSubmit = (
        event:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<
                  HTMLButtonElement | HTMLAnchorElement,
                  MouseEvent
              >
    ) => {
        event.preventDefault();
        props.changeAliasNameForModels({
            functionType: props.functionType,
            ioName: props.ioName,
            aliasName,
        });
        props.closeModalChangeName();
    };

    const handleClose = (
        event:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<
                  HTMLButtonElement | HTMLAnchorElement,
                  MouseEvent
              >
    ) => {
        event.preventDefault();
        props.closeModalChangeName();
    };

    const handleLostFocus = useCallback(
        (e : any) => {
            e.preventDefault();
            if(e.target.value.trim() === '') {
                setAliasName(props.ioName)
            }
        },[aliasName])

    return (
        <form className="wrapper-export-zip-modal" onSubmit={handleSubmit}>
            <div className="export-zip-container">
                <Input
                    className="export-zip-input"
                    label={"Alias Name"}
                    dataType={"text"}
                    value={aliasName}
                    placeholder={`Enter Alias Name for ${props.ioName}`}
                    onValueChange={handleAliasNameChange}
                    onLostFocus={handleLostFocus}
                    validator={() =>
                        aliasNameError.length > 0
                            ? { valid: false, text: aliasNameError }
                            : { valid: true, text: "OK!" }
                    }
                    instantValidation
                    showValidationBarWhenInvalid
                    showValidationIconWhenInvalid
                />
            </div>
            <ModalFooter>
                <div className="footer-part">
                    <Button
                        text={"Set"}
                        type="primary-blue"
                        sizeClass="small"
                        disabled={isSubmitBtnDisabled}
                        onClick={handleSubmit}
                    />
                    <Button
                        style={{
                            marginRight: "10px",
                        }}
                        className="submit-btn"
                        text={"Cancel"}
                        type="discreet-black"
                        sizeClass="small"
                        onClick={handleClose}
                    />
                </div>
            </ModalFooter>
        </form>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeAliasNameForModels: (payload: {
            functionType: FunctionTypeDetail;
            ioName: string;
            aliasName: string;
        }) => {
            dispatch(editAliasNameInModels(payload));
        },
        closeModalChangeName: () => dispatch(hideModal()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetAliasNAmeForModelsModal);
