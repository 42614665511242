import React, { Fragment, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from '../../../../store';
import './style.scss';
import IoTableTabs from '../../../../components/IoTableTabs';

import { VIEW_MODE, DRAGGABLE_ITEMS } from '../../../../utils/constants/appConstants';
import { useDrop } from 'react-dnd';
import { LOADER_SIZE, LOADER_TYPE } from '../../../../utils/constants/appConstants';
import Loader from '../../../../components/Loader';
import { handleInstanceDropAction } from '../../../../store/configurationTool/actions';
import CanvasBottomBar from '../../../Home/CanvasEditorContainer/CanvasBottomBar';

interface AppProps {
    viewMode: typeof VIEW_MODE.DIAGRAM | typeof VIEW_MODE.TABLE;
}

export function ConfigurationBody(
    props: AppProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
    const [, drop] = useDrop({
        accept: DRAGGABLE_ITEMS.DIAGRAM_VIEW.canvas.target,
        drop: (data: any, monitor) => {
            // console.log('%c monitor ', 'background: lime; color: black', data);
            const position = monitor.getClientOffset();
            if (position) {
                props.handleInstanceDrop({ instance: data.asset, position });
            }
        },
    });

    const [, drop1] = useDrop({
        accept: DRAGGABLE_ITEMS.DIAGRAM_VIEW.canvas.target,
        drop: (data: any, monitor) => {
            // console.log('%c monitor ', 'background: lime; color: black', data);
            const position = monitor.getClientOffset();
            if (position) {
                props.handleInstanceDrop({ instance: data.asset, position });
            }
        },
    });

    useEffect(() => {
        props.canvasController &&
            props.canvasController.addGridLines &&
            props.canvasController.addGridLines(props.showGrid);
    }, [props.showGrid, props.canvasController]);

    const handleInstanceDropEvent = (event: React.DragEvent<HTMLDivElement>) => {
        if (event.dataTransfer.getData('ObjectTree')) {
            console.log(JSON.parse(event.dataTransfer.getData('ObjectTree')));
            const data = JSON.parse(event.dataTransfer.getData('ObjectTree'));

            const position = {
                x: event.clientX,
                y: event.clientY,
            };
            if (position) {
                props.handleInstanceDrop({
                    instance: data.node,
                    position,
                });
            }
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const handleDragEventPropagation = (event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };
    return (
        <div className="wrapper-asset-page-body">
            {props.isLoading || props.isSaving || props.isOriginalFunctionLoading ? (
                <div className="canvas-message loader">
                    {/* {props.isLoading ? 'Loading...' : 'Saving..'} */}
                    {props.isLoading ? (
                        <Loader type={LOADER_TYPE.RADIAL} sizeClass={LOADER_SIZE.LARGE} />
                    ) : (
                        <Loader type={LOADER_TYPE.RADIAL} sizeClass={LOADER_SIZE.LARGE} />
                    )}
                </div>
            ) : (
                props.activeModel.isNew &&
                props.viewMode === VIEW_MODE.DIAGRAM &&
                props.activeModel.modelInstance.json.assetData.length === 0 && (
                    <div className="canvas-message">
                        Select the type from the left panel to configure it's instance. You can
                        delete instance types from existing types and add assets
                    </div>
                )
            )}

            <>
                <div
                    id={'canvas-parent'}
                    className={` ${props.viewMode !== VIEW_MODE.DIAGRAM ? 'hidden' : ''}`}
                    ref={drop}
                    onDrop={handleInstanceDropEvent}
                    onDragOver={handleDragEventPropagation}
                >
                    <canvas id="canvas"></canvas>
                </div>
                {props.viewMode === VIEW_MODE.TABLE && (
                    <div
                        className={` ${
                            props.viewMode !== VIEW_MODE.TABLE ? 'hidden' : 'sample-table-wrapper'
                        }`}
                    >
                        <div
                            style={{ height: 'inherit' }}
                            ref={drop1}
                            onDrop={handleInstanceDropEvent}
                            onDragOver={handleDragEventPropagation}
                            className="wrapper-iotabletabs"
                        >
                            <IoTableTabs />
                        </div>
                    </div>
                )}

                {props.viewMode.value === VIEW_MODE.DIAGRAM.value && props.canvasController && (
                    <CanvasBottomBar
                        canvas={props.canvasController}
                        className={props.viewMode.value !== VIEW_MODE.DIAGRAM.value ? 'hidden' : ''}
                        isEditMode={true}
                    />
                )}
            </>
        </div>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        isEditMode: true,
        isLoading: state.instanceConfig.isLoading,
        isSaving: state.instanceConfig.isSaving,
        activeModel: state.instanceConfig.activeModel,
        isOriginalFunctionLoading: state.instanceConfig.originalFunctionLoading,
        canvasController: state.configurationTool.canvasController,
        showGrid: state.grid.show,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleInstanceDrop: (payload: { instance: any; position: { x: number; y: number } }) => {
            dispatch(handleInstanceDropAction(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationBody);
