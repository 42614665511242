import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    CollapsibleContainer,
    Collapsible,
    Checkbox,
    Icon,
} from "@abb/abb-common-ux-react";

import "../../../components/CustomLeftNavigation/style.scss";
import { StoreState } from "../../../store";
import { menuProps as AppProps } from "../../../components/CustomLeftNavigation/types";
import Condition from "../../../components/shared/Condition";
import Menu, { SubMenu, MenuItem } from "rc-menu";
import FloatingMenu from "../../../components/CustomLeftNavigation/FloatingMenu";
import { If } from "../../Functions/helper";
import Loader from "../../../components/Loader";
import { AssetType } from "../../../transformers/AssetType";
import { computeModelsForExport } from "../../../store/exportAsset/actions";
import { any } from "prop-types";
import { ComputeModelToJson } from "../../../transformers/ComputeModel/toJson/ComputeModelToJson";
import { ComputeModelFromJson } from "../../../transformers/ComputeModel/fromJson/ComputeModelFromJson";
import { ModelsPageState } from "../../../store/modelsPage/types";
import CustomTooltip from "../../../components/CustomTooltip";
import { isGlobalTenantType } from "../../../utils/helpers";

interface AssetMenuProps {
    assetExportList: any[];
    updateAssetExportList: (assetList: any[]) => any;
    leftNavigationNavData: any;
    setLeftNavigationNavData: (data: any) => any;
    handleActiveModelChange: (activeModelKey: string) => any;
    computeModelValues: ModelsPageState["computeModels"]["byId"];
    setComputeModelValues: (updatedComputeModelValues: any) => any;
}

function AssetMenu(
    props: AssetMenuProps &
        AppProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps>
) {
    const {
        data,
        key,
        keyIdentifier,
        label,
        labelFunction,
        showCheckbox,
        treeState,
        updateCheckState,
        setSelectedState,
        indices,
        nLevelMenuProps,
        nextLevelAsyncLoader,
        floatingMenuProps,
        icon,
        selected,
        updateSelected,
        showMenuOnlyOnSelect,
        computeModelValues,
        setComputeModelValues,
    } = props;
    const [checked, setChecked] = useState(false);
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
    const [isOpenedOnce, setIsOpenedOnce] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [computedModels, updateComputedModels] = useState({
        byId: {},
        entities: [],
    } as ModelsPageState["computeModels"]);
    const [menuState, setMenuState] = useState({} as any);
    const menuProps = nLevelMenuProps[indices.length - 1];

    const convertToComputeModels = useCallback(
        (multipleModelsList: any[]) => {
            multipleModelsList.forEach((json: ComputeModelToJson) => {
                Object.keys(json.properties.functions).forEach((key) => {
                    //@ts-ignore
                    json.properties.functions[key].inhibit = {
                        ...{
                            referenceValue: {
                                dataType: "boolean",
                            },
                            actualValue: {
                                dataType: "boolean",
                            },
                        },
                        ...json.properties.functions[key].inhibit,
                    };
                });

                try {
                    const model = new ComputeModelFromJson(json);
                    computedModels.byId[model.objectId] = model;
                    computedModels.entities.push(model.objectId);
                } catch (err) {
                    console.log("Invalid json :", err);
                }
            });
            data.models = Object.values(computedModels.byId);
            data.models.forEach((item: any) => {
                computeModelValues[item.objectId] = item;
            });
            updateComputedModels({ ...computedModels });
            setComputeModelValues({ ...computeModelValues });
            props.setLeftNavigationNavData([...props.leftNavigationNavData]);
            console.log("Invalid json :");
        },
        [props.multipleModals, computedModels]
    );

    useEffect(() => {
        let modelsForExport = props.multipleModals
            .filter((multipleAssetModelObj: any) => {
                return multipleAssetModelObj.uniqueTypeId === data.assetRef;
            })
            .pop();
        if (
            computedModels.entities.length === 0 &&
            modelsForExport &&
            props.activeAsset!.assetRef !== data.assetRef
        ) {
            console.log(
                "COMPUTE_MODEL_LOADED_FROM_EXPORT",
                modelsForExport[data.assetName]
            );
            convertToComputeModels(modelsForExport[data.assetName]);
            return;
        }
    }, []);

    useEffect(() => {
        let modelsForExport = props.multipleModals
            .filter((multipleAssetModelObj: any) => {
                return multipleAssetModelObj.uniqueTypeId === data.assetRef;
            })
            .pop();
        if (modelsForExport && props.activeAsset!.assetRef !== data.assetRef) {
            console.log(
                "COMPUTE_MODEL_LOADED_FROM_EXPORT",
                modelsForExport[data.assetName]
            );
            convertToComputeModels(modelsForExport[data.assetName]);
            return;
        }
    }, [props.supportedModels]);

    useEffect(() => {
        if (
            props.activeAsset!.assetRef === data.assetRef &&
            !isCollapsibleOpen &&
            !isOpenedOnce
        ) {
            const defaultModel = document.getElementById(
                `${data[keyIdentifier]}_collapsible`
            );
            if (defaultModel) {
                defaultModel.click();
                setIsCollapsibleOpen(true);
            }
        }
    }, [props.activeAsset]);

    useEffect(() => {
        if (
            (props.activeAsset!.assetRef === data.assetRef && !data.models) ||
            showLoader
        ) {
            setShowLoader(props.computeModelsLoading);
        }
    }, [props.computeModelsLoading]);

    useEffect(() => {
        if (props.activeAsset!.assetRef === data.assetRef) {
            let isChanged = false;
            if (
                computedModels.entities.length <
                    props.computeModels.entities.length ||
                (computedModels.entities.length ===
                    props.computeModels.entities.length &&
                    JSON.stringify(computedModels) !==
                        JSON.stringify(props.computeModels))
            ) {
                isChanged = true;
                updateComputedModels({ ...props.computeModels });
                data.models = Object.values(props.computeModels.byId);
                updateExportModels();
                // if (!isExportLoaded) {
                //     const modelsFetchingDetails = {
                //         objectRef: data.assetRef,
                //         version: data.assetVersion,
                //         asset: data,
                //         currentRoutePath: `/${
                //             window.location.pathname.split('/')[
                //                 window.location.pathname.split('/').length - 1
                //             ]
                //         }`,
                //         uniqueTypeId: data.assetRef,
                //     };
                //     //props.computeModelsForExport(modelsFetchingDetails);
                // }
            } else if (
                computedModels.entities.length >
                props.computeModels.entities.length
            ) {
                isChanged = true;
                let deletedComputedModelId = computedModels.entities.filter(
                    (item: any) => {
                        return !props.computeModels.entities.includes(item);
                    }
                )[0];
                let updatedExportList = [...props.assetExportList];
                updateComputedModels({ ...props.computeModels });
                console.log(
                    "COMPUTE_MODEL_UPDATE_DELETE",
                    computedModels.entities.filter((item: any) => {
                        return !props.computeModels.entities.includes(item);
                    }),
                    data.models
                );
                data.models =
                    data.models &&
                    data.models.filter((model: any) => {
                        return model.objectId !== deletedComputedModelId;
                    });
                updatedExportList = updatedExportList.filter(
                    (item: any) => item.objectId !== deletedComputedModelId
                );
                console.log(
                    deletedComputedModelId,
                    updatedExportList,
                    data.models
                );
                props.updateAssetExportList(updatedExportList);
            }
            if (isChanged) {
                props.setLeftNavigationNavData([
                    ...props.leftNavigationNavData,
                ]);
            }
        }
    }, [props.computeModels.entities, props.multipleModals]);

    const updateExportModels = () => {
        let modelsForExport = props.multipleModals
            .filter((multipleAssetModelObj: any) => {
                return multipleAssetModelObj.uniqueTypeId === data.assetRef;
            })
            .pop();
        if (!modelsForExport || !data.models) {
            return;
        }
        modelsForExport = modelsForExport[data.assetName];
        data.models.sort((a: any, b: any) => {
            if (
                a.modelDetails.name.toLowerCase() <
                b.modelDetails.name.toLowerCase()
            ) {
                return -1;
            }
            if (
                a.modelDetails.name.toLowerCase() >
                b.modelDetails.name.toLowerCase()
            ) {
                return 1;
            }
            return 0;
        });

        data.models.forEach((item: any, index: number) => {
            item.exportModel = modelsForExport
                .filter((exportModel: any) => {
                    return exportModel.objectId === item.objectId;
                })
                .pop();
        });
        props.setLeftNavigationNavData([...props.leftNavigationNavData]);
    };

    useEffect(() => {
        updateExportModels();
    }, [props.multipleModals]);

    useEffect(() => {
        let menuPropIndex = indices.length - 1;
        let currentObj: any = treeState;
        indices.forEach((childKey, index) => {
            let nextLevelMenuKey = nLevelMenuProps[index].nextLevelKey;
            if (nextLevelMenuKey && index < menuPropIndex) {
                currentObj = currentObj[childKey][nextLevelMenuKey];
            } else {
                currentObj = currentObj && currentObj[childKey];
            }
        });
        setMenuState(currentObj || {});
        setChecked(currentObj && currentObj.checked);
        if (currentObj && currentObj.checked) {
            let isAllChildChecked = true;
            currentObj &&
                currentObj.models &&
                currentObj.models.forEach((item: any) => {
                    isAllChildChecked = isAllChildChecked && item.checked;
                });
            if (currentObj.checked !== isAllChildChecked) {
                updateCheckState(indices, { checked: isAllChildChecked });
                props.onCheckBoxClick &&
                    props.onCheckBoxClick(data, isAllChildChecked);
            }
        }
    }, [props.treeState]);

    const checkboxElement = (
        <Checkbox
            sizeClass="small"
            className="checkbox"
            disabled={!(data.models && data.models.length > 0)}
            value={checked && !!data.models && !!data.models.length}
            onChange={() => {
                console.log(checked && !!data.models && !data.models.length);
                updateCheckState(indices, { checked: !checked });
                if (props.onCheckBoxClick) {
                    props.onCheckBoxClick(data, !checked);
                }
            }}
        />
    );

    return (
        <CollapsibleContainer className="wrapper-func-collapsible-container">
            <Condition
                when={showCheckbox && !isGlobalTenantType(data.assetTags || [])}
            >
                <div className="select-all">{checkboxElement}</div>
            </Condition>
            <Collapsible
                icon={icon || undefined}
                onClick={(id, e) => {
                    e.stopPropagation();
                    if (
                        data.models &&
                        data.models[0] &&
                        data.models[0].objectId
                    ) {
                        //updateSelected(data.models[0].objectId);
                        //props.handleActiveModelChange(data.models[0].objectId);
                    }
                    if (!data.models && isCollapsibleOpen) {
                        nextLevelAsyncLoader && nextLevelAsyncLoader(data);
                    }
                }}
                onExpand={() => {
                    if (!data.models) {
                        nextLevelAsyncLoader && nextLevelAsyncLoader(data);
                    }
                }}
                onExpanding={() => {
                    if (!isOpenedOnce && !data.models) {
                        setShowLoader(true);
                    }
                    setIsOpenedOnce(true);
                    setIsCollapsibleOpen(true);
                }}
                onCollapsing={() => {
                    setIsCollapsibleOpen(false);
                }}
                title={
                    <CustomTooltip
                        position="top"
                        text={`${data.assetName} ${data.assetVersion}`}
                    >
                        <>{`${data.assetName} ${data.assetVersion}`}</>
                    </CustomTooltip>
                }
                id={`${data[keyIdentifier]}_collapsible`}
                itemId={`${data[keyIdentifier]}_collapsible`}
                className={
                    props.activeAsset!.assetRef === data.assetRef
                        ? "child-selected"
                        : ""
                }
            >
                <Condition when={showLoader}>
                    <Loader sizeClass="small" type="radial" />
                </Condition>
                <Condition when={!data.models && !showLoader && isOpenedOnce}>
                    <div
                        className="empty-message"
                        style={{ marginLeft: "23px" }}
                    >
                        No Models Available
                    </div>
                </Condition>
                <Condition when={data.models && !showLoader}>
                    <div style={{ paddingLeft: "18px" }}>{props.children}</div>
                </Condition>
                <Condition
                    when={
                        props.activeAsset!.assetRef === data.assetRef &&
                        (!props.computeModelsLoading || data.models) &&
                        !isGlobalTenantType(
                            (props.activeAsset &&
                                props.activeAsset.assetTags) ||
                                []
                        )
                    }
                >
                    <FloatingMenu
                        data={data}
                        keyId={data.assetRef}
                        isChild={false}
                        floatingMenuProps={
                            floatingMenuProps &&
                            computedModels.entities.length === 0
                                ? floatingMenuProps.filter((FloatingMenu) => {
                                      return (
                                          FloatingMenu.label !== "Export Model"
                                      );
                                  })
                                : floatingMenuProps || []
                        }
                    />
                </Condition>
            </Collapsible>
        </CollapsibleContainer>
    );
}

function mapStateToProps(state: StoreState) {
    return {
        multipleModals: state.exportAsset.multipleModels,
        computeModels: state.modelsPage.computeModels,
        activeModelId: state.modelsPage.activeModel.id,
        isAssetLoading: state.loader.supportedTypes.isLoading,
        computeModelDeleting: state.loader.computeModelDeleting,
        activeAsset: state.modelsPage.activeAsset,
        activeParentKey: state.modelsPage.activeParentKey,
        computeModelsLoading: state.loader.computeModelLoading.isLoading,
        supportedModels: state.modelsPage.supportedModels,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        computeModelsForExport: (payload: {
            objectRef: string;
            asset: AssetType;
            uniqueTypeId: string;
        }) => {
            dispatch(computeModelsForExport(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetMenu);
