import { IODataTypes } from "../../../components/Fabric/types";
import { FunctionTypeDetailsWithMappings } from "../../../model/TableController/types";
import { validateForSwedishAndFinishCharacters } from "../../../routes/Functions/helper";
import FunctionTypeDetail from "../../AssetType/FunctionType";

export function defaultValueValidation(options: {
    dataType: IODataTypes;
    value: string;
    inputDetails: FunctionTypeDetail["inputs"][0];
}) {
    const { dataType, value } = options;
    let valueArray: string[] = [];
    let isInvalid = false;
    let errorMessage: string = "";

    if (value === "") {
        return { isInvalid, errorMessage };
    }

    if (dataType.includes("array")) {
        valueArray = value.split(",");
    } else {
        valueArray = [value];
    }

    valueArray.forEach((value) => {
        value = value.trim();
        if (!isInvalid) {
            if (dataType.includes(IODataTypes.STRING)) {
                if (!/\w/.test(value)) {
                    if (validateForSwedishAndFinishCharacters(value)) {
                        isInvalid = true;
                        errorMessage =
                            "String datatype can only have characters from A-Z";
                    }
                }
            } else if (dataType.includes(IODataTypes.BOOLEAN)) {
                if (!(value === "true" || value === "false")) {
                    isInvalid = true;
                    errorMessage =
                        "Boolean datatype can only contain true/false";
                }
            } else if (dataType.includes(IODataTypes.INTEGER)) {
                // if (!/^[0-9]+$/.test(value)) {
                const item = parseInt(value);

                if (
                    !(Number.isInteger(item) && !isNaN(Number(value))) ||
                    value.includes(".")
                ) {
                    isInvalid = true;
                    errorMessage = "Integer datatype can only have digits";
                }
            } else if (dataType.includes(IODataTypes.NUMBER)) {
                const item = parseFloat(value);
                // if (!/^[0-9]*\.?[0-9]+$/.test(value)) {
                if (isNaN(Number(value))) {
                    isInvalid = true;
                    errorMessage = "Number datatype can only have digits";
                }
            }
        }
    });

    return { isInvalid, errorMessage };
}

export interface ValidateDataType {
    [key: number]: {
        isInvalid: boolean,
        errorMessage: string
    };
}

export function defaultArrayValueValidation(options: {
    dataType: IODataTypes;
    value: string;
}) {
    const { dataType, value } = options;
    let valueArray: string[] = [];
    let isInvalid = false;
    let errorMessage: string = "";
    let errors: ValidateDataType = {}
    // if (value === "") {
    //     return { key: { isInvalid, errorMessage } };
    // }

    if (dataType.includes("array")) {
        valueArray = value.split(",");
    } else {
        valueArray = [value];
    }

    valueArray.forEach((value: string, indexKey: number) => {
        value = value.trim();
        if (!isInvalid) {
            if (dataType.includes(IODataTypes.STRING)) {
                if (!/\w/.test(value)) {
                    if (validateForSwedishAndFinishCharacters(value)) {
                        isInvalid = true;
                        errorMessage =
                            "String datatype can only have characters from A-Z";
                        errors[indexKey] = { isInvalid, errorMessage }
                    }
                }
            } else if (dataType.includes(IODataTypes.BOOLEAN)) {
                if (!(value === "true" || value === "false")) {
                    isInvalid = true;
                    errorMessage =
                        "Boolean datatype can only contain true/false";
                    errors[indexKey] = { isInvalid, errorMessage }
                }
            } else if (dataType.includes(IODataTypes.INTEGER)) {
                // if (!/^[0-9]+$/.test(value)) {
                const item = parseInt(value);

                if (
                    !(Number.isInteger(item) && !isNaN(Number(value))) ||
                    value.includes(".")
                ) {
                    isInvalid = true;
                    errorMessage = "Integer datatype can only have digits";
                    errors[indexKey] = { isInvalid, errorMessage }

                }
            } else if (dataType.includes(IODataTypes.NUMBER)) {
                const item = parseFloat(value);
                // if (!/^[0-9]*\.?[0-9]+$/.test(value)) {
                if (isNaN(Number(item))) {
                    isInvalid = true;
                    errorMessage = "Number datatype can only have digits";
                    errors[indexKey] = { isInvalid, errorMessage }

                }
            }
        }
    });

    return errors;
}

export const initialValueValidation = (options: {
    dataType: IODataTypes;
    value: string;
}) => {
    const { dataType, value } = options;
    let valueArray: string[] = [];
    let isInvalid = false;
    let errorMessage: string = "";

    // if (value === "") {
    //     return { isInvalid, errorMessage };
    // }

    switch (dataType) {
        case IODataTypes.STRING:
            if (!/\w/.test(value)) {
                if (validateForSwedishAndFinishCharacters(value)) {
                    isInvalid = true;
                    errorMessage =
                        "String datatype can only have characters from A-Z";
                }
            }
            break;
        case IODataTypes.BOOLEAN:
            if (!(value === "true" || value === "false")) {
                isInvalid = true;
                errorMessage =
                    "Boolean datatype can only contain true/false";
            }
            break;
        case IODataTypes.INTEGER:
            const item = parseInt(value);

            if (
                !(Number.isInteger(item) && !isNaN(Number(value))) ||
                value.includes(".")
            ) {
                isInvalid = true;
                errorMessage = "Integer datatype can only have digits";
            }
            break;
        case IODataTypes.NUMBER:
            if (!value) {
                isInvalid = true;
                errorMessage = "Number datatype can not be empty";
            }
            if (isNaN(Number(value))) {
                isInvalid = true;
                errorMessage = "Number datatype can only have digits";
            }
            break;
        default:
            break;
    }
    return { isInvalid, errorMessage };
}
