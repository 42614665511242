import React, { Fragment, useState, useEffect } from 'react';
import '../style.scss';
import * as _ from 'lodash';
import { Table } from 'reactstrap';
import { If, convertedObj, initalInput } from '../helper';
import CustomCheckBox from '../../../components/CustomCheckbox';
import { columns, dropDownOptions } from '../type';
import { Icon, Checkbox } from '@abb/abb-common-ux-react';
import CustomTableHeader from '../../../components/CustomTableHeader';
import { FUNCTION_MODE, TABLE_IO_TYPE } from '../../../utils/constants/appConstants';

interface AppProps {
    ioData: any;
    columnType: 'INPUT' | 'OUTPUT';
}

function TableIoType(props: AppProps) {
    const [inputTypeFunction, updateInputTypeFunction] = useState([] as any);
    const [outputTypeFunction, updateOutputTypeFunction] = useState([] as any);
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const ioOrder = _.orderBy(convertedObj(initalInput(props.ioData)), ['name'], ['asc']);

        if (props.columnType === TABLE_IO_TYPE.INPUT) {
            updateInputTypeFunction(ioOrder);
        }
        if (props.columnType === TABLE_IO_TYPE.OUTPUT) {
            updateOutputTypeFunction(ioOrder);
        }
    }, [props.ioData]);

    const handleSortOrder = (name: string) => {
        const sorting = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(sorting);
        handleInputOutputTypeFunction(sorting, name);
    };
    const handleInputOutputTypeFunction = (sorting: 'asc' | 'desc', name: string) => {
        if (props.columnType === TABLE_IO_TYPE.INPUT) {
            const inputOrder = _.orderBy(inputTypeFunction, [name], [sorting]);
            updateInputTypeFunction(inputOrder);
        } else {
            const outputOrder = _.orderBy(outputTypeFunction, [name], [sorting]);
            updateOutputTypeFunction(outputOrder);
        }
    };
    const selelectedFunctionType =
        props.columnType === TABLE_IO_TYPE.INPUT ? inputTypeFunction : outputTypeFunction;
    const GetBody = () => {
        return (
            <tbody className="func-table-body-view-mode">
                {selelectedFunctionType.map((condition: string, conditionIndex: number) => {
                    console.log('condition :', condition);
                    let conditionsList: any = condition;

                    let type: string = conditionsList.dataType;
                    let value: any = conditionsList.value;

                    console.log('value', value);

                    if (conditionsList.items && conditionsList.items === 'integer') {
                        type = dropDownOptions.integerArr;
                    }

                    if (conditionsList.items && conditionsList.items === 'number') {
                        type = dropDownOptions.numberArr;
                    }

                    if (conditionsList.items && conditionsList.items === 'string') {
                        type = dropDownOptions.stringArr;
                    }

                    if (conditionsList.items && conditionsList.items === 'boolean') {
                        type = dropDownOptions.booleanArr;
                    }

                    if (props.columnType === TABLE_IO_TYPE.INPUT) {
                        if (!conditionsList.isMandatory) {
                            conditionsList.isMandatory = false;
                        } else {
                            conditionsList.isMandatory = true;
                        }

                        if (typeof value === 'object') {
                            value = value.join();
                        }
                    }

                    conditionsList = {
                        [columns.dataType]: type,
                        [columns.isMandatory]: conditionsList.isMandatory,
                        [columns.value]: value ? value : '-',
                        [columns.description]: conditionsList.description
                            ? conditionsList.description
                            : '-',
                    };

                    // condition !== columns.conditions && used for outputType
                    return (
                        <tr key={conditionIndex}>
                            <td
                                className={`${
                                    props.columnType === TABLE_IO_TYPE.OUTPUT
                                        ? 'width-30'
                                        : 'width-25'
                                }`}
                            >
                                {' '}
                                {condition['name']}{' '}
                            </td>
                            <td
                                className={`${
                                    props.columnType === TABLE_IO_TYPE.OUTPUT
                                        ? 'width-30'
                                        : 'width-25'
                                }`}
                            >
                                {' '}
                                {condition['alias'] || condition['name']}{' '}
                            </td>
                            <Fragment>
                                <If cond={conditionsList.hasOwnProperty('dataType')}>
                                    <td
                                        className={`${
                                            props.columnType === TABLE_IO_TYPE.OUTPUT
                                                ? 'width-30'
                                                : 'width-15'
                                        }`}
                                    >
                                        {conditionsList['dataType']}
                                    </td>
                                </If>
                                <If
                                    cond={
                                        conditionsList.hasOwnProperty('isMandatory') &&
                                        props.columnType === TABLE_IO_TYPE.INPUT
                                    }
                                >
                                    <td className="width-15">
                                        <Checkbox
                                            sizeClass={'small'}
                                            value={conditionsList['isMandatory']}
                                            disabled={true}
                                        />
                                    </td>
                                </If>

                                <If
                                    cond={
                                        conditionsList.hasOwnProperty('value') &&
                                        props.columnType === TABLE_IO_TYPE.INPUT
                                    }
                                >
                                    <td className="width-15">{conditionsList['value']}</td>
                                </If>

                                <If cond={conditionsList.hasOwnProperty('description')}>
                                    <td
                                        className={`${
                                            props.columnType === TABLE_IO_TYPE.OUTPUT
                                                ? 'width-30'
                                                : 'width-20'
                                        }`}
                                    >
                                        {conditionsList['description']}
                                    </td>
                                </If>
                            </Fragment>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    return (
        <div className="CustomFunctionView">
            <Table className="customEditTableOverflow func-table-view">
                <CustomTableHeader
                    tableType={props.columnType}
                    tableMode={FUNCTION_MODE.VIEW}
                    sortOrder={sortOrder}
                    handleSortOrder={handleSortOrder}
                />
                <GetBody />
            </Table>
        </div>
    );
}

export default TableIoType;
