import _ from 'lodash';
import { sce } from 'sce-engg-model-19.09';
import { takeLatest, put, select } from 'redux-saga/effects';
import { ActionTypes } from '../../actionTypes';
import { CreateAsset, AssetType } from '../../../transformers/AssetType';
import {
    updateAssetList,
    saveComputeModelStore,
    getComputeModelsStore,
    deleteComputeModelStore,
    saveComputeModelApiError,
    deleteComputeModelApiError,
    getComputeModelsApi,
    saveComputeModelApi,
    updateSupportedModels,
    updateModelUsedbyInstances,
    changePaginationDetails,
    resetPaginationLoader,
    getModelUsedbyInstances,
    getAllPermisssionForModelAction,
    updateModelPermission,
    updateActiveParentKey,
    // getInfoModelInstancesRequest,
    // updateInfoModelInstanceRequest,
    // saveInfoModelInstanceRequest,
    // getTypeDefinitionRequest,
    // updateTypeDefinitionRequest,
    showProgresRequest,
    // deleteInfoModelInstanceRequest,
    handleLoadingUpdateTypeRelatedIntance,
    updateOriginalComputeModels,
    updateActiveModelDetailsForImport,
    handleUniqueTypeIdLoading,
    updateActiveModel,
    handleExistingTypeIdError,
    handleUniqueTypeId,
    handleUniqueTypeIdMessage,
    handleUniqueTypeIdFailed,
    AddNewModelComputeModelPayload,
    addNewComputeModel,
    addSaveAsComputeModel,
    showInstancesModal,
} from '../actions';
import { store } from '../../../containers/AppBoot';
import { AssetsType } from '../../../components/Fabric/types';
import { ComputeModelToJsonConstructorOptions } from '../../../transformers/ComputeModel/toJson/types';
import { ComputeModelToJson } from '../../../transformers/ComputeModel/toJson/ComputeModelToJson';
import { ComputeModelFromJson } from '../../../transformers/ComputeModel/fromJson/ComputeModelFromJson';
import { StoreState } from '../..';
import {
    abbSDKGetErrorMessage,
    checkVersionUpdateStatus,
    isMinorVersionUpdate,
    compareTypesFormDetails,
} from '../../../utils/helpers';
import {
    NOTIFICATION_MODAL_STATUS,
    SAVE_OPTIONS,
    SUPPORTED_MODEL,
    ROUTE_PATHNAME,
    OVERALL_SEVERITY_FUNCTION,
    STRUCTURE_IDENTITY,
    MODEL_SAVE_TYPE,
    CONFIGURATION_TOOL_MODE,
    TYPE_VALIDATION_ERROR_MSG,
    TYPE_VALIDATION_WARNING_MSG,
} from '../../../utils/constants/appConstants';
import { computeModelJsonValidation } from '../../../transformers/ComputeModel/validation/computeModelJson';
import { showNotificationModal } from '../../notificationModal/action';
import configurationToolSaga from './configurationTool';
import {
    updateOverAllSeverityFunctionData,
    getOriginalFunctionDataFromJson,
    updateOriginalFunctiontypes,
    handleCurrentFunctionUpdateStatus,
    stopBacgroundTaskGetOriginalFunctionTypes,
    fetchModelsForTriggers,
} from '../../configurationTool/actions';
import { compareTypesJson } from '../../../transformers/ComputeModel/validation/versionUpdate';
import {
    VERSION_UPDATE_TYPE,
    VersionUpdateType,
    OriginalFunctionTypes,
} from '../../configurationTool/types';
import { getSupportedConditionMonitorsRequest } from '../../supportedConditionMonitors/action';
import { updateTypeRelatedInstacePayload } from '../utils';
import { filterTypesBylatestVersion, filterComputeModelsBylatestVersion } from '../reducer';
import AppSettings from '../../../services/AppSettings';
import { CreateFunctionTypeDetail } from '../../../transformers/AssetType/FunctionType';
import { TypesWithLibraries, PayloadForTypeLevelImport } from '../../exportAsset/types';
import {
    setDetailsForTypeLevelImport,
    updateExportableModelsList,
    saveModelsToState,
} from '../../exportAsset/actions';
import { hideModal } from '../../modal/action';
import { UpdateActiveModelType } from '../../../routes/Home/CreateModel/Form/types';
import { newModelSaveAvailable } from '../../instanceConfig/actions';

function* fetchAssetsList() {
    // Fetch Connect Models. Ex- 800xA,OPC UA etc
    try {
        const state: StoreState = yield select();
        const activeAsset = state.modelsPage.activeAsset;
        const hasSupportedModels = yield sce.hasPermission(sce.getSupportedModels);
        if (hasSupportedModels) {
            yield put(getSupportedConditionMonitorsRequest());
            if (activeAsset && state.modelsPage.supportedModels.length !== 0) {
                const computeModels = state.modelsPage.computeModels.entities.map((item) => {
                    return state.modelsPage.computeModels.byId[item];
                });
                const activeModelId = state.modelsPage.activeModel.id;

                yield put(
                    getComputeModelsStore({
                        computeModels,
                        activeAsset: activeAsset,
                        activeModelId,
                        currentRoutePath: ROUTE_PATHNAME.HOME,
                        addIsDirtyToConditions: false,
                    })
                );
            }

            const response = yield sce.getSupportedModels(false);
            console.log('%c Supported Models ', 'background: lime; color: black', response);
            let supportedMap: any = [];
            let supportedModels: string[] = [];
            let responseObj: any = {};
            if (_.has(response, 'details')) {
                responseObj = _.get(response, 'details', {});
                Object.keys(responseObj).forEach((keyVal) => {
                    supportedMap.push({
                        key: keyVal,
                        value: responseObj[keyVal].value,
                    });
                    supportedModels.push(responseObj[keyVal].value);
                });
            }

            yield put(fetchModelsForTriggers());

            const hasTypes = yield sce.hasPermission(sce.getTypes);
            const res = hasTypes
                ? yield sce.getTypes([...supportedModels, AssetsType.FUNCTION])
                : { details: [] };

            const objectTypeList: AssetType[] = [];
            const functionTypeList: AssetType[] = [];
            let severityFunctionData = {} as AssetType;

            if (res && res.details) {
                res.details.forEach((obj: any) => {
                    const asset = CreateAsset(obj);
                    if (asset) {
                        if (supportedModels.includes(asset.assetType)) {
                            objectTypeList.push(asset);
                        } else {
                            if (asset.assetRef === OVERALL_SEVERITY_FUNCTION.TYPE_ID) {
                                severityFunctionData = { ...asset };
                            } else {
                                functionTypeList.push(asset);
                            }
                        }
                    }
                });
            }

            yield put(updateOverAllSeverityFunctionData(severityFunctionData));
            let mapSupportedModels = supportedModels.map((model) => {
                return {
                    key: supportedMap.find((i: any) => i.value === model)!.key,
                    value: [] as any,
                };
            });

            mapSupportedModels.forEach((x) => {
                let assetType = supportedMap.find((i: any) => i.key === x.key)!.value;
                x.value = objectTypeList.filter((i) => i.assetType === assetType);
            });
            yield put(updateAssetList({ functionTypeList, objectTypeList }));

            if (state.modelsPage.supportedModels.length === 0) {
                yield put(updateActiveParentKey(mapSupportedModels[0].key));
                if (objectTypeList.length > 0) {
                    yield put(
                        getComputeModelsApi({
                            asset: objectTypeList[0],
                            objectRef: objectTypeList[0].assetRef,
                            version: objectTypeList[0].assetVersion,
                            currentRoutePath: ROUTE_PATHNAME.HOME,
                        })
                    );
                }
            }
            yield put(updateSupportedModels(mapSupportedModels));
        }
    } catch (error) {
        console.log('%c error inside getComputeModels ', 'background: salmon; color: black', error);
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* handleUniqueTypeIdSaga(action: ReturnType<typeof handleUniqueTypeId>) {
    const { mode, details } = action.payload;

    let newModelDetails = {} as AddNewModelComputeModelPayload;
    let updateModelDetails = {} as UpdateActiveModelType;
    let modelId = '';
    let typeId = '';
    if (mode === CONFIGURATION_TOOL_MODE.EDIT) {
        updateModelDetails = details as UpdateActiveModelType;
        modelId = updateModelDetails.model;
        typeId = updateModelDetails.typeId;
    } else {
        newModelDetails = details as AddNewModelComputeModelPayload;
        modelId = newModelDetails.modelDetails.model;
        typeId = newModelDetails.modelDetails.typeId;
    }

    yield put(handleUniqueTypeIdLoading(true));
    try {
        const result = yield sce.verifyTypeExists(modelId, typeId);
        if (result.details.isExist === true) {
            yield put(handleUniqueTypeIdFailed(true));
            yield put(handleUniqueTypeIdMessage(result.details.message));
            yield put(handleUniqueTypeIdLoading(false));
        }
        if (result.details.isExist === false) {
            debugger;
            yield put(handleUniqueTypeIdFailed(false));
            yield put(handleUniqueTypeIdMessage(''));
            yield put(handleUniqueTypeIdLoading(false));

            if (mode === CONFIGURATION_TOOL_MODE.EDIT) {
                debugger;
                // yield put(updateActiveModel(updateModelDetails));
                //  yield put (updateActiveModelDetailsForImport(updateModelDetails));
            }
            if (mode === CONFIGURATION_TOOL_MODE.CREATE) {
                debugger;
                yield put(addNewComputeModel(newModelDetails));
            }
            if (mode === CONFIGURATION_TOOL_MODE.VIEW) {
                yield put(addSaveAsComputeModel(newModelDetails));
                yield put(saveComputeModelApi({ saveOption: SAVE_OPTIONS.PUBLISH }));
                // yield put (addSaveAsComputeModel(newModelDetails))
            }

            yield put(hideModal());
        }

        console.log('result', result.details.isExist);
        yield put(handleUniqueTypeIdMessage(''));
        debugger;
    } catch (error) {
        debugger;

        yield put(handleUniqueTypeIdFailed(false));
        yield put(handleUniqueTypeIdMessage(' '));
        yield put(handleUniqueTypeIdLoading(false));
        yield put(hideModal());
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}
function* saveComputeModel(action: ReturnType<typeof saveComputeModelApi>) {
    const store: StoreState = yield select();
    const { configurationTool } = store;
    const { saveOption, isLatestFunctionsUpdating } = action.payload;
    const modelsPage = store.modelsPage;
    const activeModel = modelsPage.activeModel;
    const saveType = modelsPage.saveType;

    const computeModel =
        saveType === MODEL_SAVE_TYPE.SAVE_AS
            ? modelsPage.saveAsActiveModel.modelInstance
            : modelsPage.activeModel.modelInstance;

    const isNew =
        saveType === MODEL_SAVE_TYPE.SAVE_AS
            ? modelsPage.saveAsActiveModel.isNew
            : modelsPage.activeModel.isNew;
    console.log(action.payload.saveOption);
    if (computeModel) {
        const latestJson = configurationTool.json;
        console.log(latestJson);
        const validations = computeModelJsonValidation({
            json: latestJson,
            validations: { connect_both_inhibit: true },
            overAllSelectedFunctionId: store.configurationTool.overallSeverityFunctionId,
        });
        const oldVersion =
            modelsPage.computeModels.byId[modelsPage.activeModel.id].modelDetails.version;
        const currentVersion = modelsPage.activeModel.modelInstance.modelDetails.version;
        let warningmsg = false;
         let resultmsg=false;
        if (validations.isValid) {
            yield put(newModelSaveAvailable(currentVersion));
            if (store.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT) {
                debugger;
                let versionUpdateType: VersionUpdateType;
                const isModelDetailsChanged = compareTypesFormDetails({
                    originalDetails:
                        modelsPage.computeModels.byId[modelsPage.activeModel.id].modelDetails,
                    updatedDetails: modelsPage.activeModel.modelInstance.modelDetails,
                });
                if (isModelDetailsChanged) {
                    versionUpdateType = VERSION_UPDATE_TYPE.MAJOR;
                } else {
                    versionUpdateType = compareTypesJson({
                        originalJson: modelsPage.activeModel.modelInstance.json,
                        updatedJson: configurationTool.json,
                        computeModel: modelsPage.activeModel.modelInstance,
                        overallSeverityFunctionId: configurationTool.overallSeverityFunctionId,
                    });
                }

                const { errorVersionUpdateText, successVersionUpdate } = checkVersionUpdateStatus({
                    oldVersion: oldVersion,
                    currentVersion: currentVersion,
                    versionUpdateType: versionUpdateType,
                });

                if (!successVersionUpdate) {
                    validations.isValid = false;
                    validations.errorDetails = [errorVersionUpdateText];
                } else if (
                    versionUpdateType === VERSION_UPDATE_TYPE.DEFAULT &&
                    modelsPage.activeModel.modelInstance.saveOption !== SAVE_OPTIONS.DRAFT
                ) {
                    validations.isValid = false;
                    warningmsg=true;
                    resultmsg=true;
                    validations.errorDetails = ['No new changes detected!'];
                }
            }
        }
        // previously the validation was only happening for the models to be published. Now happening for draft models also.
        if (!validations.isValid) {
            let message = '';
            validations.errorDetails.forEach((error) => (message += error));
            yield put(saveComputeModelApiError());

            yield put(
                showNotificationModal({
                    title: warningmsg ? TYPE_VALIDATION_WARNING_MSG : TYPE_VALIDATION_ERROR_MSG,
                    details: validations.errorDetails,
                    resultStatus:  resultmsg? NOTIFICATION_MODAL_STATUS.WARNING:NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                })
            );
        } else {
            const modelToSend: ComputeModelToJsonConstructorOptions = {
                ...computeModel,
                json: latestJson,
                saveOptions: { value: action.payload.saveOption },
                overallSelectedFunctionId: store.configurationTool.overallSeverityFunctionId,
            };

            console.log('modelToSend :', modelToSend);
            debugger;
            const computeModelJson = new ComputeModelToJson(modelToSend);

            if (isNew) {
                delete computeModelJson.objectId;
                delete computeModelJson.version;
            }

            const isMinorVersionUpdateType = isMinorVersionUpdate({
                oldVersion: oldVersion,
                currentVersion: currentVersion,
            });
            debugger;
            try {
                console.log('computeModelJson :', computeModelJson);

                const res = yield sce.saveModel(computeModelJson, AppSettings.IsCOD);
                console.log(res);
                if (res && res.details) {
                    const json = res.details;
                    Object.keys(json.properties.functions).forEach((key) => {
                        //@ts-ignore
                        json.properties.functions[key].inhibit = {
                            ...{
                                referenceValue: {
                                    dataType: 'boolean',
                                },
                                actualValue: {
                                    dataType: 'boolean',
                                },
                            },
                            ...json.properties.functions[key].inhibit,
                        };
                    });
                    const computeModel = new ComputeModelFromJson(json);

                    yield put(
                        updateExportableModelsList({
                            currentItem: res.details as ComputeModelToJson,
                            type: 'Update',
                        })
                    );

                    if (isMinorVersionUpdateType && saveOption === SAVE_OPTIONS.PUBLISH) {
                        yield put(handleLoadingUpdateTypeRelatedIntance(true));
                        const originalJson = modelsPage.activeModel.modelInstance;
                        const updatedTypeJson = computeModelJson;
                        const updateTypeRelatedInstancePayloadData =
                            updateTypeRelatedInstacePayload(originalJson, updatedTypeJson);
                        const updateTypeRelatedInstance = yield sce.updateTypeRelatedInstances({
                            ...updateTypeRelatedInstancePayloadData,
                        });
                        yield put(handleLoadingUpdateTypeRelatedIntance(false));
                    }
                    const isFunctionUpdated = store.configurationTool.isUpdatedWithLatestFunction;

                    if (isLatestFunctionsUpdating || isFunctionUpdated) {
                        const latestFunctionTypes = store.configurationTool.latestFunctionTypes;
                        // update original function type details.
                        const originalFunctionTypes: OriginalFunctionTypes = {
                            byIdWithVersion: {},
                            entities: [],
                        };

                        Object.keys(latestFunctionTypes).forEach((item) => {
                            const nodeId = latestFunctionTypes[item].name;
                            const position = { x: 0, y: 0 };
                            const functionTypeDetail = CreateFunctionTypeDetail({
                                ...(latestFunctionTypes[item] as any),
                                nodeId,
                                position,
                                scale: 1,
                            });
                            if (functionTypeDetail) {
                                const funcIdWithVersion = `${latestFunctionTypes[item].typeId}#${latestFunctionTypes[item].version}`;
                                originalFunctionTypes.byIdWithVersion[funcIdWithVersion] = {
                                    ...functionTypeDetail,
                                };
                                originalFunctionTypes.entities.push(funcIdWithVersion);
                            }
                        });
                        yield put(updateOriginalFunctiontypes(originalFunctionTypes));
                    }
                    yield put(
                        showNotificationModal({
                            title: `${computeModelJson.properties.model.name.value} saved successfully`,
                            resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                        })
                    );
                    yield put(handleCurrentFunctionUpdateStatus(false));

                    yield put(saveComputeModelStore({ computeModel }));
                } else {
                    throw new Error();
                }
            } catch (error) {
                yield put(saveComputeModelApiError());
                yield put(handleLoadingUpdateTypeRelatedIntance(false));
                console.log(error, typeof error, error['"meta"']);
                yield put(
                    showNotificationModal({
                        title: `${computeModelJson.properties.model.name.value} save error`,
                        resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                        type: 'confirmation',
                        details: [abbSDKGetErrorMessage(error)],
                    })
                );
            }
        }
    }
}

function* getComputeModels(action: {
    type: typeof ActionTypes.GET_COMPUTE_MODELS_API;
    payload: {
        objectRef: string;
        version: string;
        asset: AssetType;
        currentRoutePath: string;
    };
}) {
    try {
        const { objectRef, asset, currentRoutePath } = action.payload;
        const hasModels = yield sce.hasPermission(sce.getModels);
        const res = hasModels ? yield sce.getModels(objectRef, AppSettings.IsCOD) : { details: [] };
        res.details.sort((a:any, b:any) => {
            if (a.properties.model.name.value.toLowerCase() < b.properties.model.name.value.toLowerCase()) {
              return -1;
            }
            if (a.properties.model.name.value.toLowerCase() > b.properties.model.name.value.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        console.log('all compute models', res);
        // yield sce.deleteModel(res.details[1], AppSettings.IsCOD);
        debugger;

        const state: StoreState = yield select();
        let computeModels: ComputeModelFromJson[] = [];
        let activeModelId = state.modelsPage.activeModel.id;
        let matchedModelId = '';

        if (res.details && Array.isArray(res.details)) {
            yield put(updateOriginalComputeModels([...(res.details as ComputeModelToJson[])]));
        }
        const allModels = _.cloneDeepWith(res.details);
        const filteredAllModels = filterTypesBylatestVersion({
            models: allModels,
        });

        if (filteredAllModels) {
            let exportableModel = [
                {
                    uniqueTypeId: asset.assetRef,
                    [asset.assetName]: filteredAllModels,
                },
            ];

            yield put(saveModelsToState({ fetchedModals: exportableModel }));
        }

        filteredAllModels &&
            Array.isArray(filteredAllModels) &&
            filteredAllModels.forEach((json: ComputeModelToJson) => {
                Object.keys(json.properties.functions).forEach((key) => {
                    //@ts-ignore
                    json.properties.functions[key].inhibit = {
                        ...{
                            referenceValue: {
                                dataType: 'boolean',
                            },
                            actualValue: {
                                dataType: 'boolean',
                            },
                        },
                        ...json.properties.functions[key].inhibit,
                    };
                });

                try {
                    const model = new ComputeModelFromJson(json);

                    if (model) {
                        if (model.objectId === activeModelId) {
                            matchedModelId = activeModelId;
                        }

                        computeModels.push(model);
                    }
                } catch (err) {
                    console.log('Invalid json :', err);
                }
            });

        console.log('initialPath :', window.location.pathname);

        if (matchedModelId === '') {
            matchedModelId = filteredAllModels[0] ? filteredAllModels[0].objectId : '';
        }

        yield put(
            getComputeModelsStore({
                computeModels: computeModels,
                // filteredComputeModels: updatedComputeModels,
                activeAsset: asset,
                activeModelId: matchedModelId,
                currentRoutePath: currentRoutePath,
                addIsDirtyToConditions: true,
            })
        );
    } catch (error) {
        console.log('%c error inside getComputeModels ', 'background: salmon; color: black', error);
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* deleteComputeModel() {
    const state = store.getState();
    const activeModel = state.modelsPage.activeModel;
    const computeModels = state.modelsPage.computeModels;
    let computeModelEntities = computeModels.entities;

    if (state.modelsPage.activeModel.modelInstance) {
        const filteredComputeModels = filterTypeBasedOnVersion({
            computeModels: Object.values(computeModels.byId),
            activeModel: activeModel.modelInstance,
        });
        try {
            const response = yield sce.getModelStatistics(
            1,
            100,
            activeModel.modelInstance.modelDetails.typeId,
            parseInt(activeModel.modelInstance.modelDetails.version.split('.')[0], 10)
        );

        if (response && response.status === 200 && response.details.count > 0) {
            const totalRecords = response.details.count;
            const noOfPages = Math.ceil(totalRecords / 100);
            yield put(resetPaginationLoader(false));
            yield put(
                changePaginationDetails({
                    totalRecords: totalRecords,
                    entriesPerPage: 100,
                    noOfPages: noOfPages,
                })
            );
            yield put(updateModelUsedbyInstances(response.details.data));
            yield put(showInstancesModal(true));
            yield put(deleteComputeModelApiError());
        } else {
        const finalResponse: DeleteComputeModelResponse[] = [];
        for (let item of filteredComputeModels) {
            const modelInstance = item;
            try {
                const computeModel = new ComputeModelToJson({
                    saveOptions: {
                        value: modelInstance.saveOption ? modelInstance.saveOption : 'Published',
                    },
                    ...modelInstance,
                    json: modelInstance.json,
                });

                const res = yield sce.deleteModel(computeModel, AppSettings.IsCOD);
                debugger;
                finalResponse.push({
                    objectId: modelInstance.objectId,
                    modelInstance,
                    errorMessage: '',
                    error: false,
                });

                yield put(
                    updateExportableModelsList({
                        currentItem: computeModel as ComputeModelToJson,
                        type: 'Delete',
                    })
                );

                
            } catch (error) {
                console.log('delete error :', error);
                finalResponse.push({
                    objectId: modelInstance.objectId,
                    modelInstance,
                    errorMessage: abbSDKGetErrorMessage(error),
                    error: true,
                });
            }
        }

        let hasError: boolean = false;
        const errorMessage: string[] = [];
        let updatedComputeModels = _.cloneDeepWith(computeModels);

        finalResponse.forEach((item) => {
            if (!item.error) {
                delete updatedComputeModels.byId[item.objectId];
                const modelId = item.objectId;
                const activeModelIndex = _.indexOf(updatedComputeModels.entities, modelId);
                if (activeModelIndex > -1 && updatedComputeModels.entities.length > 0) {
                    updatedComputeModels.entities.splice(activeModelIndex, 1);
                }
            } else {
                hasError = true;
                errorMessage.push(item.errorMessage);
            }
        });
        if (hasError) {
            yield put(deleteComputeModelApiError());
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [...errorMessage],
                })
            );
        } else {
            computeModelEntities = updatedComputeModels.entities;
            const computeModelsData = computeModelEntities.map(
                (item) => updatedComputeModels.byId[item]
            );

            const { updatedComputeModels: updatedComputeModelsData } =
                filterComputeModelsBylatestVersion({
                    computeModels: computeModelsData,
                });
            if (computeModelEntities.length > 0) {
                yield put(
                    deleteComputeModelStore({
                        newModelId: updatedComputeModelsData.entities[0],
                        computeModels: updatedComputeModelsData,
                    })
                );
                const activeModel =
                    updatedComputeModelsData.byId[updatedComputeModelsData.entities[0]];
                yield put(stopBacgroundTaskGetOriginalFunctionTypes());
                yield put(
                    getOriginalFunctionDataFromJson({
                        json: activeModel,
                        currentRoutePath: ROUTE_PATHNAME.HOME,
                    })
                );
            }
            if (computeModelEntities.length === 0) {
                yield put(
                    deleteComputeModelStore({
                        newModelId: '',
                        computeModels: { byId: {}, entities: [] },
                    })
                );
            }
            yield put(
                showNotificationModal({
                    title: `Model deleted successfully`,
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                })
            );
        }
        }
        } catch (error) {
            yield put(resetPaginationLoader(false));
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                    details: [abbSDKGetErrorMessage(error)],
                })
            );
        }
        

        
    }
}

interface DeleteComputeModelResponse {
    objectId: string;
    errorMessage: any;
    error: boolean;
    modelInstance: ComputeModelFromJson;
}

function* getModelUsedbyInstancesList(action: ReturnType<typeof getModelUsedbyInstances>) {
    const { modelsPage }: StoreState = yield select();
    const { pageNumber, pageSize } = action.payload;
    yield put(resetPaginationLoader(true));

    try {
        const response = yield sce.getModelStatistics(
            pageNumber,
            pageSize,
            modelsPage.activeModel.modelInstance.modelDetails.typeId,
            parseInt(modelsPage.activeModel.modelInstance.modelDetails.version.split('.')[0], 10)
        );

        if (response && response.status === 200) {
            const totalRecords = response.details.count;
            const noOfPages = Math.ceil(totalRecords / pageSize);
            yield put(resetPaginationLoader(false));
            yield put(
                changePaginationDetails({
                    totalRecords: totalRecords,
                    entriesPerPage: pageSize,
                    noOfPages: noOfPages,
                })
            );
            yield put(updateModelUsedbyInstances(response.details.data));
        } else {
            yield put(
                showNotificationModal({
                    title: 'API error',
                    resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                    type: 'confirmation',
                })
            );
        }
    } catch (error) {
        yield put(resetPaginationLoader(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

// get All Permissions for Function
function* getAllPermisssionForModel(action: ReturnType<typeof getAllPermisssionForModelAction>) {
    try {
        const hasSave = yield sce.hasPermission(sce.saveModel);
        const hasDelete = yield sce.hasPermission(sce.deleteModel);
        const hasImport = yield sce.hasPermission(sce.importModels);
        const hasExport = yield sce.hasPermission(sce.createExportableModels);
        yield put(
            updateModelPermission({
                hasSave,
                hasDelete,
                hasEdit: hasSave,
                hasImport,
                hasExport,
            })
        );
    } catch (error) {
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

// function* getInfoModelInstances(action: ReturnType<typeof getInfoModelInstancesRequest>) {
//     try {
//         yield put(showProgresRequest(true));
//         const response = yield sce.getInfoModelInstances(SUPPORTED_MODEL.MODEL_ID);
//         const responseIdentity = yield sce.getInfoModelInstances(STRUCTURE_IDENTITY.MODEL);
//         yield put(
//             updateInfoModelInstanceRequest(
//                 response && response.details ? response.details[0] : null,
//                 responseIdentity && responseIdentity.details ? responseIdentity.details : []
//             )
//         );
//         yield put(showProgresRequest(false));
//     } catch (error) {
//         yield put(showProgresRequest(false));
//         yield put(
//             showNotificationModal({
//                 title: 'API error',
//                 resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
//                 type: 'confirmation',
//                 details: [abbSDKGetErrorMessage(error)],
//             })
//         );
//     }
// }

// function* saveInfoModelInstance(action: ReturnType<typeof saveInfoModelInstanceRequest>) {
//     try {
//         yield put(showProgresRequest(true));
//         const { infoModel, type } = action.payload;
//         if (infoModel) {
//             const response =
//                 type == 'UPDATE'
//                     ? yield sce.updateInfoModelInstance(
//                           infoModel.objectId,
//                           infoModel.model,
//                           infoModel
//                       )
//                     : yield sce.saveInfoModelInstance(infoModel);
//         }
//         yield put(showProgresRequest(false));
//         yield put(getInfoModelInstancesRequest());
//         yield put(
//             showNotificationModal({
//                 title: 'Successfully Updated',
//                 resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
//                 type: 'banner',
//             })
//         );
//     } catch (error) {
//         yield put(showProgresRequest(false));
//         yield put(
//             showNotificationModal({
//                 title: 'API error',
//                 resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
//                 type: 'confirmation',
//                 details: [abbSDKGetErrorMessage(error)],
//             })
//         );
//     }
// }

// function* deleteInfoModelInstance(action: ReturnType<typeof deleteInfoModelInstanceRequest>) {
//     try {
//         yield put(showProgresRequest(true));
//         const { objectId, modelId } = action.payload;
//         if (objectId && modelId) {
//             const response = yield sce.deleteInfoModelInstance(objectId, modelId);
//         }
//         yield put(showProgresRequest(false));
//         yield put(getInfoModelInstancesRequest());
//         yield put(
//             showNotificationModal({
//                 title: 'Successfully Deleted',
//                 resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
//                 type: 'banner',
//             })
//         );
//     } catch (error) {
//         yield put(showProgresRequest(false));
//         yield put(
//             showNotificationModal({
//                 title: 'API error',
//                 resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
//                 type: 'confirmation',
//                 details: [abbSDKGetErrorMessage(error)],
//             })
//         );
//     }
// }

// function* getTypeDefinition(action: ReturnType<typeof getTypeDefinitionRequest>) {
//     try {
//         const response = yield sce.getTypeDefinition(
//             STRUCTURE_IDENTITY.MODEL,
//             STRUCTURE_IDENTITY.TYPE,
//             STRUCTURE_IDENTITY.VERSION
//         );
//         yield put(
//             updateTypeDefinitionRequest(response && response.details ? response.details : undefined)
//         );
//     } catch (error) {
//         // yield put(showProgresRequest(false));
//         yield put(
//             showNotificationModal({
//                 title: 'API error',
//                 resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
//                 type: 'confirmation',
//                 details: [abbSDKGetErrorMessage(error)],
//             })
//         );
//     }
// }

function* handleActiveModelDetailsForImportSaga(
    action: ReturnType<typeof updateActiveModelDetailsForImport>
) {
    const { description, model, name, typeId, tags, version } = action.payload;

    const store: StoreState = yield select();
    const { selectedItemsForImport, dependantLibraries, passwordDetails } = store.exportAsset;
    let typeDetail: TypesWithLibraries[] = _.cloneDeepWith(
        selectedItemsForImport
    ) as TypesWithLibraries[];
    typeDetail[0].model.properties.model = {
        description: {
            value: description,
        },
        model: { value: model },
        name: { value: name },
        tags: { value: tags },
        typeId: { value: typeId },
        version: { value: version },
    };

    const payloadTypeLevelImport: PayloadForTypeLevelImport = {
        dependantLibraries: dependantLibraries,
        passwordDetails: passwordDetails,
        selectedItemsForImport: typeDetail,
    };
    try {
        yield put(handleUniqueTypeIdLoading(true));
        debugger;
        // Add uniquetypeId check api call here.
        const res = yield sce.getTypeDetails(model, typeId, version);
        if (res) {
            yield put(handleExistingTypeIdError(true));
        }
        debugger;
        yield put(handleUniqueTypeIdLoading(false));
    } catch (error) {
        debugger;
        // when type id not found in the database.
        yield put(handleExistingTypeIdError(false));
        yield put(hideModal());
        yield put(handleUniqueTypeIdLoading(false));
        yield put(updateActiveModel(action.payload));
        yield put(setDetailsForTypeLevelImport(payloadTypeLevelImport));
    }
}

function filterTypeBasedOnVersion(options: {
    computeModels: ComputeModelFromJson[];
    activeModel: ComputeModelFromJson;
}) {
    const { computeModels, activeModel } = options;
    const filteredComputeModels = computeModels.filter(
        (item) =>
            item.modelDetails.typeId === activeModel.modelDetails.typeId &&
            item.modelDetails.model === activeModel.modelDetails.model
    );
    return filteredComputeModels;
}

export default [
    ...configurationToolSaga,
    takeLatest(ActionTypes.FETCH_ASSETS_LIST, fetchAssetsList),
    takeLatest(ActionTypes.SAVE_COMPUTE_MODEL_API, saveComputeModel),
    takeLatest(ActionTypes.GET_COMPUTE_MODELS_API, getComputeModels),
    takeLatest(ActionTypes.DELETE_COMPUTE_MODEL_API, deleteComputeModel),
    takeLatest(ActionTypes.GET_MODEL_USED_BY_INSTANCES, getModelUsedbyInstancesList),
    takeLatest(ActionTypes.GET_ALL_PERMISSION_MODEL, getAllPermisssionForModel),
    // takeLatest(ActionTypes.GET_INFO_MODEL_INSTANCES, getInfoModelInstances),
    // takeLatest(ActionTypes.SAVE_INFO_MODEL_INSTANCE, saveInfoModelInstance),
    // takeLatest(ActionTypes.GET_TYPE_DEFINITION_REQUEST, getTypeDefinition),
    // takeLatest(ActionTypes.DELETE_INFO_MODEL_INSTANCE, deleteInfoModelInstance),
    takeLatest(ActionTypes.HANDLE_UNIQUE_TYPEID, handleUniqueTypeIdSaga),
    takeLatest(
        ActionTypes.UPDATE_ACTIVE_MODEL_DETAILS_FOR_IMPORT,
        handleActiveModelDetailsForImportSaga
    ),
];
