import _ from 'lodash';
import { ComputeModelToJsonConstructorOptions, ComputeModelProperties, Alarms } from './types';
import { AssetsType, IODataTypes, ALARM_TYPE } from '../../../components/Fabric/types';
import { dataTypeConverterToJson } from '../../utils/dataTypeConverter';
import ObjectTypeDetail from '../../AssetType/ObjectType';
import FunctionTypeDetail from '../../AssetType/FunctionType';
import { defaultValueToJson } from '../../defaultValue';
import { MODEL, ROUTE_PATHNAME } from '../../../utils/constants/appConstants';
import { AlarmMapping } from '../../../store/function/types';
import { store } from '../../../containers/AppBoot';
import { getAllMatchingPaths } from '../../utils/outputFinder';
import AlarmTypeDetail from '../../AssetType/AlarmType';
import EventTypeDetail from '../../AssetType/EventType';

// TODO: Add constructor fields validation
export class ComputeModelToJson {
    // Remains static
    model = 'abb.ia.sce.engineering';
    type = 'abb.ia.sce.engineering@1';
    // Blank for first save on new models.
    objectId: string;
    properties: ComputeModelProperties;
    variables?: any;
    version: string;

    constructor(options: ComputeModelToJsonConstructorOptions) {
        const {
            associatedObject,
            saveOptions,
            modelDetails,
            version,
            isOverAllSeverityFunctionPresent,
            overallSelectedFunctionId,
        } = options;
        const { assetData, connectionData } = options.json;

        this.version = version;

        this.properties = {
            associatedObjectType: {
                model: { value: associatedObject.model },
                typeId: { value: associatedObject.typeId },
                version: { value: associatedObject.version },
            },
            category: { value: 'Type' },
            saveOption: saveOptions,
            model: {
                description: { value: modelDetails.description },
                model: {
                    value: modelDetails.model || MODEL.DEFAULT_MODEL_ID,
                },
                name: { value: modelDetails.name },
                tags: { value: modelDetails.tags || [] },
                typeId: { value: modelDetails.typeId },
                version: { value: modelDetails.version || '1.0.0' },
            },
            objectTypes: {},
            functions: {},
            objectInstances: {},
            types: {},
            alarms: {},
            alarmTypes: {},
            eventTypes: {},
        };

        this.objectId = options.objectId;
        assetData.forEach((asset) => {
            let commonData = {
                xcoordinate: {
                    value: asset.position.x,
                    dataType: typeof asset.position.x,
                },
                ycoordinate: {
                    value: asset.position.y,
                    dataType: typeof asset.position.y,
                },
                scale: {
                    value: asset.scale,
                    dataType: typeof asset.scale,
                },
                name: {
                    value: asset.assetName,
                    dataType: typeof asset.assetName,
                },
                version: {
                    value: asset.assetVersion,
                    dataType: typeof asset.assetVersion,
                },
            };

            if (asset instanceof ObjectTypeDetail) {
                if (asset.objectInstance) {
                    let outputs = {};

                    asset.outputs.forEach((output) => {
                        let value = {
                            ...dataTypeConverterToJson({
                                dataType: output.dataType,
                            }),
                            path: output.id,
                        };

                        const assetType = output.id.split('#')[1].split('/')[0];
                        if (!outputs[assetType]) {
                            outputs[assetType] = {};
                        }

                        _.set(
                            outputs[assetType],
                            `[variables][${output.path.replace('.', '][')}]`,
                            value
                        );
                    });

                    //@ts-ignore
                    this.properties['objectInstances'][asset.nodeId] = {
                        ...commonData,
                        ...outputs,
                    };
                } else {
                    let outputs = {};

                    asset.outputs.forEach((output) => {
                        let value: {
                            path: string;
                            dataType: string;
                            items?: string;
                            alarmId?: string;
                            eventId?: string;
                        } = {
                            ...dataTypeConverterToJson({
                                dataType: output.dataType,
                            }),
                            path: output.id,
                        };

                        if (output.alarmId) {
                            value = { ...value, alarmId: output.alarmId };
                        }
                        if (output.eventId) {
                            value = { ...value, eventId: output.eventId };
                        }

                        _.set(outputs, `[${output.path.replace('.', '][')}]`, value);
                    });

                    if (asset.isReferencedType) {
                        this.properties['types']![asset.nodeId] = {
                            objectTypeModel: {
                                value: asset.assetType,
                                dataType: typeof asset.assetRef,
                                isMandatory: false,
                            },
                            objectType: {
                                value: asset.assetRef,
                                dataType: typeof asset.assetRef,
                                isMandatory: false,
                            },
                            objectId: { value: asset.objectId! },
                            referencedTypes: {
                                dataType: IODataTypes.ARRAY,
                                value: asset.referencedTypes ? [...asset.referencedTypes] : [],
                                items: IODataTypes.STRING,
                                isMandatory: false,
                            },

                            variables: outputs,
                            ...commonData,
                        };
                    } else {
                        this.properties['objectTypes'][asset.nodeId] = {
                            objectTypeModel: {
                                value: asset.assetType || AssetsType.OBJECT,
                                dataType: typeof AssetsType.OBJECT,
                                isMandatory: false,
                            },
                            objectType: {
                                value: asset.assetRef,
                                dataType: typeof asset.assetRef,
                                isMandatory: false,
                            },

                            variables: outputs,
                            ...commonData,
                        };
                    }
                }

                //@ts-ignore
            } else if (asset instanceof FunctionTypeDetail) {
                const inputs = {};
                const outputs = {};
                const inhibits = {};
                const alarmInputs = {};
                const eventInputs = {};
                const alias = _.cloneDeep(asset.alias);
                asset.alarmInputs.forEach((alarmInput) => {
                    alarmInputs[alarmInput.name] = {
                        ...dataTypeConverterToJson({
                            dataType: alarmInput.dataType,
                        }),
                        description: '',
                    };
                });
                asset.eventInputs.forEach((eventInput) => {
                    eventInputs[eventInput.name] = {
                        ...dataTypeConverterToJson({
                            dataType: eventInput.dataType,
                        }),
                        description: '',
                    };
                });
                //@ts-ignore
                asset.inputs.forEach((input) => {
                    inputs[input.name] = {
                        ...dataTypeConverterToJson({
                            dataType: input.dataType,
                        }),
                        description: '',
                    };

                    if (input.defaultValue) {
                        inputs[input.name].value = defaultValueToJson({
                            dataType: input.dataType,
                            value: input.defaultValue,
                        });
                    }
                    // Historical Data properties.
                    if (input.timeWindow) {
                        inputs[input.name].timeWindow = input.timeWindow;
                    }
                    if (input.samplingWindow) {
                        inputs[input.name].samplingWindow = input.samplingWindow;
                    }
                    if (input.samplingAggregate) {
                        inputs[input.name].samplingAggregate = input.samplingAggregate;
                    }
                    if (input.limit) {
                        inputs[input.name].limit = input.limit;
                    }
                    //adding alias if not present in models page
                    if(window.location.pathname === ROUTE_PATHNAME.HOME && (!alias[input.name] || !alias[input.name].value)) {
                        alias[input.name] = {
                            value: input.name,
                            dataType: 'string',
                            isDirty: false,
                        }
                    }
                    inputs[input.name].link = '';
                });

                asset.inhibits.forEach((inhibit) => {
                    if (inhibit.connected || inhibit.defaultValue) {
                        inhibits[inhibit.name] = {
                            ...dataTypeConverterToJson({
                                dataType: inhibit.dataType,
                            }),
                            description: '',
                        };

                        if (inhibit.defaultValue) {
                            inhibits[inhibit.name].value = defaultValueToJson({
                                dataType: inhibit.dataType,
                                value: inhibit.defaultValue,
                            });
                        }
                    }
                });

                asset.outputs.forEach((output) => {
                    outputs[output.name] = {
                        ...dataTypeConverterToJson({
                            dataType: output.dataType,
                        }),
                        description: '',
                    };
                    if (output.isPublished) {
                        if (
                            overallSelectedFunctionId &&
                            asset.nodeId === overallSelectedFunctionId
                        ) {
                            _.set(this, `variables.severity`, {
                                description: '',
                                ...dataTypeConverterToJson({
                                    dataType: IODataTypes.INTEGER,
                                }),
                            });
                            outputs[output.name].link = `#/variables/severity`;
                        } else {
                            _.set(this, `variables.${output.path}`, {
                                description: '',
                                ...dataTypeConverterToJson({
                                    dataType: output.dataType,
                                }),
                            });

                            /* adding variables based on  configuration variables */
                            if (
                                asset.conditions &&
                                asset.conditionsConfigurationVariables &&
                                asset.conditionsConfigurationVariables.length > 0
                            ) {
                                let conditionsObj = {};
                                Object.keys(asset.conditions).forEach((item: string) => {
                                    let variablesObj = {};
                                    asset.conditionsConfigurationVariables.forEach(
                                        (variable: string) => {
                                            variablesObj[variable] = {
                                                dataType:
                                                    variable === 'severity' ? 'integer' : 'string',
                                            };
                                            if (variable === 'subCondition') {
                                                variablesObj[variable] = {
                                                    ...variablesObj[variable],
                                                    enum:
                                                        asset.conditions &&
                                                        Object.keys(
                                                            asset.conditions[item].subConditions
                                                        ),
                                                };
                                            }
                                        }
                                    );
                                    conditionsObj[item] = variablesObj;
                                });
                                this.variables[asset.nodeId].outputs['conditions'] = conditionsObj;
                            } else if (
                                (asset.conditionsConfigurationVariables.length === 0 ||
                                    !asset.conditionsConfigurationVariables) &&
                                this.variables[asset.nodeId].outputs.hasOwnProperty('conditions')
                            ) {
                                delete this.variables[asset.nodeId].outputs.conditions;
                            }
                            /* ----------- */

                            outputs[output.name].link = `#/variables/${output.path.replace(
                                /\./g,
                                '/'
                            )}`;
                        }
                    } else {
                        outputs[output.name].link = '';
                    }
                    //adding alias if not present in models page
                    if(window.location.pathname === ROUTE_PATHNAME.HOME && (!alias[output.name] || !alias[output.name].value)) {
                        alias[output.name] = {
                            value: output.name,
                            dataType: 'string',
                            isDirty: false,
                        }
                    }
                });

                /* populating condition in function outputs based on variables*/
                if (
                    asset.conditionsConfigurationVariables &&
                    asset.conditionsConfigurationVariables.length > 0 &&
                    asset.conditions &&
                    this.variables
                ) {
                    outputs['conditions'] = {};
                    let conditionsObj = {};
                    Object.keys(asset.conditions).forEach((item: string) => {
                        let variablesObj = {};
                        asset.conditionsConfigurationVariables.forEach((variable: string) => {
                            variablesObj[variable] = {
                                dataType: variable === 'severity' ? 'integer' : 'string',
                                link: `#/variables/${asset.nodeId}/${getAllMatchingPaths(
                                    this.variables[asset.nodeId],
                                    variable,
                                    '/'
                                ).filter(
                                    (path: string) =>
                                        path.split('/')[path.split('/').length - 1] === item
                                )}/${variable}`,
                            };
                            if (variable === 'subCondition') {
                                variablesObj[variable] = {
                                    ...variablesObj[variable],
                                    enum:
                                        asset.conditions &&
                                        Object.keys(asset.conditions[item].subConditions),
                                };
                            }
                        });
                        conditionsObj[item] = variablesObj;
                    });
                    outputs['conditions'] = conditionsObj;
                } else if (
                    (asset.conditionsConfigurationVariables.length === 0 ||
                        !asset.conditionsConfigurationVariables) &&
                    outputs.hasOwnProperty('conditions')
                ) {
                    delete outputs['conditions'];
                }
                /* --------- */

                this.properties.functions[asset.nodeId] = {
                    ...commonData,
                    endpoint: {
                        dataType: 'string',
                        value: `${asset.endpoint || ''}`,
                        isDirty: !!asset.isEndPointDirty,
                        description: '',
                    },
                    functionType: {
                        dataType: typeof asset.assetRef,
                        value: asset.assetRef,
                    },
                    inputs: inputs,
                    outputs: outputs,
                    alias: alias,
                    alarmInputs,
                    eventInputs,
                };

                if (Object.keys(inhibits).length > 0) {
                    this.properties.functions[asset.nodeId].inhibit = {
                        ...inhibits,
                    };
                }

                if (asset.conditions) {
                    if (Object.keys(asset.conditions).length > 0) {
                        this.properties.functions[asset.nodeId].inputs.conditions =
                            asset.conditions;
                    }

                    if (window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)) {
                        delete this.properties.functions[asset.nodeId].inputs.conditions;
                    }

                    const alarmConditions: Alarms[''] = {};
                    let alarmType: AlarmMapping['']['alarmType'];
                    if (asset.alarmMapping) {
                        this.properties.functions[asset.nodeId].inputs.alarmMapping =
                            asset.alarmMapping;
                        const alarmName = Object.keys(asset.alarmMapping)[0];

                        alarmType = asset.alarmMapping[alarmName].alarmType;
                    }
                    Object.keys(asset.conditions).forEach((conditionName) => {
                        if(!window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)){
                            alarmConditions[conditionName] = {
                                alarmType: alarmType,
                            };
                        } else{
                            if(asset.alarmPins.isPublished){
                                alarmConditions[conditionName] = {
                                    alarmType: alarmType,
                                };
                            } else{
                                alarmConditions[conditionName] = {
                                    alarmType: {
                                        value: ""
                                    },
                                };        
                            }
                        }
                    });
                    if (asset.alarmMapping && asset.alarmPins.isPublished) {
                        // @ts-ignore
                        this.properties.alarms[asset.nodeId] = alarmConditions;
                    }
                    if(asset.alarmMapping && window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS)){
                         // @ts-ignore
                         this.properties.alarms[asset.nodeId] = alarmConditions;
                    }
                }
            } else {
                const inputs = {};
                const outputs = {};
                asset.inputs.forEach((input) => {
                    inputs[input.name] = {
                        ...dataTypeConverterToJson({
                            dataType: input.dataType,
                        }),
                        description: '',
                    };

                    if (input.defaultValue) {
                        inputs[input.name].value = defaultValueToJson({
                            dataType: input.dataType,
                            value: input.defaultValue,
                        });
                    }
                });

                asset.outputs.forEach((output) => {
                    outputs[output.name] = {
                        ...dataTypeConverterToJson({
                            dataType: output.dataType,
                        }),
                        description: '',
                    };
                    if (output.alarmId) {
                        outputs[output.name] = { ...outputs[output.name], alarmId: output.alarmId };
                    }
                    if (output.eventId) {
                        outputs[output.name] = { ...outputs[output.name], eventId: output.eventId };
                    }
                    if (output.isPublished) {
                        _.set(this, `variables.${output.path}`, {
                            description: '',
                            ...dataTypeConverterToJson({
                                dataType: output.dataType,
                            }),
                        });
                        outputs[output.name].link = `#/variables/${output.path.replace(
                            /\./g,
                            '/'
                        )}`;
                    }
                });
                if (asset instanceof AlarmTypeDetail) {
                    this.properties.alarmTypes![asset.nodeId] = {
                        ...commonData,
                        alarmType: {
                            dataType: typeof asset.assetRef,
                            value: asset.assetRef,
                        },
                        alarmTypeModel: {
                            dataType: typeof asset.modelId,
                            value: asset.modelId,
                        },
                        inputs: inputs,
                        outputs: outputs,
                    };
                } else {
                    this.properties.eventTypes![asset.nodeId] = {
                        ...commonData,
                        eventType: {
                            dataType: typeof asset.assetRef,
                            value: asset.assetRef,
                        },
                        eventTypeModel: {
                            dataType: typeof asset.modelId,
                            value: asset.modelId,
                        },
                        inputs: inputs,
                        outputs: outputs,
                    };
                }
            }

            if (
                window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) &&
                asset instanceof FunctionTypeDetail
            ) {
                if (asset.timeTrigger || asset.timeTrigger === '') {
                    _.set(this, `properties.functions.${asset.nodeId}.triggers.time`, {
                        dataType: 'string',
                        value: asset.timeTrigger ? asset.timeTrigger.toString() : '',
                    });
                }

                if (asset.variableChangeTrigger) {
                    // initially setting the variable change trigger value to empty.
                    Object.keys(asset.variableChangeTrigger).forEach((item) => {
                        let finalDataType = '';
                        if (asset.variableChangeTrigger) {
                            finalDataType = asset.variableChangeTrigger[item].dataType;
                            const arrayType = asset.variableChangeTrigger[item].items;
                            if (arrayType) {
                                if (finalDataType === IODataTypes.ARRAY) {
                                    finalDataType = `${finalDataType}_${arrayType}`;
                                }
                            }
                        }

                        _.set(
                            this,
                            `properties.functions.${asset.nodeId}.triggers.variableChange.${item}`,
                            {
                                ...dataTypeConverterToJson({
                                    dataType: finalDataType as IODataTypes,
                                }),
                                link: '',
                            }
                        );
                    });
                }

                asset.alarmInputs.forEach((item) => {
                    _.set(
                        this,
                        `properties.functions.${asset.nodeId}.triggers.alarm.${item.name}`,
                        {
                            ...dataTypeConverterToJson({
                                dataType: item.dataType as IODataTypes,
                            }),
                            link: '',
                        }
                    );
                });
                asset.eventInputs.forEach((item) => {
                    _.set(
                        this,
                        `properties.functions.${asset.nodeId}.triggers.event.${item.name}`,
                        {
                            ...dataTypeConverterToJson({
                                dataType: item.dataType as IODataTypes,
                            }),
                            link: '',
                        }
                    );
                });
            }

            if (
                !window.location.pathname.includes(ROUTE_PATHNAME.OBJECTS) &&
                asset instanceof FunctionTypeDetail
            ) {
                _.set(this, `properties.functions.${asset.nodeId}.triggers.time`, {
                    dataType: 'string',
                    value: asset.timeTrigger ? asset.timeTrigger.toString() : '',
                });

                asset.inputs.forEach((input) => {
                    // To set the Variable Change trigger of functions inputs.
                    _.set(
                        this,
                        `properties.functions.${asset.nodeId}.triggers.variableChange.${input.name}`,
                        {
                            ...dataTypeConverterToJson({
                                dataType: input.dataType,
                            }),
                            link: '',
                        }
                    );
                });
                asset.inhibits.forEach((inhibit) => {
                    // To set the Variable Change trigger of functions inhibits.
                    _.set(
                        this,
                        `properties.functions.${asset.nodeId}.triggers.variableChange.${inhibit.name}`,
                        {
                            ...dataTypeConverterToJson({
                                dataType: inhibit.dataType,
                            }),
                            link: '',
                        }
                    );
                });
                asset.alarmInputs.forEach((item) => {
                    _.set(
                        this,
                        `properties.functions.${asset.nodeId}.triggers.alarm.${item.name}`,
                        {
                            ...dataTypeConverterToJson({
                                dataType: item.dataType as IODataTypes,
                            }),
                            link: '',
                        }
                    );
                });
                asset.eventInputs.forEach((item) => {
                    _.set(
                        this,
                        `properties.functions.${asset.nodeId}.triggers.event.${item.name}`,
                        {
                            ...dataTypeConverterToJson({
                                dataType: item.dataType as IODataTypes,
                            }),
                            link: '',
                        }
                    );
                });
            }
        });

        const state = store.getState();
        // connection data

        connectionData.forEach((connection) => {
            const inputPath = connection.input.circleData.path;
            let ref: any = connection.output.circleData.id;
            const inputAssetType =
                connection.input.asset instanceof FunctionTypeDetail
                    ? 'functions'
                    : connection.input.asset instanceof AlarmTypeDetail
                        ? 'alarmTypes'
                        : 'eventTypes';

            if (connection.input.circleData.dataType.includes('array')) {
                ref = _.get(this, `properties.${inputAssetType}.${inputPath}.link`);

                ref = ref ? JSON.parse(ref) : undefined;
                if (ref && Array.isArray(ref)) {
                    ref.push(connection.output.circleData.id);
                } else {
                    ref = [connection.output.circleData.id];
                }
                ref = JSON.stringify(ref);
            }

            _.set(this, `properties.${inputAssetType}.${inputPath}.link`, ref);
            // const inputPropertyName = inputPath.split('.')[1];
            // let updatedPath = inputPath;
            // updatedPath = updatedPath.replace(inputPropertyName, 'inputs');
            // const outputAsset = connection.output.asset;
            // let updatedLink = `${ref}`;
            // if (outputAsset instanceof AlarmTypeDetail || outputAsset instanceof EventTypeDetail) {
            //     if (inputPropertyName === 'alarmInputs' || inputPropertyName === 'eventInputs') {
            //         updatedLink = updatedLink.replace(outputAsset.nodeId, '');
            //     }
            // }
            // _.set(this, `properties.${inputAssetType}.${updatedPath}.link`, updatedLink);

            if (connection.trigger) {
                let field = 'inputs';
                if (connection.input.circleData.path.includes('inhibit')) {
                    field = 'inhibit';
                }
                if (connection.input.circleData.path.includes('alarmInputs')) {
                    field = 'alarmInputs';
                }
                if (connection.input.circleData.path.includes('eventInputs')) {
                    field = 'eventInputs';
                }

                const isAlarmTrigger = connection.input.circleData.path.includes('alarmInputs');
                const isEventTrigger = connection.input.circleData.path.includes('eventInputs');
                if (isAlarmTrigger) {
                    const outputAsset = connection.output.asset;
                    if (outputAsset instanceof AlarmTypeDetail) {
                        let link = connection.output.circleData.id;
                        link = link.replace(outputAsset.nodeId, '');
                        link = link.replace('/alarmId', '');
                        _.set(
                            this,
                            `properties.${inputAssetType}.${inputPath.replace(
                                field,
                                `triggers.alarm`
                            )}`,
                            {
                                ...dataTypeConverterToJson({
                                    dataType: connection.input.circleData.dataType,
                                }),
                                link: link,
                            }
                        );
                    }
                } else if (isEventTrigger) {
                    const outputAsset = connection.output.asset;
                    if (outputAsset instanceof EventTypeDetail) {
                        let link = connection.output.circleData.id;
                        link = link.replace(outputAsset.nodeId, '');
                        link = link.replace('/eventId', '');
                        _.set(
                            this,
                            `properties.${inputAssetType}.${inputPath.replace(
                                field,
                                `triggers.event`
                            )}`,
                            {
                                ...dataTypeConverterToJson({
                                    dataType: connection.input.circleData.dataType,
                                }),
                                link: link,
                            }
                        );
                    }
                } else {
                    _.set(
                        this,
                        `properties.${inputAssetType}.${inputPath.replace(
                            field,
                            `triggers.variableChange`
                        )}`,
                        {
                            ...dataTypeConverterToJson({
                                dataType: connection.input.circleData.dataType,
                            }),
                            link: connection.input.circleData.id,
                        }
                    );
                }
            }
        });

        if (!this.variables) delete this.variables;
        if (!this.version) delete this.version;

        if (
            !(
                this.properties.objectInstances &&
                Object.keys(this.properties.objectInstances).length > 0
            )
        )
            delete this.properties.objectInstances;
        if (!(this.properties.alarms && Object.keys(this.properties.alarms).length > 0))
            delete this.properties.alarms;

        if (!(this.properties.types && Object.keys(this.properties.types).length > 0))
            delete this.properties.types;
    }
}

//@ts-ignore
window.ComputeModelToJson = ComputeModelToJson;
