import React, { useState, useEffect, Fragment } from "react";
import * as _ from "lodash";
import { Dispatch } from "redux";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import { Button } from "@abb/abb-common-ux-react";

import {
    search,
    initalInput,
    setDataTypes,
    convertedObj,
    getNewUniqueName,
    checkIntegerArray,
    convertedToJSONObj,
    getCheckBoxDisabled,
    getSelectedDataType,
    getDisabledForButton,
    updateHeaderDetailsObj,
    checkDefaultFunctionName,
    computeToHandleError,
    getInputKeysSet,
    validateConditions,
    validateCalculations,
    inValidAndDeletingInputsMatch,
    checkNumberArray,
    validateForSwedishAndFinishCharacters,
    hasSpecialCharacters,
    validateForSpecialCharacters_Including_HyphenAndUnderscore,
    hasWhiteSpace,
} from "../helper";
import {
    FUNCTION_MODE,
    TABLE_IO_TYPE,
    DEL_SELECTED_ITEM,
    DEL_SELECTED_ITEM_WITH_INVALID_INPUT,
    FUNCTION_MSG,
} from "../../../utils/constants/appConstants";
import {
    updateFunctionHeaderTypeDetails,
    updateFunctionHeaderTypeStatusDetails,
} from "../../../store/function/action";
import { ShowMessageDispatchProps } from "..";
import { dropDownOptions } from "../type";
import { StoreState } from "../../../store";
import CustomTableBody from "./CustomTableBody";
import CustomTableHeader from "../../../components/CustomTableHeader";
import { FunctionHeaderTypeDetails } from "../../../store/function/types";
import { CONFIRMATION_BUTTON } from "../../../utils/constants/uiConstants";
import "./style.scss";
import {
    EMPTY_FUNCTION_COMPONENTS_PLACEHOLDER,
    SEVERITY_PIN,
} from "../constants";
import { values } from "lodash";
interface IOFunctionTypeProps {
    ioData: any;
    droppedItem: boolean;
    isActive?: boolean;
    handleData: (data: any) => void;
    handleIOError: (value: boolean) => void;
    severityStatus?: number;
    componentMode: "VIEW" | "EDIT" | "CREATE";
    componentType: "INPUT" | "OUTPUT";
}
const IOFunctionType = (
    props: IOFunctionTypeProps &
        ReturnType<typeof mapStateToProps> &
        ReturnType<typeof mapDispatchToProps> &
        ShowMessageDispatchProps
) => {
    const { functionHeaderDetails, errorFlag } = props;
    const [values1, updateData] = useState("" as any);
    const [selectedValue, updateSelectedValue] = useState([] as any);
    const [typeFunction, updatetypeFunction] = useState([] as any);
    const [deleteModal, setDeleteModal] = useState(false);
    const [sortOrder, setSortOrder] = useState("asc");
    const [count, updateCount] = useState(0);
    const [overallCheckState, updateOverallCheckState] = useState(false);

    const [calculationData, updateCalculationData] = useState([] as any);

    useEffect(() => {
        const calcDetails = functionHeaderDetails.calculationDetails;
        let calcData = {};
        Object.keys(calcDetails).forEach(
            (item: any) => {
                (calcData[calcDetails[item].name] = {
                    ...calcDetails[item].outputType,
                    description: calcDetails[item].description.value,
                })
                
            }
        );
        let updateRequired = false;
        Object.keys(props.ioData).forEach((item: any) => {
            // if (!props.ioData[item].alias) {
            //     props.ioData[item].alias = item;
            //     updateRequired = true;
            // }

            if (
                calcData[item] &&
                !props.ioData[item]["error"] &&
                props.componentType === "INPUT"
            ) {
                //delete props.ioData[item];
                props.ioData[item]["error"] = {
                    valid: false,
                    text:
                        item === SEVERITY_PIN
                            ? props.componentType === "INPUT"
                                ? FUNCTION_MSG.NO_SEVERITY_PIN_INPUT
                                : FUNCTION_MSG.NO_SEVERITY_PIN_OUTPUT
                            : validateNameError(item),
                };
                props.handleIOError(true);
            } else if (calcData[item] && !props.ioData[item]["error"]) {
                delete props.ioData[item];
            }
        });
        updateCalculationData(convertedObj(calcData));
        updatetypeFunction(convertedObj(props.ioData));
        if (updateRequired) {
            props.handleData(convertedToJSONObj(convertedObj(props.ioData)));
        }
    }, [functionHeaderDetails]);

    useEffect(() => {
        const inputOrder = _.orderBy(
            convertedObj(initalInput(props.ioData)),
            ["name"],
            ["asc"]
        );
        updateSelectedValue(setDataTypes(inputOrder));
        updatetypeFunction(inputOrder);
    }, []);

    const handleSortOrder = (name: string) => {
        const sorting = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(sorting);
        const inputOrder = _.orderBy(typeFunction, [name], [sorting as any]);
        updatetypeFunction(inputOrder);
        updateSelectedValue(setDataTypes(convertedToJSONObj(inputOrder)));
        updateData(Math.random());
    };

    const handleIOAdd = () => {
        if (props.componentType === TABLE_IO_TYPE.OUTPUT) {
            let defaultObj: any = {};
            const severityIndex: number = _.findIndex(typeFunction, {
                name: "severity",
            });
            const counter: number = count === 0 ? typeFunction.length : count;
            const name: string = `Output${counter}`;
            updateCount(counter + 1);
            if (
                severityIndex === -1 &&
                typeFunction.length >= 0 &&
                props.severityStatus !== -1
            ) {
                defaultObj = {
                    name: "severity",
                    dataType: dropDownOptions.integer,
                };
            } else {
                defaultObj = {
                    name: getNewUniqueName(
                        props.functionHeaderDetails.outputDetails,
                        "Output",
                        props.functionHeaderDetails.calculationDetails
                    ),
                    dataType: dropDownOptions.integer,
                };
            }
            typeFunction.push(defaultObj);
            updateDataList(typeFunction.length);
            selectedValue.push([
                {
                    label: dropDownOptions.integer,
                    value: dropDownOptions.integer,
                },
            ]);
            updateSelectedValue(selectedValue);
        } else {
            if (props.componentType === TABLE_IO_TYPE.INPUT) {
                const counter: number =
                    count === 0 ? typeFunction.length : count;
                const name: string = `Input${counter}`;
                updateCount(counter + 1);
                const defaultObj = {
                    name: checkDefaultFunctionName(typeFunction, counter, name),
                    isMandatory: false,
                    dataType: dropDownOptions.integer,
                    alias: name,
                };
                typeFunction.push(defaultObj);
                updateDataList(typeFunction.length);
                selectedValue.push([
                    {
                        label: dropDownOptions.integer,
                        value: dropDownOptions.integer,
                    },
                ]);
                updateSelectedValue(selectedValue);
            }
        }
    };
    useEffect(() => {
        if (
            (props.componentType === TABLE_IO_TYPE.OUTPUT &&
                Object.keys(props.functionHeaderDetails.outputDetails)
                    .length === 0 &&
                Object.keys(props.functionHeaderDetails.calculationDetails)
                    .length === 0) ||
            (Object.keys(props.functionHeaderDetails.inputDetails).length ===
                0 &&
                props.componentType === TABLE_IO_TYPE.INPUT)
        ) {
            handleIOAdd();
        }
    }, []);

    useEffect(() => {
        const hasConditions =
            Object.keys(props.functionHeaderDetails.conditionDetails).length >
            0;

        let overallCheckBoxStatus = getCheckBoxDisabled(
            props.componentType === TABLE_IO_TYPE.INPUT
                ? props.functionHeaderDetails.inputDetails
                : props.functionHeaderDetails.outputDetails,
            props.componentType,
            hasConditions
        );
        if (
            props.componentType === TABLE_IO_TYPE.OUTPUT &&
            Object.keys(props.functionHeaderDetails.outputDetails).length ===
                0 &&
            Object.keys(props.functionHeaderDetails.calculationDetails).length >
                0
        ) {
            overallCheckBoxStatus = false;
        }
        updateOverallCheckState(overallCheckBoxStatus);
    }, [props.functionHeaderDetails]);

    const updateDataList = (conditionIndex: number) => {
        props.handleData(convertedToJSONObj(typeFunction));
        updatetypeFunction(typeFunction);
    };

    const handleMessageClose = (data: string, deleteIndex?: number) => {
        if (data === CONFIRMATION_BUTTON.ACCEPT) {
            deleteSelection(deleteIndex);
        }
        setDeleteModal(!deleteModal);
    };

    const handleDeleteFunctionItems = (
        e: React.MouseEvent<HTMLInputElement>,
        deleteIndex?: number
    ) => {
        // setDeleteModal(!deleteModal);
        const conditions = _.cloneDeepWith(
            props.functionHeaderDetails.conditionDetails
        );
        const calculations = _.cloneDeepWith(
            props.functionHeaderDetails.calculationDetails
        );

        let isValid = true;
        let inValidInputs: string[] = [];
        const { inputKeysToDelete, inputKeysToNotDelete } =
            getInputKeysSet(typeFunction);

        if (conditions || calculations) {
            const { isLogicValid, overallInvalidInputs } = validateConditions(
                conditions,
                inputKeysToNotDelete
            );
            const {
                isLogicValid: isCalculationsLogicValid,
                overallInvalidInputs: overallInvalidCalculationInput,
            } = validateCalculations(calculations, inputKeysToNotDelete);
            const totalInvalidInputs = [
                ...overallInvalidInputs,
                ...overallInvalidCalculationInput,
            ];
            inValidInputs = totalInvalidInputs.filter(
                (val, index) => totalInvalidInputs.indexOf(val) === index
            );
            isValid = isLogicValid && isCalculationsLogicValid;

            if (
                !inValidAndDeletingInputsMatch(inputKeysToDelete, inValidInputs)
            ) {
                isValid = true;
            }
        }

        props.showMessageModal!({
            title: "Delete",
            rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
            handleStandardButton: (data: string) =>
                handleMessageClose(data, deleteIndex),
            warningText: isValid
                ? DEL_SELECTED_ITEM
                : DEL_SELECTED_ITEM_WITH_INVALID_INPUT(inValidInputs),
        });
        updateData(e.timeStamp);
    };

    const getDesValueAndUpdate = (val: string, conditionIndex: number) => {
        typeFunction[conditionIndex]["description"] = val;
        console.log(conditionIndex);
        updateDataList(conditionIndex);
    };

    const validateNameError = (val: string): string => {
        if (val === "" || val === null) return "Name can't be empty";
        else if (hasWhiteSpace(val)) return `Spaces are not allowed`;
        else if (
            !validateForSpecialCharacters_Including_HyphenAndUnderscore(val)
        )
            return `Special Characters are not allowed`;
        else return `${val} already exists`;
    };

    const validateAliasNameError = (val: string): string => {
        // if (!validateForSwedishAndFinishCharacters(val))
        //     return `Special Characters are not allowed`;
        // else 
        return `${val} already exists`;
    };

    const getValueAndUpdate = (val: string, conditionIndex: number) => {
        const objInd: number = _.findIndex(typeFunction, { name: val });

        const calIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.calculationDetails),
            { name: val }
        );

        const calAliasIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.calculationDetails),
            { alias: val }
        );

        const outputIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.inputDetails),
            { name: val }
        );

        const outputAliasIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.inputDetails),
            { alias: val }
        );

        const inputIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.outputDetails),
            { name: val }
        );

        const inputAliasIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.outputDetails),
            { alias: val }
        );

        if (
            !validateForSpecialCharacters_Including_HyphenAndUnderscore(val) ||
            objInd !== -1 ||
            calIndex !== -1 ||
            val === SEVERITY_PIN ||
            val === "" ||
            outputIndex !== -1 ||
            inputIndex !== -1 ||
            calAliasIndex !== -1 ||
            outputAliasIndex !== -1 ||
            inputAliasIndex !== -1
        ) {
            typeFunction[conditionIndex]["error"] = {
                valid: false,
                field: "name",
                text:
                    val === SEVERITY_PIN
                        ? props.componentType === "INPUT"
                            ? FUNCTION_MSG.NO_SEVERITY_PIN_INPUT
                            : FUNCTION_MSG.NO_SEVERITY_PIN_OUTPUT
                        : validateNameError(val),
            };

            props.handleIOError(true);
            typeFunction[conditionIndex]["name"] = val;
        } else {
            typeFunction[conditionIndex]["name"] = val;
            delete typeFunction[conditionIndex]["error"];
            props.handleIOError(computeToHandleError(typeFunction));
        }
        const typeFunctionCopy = _.cloneDeep(typeFunction);
        typeFunctionCopy.forEach((fnc: any, index: number) => {
            if (index !== conditionIndex && fnc.hasOwnProperty("error")) {
                const isNamePresent = search(typeFunction, fnc.name);
                const isAliasNamePresent = fnc.alias
                    ? search(typeFunction, fnc.alias)
                    : false;
                if (!isNamePresent && !isAliasNamePresent) {
                    delete typeFunction[index].error;
                }
            }
        });

        props.handleIOError(computeToHandleError(typeFunction));
        updateDataList(conditionIndex);
    };

    const getValueAndUpdateWithAlias = (
        val: string,
        conditionIndex: number
    ) => {
        if (val.trim() === "") {
            typeFunction[conditionIndex]["alias"] = val;
            delete typeFunction[conditionIndex]["error"];
            props.handleIOError(computeToHandleError(typeFunction));
            updateDataList(conditionIndex);
            return;
        }
        const objInd: number = _.findIndex(typeFunction, { name: val });

        const calIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.calculationDetails),
            { name: val }
        );

        const calAliasIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.calculationDetails),
            { alias: val }
        );

        const outputIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.inputDetails),
            { name: val }
        );

        const outputAliasIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.inputDetails),
            { alias: val }
        );

        const inputIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.outputDetails),
            { name: val }
        );

        const inputAliasIndex: number = _.findIndex(
            Object.values(props.functionHeaderDetails.outputDetails),
            { alias: val }
        );
        
        if (
            //!validateForSpecialCharacters_Including_HyphenAndUnderscore(val) ||
            (objInd !== -1 && val !== typeFunction[conditionIndex]['name']) ||
            calIndex !== -1 ||
            val === SEVERITY_PIN ||
            (outputIndex !== -1 && val !== typeFunction[conditionIndex]['name']) ||
            (inputIndex !== -1 && val !== typeFunction[conditionIndex]['name']) ||
            calAliasIndex !== -1 ||
            outputAliasIndex !== -1 ||
            inputAliasIndex !== -1
        ) {
            typeFunction[conditionIndex]["error"] = {
                valid: false,
                field: "alias",
                text:
                    val === SEVERITY_PIN
                        ? props.componentType === "INPUT"
                            ? FUNCTION_MSG.NO_SEVERITY_PIN_INPUT
                            : FUNCTION_MSG.NO_SEVERITY_PIN_OUTPUT
                        : validateAliasNameError(val),
            };

            props.handleIOError(true);
            typeFunction[conditionIndex]["alias"] = val;
        } else {
            typeFunction[conditionIndex]["alias"] = val;
            delete typeFunction[conditionIndex]["error"];
            props.handleIOError(computeToHandleError(typeFunction));
        }
        const typeFunctionCopy = _.cloneDeep(typeFunction);
        typeFunctionCopy.forEach((fnc: any, index: number) => {
            if (index !== conditionIndex && fnc.hasOwnProperty("error")) {
                const isNamePresent = search(typeFunction, fnc.name);
                const isAliasNamePresent = fnc.alias
                    ? search(typeFunction, fnc.alias)
                    : false;
                if (!isNamePresent && !isAliasNamePresent) {
                    delete typeFunction[index].error;
                }
            }
        });

        props.handleIOError(computeToHandleError(typeFunction));
        updateDataList(conditionIndex);
    };

    const validateInputValue = (
        val: any,
        conditionIndex: number,
        selectedObj: any
    ) => {
        const compareValue = selectedObj[0]["value"];
        let valueInput: any;
        if (
            compareValue === dropDownOptions.boolean ||
            compareValue === dropDownOptions.string ||
            compareValue === dropDownOptions.integer ||
            compareValue === dropDownOptions.number
        ) {
            valueInput = val;
            delete typeFunction[conditionIndex]["numberError"];
        }

        if (
            compareValue === dropDownOptions.integer ||
            compareValue === dropDownOptions.number
        ) {
            if (val !== "") {
                valueInput = Number(val);
                if (compareValue === dropDownOptions.integer) {
                    const isIntegerValue = parseInt(val) === Number(val);
                    if (!isIntegerValue) {
                        typeFunction[conditionIndex]["numberError"] = {
                            valid: false,
                            text: "Only integer value is required",
                        };
                    } else {
                        delete typeFunction[conditionIndex]["numberError"];
                    }
                }
            } else {
                valueInput = undefined;
            }
        }

        if (compareValue === dropDownOptions.stringArr) {
            valueInput = val.toString().split(",");
        }

        if (compareValue === dropDownOptions.booleanArr) {
            valueInput = val.toString().split(",");

            const isBoolean = valueInput.some((item: string) => {
                if (!(item === "true" || item === "false")) {
                    return true;
                } else {
                    return false;
                }
            });

            if (isBoolean) {
                typeFunction[conditionIndex]["numberError"] = {
                    valid: false,
                    text: "Boolean array data type can only contain true or false",
                };
            } else {
                delete typeFunction[conditionIndex]["numberError"];
            }

            if (!val || val.length === 0) {
                delete typeFunction[conditionIndex]["numberError"];
            }
        }

        if (compareValue === dropDownOptions.integerArr) {
            if (checkIntegerArray(val)) {
                delete typeFunction[conditionIndex]["numberError"];
                valueInput = val.toString().split(",");
            } else {
                typeFunction[conditionIndex]["numberError"] = {
                    valid: false,
                    text: "Only integer value  separated by comma is allowed",
                };
                valueInput = val.toString().split(",");
            }
        }
        if (compareValue === dropDownOptions.numberArr) {
            if (checkNumberArray(val)) {
                delete typeFunction[conditionIndex]["numberError"];
                valueInput = val.toString().split(",");
            } else {
                typeFunction[conditionIndex]["numberError"] = {
                    valid: false,
                    text: "Only numeric value separated by comma is allowed",
                };
                valueInput = val.toString().split(",");
            }
        }
        if (valueInput || valueInput === 0) {
            typeFunction[conditionIndex]["value"] = valueInput;
        } else {
            delete typeFunction[conditionIndex]["value"];
        }
    };

    const handleSelectedValue = (selectedObj: any, conditionIndex: number) => {
        if (typeFunction[conditionIndex].value) {
            validateInputValue(
                typeFunction[conditionIndex].value,
                conditionIndex,
                selectedObj
            );
        }

        selectedValue[conditionIndex] = selectedObj;
        updateSelectedValue(selectedValue);
        const dataTypeValue = getSelectedDataType(selectedObj[0]["value"]);
        typeFunction[conditionIndex]["dataType"] = dataTypeValue["dataType"];
        if (
            selectedObj[0]["value"] === dropDownOptions.integerArr ||
            selectedObj[0]["value"] === dropDownOptions.numberArr ||
            selectedObj[0]["value"] === dropDownOptions.stringArr ||
            selectedObj[0]["value"] === dropDownOptions.booleanArr
        ) {
            typeFunction[conditionIndex]["items"] = dataTypeValue["items"];
        } else {
            delete typeFunction[conditionIndex]["items"];
        }
        updatetypeFunction(typeFunction);
        props.handleData(convertedToJSONObj(typeFunction));
    };

    const handleUpdateDeleteList = (conditionIndex: number) => {
        let payloadDetails: FunctionHeaderTypeDetails = {
            inputDetails: {},
            outputDetails: {},
        };
        if (typeFunction[conditionIndex]["isDelete"]) {
            delete typeFunction[conditionIndex]["isDelete"];
            payloadDetails = updateHeaderDetailsObj(
                props.componentType,
                typeFunction,
                props.functionHeaderDetails
            );
        } else {
            typeFunction[conditionIndex]["isDelete"] = true;
            payloadDetails = updateHeaderDetailsObj(
                props.componentType,
                typeFunction,
                props.functionHeaderDetails
            );
        }
        props.updateFunctionHeaderTypeDetails(payloadDetails);
        updateData(Math.random());
    };

    const handleAllDeleteList = (
        tableType: "INPUT" | "OUTPUT" | "CONDITION"
    ) => {
        const payload = {
            functionHeaderDetails: props.functionHeaderDetails,
            overallStatus: !overallCheckState,
            tableType,
        };
        props.updateCheckboxesStatus(payload);
        updateOverallCheckState(!overallCheckState);
        updateData(Math.random());
    };

    const deleteSelection = (deleteIndex?: number) => {
        let resultArray: any[] = [];
        if (deleteIndex! >= 0) {
            resultArray = typeFunction.filter((input: any, index: number) => {
                return index !== deleteIndex;
            });
        } else {
            resultArray = typeFunction.filter((input: any) => {
                return !input["isDelete"];
            });
        }

        updatetypeFunction(resultArray);
        props.handleData(convertedToJSONObj(resultArray));
        props.handleIOError(computeToHandleError(typeFunction));
        updateData(Math.random());
    };

    const setMandatory = (conditionIndex: number) => {
        typeFunction[conditionIndex]["isMandatory"] =
            !typeFunction[conditionIndex]["isMandatory"];
        updateDataList(conditionIndex);
    };

    const getInputValue = (val: any, conditionIndex: number) => {
        validateInputValue(val, conditionIndex, selectedValue[conditionIndex]);
        updateDataList(conditionIndex);
    };
    // debugger;
    const tableComponent = () => {
        return (
            <Fragment>
                <div className="add-delete-btns">
                    <Button
                        text="Add"
                        sizeClass="small"
                        shape="rounded"
                        type="primary-blue"
                        className="delete-btn"
                        disabled={errorFlag}
                        onClick={(e: any) => {
                            console.log("add I/O  button called");
                            handleIOAdd();
                        }}
                    />
                    {/* <Button
                        text="Delete"
                        sizeClass="small"
                        shape="rounded"
                        type="normal"
                        className="delete-btn"
                        onClick={(e: any) => handleDeleteFunctionItems(e)}
                        disabled={
                            errorFlag ||
                            getDisabledForButton(
                                props.componentType === TABLE_IO_TYPE.INPUT
                                    ? props.functionHeaderDetails.inputDetails
                                    : props.functionHeaderDetails.outputDetails
                            )
                        }
                    /> */}
                </div>

                <div className="func-edit-mode-table">
                    <Table className="customEditTableOverflow">
                        <CustomTableHeader
                            tableType={props.componentType}
                            tableMode={props.componentMode}
                            allDeleteStatus={overallCheckState}
                            handleAllDeleteList={handleAllDeleteList}
                            sortOrder={sortOrder}
                            handleSortOrder={(x) => handleSortOrder(x)}
                            isDeleteDisabled={
                                errorFlag ||
                                getDisabledForButton(
                                    props.componentType === TABLE_IO_TYPE.INPUT
                                        ? props.functionHeaderDetails
                                              .inputDetails
                                        : props.functionHeaderDetails
                                              .outputDetails
                                )
                            }
                            deleteFunctionItems={(e: any) =>
                                handleDeleteFunctionItems(e)
                            }
                        />
                        <CustomTableBody
                            setMandatory={setMandatory}
                            calculationData={calculationData}
                            functionType={typeFunction}
                            selectedValue={selectedValue}
                            getInputValue={getInputValue}
                            tableType={props.componentType}
                            getValueAndUpdate={getValueAndUpdate}
                            getValueAndUpdateWithAlias={
                                getValueAndUpdateWithAlias
                            }
                            handleSelectedValue={handleSelectedValue}
                            getDesValueAndUpdate={getDesValueAndUpdate}
                            handleUpdateDeleteList={handleUpdateDeleteList}
                            deleteFunctionItems={(e: any, index: number) =>
                                handleDeleteFunctionItems(e, index)
                            }
                        />
                    </Table>
                </div>
            </Fragment>
        );
    };
    console.log('fn--->',typeFunction);
    
    return props.componentMode === FUNCTION_MODE.CREATE ? (
        calculationData.length === 0 &&
        typeFunction.length === 0 &&
        !props.isActive ? (
            <div className="center-div">
                {EMPTY_FUNCTION_COMPONENTS_PLACEHOLDER}
            </div>
        ) : (
            tableComponent()
        )
    ) : (
        tableComponent()
    );
};
function mapDispatchToProps(dispatch: Dispatch) {
    return {
        updateFunctionHeaderTypeDetails: (
            payload: FunctionHeaderTypeDetails
        ) => {
            dispatch(updateFunctionHeaderTypeDetails(payload));
        },
        updateCheckboxesStatus: (payload: {
            functionHeaderDetails: FunctionHeaderTypeDetails;
            overallStatus: boolean;
            tableType: "INPUT" | "OUTPUT" | "CONDITION";
        }) => {
            dispatch(updateFunctionHeaderTypeStatusDetails(payload));
        },
    };
}
function mapStateToProps(state: StoreState) {
    return {
        errorFlag: state.functions.functionErrorFlag,
        functionHeaderDetails: state.functions.functionHeaderDetails,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IOFunctionType);
